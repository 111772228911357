import React from "react";
import { useFormulario } from "./context/FormularioContext";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import { Textarea } from "../../../ui/textarea";

const Annotazioni = () => {
  const { updateAnnotazioni, formularioData } = useFormulario();

  const handleAnnotazioniChange = (value) => {
    updateAnnotazioni({ note: value });
  };

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Annotazioni</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            <Textarea
              placeholder="Inserisci le tue annotazioni..."
              className="min-h-[200px] resize-y"
              value={formularioData.annotazioni.note || ""}
              onChange={(e) => handleAnnotazioniChange(e.target.value)}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Annotazioni;
