import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaFilePdf, FaPlus } from "react-icons/fa";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createReport,
  getReports,
  getReport,
  updateReport,
  deleteReport,
  exportAllReportsToPDF
} from "../../../apis/ConstructionReport";
import { getConstructionSite } from "../../../apis/ConstructionSite";
import ConstructionSiteNavbar from "./ConstructionSiteNavbar";
import { Archive, Loader2, Edit, Trash } from "lucide-react";

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Textarea } from "../../ui/textarea";
import { Label } from "../../ui/label";
import {
  EnhancedTable,
  TableBody,
  TableCell,
  TableHeader,
  SelectableTableRow,
  SortableTableHead,
  SelectAllTableHead,
  SelectionTableCell,
  ActionsTableCell
} from "../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "../../ui/dialog";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { DropdownMenuItem } from "../../ui/dropdown-menu";

function Rapportini() {
  const { id } = useParams();
  const [reports, setReports] = useState([]);
  const [report, setReport] = useState({
    date: new Date().toISOString().split("T")[0],
    operator: "",
    annotations: "",
    items: [],
    constructionSiteId: id
  });
  const [newItem, setNewItem] = useState({ name: "", quantity: "", unit: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [selectedReports, setSelectedReports] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [selectedItems, setSelectedItems] = useState({});
  const [itemSelectAll, setItemSelectAll] = useState(false);
  const [itemSortConfig, setItemSortConfig] = useState({
    key: null,
    direction: null
  });

  useEffect(() => {
    fetchSiteStatus();
    fetchReports();
  }, [id]);

  const fetchSiteStatus = async () => {
    setIsPageLoading(true);
    try {
      const response = await getConstructionSite(id);
      setIsArchived(response.data.status === "Archiviato");
    } catch (error) {
      console.error("Error fetching site status:", error);
    } finally {
      setIsPageLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("it-IT", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date)
      ? date.toISOString().split("T")[0]
      : "";
  };

  const fetchReports = async () => {
    try {
      const response = await getReports(id);
      setReports(response.data);
    } catch (error) {
      console.error("Errore nel recupero dei rapporti:", error);
      toast.error("Errore nel recupero dei rapporti");
    }
  };

  const fetchReport = async (reportId) => {
    try {
      const response = await getReport(reportId);
      setReport(response.data);
    } catch (error) {
      console.error("Errore nel recupero del rapporto:", error);
      toast.error("Errore nel recupero del rapporto");
    }
  };

  const handleInputChange = (e) => {
    if (isArchived) return;
    setReport({ ...report, [e.target.name]: e.target.value });
  };

  const handleItemChange = (e) => {
    if (isArchived) return;
    setNewItem({ ...newItem, [e.target.name]: e.target.value });
  };

  const addItem = () => {
    if (isArchived) return;
    setReport({ ...report, items: [...report.items, newItem] });
    setNewItem({ name: "", quantity: "", unit: "" });
  };

  const exportToPDF = (reportToExport) => {
    const doc = new jsPDF();
    doc.text(`Rapporto - ${formatDate(reportToExport.date)}`, 20, 10);
    doc.text(`Operatore: ${reportToExport.operator}`, 20, 20);
    doc.text(`Annotazioni: ${reportToExport.annotations}`, 20, 30);

    doc.autoTable({
      startY: 40,
      head: [["Nome", "Quantità", "Unità"]],
      body: reportToExport.items.map((item) => [
        item.name,
        item.quantity,
        item.unit
      ])
    });

    doc.save(`rapporto_${reportToExport._id}.pdf`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isArchived) return;
    setIsLoading(true);
    const formData = new FormData();

    Object.keys(report).forEach((key) => {
      if (key === "items") {
        formData.append(key, JSON.stringify(report[key]));
      } else {
        formData.append(key, report[key]);
      }
    });

    try {
      if (report._id) {
        await updateReport(report._id, formData);
        toast.success("Rapporto aggiornato con successo");
      } else {
        await createReport(formData);
        toast.success("Rapporto creato con successo");
      }
      fetchReports();
      setShowForm(false);
      setReport({
        date: new Date().toISOString().split("T")[0],
        operator: "",
        annotations: "",
        items: [],
        constructionSiteId: id
      });
    } catch (error) {
      console.error("Errore nel salvare il rapporto:", error);
      toast.error("Errore nel salvare il rapporto");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async (reportId) => {
    if (isArchived) return;
    await fetchReport(reportId);
    setShowForm(true);
  };

  const handleDeleteClick = (reportId) => {
    if (isArchived) return;
    setReportToDelete(reportId);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    if (isArchived) return;
    if (reportToDelete) {
      try {
        await deleteReport(reportToDelete);
        fetchReports();
        toast.success("Rapporto eliminato con successo");
      } catch (error) {
        console.error("Errore nell'eliminazione del rapporto:", error);
        toast.error("Errore nell'eliminazione del rapporto");
      } finally {
        setShowDeleteModal(false);
        setReportToDelete(null);
      }
    }
  };

  const handleExportAllToPDF = async () => {
    try {
      const pdfBlob = await exportAllReportsToPDF(id);
      const url = window.URL.createObjectURL(new Blob([pdfBlob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "rapporti_cantiere.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error exporting PDF:", error);
      toast.error("Errore nell'esportazione del PDF");
    }
  };

  const handleAddReport = () => {
    if (isArchived) return;
    setReport({
      date: new Date().toISOString().split("T")[0],
      operator: "",
      annotations: "",
      items: [],
      constructionSiteId: id
    });
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setReport({
      date: new Date().toISOString().split("T")[0],
      operator: "",
      annotations: "",
      items: [],
      constructionSiteId: id
    });
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ConstructionSiteNavbar />

      {isArchived && (
        <div className="mx-6 mt-6">
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex items-center">
              <Archive className="w-5 h-5 text-yellow-400 mr-2" />
              <p className="text-yellow-700">
                Questo cantiere è archiviato. È possibile solo visualizzare i
                rapportini.
              </p>
            </div>
          </div>
        </div>
      )}

      {isPageLoading ? (
        <div className="flex justify-center items-center h-screen">
          <Loader2 className="h-12 w-12 animate-spin" />
        </div>
      ) : (
        <div className="p-4">
          <div className="flex justify-start my-4 space-x-4">
            {!isArchived && (
              <Button
                onClick={showForm ? handleCloseForm : handleAddReport}
                variant={showForm ? "outline" : "default"}
              >
                {showForm ? "Indietro" : "Aggiungi"}
              </Button>
            )}

            <Button onClick={handleExportAllToPDF} variant="outline">
              Esporta
            </Button>
          </div>

          {!showForm && (
            <Card className="border-0">
              <CardHeader>
                <CardTitle>Elenco Rapportini</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="rounded-md border">
                  <EnhancedTable
                    items={reports}
                    sortConfig={sortConfig}
                    onSortChange={setSortConfig}
                    selectedItems={selectedReports}
                    onSelectChange={setSelectedReports}
                    selectAll={selectAll}
                    onSelectAllChange={setSelectAll}
                  >
                    <TableHeader>
                      <SelectAllTableHead />
                      <SortableTableHead sortKey="date">Data</SortableTableHead>
                      <SortableTableHead sortKey="operator">
                        Operatore
                      </SortableTableHead>
                      <SortableTableHead></SortableTableHead>
                    </TableHeader>
                    <TableBody>
                      {reports.length > 0 ? (
                        reports.map((report) => (
                          <SelectableTableRow key={report._id} item={report}>
                            <SelectionTableCell item={report} />
                            <TableCell>{formatDate(report.date)}</TableCell>
                            <TableCell>{report.operator}</TableCell>
                            <ActionsTableCell>
                              {!isArchived && (
                                <>
                                  <DropdownMenuItem
                                    onClick={() => handleEdit(report._id)}
                                    className="flex items-center"
                                  >
                                    <Edit className="h-4 w-4 mr-2" /> Modifica
                                  </DropdownMenuItem>
                                  <DropdownMenuItem
                                    onClick={() =>
                                      handleDeleteClick(report._id)
                                    }
                                    className="flex items-center text-red-600"
                                  >
                                    <Trash className="h-4 w-4 mr-2" /> Elimina
                                  </DropdownMenuItem>
                                </>
                              )}
                              <DropdownMenuItem
                                onClick={() => exportToPDF(report)}
                                className="flex items-center"
                              >
                                <FaFilePdf className="h-4 w-4 mr-2" /> Esporta
                                PDF
                              </DropdownMenuItem>
                            </ActionsTableCell>
                          </SelectableTableRow>
                        ))
                      ) : (
                        <SelectableTableRow>
                          <TableCell colSpan={4} className="text-center">
                            Nessun rapporto trovato.
                          </TableCell>
                        </SelectableTableRow>
                      )}
                    </TableBody>
                  </EnhancedTable>
                </div>
              </CardContent>
            </Card>
          )}

          {showForm && !isArchived && (
            <Card className="border-0">
              <CardHeader>
                <CardTitle>
                  {report._id ? "Modifica Rapporto" : "Nuovo Rapporto"}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <Label htmlFor="date">Data:</Label>
                    <Input
                      id="date"
                      type="date"
                      name="date"
                      value={formatDateForInput(report.date)}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div>
                    <Label htmlFor="operator">Operatore:</Label>
                    <Input
                      id="operator"
                      type="text"
                      name="operator"
                      value={report.operator}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div>
                    <Label htmlFor="annotations">Annotazioni:</Label>
                    <Textarea
                      id="annotations"
                      name="annotations"
                      value={report.annotations}
                      onChange={handleInputChange}
                      rows={4}
                    />
                  </div>

                  <div>
                    <h3 className="text-lg font-semibold mb-2">Elementi</h3>
                    <div className="rounded-md border">
                      <EnhancedTable
                        items={report.items.map((item, index) => ({
                          ...item,
                          _id: index
                        }))}
                        sortConfig={itemSortConfig}
                        onSortChange={setItemSortConfig}
                        selectedItems={selectedItems}
                        onSelectChange={setSelectedItems}
                        selectAll={itemSelectAll}
                        onSelectAllChange={setItemSelectAll}
                      >
                        <TableHeader>
                          <SelectAllTableHead />
                          <SortableTableHead sortKey="name">
                            Nome
                          </SortableTableHead>
                          <SortableTableHead sortKey="quantity">
                            Quantità
                          </SortableTableHead>
                          <SortableTableHead sortKey="unit">
                            Unità
                          </SortableTableHead>
                          <SortableTableHead></SortableTableHead>
                        </TableHeader>
                        <TableBody>
                          {report.items.length > 0 ? (
                            report.items.map((item, index) => (
                              <SelectableTableRow
                                key={index}
                                item={{ ...item, _id: index }}
                              >
                                <SelectionTableCell
                                  item={{ ...item, _id: index }}
                                />
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.quantity}</TableCell>
                                <TableCell>{item.unit}</TableCell>
                                <ActionsTableCell>
                                  <DropdownMenuItem
                                    onClick={() => {
                                      const newItems = [...report.items];
                                      newItems.splice(index, 1);
                                      setReport({ ...report, items: newItems });
                                    }}
                                    className="flex items-center text-red-600"
                                  >
                                    <Trash className="h-4 w-4 mr-2" /> Elimina
                                  </DropdownMenuItem>
                                </ActionsTableCell>
                              </SelectableTableRow>
                            ))
                          ) : (
                            <SelectableTableRow>
                              <TableCell colSpan={5} className="text-center">
                                Nessun elemento trovato. Aggiungi elementi
                                usando il modulo qui sotto.
                              </TableCell>
                            </SelectableTableRow>
                          )}
                        </TableBody>
                      </EnhancedTable>
                    </div>
                  </div>

                  <div className="flex space-x-2">
                    <Input
                      type="text"
                      name="name"
                      value={newItem.name}
                      onChange={handleItemChange}
                      placeholder="Nome"
                    />
                    <Input
                      type="number"
                      name="quantity"
                      value={newItem.quantity}
                      onChange={handleItemChange}
                      placeholder="Quantità"
                    />
                    <Input
                      type="text"
                      name="unit"
                      value={newItem.unit}
                      onChange={handleItemChange}
                      placeholder="Unità"
                    />
                    <Button type="button" onClick={addItem}>
                      <FaPlus className="mr-2" /> Aggiungi
                    </Button>
                  </div>

                  <div className="flex justify-end space-x-2">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={handleCloseForm}
                    >
                      Annulla
                    </Button>
                    <Button type="submit" disabled={isLoading}>
                      {isLoading ? (
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      ) : null}
                      {report._id ? "Aggiorna" : "Salva"}
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
          )}

          {!isArchived && (
            <Dialog open={showDeleteModal} onOpenChange={setShowDeleteModal}>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Conferma eliminazione</DialogTitle>
                  <DialogDescription>
                    Sei sicuro di voler eliminare questo rapporto?
                  </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                  <Button
                    variant="outline"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Annulla
                  </Button>
                  <Button variant="destructive" onClick={handleDelete}>
                    Elimina
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          )}
        </div>
      )}
    </div>
  );
}

export default Rapportini;
