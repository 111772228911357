import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  FaFolder,
  FaImage,
  FaVideo,
  FaTimes,
  FaTrash,
  FaUpload,
  FaPlus,
  FaArrowLeft,
  FaEllipsisV,
  FaEdit
} from "react-icons/fa";
import {
  getMediaFolders,
  createMediaFolder,
  uploadMedia,
  deleteMedia,
  updateMediaFolder,
  deleteMediaFolder,
  getConstructionSite
} from "../../../apis/ConstructionSite";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConstructionSiteNavbar from "./ConstructionSiteNavbar";
import { Archive } from "lucide-react";

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from "../../ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "../../ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "../../ui/dropdown-menu";
import { Label } from "../../ui/label";

function Media() {
  const { id } = useParams();
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [newFolderName, setNewFolderName] = useState("");
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [editingFolder, setEditingFolder] = useState(null);
  const [editedFolderName, setEditedFolderName] = useState("");
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [mediaToDelete, setMediaToDelete] = useState(null);
  const [isArchived, setIsArchived] = useState(false);
  const [password, setPassword] = useState("");
  const [deleteError, setDeleteError] = useState("");

  useEffect(() => {
    fetchSiteStatus();
    fetchFolders();
  }, [id]);

  const fetchSiteStatus = async () => {
    try {
      const response = await getConstructionSite(id);
      setIsArchived(response.data.status === "Archiviato");
    } catch (error) {
      console.error("Error fetching site status:", error);
    }
  };

  const fetchFolders = async () => {
    setIsLoading(true);
    try {
      const fetchedFolders = await getMediaFolders(id);
      setFolders(fetchedFolders);
    } catch (error) {
      console.error("Error fetching folders:", error);
      toast.error("Errore nel recupero delle cartelle");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateFolder = async () => {
    if (isArchived) return;
    if (!newFolderName.trim()) {
      toast.error("Il nome della cartella non può essere vuoto");
      return;
    }
    setIsLoading(true);
    try {
      const newFolder = await createMediaFolder(id, newFolderName);
      setFolders([...folders, newFolder]);
      setNewFolderName("");
      toast.success("Cartella creata con successo");
    } catch (error) {
      console.error("Error creating folder:", error);
      toast.error("Errore nella creazione della cartella");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    if (isArchived) return;
    setFiles([...e.target.files]);
  };

  const handleUpload = async () => {
    if (isArchived) return;
    if (!selectedFolder) {
      toast.error("Seleziona una cartella prima di caricare i file");
      return;
    }
    if (files.length === 0) {
      toast.error("Seleziona almeno un file da caricare");
      return;
    }
    setIsUploading(true);
    const formData = new FormData();
    files.forEach((file) => formData.append("media", file));
    try {
      const updatedFolder = await uploadMedia(id, selectedFolder._id, formData);
      setFolders(
        folders.map((f) => (f._id === updatedFolder._id ? updatedFolder : f))
      );
      setSelectedFolder(updatedFolder);
      setFiles([]);
      toast.success("File caricati con successo");
    } catch (error) {
      console.error("Error uploading media:", error);
      toast.error("Errore nel caricamento dei file");
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteMedia = async () => {
    if (isArchived) return;
    if (!mediaToDelete || !selectedFolder) return;
    setIsLoading(true);
    try {
      await deleteMedia(id, selectedFolder._id, mediaToDelete._id);
      const updatedFolder = {
        ...selectedFolder,
        media: selectedFolder.media.filter((m) => m._id !== mediaToDelete._id)
      };
      setFolders(
        folders.map((f) => (f._id === selectedFolder._id ? updatedFolder : f))
      );
      setSelectedFolder(updatedFolder);
      toast.success("File eliminato con successo");
    } catch (error) {
      console.error("Error deleting media:", error);
      toast.error("Errore nell'eliminazione del file");
    } finally {
      setIsLoading(false);
      setMediaToDelete(null);
    }
  };

  const handleEditFolder = (folder) => {
    if (isArchived) return;
    setEditingFolder(folder);
    setEditedFolderName(folder.name);
  };

  const handleUpdateFolder = async () => {
    if (isArchived) return;
    if (!editedFolderName.trim()) {
      toast.error("Il nome della cartella non può essere vuoto");
      return;
    }
    setIsLoading(true);
    try {
      const updatedFolder = await updateMediaFolder(
        id,
        editingFolder._id,
        editedFolderName
      );
      setFolders(
        folders.map((f) => (f._id === updatedFolder._id ? updatedFolder : f))
      );
      setEditingFolder(null);
      toast.success("Cartella aggiornata con successo");
    } catch (error) {
      console.error("Error updating folder:", error);
      toast.error("Errore nell'aggiornamento della cartella");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFolder = async () => {
    if (isArchived || !folderToDelete) return;
    setIsLoading(true);
    try {
      await deleteMediaFolder(id, folderToDelete._id, password);
      setFolders(folders.filter((f) => f._id !== folderToDelete._id));
      setFolderToDelete(null);
      setPassword("");
      toast.success("Cartella eliminata con successo");
    } catch (error) {
      setDeleteError(error.message || "Password non valida");
      toast.error("Errore nell'eliminazione della cartella");
    } finally {
      setIsLoading(false);
    }
  };

  const renderFolderContent = () => (
    <>
      <div className="flex items-center my-4 p-2">
        <Button
          onClick={() => setSelectedFolder(null)}
          variant="outline"
          className="mr-4"
        >
          <FaArrowLeft className="mr-2" /> Indietro
        </Button>
        <h3 className="font-bold text-xl">{selectedFolder.name}</h3>
        {!isArchived && (
          <div className="flex items-center ml-auto">
            <Input
              type="file"
              multiple
              onChange={handleFileChange}
              className="mr-4"
            />
            <Button onClick={handleUpload} disabled={isUploading}>
              {isUploading ? (
                <>
                  <svg
                    className="animate-spin h-5 w-5 mr-2"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Caricamento...
                </>
              ) : (
                <>
                  <FaUpload className="mr-2" /> Carica
                </>
              )}
            </Button>
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {selectedFolder.media.map((media) => (
          <Card key={media._id}>
            <CardContent className="p-2">
              {media.type === "image" ? (
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}/${media.path}`}
                  alt={media.name}
                  className="w-full h-48 object-cover rounded cursor-pointer"
                  onClick={() => setPreviewImage(media)}
                />
              ) : (
                <video
                  src={`${process.env.REACT_APP_BACKEND_URL}/${media.path}`}
                  className="w-full h-48 object-cover rounded"
                  controls
                />
              )}
              {!isArchived && (
                <Dialog>
                  <DialogTrigger asChild>
                    <Button
                      variant="destructive"
                      size="icon"
                      className="absolute top-2 right-2"
                    >
                      <FaTrash className="text-red-600" />
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Conferma eliminazione</DialogTitle>
                      <DialogDescription>
                        Sei sicuro di voler eliminare il file "{media.name}"?
                        Questa azione non può essere annullata.
                      </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                      <Button
                        variant="outline"
                        onClick={() => setMediaToDelete(null)}
                      >
                        Annulla
                      </Button>
                      <Button
                        variant="destructive"
                        onClick={() => handleDeleteMedia(media)}
                      >
                        Elimina
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              )}
              <p className="mt-2 text-sm truncate">{media.name}</p>
            </CardContent>
          </Card>
        ))}
      </div>
    </>
  );

  const renderFolderList = () => (
    <>
      {!isArchived && (
        <div className="flex justify-start items-center my-4 p-2">
          <Input
            type="text"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            placeholder="Nome Nuova Cartella"
            className="mr-4 w-auto"
          />
          <Button onClick={handleCreateFolder} disabled={isLoading}>
            Crea Cartella
          </Button>
        </div>
      )}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-2">
        {folders.map((folder) => (
          <Card key={folder._id}>
            <CardContent className="p-0 relative">
              {editingFolder && editingFolder._id === folder._id ? (
                <div className="flex items-center">
                  <Input
                    type="text"
                    value={editedFolderName}
                    onChange={(e) => setEditedFolderName(e.target.value)}
                    className="mr-2"
                  />
                  <Button onClick={handleUpdateFolder} variant="outline">
                    <FaEdit className="mr-2" />
                    Salva
                  </Button>
                </div>
              ) : (
                <>
                  <div
                    onClick={() => setSelectedFolder(folder)}
                    className="flex items-center cursor-pointer hover:bg-gray-100 p-2 rounded transition-colors"
                  >
                    <FaFolder
                      className="mr-2 text-yellow-500 flex-shrink-0"
                      size={24}
                    />
                    <span className="truncate flex-grow">{folder.name}</span>
                    {!isArchived && (
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" size="icon" className="ml-2">
                            <FaEllipsisV className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuItem
                            onClick={() => handleEditFolder(folder)}
                          >
                            <FaEdit className="mr-2 h-4 w-4" /> Modifica
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => setFolderToDelete(folder)}
                            className="text-red-600"
                          >
                            <FaTrash className="mr-2 h-4 w-4" /> Elimina
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    )}
                  </div>
                </>
              )}
            </CardContent>
          </Card>
        ))}
      </div>
    </>
  );

  return (
    <div className="mx-auto">
      <ToastContainer position="top-right" autoClose={3000} />
      <ConstructionSiteNavbar />

      {isArchived && (
        <div className="mx-6 mt-6">
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex items-center">
              <Archive className="w-5 h-5 text-yellow-400 mr-2" />
              <p className="text-yellow-700">
                Questo cantiere è archiviato. È possibile solo visualizzare i
                media.
              </p>
            </div>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <svg
            className="animate-spin h-9 w-10 text-primary"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : selectedFolder ? (
        renderFolderContent()
      ) : (
        renderFolderList()
      )}

      <Dialog open={!!previewImage} onOpenChange={() => setPreviewImage(null)}>
        <DialogContent className="sm:max-w-[80vw]">
          <DialogHeader>
            <DialogTitle>{previewImage?.name}</DialogTitle>
          </DialogHeader>
          <img
            src={
              previewImage
                ? `${process.env.REACT_APP_BACKEND_URL}/${previewImage.path}`
                : ""
            }
            alt={previewImage?.name}
            className="max-w-full max-h-[80vh] object-contain"
          />
        </DialogContent>
      </Dialog>

      {!isArchived && (
        <Dialog
          open={!!folderToDelete}
          onOpenChange={() => setFolderToDelete(null)}
        >
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Conferma eliminazione</DialogTitle>
              <DialogDescription>
                Sei sicuro di voler eliminare la cartella "
                {folderToDelete?.name}"? Inserisci la tua password per
                confermare. Questa azione non può essere annullata.
              </DialogDescription>
            </DialogHeader>
            <div className="space-y-2">
              <Label>Password</Label>
              <Input
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setDeleteError("");
                }}
                placeholder="Inserisci la password"
              />
              {deleteError && (
                <p className="text-sm text-red-500">{deleteError}</p>
              )}
            </div>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => {
                  setFolderToDelete(null);
                  setPassword("");
                  setDeleteError("");
                }}
              >
                Annulla
              </Button>
              <Button
                variant="destructive"
                onClick={handleDeleteFolder}
                disabled={!password}
              >
                Elimina
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default Media;
