import React from "react";
import { PolicyLink } from '../../admin/sharedComponent/PolicyLink';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className=" pb-2 px-4 lg:ml-[16rem] md:fixed md:bottom-0 right-0 left-0">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="md:mb-0 text-center md:text-left text-sm flex items-center justify-center md:justify-start gap-2">
          <span className="font-semibold">Restruct</span> &copy; {currentYear} Galdiero Systems | All rights reserved
          <span className="text-xs text-gray-500">v1.8.0</span>
        </div>
        <div className="flex flex-row items-center gap-2 text-sm md:gap-6">
          <PolicyLink
            href="https://www.iubenda.com/termini-e-condizioni/33611450"
            title="Terms and Conditions"
          />
          <span className="hidden sm:block border-l-2 border-slate-400 h-6"></span>
          <PolicyLink
            href="https://www.iubenda.com/privacy-policy/33611450"
            title="Privacy Policy"
          />
          <span className="hidden sm:block border-l-2 border-slate-400 h-6"></span>
          <PolicyLink
            href="https://www.iubenda.com/privacy-policy/33611450/cookie-policy"
            title="Cookie Policy"
          />
        </div>
      </div>
    </footer>
  );
};


export default Footer;