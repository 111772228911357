import { createJsonTypeInstance } from "./index";
const axios = createJsonTypeInstance();
export const fetchComputimetriciaddrec = async (id) => {
  try {
    const response = await axios.get(`/computimetriciaddrec/${id}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const fetchComputimetricsumm = async (id) => {
  try {
    const response = await axios.get(`/computimetric_summ/${id}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const updateComputimetricsumm = async (id, updatedData) => {
  try {
    const response = await axios.put(`/computimetric_summ/${id}`, updatedData);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const deleteComputimetricsumm = async (id) => {
  try {
    const response = await axios.delete(`/computimetric_summ/${id}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const addComputimetricsumm = async (newData) => {
  try {
    const response = await axios.post(`/computimetric_summ`, newData);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const fetchUms = async () => {
  try {
    const response = await axios.get("/um");
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const addComputimetricaddrec = async (newData) => {
  try {
    const response = await axios.post(`/computimetriciaddrec`, newData);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const updateComputimetricaddrec = async (id, updatedData) => {
  try {
    const response = await axios.put(
      `/computimetriciaddrec/${id}`,
      updatedData
    );
    return response;
  } catch (error) {
    throw error.response;
  }
};
export const fetchComputimetricById = async (id) => {
  try {
    const response = await axios.get(`/computimetric/${id}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const fetchCompany = async (companyId) => {
  try {
    const response = await axios.get(`/company/${companyId}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const downloadComputimetricCalculationsPDF = async (id) => {
  try {
    const response = await axios.get(`/computimetric/${id}/calculations-pdf`, {
      responseType: "blob"
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `computimetric-calculations-${id}.pdf`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error("Error downloading calculations PDF:", error);
    throw error;
  }
};

export const fetchGeneralData = async (id) => {
  try {
    const response = await axios.get(`/computimetric/${id}/general-data`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const updateGeneralData = async (id, data) => {
  try {
    const response = await axios.put(`/computimetric/${id}/general-data`, data);
    return response;
  } catch (error) {
    throw error.response;
  }
};
