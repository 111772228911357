import React, { useEffect, useState } from "react";
import { fetchPreventivi, updatePreventivi, deletePreventivo, archivePreventivo } from "../../../apis/Preventivi";
import { toast } from "react-toastify";
import { MoreVertical, Eye, FileEdit, Archive, Trash } from "lucide-react";
import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import { Textarea } from "../../ui/textarea";
import Spinner from "../../Spinner";

// Status options for the Kanban board
const KANBAN_STATUSES = ["In corso", "Inviato", "Accettato", "Rifiutato"];

// Status options for the dropdown
const STATUS_OPTIONS = [
  { value: "In corso", label: "In corso" },
  { value: "Inviato", label: "Inviato" },
  { value: "Accettato", label: "Accettato" },
  { value: "Rifiutato", label: "Rifiutato" },
];

// Card component for each preventivo
const PreventivoCard = ({ preventivo, onStatusChange, onDelete, onArchive }) => {
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [newStatus, setNewStatus] = useState(preventivo.stato || "In corso");
  const [archiveReason, setArchiveReason] = useState("");
  
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return new Date(dateString)
      .toLocaleDateString("it-IT", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      })
      .replace(/\//g, "-");
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(parseFloat(value) || 0);
  };

  const handleStatusChange = () => {
    onStatusChange(preventivo._id, newStatus);
    setIsStatusDialogOpen(false);
  };

  const handleArchive = () => {
    onArchive(preventivo._id, archiveReason);
    setIsArchiveDialogOpen(false);
  };

  const handleDelete = () => {
    onDelete(preventivo._id);
    setIsDeleteDialogOpen(false);
  };

  return (
    <div className="bg-white rounded-lg shadow p-4 mb-3">
      <div className="flex justify-between items-start">
        <div className="font-bold">{preventivo.clienti?.fullName}</div>
        <DropdownMenu>
          <DropdownMenuTrigger className="focus:outline-none">
            <MoreVertical className="h-5 w-5 cursor-pointer" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem asChild>
              <Link to={`/preventivi_edit/${preventivo._id}`} className="flex items-center">
                <Eye className="h-4 w-4 mr-2" /> Apri
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setIsStatusDialogOpen(true)} className="flex items-center">
              <FileEdit className="h-4 w-4 mr-2" /> Cambia stato
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setIsArchiveDialogOpen(true)} className="flex items-center">
              <Archive className="h-4 w-4 mr-2" /> Archivia
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setIsDeleteDialogOpen(true)} className="flex items-center">
              <Trash className="h-4 w-4 text-red-500 mr-2" /> Elimina
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      
      <div className="text-sm text-gray-600 mt-2 truncate">
        {preventivo.ogguto || "Nessuna descrizione"}
      </div>
      
      <div className="flex justify-between mt-3 text-sm">
        <div>{formatDate(preventivo.dataa)}</div>
        <div className="font-semibold">{formatCurrency(preventivo.totaleval)}</div>
      </div>

      {/* Change Status Dialog */}
      <Dialog open={isStatusDialogOpen} onOpenChange={setIsStatusDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Cambia stato</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="status">Stato</Label>
              <Select value={newStatus} onValueChange={setNewStatus}>
                <SelectTrigger id="status">
                  <SelectValue placeholder="Seleziona stato" />
                </SelectTrigger>
                <SelectContent>
                  {STATUS_OPTIONS.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsStatusDialogOpen(false)}>
              Annulla
            </Button>
            <Button onClick={handleStatusChange}>
              Conferma
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Archive Dialog */}
      <Dialog open={isArchiveDialogOpen} onOpenChange={setIsArchiveDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conferma archiviazione</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            Sei sicuro di voler archiviare questo preventivo?
          </DialogDescription>
          <div className="space-y-2">
            <Label htmlFor="archiveReason">Motivo (opzionale)</Label>
            <Textarea
              id="archiveReason"
              placeholder="Inserisci un motivo per l'archiviazione"
              value={archiveReason}
              onChange={(e) => setArchiveReason(e.target.value)}
            />
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsArchiveDialogOpen(false)}>
              Annulla
            </Button>
            <Button onClick={handleArchive} variant="destructive">
              Archivia
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conferma eliminazione</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            Sei sicuro di voler eliminare questo preventivo? Questa azione non può essere annullata.
          </DialogDescription>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDeleteDialogOpen(false)}>
              Annulla
            </Button>
            <Button onClick={handleDelete} variant="destructive">
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

// Main Kanban Board Component
const SituazionePreventivi = () => {
  const [loading, setLoading] = useState(true);
  const [preventivi, setPreventivi] = useState({
    "In corso": [],
    "Inviato": [],
    "Accettato": [],
    "Rifiutato": []
  });
  
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;

  const loadPreventivi = async () => {
    try {
      setLoading(true);
      const response = await fetchPreventivi(companyId, 1, 1000);
      
      // Only include non-archived preventivi
      const activePreventivi = response.data.preventivis.filter(p => !p.isArchived);
      
      // Initialize groups with empty arrays
      const grouped = {};
      KANBAN_STATUSES.forEach(status => {
        grouped[status] = [];
      });
      
      // Group preventivi by status
      activePreventivi.forEach(preventivo => {
        const status = preventivo.stato || "In corso";
        // Check if status is one of our defined columns
        if (KANBAN_STATUSES.includes(status)) {
          grouped[status].push(preventivo);
        } else if (status === "Importato") {
          // Map "Importato" to "In corso"
          grouped["In corso"].push(preventivo);
        } else {
          // Default case, put in "In corso"
          grouped["In corso"].push(preventivo);
        }
      });
      
      setPreventivi(grouped);
    } catch (error) {
      console.error("Error loading preventivi:", error);
      toast.error("Errore nel caricamento dei preventivi");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPreventivi();
  }, []);

  const handleStatusChange = async (id, newStatus) => {
    try {
      await updatePreventivi(id, { stato: newStatus });
      toast.success("Stato aggiornato con successo");
      loadPreventivi();
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Errore nell'aggiornamento dello stato");
    }
  };
  
  const handleDelete = async (id) => {
    try {
      await deletePreventivo(id);
      toast.success("Preventivo eliminato con successo");
      loadPreventivi();
    } catch (error) {
      console.error("Error deleting preventivo:", error);
      toast.error("Errore nell'eliminazione del preventivo");
    }
  };
  
  const handleArchive = async (id, reason) => {
    try {
      await archivePreventivo(id, reason);
      toast.success("Preventivo archiviato con successo");
      loadPreventivi();
    } catch (error) {
      console.error("Error archiving preventivo:", error);
      toast.error("Errore nell'archiviazione del preventivo");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Spinner loading={loading} />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {KANBAN_STATUSES.map((status, idx) => (
        <div key={idx} className="flex flex-col">
          <div className="bg-white text-[#06052C]  py-2 px-4 flex justify-between items-center mb-2 font-semibold">
            <div>{status}</div>
            <div className="bg-white text-[#06052C] rounded-full w-6 h-6 flex items-center justify-center text-sm">
              {preventivi[status].length}
            </div>
          </div>
          <div className="overflow-y-auto max-h-[calc(100vh-200px)] p-2">
            {preventivi[status].map((preventivo) => (
              <PreventivoCard
                key={preventivo._id}
                preventivo={preventivo}
                onStatusChange={handleStatusChange}
                onDelete={handleDelete}
                onArchive={handleArchive}
              />
            ))}
            {preventivi[status].length === 0 && (
              <div className="text-center text-gray-500 py-4">
                Nessun preventivo
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SituazionePreventivi;