import React, { useEffect, useState, useCallback } from "react";
import { Edit, Trash2, Filter, Plus } from "lucide-react";
import { createJsonTypeInstance, createMultipartInstance } from "../../../apis";
import debounce from "lodash/debounce";
import { exportToExcel, exportToPDF } from "../../../config/helper";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../../ui/tooltip";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import {
  EnhancedTable,
  TableHeader,
  TableBody,
  TableCell,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import { Label } from "../../ui/label";
import { Textarea } from "../../ui/textarea";
import { toast } from "react-toastify";
import { DropdownMenuItem } from "../../ui/dropdown-menu";

import SearchBar from "../sharedComponent/SearchBar";
import Pagination from "../sharedComponent/Pgination";
import HelpSheet from "../sharedComponent/HelpSheet";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";

const multipartAxios = createMultipartInstance();
const jsonTypeAxios = createJsonTypeInstance();

function TableComponent() {
  const [items, setItems] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isInventoryTab, setIsInventoryTab] = useState(true);
  const [editingItem, setEditingItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [filters, setFilters] = useState({
    quantityRange: "",
    storageLocation: ""
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  // Enhanced Table state
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  useEffect(() => {
    fetchItems().catch((err) =>
      setError("Failed to fetch items. Please try again.")
    );
    fetchLocations().catch((err) =>
      setError("Failed to fetch locations. Please try again.")
    );
  }, [currentPage, limit]);

  // Apply sorting when sort configuration changes
  useEffect(() => {
    if (
      sortConfig.key &&
      (isInventoryTab ? filteredItems : locations).length > 0
    ) {
      if (isInventoryTab) {
        const sortedData = [...filteredItems].sort((a, b) => {
          let aValue, bValue;

          // Handle special cases for different columns
          if (sortConfig.key === "location") {
            // For location, use location name
            const aLoc = locations.find((loc) => loc._id === a.location);
            const bLoc = locations.find((loc) => loc._id === b.location);
            aValue = aLoc ? aLoc.name : "Unknown Location";
            bValue = bLoc ? bLoc.name : "Unknown Location";
          } else if (sortConfig.key === "quantity") {
            aValue = parseFloat(a.quantity) || 0;
            bValue = parseFloat(b.quantity) || 0;
          } else {
            aValue = a[sortConfig.key];
            bValue = b[sortConfig.key];
          }

          // Handle string comparison
          if (typeof aValue === "string") {
            aValue = aValue.toLowerCase();
            bValue = bValue.toLowerCase();
          }

          if (aValue < bValue) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });

        setFilteredItems(sortedData);
      } else {
        // Sort locations
        const sortedLocations = [...locations].sort((a, b) => {
          let aValue = a[sortConfig.key];
          let bValue = b[sortConfig.key];

          // Handle string comparison
          if (typeof aValue === "string") {
            aValue = aValue.toLowerCase();
            bValue = bValue.toLowerCase();
          }

          if (aValue < bValue) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });

        setLocations(sortedLocations);
      }
    }
  }, [sortConfig, isInventoryTab, locations]);

  const areFiltersActive = () => {
    return filters.quantityRange !== "" || filters.storageLocation !== "";
  };

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    } else {
      resetData();
    }
  }, [searchTerm]);

  const resetData = () => {
    fetchItems();
    fetchLocations();
  };

  useEffect(() => {
    if (!areFiltersActive()) {
      setFilteredItems(items);
    }
  }, [items]);

  const debouncedSearch = useCallback(
    debounce((term) => {
      if (isInventoryTab) {
        searchItems(term);
      } else {
        searchLocations(term);
      }
    }, 300),
    [isInventoryTab]
  );

  const fetchItems = async (page = currentPage, pageLimit = limit) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.companyId) {
        throw new Error("User or company information not found");
      }
      const response = await jsonTypeAxios.get(
        `/items?companyId=${user.companyId}&page=${page}&limit=${pageLimit}`
      );

      if (response.data) {
        const items = Array.isArray(response.data.items)
          ? response.data.items
          : Array.isArray(response.data)
          ? response.data
          : [];

        // Map location names with null check
        const itemsWithLocationNames = items.map((item) => ({
          ...item,
          locationName: item.location?.name || "Unknown Location"
        }));

        setItems(itemsWithLocationNames);
        setFilteredItems(itemsWithLocationNames);
        setTotalPages(
          response.data.totalPages || Math.ceil(items.length / pageLimit) || 1
        );
      } else {
        throw new Error("No data received from the server");
      }
    } catch (error) {
      console.error("Error fetching items:", error);
      toast.error("Failed to fetch items. Please try again.");
      setItems([]);
      setFilteredItems([]);
    }
  };

  const fetchLocations = async (page = currentPage, pageLimit = limit) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.companyId) {
        throw new Error("User or company information not found");
      }
      const response = await jsonTypeAxios.get(
        `/locations?companyId=${user.companyId}&page=${page}&limit=${pageLimit}`
      );
      //console.log("Locations API Response:", response); // Log the entire response for debugging

      if (response.data) {
        const locations = Array.isArray(response.data.locations)
          ? response.data.locations
          : Array.isArray(response.data)
          ? response.data
          : [];
        setLocations(locations);
        setTotalPages(
          response.data.totalPages ||
            Math.ceil(locations.length / pageLimit) ||
            1
        );
      } else {
        throw new Error("No data received from the server");
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
      toast.error("Failed to fetch locations. Please try again.");
      setLocations([]);
    }
  };

  const searchItems = async (term) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.companyId) {
        console.error("User or company information not found");
        return;
      }
      const response = await jsonTypeAxios.get(
        `/items/search?q=${term}&companyId=${user.companyId}`
      );
      setItems(response.data);
    } catch (error) {
      console.error("Error searching items:", error);
      toast.error("Failed to search items. Please try again.");
    }
  };

  const searchLocations = async (term) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.companyId) {
        console.error("User or company information not found");
        return;
      }
      const response = await jsonTypeAxios.get(
        `/locations/search?q=${term}&companyId=${user.companyId}`
      );
      setLocations(response.data);
    } catch (error) {
      console.error("Error searching locations:", error);
      toast.error("Failed to search locations. Please try again.");
    }
  };

  const handleAddOrUpdateItem = async (item) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.companyId) {
        console.error("User or company information not found");
        return;
      }

      // Ensure location is properly set as ObjectId
      const itemWithCompanyAndLocation = {
        ...item,
        companyId: user.companyId,
        location: item.location // This should be the location ID from the select
      };

      //console.log("Saving item:", itemWithCompanyAndLocation); // Debug log

      if (editingItem) {
        await jsonTypeAxios.put(
          `/items/${editingItem._id}`,
          itemWithCompanyAndLocation
        );
        toast.success("Item updated successfully");
      } else {
        await jsonTypeAxios.post("/items", itemWithCompanyAndLocation);
        toast.success("Item added successfully");
      }

      // Refresh both locations and items
      await fetchLocations();
      await fetchItems();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error adding/updating item:", error);
      toast.error("Failed to save item");
    }
  };

  const handleAddOrUpdateLocation = async (location) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.companyId) {
        console.error("User or company information not found");
        return;
      }

      const locationWithCompany = { ...location, companyId: user.companyId };

      if (editingItem) {
        await jsonTypeAxios.put(
          `/locations/${editingItem._id}`,
          locationWithCompany
        );
        toast.success("Location updated successfully");
      } else {
        await jsonTypeAxios.post("/locations", locationWithCompany);
        toast.success("Location added successfully");
      }
      fetchLocations();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error adding/updating location:", error);
      toast.error("Failed to save location");
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await jsonTypeAxios.delete(`/items/${id}`);
      fetchItems();
      toast.success("Item deleted successfully");
      setDeleteDialogOpen(false);
      setItemToDelete(null);
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("Failed to delete item");
    }
  };

  const handleDeleteLocation = async (id) => {
    try {
      await jsonTypeAxios.delete(`/locations/${id}`);
      fetchLocations();
      toast.success("Location deleted successfully");
      setDeleteDialogOpen(false);
      setItemToDelete(null);
    } catch (error) {
      console.error("Error deleting location:", error);
      toast.error("Failed to delete location");
    }
  };

  // Add the delete confirmation dialog component:
  const DeleteConfirmationDialog = () => (
    <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Conferma eliminazione</DialogTitle>
          <DialogDescription>
            Sei sicuro di voler eliminare questo{" "}
            {isInventoryTab ? "prodotto" : "luogo di deposito"}? Questa azione
            non può essere annullata.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="mt-4">
          <Button
            variant="outline"
            onClick={() => {
              setDeleteDialogOpen(false);
              setItemToDelete(null);
            }}
          >
            Annulla
          </Button>
          <Button
            variant="destructive"
            onClick={() => {
              if (itemToDelete) {
                if (isInventoryTab) {
                  handleDeleteItem(itemToDelete._id);
                } else {
                  handleDeleteLocation(itemToDelete._id);
                }
              }
            }}
          >
            Elimina
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );

  const handleExport = (value) => {
    try {
      // Add null checks and provide default values
      const exportData = filteredItems.map((item) => ({
        name: item.name || "",
        code: item.code || "",
        description: item.description || "",
        quantity: item.quantity || 0,
        // Safely access location name with fallback
        locationName: item.location?.name || "Unknown Location"
      }));

      const columns = [
        { header: "Nome", key: "name", width: 1 },
        { header: "Codice", key: "code", width: 1 },
        { header: "Descrizione", key: "description", width: 1.5 },
        { header: "Quantità", key: "quantity", width: 0.8 },
        { header: "Luogo di deposito", key: "locationName", width: 1 }
      ];

      const currentDate = new Date().toLocaleDateString("it-IT", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      });

      // Format stats object properly for exportToPDF
      const stats = {
        left: [
          { label: "Totale Prodotti", value: filteredItems.length.toString() },
          { label: "Data", value: currentDate }
        ],
        right: [
          { label: "Cliente", value: "" } // Add client info if needed
        ]
      };

      // Calculate summaries
      const summaries = [
        {
          label: "Totale Quantità",
          value: filteredItems
            .reduce((sum, item) => sum + (item.quantity || 0), 0)
            .toString()
        }
      ];

      if (value === "excel") {
        exportToExcel(exportData, "Elenco magazzino");
      } else if (value === "pdf") {
        exportToPDF(
          exportData,
          columns,
          "Elenco magazzino",
          stats,
          undefined, // companyInfo parameter
          summaries
        );
      }

      toast.success(
        `Esportazione ${value.toUpperCase()} completata con successo`
      );
    } catch (error) {
      console.error("Errore durante l'esportazione:", error);
      toast.error(`Errore durante l'esportazione ${value.toUpperCase()}`);
    }
  };

  const handleFilterChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const applyFilters = () => {
    let result = [...items];

    if (filters.quantityRange && filters.quantityRange !== "all") {
      const [min, max] = filters.quantityRange.split("-").map(Number);
      result = result.filter((item) => {
        if (max) {
          return item.quantity >= min && item.quantity <= max;
        } else {
          return item.quantity >= min;
        }
      });
    }

    if (filters.storageLocation && filters.storageLocation !== "all") {
      result = result.filter(
        (item) => item.location === filters.storageLocation
      );
    }

    setFilteredItems(result);
    setIsFilterDialogOpen(false);
  };

  const resetFilters = () => {
    setFilters({
      quantityRange: "",
      storageLocation: ""
    });
    setFilteredItems(items);
    setIsFilterDialogOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    if (isInventoryTab) {
      fetchItems(page);
    } else {
      fetchLocations(page);
    }
  };

  return (
    <div className="p-6 space-y-6">
      <Tabs
        value={isInventoryTab ? "inventario" : "luoghi"}
        onValueChange={(value) => setIsInventoryTab(value === "inventario")}
      >
        <div className="border-b border-gray-200">
          <TabsList className="flex justify-start h-auto p-0 bg-transparent space-x-8">
            <TabsTrigger
              value="inventario"
              className="py-4 px-1 text-sm font-medium text-gray-500 hover:text-gray-700 relative data-[state=active]:text-slate-600 data-[state=active]:font-medium bg-transparent before:absolute before:h-0.5 before:w-full before:bg-slate-700 before:bottom-0 before:left-0 before:scale-x-0 data-[state=active]:before:scale-x-100 before:transition-transform"
            >
              Inventario
            </TabsTrigger>
            <TabsTrigger
              value="luoghi"
              className="py-4 px-1 text-sm font-medium text-gray-500 hover:text-gray-700 relative data-[state=active]:text-slate-600 data-[state=active]:font-medium bg-transparent before:absolute before:h-0.5 before:w-full before:bg-slate-700 before:bottom-0 before:left-0 before:scale-x-0 data-[state=active]:before:scale-x-100 before:transition-transform"
            >
              Luoghi di deposito
            </TabsTrigger>
          </TabsList>
        </div>
      </Tabs>
      <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-row sm:items-center">
          <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
            <DialogTrigger asChild>
              <Button
                onClick={() => setEditingItem(null)}
                className="w-full sm:w-auto"
              >
                Aggiungi
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px] w-[95vw] max-h-[90vh] overflow-y-auto">
              <DialogHeader>
                <DialogTitle>
                  {editingItem ? "Modifica" : "Aggiungi nuovo"}{" "}
                  {isInventoryTab ? "prodotto" : "luogo di deposito"}
                </DialogTitle>
              </DialogHeader>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const formData = new FormData(e.target);
                  const newItemOrLocation = Object.fromEntries(
                    formData.entries()
                  );
                  if (isInventoryTab) {
                    handleAddOrUpdateItem(newItemOrLocation);
                  } else {
                    handleAddOrUpdateLocation(newItemOrLocation);
                  }
                }}
              >
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-1 items-center gap-4">
                    <Label htmlFor="name" className="text-left">
                      Nome <span className="text-red-500">*</span>
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      defaultValue={editingItem?.name}
                      className="col-span-3"
                      required
                    />
                  </div>
                  {isInventoryTab && (
                    <>
                      <div className="grid grid-cols-1 items-center gap-4">
                        <Label htmlFor="code" className="text-left">
                          Codice <span className="text-red-500">*</span>
                        </Label>
                        <Input
                          id="code"
                          name="code"
                          defaultValue={editingItem?.code}
                          className="col-span-3"
                          required
                        />
                      </div>
                      <div className="grid grid-cols-1 items-center gap-4">
                        <Label htmlFor="description" className="text-left">
                          Descrizione
                        </Label>
                        <Textarea
                          id="description"
                          name="description"
                          defaultValue={editingItem?.description}
                          className="col-span-3"
                        />
                      </div>
                      <div className="grid grid-cols-1 items-center gap-4">
                        <Label htmlFor="quantity" className="text-left">
                          Quantità <span className="text-red-500">*</span>
                        </Label>
                        <Input
                          type="number"
                          id="quantity"
                          name="quantity"
                          defaultValue={editingItem?.quantity}
                          className="col-span-3"
                          required
                        />
                      </div>
                      <div className="grid grid-cols-1 items-center gap-4">
                        <Label htmlFor="location" className="text-left">
                          Luogo di deposito{" "}
                          <span className="text-red-500">*</span>
                        </Label>
                        <Select
                          name="location"
                          defaultValue={editingItem?.location}
                        >
                          <SelectTrigger className="col-span-3">
                            <SelectValue placeholder="Seleziona un luogo" />
                          </SelectTrigger>
                          <SelectContent>
                            {locations.map((location) => (
                              <SelectItem
                                key={location._id}
                                value={location._id}
                              >
                                {location.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </>
                  )}
                </div>
                <DialogFooter>
                  <Button type="submit">
                    {editingItem ? "Aggiorna" : "Aggiungi"}
                  </Button>
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>

          <Select onValueChange={handleExport}>
            <SelectTrigger className="w-full sm:w-[180px]">
              <SelectValue placeholder="Esporta" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="excel">Esporta in Excel</SelectItem>
              <SelectItem value="pdf">Esporta in PDF</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="flex justify-center gap-x-2 items-center">
          <SearchBar
            onSearch={debouncedSearch}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Dialog
            open={isFilterDialogOpen}
            onOpenChange={setIsFilterDialogOpen}
          >
            <DialogTrigger asChild>
              <Button variant="outline" className="w-full sm:w-auto">
                <Filter className="mr-2 h-4 w-4" /> Filtri
                {areFiltersActive() && (
                  <span className="ml-2 bg-red-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                    !
                  </span>
                )}
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px] w-[95vw]">
              <DialogHeader>
                <DialogTitle>Filtri</DialogTitle>
                <DialogDescription>
                  Applica filtri per la ricerca di prodotti.
                </DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4">
                <div className="grid grid-cols-1 items-center gap-4">
                  <Label htmlFor="quantityRange" className="text-left">
                    Quantità
                  </Label>
                  <Select
                    name="quantityRange"
                    value={filters.quantityRange}
                    onValueChange={(value) =>
                      handleFilterChange("quantityRange", value)
                    }
                  >
                    <SelectTrigger className="col-span-3">
                      <SelectValue placeholder="Seleziona intervallo" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">Tutti</SelectItem>
                      <SelectItem value="0-10">0 - 10</SelectItem>
                      <SelectItem value="11-50">11 - 50</SelectItem>
                      <SelectItem value="51-100">51 - 100</SelectItem>
                      <SelectItem value="101+">101+</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="grid grid-cols-1 items-center gap-4">
                  <Label htmlFor="storageLocation" className="text-left">
                    Luogo
                  </Label>
                  <Select
                    name="storageLocation"
                    value={filters.storageLocation}
                    onValueChange={(value) =>
                      handleFilterChange("storageLocation", value)
                    }
                  >
                    <SelectTrigger className="col-span-3">
                      <SelectValue placeholder="Seleziona luogo" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">Tutti</SelectItem>
                      {locations.map((location) => (
                        <SelectItem key={location._id} value={location._id}>
                          {location.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <DialogFooter>
                <Button variant="outline" onClick={resetFilters}>
                  Resetta
                </Button>
                <Button onClick={applyFilters}>Applica Filtri</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          {areFiltersActive() && (
            <Button variant="outline" onClick={resetFilters}>
              Cancella Filtri
            </Button>
          )}
          <HelpSheet route="/magazzino" />
        </div>
      </div>

      {error && <div className="text-red-500 text-center">{error}</div>}

      <div className="rounded-md border">
        <EnhancedTable
          items={isInventoryTab ? filteredItems : locations}
          sortConfig={sortConfig}
          onSortChange={setSortConfig}
          selectedItems={selectedItems}
          onSelectChange={setSelectedItems}
          selectAll={selectAll}
          onSelectAllChange={setSelectAll}
        >
          <TableHeader>
            <SelectAllTableHead />
            <SortableTableHead className="w-[100px]">ID</SortableTableHead>
            <SortableTableHead sortKey="name">Nome</SortableTableHead>
            {isInventoryTab && (
              <>
                <SortableTableHead sortKey="code">Codice</SortableTableHead>
                <SortableTableHead sortKey="description">
                  Descrizione
                </SortableTableHead>
                <SortableTableHead sortKey="quantity">
                  Quantità
                </SortableTableHead>
                <SortableTableHead sortKey="location">
                  Luogo di deposito
                </SortableTableHead>
              </>
            )}
            <SortableTableHead></SortableTableHead>
          </TableHeader>
          <TableBody>
            {isInventoryTab ? (
              filteredItems.length > 0 ? (
                filteredItems.map((item, index) => {
                  // Find the location name for this item
                  const locationObj = locations.find(
                    (loc) => loc._id === item.location
                  );
                  const locationName = locationObj
                    ? locationObj.name
                    : "Unknown Location";

                  return (
                    <SelectableTableRow key={item._id} item={item}>
                      <SelectionTableCell item={item} />
                      <TableCell className="font-medium">{index + 1}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.code}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>
                        {item.location?.name || locationName}
                      </TableCell>
                      <ActionsTableCell>
                        <DropdownMenuItem
                          onClick={() => {
                            setEditingItem(item);
                            setIsModalOpen(true);
                          }}
                          className="flex items-center"
                        >
                          <Edit className="h-4 w-4 mr-2" /> Modifica
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => {
                            setItemToDelete(item);
                            setDeleteDialogOpen(true);
                          }}
                          className="flex items-center"
                        >
                          <Trash2 className="h-4 w-4 text-red-500 mr-2" />{" "}
                          Elimina
                        </DropdownMenuItem>
                      </ActionsTableCell>
                    </SelectableTableRow>
                  );
                })
              ) : (
                <SelectableTableRow>
                  <TableCell colSpan={8} className="text-center py-4">
                    Nessun prodotto trovato. Aggiungi nuovi prodotti o modifica
                    i filtri.
                  </TableCell>
                </SelectableTableRow>
              )
            ) : locations.length > 0 ? (
              locations.map((location, index) => (
                <SelectableTableRow key={location._id} item={location}>
                  <SelectionTableCell item={location} />
                  <TableCell className="font-medium">{index + 1}</TableCell>
                  <TableCell>{location.name}</TableCell>
                  <ActionsTableCell>
                    <DropdownMenuItem
                      onClick={() => {
                        setEditingItem(location);
                        setIsModalOpen(true);
                      }}
                      className="flex items-center"
                    >
                      <Edit className="h-4 w-4 mr-2" /> Modifica
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => {
                        setItemToDelete(location);
                        setDeleteDialogOpen(true);
                      }}
                      className="flex items-center"
                    >
                      <Trash2 className="h-4 w-4 text-red-500 mr-2" /> Elimina
                    </DropdownMenuItem>
                  </ActionsTableCell>
                </SelectableTableRow>
              ))
            ) : (
              <SelectableTableRow>
                <TableCell colSpan={3} className="text-center">
                  Nessun dato trovato
                </TableCell>
              </SelectableTableRow>
            )}
          </TableBody>
        </EnhancedTable>
      </div>

      <DeleteConfirmationDialog />

      {(isInventoryTab ? filteredItems : locations).length > 0 && (
        <div className="flex justify-end mt-4">
          <Pagination
            totalPages={totalPages}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
          />
        </div>
      )}
    </div>
  );
}

export default TableComponent;
