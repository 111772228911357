import React, { useEffect, useState } from "react";
import { Loader2 } from "lucide-react";
import { Button } from "../ui/button";
import Modal from "react-modal";
import { Input } from "../../components/ui/input";
import BankSelectionModal from "./bankSectionModal";
import PaymentModal from "./paymentModal";
import { Card, CardContent } from "../ui/card";
import {
  createAggreement,
  // createPayment,
  createRequistition,
  disconnectBankAccount,
  fetchBankAccounts,
  fetchCountryBanks,
  // fetchMandateId,
  getAllBalances,
} from "../../../src/apis/PaymentMethods";
import { toast } from "react-toastify";
import Spinner from "../Spinner";

Modal.setAppElement("#root");
const user = JSON.parse(localStorage.getItem("user") || "{}");
const userId = user?.id || "";
const BankAccountsList = () => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [sessionToken, setSessionToken] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [mandateId, setMandateId] = useState("");
  const [accountBalances, setAccountBalances] = useState(0);
  // Fetch linked bank accounts from backend
  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    setLoading(true);
    try {
      const response = await fetchBankAccounts(userId);

      const accountIds = response?.data?.connectedAccounts?.map(
        (account) => account.accountId
      );
      const balances = await getAllBalances(accountIds);
      setAccountBalances(balances?.data?.totalInterimAvailable);
      setAccounts(response?.data?.connectedAccounts);
    } catch (error) {
      console.error("Error fetching bank accounts:", error);
      toast.error("Error fetching accounts, please try again later");
    }
    setLoading(false);
  };
  const connectBankAccount = async (bankId, bankName) => {
    setLoading(true);

    try {
      // Step 1: Create agreement
      const agreementResponse = await createAggreement(bankId, sessionToken);

      const agreementId = agreementResponse?.data?.agreement?.id;
      // Step 2: Create requisition
      const requisitionResponse = await createRequistition(
        bankId,
        sessionToken,
        agreementId,
        userId,
        bankName
      );

      localStorage.setItem(
        "requisitionId",
        requisitionResponse?.data?.requisitionId
      );
      setLoading(false);
      // Redirect user to bank authentication page
      window.location.href = requisitionResponse.data.authLink;
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const fetchBanks = async () => {
    setLoading(true);
    try {
      const response = await fetchCountryBanks("it"); //For Italy , we can change this
      setBanks(response?.data?.institutions);
      setSessionToken(response?.data?.sessionToken);
      localStorage.setItem("sessionToken", response?.data?.sessionToken);
      setModalIsOpen(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching banks:", error);
      toast.error("Error fetching banks, please try again later");
    }
  };

  // const handleBankClick = async (customerAccountId, requisitionId) => {
  //   setLoading(true);
  //   try {
  //     const response = await fetchMandateId(customerAccountId, requisitionId);
  //     const retrievedMandateId =
  //       response?.data && response?.data?.length && response?.data[0]?.id;

  //     if (!retrievedMandateId) throw new Error("Mandate ID not found");

  //     setMandateId(retrievedMandateId);
  //     setPaymentModalOpen(true);
  //   } catch (error) {
  //     toast.error("Error fetching mandate, please try again later");
  //   }
  //   setLoading(false);
  // };

  const handleDisconnect = async (requisitionId) => {
    try {
      setLoading(true);
      const disconnect = await disconnectBankAccount(requisitionId, userId);
      toast.success("Bank account disconnected successfully ");
      const currentAccounts = accounts?.filter(
        (account) => account?.requisitionId !== requisitionId
      );
      setAccounts(currentAccounts);
      setLoading(false);
      return disconnect;
    } catch (error) {
      setLoading(false);
      toast.error("Error disconnecting Bank Account");
    }
  };

  // const handlePaymentSubmit = async () => {
  //   if (!paymentAmount || !paymentDescription) {
  //     toast.error("Please fill all payment fields");
  //     return;
  //   }

  //   setLoading(true);
  //   try {
  //     const paymentData = {
  //       mandateId,
  //       userId,
  //       amount: paymentAmount,
  //       description: paymentDescription,
  //     };
  //     await createPayment(paymentData);
  //     toast.success("Payment created successfully!");
  //     setPaymentModalOpen(false);
  //   } catch (error) {
  //     toast.error("Error creating payment");
  //   }
  //   setLoading(false);
  // };
  const handleSearch = (searchValue) => {
    setSearchQuery(searchValue);
    const sortedBanks = [...banks].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      const searchLower = searchValue.toLowerCase();

      const startsWithA = nameA.startsWith(searchLower);
      const startsWithB = nameB.startsWith(searchLower);

      if (startsWithA && !startsWithB) return -1;
      if (!startsWithA && startsWithB) return 1;

      return nameA.localeCompare(nameB);
    });
    setBanks(sortedBanks);
  };

  return (
    <div className="max-w-4xl p-6 space-y-6">
      {/* Balance Card */}
      <div className="bg-gray-100 p-6 rounded-lg max-w-md shadow-md ">
        <h3 className="text-lg font-semibold text-gray-700">
          Saldo totale dei tuoi conti bancari
        </h3>
        <p className="text-3xl font-bold text-gray-900">€{accountBalances}</p>
      </div>

      {/* Connect a New Bank Account Button */}
      <div className="flex justify-start">
        <Button onClick={fetchBanks} disabled={loading}>
          Collega un nuovo conto
        </Button>
      </div>

      {/* Show Spinner When Loading */}
      {loading && (
        <div className="flex justify-center">
          <Spinner />
        </div>
      )}

      {/* No Account Message */}
      {!loading && accounts?.length === 0 && (
        <div className="text-gray-500 text-center">
          Nessun account collegato.
        </div>
      )}

      {/* Bank Accounts List */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {accounts?.length > 0 &&
          accounts.map((account) => (
            <div
              key={account.id}
              className="bg-white p-4 flex flex-col justify-between rounded-lg shadow-md border"
            >
              <div>
                <p className="text-lg font-medium">{account.institutionName}</p>
                <p className="text-sm text-gray-500">
                  ••••{account.iban.slice(-4)}
                </p>
              </div>
              <div className="flex justify-between">
                <p
                  className={`text-sm font-medium ${
                    account.status === "LN"
                      ? "text-green-600"
                      : account.status === "EX"
                      ? "text-gray-500"
                      : account.status === "RJ"
                      ? "text-red-600"
                      : "text-yellow-600"
                  }`}
                >
                  {account.status === "LN"
                    ? "Connesso"
                    : account.status === "EX"
                    ? "Scaduto"
                    : account.status === "RJ"
                    ? "Respinto"
                    : "In attesa"}
                </p>

                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDisconnect(account?.requisitionId);
                  }}
                  className="bg-red-900 text-white px-4 py-2 mt-2 rounded-md"
                >
                  Disconnetti
                </Button>
              </div>
            </div>
          ))}
      </div>

      {/* Modals */}
      <BankSelectionModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        banks={banks}
        connectBankAccount={connectBankAccount}
        loading={loading}
      />

      {/* <PaymentModal
        isOpen={paymentModalOpen}
        onClose={() => setPaymentModalOpen(false)}
        paymentAmount={paymentAmount}
        setPaymentAmount={setPaymentAmount}
        paymentDescription={paymentDescription}
        setPaymentDescription={setPaymentDescription}
        // handlePaymentSubmit={handlePaymentSubmit}
        loading={loading}
      /> */}
    </div>
  );
};

export default BankAccountsList;
