import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { 
  FileText, Plus, Loader2, Trash, Edit, 
  MoreHorizontal, ChevronUp, ChevronDown, SortAsc, SortDesc 
} from "lucide-react";
import { Button } from "../../ui/button";
import { Checkbox } from "../../ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "../../ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import { toast } from "react-toastify";
import {
  getUserDocuments,
  getDocumentTemplates,
  createDocument,
  deleteDocument
} from "../../../apis/Documents";
import { getConstructionSites } from "../../../apis/ConstructionSite";

// Create a React context for the enhanced table features
const EnhancedTableContext = React.createContext({
  sortConfig: { key: null, direction: null },
  requestSort: () => {},
  selectedItems: {},
  selectItem: () => {},
  selectAll: false,
  handleSelectAll: () => {},
  items: []
});

// Wrapper component for enhanced table functionality
const EnhancedTable = ({ 
  children, 
  items = [], 
  sortConfig = { key: null, direction: null }, 
  onSortChange,
  selectedItems = {},
  onSelectChange,
  selectAll = false,
  onSelectAllChange,
  ...props 
}) => {
  const [internalSortConfig, setInternalSortConfig] = useState({ key: null, direction: null });
  const [internalSelectedItems, setInternalSelectedItems] = useState({});
  const [internalSelectAll, setInternalSelectAll] = useState(false);
  
  const actualSortConfig = sortConfig || internalSortConfig;
  const actualSelectedItems = selectedItems || internalSelectedItems;
  const actualSelectAll = selectAll || internalSelectAll;
  
  const requestSort = (key) => {
    let direction = 'ascending';
    if (actualSortConfig.key === key && actualSortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    
    const newSortConfig = { key, direction };
    
    if (onSortChange) {
      onSortChange(newSortConfig);
    } else {
      setInternalSortConfig(newSortConfig);
    }
  };
  
  const selectItem = (id, checked) => {
    const newSelectedItems = { ...actualSelectedItems, [id]: checked };
    
    if (onSelectChange) {
      onSelectChange(newSelectedItems);
    } else {
      setInternalSelectedItems(newSelectedItems);
    }
    
    const allSelected = items.length > 0 && 
      items.every(item => newSelectedItems[item.id || item._id]);
    
    if (onSelectAllChange) {
      onSelectAllChange(allSelected);
    } else {
      setInternalSelectAll(allSelected);
    }
  };
  
  const handleSelectAll = (checked) => {
    const newSelectedItems = {};
    
    if (checked) {
      items.forEach(item => {
        const itemId = item.id || item._id;
        if (itemId) newSelectedItems[itemId] = true;
      });
    }
    
    if (onSelectChange) {
      onSelectChange(newSelectedItems);
    } else {
      setInternalSelectedItems(newSelectedItems);
    }
    
    if (onSelectAllChange) {
      onSelectAllChange(checked);
    } else {
      setInternalSelectAll(checked);
    }
  };
  
  return (
    <EnhancedTableContext.Provider
      value={{
        sortConfig: actualSortConfig,
        requestSort,
        selectedItems: actualSelectedItems,
        selectItem,
        selectAll: actualSelectAll,
        handleSelectAll,
        items
      }}
    >
      <Table {...props}>{children}</Table>
    </EnhancedTableContext.Provider>
  );
};

// Hook to use the enhanced table context
const useEnhancedTable = () => React.useContext(EnhancedTableContext);

// Enhanced TableHead component with sorting capability
const SortableTableHead = ({ children, sortKey, ...props }) => {
  const { sortConfig, requestSort } = useEnhancedTable();
  
  const getSortIcon = () => {
    if (!sortKey) return null;
    
    if (sortConfig.key !== sortKey) {
      return (
        <div className="ml-2 inline-block">
          <ChevronUp className="h-3 w-3 opacity-50" />
          <ChevronDown className="h-3 w-3 -mt-1 opacity-50" />
        </div>
      );
    }
    return sortConfig.direction === 'ascending' ? (
      <SortAsc className="ml-2 h-4 w-4 inline-block" />
    ) : (
      <SortDesc className="ml-2 h-4 w-4 inline-block" />
    );
  };

  return (
    <TableHead 
      className={sortKey ? "cursor-pointer" : ""} 
      onClick={() => sortKey && requestSort(sortKey)}
      {...props}
    >
      {children}
      {getSortIcon()}
    </TableHead>
  );
};

// Select All checkbox for table header
const SelectAllTableHead = (props) => {
  const { selectAll, handleSelectAll, items } = useEnhancedTable();
  
  return (
    <TableHead className="w-12" {...props}>
      {items.length > 0 && (
        <Checkbox 
          checked={selectAll}
          onCheckedChange={handleSelectAll}
          aria-label="Select all"
        />
      )}
    </TableHead>
  );
};

// Checkbox cell for row selection
const SelectionTableCell = ({ item, ...props }) => {
  const { selectedItems, selectItem } = useEnhancedTable();
  const itemId = item?.id || item?._id;
  
  return (
    <TableCell {...props}>
      {itemId && (
        <Checkbox 
          checked={!!selectedItems[itemId]}
          onCheckedChange={(checked) => selectItem(itemId, checked)}
          aria-label={`Select row ${itemId}`}
        />
      )}
    </TableCell>
  );
};

// Dropdown menu for actions
const ActionsTableCell = ({ children, ...props }) => {
  return (
    <TableCell {...props}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm">
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {children}
        </DropdownMenuContent>
      </DropdownMenu>
    </TableCell>
  );
};

export default function UserDocuments() {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [templates, setTemplates] = useState([]);
  const [sites, setSites] = useState([]);
  const [userDocuments, setUserDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  useEffect(() => {
    fetchInitialData();
  }, []);

  // Apply sorting to documents when sortConfig changes
  useEffect(() => {
    if (sortConfig.key && userDocuments.length > 0) {
      const sortedDocuments = [...userDocuments].sort((a, b) => {
        let aValue, bValue;
        
        // Handle special cases
        if (sortConfig.key === 'createdAt') {
          aValue = new Date(a.createdAt).getTime();
          bValue = new Date(b.createdAt).getTime();
        } else if (sortConfig.key === 'constructionSite') {
          aValue = a.constructionSite?.name || '';
          bValue = b.constructionSite?.name || '';
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }
        
        // Handle string comparison
        if (typeof aValue === 'string') {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }
        
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
      
      setUserDocuments(sortedDocuments);
    }
  }, [sortConfig]);

  const fetchInitialData = async () => {
    try {
      setIsLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.companyId) {
        toast.error("Informazioni utente non trovate");
        navigate("/login");
        return;
      }

      const [templatesData, sitesResponse, documentsData] = await Promise.all([
        getDocumentTemplates(),
        getConstructionSites(user.companyId),
        getUserDocuments()
      ]);

      setTemplates(templatesData || []);
      setSites(sitesResponse.data.sites || []);
      setUserDocuments(documentsData || []);
    } catch (error) {
      toast.error(error.message || "Errore nel caricamento dei dati");
      console.error("Error loading initial data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateDocument = async () => {
    if (!selectedTemplate || !documentName || !selectedSite) {
      toast.error("Compila tutti i campi richiesti");
      return;
    }

    try {
      setIsCreating(true);
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.companyId) {
        toast.error("Informazioni utente non trovate");
        navigate("/login");
        return;
      }

      const data = await createDocument({
        templateId: selectedTemplate,
        name: documentName,
        constructionSiteId: selectedSite,
        status: "draft"
      });

      toast.success("Documento creato con successo");
      setIsCreateModalOpen(false);
      resetForm();
      navigate(`/documenti/${data._id}/edit`);
    } catch (error) {
      toast.error(error.message || "Errore nella creazione del documento");
      console.error("Error creating document:", error);
    } finally {
      setIsCreating(false);
    }
  };

  const handleDeleteClick = (doc) => {
    setDocumentToDelete(doc);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      setIsDeleting(true);
      await deleteDocument(documentToDelete._id);
      toast.success("Documento eliminato con successo");
      fetchInitialData();
      setIsDeleteModalOpen(false);
      setDocumentToDelete(null);
    } catch (error) {
      toast.error(
        error.message || "Errore durante l'eliminazione del documento"
      );
    } finally {
      setIsDeleting(false);
    }
  };

  const resetForm = () => {
    setDocumentName("");
    setSelectedTemplate("");
    setSelectedSite("");
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="mx-auto p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">I miei documenti</h1>
        <Button onClick={() => setIsCreateModalOpen(true)}>
          Crea documento
        </Button>
      </div>
      <div className="rounded-md border">
        <EnhancedTable 
          items={userDocuments}
          sortConfig={sortConfig}
          onSortChange={setSortConfig}
          selectedItems={selectedItems}
          onSelectChange={setSelectedItems}
          selectAll={selectAll}
          onSelectAllChange={setSelectAll}
        >
          <TableHeader>
            <TableRow>
              <SelectAllTableHead />
              <SortableTableHead sortKey="id">#</SortableTableHead>
              <SortableTableHead sortKey="name">Nome documento</SortableTableHead>
              <SortableTableHead sortKey="type">Tipo</SortableTableHead>
              <SortableTableHead sortKey="constructionSite">Cantiere</SortableTableHead>
              <SortableTableHead sortKey="status">Stato</SortableTableHead>
              <SortableTableHead sortKey="createdAt">Data creazione</SortableTableHead>
              <TableHead>Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {userDocuments.map((doc, index) => (
              <TableRow key={doc._id}>
                <SelectionTableCell item={doc} />
                <TableCell>{index + 1}</TableCell>
                <TableCell>{doc.name}</TableCell>
                <TableCell>{doc.type}</TableCell>
                <TableCell>{doc.constructionSite?.name}</TableCell>
                <TableCell>
                  <span
                    className={`px-2 py-1 rounded-full text-sm ${
                      doc.status === "draft"
                        ? "bg-yellow-100 text-yellow-800"
                        : "bg-green-100 text-green-800"
                    }`}
                  >
                    {doc.status === "draft" ? "Bozza" : "Completato"}
                  </span>
                </TableCell>
                <TableCell>
                  {new Date(doc.createdAt).toLocaleDateString("it-IT")}
                </TableCell>
                <ActionsTableCell>
                  <DropdownMenuItem onClick={() => navigate(`/documenti/${doc._id}/edit`)} className="flex items-center">
                    <FileText className="h-4 w-4 mr-2" /> Modifica
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => handleDeleteClick(doc)} className="flex items-center">
                    <Trash className="h-4 w-4 text-red-500 mr-2" /> Elimina
                  </DropdownMenuItem>
                </ActionsTableCell>
              </TableRow>
            ))}
            {userDocuments.length === 0 && (
              <TableRow>
                <TableCell colSpan={8} className="text-center py-4">
                  Nessun documento trovato
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </EnhancedTable>
      </div>
      <Dialog
        open={isCreateModalOpen}
        onOpenChange={(open) => {
          if (!open) resetForm();
          setIsCreateModalOpen(open);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Crea nuovo documento</DialogTitle>
            <DialogDescription>
              Seleziona il tipo di documento e il cantiere per iniziare
            </DialogDescription>
          </DialogHeader>

          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="documentName">Nome documento</Label>
              <Input
                id="documentName"
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
                placeholder="Inserisci il nome del documento"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="template">Tipo documento</Label>
              <Select
                onValueChange={setSelectedTemplate}
                value={selectedTemplate}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona tipo documento" />
                </SelectTrigger>
                <SelectContent>
                  {templates.map((template) => (
                    <SelectItem key={template._id} value={template._id}>
                      {template.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="site">Cantiere</Label>
              <Select onValueChange={setSelectedSite} value={selectedSite}>
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona cantiere" />
                </SelectTrigger>
                <SelectContent>
                  {sites.map((site) => (
                    <SelectItem key={site._id} value={site._id}>
                      {site.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsCreateModalOpen(false)}
            >
              Annulla
            </Button>
            <Button onClick={handleCreateDocument} disabled={isCreating}>
              {isCreating ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Creazione...
                </>
              ) : (
                "Genera documento"
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conferma eliminazione</DialogTitle>
            <DialogDescription>
              Sei sicuro di voler eliminare il documento "
              {documentToDelete?.name}"?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setIsDeleteModalOpen(false);
                setDocumentToDelete(null);
              }}
            >
              Annulla
            </Button>
            <Button
              variant="destructive"
              onClick={handleDeleteConfirm}
              disabled={isDeleting}
            >
              {isDeleting ? "Eliminazione..." : "Elimina"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}