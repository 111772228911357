import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import {
  fetchClients,
  deleteClient,
  importClientList,
  addClient,
  updateClient,
  getSingleClient
} from "../../../../apis/client";
import { exportToExcel, exportToPDF } from "../../../../config/helper";
import { useForm } from "react-hook-form";
import { Eye, Edit, Trash2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import {
  EnhancedTable,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "../../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../../ui/select";
import { DropdownMenuItem } from "../../../ui/dropdown-menu";
import { Card, CardContent } from "../../../ui/card";
import { Label } from "../../../ui/label";
import { Textarea } from "../../../ui/textarea";
import { ScrollArea } from "../../../ui/scroll-area";

import SearchBar from "../../../../components/admin/sharedComponent/SearchBar";
import ImportFile from "../../sharedComponent/ImportFile";
import Pagination from "../../sharedComponent/Pgination";
import FilterModal from "./FilterModal";
import HelpSheet from "../../sharedComponent/HelpSheet";

const deleteContent = {
  title: "Elimina Cliente",
  description:
    "Sei sicuro di voler eliminare questo cliente? Questa azione è irreversibile."
};

const Client = ({ employeeData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [dataUpdate, setDataUpdate] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;
  const [clientsList, setClientsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();

  // Add enhanced table state
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      fullName: "",
      vatNumber: "",
      taxId: "",
      billingCode: "",
      address: "",
      city: "",
      postalCode: "",
      province: "",
      nation: "",
      email: "",
      pec: "",
      telephone: "",
      website: "",
      note: ""
    }
  });

  const GetALLClients = async () => {
    setIsLoading(true);
    const filterObj = {
      typology: filterValue
    };
    const filterString = JSON.stringify(filterObj);
    try {
      const response = await fetchClients(
        companyId,
        currentPage,
        limit,
        searchQuery,
        filterString
      );
      if (response.status === 200) {
        // Add id property for selection tracking
        const clientsWithIds = (response.data?.clients || []).map((client) => ({
          ...client,
          id: client._id // Ensure we have an id field for selection
        }));
        setClientsList(clientsWithIds);
        setTotalPage(response.data?.totalPages);
        setCurrentPage(response.data?.currentPage);
      }
    } catch (error) {
      console.error(error);
      toast.error("Errore nel caricamento dei clienti");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetALLClients();
  }, [dataUpdate, currentPage, limit, searchQuery, filterValue]);

  // Sort clients based on sortConfig
  const sortedClients = useMemo(() => {
    if (!sortConfig.key) return clientsList;

    return [...clientsList].sort((a, b) => {
      if (!a[sortConfig.key] && !b[sortConfig.key]) return 0;
      if (!a[sortConfig.key]) return 1;
      if (!b[sortConfig.key]) return -1;

      const valA = typeof a[sortConfig.key] === 'string' ? a[sortConfig.key].toLowerCase() : a[sortConfig.key];
      const valB = typeof b[sortConfig.key] === 'string' ? b[sortConfig.key].toLowerCase() : b[sortConfig.key];

      if (valA < valB) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (valA > valB) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }, [clientsList, sortConfig]);

  const handleDeleteModal = (id) => {
    setSelectedId(id);
    setDeleteModal(true);
  };

  const handleDeleteClient = async () => {
    setIsSubmitting(true);
    try {
      const response = await deleteClient(selectedId);
      if (response.status) {
        toast.success(response.data.message);
        setDeleteModal(false);
        setDataUpdate(!dataUpdate);
      }
    } catch (error) {
      console.error(error);
      toast.error("Errore durante l'eliminazione del cliente");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleExport = (value) => {
    if (!value) return;

    const columns = [
      { header: "Id", key: "id" },
      { header: "Nome/Regione", key: "fullName" },
      { header: "Indirizzo", key: "address" },
      { header: "Email", key: "email" },
      { header: "Telefono", key: "telephone" }
    ];

    if (value === "excel") {
      exportToExcel(clientsList, "clienti");
    } else if (value === "pdf") {
      exportToPDF(clientsList, columns, "Elenco Clienti");
    }
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const importClient = async (fileData) => {
    setIsSubmitting(true);
    try {
      let payload = {
        companyId: companyId,
        clients: fileData
      };
      const response = await importClientList(payload);
      if (response.status === 201) {
        toast.success("Clienti importati con successo!");
        setDataUpdate(!dataUpdate);
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Errore durante l'importazione dei clienti");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditClient = async (id) => {
    setSelectedId(id);
    setIsEditing(true);
    setIsLoading(true);
    try {
      const response = await getSingleClient(id);
      if (response.status === 200) {
        const clientData = response.data.client;
        Object.keys(clientData).forEach((key) => {
          setValue(key, clientData[key]);
        });
        setIsAddClientModalOpen(true);
      }
    } catch (error) {
      console.error(error);
      toast.error("Impossibile recuperare i dati del cliente");
    } finally {
      setIsLoading(false);
    }
  };

  const onAddClientModalOpen = () => {
    reset();
    setIsEditing(false);
    setIsAddClientModalOpen(true);
  };

  const onAddClientModalClose = () => {
    if (isSubmitting) return;
    reset();
    setIsEditing(false);
    setIsAddClientModalOpen(false);
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const payload = {
        ...data,
        companyId: companyId
      };
      let response;
      if (isEditing) {
        response = await updateClient(selectedId, payload);
      } else {
        response = await addClient(payload);
      }
      if (response.status === 200 || response.status === 201) {
        toast.success(response.data.message);
        onAddClientModalClose();
        setDataUpdate(!dataUpdate);
      }
    } catch (error) {
      console.error(error);
      const errorMessage =
        error?.message ||
        `Impossibile ${isEditing ? "aggiornare" : "aggiungere"} il cliente`;
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card className="shadow-none border-0">
      <CardContent>
        <div className="sm:flex justify-between items-center space-y-4 sm:space-y-0 my-7">
          <div className="flex flex-wrap items-center gap-2">
            <Button
              onClick={onAddClientModalOpen}
              className="flex-grow sm:flex-grow-0"
              disabled={isLoading}
            >
              Aggiungi
            </Button>
            <Button
              onClick={() => setIsModalOpen(true)}
              variant="secondary"
              className="flex-grow sm:flex-grow-0"
              disabled={isLoading}
            >
              Importa
            </Button>
            <Select
              onValueChange={handleExport}
              disabled={isLoading || clientsList.length === 0}
            >
              <SelectTrigger className="w-full sm:w-[180px]">
                <SelectValue placeholder="Esporta" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="excel">Esporta in Excel</SelectItem>
                <SelectItem value="pdf">Esporta in PDF</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="flex flex-wrap items-center gap-2 mt-4 sm:mt-0">
            <SearchBar
              onSearch={handleSearch}
              className="w-full sm:w-auto sm:flex-grow"
              disabled={isLoading}
            />
            <HelpSheet route="/clients" />
          </div>
        </div>

        <div className="rounded-md border">
          <EnhancedTable
            items={sortedClients}
            sortConfig={sortConfig}
            onSortChange={setSortConfig}
            selectedItems={selectedItems}
            onSelectChange={setSelectedItems}
            selectAll={selectAll}
            onSelectAllChange={setSelectAll}
          >
            <TableHeader>
              <TableRow>
                <SelectAllTableHead />
                <SortableTableHead>Id</SortableTableHead>
                <SortableTableHead sortKey="fullName">
                  Ragione sociale
                </SortableTableHead>
                <SortableTableHead sortKey="address">
                  Indirizzo
                </SortableTableHead>
                <SortableTableHead sortKey="email">Email</SortableTableHead>
                <SortableTableHead sortKey="telephone">
                  Telefono
                </SortableTableHead>
                <SortableTableHead></SortableTableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={7} className="text-center py-8">
                    Caricamento...
                  </TableCell>
                </TableRow>
              ) : sortedClients.length > 0 ? (
                sortedClients.map((data, index) => (
                  <SelectableTableRow key={data._id} item={data}>
                    <SelectionTableCell item={data} />
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{data.fullName}</TableCell>
                    <TableCell>{data.address}</TableCell>
                    <TableCell>{data.email}</TableCell>
                    <TableCell>{data.telephone}</TableCell>
                    <ActionsTableCell>
                      <DropdownMenuItem
                        onClick={() => navigate(`/clients/${data._id}`)}
                      >
                        <Eye className="h-4 w-4 mr-2" /> Visualizza dettagli
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => handleEditClient(data._id)}
                        disabled={isLoading}
                      >
                        <Edit className="h-4 w-4 mr-2" /> Modifica
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => handleDeleteModal(data._id)}
                        disabled={isLoading}
                        className="text-red-600"
                      >
                        <Trash2 className="h-4 w-4 mr-2" /> Elimina
                      </DropdownMenuItem>
                    </ActionsTableCell>
                  </SelectableTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} className="text-center py-8">
                    Nessun dato
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </EnhancedTable>
        </div>

        {clientsList.length > 0 && (
          <div className="mt-4 flex justify-end">
            <Pagination
              totalPages={totalPages}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              limit={limit}
              setLimit={setLimit}
            />
          </div>
        )}
      </CardContent>

      <Dialog open={deleteModal} onOpenChange={setDeleteModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{deleteContent.title}</DialogTitle>
            <DialogDescription>{deleteContent.description}</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setDeleteModal(false)}
              disabled={isSubmitting}
            >
              Annulla
            </Button>
            <Button
              variant="destructive"
              onClick={handleDeleteClient}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Eliminazione..." : "Elimina"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <ImportFile
        open={isModalOpen}
        onOpenChange={setIsModalOpen}
        importFunction={importClient}
        vendorType="Clienti"
        isLoading={isSubmitting}
      />

      <Dialog
        open={isAddClientModalOpen}
        onOpenChange={(open) => {
          if (!open && !isSubmitting) {
            onAddClientModalClose();
          }
        }}
      >
        <DialogContent className="max-w-4xl max-h-[90vh]">
          <DialogHeader>
            <DialogTitle>
              {isEditing ? "Modifica Cliente" : "Aggiungi Nuovo Cliente"}
            </DialogTitle>
            <DialogDescription>
              {isEditing
                ? "Modifica i dettagli del cliente."
                : "Inserisci i dettagli per aggiungere un nuovo cliente."}
            </DialogDescription>
          </DialogHeader>
          <ScrollArea className="max-h-[calc(90vh-200px)] overflow-y-auto">
            <form onSubmit={handleSubmit(onSubmit)} className="px-1">
              <div className="grid gap-4 py-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label htmlFor="fullName">
                      Ragione Sociale{" "}
                      <span className="text-red-500 ml-1">*</span>
                    </Label>
                    <Input
                      id="fullName"
                      {...register("fullName", {
                        required: "Ragione Sociale è obbligatoria"
                      })}
                      placeholder="Inserisci la ragione sociale"
                      className={errors.fullName ? "border-red-500" : ""}
                    />
                    {errors.fullName && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.fullName.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <Label htmlFor="vatNumber">Partita IVA</Label>
                    <Input
                      id="vatNumber"
                      {...register("vatNumber")}
                      placeholder="IT12345678901"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label htmlFor="taxId">Codice Fiscale</Label>
                    <Input
                      id="taxId"
                      {...register("taxId")}
                      placeholder="RSSMRA80A01H501U"
                    />
                  </div>
                  <div>
                    <Label htmlFor="billingCode">
                      Codice Univoco di Fatturazione
                    </Label>
                    <Input
                      id="billingCode"
                      {...register("billingCode")}
                      placeholder="ABCDEF1"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label htmlFor="address">Indirizzo</Label>
                    <Input
                      id="address"
                      {...register("address")}
                      placeholder="Via Roma, 1"
                    />
                  </div>
                  <div>
                    <Label htmlFor="city">Città</Label>
                    <Input id="city" {...register("city")} placeholder="Roma" />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <Label htmlFor="postalCode">CAP</Label>
                    <Input
                      id="postalCode"
                      {...register("postalCode")}
                      placeholder="00100"
                    />
                  </div>
                  <div>
                    <Label htmlFor="province">Provincia</Label>
                    <Input
                      id="province"
                      {...register("province")}
                      placeholder="RM"
                    />
                  </div>
                  <div>
                    <Label htmlFor="nation">Nazione</Label>
                    <Input
                      id="nation"
                      {...register("nation")}
                      placeholder="Italia"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label htmlFor="email">Email</Label>
                    <Input
                      id="email"
                      type="email"
                      {...register("email", {
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Indirizzo email non valido"
                        }
                      })}
                      placeholder="info@esempio.com"
                      className={errors.email ? "border-red-500" : ""}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <Label htmlFor="pec">PEC</Label>
                    <Input
                      id="pec"
                      type="email"
                      {...register("pec", {
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Indirizzo PEC non valido"
                        }
                      })}
                      placeholder="pec@esempio.it"
                      className={errors.pec ? "border-red-500" : ""}
                    />
                    {errors.pec && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.pec.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label htmlFor="telephone">Telefono</Label>
                    <Input
                      id="telephone"
                      {...register("telephone")}
                      placeholder="+39 06 1234567"
                    />
                  </div>
                  <div>
                    <Label htmlFor="website">Sito Web</Label>
                    <Input
                      id="website"
                      {...register("website")}
                      placeholder="https://www.esempio.it"
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="note">Note</Label>
                  <Textarea
                    id="note"
                    {...register("note")}
                    placeholder="Note aggiuntive..."
                  />
                </div>
              </div>
            </form>
          </ScrollArea>
          <DialogFooter>
            <Button
              type="button"
              variant="outline"
              onClick={onAddClientModalClose}
              disabled={isSubmitting}
            >
              Annulla
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitting}
            >
              {isSubmitting
                ? isEditing
                  ? "Aggiornamento..."
                  : "Aggiunta..."
                : isEditing
                ? "Aggiorna"
                : "Aggiungi"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default Client;