import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import CronoprogrammaComponent from '../../../components/admin/cantieri/cronoprogramma'

const Cronoprogramma = () => {
  return (
    <Layout>
      <CronoprogrammaComponent />
    </Layout>
  )
}

export default Cronoprogramma