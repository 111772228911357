import React, { useEffect, useState, useMemo } from "react";
import { Edit, Trash2 } from "lucide-react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { it } from "date-fns/locale";

import {
  GetCompanyLeaves,
  GetSingleLeaves,
  deleteLeave
} from "../../../../../apis/leave";

import { Button } from "../../../../ui/button";
import {
  EnhancedTable,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "../../../../ui/dialog";
import { DropdownMenuItem } from "../../../../ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../../../../ui/tooltip";
import AddAbsence from "./AddAbsence";
import SearchBar from "../../../sharedComponent/SearchBar";
import HelpSheet from "../../../sharedComponent/HelpSheet";

const Absence = ({ employeeData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;
  const [leaveData, setLeaveData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [singleLeaveData, setSingleLeaveData] = useState({});
  const [dataUpdate, setDataUpdate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // Add enhanced table state
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  // Create sortedData memoized function
  const sortedData = useMemo(() => {
    if (!leaveData || !sortConfig.key) return leaveData;
    
    return [...leaveData].sort((a, b) => {
      // Handle nested properties like employeeId.firstName
      if (sortConfig.key.includes('.')) {
        const keys = sortConfig.key.split('.');
        let aValue = a;
        let bValue = b;
        
        // Navigate through nested objects
        for (const key of keys) {
          aValue = aValue?.[key] || '';
          bValue = bValue?.[key] || '';
        }
        
        // Compare the values
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortConfig.direction === 'ascending' 
            ? aValue.localeCompare(bValue) 
            : bValue.localeCompare(aValue);
        }
        
        return sortConfig.direction === 'ascending' 
          ? (aValue > bValue ? 1 : -1) 
          : (bValue > aValue ? 1 : -1);
      }
      
      // Handle date sorting
      if (['startDate', 'endDate'].includes(sortConfig.key)) {
        const dateA = new Date(a[sortConfig.key] || '');
        const dateB = new Date(b[sortConfig.key] || '');
        return sortConfig.direction === 'ascending' ? dateA - dateB : dateB - dateA;
      }
      
      // Handle numeric fields
      if (['absenceHours'].includes(sortConfig.key)) {
        const aValue = parseFloat(a[sortConfig.key] || 0);
        const bValue = parseFloat(b[sortConfig.key] || 0);
        return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
      }
      
      // Default string comparison
      const aValue = a[sortConfig.key] || '';
      const bValue = b[sortConfig.key] || '';
      
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortConfig.direction === 'ascending' 
          ? aValue.localeCompare(bValue) 
          : bValue.localeCompare(aValue);
      }
      
      // Default comparison
      return sortConfig.direction === 'ascending' 
        ? (aValue > bValue ? 1 : -1) 
        : (bValue > aValue ? 1 : -1);
    });
  }, [leaveData, sortConfig]);

  // Function to truncate text with tooltip
  const truncateText = (text, maxLength = 20) => {
    if (!text) return "-";
    
    if (text.length <= maxLength) {
      return text;
    }
    
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="cursor-help">{text.substring(0, maxLength)}...</span>
          </TooltipTrigger>
          <TooltipContent>
            <p>{text}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  const editLeave = async (id) => {
    try {
      setSelectedId(id);
      const response = await GetSingleLeaves(id);
      setSingleLeaveData(response.data.singleLeave);
      if (response.status === 200) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Errore nel recupero dei dati dell'assenza", error);
      toast.error("Impossibile recuperare i dati dell'assenza");
    }
  };

  const handleDeleteModal = (id) => {
    setDeletedId(id);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteEmployee = async () => {
    try {
      const response = await deleteLeave(deletedId);
      if (response.status === 200) {
        toast.success(response.data?.message);
        setDataUpdate(!dataUpdate);
        setIsDeleteModalOpen(false);
      }
    } catch (error) {
      console.error("Errore nell'eliminazione dell'assenza", error);
      toast.error("Impossibile eliminare l'assenza");
    }
  };

  const fetchCompanyLeaves = async () => {
    try {
      const response = await GetCompanyLeaves(companyId, searchQuery);
      if (response.status === 200) {
        // Add an id property to each leave for selection tracking
        const enhancedLeaveData = response.data.leaves.map((leave) => ({
          ...leave,
          id: leave._id // Ensure we have an id field for the table selection
        }));
        setLeaveData(enhancedLeaveData);
      }
    } catch (error) {
      console.error("Errore nel recupero delle assenze", error);
      toast.error("Impossibile recuperare le assenze");
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    fetchCompanyLeaves();
  }, [searchQuery, dataUpdate]);

  return (
    <div className="mx-auto space-y-4">
      <div className="flex flex-col md:flex-row justify-between items-center my-2 space-y-4 md:space-y-0">
        <Button onClick={() => setIsModalOpen(true)}>Aggiungi Assenza</Button>
        <div className="md:w-1/6 flex justify-center items-center gap-x-2">
          <SearchBar onSearch={handleSearch} />
          <HelpSheet route="/employees" />
        </div>
      </div>

      <div className="rounded-md border">
        <EnhancedTable
          items={sortedData}
          sortConfig={sortConfig}
          onSortChange={setSortConfig}
          selectedItems={selectedItems}
          onSelectChange={setSelectedItems}
          selectAll={selectAll}
          onSelectAllChange={setSelectAll}
        >
          <TableHeader>
            <TableRow>
              <SelectAllTableHead />
              <SortableTableHead sortKey="employeeId.freshman">
                Matricola
              </SortableTableHead>
              <SortableTableHead sortKey="employeeId.firstName">
                Nome
              </SortableTableHead>
              <SortableTableHead sortKey="employeeId.sureName">
                Cognome
              </SortableTableHead>
              <SortableTableHead sortKey="absenceType">
                Tipologia
              </SortableTableHead>
              <SortableTableHead sortKey="startDate">
                Data Inizio Assenza
              </SortableTableHead>
              <SortableTableHead sortKey="endDate">
                Data Fine Assenza
              </SortableTableHead>
              <SortableTableHead sortKey="absenceHours">
                Ore di Assenza
              </SortableTableHead>
              <SortableTableHead></SortableTableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedData?.length > 0 ? (
              sortedData?.map((data, index) => (
                <SelectableTableRow key={data._id} item={data}>
                  <SelectionTableCell item={data} />
                  <TableCell>{data.employeeId?.freshman}</TableCell>
                  <TableCell>{data.employeeId?.firstName}</TableCell>
                  <TableCell>{data.employeeId?.sureName}</TableCell>
                  <TableCell>{truncateText(data.absenceType, 15)}</TableCell>
                  <TableCell>
                    {format(new Date(data.startDate), "dd/MM/yyyy", {
                      locale: it
                    })}
                  </TableCell>
                  <TableCell>
                    {format(new Date(data.endDate), "dd/MM/yyyy", {
                      locale: it
                    })}
                  </TableCell>
                  <TableCell>{data.absenceHours}H</TableCell>
                  <ActionsTableCell>
                    <DropdownMenuItem onClick={() => editLeave(data._id)}>
                      <Edit className="h-4 w-4 mr-2" /> Modifica
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => handleDeleteModal(data._id)}
                    >
                      <Trash2 className="h-4 w-4 mr-2 text-red-600" /> Elimina
                    </DropdownMenuItem>
                  </ActionsTableCell>
                </SelectableTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} className="text-center py-8">
                  Nessun dato disponibile
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </EnhancedTable>
      </div>

      <AddAbsence
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedId(null);
        }}
        setIsModalOpen={setIsModalOpen}
        employeeData={employeeData}
        selectedId={selectedId}
        singleLeaveData={singleLeaveData}
        dataUpdate={dataUpdate}
        setDataUpdate={setDataUpdate}
      />

      <Dialog open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Elimina Assenza</DialogTitle>
            <DialogDescription>
              Sei sicuro di voler eliminare questa assenza? Questa azione è
              irreversibile.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Annulla
            </Button>
            <Button variant="destructive" onClick={handleDeleteEmployee}>
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Absence;