import { checkRequisitionStatus } from "../../apis/PaymentMethods";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import { Button } from "../ui/button";

const BankCallback = () => {
  const [countdown, setCountdown] = useState(30);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const requistitionStatus = async () => {
    const requisitionId = localStorage.getItem("requisitionId") || "";

    if (!requisitionId) {
      toast.error(
        "No requisitionId found in localStorage.Please try connecting bank again"
      );
      console.error("No requisitionId found in localStorage");
      return;
    }
    setLoading(true);
    try {
      toast.success(
        "Please wait patiently while we are cheking your requistition status"
      );
      const response = await checkRequisitionStatus(requisitionId);
      if (response?.data?.status === "LN") {
        toast.success("Bank account connected successfully");
      } else if (response?.data?.status === "EX") {
        toast.error("Requistition is expired , please try again");
      } else if (response?.data?.status === "RJ") {
        toast.error("Bank acccount connection is rejected , please try again");
      } else if (response?.data?.status === "UA") {
        toast.error(
          "Bank acccount is in authentication process , please try again"
        );
      } else {
        toast("There is some problem in connecting bank , please try again");
      }
      localStorage.removeItem("requisitionId");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching requisition status:", error);
    }
  };

  useEffect(() => {
    const fetchDataAndStartCountdown = async () => {
      await requistitionStatus(); // Call the API first

      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            navigate("/payment-methods");
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    };

    fetchDataAndStartCountdown();
  }, [navigate]);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      {loading && (
        <div className="flex justify-center">
          <Spinner />
        </div>
      )}
      {!loading && (
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-lg font-semibold mb-2">
            You will be automatically redirected to the bank listing section in:
          </h2>
          <p className="text-4xl font-bold text-red-500 mb-4">{countdown}s</p>
          <Button onClick={() => navigate("/payment-methods")}>
            Click here to see listing
          </Button>
        </div>
      )}
    </div>
  );
};

export default BankCallback;
