import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getConstructionSite,
  addDocuments,
  deleteDocument,
  updateDocumentName
} from "../../../apis/ConstructionSite";
import {
  FaTrash,
  FaEdit,
  FaCheck,
  FaTimes,
  FaUpload,
  FaFile,
  FaEye,
  FaDownload
} from "react-icons/fa";
import { Archive, Loader2 } from "lucide-react";
import ConstructionSiteNavbar from "./ConstructionSiteNavbar";

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import {
  EnhancedTable,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "../../ui/dialog";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { DropdownMenuItem } from "../../ui/dropdown-menu";
import { toast } from "react-toastify";

function TableComponent() {
  const { id } = useParams();
  const [site, setSite] = useState({ documents: [] });
  const [files, setFiles] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [editingDocument, setEditingDocument] = useState(null);
  const [newDocumentName, setNewDocumentName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isArchived, setIsArchived] = useState(false);

  // Add enhanced table state
  const [sortConfig, setSortConfig] = useState({
    key: "uploadDate",
    direction: "descending"
  });
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    fetchSite();
  }, [id]);

  const fetchSite = async () => {
    setIsLoading(true);
    try {
      const response = await getConstructionSite(id);
      // Add id property for selection tracking
      const docsWithIds = response.data.documents.map((doc) => ({
        ...doc,
        id: doc._id
      }));
      setSite({
        ...response.data,
        documents: docsWithIds
      });
      setIsArchived(response.data.status === "Archiviato");
    } catch (error) {
      console.error("Error fetching construction site:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    if (isArchived) return;

    const selectedFiles = Array.from(e.target.files);
    const MAX_FILE_SIZE = 30 * 1024 * 1024; // 30MB in bytes

    const oversizedFiles = selectedFiles.filter(
      (file) => file.size > MAX_FILE_SIZE
    );

    if (oversizedFiles.length > 0) {
      const fileNames = oversizedFiles.map((file) => file.name).join(", ");
      toast.error(
        `File troppo grande: ${fileNames}. Il limite massimo è 30MB per file.`
      );
      return;
    }

    setFiles(selectedFiles);
  };

  const handleUpload = async () => {
    if (isArchived || files.length === 0) return;
    setIsUploading(true);
    try {
      const formData = new FormData();
      // Only append the documents, nothing else
      files.forEach((file) => formData.append("documents", file));

      const response = await addDocuments(id, formData);
      if (response.data) {
        toast.success("Documenti caricati con successo");
        fetchSite(); // Refresh the site data
        setFiles([]); // Clear the files
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
      const errorMessage =
        error.response?.data?.message ||
        "Errore durante il caricamento dei documenti";
      toast.error(errorMessage);
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteClick = (document) => {
    if (isArchived) return;
    setDocumentToDelete(document);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (isArchived) return;
    setIsLoading(true);
    try {
      await deleteDocument(id, documentToDelete._id);
      fetchSite();
      setDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting document:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = (document) => {
    if (isArchived) return;
    setEditingDocument(document);
    setNewDocumentName(document.name);
  };

  const handleEditSave = async () => {
    if (isArchived) return;
    setIsLoading(true);
    try {
      await updateDocumentName(id, editingDocument._id, newDocumentName);
      fetchSite();
      setEditingDocument(null);
    } catch (error) {
      console.error("Error updating document name:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewDocument = (doc) => {
    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/${doc.path}`,
      "_blank",
      "noopener noreferrer"
    );
  };

  const handleDownloadDocument = (doc) => {
    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_BACKEND_URL}/${doc.path}`;
    link.download = doc.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEditCancel = () => {
    setEditingDocument(null);
    setNewDocumentName("");
  };

  return (
    <div>
      <ConstructionSiteNavbar />
      {isArchived && (
        <div className="mx-6 mt-6">
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex items-center">
              <Archive className="w-5 h-5 text-yellow-400 mr-2" />
              <p className="text-yellow-700">
                Questo cantiere è archiviato. È possibile solo visualizzare i
                documenti.
              </p>
            </div>
          </div>
        </div>
      )}
      <Card className="mt-4 border-0">
        <CardHeader>
          <CardTitle>Gestione Documenti</CardTitle>
        </CardHeader>
        <CardContent>
          {!isArchived && (
            <div className="mb-6 flex items-center md:justify-between space-x-4">
              <Input type="file" multiple onChange={handleFileChange} className='w-auto' />
              <Button
                onClick={handleUpload}
                disabled={isUploading || files.length === 0}
              >
                {isUploading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Caricamento...
                  </>
                ) : (
                  <>
                    <FaUpload className="mr-2" />
                    Carica Documenti
                  </>
                )}
              </Button>
            </div>
          )}

          <h3 className="text-xl font-semibold mb-4">Documenti Esistenti:</h3>
          {isLoading ? (
            <div className="flex justify-center items-center h-32">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : site.documents.length > 0 ? (
            <EnhancedTable
              items={site.documents}
              sortConfig={sortConfig}
              onSortChange={setSortConfig}
              selectedItems={selectedItems}
              onSelectChange={setSelectedItems}
              selectAll={selectAll}
              onSelectAllChange={setSelectAll}
            >
              <TableHeader>
                <TableRow>
                  <SelectAllTableHead />
                  <SortableTableHead sortKey="name">Nome</SortableTableHead>
                  <SortableTableHead sortKey="uploadDate">
                    Data di caricamento
                  </SortableTableHead>
                  <SortableTableHead></SortableTableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {site.documents.map((doc) => (
                  <SelectableTableRow key={doc._id} item={doc}>
                    <SelectionTableCell item={doc} />
                    <TableCell>
                      <div className="flex items-center">
                        <FaFile className="flex-shrink-0 h-5 w-5 text-gray-400 mr-3" />
                        <div className="text-sm font-medium">
                          {editingDocument &&
                          editingDocument._id === doc._id &&
                          !isArchived ? (
                            <Input
                              value={newDocumentName}
                              onChange={(e) =>
                                setNewDocumentName(e.target.value)
                              }
                              className="w-full"
                            />
                          ) : (
                            doc.name
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {new Date(doc.uploadDate).toLocaleString()}
                    </TableCell>
                    {editingDocument &&
                    editingDocument._id === doc._id &&
                    !isArchived ? (
                      <TableCell>
                        <div className="flex items-center space-x-2">
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={handleEditSave}
                            title="Salva"
                          >
                            <FaCheck />
                          </Button>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={handleEditCancel}
                            title="Annulla"
                          >
                            <FaTimes />
                          </Button>
                        </div>
                      </TableCell>
                    ) : (
                      <ActionsTableCell>
                        <DropdownMenuItem
                          onClick={() => handleViewDocument(doc)}
                        >
                          <FaEye className="h-4 w-4 mr-2" /> Visualizza
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => handleDownloadDocument(doc)}
                        >
                          <FaDownload className="h-4 w-4 mr-2" /> Scarica
                        </DropdownMenuItem>
                        {!isArchived && (
                          <>
                            <DropdownMenuItem
                              onClick={() => handleEditClick(doc)}
                            >
                              <FaEdit className="h-4 w-4 mr-2" /> Modifica nome
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() => handleDeleteClick(doc)}
                              className="text-red-600"
                            >
                              <FaTrash className="h-4 w-4 mr-2" /> Elimina
                            </DropdownMenuItem>
                          </>
                        )}
                      </ActionsTableCell>
                    )}
                  </SelectableTableRow>
                ))}
              </TableBody>
            </EnhancedTable>
          ) : (
            <p className="text-gray-500 italic">Nessun documento caricato.</p>
          )}
        </CardContent>
      </Card>

      <Dialog open={deleteModalOpen} onOpenChange={setDeleteModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conferma eliminazione</DialogTitle>
            <DialogDescription>
              Sei sicuro di voler eliminare il documento "
              {documentToDelete?.name}"?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setDeleteModalOpen(false)}>
              Annulla
            </Button>
            <Button variant="destructive" onClick={handleDeleteConfirm}>
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TableComponent;
