"use client";

import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ConstructionSiteNavbar from "./ConstructionSiteNavbar";
import {
  getConstructionSite,
  getGanttChart,
  createCategory,
  updateCategory,
  deleteCategory,
  createTask,
  updateTask,
  deleteTask,
  importFromComputo
} from "../../../apis/ConstructionSite";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "../../ui/dialog";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { ScrollArea } from "../../ui/scroll-area";
import { Progress } from "../../ui/progress";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import {
  ChevronDown,
  ChevronRight,
  Calendar,
  Plus,
  Edit,
  Trash,
  Move,
  Folder,
  FolderPlus,
  FileText,
  Link,
  AlertCircle
} from "lucide-react";
import CustomGanttChart from "./components/CustomGanttChart";

// Category colors for different categories
const CATEGORY_COLORS = {
  default: {
    bg: "bg-violet-500",
    hover: "hover:bg-violet-600",
    light: "bg-violet-100",
    circle: "bg-violet-500"
  },
  home: {
    bg: "bg-blue-500",
    hover: "hover:bg-blue-600",
    light: "bg-blue-100",
    circle: "bg-blue-500"
  },
  dew: {
    bg: "bg-emerald-500",
    hover: "hover:bg-emerald-600",
    light: "bg-emerald-100",
    circle: "bg-emerald-500"
  },
  planning: {
    bg: "bg-amber-500",
    hover: "hover:bg-amber-600",
    light: "bg-amber-100",
    circle: "bg-amber-500"
  },
  design: {
    bg: "bg-rose-500",
    hover: "hover:bg-rose-600",
    light: "bg-rose-100",
    circle: "bg-rose-500"
  },
  development: {
    bg: "bg-indigo-500",
    hover: "hover:bg-indigo-600",
    light: "bg-indigo-100",
    circle: "bg-indigo-500"
  },
  testing: {
    bg: "bg-orange-500",
    hover: "hover:bg-orange-600",
    light: "bg-orange-100",
    circle: "bg-orange-500"
  },
  deployment: {
    bg: "bg-cyan-500",
    hover: "hover:bg-cyan-600",
    light: "bg-cyan-100",
    circle: "bg-cyan-500"
  },
  maintenance: {
    bg: "bg-purple-500",
    hover: "hover:bg-purple-600",
    light: "bg-purple-100",
    circle: "bg-purple-500"
  }
};

// Helper function to format date for input fields
const formatDateForInput = (date, respectBoundaries = false) => {
  if (!date) return "";

  const d = new Date(date);
  return d.toISOString().split("T")[0];
};

const Cronoprogramma = () => {
  const { id } = useParams();
  const [siteInfo, setSiteInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [ganttTasks, setGanttTasks] = useState([]);
  const [categoryColors, setCategoryColors] = useState({});
  const [newTask, setNewTask] = useState({
    name: "",
    startDate: formatDateForInput(new Date()),
    endDate: formatDateForInput(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)),
    progress: 0,
    dependencies: "",
    categoryId: ""
  });
  const [newCategory, setNewCategory] = useState({
    name: "",
    parentId: ""
  });
  const [editingTask, setEditingTask] = useState(null);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskInfoModalOpen, setTaskInfoModalOpen] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);

  useEffect(() => {
    fetchSiteData();
  }, [id]);

  // Helper function for date validation
  const validateTaskDates = (taskStartDate, taskEndDate) => {
    // Convert to Date objects if they're strings
    const startDate =
      typeof taskStartDate === "string"
        ? new Date(taskStartDate)
        : taskStartDate;
    const endDate =
      typeof taskEndDate === "string" ? new Date(taskEndDate) : taskEndDate;

    // Ensure site info is loaded
    if (!siteInfo)
      return {
        valid: false,
        message: "Informazioni del cantiere non disponibili"
      };

    // Get site opening and closing dates
    const siteOpeningDate = new Date(siteInfo.openingDate);

    // If site has a closing date, use it. Otherwise, allow any future end date
    const hasClosingDate =
      siteInfo.closingDate && siteInfo.closingDate !== null;
    const siteClosingDate = hasClosingDate
      ? new Date(siteInfo.closingDate)
      : null;

    // Reset time parts for accurate date comparison
    siteOpeningDate.setHours(0, 0, 0, 0);
    if (siteClosingDate) siteClosingDate.setHours(23, 59, 59, 999);

    const taskStartWithoutTime = new Date(startDate);
    taskStartWithoutTime.setHours(0, 0, 0, 0);

    const taskEndWithoutTime = new Date(endDate);
    taskEndWithoutTime.setHours(23, 59, 59, 999);

    // Validate against opening date
    if (taskStartWithoutTime < siteOpeningDate) {
      return {
        valid: false,
        message: `La data di inizio non può essere precedente alla data di apertura del cantiere (${siteOpeningDate.toLocaleDateString(
          "it-IT"
        )})`
      };
    }

    // Validate against closing date if it exists
    if (hasClosingDate && taskEndWithoutTime > siteClosingDate) {
      return {
        valid: false,
        message: `La data di fine non può essere successiva alla data di chiusura del cantiere (${siteClosingDate.toLocaleDateString(
          "it-IT"
        )})`
      };
    }

    // Ensure end date is after start date
    if (taskEndWithoutTime <= taskStartWithoutTime) {
      return {
        valid: false,
        message: "La data di fine deve essere successiva alla data di inizio"
      };
    }

    return { valid: true };
  };

  // Get min/max date attributes for date input fields
  const getDateConstraints = () => {
    if (!siteInfo) return { min: "", max: "" };

    const minDate = formatDateForInput(siteInfo.openingDate);
    const maxDate = siteInfo.closingDate
      ? formatDateForInput(siteInfo.closingDate)
      : "";

    return { min: minDate, max: maxDate };
  };

  const filterCategoriesBySearchTerm = useCallback(() => {
    if (!searchTerm.trim()) {
      setFilteredCategories(categories);
      return;
    }

    const searchTextLower = searchTerm.toLowerCase();

    // Helper function to check if category or its children contain the search term
    const categoryContainsSearchTerm = (category) => {
      // Check category name
      if (
        category.name &&
        category.name.toLowerCase().includes(searchTextLower)
      ) {
        return true;
      }

      // Check tasks in category
      const tasksContainTerm =
        category.tasks &&
        category.tasks.some(
          (task) =>
            task.name && task.name.toLowerCase().includes(searchTextLower)
        );

      if (tasksContainTerm) {
        return true;
      }

      // Check subcategories recursively
      if (category.subcategories && category.subcategories.length > 0) {
        return category.subcategories.some((subcat) =>
          categoryContainsSearchTerm(subcat)
        );
      }

      return false;
    };

    // Filter top-level categories
    const filtered = categories.filter((category) =>
      categoryContainsSearchTerm(category)
    );
    setFilteredCategories(filtered);
  }, [categories, searchTerm]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      filterCategoriesBySearchTerm();
      // Extract all tasks for the Gantt chart
      extractTasksForGantt(categories);
      // Generate category colors
      generateCategoryColors(categories);
    } else {
      setFilteredCategories([]);
      setGanttTasks([]);
    }
  }, [categories, searchTerm, filterCategoriesBySearchTerm]);

  // Generate category colors
  const generateCategoryColors = (cats) => {
    const colors = {};
    const uniqueCategories = new Set();

    // First collect all category IDs
    const collectCategories = (categories) => {
      if (!categories) return;

      categories.forEach((cat) => {
        if (cat._id) {
          uniqueCategories.add(cat._id);
        }

        if (cat.subcategories && cat.subcategories.length > 0) {
          collectCategories(cat.subcategories);
        }
      });
    };

    collectCategories(cats);

    // Assign colors to categories
    const colorKeys = Object.keys(CATEGORY_COLORS);
    let colorIndex = 0;

    uniqueCategories.forEach((categoryId) => {
      // Find category by ID
      const findCategory = (categories, id) => {
        if (!categories) return null;

        for (const cat of categories) {
          if (cat._id === id) return cat;

          if (cat.subcategories && cat.subcategories.length > 0) {
            const found = findCategory(cat.subcategories, id);
            if (found) return found;
          }
        }

        return null;
      };

      const category = findCategory(cats, categoryId);
      const categoryName = category?.name?.toLowerCase() || "";

      // Check if the category name matches any of our predefined color keys
      const matchedColor = colorKeys.find((key) => categoryName.includes(key));

      if (matchedColor) {
        colors[categoryId] = CATEGORY_COLORS[matchedColor];
      } else {
        // Assign the next color from our list
        colors[categoryId] =
          CATEGORY_COLORS[colorKeys[colorIndex % colorKeys.length]];
        colorIndex++;
      }
    });

    setCategoryColors(colors);
  };

  // Get color for a category
  const getCategoryColor = (categoryId) => {
    return categoryColors[categoryId] || CATEGORY_COLORS.default;
  };

  // Extract all tasks from categories recursively for the Gantt chart
  const extractTasksForGantt = (cats) => {
    const allTasks = [];

    const extractTasks = (categories, parentCategoryId = null) => {
      if (!categories) return;

      categories.forEach((category) => {
        if (category.tasks && category.tasks.length > 0) {
          category.tasks.forEach((task) => {
            // Ensure task has required fields with fallbacks
            if (task && task._id) {
              // Validate dates
              const startDate = new Date(task.startDate);
              const endDate = new Date(task.endDate);

              // Only add valid tasks to the gantt
              if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
                allTasks.push({
                  ...task,
                  categoryId: category._id,
                  categoryName: category.name || "Unnamed Category",
                  // Ensure progress is a number between 0-100
                  progress:
                    typeof task.progress === "number"
                      ? Math.min(Math.max(task.progress, 0), 100)
                      : 0,
                  // Ensure name is a string
                  name: task.name || "Unnamed Task"
                });
              }
            }
          });
        }

        if (category.subcategories && category.subcategories.length > 0) {
          extractTasks(category.subcategories, category._id);
        }
      });
    };

    extractTasks(cats);
    setGanttTasks(allTasks);
  };

  const fetchSiteData = async () => {
    try {
      setLoading(true);

      // Get construction site info
      const siteResponse = await getConstructionSite(id);
      setSiteInfo(siteResponse.data);

      // Get gantt chart data
      const ganttResponse = await getGanttChart(id);

      // Check if ganttResponse is an object with categories property
      if (ganttResponse && ganttResponse.categories) {
        setCategories(ganttResponse.categories);
      } else {
        setCategories([]);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Errore nel caricamento dei dati");
      setLoading(false);
      setCategories([]);
    }
  };

  const handleTaskChange = (e) => {
    const { name, value } = e.target;
    setNewTask((prev) => ({
      ...prev,
      [name]: name === "progress" ? Number.parseInt(value, 10) : value
    }));
  };

  const handleSelectChange = (name, value) => {
    if (name === "dependencies") {
      setNewTask((prev) => ({
        ...prev,
        [name]: value === "none" ? "" : value
      }));
    } else if (name === "categoryId") {
      setNewTask((prev) => ({
        ...prev,
        [name]: value
      }));
    } else if (name === "parentId") {
      setNewCategory((prev) => ({
        ...prev,
        [name]: value === "root" ? "" : value
      }));
    }
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setNewCategory((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle Gantt chart task click
  const handleGanttTaskClick = (task) => {
    viewTaskDetails(task, task.categoryName);
  };

  // Handle task updates from Gantt chart (drag, resize, progress change)
  const handleGanttTaskUpdate = async (task) => {
    try {
      // Validate the task dates against construction site timeframe
      const validation = validateTaskDates(task.startDate, task.endDate);
      if (!validation.valid) {
        toast.error(validation.message);
        // Refresh to restore the original task position
        fetchSiteData();
        return;
      }

      setIsSubmitting(true);

      const taskData = {
        name: task.name,
        startDate: task.startDate,
        endDate: task.endDate,
        progress: task.progress || 0,
        dependencies: task.dependencies || null,
        categoryId: task.categoryId
      };

      // Make API call
      await updateTask(id, task._id, taskData);

      // Instead of fetching all data again, update the local state directly
      setCategories((prevCategories) => {
        // Create a deep copy of categories
        const updatedCategories = JSON.parse(JSON.stringify(prevCategories));

        // Helper function to find and update the task in the category tree
        const updateTaskInCategories = (categories) => {
          for (const category of categories) {
            if (category.tasks) {
              const taskIndex = category.tasks.findIndex(
                (t) => t._id === task._id
              );
              if (taskIndex !== -1) {
                category.tasks[taskIndex] = {
                  ...category.tasks[taskIndex],
                  ...taskData,
                  startDate: new Date(task.startDate),
                  endDate: new Date(task.endDate)
                };
                return true;
              }
            }

            if (category.subcategories && category.subcategories.length > 0) {
              if (updateTaskInCategories(category.subcategories)) {
                return true;
              }
            }
          }
          return false;
        };

        updateTaskInCategories(updatedCategories);
        return updatedCategories;
      });

      // Update ganttTasks state too
      setGanttTasks((prevTasks) => {
        return prevTasks.map((t) => {
          if (t._id === task._id) {
            return { ...t, ...taskData };
          }
          return t;
        });
      });

      toast.success("Attività aggiornata con successo");
    } catch (error) {
      console.error("Error updating task:", error);
      toast.error(
        error.message || "Errore durante l'aggiornamento dell'attività"
      );
      // Refresh to restore the original task position
      fetchSiteData();
    } finally {
      setIsSubmitting(false);
    }
  };

  // Validate dates and ensure end date is after start date
  const validateDateRange = (startDate, endDate) => {
    // Basic date format validation
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (isNaN(start.getTime())) {
      toast.error("Data di inizio non valida");
      return false;
    }

    if (isNaN(end.getTime())) {
      toast.error("Data di fine non valida");
      return false;
    }

    // Validate against construction site timeframe
    const validation = validateTaskDates(start, end);
    if (!validation.valid) {
      toast.error(validation.message);
      return false;
    }

    return true;
  };

  const addTask = async () => {
    if (
      !newTask.name ||
      !newTask.startDate ||
      !newTask.endDate ||
      !newTask.categoryId
    ) {
      toast.error("Compila tutti i campi obbligatori");
      return;
    }

    // Validate date range and construction site timeframe
    if (!validateDateRange(newTask.startDate, newTask.endDate)) {
      return;
    }

    setIsSubmitting(true);

    try {
      const taskData = {
        name: newTask.name,
        startDate: newTask.startDate,
        endDate: newTask.endDate,
        progress: newTask.progress || 0,
        dependencies: newTask.dependencies || null,
        categoryId: newTask.categoryId
      };

      await createTask(id, taskData);

      toast.success("Attività aggiunta con successo");
      setTaskModalOpen(false);

      // Reset form
      setNewTask({
        name: "",
        startDate: formatDateForInput(new Date()),
        endDate: formatDateForInput(
          new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
        ),
        progress: 0,
        dependencies: "",
        categoryId: ""
      });

      // Update local state instead of fetching everything again
      setCategories((prevCategories) => {
        // Create a deep copy of categories
        const updatedCategories = JSON.parse(JSON.stringify(prevCategories));

        // Find the category to add the task to
        const addTaskToCategory = (categories) => {
          for (const category of categories) {
            if (category._id === taskData.categoryId) {
              if (!category.tasks) category.tasks = [];

              // Add the new task with a generated _id (simulate server response)
              const newTask = {
                ...taskData,
                _id: Math.random().toString(36).substring(2, 15),
                startDate: new Date(taskData.startDate),
                endDate: new Date(taskData.endDate)
              };

              category.tasks.push(newTask);
              return true;
            }

            if (category.subcategories && category.subcategories.length > 0) {
              if (addTaskToCategory(category.subcategories)) return true;
            }
          }
          return false;
        };

        addTaskToCategory(updatedCategories);

        return updatedCategories;
      });

      // We'll update the ganttTasks state in the useEffect that watches for category changes
    } catch (error) {
      console.error("Error adding task:", error);
      toast.error(error.message || "Errore durante l'aggiunta dell'attività");
    } finally {
      setIsSubmitting(false);
    }
  };

  const addCategory = async () => {
    if (!newCategory.name) {
      toast.error("Il nome della categoria è obbligatorio");
      return;
    }

    setIsSubmitting(true);

    try {
      const categoryData = {
        name: newCategory.name,
        parentId: newCategory.parentId || null
      };

      await createCategory(id, categoryData);

      toast.success("Categoria aggiunta con successo");
      setCategoryModalOpen(false);

      // Reset form
      setNewCategory({
        name: "",
        parentId: ""
      });

      // Refresh data
      fetchSiteData();
    } catch (error) {
      console.error("Error adding category:", error);
      toast.error(error.message || "Errore durante l'aggiunta della categoria");
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteTaskHandler = async (taskId) => {
    if (!taskId) return;

    try {
      await deleteTask(id, taskId);
      toast.success("Attività eliminata con successo");

      // Update local state
      setCategories((prevCategories) => {
        const updatedCategories = JSON.parse(JSON.stringify(prevCategories));

        // Helper function to find and remove the task
        const removeTask = (categories) => {
          for (const category of categories) {
            if (category.tasks) {
              const taskIndex = category.tasks.findIndex(
                (t) => t._id === taskId
              );
              if (taskIndex !== -1) {
                category.tasks.splice(taskIndex, 1);
                return true;
              }
            }

            if (category.subcategories && category.subcategories.length > 0) {
              if (removeTask(category.subcategories)) {
                return true;
              }
            }
          }
          return false;
        };

        removeTask(updatedCategories);
        return updatedCategories;
      });

      // Also update ganttTasks
      setGanttTasks((prevTasks) => prevTasks.filter((t) => t._id !== taskId));
    } catch (error) {
      console.error("Error deleting task:", error);
      toast.error(
        error.message || "Errore durante l'eliminazione dell'attività"
      );
    }
  };

  const deleteCategoryHandler = async (categoryId) => {
    if (!categoryId) return;

    try {
      await deleteCategory(id, categoryId);
      toast.success("Categoria eliminata con successo");

      // Refresh data
      fetchSiteData();
    } catch (error) {
      console.error("Error deleting category:", error);
      toast.error(
        error.message || "Errore durante l'eliminazione della categoria"
      );
    }
  };

  const editTask = (task, categoryId) => {
    setEditingTask({ ...task, categoryId });
    setNewTask({
      name: task.name,
      startDate: formatDateForInput(new Date(task.startDate)),
      endDate: formatDateForInput(new Date(task.endDate)),
      progress: task.progress,
      dependencies: task.dependencies || "",
      categoryId: categoryId
    });
    setTaskModalOpen(true);
  };

  const updateTaskHandler = async () => {
    if (
      !newTask.name ||
      !newTask.startDate ||
      !newTask.endDate ||
      !newTask.categoryId
    ) {
      toast.error("Compila tutti i campi obbligatori");
      return;
    }

    // Validate date range and construction site timeframe
    if (!validateDateRange(newTask.startDate, newTask.endDate)) {
      return;
    }

    setIsSubmitting(true);

    try {
      const taskData = {
        name: newTask.name,
        startDate: newTask.startDate,
        endDate: newTask.endDate,
        progress: newTask.progress || 0,
        dependencies: newTask.dependencies || null,
        categoryId: newTask.categoryId
      };

      await updateTask(id, editingTask._id, taskData);

      toast.success("Attività aggiornata con successo");
      setTaskModalOpen(false);

      // Reset form
      setNewTask({
        name: "",
        startDate: formatDateForInput(new Date()),
        endDate: formatDateForInput(
          new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
        ),
        progress: 0,
        dependencies: "",
        categoryId: ""
      });

      setEditingTask(null);

      // Update local state instead of fetching everything again
      setCategories((prevCategories) => {
        // Create a deep copy of categories
        const updatedCategories = JSON.parse(JSON.stringify(prevCategories));

        // Find the existing task to update or move
        let taskFound = false;
        let oldCategoryId = null;

        // Function to find and process the task
        const findAndProcessTask = (categories) => {
          for (const category of categories) {
            if (!category.tasks) continue;

            for (let i = 0; i < category.tasks.length; i++) {
              if (category.tasks[i]._id === editingTask._id) {
                taskFound = true;
                oldCategoryId = category._id;

                // If category is not changing, update in place
                if (taskData.categoryId === oldCategoryId) {
                  category.tasks[i] = {
                    ...category.tasks[i],
                    ...taskData,
                    startDate: new Date(taskData.startDate),
                    endDate: new Date(taskData.endDate)
                  };
                  return null;
                } else {
                  // Save task for moving to new category
                  const taskToMove = {
                    ...category.tasks[i],
                    ...taskData,
                    startDate: new Date(taskData.startDate),
                    endDate: new Date(taskData.endDate)
                  };
                  // Remove from current category
                  category.tasks.splice(i, 1);
                  return taskToMove;
                }
              }
            }

            if (category.subcategories && category.subcategories.length > 0) {
              const result = findAndProcessTask(category.subcategories);
              if (taskFound) return result;
            }
          }
          return null;
        };

        const taskToMove = findAndProcessTask(updatedCategories);

        // If task is moving to a new category
        if (taskFound && taskToMove && oldCategoryId !== taskData.categoryId) {
          const addToCategory = (categories) => {
            for (const category of categories) {
              if (category._id === taskData.categoryId) {
                if (!category.tasks) category.tasks = [];
                category.tasks.push(taskToMove);
                return true;
              }

              if (category.subcategories && category.subcategories.length > 0) {
                if (addToCategory(category.subcategories)) return true;
              }
            }
            return false;
          };

          addToCategory(updatedCategories);
        }

        return updatedCategories;
      });

      // Also update ganttTasks state
      setGanttTasks((prevTasks) => {
        return prevTasks.map((t) => {
          if (t._id === editingTask._id) {
            return {
              ...t,
              ...taskData,
              categoryName:
                taskData.categoryId === t.categoryId
                  ? t.categoryName
                  : findCategoryName(categories, taskData.categoryId) ||
                    t.categoryName
            };
          }
          return t;
        });
      });
    } catch (error) {
      console.error("Error updating task:", error);
      toast.error(
        error.message || "Errore durante l'aggiornamento dell'attività"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Helper function to find category name by ID
  const findCategoryName = (cats, categoryId) => {
    for (const cat of cats) {
      if (cat._id === categoryId) {
        return cat.name;
      }

      if (cat.subcategories && cat.subcategories.length > 0) {
        const name = findCategoryName(cat.subcategories, categoryId);
        if (name) return name;
      }
    }
    return null;
  };

  const viewTaskDetails = (task, categoryName) => {
    setSelectedTask({ ...task, categoryName });
    setTaskInfoModalOpen(true);
  };

  const toggleCategoryExpand = async (categoryId) => {
    try {
      // Find category to toggle
      const findCategory = (cats) => {
        for (const cat of cats) {
          if (cat._id.toString() === categoryId) {
            return cat;
          }

          if (cat.subcategories && cat.subcategories.length > 0) {
            const found = findCategory(cat.subcategories);
            if (found) return found;
          }
        }
        return null;
      };

      const category = findCategory(categories);
      if (category) {
        // Toggle expanded state locally
        setCategories((prevCategories) => {
          const updatedCategories = JSON.parse(JSON.stringify(prevCategories));

          const toggleExpand = (cats) => {
            for (const cat of cats) {
              if (cat._id === categoryId) {
                cat.expanded = !cat.expanded;
                return true;
              }

              if (cat.subcategories && cat.subcategories.length > 0) {
                if (toggleExpand(cat.subcategories)) return true;
              }
            }
            return false;
          };

          toggleExpand(updatedCategories);
          return updatedCategories;
        });

        // Also update on the server
        await updateCategory(id, categoryId, { expanded: !category.expanded });
      }
    } catch (error) {
      console.error("Error toggling category:", error);
      toast.error("Errore durante l'aggiornamento della categoria");
    }
  };

  const handleDragStart = (e, item) => {
    setDraggedItem(item);
    e.dataTransfer.setData("text/plain", ""); // Required for Firefox
    // Add a dragging class to the element
    e.currentTarget.classList.add("opacity-50");
  };

  const handleDragEnd = (e) => {
    e.currentTarget.classList.remove("opacity-50");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  const handleDrop = async (e, targetCategoryId) => {
    e.preventDefault();

    if (!draggedItem || !targetCategoryId) return;

    // Only handle task drops for now
    if (draggedItem.type === "task") {
      try {
        const taskData = {
          categoryId: targetCategoryId
        };

        await updateTask(id, draggedItem.id, taskData);

        toast.success("Attività spostata con successo");

        // Update local state
        setCategories((prevCategories) => {
          const updatedCategories = JSON.parse(JSON.stringify(prevCategories));

          // Find the task to move
          let taskToMove = null;
          let sourceCategory = null;

          const findTaskAndSource = (categories) => {
            for (const category of categories) {
              if (category.tasks) {
                const taskIndex = category.tasks.findIndex(
                  (t) => t._id === draggedItem.id
                );
                if (taskIndex !== -1) {
                  taskToMove = { ...category.tasks[taskIndex] };
                  sourceCategory = category;
                  category.tasks.splice(taskIndex, 1);
                  return true;
                }
              }

              if (category.subcategories && category.subcategories.length > 0) {
                if (findTaskAndSource(category.subcategories)) {
                  return true;
                }
              }
            }
            return false;
          };

          // Find and remove task from source
          findTaskAndSource(updatedCategories);

          // Add task to target category
          if (taskToMove) {
            const addToTarget = (categories) => {
              for (const category of categories) {
                if (category._id === targetCategoryId) {
                  if (!category.tasks) category.tasks = [];
                  category.tasks.push(taskToMove);
                  return true;
                }

                if (
                  category.subcategories &&
                  category.subcategories.length > 0
                ) {
                  if (addToTarget(category.subcategories)) {
                    return true;
                  }
                }
              }
              return false;
            };

            addToTarget(updatedCategories);
          }

          return updatedCategories;
        });

        // Also update ganttTasks
        setGanttTasks((prevTasks) => {
          return prevTasks.map((t) => {
            if (t._id === draggedItem.id) {
              return {
                ...t,
                categoryId: targetCategoryId,
                categoryName:
                  findCategoryName(categories, targetCategoryId) ||
                  t.categoryName
              };
            }
            return t;
          });
        });
      } catch (error) {
        console.error("Error moving task:", error);
        toast.error("Errore durante lo spostamento dell'attività");
      }
    }

    setDraggedItem(null);
  };

  const renderCategoriesAndTasks = (cats, level = 0) => {
    if (!cats) return null;

    return cats.map((category) => (
      <div key={category._id} className="mb-1">
        <div
          className={`flex items-center py-2 px-3 hover:bg-gray-50 rounded transition-colors ${
            level > 0 ? "ml-4" : ""
          }`}
          style={{ paddingLeft: `${level * 16 + 8}px` }}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, category._id)}
        >
          <div
            className="mr-2 cursor-pointer flex items-center"
            onClick={() => toggleCategoryExpand(category._id)}
          >
            {category.expanded ? (
              <ChevronDown className="h-4 w-4 text-gray-500" />
            ) : (
              <ChevronRight className="h-4 w-4 text-gray-500" />
            )}
            <Folder className="h-4 w-4 text-gray-400 ml-1" />
          </div>

          <div className="flex items-center flex-1">
            <span
              className={`h-2.5 w-2.5 rounded-full mr-2 ${
                getCategoryColor(category._id).circle
              }`}
            ></span>
            <div className="font-medium text-gray-700">{category.name}</div>
          </div>

          <div className="flex space-x-1">
            <Button
              variant="ghost"
              size="icon"
              className="h-7 w-7"
              title="Aggiungi sottocategoria"
              onClick={() => {
                setNewCategory({
                  name: "",
                  parentId: category._id
                });
                setCategoryModalOpen(true);
              }}
            >
              <FolderPlus className="h-4 w-4 text-gray-500" />
            </Button>

            <Button
              variant="ghost"
              size="icon"
              className="h-7 w-7"
              title="Aggiungi attività"
              onClick={() => {
                setNewTask({
                  ...newTask,
                  categoryId: category._id
                });
                setTaskModalOpen(true);
              }}
            >
              <Plus className="h-4 w-4 text-gray-500" />
            </Button>

            <Button
              variant="ghost"
              size="icon"
              className="h-7 w-7"
              title="Elimina categoria"
              onClick={() => deleteCategoryHandler(category._id)}
            >
              <Trash className="h-4 w-4 text-red-500" />
            </Button>
          </div>
        </div>

        {category.expanded && (
          <>
            {/* Render tasks */}
            {category.tasks &&
              category.tasks.map((task) => (
                <div
                  key={task._id}
                  className={`flex items-center py-2 px-3 hover:bg-gray-50 rounded transition-colors ${
                    level > 0 ? "ml-4" : ""
                  }`}
                  style={{ paddingLeft: `${level * 16 + 32}px` }}
                  draggable
                  onDragStart={(e) =>
                    handleDragStart(e, { type: "task", id: task._id })
                  }
                  onDragEnd={handleDragEnd}
                >
                  <div className="flex-1 truncate">
                    <div className="flex items-center">
                      <Move className="h-3 w-3 mr-2 text-gray-400" />
                      <span
                        className="cursor-pointer hover:text-primary hover:underline text-gray-700"
                        onClick={() => viewTaskDetails(task, category.name)}
                      >
                        {task.name}
                      </span>
                    </div>

                    <div className="flex items-center mt-1 text-xs text-gray-500">
                      <Calendar className="h-3 w-3 mr-1" />
                      <span>
                        {new Date(task.startDate).toLocaleDateString("it-IT")} -{" "}
                        {new Date(task.endDate).toLocaleDateString("it-IT")}
                      </span>
                    </div>
                  </div>

                  <div className="w-24">
                    <Progress value={task.progress} className="h-2" />
                    <div className="text-xs text-center mt-1 text-gray-500">
                      {task.progress}%
                    </div>
                  </div>

                  <div className="flex space-x-1 ml-2">
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-7 w-7"
                      title="Modifica attività"
                      onClick={() => editTask(task, category._id)}
                    >
                      <Edit className="h-4 w-4 text-gray-500" />
                    </Button>

                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-7 w-7"
                      title="Elimina attività"
                      onClick={() => deleteTaskHandler(task._id)}
                    >
                      <Trash className="h-4 w-4 text-red-500" />
                    </Button>
                  </div>
                </div>
              ))}

            {/* Render subcategories */}
            {category.subcategories &&
              category.subcategories.length > 0 &&
              renderCategoriesAndTasks(category.subcategories, level + 1)}
          </>
        )}
      </div>
    ));
  };

  if (loading) {
    return (
      <>
        <ConstructionSiteNavbar />
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-primary"></div>
        </div>
      </>
    );
  }

  // Get all tasks for dependencies dropdown
  const getAllTasks = (cats, excludeTaskId = null) => {
    if (!cats) return [];

    const allTasks = [];

    const collectTasks = (categories) => {
      categories.forEach((category) => {
        if (category.tasks) {
          category.tasks.forEach((task) => {
            if (!excludeTaskId || task._id !== excludeTaskId) {
              allTasks.push({
                id: task._id,
                name: task.name || "Task",
                category: category.name || "Category"
              });
            }
          });
        }

        if (category.subcategories && category.subcategories.length > 0) {
          collectTasks(category.subcategories);
        }
      });
    };

    collectTasks(cats);
    return allTasks;
  };

  // Get all categories for dropdown
  const getAllCategories = (cats, level = 0) => {
    if (!cats) return [];

    let allCategories = [];

    cats.forEach((category) => {
      if (category && category._id) {
        allCategories.push({
          id: category._id,
          name: category.name || "Category",
          level
        });

        if (category.subcategories && category.subcategories.length > 0) {
          allCategories = [
            ...allCategories,
            ...getAllCategories(category.subcategories, level + 1)
          ];
        }
      }
    });

    return allCategories;
  };

  return (
    <>
      <ConstructionSiteNavbar />

      <div className="p-4">
        <div className="flex flex-col h-full">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 mb-2">
            <div></div>

            <div className="flex flex-wrap gap-2">
              <Button
                onClick={() => setTaskModalOpen(true)}
                variant="default"
                size="sm"
                className="h-9"
              >
                Nuova Attività
              </Button>

              <div className="relative w-56">
                <Input
                  placeholder="Cerca attività..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="h-9 pl-8 pr-3 text-sm"
                />
                <div className="absolute left-2 top-1/2 transform -translate-y-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="text-gray-400"
                  >
                    <circle cx="11" cy="11" r="8" />
                    <path d="m21 21-4.3-4.3" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 flex-1 h-[calc(100%-60px)]">
            {/* Left sidebar - Categories and Tasks */}
            <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden h-full">
              <div className="p-3 border-b border-gray-200 bg-gray-50 flex justify-between items-center">
                <h3 className="font-medium text-gray-700">
                  Attività e Categorie
                </h3>

                <Button
                  onClick={() => setCategoryModalOpen(true)}
                  variant="outline"
                  size="sm"
                  className="h-9"
                >
                  Categoria
                </Button>
              </div>

              <ScrollArea className="h-[calc(100%-50px)] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                <div className="p-2">
                  {filteredCategories && filteredCategories.length > 0 ? (
                    renderCategoriesAndTasks(filteredCategories)
                  ) : categories && categories.length > 0 ? (
                    <div className="py-4 px-3 text-center text-gray-500">
                      <AlertCircle className="h-5 w-5 mx-auto mb-2" />
                      <p>Nessun risultato trovato</p>
                    </div>
                  ) : (
                    <div className="py-6 text-center text-gray-500">
                      <FolderPlus className="h-10 w-10 mx-auto mb-2 opacity-20" />
                      <p className="mb-1">Nessuna categoria definita</p>
                      <p className="text-sm">
                        Crea una nuova categoria per iniziare
                      </p>
                    </div>
                  )}
                </div>
              </ScrollArea>
            </div>

            {/* Right section - Custom Gantt Chart */}
            <div className="md:col-span-2 bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden h-auto">
              <div className="h-[640px] overflow-hidden relative">
                {ganttTasks.length > 0 ? (
                  <CustomGanttChart
                    tasks={ganttTasks}
                    onTaskClick={handleGanttTaskClick}
                    onTaskUpdate={handleGanttTaskUpdate}
                    siteOpeningDate={siteInfo?.openingDate}
                    siteClosingDate={siteInfo?.closingDate}
                  />
                ) : (
                  <div className="flex justify-center items-center h-full">
                    <div className="text-center text-gray-500">
                      <Calendar className="h-16 w-16 mx-auto mb-4 opacity-20" />
                      <h3 className="text-lg font-medium mb-2">
                        Nessuna attività
                      </h3>
                      <p>
                        Aggiungi attività per visualizzare il diagramma di
                        Gantt.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* New/Edit Task Modal */}
      <Dialog
        open={taskModalOpen}
        onOpenChange={(open) => {
          if (!open) {
            setEditingTask(null);
            setNewTask({
              name: "",
              startDate: formatDateForInput(new Date()),
              endDate: formatDateForInput(
                new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
              ),
              progress: 0,
              dependencies: "",
              categoryId: ""
            });
          }
          setTaskModalOpen(open);
        }}
      >
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>
              {editingTask ? "Modifica Attività" : "Nuova Attività"}
            </DialogTitle>
            <DialogDescription>
              {editingTask
                ? "Modifica i dettagli dell'attività"
                : "Inserisci i dettagli della nuova attività"}
            </DialogDescription>
          </DialogHeader>

          <div className="grid grid-cols-2 gap-4 py-4">
            <div className="col-span-2">
              <Label htmlFor="name" className="mb-1.5 block">
                Nome<span className="text-red-500">*</span>
              </Label>
              <Input
                id="name"
                name="name"
                value={newTask.name}
                onChange={handleTaskChange}
                placeholder="Nome dell'attività"
              />
            </div>

            <div>
              <Label htmlFor="startDate" className="mb-1.5 block">
                Data Inizio<span className="text-red-500">*</span>
              </Label>
              <Input
                id="startDate"
                name="startDate"
                type="date"
                value={newTask.startDate}
                onChange={handleTaskChange}
                min={getDateConstraints().min}
                max={newTask.endDate || getDateConstraints().max}
              />
              <div className="text-xs text-gray-500 mt-1">
                {siteInfo &&
                  `Apertura cantiere: ${new Date(
                    siteInfo.openingDate
                  ).toLocaleDateString("it-IT")}`}
              </div>
            </div>

            <div>
              <Label htmlFor="endDate" className="mb-1.5 block">
                Data Fine<span className="text-red-500">*</span>
              </Label>
              <Input
                id="endDate"
                name="endDate"
                type="date"
                value={newTask.endDate}
                onChange={handleTaskChange}
                min={newTask.startDate || getDateConstraints().min}
                max={getDateConstraints().max}
              />
              <div className="text-xs text-gray-500 mt-1">
                {siteInfo &&
                  siteInfo.closingDate &&
                  `Chiusura cantiere: ${new Date(
                    siteInfo.closingDate
                  ).toLocaleDateString("it-IT")}`}
              </div>
            </div>

            <div>
              <Label htmlFor="progress" className="mb-1.5 block">
                Avanzamento (%)
              </Label>
              <Input
                id="progress"
                name="progress"
                type="number"
                min="0"
                max="100"
                value={newTask.progress}
                onChange={handleTaskChange}
              />
            </div>

            <div>
              <Label htmlFor="dependencies" className="mb-1.5 block">
                Dipendenza
              </Label>
              <Select
                value={newTask.dependencies || "none"}
                onValueChange={(value) =>
                  handleSelectChange("dependencies", value)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona dipendenza" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="none">Nessuna dipendenza</SelectItem>
                  {getAllTasks(categories, editingTask?._id).map((task) => (
                    <SelectItem key={task.id} value={task.id}>
                      {task.name} ({task.category})
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="col-span-2">
              <Label htmlFor="categoryId" className="mb-1.5 block">
                Categoria<span className="text-red-500">*</span>
              </Label>
              <Select
                value={newTask.categoryId}
                onValueChange={(value) =>
                  handleSelectChange("categoryId", value)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona categoria" />
                </SelectTrigger>
                <SelectContent>
                  {getAllCategories(categories).map((cat) => {
                    const catColor = getCategoryColor(cat.id).circle;
                    return (
                      <SelectItem key={cat.id} value={cat.id}>
                        <div className="flex items-center">
                          <div
                            className={`h-2 w-2 rounded-full ${catColor} mr-2`}
                          ></div>
                          {cat.level > 0 ? "└ ".repeat(cat.level) : ""}
                          {cat.name}
                        </div>
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            </div>
          </div>

          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setTaskModalOpen(false);
                setEditingTask(null);
                setNewTask({
                  name: "",
                  startDate: formatDateForInput(new Date()),
                  endDate: formatDateForInput(
                    new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
                  ),
                  progress: 0,
                  dependencies: "",
                  categoryId: ""
                });
              }}
            >
              Annulla
            </Button>
            <Button
              onClick={editingTask ? updateTaskHandler : addTask}
              disabled={isSubmitting}
            >
              {isSubmitting
                ? "Salvataggio..."
                : editingTask
                ? "Aggiorna"
                : "Aggiungi"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* New Category Modal */}
      <Dialog
        open={categoryModalOpen}
        onOpenChange={(open) => {
          if (!open) {
            setNewCategory({
              name: "",
              parentId: ""
            });
          }
          setCategoryModalOpen(open);
        }}
      >
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle>Nuova sottocategoria</DialogTitle>
            <DialogDescription>
              Crea una nuova categoria per organizzare le attività
            </DialogDescription>
          </DialogHeader>

          <div className="grid gap-4 py-4">
            <div>
              <Label htmlFor="name" className="mb-1.5 block">
                Nome sottocategoria<span className="text-red-500">*</span>
              </Label>
              <Input
                id="name"
                name="name"
                value={newCategory.name}
                onChange={handleCategoryChange}
                placeholder="Nome della categoria"
              />
            </div>

            <div>
              <Label htmlFor="parentId" className="mb-1.5 block">
                Categoria Padre
              </Label>
              <Select
                value={newCategory.parentId || "root"}
                onValueChange={(value) => handleSelectChange("parentId", value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Categoria principale" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="root">Categoria principale</SelectItem>
                  {getAllCategories(categories).map((cat) => {
                    const catColor = getCategoryColor(cat.id).circle;
                    return (
                      <SelectItem key={cat.id} value={cat.id}>
                        <div className="flex items-center">
                          <div
                            className={`h-2 w-2 rounded-full ${catColor} mr-2`}
                          ></div>
                          {cat.level > 0 ? "└ ".repeat(cat.level) : ""}
                          {cat.name}
                        </div>
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            </div>
          </div>

          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setCategoryModalOpen(false);
                setNewCategory({
                  name: "",
                  parentId: ""
                });
              }}
            >
              Annulla
            </Button>
            <Button onClick={addCategory} disabled={isSubmitting}>
              {isSubmitting ? "Salvataggio..." : "Aggiungi"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Task Details Modal */}
      <Dialog open={taskInfoModalOpen} onOpenChange={setTaskInfoModalOpen}>
        <DialogContent className="sm:max-w-[500px]">
          {selectedTask && (
            <>
              <DialogHeader>
                <DialogTitle>{selectedTask.name}</DialogTitle>
                <DialogDescription className="flex items-center">
                  <span
                    className={`h-2.5 w-2.5 rounded-full mr-2 ${
                      getCategoryColor(selectedTask.categoryId).circle
                    }`}
                  ></span>
                  Categoria: {selectedTask.categoryName}
                </DialogDescription>
              </DialogHeader>

              <div className="py-4">
                <div className="space-y-4">
                  <div className="bg-gray-50 p-3 rounded-md">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">
                      Date
                    </h4>
                    <div className="flex items-center">
                      <Calendar className="h-4 w-4 mr-2 text-gray-500" />
                      <p className="text-gray-600">
                        Dal{" "}
                        {new Date(selectedTask.startDate).toLocaleDateString(
                          "it-IT"
                        )}{" "}
                        al{" "}
                        {new Date(selectedTask.endDate).toLocaleDateString(
                          "it-IT"
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="bg-gray-50 p-3 rounded-md">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">
                      Avanzamento
                    </h4>
                    <div className="flex items-center">
                      <Progress
                        value={selectedTask.progress}
                        className="h-2 flex-1 mr-2"
                      />
                      <span className="text-gray-600 font-medium">
                        {selectedTask.progress}%
                      </span>
                    </div>
                  </div>

                  {selectedTask.dependencies && (
                    <div className="bg-gray-50 p-3 rounded-md">
                      <h4 className="text-sm font-medium text-gray-700 mb-2">
                        Dipendenza
                      </h4>
                      <div className="flex items-center">
                        <Link className="h-4 w-4 mr-2 text-gray-500" />
                        <p className="text-gray-600">
                          {(() => {
                            // Find the dependency task name
                            const findTask = (cats, taskId) => {
                              if (!cats) return "Attività sconosciuta";

                              for (const cat of cats) {
                                if (cat.tasks) {
                                  for (const task of cat.tasks) {
                                    if (task._id === taskId) {
                                      return `${task.name} (${cat.name})`;
                                    }
                                  }
                                }

                                if (
                                  cat.subcategories &&
                                  cat.subcategories.length > 0
                                ) {
                                  const name = findTask(
                                    cat.subcategories,
                                    taskId
                                  );
                                  if (name) return name;
                                }
                              }
                              return "Attività sconosciuta";
                            };

                            return findTask(
                              categories,
                              selectedTask.dependencies
                            );
                          })()}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="bg-gray-50 p-3 rounded-md">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">
                      Durata
                    </h4>
                    <p className="text-gray-600">
                      {(() => {
                        const start = new Date(selectedTask.startDate);
                        const end = new Date(selectedTask.endDate);
                        const diffTime = Math.abs(end - start);
                        const diffDays = Math.ceil(
                          diffTime / (1000 * 60 * 60 * 24)
                        );
                        return `${diffDays} giorni`;
                      })()}
                    </p>
                  </div>
                </div>
              </div>

              <DialogFooter>
                <Button
                  variant="outline"
                  onClick={() => setTaskInfoModalOpen(false)}
                >
                  Chiudi
                </Button>
                <Button
                  onClick={() => {
                    const findCategoryId = (cats, taskId) => {
                      if (!cats) return null;

                      for (const cat of cats) {
                        if (cat.tasks) {
                          for (const task of cat.tasks) {
                            if (task._id === taskId) {
                              return cat._id;
                            }
                          }
                        }

                        if (cat.subcategories && cat.subcategories.length > 0) {
                          const id = findCategoryId(cat.subcategories, taskId);
                          if (id) return id;
                        }
                      }
                      return null;
                    };

                    const categoryId = findCategoryId(
                      categories,
                      selectedTask._id
                    );
                    if (categoryId) {
                      editTask(selectedTask, categoryId);
                      setTaskInfoModalOpen(false);
                    }
                  }}
                >
                  Modifica
                </Button>
              </DialogFooter>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Cronoprogramma;
