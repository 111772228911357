import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../../ui/tabs";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Produttore from "./produttore";
import Destinatario from "./destinatario";
import Trasportatori from "./trasportatori";
import Intermediari from "./intermediari";
import Rifiuto from "./rifiuto";
import TrasportoPartenza from "./trasportoPartenza";
import Annotazioni from "./annotazioni";
import { Card, CardContent } from "../../../ui/card";
import { Button } from "../../../ui/button";
import { FormularioProvider } from "./context/FormularioContext";

const FORM_TABS = [
  { name: "Produttore", value: "produttore" },
  { name: "Destinatario", value: "destinatario" },
  { name: "Trasportatori", value: "trasportatori" },
  { name: "Intermediari", value: "intermediari" },
  { name: "Rifiuto", value: "rifiuto" },
  { name: "Trasporto/Partenza", value: "trasporto_partenza" },
  { name: "Annotazioni", value: "annotazioni" },
];

const ScrollableTabsList = ({ children }) => {
  const scrollRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
        setShowLeftArrow(scrollLeft > 0);
        setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1);
      }
    };

    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
      handleScroll();

      const resizeObserver = new ResizeObserver(handleScroll);
      resizeObserver.observe(scrollElement);

      return () => {
        scrollElement.removeEventListener("scroll", handleScroll);
        resizeObserver.disconnect();
      };
    }
  }, []);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = direction === "left" ? -100 : 100;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <div className="relative bg-[#06052C] rounded overflow-hidden">
      {showLeftArrow && (
        <button
          onClick={() => scroll("left")}
          className="absolute left-0 top-1/2 -translate-y-1/2 bg-white rounded-md p-1 z-10"
        >
          <ChevronLeft size={20} />
        </button>
      )}
      <div ref={scrollRef} className="overflow-x-auto scrollbar-hide">
        {children}
      </div>
      {showRightArrow && (
        <button
          onClick={() => scroll("right")}
          className="absolute right-0 top-1/2 -translate-y-1/2 bg-white rounded-md p-1 z-10"
        >
          <ChevronRight size={20} />
        </button>
      )}
    </div>
  );
};

const CreateFormularioComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("produttore");

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    const tab = FORM_TABS.find((t) => t.value === path);
    if (tab) {
      setActiveTab(tab.value);
    } else {
      navigate("/formulari/create/produttore", { replace: true });
    }
  }, [location, navigate]);

  const handleTabChange = (value) => {
    navigate(`/formulari/create/${value}`);
  };

  const currentTabIndex = FORM_TABS.findIndex((tab) => tab.value === activeTab);

  const handleNavigation = (direction) => {
    const newIndex =
      direction === "next"
        ? Math.min(currentTabIndex + 1, FORM_TABS.length - 1)
        : Math.max(currentTabIndex - 1, 0);

    handleTabChange(FORM_TABS[newIndex].value);
  };

  return (
    <FormularioProvider>
      <div className="h-full md:p-6 p-2 pt-1">
        <Tabs
          value={activeTab}
          onValueChange={handleTabChange}
          className="w-full"
        >
          <ScrollableTabsList>
            <TabsList className="w-full flex justify-start h-auto p-1 bg-transparent">
              {FORM_TABS.map((tab) => (
                <TabsTrigger
                  key={tab.value}
                  value={tab.value}
                  className="py-2 px-4 text-white whitespace-nowrap data-[state=active]:bg-white data-[state=active]:text-blue-950"
                >
                  {tab.name}
                </TabsTrigger>
              ))}
            </TabsList>
          </ScrollableTabsList>

          {/* Summary Section */}
          <div className="my-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <Card className="bg-white shadow-sm">
              <CardContent className="p-4">
                <div className="flex flex-col gap-2">
                  <span className="text-sm font-medium text-muted-foreground">
                    FIR
                  </span>
                  <span className="text-lg font-semibold">IT-000123</span>
                </div>
              </CardContent>
            </Card>

            <Card className="bg-white shadow-sm">
              <CardContent className="p-4">
                <div className="flex flex-col gap-2">
                  <span className="text-sm font-medium text-muted-foreground">
                    Denominazione operatore
                  </span>
                  <span className="text-lg font-semibold truncate">
                    Sagia Costruzioni S.R.L.
                  </span>
                </div>
              </CardContent>
            </Card>

            <Card className="bg-white shadow-sm">
              <CardContent className="p-4">
                <div className="flex flex-col gap-2">
                  <span className="text-sm font-medium text-muted-foreground">
                    Nome unità locale
                  </span>
                  <span className="text-lg font-semibold truncate">
                    Sede Operativa Napoli
                  </span>
                </div>
              </CardContent>
            </Card>

            <Card className="bg-white shadow-sm">
              <CardContent className="p-4">
                <div className="flex flex-col gap-2">
                  <span className="text-sm font-medium text-muted-foreground">
                    Numero iscrizione unità locale
                  </span>
                  <span className="text-lg font-semibold">REG-2023/456</span>
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Tabs container */}
          <div className="space-y-6">
            <TabsContent value="produttore">
              <Produttore />
            </TabsContent>
            <TabsContent value="destinatario">
              <Destinatario />
            </TabsContent>
            <TabsContent value="trasportatori">
              <Trasportatori />
            </TabsContent>
            <TabsContent value="intermediari">
              <Intermediari />
            </TabsContent>
            <TabsContent value="rifiuto">
              <Rifiuto />
            </TabsContent>
            <TabsContent value="trasporto_partenza">
              <TrasportoPartenza />
            </TabsContent>
            <TabsContent value="annotazioni">
              <Annotazioni />
            </TabsContent>

            {/* Navigation Card */}
            <Card className="mt-6">
              <CardContent className="flex justify-between items-center py-4">
                <Button
                  variant="outline"
                  onClick={() => handleNavigation("prev")}
                  disabled={currentTabIndex === 0}
                  className="w-fit select-none"
                >
                  <ChevronRight className="w-4 h-4 mr-2 rotate-180" />
                  Indietro
                </Button>

                <span className="text-sm text-muted-foreground">
                  Tab {currentTabIndex + 1} di {FORM_TABS.length}
                </span>

                <Button
                  onClick={() => handleNavigation("next")}
                  disabled={currentTabIndex === FORM_TABS.length - 1}
                  className="w-fit select-none"
                >
                  Avanti
                  <ChevronRight className="w-4 h-4 ml-2" />
                </Button>
              </CardContent>
            </Card>
          </div>
        </Tabs>
      </div>
    </FormularioProvider>
  );
};

export default CreateFormularioComponent;
