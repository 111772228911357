import React, { useState } from "react";
import { Button } from "../../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "../../ui/table";
import { Edit, Trash2, Filter } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "../../ui/dialog";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import SearchBar from "../sharedComponent/SearchBar";

const Formulari = () => {
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    status: "",
    type: ""
  });

  const handleFilterChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const applyFilters = () => {
    setIsFilterDialogOpen(false);
  };

  return (
    <div className="flex p-6 gap-4 flex-col">
      {/* top bar with improved responsive layout */}
      <div className="flex flex-col gap-4 lg:flex-row lg:justify-between lg:items-center">
        <div className="flex flex-col gap-2 sm:flex-row">
          <Button className="w-full sm:w-auto">Crea FIR</Button>
          <Button variant="outline" className="w-full sm:w-auto">
            Vidima FIR vuoto
          </Button>
        </div>

        <div className="flex flex-col gap-2 sm:flex-row sm:items-center sm:flex-initial flex-1">
          <Select defaultValue="unit1">
            <SelectTrigger className="w-full sm:w-[180px] shrink-0">
              <SelectValue placeholder="Unità locale" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="unit1">Unità Locale 1</SelectItem>
              <SelectItem value="unit2">Unità Locale 2</SelectItem>
              <SelectItem value="unit3">Unità Locale 3</SelectItem>
            </SelectContent>
          </Select>

          <div className="flex gap-2 sm:flex-1">
            <div className="flex-1">
              <SearchBar onSearch={(query) => {}} />
            </div>
            <Button
              variant="outline"
              onClick={() => setIsFilterDialogOpen(true)}
              className="flex items-center gap-2 shrink-0"
            >
              <Filter className="h-4 w-4" />
              Filtri
            </Button>
          </div>
        </div>
      </div>

      {/* Filter Dialog */}
      <Dialog open={isFilterDialogOpen} onOpenChange={setIsFilterDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Filtri</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label htmlFor="startDate">Data inizio</Label>
                <Input
                  id="startDate"
                  type="date"
                  value={filters.startDate}
                  onChange={(e) =>
                    handleFilterChange("startDate", e.target.value)
                  }
                />
              </div>
              <div>
                <Label htmlFor="endDate">Data fine</Label>
                <Input
                  id="endDate"
                  type="date"
                  value={filters.endDate}
                  onChange={(e) =>
                    handleFilterChange("endDate", e.target.value)
                  }
                />
              </div>
            </div>

            <div>
              <Label htmlFor="type">Tipo FIR</Label>
              <Select
                value={filters.type}
                onValueChange={(value) => handleFilterChange("type", value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona tipo" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">Tutti</SelectItem>
                  <SelectItem value="in">In entrata</SelectItem>
                  <SelectItem value="out">In uscita</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div>
              <Label htmlFor="status">Stato</Label>
              <Select
                value={filters.status}
                onValueChange={(value) => handleFilterChange("status", value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona stato" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">Tutti</SelectItem>
                  <SelectItem value="completed">Completati</SelectItem>
                  <SelectItem value="pending">In attesa</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <DialogFooter className="flex justify-between">
            <Button
              type="button"
              variant="outline"
              onClick={() =>
                setFilters({
                  startDate: "",
                  endDate: "",
                  status: "",
                  type: ""
                })
              }
            >
              Reset
            </Button>
            <div className="flex gap-2">
              <Button
                type="button"
                variant="outline"
                onClick={() => setIsFilterDialogOpen(false)}
              >
                Annulla
              </Button>
              <Button type="button" onClick={applyFilters}>
                Applica
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Rest of the table component */}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>FIR</TableHead>
              <TableHead>Data Creazione</TableHead>
              <TableHead>Codice EER</TableHead>
              <TableHead>Quantità</TableHead>
              <TableHead>Produttore</TableHead>
              <TableHead>Trasportatori</TableHead>
              <TableHead>Registro</TableHead>
              <TableHead className="text-right"></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Cognome</TableCell>
              <TableCell>CF</TableCell>
              <TableCell>Telefono</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Indirizzo</TableCell>
              <TableCell>Comune</TableCell>
              <TableCell className="text-right">
                <div className="flex justify-end gap-2">
                  <Button variant="ghost" size="sm">
                    <Edit className="h-4 w-4" />
                  </Button>
                  <Button variant="ghost" size="sm">
                    <Trash2 className="h-4 w-4 text-red-500" />
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Formulari;
