import React from "react";
import Layout from "../../../components/admin/sharedComponent/Layout";
import RegistriComponent from "../../../components/admin/registri/index";

const Registri = () => {
  return (
    <Layout>
      <RegistriComponent />
    </Layout>
  );
};

export default Registri;
