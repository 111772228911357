import jsPDF from "jspdf";
import "jspdf-autotable";
import { format } from "date-fns";
import { it } from "date-fns/locale";

const addGiornaleLavoriDataPages = async (doc, entries) => {
  const pageWidth = doc.internal.pageSize.width;
  const margin = 16;
  const navyBlue = [0, 0, 128];

  entries.forEach((entry, index) => {
    if (index > 0) doc.addPage();

    // Date Header
    doc.setFontSize(14);
    doc.setFont("Montserrat", "bold");
    doc.setTextColor(0, 0, 0);
    const dateStr = format(new Date(entry.date), "dd MMMM yyyy", {
      locale: it
    });
    doc.text(`Data: ${dateStr}`, margin, 30);

    // Weather Information Table
    doc.autoTable({
      startY: 40,
      head: [["Condizioni meteorologiche"]],
      body: [
        [
          `Temperatura: ${entry.temperature}°C   |   Meteo: ${entry.weatherConditions}`
        ]
      ],
      styles: {
        font: "Montserrat",
        fontSize: 10,
        cellPadding: 8
      },
      headStyles: {
        fillColor: false,
        textColor: [0, 0, 0],
        fontStyle: "bold",
        halign: "left"
      },
      theme: "plain",
      margin: { left: margin, right: margin }
    });

    // Workers and Equipment Table
    doc.setFont("Montserrat", "bold");
    doc.text(
      "Operai, Mezzi ed Attrezzatura utilizzati:",
      margin,
      doc.autoTable.previous.finalY + 20
    );

    // Table with header groups like in Computi metrici
    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 30,
      head: [
        // First header row with grouped columns
        [
          { content: "Dettagli", colSpan: 2 },
          { content: "Quantità", colSpan: 1 }
        ],
        // Second header row with specific columns
        ["Nome", "Azienda", "Q.tà"]
      ],
      body: entry.entries.map((item) => [
        item.name,
        item.company,
        item.quantity.toString()
      ]),
      styles: {
        font: "Montserrat",
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [80, 80, 80],
        textColor: [0, 0, 0]
      },
      headStyles: {
        fillColor: false,
        textColor: [0, 0, 0],
        fontStyle: "bold",
        halign: "center"
      },
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: "auto" },
        2: { cellWidth: 40, halign: "center" }
      },
      theme: "plain",
      margin: { left: margin, right: margin }
    });

    // Annotations Table
    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 20,
      head: [["Annotazioni ed osservazioni"]],
      body: [[entry.annotations || ""]],
      styles: {
        font: "Montserrat",
        fontSize: 10,
        cellPadding: 8,
        minCellHeight: 20
      },
      headStyles: {
        fillColor: false,
        textColor: [0, 0, 0],
        fontStyle: "bold",
        halign: "left"
      },
      theme: "plain",
      margin: { left: margin, right: margin }
    });

    // Add footer to each page
    addCompanyFooter(doc);
  });
};

const createGiornaleLavoriLandingPage = async (
  doc,
  constructionSite,
  generalData = {}
) => {
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const margin = 16;
  const navyBlue = [0, 0, 128];

  // Header Section with Municipality
  doc.setFontSize(32);
  doc.setFont("Montserrat", "bold");
  doc.setTextColor(...navyBlue);

  const headerTitle = `Comune di ${constructionSite.city || ""}`;
  const headerTitleWidth = doc.getTextWidth(headerTitle);
  doc.text(headerTitle, (pageWidth - headerTitleWidth) / 2, 40);

  // Province
  doc.setFontSize(20);
  const subHeader = `Provincia di ${constructionSite.province || ""}`;
  const subHeaderWidth = doc.getTextWidth(subHeader);
  doc.text(subHeader, (pageWidth - subHeaderWidth) / 2, 55);

  // Divider
  doc.setLineWidth(0.5);
  doc.setDrawColor(...navyBlue);
  doc.line(margin, 65, pageWidth - margin, 65);

  // Main Title
  doc.setFontSize(32);
  const mainTitle = "GIORNALE DEI LAVORI";
  const mainTitleWidth = doc.getTextWidth(mainTitle);
  doc.text(mainTitle, (pageWidth - mainTitleWidth) / 2, 100);

  // Project Description (Oggetto)
  const labelY = 130;
  doc.setFillColor(...navyBlue);
  doc.setTextColor(255, 255, 255);
  doc.rect(margin, labelY, 50, 12, "F");
  doc.setFontSize(14);
  doc.setFont("Montserrat", "bold");
  doc.text("OGGETTO :", margin + 5, labelY + 8);

  doc.setTextColor(0, 0, 0);
  doc.setFont("Montserrat", "normal");
  const maxWidth = pageWidth - (margin + 60 + margin);
  const oggetto = constructionSite.description || "";
  const splitOggetto = doc.splitTextToSize(oggetto, maxWidth);
  splitOggetto.forEach((line, index) => {
    doc.text(line, margin + 60, labelY + 8 + index * 8);
  });

  // Client Information
  const committenteLabelY = labelY + 40;
  doc.setTextColor(255, 255, 255);
  doc.setFillColor(...navyBlue);
  doc.rect(margin, committenteLabelY, 50, 12, "F");
  doc.setFont("Montserrat", "bold");
  doc.text("COMMITTENTE :", margin + 5, committenteLabelY + 8);

  // Get client info from nested structure
  doc.setTextColor(0, 0, 0);
  doc.setFont("Montserrat", "normal");

  let clientName = "Cliente non specificato";

  if (constructionSite.client) {
    // Check if client is the response object
    if (constructionSite.client.client) {
      const clientData = constructionSite.client.client;
      clientName =
        clientData.typology === "Private"
          ? clientData.fullName
          : clientData.companyName;
    }
    // Check if client is direct client object
    else if (constructionSite.client.typology) {
      clientName =
        constructionSite.client.typology === "Private"
          ? constructionSite.client.fullName
          : constructionSite.client.companyName;
    }
  }

  doc.text(clientName, margin + 60, committenteLabelY + 8);

  // Add footer
  await addCompanyFooter(doc);
};

const addCompanyFooter = async (doc) => {
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const footerHeight = 24;
  const footerY = pageHeight - footerHeight;
  const margin = 16;
  const navyBlue = [0, 0, 128];

  // Get company info
  const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

  // Background
  doc.setFillColor(...navyBlue);
  doc.rect(0, footerY, pageWidth, footerHeight, "F");

  // Footer text
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(10);
  doc.setFont("Montserrat", "normal");

  if (companyInfo) {
    // Company details
    const leftFooterLine1 = [
      companyInfo.companyName,
      companyInfo.address,
      companyInfo.postalCode,
      companyInfo.city,
      companyInfo.province
    ]
      .filter(Boolean)
      .join(", ");

    const leftFooterLine2 = [
      companyInfo.telephoneNumber && `Tel: ${companyInfo.telephoneNumber}`,
      companyInfo.businessEmail
    ]
      .filter(Boolean)
      .join(" - ");

    doc.text(leftFooterLine1, margin, footerY + 12);
    doc.text(leftFooterLine2, margin, footerY + 20);
  }

  // Copyright text
  doc.text(
    "Powered by Restruct © Galdiero Systems Srl",
    pageWidth - margin - 60,
    footerY + 16
  );
};

export const exportGiornaleLavoriPDF = async (constructionSite, entries) => {
  try {
    //console.log('Constructing PDF with site data:', constructionSite);
    //console.log('Client information:', constructionSite.client);

    const doc = new jsPDF();
    await createGiornaleLavoriLandingPage(doc, constructionSite);
    doc.addPage();
    await addGiornaleLavoriDataPages(doc, entries);
    return doc;
  } catch (error) {
    console.error("Error generating Giornale dei lavori PDF:", error);
    throw error;
  }
};

export const downloadGiornaleLavoriPDF = async (constructionSite, entries) => {
  try {
    const doc = await exportGiornaleLavoriPDF(constructionSite, entries);
    doc.save(`Giornale_dei_lavori_${constructionSite.name}.pdf`);
    return true;
  } catch (error) {
    console.error("Error downloading PDF:", error);
    throw error;
  }
};
