import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const addPoints = async (data) => {
  try {
    const response = await axios.post('/violations/add-points', data);
    return response;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const getViolations = async () => {
  try {
    const response = await axios.get('/violations');
    return response;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const createViolation = async (data) => {
  try {
    const response = await axios.post('/violations', data);
    return response;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const deleteViolation = async (id) => {
  try {
    const response = await axios.delete(`/violations/${id}`);
    return response;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const updateViolation = async (id, data) => {
  try {
    const response = await axios.patch(`/violations/${id}`, data);
    return response;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const resetPoints = async () => {
  try {
    const response = await axios.post('/violations/reset-points');
    return response;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const updatePoints = async (points) => {
  try {
    const response = await axios.patch('/violations/update-points', { points });
    return response;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const deletePoints = async (id, isPointAddition) => {
  try {
    const response = await axios.delete(`/violations/${id}/points`);
    return response;
  } catch (error) {
    throw error?.response?.data || error;
  }
};