import { createJsonTypeInstance } from "./index";
const axios = createJsonTypeInstance();

export const fetchCurrencies = async () => {
  try {
    const response = await axios.get("/valuta");
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchUm = async () => {
  try {
    const response = await axios.get("/um");
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchDdtInvoiceDetails = async (id) => {
  try {
    const response = await axios.get(`/ddt_invoice/${id}`);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchSupplier = async (companyId) => {
  try {
    const response = await axios.get(`/Supplier/all-Suppliers/${companyId}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const fetchCantiere = async (companyId) => {
  try {
    // Update to match the construction-sites API route
    const response = await axios.get(`/construction-sites`, {
      params: { companyId }
    });
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const fetchClients = async (companyId) => {
  try {
    const response = await axios.get(
      `/client/all-Clients_computimetric/${companyId}`
    );
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const addDdt = async (data) => {
  //console.log(`data sent through apis ${data}`);
  try {
    const response = await axios.post("/ddt", data);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
