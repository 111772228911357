import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import Budget from '../../../components/admin/budget/index'

const BudgetPage = () => {
  return (
    <Layout>
      <Budget />
    </Layout>
  )
}

export default BudgetPage