import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import FormulariComponent from '../../../components/admin/formulari/index'

const Formulari = () => {
  return (
    <Layout>
      <FormulariComponent />
    </Layout>
  )
}

export default Formulari