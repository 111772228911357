import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../../ui/card";
import { Button } from "../../ui/button";
import { Copy, Users } from 'lucide-react';
import { toast } from "react-toastify";
import { getReferralCode, getReferrals } from '../../../apis/Referral';
import SettingsTabs from './components/SettingsTabs';

const DOMAIN = "https://restruct.glds.it";

function InvitationCode() {
    const [referralCode, setReferralCode] = useState("");
    const [referrals, setReferrals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingReferrals, setIsLoadingReferrals] = useState(true);

    const fullInviteUrl = `${DOMAIN}/register?ref=${referralCode}`;

    useEffect(() => {
        fetchReferralCode();
        fetchReferrals();
    }, []);

    const fetchReferralCode = async () => {
        try {
            setIsLoading(true);
            const response = await getReferralCode();
            if (response.success && response.referralCode) {
                setReferralCode(response.referralCode);
            } else {
                throw new Error(response.message || "Errore nel recuperare il codice di invito");
            }
        } catch (error) {
            toast.error(error.message || "Errore nel recuperare il codice di invito");
            console.error('Error fetching referral code:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const fetchReferrals = async () => {
        try {
            setIsLoadingReferrals(true);
            const response = await getReferrals();
            if (response.success) {
                setReferrals(response.referrals || []);
            } else {
                throw new Error(response.message || "Errore nel recuperare gli inviti");
            }
        } catch (error) {
            toast.error(error.message || "Errore nel recuperare gli inviti");
            console.error('Error fetching referrals:', error);
        } finally {
            setIsLoadingReferrals(false);
        }
    };

    const copyToClipboard = async (textToCopy, type = 'link') => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            toast.success(type === 'link' ? "Link di invito copiato!" : "Codice copiato!");
        } catch (err) {
            toast.error("Errore durante la copia");
            console.error('Copy error:', err);
        }
    };

    if (isLoading) {
        return (
            <SettingsTabs defaultTab="/invitation-code">
                <div className="flex items-center justify-center min-h-[400px]">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                </div>
            </SettingsTabs>
        );
    }

    return (
        <SettingsTabs defaultTab="/invitation-code">
            <Card className="mt-6">
                <CardHeader>
                    <div className="flex items-center justify-between">
                        <div>
                            <CardTitle>Il Tuo Codice Invito</CardTitle>
                            <CardDescription>
                                Condividi questo link o codice di invito con i tuoi amici.
                            </CardDescription>
                        </div>
                        <div className="flex items-center space-x-2">
                            <Users className="h-5 w-5 text-gray-500" />
                            <span className="text-sm text-gray-500">
                                {referrals.length} inviti accettati
                            </span>
                        </div>
                    </div>
                </CardHeader>
                <CardContent className="space-y-6">
                    <div>
                        <div className="mb-4">
                            <h4 className="text-sm font-medium mb-2">Codice di invito</h4>
                            <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                                <code className="text-sm font-mono">{referralCode}</code>
                                <Button 
                                    variant="outline" 
                                    size="sm"
                                    onClick={() => copyToClipboard(referralCode, 'code')}
                                    className="ml-4"
                                >
                                    <Copy className="h-4 w-4" />
                                </Button>
                            </div>
                        </div>
                        
                        <div>
                            <h4 className="text-sm font-medium mb-2">Link di registrazione completo</h4>
                            <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                                <code className="text-sm font-mono break-all">{fullInviteUrl}</code>
                                <Button 
                                    variant="outline" 
                                    size="sm"
                                    onClick={() => copyToClipboard(fullInviteUrl)}
                                    className="ml-4 flex-shrink-0"
                                >
                                    <Copy className="h-4 w-4" />
                                </Button>
                            </div>
                        </div>
                    </div>
                    
                    {!isLoadingReferrals && referrals.length > 0 && (
                        <div>
                            <h4 className="text-sm font-medium mb-2">Utenti Invitati</h4>
                            <div className="bg-gray-50 rounded-lg p-4">
                                <table className="w-full">
                                    <thead>
                                        <tr>
                                            <th className="text-left text-sm font-medium text-gray-500">Nome</th>
                                            <th className="text-left text-sm font-medium text-gray-500">Email</th>
                                            <th className="text-left text-sm font-medium text-gray-500">Data Registrazione</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {referrals.map((referral) => (
                                            <tr key={referral._id}>
                                                <td className="py-2 text-sm">{referral.username}</td>
                                                <td className="py-2 text-sm">{referral.email}</td>
                                                <td className="py-2 text-sm">
                                                    {new Date(referral.createdAt).toLocaleDateString('it-IT')}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                    
                    <p className="text-sm text-gray-500">
                        Gli utenti possono utilizzare sia il codice di invito durante la registrazione, 
                        sia accedere direttamente tramite il link completo.
                    </p>
                </CardContent>
            </Card>
        </SettingsTabs>
    );
}

export default InvitationCode;