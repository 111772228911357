import React, { useState, useEffect } from "react";
import { useFormulario } from "./context/FormularioContext";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import { RadioGroup, RadioGroupItem } from "../../../ui/radio-group";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import { Switch } from "../../../ui/switch";

const Destinatario = () => {
  const { updateDestinatario, formularioData } = useFormulario();
  const destinatarioData = formularioData.destinatario;

  const [useCurrentUnit, setUseCurrentUnit] = useState(
    destinatarioData.useCurrentUnit || false
  );

  useEffect(() => {
    setUseCurrentUnit(destinatarioData.useCurrentUnit || false);
  }, [destinatarioData]);

  const handleDestinatarioChange = (section, field, value) => {
    updateDestinatario({
      ...destinatarioData,
      [section]: {
        ...destinatarioData[section],
        [field]: value,
      },
    });
  };

  const handleUseCurrentUnitChange = (checked) => {
    setUseCurrentUnit(checked);
    updateDestinatario({
      ...destinatarioData,
      useCurrentUnit: checked,
    });
  };

  return (
    <div className="space-y-6">
      {/* Recipient Section */}
      <Card>
        <CardHeader>
          <CardTitle>Destinatario</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {/* First row */}
            <div className="flex items-center justify-between w-fit gap-4">
              <Label htmlFor="useCurrentUnit" className="font-medium">
                Ricopia anagrafica dell'unità corrente
              </Label>
              <Switch
                id="useCurrentUnit"
                checked={useCurrentUnit}
                onCheckedChange={handleUseCurrentUnitChange}
              />
            </div>

            {/* Second row */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="space-y-2">
                <Label htmlFor="nazionalita">Nazionalità</Label>
                <Select
                  value={destinatarioData.anagrafica.nazionalita}
                  onValueChange={(value) =>
                    handleDestinatarioChange("anagrafica", "nazionalita", value)
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona nazionalità" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="IT">Italia</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="codiceFiscale">Codice Fiscale</Label>
                <Input
                  id="codiceFiscale"
                  value={destinatarioData.anagrafica.codiceFiscale}
                  onChange={(e) =>
                    handleDestinatarioChange(
                      "anagrafica",
                      "codiceFiscale",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="denominazione">Denominazione</Label>
                <Input
                  id="denominazione"
                  value={destinatarioData.anagrafica.denominazione || ""}
                  onChange={(e) =>
                    handleDestinatarioChange(
                      "anagrafica",
                      "denominazione",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>

            {/* Third row */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="space-y-2">
                <Label htmlFor="nazione">Nazione</Label>
                <Select
                  value={destinatarioData.anagrafica.nazione || ""}
                  onValueChange={(value) =>
                    handleDestinatarioChange("anagrafica", "nazione", value)
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona nazione" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="IT">Italia</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="provincia">Provincia</Label>
                <Select
                  value={destinatarioData.anagrafica.provincia || ""}
                  onValueChange={(value) =>
                    handleDestinatarioChange("anagrafica", "provincia", value)
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona provincia" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="RM">Roma</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="comune">Comune</Label>
                <Select
                  value={destinatarioData.anagrafica.comune || ""}
                  onValueChange={(value) =>
                    handleDestinatarioChange("anagrafica", "comune", value)
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona comune" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="ROMA">Roma</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            {/* Fourth row */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="space-y-2">
                <Label htmlFor="indirizzo">Indirizzo</Label>
                <Input
                  id="indirizzo"
                  value={destinatarioData.anagrafica.indirizzo || ""}
                  onChange={(e) =>
                    handleDestinatarioChange(
                      "anagrafica",
                      "indirizzo",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="civico">Civico</Label>
                <Input
                  id="civico"
                  type="number"
                  value={destinatarioData.anagrafica.civico || ""}
                  onChange={(e) =>
                    handleDestinatarioChange(
                      "anagrafica",
                      "civico",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="cap">CAP</Label>
                <Input
                  id="cap"
                  type="number"
                  value={destinatarioData.anagrafica.cap || ""}
                  onChange={(e) =>
                    handleDestinatarioChange(
                      "anagrafica",
                      "cap",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Destination Type Section */}
      <Card>
        <CardHeader>
          <CardTitle>Destinazione</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center gap-8">
            <div className="flex items-center gap-4">
              <RadioGroup
                value={destinatarioData.destinazione.tipo}
                onValueChange={(value) =>
                  handleDestinatarioChange("destinazione", "tipo", value)
                }
                className="flex items-center gap-4"
              >
                <div className="flex items-center gap-2">
                  <RadioGroupItem value="recupero" id="recupero" />
                  <Label htmlFor="recupero">Recupero</Label>
                </div>
                <div className="flex items-center gap-2">
                  <RadioGroupItem value="smaltimento" id="smaltimento" />
                  <Label htmlFor="smaltimento">Smaltimento</Label>
                </div>
              </RadioGroup>
            </div>
            <div className="flex-1 max-w-md">
              <Select
                value={destinatarioData.destinazione.attivita}
                onValueChange={(value) =>
                  handleDestinatarioChange("destinazione", "attivita", value)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona attività" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="R1">
                    R1 - Utilizzazione principale come combustibile
                  </SelectItem>
                  <SelectItem value="R2">
                    R2 - Rigenerazione/recupero di solventi
                  </SelectItem>
                  <SelectItem value="R3">
                    R3 - Riciclo/recupero sostanze organiche
                  </SelectItem>
                  {/* Add more activities as needed */}
                </SelectContent>
              </Select>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Authorizations section */}
      <Card>
        <CardHeader>
          <CardTitle>Autorizzazioni</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="space-y-2">
              <Label htmlFor="numeroAlbo">Numero descrizione albo</Label>
              <Input
                id="numeroAlbo"
                value={destinatarioData.autorizzazioni?.numeroAlbo || ""}
                onChange={(e) =>
                  handleDestinatarioChange(
                    "autorizzazioni",
                    "numeroAlbo",
                    e.target.value
                  )
                }
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="tipoAutorizzazione">Tipo autorizzazione</Label>
              <Select
                value={
                  destinatarioData.autorizzazioni?.tipoAutorizzazione || ""
                }
                onValueChange={(value) =>
                  handleDestinatarioChange(
                    "autorizzazioni",
                    "tipoAutorizzazione",
                    value
                  )
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona tipo" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="tipo1">Tipo 1</SelectItem>
                  <SelectItem value="tipo2">Tipo 2</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="numero">Numero</Label>
              <Input
                id="numero"
                type="number"
                value={destinatarioData.autorizzazioni?.numero || ""}
                onChange={(e) =>
                  handleDestinatarioChange(
                    "autorizzazioni",
                    "numero",
                    e.target.value
                  )
                }
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Destinatario;
