import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AccordionItem from "./AccordionItem";
import MainData from "./MainData";
import Position from "./Position";
import Contacts from "./Contacts";
import Extra from "./Extra";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  addClient,
  getSingleClient,
  updateClient
} from "../../../../../apis/client";

const AddClient = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;
  const [singleClientData, setSingleClientData] = useState({});
  const { register, handleSubmit, reset } = useForm({
    initialValue: singleClientData
  });
  const items = [
    { id: "11", label: "Main Data", content: <MainData register={register} /> },
    { id: "22", label: "Position", content: <Position register={register} /> },
    { id: "33", label: "Contacts", content: <Contacts register={register} /> },
    { id: "34", label: "Extra", content: <Extra register={register} /> }
  ];

  const handleClick = (nextIndex) => {
    setExpandedIndex(expandedIndex === nextIndex ? -1 : nextIndex);
  };

  const fetchSingleClient = async () => {
    try {
      const response = await getSingleClient(id);
      //console.log(response.data.client)
      setSingleClientData(response?.data?.client);
    } catch (error) {
      //console.log(error)
    }
  };
  useEffect(() => {
    if (id) {
      fetchSingleClient();
    }
  }, []);

  useEffect(() => {
    reset(singleClientData);
  }, [singleClientData, reset]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      const payload = {
        ...data,
        companyId: companyId
      };

      if (id) {
        const response = await updateClient(id, payload);
        //console.log(response)
        if (response.status === 200) {
          toast.success(response.data.message);
          navigate("/clients");
        }
      } else {
        const response = await addClient(payload);
        //console.log(response)
        if (response.status === 201) {
          toast.success(response.data.message);
          navigate("/clients");
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <div className="w-4/5 mx-auto rounded-lg shadow-lg mt-20 bg-white p-5">
      <h1 className="text-2xl font-bold mb-5 text-center">Add New Customer</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          {items.map((item, index) => (
            <AccordionItem
              key={item.id}
              id={item.id}
              label={item.label}
              isExpanded={index === expandedIndex}
              onClick={() => handleClick(index)}
            >
              {item.content}
            </AccordionItem>
          ))}
        </div>
        <div className="flex justify-end mt-5 gap-3">
          <button
            type="submit"
            className="bg-red-500 text-white px-5 py-2 rounded"
          >
            Annulla
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white px-5 py-2 rounded"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddClient;
