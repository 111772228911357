import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo
} from "react";
import io from "socket.io-client";
import * as chatApi from "../../../apis/Chat";
import { format } from "date-fns";
import { useToast } from "../../ui/use-toast";
import { cn } from "../../../lib/utils";
import {
  Loader2,
  Search,
  Send,
  Plus,
  Trash2,
  ChevronLeft,
  X,
  AlertCircle
} from "lucide-react";

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { ScrollArea } from "../../ui/scroll-area";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter
} from "../../ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogDescription
} from "../../ui/dialog";
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar";
import { Separator } from "../../ui/separator";
import { Textarea } from "../../ui/textarea";
import { Alert, AlertDescription, AlertTitle } from "../../ui/alert";
import ChatList from "./components/ChatList";

// Constants
const BUBBLE_VARIANTS = {
  sender: "bg-slate-200 dark:bg-slate-700 text-foreground dark:text-slate-100",
  receiver: "bg-slate-100 dark:bg-slate-800 text-foreground dark:text-slate-100"
};

const LAYOUT_CLASSES = {
  container:
    "flex flex-col min-h-[calc(100vh-theme(space.16))] md:h-[calc(88vh-1rem)]",
  chatSection:
    "flex-1 flex flex-col md:flex-row gap-4 h-full overflow-hidden p-4",
  sidebarCard: "w-full md:w-80 flex flex-col h-full",
  mainCard: "flex-1 flex flex-col h-full",
  messageArea: "flex-1 overflow-hidden relative",
  messageScroll: "h-full overflow-y-auto px-4 py-2",
  inputArea: "border-t p-4 mt-auto flex-shrink-0 bg-white"
};

// Message Bubble Component
const MessageBubble = React.memo(
  ({ message, isCurrentUser, user, onDelete }) => {
    if (!message?.sender) return null;

    const bubbleClass = isCurrentUser
      ? BUBBLE_VARIANTS.sender
      : BUBBLE_VARIANTS.receiver;

    return (
      <div
        className={cn(
          "flex items-start space-x-2 group",
          isCurrentUser ? "flex-row-reverse space-x-reverse" : "flex-row"
        )}
      >
        <Avatar className="h-8 w-8 flex-shrink-0">
          <AvatarImage
            src={
              message.sender?.avatar ||
              `/api/avatar/${message.sender?.username}`
            }
            alt={message.sender?.username}
          />
          <AvatarFallback>
            {(message.sender?.username || "?"[0]).toUpperCase()}
          </AvatarFallback>
        </Avatar>
        <div
          className={cn(
            "flex flex-col max-w-[75%]",
            isCurrentUser ? "items-end" : "items-start"
          )}
        >
          <div className="flex flex-col space-y-1">
            <span className="text-xs text-muted-foreground">
              {message.sender?.username}
            </span>
            <div
              className={cn("px-4 py-2 rounded-2xl break-words", bubbleClass)}
            >
              <p className="text-sm whitespace-pre-wrap break-words">
                {message.content}
              </p>
            </div>
            <div className="flex items-center space-x-2 text-xs text-muted-foreground">
              <span>{format(new Date(message.timestamp), "HH:mm")}</span>
              {isCurrentUser && (
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => onDelete(message._id)}
                  className="h-6 w-6 opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <X className="h-4 w-4" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

MessageBubble.displayName = "MessageBubble";

// Connection Status Component
// const ConnectionStatus = ({ isConnected, onReconnect }) => {
//   if (isConnected) return null;

//   return (
//     <Alert variant="destructive" className="fixed bottom-4 right-4 w-auto">
//       <AlertCircle className="h-4 w-4" />
//       <AlertTitle>Disconnected</AlertTitle>
//       <AlertDescription className="flex items-center gap-2">
//         Chat server connection lost
//         <Button
//           variant="outline"
//           size="sm"
//           onClick={onReconnect}
//           className="ml-2"
//         >
//           Reconnect
//         </Button>
//       </AlertDescription>
//     </Alert>
//   );
// };

function ChatComponent() {
  const { toast } = useToast();
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState({
    chats: false,
    messages: false,
    users: false,
    sending: false
  });
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [showChatList, setShowChatList] = useState(true);
  const [chatToDelete, setChatToDelete] = useState(null);
  const [user, setUser] = useState(null);
  const [socketConnected, setSocketConnected] = useState(false);
  const [connectionAttempts, setConnectionAttempts] = useState(0);
  const socketRef = useRef(null);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  // Scroll handler with debounce
  const scrollToBottom = useCallback(() => {
    if (messagesContainerRef.current) {
      const scrollArea = messagesContainerRef.current.querySelector(
        "[data-radix-scroll-area-viewport]"
      );
      if (scrollArea) {
        requestAnimationFrame(() => {
          scrollArea.scrollTop = scrollArea.scrollHeight;
        });
      }
    }
  }, []);

  useEffect(() => {
    if (user?.companyId) {
      //console.log("Fetching initial data for company:", user.companyId);
      fetchChats();
      fetchCompanyUsers();
    }
  }, [user]);

  // Message handlers
  const handleNewMessage = useCallback(
    (message) => {
      if (currentChat?._id === message.chatId) {
        setMessages((prev) => [...prev, message]);
        scrollToBottom();
      }
      fetchChats();
    },
    [currentChat, scrollToBottom]
  );

  const handleChatDeleted = useCallback(
    (chatId) => {
      setChats((prev) => prev.filter((chat) => chat._id !== chatId));
      if (currentChat?._id === chatId) {
        setCurrentChat(null);
        setMessages([]);
      }
    },
    [currentChat]
  );

  const handleMessageDeleted = useCallback(
    (data) => {
      if (currentChat?._id === data.chatId) {
        setMessages((prev) => prev.filter((msg) => msg._id !== data.messageId));
      }
    },
    [currentChat]
  );

  // User session effect
  useEffect(() => {
    try {
      const userStr = localStorage.getItem("user");
      if (!userStr) {
        throw new Error("No user data found");
      }

      const userData = JSON.parse(userStr);
      if (!userData.id) {
        throw new Error("Invalid user data");
      }

      setUser({
        ...userData,
        _id: userData.id
      });
    } catch (error) {
      console.error("User session error:", error);
      toast({
        title: "Authentication Error",
        description: "Please log in again",
        variant: "destructive"
      });
    }
  }, [toast]);

  // Socket connection effect
  useEffect(() => {
    if (!user?.id) return;

    const socketUrl =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_SOCKET_URL
        : "http://localhost:5000";

    const socket = io(socketUrl, {
      transports: ["polling", "websocket"],
      upgrade: true,
      rememberUpgrade: true,
      query: { userId: user.id },
      withCredentials: true,
      reconnection: true,
      reconnectionAttempts: 3,
      reconnectionDelay: 1000,
      timeout: 20000,
      auth: { userId: user.id }
    });

    socket.on("connect", () => {
      //console.log("Socket connected:", socket.id);
      setSocketConnected(true);
      setConnectionAttempts(0);
      socket.emit("join", user.id);
    });

    socket.on("connect_error", handleSocketError);
    socket.on("disconnect", handleSocketDisconnect);
    socket.on("newMessage", handleNewMessage);
    socket.on("chatDeleted", handleChatDeleted);
    socket.on("messageDeleted", handleMessageDeleted);

    socketRef.current = socket;

    return () => {
      socket.disconnect();
      socketRef.current = null;
    };
  }, [user?.id]);

  // Socket error handler
  const handleSocketError = useCallback(
    (error) => {
      console.error("Socket error:", error);
      setSocketConnected(false);
      // toast({
      //   title: "Connection Error",
      //   description: "Chat connection lost. Trying to reconnect...",
      //   variant: "destructive"
      // });
    },
    [toast]
  );

  // Socket disconnect handler
  const handleSocketDisconnect = useCallback((reason) => {
    //console.log("Socket disconnected:", reason);
    setSocketConnected(false);
  }, []);

  // Responsive handler
  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Messages auto-scroll
  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  // Data fetching
  const fetchChats = async () => {
    if (!user) return;
    setLoading((prev) => ({ ...prev, chats: true }));
    try {
      const data = await chatApi.getChats();
      setChats(data);
      if (!currentChat && data.length > 0) {
        const mostRecentChat = data[0];
        setCurrentChat(mostRecentChat);
        await fetchMessages(mostRecentChat._id);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch chats",
        variant: "destructive"
      });
    } finally {
      setLoading((prev) => ({ ...prev, chats: false }));
    }
  };

  const fetchMessages = async (chatId) => {
    setLoading((prev) => ({ ...prev, messages: true }));
    try {
      const data = await chatApi.getMessages(chatId);
      setMessages(data);
      requestAnimationFrame(scrollToBottom);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch messages",
        variant: "destructive"
      });
    } finally {
      setLoading((prev) => ({ ...prev, messages: false }));
    }
  };

  // Fetch company users
  const fetchCompanyUsers = async () => {
    if (!user?.companyId) return;
    setLoading((prev) => ({ ...prev, users: true }));
    try {
      //console.log("Fetching company users for:", user.companyId);
      const response = await chatApi.getCompanyUsers(user.companyId);
      //console.log("Company users response:", response);

      if (response?.users) {
        // Filter out current user and users already in chats
        const availableUsers =
          response?.users?.filter(
            (u) =>
              u?._id !== user?._id &&
              !chats?.some((chat) =>
                chat?.participants?.some((p) => p?.user?._id === u?._id)
              )
          ) || [];
        setCompanyUsers(availableUsers);
        setFilteredUsers(availableUsers);
      }
    } catch (error) {
      console.error("Error fetching company users:", error);
      toast({
        title: "Error",
        description: "Failed to fetch users",
        variant: "destructive"
      });
    } finally {
      setLoading((prev) => ({ ...prev, users: false }));
    }
  };

  const handleChatClick = async (chat) => {
    try {
      setCurrentChat(chat);
      await fetchMessages(chat._id);
      if (isMobileView) {
        setShowChatList(false);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to load chat messages",
        variant: "destructive"
      });
    }
  };

  const handleSendMessage = async (e) => {
    e?.preventDefault();

    if (!newMessage.trim() || !currentChat?._id || loading.sending) return;

    setLoading((prev) => ({ ...prev, sending: true }));
    try {
      // Optimistically add message to UI
      const tempMessage = {
        _id: Date.now().toString(),
        content: newMessage.trim(),
        sender: user,
        timestamp: new Date(),
        chatId: currentChat._id,
        isTemp: true
      };
      setMessages((prev) => [...prev, tempMessage]);
      setNewMessage("");
      scrollToBottom();

      // Send message to server
      const sentMessage = await chatApi.sendMessage(
        currentChat._id,
        newMessage.trim()
      );

      // Replace temp message with actual message
      setMessages((prev) =>
        prev.map((msg) => (msg.isTemp ? sentMessage : msg))
      );

      // Emit socket event
      if (socketRef.current?.connected) {
        socketRef.current.emit("messageSent", {
          chatId: currentChat._id,
          message: sentMessage
        });
      }
    } catch (error) {
      // Remove temp message on error
      setMessages((prev) => prev.filter((msg) => !msg.isTemp));
      setNewMessage(newMessage.trim());
      toast({
        title: "Error",
        description: "Failed to send message",
        variant: "destructive"
      });
    } finally {
      setLoading((prev) => ({ ...prev, sending: false }));
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await chatApi.deleteMessage(currentChat._id, messageId);
      setMessages((prev) => prev.filter((msg) => msg._id !== messageId));
      socketRef.current?.emit("messageDeleted", {
        chatId: currentChat._id,
        messageId
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete message",
        variant: "destructive"
      });
    }
  };

  const handleDeleteChat = async (chatId) => {
    try {
      await chatApi.deleteChat(chatId);
      setChats((prev) => prev.filter((chat) => chat._id !== chatId));
      if (currentChat?._id === chatId) {
        setCurrentChat(null);
        setMessages([]);
      }
      setChatToDelete(null);
      toast({
        title: "Success",
        description: "Chat deleted",
        variant: "success"
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete chat",
        variant: "destructive"
      });
    }
  };

  const handleCreateChat = async (participantId) => {
    try {
      const newChat = await chatApi.createChat(participantId);
      setChats((prev) => {
        if (prev.some((chat) => chat._id === newChat._id)) return prev;
        return [newChat, ...prev];
      });
      setShowModal(false);
      setCurrentChat(newChat);
      await fetchMessages(newChat._id);
      fetchCompanyUsers();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to create chat",
        variant: "destructive"
      });
    }
  };

  // Loading state
  if (!user) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader2 className="animate-spin h-8 w-8" />
      </div>
    );
  }

  return (
    <div className={LAYOUT_CLASSES.container}>
      <div className={LAYOUT_CLASSES.chatSection}>
        {/* Sidebar */}
        {(!isMobileView || (isMobileView && showChatList)) && (
          <Card className={LAYOUT_CLASSES.sidebarCard}>
            <CardHeader className="space-y-4 flex-shrink-0 py-4">
              <CardTitle className="flex justify-between items-center">
                Messages
                <Dialog open={showModal} onOpenChange={setShowModal}>
                  <DialogTrigger asChild>
                    <Button variant="outline" size="icon">
                      <Plus className="h-4 w-4" />
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-md">
                    <DialogHeader>
                      <DialogTitle>New Conversation</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4">
                      <div className="flex items-center space-x-2">
                        <Search className="h-4 w-4 text-muted-foreground" />
                        <Input
                          placeholder="Search users..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className="flex-1"
                        />
                      </div>
                      <ScrollArea className="h-72">
                        {loading.users ? (
                          <div className="flex justify-center p-4">
                            <Loader2 className="animate-spin h-6 w-6" />
                          </div>
                        ) : filteredUsers.length === 0 ? (
                          <p className="text-center text-muted-foreground p-4">
                            No users found
                          </p>
                        ) : (
                          filteredUsers.map((user) => (
                            <div
                              key={user._id}
                              className="flex items-center space-x-3 p-2 hover:bg-accent rounded-md cursor-pointer"
                              onClick={() => handleCreateChat(user._id)}
                            >
                              <Avatar>
                                <AvatarImage src={user.avatar} />
                                <AvatarFallback>
                                  {(user.username ||
                                    user.name ||
                                    "?")[0].toUpperCase()}
                                </AvatarFallback>
                              </Avatar>
                              <span>{user.username || user.name}</span>
                            </div>
                          ))
                        )}
                      </ScrollArea>
                    </div>
                  </DialogContent>
                </Dialog>
              </CardTitle>
              <Separator />
            </CardHeader>

            <CardContent className="flex-1 overflow-hidden p-0">
              <ChatList
                chats={chats}
                currentChat={currentChat}
                user={user}
                handleChatClick={handleChatClick}
                setChatToDelete={setChatToDelete}
                loading={loading}
                onNewChat={() => setShowModal(true)} // Pass the function this way
              />
            </CardContent>
          </Card>
        )}

        {/* Main Chat Area */}
        {(!isMobileView || (isMobileView && !showChatList)) && (
          <Card className={LAYOUT_CLASSES.mainCard}>
            <CardHeader className="border-b flex-shrink-0 py-4">
              {currentChat ? (
                <div className="flex items-center space-x-4">
                  {isMobileView && (
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => setShowChatList(true)}
                    >
                      <ChevronLeft className="h-4 w-4" />
                    </Button>
                  )}
                  <Avatar className="h-8 w-8">
                    <AvatarImage
                      src={
                        currentChat?.participants?.find(
                          (p) => p?.user?._id !== user?._id
                        )?.user?.avatar
                      }
                    />
                    <AvatarFallback>
                      {currentChat?.participants?.find(
                        (p) => p?.user?._id !== user?._id
                      )?.user?.username?.[0] || "?"}
                    </AvatarFallback>
                  </Avatar>
                  <div>
                    <CardTitle className="text-lg">
                      {currentChat?.participants?.find(
                        (p) => p?.user?._id !== user?._id
                      )?.user?.username || "Chat"}
                    </CardTitle>
                    <CardDescription>
                      {messages?.length || 0} messages
                    </CardDescription>
                  </div>
                </div>
              ) : (
                <div className="text-center py-4">
                  <CardTitle>Welcome to Chat</CardTitle>
                  <CardDescription>
                    Select a conversation or start a new one
                  </CardDescription>
                </div>
              )}
            </CardHeader>

            {currentChat && (
              <>
                <ScrollArea
                  className={LAYOUT_CLASSES.messageArea}
                  ref={messagesContainerRef}
                >
                  <div className="space-y-4 p-4">
                    {loading.messages ? (
                      <div className="flex justify-center">
                        <Loader2 className="animate-spin h-6 w-6" />
                      </div>
                    ) : messages.length === 0 ? (
                      <div className="flex flex-col items-center justify-center h-full text-center">
                        <p className="text-muted-foreground">No messages yet</p>
                        <p className="text-sm text-muted-foreground">
                          Start the conversation
                        </p>
                      </div>
                    ) : (
                      messages.map((message) => (
                        <MessageBubble
                          key={message._id}
                          message={message}
                          isCurrentUser={message?.sender?._id === user?._id}
                          user={user}
                          onDelete={handleDeleteMessage}
                        />
                      ))
                    )}
                    <div ref={messagesEndRef} />
                  </div>
                </ScrollArea>

                <CardFooter className={LAYOUT_CLASSES.inputArea}>
                  <form
                    onSubmit={handleSendMessage}
                    className="flex items-end space-x-2 w-full"
                  >
                    <Textarea
                      placeholder="Type a message..."
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      disabled={loading.sending}
                      className="flex-grow resize-none min-h-[40px] max-h-[120px]"
                      rows={1}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          handleSendMessage(e);
                        }
                      }}
                    />
                    <Button
                      type="submit"
                      disabled={!newMessage.trim() || loading.sending}
                      className="flex-shrink-0"
                    >
                      {loading.sending ? (
                        <Loader2 className="h-4 w-4 animate-spin" />
                      ) : (
                        <Send className="h-4 w-4" />
                      )}
                    </Button>
                  </form>
                </CardFooter>
              </>
            )}
          </Card>
        )}
      </div>

      {/* Delete Chat Dialog */}
      <Dialog open={!!chatToDelete} onOpenChange={() => setChatToDelete(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Conversation</DialogTitle>
            <DialogDescription>
              This action cannot be undone. All messages will be permanently
              deleted.
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={() => setChatToDelete(null)}>
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={() => chatToDelete && handleDeleteChat(chatToDelete)}
            >
              Delete
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Connection Status */}
      {/* <ConnectionStatus
        isConnected={socketConnected}
        onReconnect={() => socketRef.current?.connect()}
      /> */}
    </div>
  );
}

export default ChatComponent;
