import { useState } from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../ui/card";
import { Button } from "../../ui/button";
import { Alert, AlertDescription } from "../../ui/alert";
import { 
  Building2, 
  HardHat, 
  FileText, 
  Bell,
  Mail, 
  AlertTriangle,
  Smartphone
} from "lucide-react";
import { toast } from "react-toastify";
import { useNotificationPreferences } from "../../../apis/NotificationApi";
import SettingsTabs from "./components/SettingsTabs";
import { Switch } from "../../ui/switch";
import { Label } from "../../ui/label";

const NotificationSettings = () => {
  const {
    preferences,
    loading,
    error,
    updatePreferences,
    refreshPreferences
  } = useNotificationPreferences();

  const [isUpdating, setIsUpdating] = useState(false);

  const handleSaveSettings = async () => {
    setIsUpdating(true);
    try {
      const success = await updatePreferences(preferences);
      if (success) {
        toast.success('Preferenze di notifica aggiornate con successo');
      } else {
        toast.error('Errore durante il salvataggio delle preferenze');
      }
    } catch (error) {
      toast.error('Errore durante il salvataggio delle preferenze');
      console.error('Error saving preferences:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const notificationSections = [
    {
      title: "Cantieri",
      icon: <HardHat className="h-5 w-5" />,
      description: "Notifiche relative ai cantieri",
      items: [
        {
          key: "constructionSiteExpiration",
          label: "Scadenza cantieri",
          description: "Notifiche quando i cantieri stanno per scadere"
        },
        {
          key: "constructionSiteUpdates",
          label: "Aggiornamenti cantieri",
          description: "Notifiche per aggiornamenti importanti sui cantieri"
        }
      ]
    },
    {
      title: "Documenti",
      icon: <FileText className="h-5 w-5" />,
      description: "Notifiche relative ai documenti",
      items: [
        {
          key: "documentExpiration",
          label: "Scadenza documenti",
          description: "Notifiche quando i documenti stanno per scadere"
        },
        {
          key: "documentUpdates",
          label: "Aggiornamenti documenti",
          description: "Notifiche per nuovi documenti o modifiche"
        }
      ]
    },
    {
      title: "Sistema",
      icon: <Bell className="h-5 w-5" />,
      description: "Notifiche di sistema",
      items: [
        {
          key: "systemUpdates",
          label: "Aggiornamenti di sistema",
          description: "Notifiche per aggiornamenti e manutenzione del sistema"
        },
        {
          key: "securityAlerts",
          label: "Avvisi di sicurezza",
          description: "Notifiche relative alla sicurezza dell'account"
        }
      ]
    }
  ];

  const notificationChannels = [
    {
      key: "inApp",
      label: "Notifiche in-app",
      icon: <Bell className="h-5 w-5" />,
      description: "Ricevi notifiche all'interno dell'applicazione"
    },
    {
      key: "email",
      label: "Email",
      icon: <Mail className="h-5 w-5" />,
      description: "Ricevi notifiche via email"
    },
    {
      key: "push",
      label: "Notifiche push",
      icon: <Smartphone className="h-5 w-5" />,
      description: "Ricevi notifiche push sul browser o dispositivo mobile"
    }
  ];

  if (loading) {
    return (
      <Card>
        <CardContent className="p-6">
          <div className="flex items-center justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertTriangle className="h-4 w-4" />
        <AlertDescription>
          Si è verificato un errore nel caricamento delle preferenze: {error}
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle className="text-xl">Canali di notifica</CardTitle>
          <CardDescription>
            Scegli come vuoi ricevere le notifiche
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          {notificationChannels.map((channel) => (
            <div key={channel.key} className="flex items-start space-x-4">
              <div className="mt-1">{channel.icon}</div>
              <div className="flex-1 space-y-1">
                <div className="flex items-center justify-between">
                  <Label htmlFor={channel.key} className="font-medium">
                    {channel.label}
                  </Label>
                  <Switch
                    id={channel.key}
                    checked={preferences[channel.key]}
                    onCheckedChange={(checked) =>
                      updatePreferences({ ...preferences, [channel.key]: checked })
                    }
                  />
                </div>
                <p className="text-sm text-muted-foreground">{channel.description}</p>
              </div>
            </div>
          ))}
        </CardContent>
      </Card>

      {notificationSections.map((section) => (
        <Card key={section.title}>
          <CardHeader>
            <div className="flex items-center space-x-2">
              {section.icon}
              <CardTitle className="text-xl">{section.title}</CardTitle>
            </div>
            <CardDescription>{section.description}</CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            {section.items.map((item) => (
              <div key={item.key} className="flex items-start space-x-4">
                <div className="flex-1 space-y-1">
                  <div className="flex items-center justify-between">
                    <Label htmlFor={item.key} className="font-medium">
                      {item.label}
                    </Label>
                    <Switch
                      id={item.key}
                      checked={preferences[item.key]}
                      onCheckedChange={(checked) =>
                        updatePreferences({ ...preferences, [item.key]: checked })
                      }
                    />
                  </div>
                  <p className="text-sm text-muted-foreground">{item.description}</p>
                </div>
              </div>
            ))}
          </CardContent>
        </Card>
      ))}

      <div className="flex justify-end">
        <Button
          onClick={handleSaveSettings}
          disabled={isUpdating}
        >
          {isUpdating ? "Salvataggio..." : "Salva preferenze"}
        </Button>
      </div>
    </div>
  );
};

const Notifications = () => {
  return (
    <SettingsTabs defaultTab="/notifications">
      <NotificationSettings />
    </SettingsTabs>
  );
};

export default Notifications;