import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const createConstructionSite = async (data) => {
  try {
    const response = await axios.post('/construction-sites', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const getConstructionSites = async (companyId) => {
  try {
    const response = await axios.get('/construction-sites', {
      params: { companyId }
    });
    return response;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const getConstructionSite = async (id) => {
  try {
    const response = await axios.get(`/construction-sites/${id}`);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateConstructionSite = async (id, data) => {
  try {
    const response = await axios.patch(`/construction-sites/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteConstructionSite = async (id, password) => {
  try {
    const response = await axios.delete(`/construction-sites/${id}`, {
      data: { password }
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const addDocuments = async (id, data) => {
  try {
    const response = await axios.post(`/construction-sites/${id}/documents`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteDocument = async (siteId, documentId) => {
  try {
    const response = await axios.delete(`/construction-sites/${siteId}/documents/${documentId}`);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateDocumentName = async (siteId, documentId, newName) => {
  try {
    const response = await axios.patch(`/construction-sites/${siteId}/documents/${documentId}`, { name: newName });
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const getContabilita = async (id) => {
  try {
    const response = await axios.get(`/construction-sites/${id}/contabilita`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const addProgressStatus = async (id, progressStatus) => {
  try {
    const response = await axios.post(`/construction-sites/${id}/progress-status`, progressStatus);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateProgressStatus = async (siteId, statusId, updatedStatus) => {
  try {
    const response = await axios.put(`/construction-sites/${siteId}/progress-status/${statusId}`, updatedStatus);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteProgressStatus = async (siteId, statusId) => {
  try {
    const response = await axios.delete(`/construction-sites/${siteId}/progress-status/${statusId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const createMediaFolder = async (siteId, folderName) => {
  try {
    const response = await axios.post(`/construction-sites/${siteId}/media-folders`, { folderName });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const uploadMedia = async (siteId, folderId, formData) => {
  try {
    const response = await axios.post(`/construction-sites/${siteId}/media-folders/${folderId}/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteMedia = async (siteId, folderId, mediaId) => {
  try {
    const response = await axios.delete(`/construction-sites/${siteId}/media-folders/${folderId}/media/${mediaId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getMediaFolders = async (siteId) => {
  try {
    const response = await axios.get(`/construction-sites/${siteId}/media-folders`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateMediaFolder = async (siteId, folderId, folderName) => {
  try {
    const response = await axios.put(`/construction-sites/${siteId}/media-folders/${folderId}`, { folderName });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteMediaFolder = async (siteId, folderId, password) => {
  try {
    const response = await axios.delete(`/construction-sites/${siteId}/media-folders/${folderId}`, {
      data: { password }
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getAccountingItems = async (siteId) => {
  try {
    const response = await axios.get(`/construction-sites/${siteId}/accounting`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const addAccountingItem = async (siteId, item) => {
  try {
    const response = await axios.post(`/construction-sites/${siteId}/accounting`, item);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateAccountingItem = async (siteId, itemId, item) => {
  try {
    const response = await axios.put(
      `/construction-sites/${siteId}/accounting/${itemId}`, 
      item
    );
    return response.data;
  } catch (error) {
    console.error('API Error:', error.response?.data || error);
    throw error.response?.data || error;
  }
};

export const deleteAccountingItem = async (siteId, itemId) => {
  try {
    const response = await axios.delete(`/construction-sites/${siteId}/accounting/${itemId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const archiveConstructionSite = async (id, reason) => {
  try {
    const response = await axios.post(
      `/construction-sites/${id}/archive`,
      { reason: reason || '' }
    );
    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const unarchiveConstructionSite = async (id) => {
  try {
    const response = await axios.post(`/construction-sites/${id}/unarchive`);
    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};

// Gantt Chart APIs
export const getGanttChart = async (siteId) => {
  try {
    const response = await axios.get(`/construction-sites/${siteId}/gantt-chart`);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const createCategory = async (siteId, category) => {
  try {
    const response = await axios.post(`/construction-sites/${siteId}/gantt-chart/categories`, category);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const updateCategory = async (siteId, categoryId, data) => {
  try {
    const response = await axios.put(`/construction-sites/${siteId}/gantt-chart/categories/${categoryId}`, data);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const deleteCategory = async (siteId, categoryId) => {
  try {
    const response = await axios.delete(`/construction-sites/${siteId}/gantt-chart/categories/${categoryId}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const createTask = async (siteId, task) => {
  try {
    const response = await axios.post(`/construction-sites/${siteId}/gantt-chart/tasks`, task);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const updateTask = async (siteId, taskId, data) => {
  try {
    const response = await axios.put(`/construction-sites/${siteId}/gantt-chart/tasks/${taskId}`, data);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const deleteTask = async (siteId, taskId) => {
  try {
    const response = await axios.delete(`/construction-sites/${siteId}/gantt-chart/tasks/${taskId}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const importFromComputo = async (siteId) => {
  try {
    const response = await axios.post(`/construction-sites/${siteId}/gantt-chart/import-from-computo`);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};