import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../ui/button";
import { Archive, Folder, ChevronLeft } from "lucide-react";
import { fetchPreventivi } from "../../../apis/Preventivi";
import { getConstructionSites } from "../../../apis/ConstructionSite";

// Folder-based breadcrumb navigation - only shown when inside a folder
const ArchiveNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  
  // If no type, don't render navigation at all
  if (!type) return null;
  
  // Get folder name based on type
  const getFolderName = () => {
    switch(type) {
      case "cantieri": return "Cantieri";
      case "preventivi": return "Preventivi";
      default: return "Archivio";
    }
  };

  return (
    <div className="mb-6">
      <div className="flex items-center gap-2 mb-4">
        <Button 
          variant="ghost" 
          size="sm" 
          onClick={() => navigate("/archive")}
          className="p-0 h-8 w-8"
        >
          <ChevronLeft className="h-5 w-5" />
        </Button>
        
        <div className="flex items-center gap-2">
          <Archive className="w-5 h-5 text-gray-500" />
          <span className="text-lg font-medium">
            Archivio/{getFolderName()}
          </span>
        </div>
      </div>
    </div>
  );
};

// Folder component
const ArchiveFolder = ({ title, count, onClick, isLoading }) => {
  return (
    <div 
      className="bg-white border rounded-lg p-4 cursor-pointer hover:shadow-md transition-shadow" 
      onClick={onClick}
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-3">
          <Folder size={24} className="text-yellow-500" />
          <span className="font-medium">{title}</span>
        </div>
        <div className="text-sm text-gray-500">
          {isLoading ? "Caricamento..." : `${count} elementi`}
        </div>
      </div>
      <div className="flex items-center text-sm text-gray-500">
        <Archive className="w-4 h-4 mr-2" />
        <span>{title} archiviati</span>
      </div>
    </div>
  );
};

// Main component that displays the archive screens
const ArchiveIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  
  const [CantieriArchiveScreen, setCantieriArchiveScreen] = useState(null);
  const [PreventiviArchiveScreen, setPreventiviArchiveScreen] = useState(null);
  const [archivedCounts, setArchivedCounts] = useState({
    cantieri: 0,
    preventivi: 0
  });
  const [isLoading, setIsLoading] = useState(true);
  
  // Load counts of archived items from the actual APIs
  useEffect(() => {
    const fetchArchivedCounts = async () => {
      setIsLoading(true);
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user || !user.companyId) {
          console.error("User or company information not found");
          setIsLoading(false);
          return;
        }
        
        // Fetch cantieri and preventivi in parallel
        const [cantieriResponse, preventiviResponse] = await Promise.all([
          getConstructionSites(user.companyId),
          fetchPreventivi(user.companyId)
        ]);
        
        // Count archived items
        const archivedCantieri = cantieriResponse.data.sites.filter(
          site => site.status === "Archiviato"
        ).length;
        
        const archivedPreventivi = preventiviResponse.data.preventivis.filter(
          item => item.isArchived
        ).length;
        
        setArchivedCounts({
          cantieri: archivedCantieri,
          preventivi: archivedPreventivi
        });
      } catch (error) {
        console.error("Error fetching archive counts:", error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchArchivedCounts();
  }, []);
  
  // Dynamically import the archive screens to avoid circular dependencies
  useEffect(() => {
    const loadComponents = async () => {
      if (type === "cantieri") {
        const { default: Component } = await import("./CantieriArchiveScreen");
        setCantieriArchiveScreen(() => Component);
      } else if (type === "preventivi") {
        const { default: Component } = await import("./PreventiviArchiveScreen");
        setPreventiviArchiveScreen(() => Component);
      }
    };
    
    if (type) {
      loadComponents();
    }
  }, [type]);

  return (
    <div className=" mx-auto p-6">
      <ArchiveNavigation />
      
      {!type && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <ArchiveFolder 
            title="Cantieri" 
            count={archivedCounts.cantieri}
            onClick={() => navigate("/archive?type=cantieri")}
            isLoading={isLoading}
          />
          
          <ArchiveFolder 
            title="Preventivi" 
            count={archivedCounts.preventivi}
            onClick={() => navigate("/archive?type=preventivi")}
            isLoading={isLoading}
          />
        </div>
      )}
      
      {type === "cantieri" && CantieriArchiveScreen && <CantieriArchiveScreen />}
      {type === "preventivi" && PreventiviArchiveScreen && <PreventiviArchiveScreen />}
    </div>
  );
};

export default ArchiveIndex;