import React, { useState, useEffect, useMemo } from "react";
import { Card } from "../../../../ui/card";
import { Button } from "../../../../ui/button";
import { Input } from "../../../../ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "../../../../ui/dialog";
import {
  EnhancedTable,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../../../ui/table";
import { Eye, Pencil, Trash } from "lucide-react";
import { toast } from "react-toastify";
import { DropdownMenuItem } from "../../../../ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../../../../ui/tooltip";
import * as MedicalVisitsAPI from "../../../../../apis/MedicalVisits";
import HelpSheet from "../../../sharedComponent/HelpSheet";

const MedicalVisits = ({ employeeData = [] }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [visits, setVisits] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [visitDate, setVisitDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [notes, setNotes] = useState("");
  const [editingVisit, setEditingVisit] = useState(null);
  const [visitToDelete, setVisitToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Add enhanced table state
  const [sortConfig, setSortConfig] = useState({
    key: "expiryDate",
    direction: "ascending"
  });
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // Create sortedData memoized function
  const sortedData = useMemo(() => {
    if (!visits || !visits.length) return [];
    if (!sortConfig.key) return visits;
    
    return [...visits].sort((a, b) => {
      // Handle nested properties like employeeId.firstName
      if (sortConfig.key.includes('.')) {
        const keys = sortConfig.key.split('.');
        let aValue = a;
        let bValue = b;
        
        // Navigate through nested objects
        for (const key of keys) {
          aValue = aValue?.[key] || '';
          bValue = bValue?.[key] || '';
        }
        
        // Compare the values
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortConfig.direction === 'ascending' 
            ? aValue.localeCompare(bValue) 
            : bValue.localeCompare(aValue);
        }
        
        return sortConfig.direction === 'ascending' 
          ? (aValue > bValue ? 1 : -1) 
          : (bValue > aValue ? 1 : -1);
      }
      
      // Handle date sorting
      if (['visitDate', 'expiryDate'].includes(sortConfig.key)) {
        const dateA = new Date(a[sortConfig.key] || '');
        const dateB = new Date(b[sortConfig.key] || '');
        return sortConfig.direction === 'ascending' ? dateA - dateB : dateB - dateA;
      }
      
      // Default string comparison
      const aValue = a[sortConfig.key] || '';
      const bValue = b[sortConfig.key] || '';
      
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortConfig.direction === 'ascending' 
          ? aValue.localeCompare(bValue) 
          : bValue.localeCompare(aValue);
      }
      
      // Numeric comparison
      return sortConfig.direction === 'ascending' 
        ? (aValue > bValue ? 1 : -1) 
        : (bValue > aValue ? 1 : -1);
    });
  }, [visits, sortConfig]);

  const getExpiryStatus = (expiryDate) => {
    const today = new Date();
    const expiry = new Date(expiryDate);
    const diffTime = expiry - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 0) {
      return { status: "Scaduta", color: "text-red-500" };
    } else if (diffDays <= 30) {
      return { status: "In Scadenza", color: "text-orange-500" };
    } else {
      return { status: "Valida", color: "text-green-500" };
    }
  };

  // Function to truncate text with tooltip
  const truncateText = (text, maxLength = 20) => {
    if (!text) return "N/A";
    
    if (text.length <= maxLength) {
      return text;
    }
    
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="cursor-help">{text.substring(0, maxLength)}...</span>
          </TooltipTrigger>
          <TooltipContent>
            <p>{text}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  const fetchVisits = async () => {
    try {
      setIsLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user?.companyId) {
        toast.error("Company ID not found");
        return;
      }

      const response = await MedicalVisitsAPI.getMedicalVisits(
        user.companyId,
        currentPage,
        10
      );

      if (response.status === 200) {
        // Add an id property to each visit for selection tracking
        const visitsWithIds = (response.data.visits || []).map((visit) => ({
          ...visit,
          id: visit._id
        }));
        setVisits(visitsWithIds);
        setTotalPages(response.data.totalPages || 1);
      }
    } catch (error) {
      toast.error("Failed to fetch medical visits");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (employeeData?.length > 0) {
      fetchVisits();
    }
  }, [currentPage, employeeData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!selectedEmployee) {
      toast.error("Seleziona un dipendente");
      return;
    }

    if (!visitDate) {
      toast.error("Inserisci la data della visita");
      return;
    }

    if (!expiryDate) {
      toast.error("Inserisci la data di scadenza");
      return;
    }

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("employeeId", selectedEmployee);
      formData.append("visitDate", visitDate);
      formData.append("expiryDate", expiryDate);
      formData.append("notes", notes);
      if (selectedFile) {
        formData.append("file", selectedFile);
      }

      if (editingVisit) {
        await MedicalVisitsAPI.updateMedicalVisit(editingVisit._id, formData);
        toast.success("Visita medica aggiornata con successo");
      } else {
        await MedicalVisitsAPI.addMedicalVisit(formData);
        toast.success("Visita medica aggiunta con successo");
      }

      fetchVisits();
      resetForm();
    } catch (error) {
      console.error("Error saving medical visit:", error);
      toast.error(error.response?.data?.message || "Errore durante il salvataggio della visita medica");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (visit) => {
    setVisitToDelete(visit);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    if (!visitToDelete) return;

    try {
      setIsLoading(true);
      await MedicalVisitsAPI.deleteMedicalVisit(visitToDelete._id);
      toast.success("Visita medica eliminata con successo");
      setShowDeleteModal(false);
      setVisitToDelete(null);
      fetchVisits();
    } catch (error) {
      toast.error("Impossibile eliminare la visita medica");
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setSelectedEmployee(null);
    setVisitDate("");
    setExpiryDate("");
    setNotes("");
    setSelectedFile(null);
    setEditingVisit(null);
    setShowModal(false);
  };

  const handleEdit = (visit) => {
    setEditingVisit(visit);
    setSelectedEmployee(visit.employeeId._id);
    setVisitDate(new Date(visit.visitDate).toISOString().split("T")[0]);
    setExpiryDate(new Date(visit.expiryDate).toISOString().split("T")[0]);
    setNotes(visit.notes || "");
    setShowModal(true);
  };

  const getDocumentUrl = (documentUrl) => {
    if (!documentUrl) return "";
    if (documentUrl.startsWith("http")) return documentUrl;
    return `${backendUrl}${documentUrl}`;
  };

  if (!employeeData?.length) {
    return (
      <Card className="p-4">
        <div className="text-center py-4">Loading employee data...</div>
      </Card>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <Button
          onClick={() => setShowModal(true)}
          className="flex items-center gap-2"
        >
          Aggiungi Visita Medica
        </Button>
        <HelpSheet />
      </div>

      <Card className="p-4">
        <div className="relative w-full overflow-auto">
          <EnhancedTable
            items={sortedData}
            sortConfig={sortConfig}
            onSortChange={setSortConfig}
            selectedItems={selectedItems}
            onSelectChange={setSelectedItems}
            selectAll={selectAll}
            onSelectAllChange={setSelectAll}
          >
            <TableHeader>
              <TableRow>
                <SelectAllTableHead />
                <SortableTableHead sortKey="employeeId.freshman">
                  Matricola
                </SortableTableHead>
                <SortableTableHead sortKey="employeeId.firstName">
                  Nome
                </SortableTableHead>
                <SortableTableHead sortKey="visitDate">
                  Data Visita
                </SortableTableHead>
                <SortableTableHead sortKey="expiryDate">
                  Data Scadenza
                </SortableTableHead>
                <SortableTableHead></SortableTableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedData.length > 0 ? (
                sortedData.map((visit) => {
                  const expiryStatus = getExpiryStatus(visit.expiryDate);
                  return (
                    <SelectableTableRow key={visit._id} item={visit}>
                      <SelectionTableCell item={visit} />
                      <TableCell>{visit.employeeId?.freshman || "N/A"}</TableCell>
                      <TableCell>
                        {visit.employeeId
                          ? truncateText(`${visit.employeeId.firstName || ""} ${visit.employeeId.sureName || ""}`, 25)
                          : "Unknown Employee"}
                      </TableCell>
                      <TableCell>
                        {new Date(visit.visitDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        {new Date(visit.expiryDate).toLocaleDateString()}
                        <span className={`ml-2 ${expiryStatus.color}`}>
                          ({expiryStatus.status})
                        </span>
                      </TableCell>
                      <ActionsTableCell>
                        {visit.documentUrl && (
                          <DropdownMenuItem
                            onClick={() =>
                              window.open(
                                getDocumentUrl(visit.documentUrl),
                                "_blank"
                              )
                            }
                          >
                            <Eye className="w-4 h-4 mr-2" /> Visualizza documento
                          </DropdownMenuItem>
                        )}
                        <DropdownMenuItem onClick={() => handleEdit(visit)}>
                          <Pencil className="w-4 h-4 mr-2" /> Modifica
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => handleDeleteClick(visit)}
                          className="text-red-600 hover:text-red-700"
                        >
                          <Trash className="w-4 h-4 mr-2" /> Elimina
                        </DropdownMenuItem>
                      </ActionsTableCell>
                    </SelectableTableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    className="text-center py-4 text-muted-foreground"
                  >
                    Nessuna visita medica trovata
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </EnhancedTable>
        </div>

        {totalPages > 1 && (
          <div className="flex justify-center gap-2 mt-4">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <Button
                key={page}
                variant={page === currentPage ? "default" : "outline"}
                size="sm"
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </Button>
            ))}
          </div>
        )}
      </Card>

      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {editingVisit
                ? "Modifica Visita Medica"
                : "Aggiungi Visita Medica"}
            </DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <label className="flex items-center gap-1">
                Dipendente
                <span className="text-red-500">*</span>
              </label>
              <select
                value={selectedEmployee || ""}
                onChange={(e) => setSelectedEmployee(e.target.value)}
                className="w-full p-2 border rounded"
                disabled={isLoading}
                required
              >
                <option value="">Seleziona Dipendente</option>
                {employeeData?.map((emp) => (
                  <option key={emp._id} value={emp._id}>
                    {`${emp.firstName || ""} ${emp.sureName || ""} ${emp.freshman ? `(${emp.freshman})` : ""}`}
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label className="flex items-center gap-1">
                Data Visita
                <span className="text-red-500">*</span>
              </label>
              <Input
                type="date"
                value={visitDate}
                onChange={(e) => setVisitDate(e.target.value)}
                disabled={isLoading}
                required
              />
            </div>

            <div className="space-y-2">
              <label className="flex items-center gap-1">
                Data Scadenza
                <span className="text-red-500">*</span>
              </label>
              <Input
                type="date"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                disabled={isLoading}
                required
              />
            </div>

            <div className="space-y-2">
              <label>Note</label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="w-full p-2 border rounded"
                disabled={isLoading}
              />
            </div>

            <div className="space-y-2">
              <label>Documento</label>
              <Input
                type="file"
                onChange={(e) => setSelectedFile(e.target.files[0])}
                disabled={isLoading}
              />
            </div>

            <div className="flex justify-end gap-2">
              <Button type="button" variant="outline" onClick={resetForm}>
                Annulla
              </Button>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? "Salvando..." : "Salva"}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={showDeleteModal} onOpenChange={setShowDeleteModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conferma Eliminazione</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            Sei sicuro di voler eliminare questa visita medica?
            {visitToDelete && (
              <div className="mt-2 text-sm text-gray-500">
                Per il dipendente: {visitToDelete.employeeId?.firstName || ""}{" "}
                {visitToDelete.employeeId?.sureName || ""}
                <br />
                Data visita:{" "}
                {new Date(visitToDelete.visitDate).toLocaleDateString()}
              </div>
            )}
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setShowDeleteModal(false);
                setVisitToDelete(null);
              }}
              disabled={isLoading}
            >
              Annulla
            </Button>
            <Button
              variant="destructive"
              onClick={handleDelete}
              disabled={isLoading}
            >
              {isLoading ? "Eliminando..." : "Elimina"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MedicalVisits;