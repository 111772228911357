import React, { useState, useEffect, useMemo } from "react";
import {
  Edit,
  Trash2,
  Download,
  Eye,
  Search,
  MoreHorizontal
} from "lucide-react";
import { DateFormat } from "../../../../config/helper";
import {
  fetchDocuments,
  deleteDocument,
  fetchDocument,
  postDocument,
  updateDocument
} from "../../../../apis/Document";
import { toast } from "react-toastify";

import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import {
  EnhancedTable,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell,
  useTableContext
} from "../../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "../../../ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../../../ui/tooltip";
import { DropdownMenuItem } from "../../../ui/dropdown-menu";
import { Badge } from "../../../ui/badge";
import { Label } from "../../../ui/label";

import Pagination from "../../../admin/sharedComponent/Pgination";

const deleteContent = {
  title: "Elimina Documento",
  description:
    "Sei sicuro di voler eliminare questo documento? Questa azione è irreversibile."
};

const noFileContent = {
  title: "Nessun file",
  description: "Nessun file è stato caricato per questo documento."
};

const AllDocuments = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [isNoFileDialogOpen, setIsNoFileDialogOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const [newDocument, setNewDocument] = useState({
    title: "",
    expireDate: "",
    file: null
  });

  // Create sorted data based on sortConfig
  const sortedData = useMemo(() => {
    if (!sortConfig.key) return documentData;
    
    return [...documentData].sort((a, b) => {
      if (sortConfig.key === 'expireDate') {
        // For dates
        const dateA = new Date(a[sortConfig.key] || '');
        const dateB = new Date(b[sortConfig.key] || '');
        return sortConfig.direction === 'ascending' ? dateA - dateB : dateB - dateA;
      } else {
        // For strings and other types
        const valueA = (a[sortConfig.key] || '').toString().toLowerCase();
        const valueB = (b[sortConfig.key] || '').toString().toLowerCase();
        
        return sortConfig.direction === 'ascending' 
          ? valueA.localeCompare(valueB) 
          : valueB.localeCompare(valueA);
      }
    });
  }, [documentData, sortConfig]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const GetALLDocument = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetchDocuments(
        companyId,
        currentPage,
        limit,
        searchQuery
      );
      if (response && response.documents) {
        // Add ID field for selection if not present
        const documentsWithIds = response.documents.map((doc) => ({
          ...doc,
          id: doc._id // Ensure we have an id field for the table selection
        }));
        setDocumentData(documentsWithIds);
        setTotalPage(response.totalPages);
        setCurrentPage(response.currentPage);
      } else {
        setError("Ricevuto un formato di risposta inaspettato dal server.");
      }
    } catch (error) {
      console.error("Errore nel recupero dei documenti:", error);
      setError("Impossibile recuperare i documenti. Riprova più tardi.");
    } finally {
      setIsLoading(false);
    }
  };

  function handleExpireDate(expireDate) {
    const currentDate = new Date();
    const expireDateTime = new Date(expireDate);
    return expireDateTime > currentDate;
  }

  useEffect(() => {
    GetALLDocument();
  }, [isUpdated, currentPage, limit, searchQuery]);

  const editDocument = async (id) => {
    try {
      setSelectedId(id);
      const response = await fetchDocument(id);
      setNewDocument({
        title: response.data.title,
        expireDate: response.data.expireDate,
        file: null
      });
      setIsModalOpen(true);
    } catch (error) {
      console.error(
        "Errore nel recupero del documento per la modifica:",
        error
      );
      toast.error("Impossibile recuperare il documento per la modifica.");
    }
  };

  const handleDeleteDocument = async () => {
    try {
      const response = await deleteDocument(selectedId);
      if (response.status === 200) {
        toast.success(response.data?.message);
        setIsUpdated(!isUpdated);
        setDeleteModal(false);
      }
    } catch (error) {
      console.error("Errore nell'eliminazione del documento:", error);
      toast.error("Impossibile eliminare il documento.");
    }
  };

  const handleDownload = async (filePath) => {
    if (!filePath) {
      setIsNoFileDialogOpen(true);
      return;
    }

    const proxyUrl = "https://cors-anywhere.herokuapp.com/";
    const baseUrl = process.env.REACT_APP_FRONTEND_URL;
    const fileUrl = `${baseUrl}/${filePath}`;
    try {
      const response = await fetch(proxyUrl + fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filePath.split("/").pop());
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("Documento scaricato con successo");
    } catch (error) {
      console.error("Errore nel download del file", error);
      toast.error("Impossibile scaricare il documento.");
    }
  };

  const handlePreview = async (filePath) => {
    if (!filePath) {
      setIsNoFileDialogOpen(true);
      return;
    }

    const fileUrl = `${process.env.REACT_APP_BACKEND_URL}/${filePath}`;
    try {
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Errore nell'apertura del file per l'anteprima:", error);
      toast.error("Impossibile visualizzare l'anteprima del documento.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "file") {
      setNewDocument({ ...newDocument, [name]: files[0] });
    } else {
      setNewDocument({ ...newDocument, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (selectedId) {
        response = await updateDocument(selectedId, {
          ...newDocument,
          companyId
        });
      } else {
        response = await postDocument({ ...newDocument, companyId });
      }

      setIsModalOpen(false);
      setIsUpdated(!isUpdated);
      setNewDocument({ title: "", expireDate: "", file: null });
      toast.success(
        selectedId
          ? "Documento aggiornato con successo"
          : "Documento aggiunto con successo"
      );

      GetALLDocument();
    } catch (error) {
      console.error("Errore nell'invio del documento:", error);
      toast.error("Impossibile salvare il documento. Riprova.");
    }
  };

  if (isLoading && !documentData.length)
    return <div className="text-center py-4">Caricamento...</div>;
  if (error)
    return <div className="text-center py-4 text-red-500">Errore: {error}</div>;

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="flex space-x-2">
          <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
            <DialogTrigger asChild>
              <Button variant="default">Aggiungi</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>
                  {selectedId
                    ? "Modifica Documento"
                    : "Aggiungi Nuovo Documento"}
                </DialogTitle>
                <DialogDescription>
                  {selectedId
                    ? "Apporta modifiche al tuo documento qui."
                    : "Aggiungi un nuovo documento qui."}
                </DialogDescription>
              </DialogHeader>
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-1 items-center gap-4">
                    <Label htmlFor="title" className="text-left">
                      Titolo
                    </Label>
                    <Input
                      id="title"
                      name="title"
                      value={newDocument.title}
                      onChange={handleInputChange}
                      placeholder="Inserisci il titolo"
                    />
                  </div>
                  <div className="grid grid-cols-1 items-center gap-4">
                    <Label htmlFor="expireDate" className="text-left">
                      Data di Scadenza
                    </Label>
                    <Input
                      id="expireDate"
                      name="expireDate"
                      type="date"
                      value={newDocument.expireDate}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="grid grid-cols-1 items-center gap-4">
                    <Label htmlFor="file" className="text-left">
                      File
                    </Label>
                    <Input
                      id="file"
                      name="file"
                      type="file"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <DialogFooter>
                  <Button type="submit">
                    {selectedId ? "Salva modifiche" : "Aggiungi documento"}
                  </Button>
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>
        </div>
        <div className="md:w-[20%] relative">
          <Input
            type="search"
            placeholder="Cerca documenti..."
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-10"
          />
          <Search
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            size={18}
          />
        </div>
      </div>

      <div className="rounded-md border">
        <EnhancedTable
          items={sortedData} // Using sorted data instead of raw documentData
          sortConfig={sortConfig}
          onSortChange={setSortConfig}
          selectedItems={selectedItems}
          onSelectChange={setSelectedItems}
          selectAll={selectAll}
          onSelectAllChange={setSelectAll}
        >
          <TableHeader>
            <TableRow>
              <SelectAllTableHead />
              <SortableTableHead>No.</SortableTableHead>
              <SortableTableHead sortKey="title">
                Nome documento
              </SortableTableHead>
              <SortableTableHead sortKey="expireDate">
                Scadenza
              </SortableTableHead>
              <SortableTableHead>Stato</SortableTableHead>
              <SortableTableHead></SortableTableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedData.length > 0 ? (
              sortedData.map((data, index) => (
                <SelectableTableRow key={data._id} item={data}>
                  <SelectionTableCell item={data} />
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{data.title}</TableCell>
                  <TableCell>{DateFormat(data.expireDate)}</TableCell>
                  <TableCell>
                    <Badge
                      variant={
                        handleExpireDate(data.expireDate)
                          ? "success"
                          : "destructive"
                      }
                    >
                      {handleExpireDate(data.expireDate) ? "Valido" : "Scaduto"}
                    </Badge>
                  </TableCell>
                  <ActionsTableCell>
                    <DropdownMenuItem onClick={() => handlePreview(data.file)}>
                      <Eye className="h-4 w-4 mr-2" /> Visualizza
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => editDocument(data._id)}>
                      <Edit className="h-4 w-4 mr-2" /> Modifica
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => {
                        setSelectedId(data._id);
                        setDeleteModal(true);
                      }}
                    >
                      <Trash2 className="h-4 w-4 mr-2 text-red-600" /> Elimina
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleDownload(data.file)}>
                      <Download className="h-4 w-4 mr-2" /> Scarica
                    </DropdownMenuItem>
                  </ActionsTableCell>
                </SelectableTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} className="text-center py-4">
                  Nessun dato disponibile
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </EnhancedTable>
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteModal} onOpenChange={setDeleteModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{deleteContent.title}</DialogTitle>
            <DialogDescription>{deleteContent.description}</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setDeleteModal(false)}>
              Annulla
            </Button>
            <Button variant="destructive" onClick={handleDeleteDocument}>
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Dialog for no file */}
      <Dialog open={isNoFileDialogOpen} onOpenChange={setIsNoFileDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{noFileContent.title}</DialogTitle>
            <DialogDescription>{noFileContent.description}</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsNoFileDialogOpen(false)}
            >
              Chiudi
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {sortedData.length > 0 && (
        <div className="flex items-center justify-end space-x-2 py-4">
          <Pagination
            totalPages={totalPages}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
          />
        </div>
      )}
    </div>
  );
};

export default AllDocuments;