import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const getTaxes = async (searchQuery = "") => {
  try {
    const response = await axios.get(`/recurring-taxes`, { 
      params: { search: searchQuery } 
    });
    return response;
  } catch (error) {
    console.error("Error fetching recurring taxes:", error.response?.data || error.message);
    throw error.response?.data || { error: "An unexpected error occurred" };
  }
};

export const createTax = async (taxData) => {
  try {
    const response = await axios.post('/recurring-taxes', taxData);
    return response;
  } catch (error) {
    console.error("Error creating recurring tax:", error.response?.data || error.message);
    throw error.response?.data || { error: "An unexpected error occurred" };
  }
};

export const updateTax = async (taxId, taxData) => {
  try {
    const response = await axios.put(`/recurring-taxes/${taxId}`, taxData);
    return response;
  } catch (error) {
    console.error("Error updating recurring tax:", error.response?.data || error.message);
    throw error.response?.data || { error: "An unexpected error occurred" };
  }
};

export const deleteTax = async (taxId) => {
  try {
    const response = await axios.delete(`/recurring-taxes/${taxId}`);
    return response;
  } catch (error) {
    console.error("Error deleting recurring tax:", error.response?.data || error.message);
    throw error.response?.data || { error: "An unexpected error occurred" };
  }
};

export const getTax = async (taxId) => {
  try {
    const response = await axios.get(`/recurring-taxes/${taxId}`);
    return response;
  } catch (error) {
    console.error("Error fetching recurring tax:", error.response?.data || error.message);
    throw error.response?.data || { error: "An unexpected error occurred" };
  }
};

export const recordTaxPayment = async (taxId, paymentData) => {
  try {
    const response = await axios.post(`/recurring-taxes/${taxId}/payment`, paymentData);
    return response;
  } catch (error) {
    console.error("Error recording payment:", error.response?.data || error.message);
    throw error.response?.data || { error: "An unexpected error occurred" };
  }
};