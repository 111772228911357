import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getConstructionSite,
  updateConstructionSite,
  getConstructionSites
} from "../../../apis/ConstructionSite";
import ConstructionSiteNavbar from "./ConstructionSiteNavbar";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Textarea } from "../../ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import { Card, CardContent } from "../../ui/card";
import { Skeleton } from "../../ui/skeleton";
import Spinner from "../../Spinner";
import { toast } from "react-toastify";
import { Archive, ImagePlus } from "lucide-react";
import placeholderImage from "../../../assest/cantieri.png";
import { getContabilita } from "../../../apis/ConstructionSite";

function Informazioni() {
  const [site, setSite] = useState({
    name: "",
    client: "",
    address: "",
    amount: "",
    workType: "",
    description: "",
    city: "",
    postalCode: "",
    province: "",
    country: "",
    featuredImage: null,
    openingDate: "",
    closingDate: "",
    status: "In pianificazione",
    archivedAt: null,
    archiveReason: null
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);

  useEffect(() => {
    fetchSite();
  }, [id]);

  useEffect(() => {
    return () => {
      if (site.featuredImagePreview) {
        URL.revokeObjectURL(site.featuredImagePreview);
      }
    };
  }, [site.featuredImagePreview]);

  const getUserFromLocalStorage = () => {
    const userString = localStorage.getItem("user");
    if (!userString) return null;
    const user = JSON.parse(userString);
    if (!user.companyId) {
      console.warn("User does not have a companyId");
      user.companyId = "default";
    }
    return user;
  };

  const fetchClients = async () => {
    try {
      const user = getUserFromLocalStorage();
      if (!user) {
        console.error("User information not found");
        return;
      }
      const response = await getConstructionSites(user.companyId, 1, 10);
      setClients(response.data.clients);
    } catch (error) {
      console.error("Error fetching clients:", error);
      toast.error("Errore nel recupero dei clienti");
    }
  };

  const fetchSite = async () => {
    setIsLoading(true);
    try {
      const response = await getConstructionSite(id);
      setSite(response.data);
      setIsArchived(response.data.status === "Archiviato");
    } catch (error) {
      console.error("Errore nel recupero del cantiere:", error);
      toast.error("Errore nel recupero del cantiere");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleInputChange = (e) => {
    if (isArchived) return;

    const { name, value } = e.target;

    if (name === "closingDate") {
      const openingDate = new Date(site.openingDate);
      const selectedClosingDate = new Date(value);

      if (selectedClosingDate < openingDate) {
        toast.error(
          "La data di chiusura non può essere precedente alla data di apertura"
        );
        return;
      }
    }

    setSite({ ...site, [name]: value });
  };

  const handleFileChange = (e) => {
    if (isArchived) return;

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const previewUrl = URL.createObjectURL(file); // Create preview URL

      setSite((prev) => ({
        ...prev,
        featuredImage: file, // Store actual file for upload
        featuredImagePreview: previewUrl // Store preview URL for immediate display
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isArchived) return;

    setIsUpdating(true);

    try {
      const formData = new FormData();

      // Append each field to FormData
      Object.keys(site).forEach((key) => {
        if (key === "featuredImage") {
          if (site[key] && typeof site[key] !== "string") {
            // Only append if it's a new file (not an existing URL)
            formData.append("featuredImage", site[key]);
          }
        } else if (site[key] !== null && site[key] !== undefined) {
          formData.append(key, site[key]);
        }
      });

      //console.log("FormData Content:");
      for (let [key, value] of formData.entries()) {
        //console.log(`${key}:`, value);
      }

      // Send request
      await updateConstructionSite(id, formData);

      await fetchSite();
      navigate("/cantieri");
      toast.success("Cantiere aggiornato con successo");
    } catch (error) {
      console.error("Errore nell'aggiornamento del cantiere:", error);
      toast.error("Errore nell'aggiornamento del cantiere");
    } finally {
      setIsUpdating(false);
    }
  };

  const checkPaymentStatus = async () => {
    try {
      const contabilityData = await getContabilita(id);
      return contabilityData.percentagePaid === 100;
    } catch (error) {
      console.error("Error checking payment status:", error);
      return false;
    }
  };

  const handleSelectChange = async (name, value) => {
    if (isArchived) return;

    if (name === "status" && value === "Terminato") {
      setIsCheckingStatus(true);
      const isFullyPaid = await checkPaymentStatus();
      setIsCheckingStatus(false);

      if (!isFullyPaid) {
        toast.error(
          "Non è possibile impostare lo stato su 'Terminato' finché la percentuale pagata non raggiunge il 100%"
        );
        return;
      }
    }

    setSite((prevSite) => ({
      ...prevSite,
      [name]: value
    }));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <ConstructionSiteNavbar />

      {isArchived && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 my-4 mx-6">
          <div className="flex items-center">
            <Archive className="w-5 h-5 text-yellow-400 mr-2" />
            <p className="text-yellow-700">
              Questo cantiere è archiviato.
              {site.archiveReason && (
                <span className="ml-2">Motivo: {site.archiveReason}</span>
              )}
              <span className="ml-2">
                Data archiviazione:{" "}
                {new Date(site.archivedAt).toLocaleDateString("it-IT")}
              </span>
            </p>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <Card className="border-0">
          <CardContent className="p-6">
            <div className="space-y-4">
              <div>
                <h2 className="text-lg font-medium">Immagine in evidenza</h2>
                <div className="md:flex justify-between items-start mt-2">
                  <div className="flex flex-col items-start">
                    {/* Image Preview Container */}
                    <div className="relative w-32 h-32 rounded-full overflow-hidden group cursor-pointer">
                      {/* Image Preview */}
                      <img
                        src={
                          site.featuredImagePreview
                            ? site.featuredImagePreview
                            : site.featuredImage
                            ? `${process.env.REACT_APP_BACKEND_URL}/${site.featuredImage}`
                            : placeholderImage
                        }
                        alt={site.name}
                        className="w-full h-full object-cover"
                      />

                      {/* File Input & Icon on Hover */}
                      <label className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
                        <ImagePlus className="w-6 h-6 text-white" />
                        <input
                          type="file"
                          onChange={handleFileChange}
                          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                          accept="image/*"
                        />
                      </label>
                    </div>

                    {/* Confirmation Text Below Image */}
                    <p className="text-xs text-gray-500 mt-2 italic">
                      L'immagine verrà aggiornata solo dopo il salvataggio.
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-y-4 gap-x-8">
                <div className="space-y-2">
                  <Label htmlFor="name">Nome Cantiere</Label>
                  <Input
                    id="name"
                    name="name"
                    value={site.name}
                    onChange={handleInputChange}
                    required
                    disabled={isArchived}
                    className={
                      isArchived ? "bg-gray-100 cursor-not-allowed" : ""
                    }
                  />
                </div>

                {/* Codice */}
                <div className="space-y-2">
                  <Label htmlFor="code">Codice</Label>
                  <Input
                    id="code"
                    name="code"
                    value={site.code || ""}
                    onChange={handleInputChange}
                    disabled={isArchived}
                    className={
                      isArchived ? "bg-gray-100 cursor-not-allowed" : ""
                    }
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="amount">Importo €</Label>
                  <Input
                    id="amount"
                    name="amount"
                    type="number"
                    step="0.01"
                    min="0"
                    value={site.amount}
                    onChange={handleInputChange}
                    required
                    disabled={isArchived}
                    className={
                      isArchived ? "bg-gray-100 cursor-not-allowed" : ""
                    }
                  />
                </div>
                {/* Budget lavori */}
                <div className="space-y-2">
                  <Label htmlFor="budget">Budget lavori €</Label>
                  <Input
                    id="budget"
                    name="budget"
                    type="number"
                    step="0.01"
                    min="0"
                    value={site.budget || ""}
                    onChange={handleInputChange}
                    disabled={isArchived}
                    className={
                      isArchived ? "bg-gray-100 cursor-not-allowed" : ""
                    }
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="client">Committente</Label>
                  <Select
                    name="client"
                    value={site.client}
                    onValueChange={(value) =>
                      handleSelectChange("client", value)
                    }
                    disabled={isArchived}
                  >
                    <SelectTrigger
                      className={
                        isArchived ? "bg-gray-100 cursor-not-allowed" : ""
                      }
                    >
                      <SelectValue placeholder="Seleziona committente" />
                    </SelectTrigger>
                    <SelectContent>
                      {clients.map((client) => (
                        <SelectItem key={client._id} value={client._id}>
                          {client.typology === "Private"
                            ? client.fullName
                            : client.companyName}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="workType">Tipologia di lavoro</Label>
                  <Select
                    name="workType"
                    value={site.workType}
                    onValueChange={(value) =>
                      handleSelectChange("workType", value)
                    }
                    disabled={isArchived}
                  >
                    <SelectTrigger
                      className={
                        isArchived ? "bg-gray-100 cursor-not-allowed" : ""
                      }
                    >
                      <SelectValue placeholder="Seleziona tipologia" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Manutenzione ordinaria">
                        Manutenzione ordinaria
                      </SelectItem>
                      <SelectItem value="Manutenzione straordinaria">
                        Manutenzione straordinaria
                      </SelectItem>
                      <SelectItem value="Restauro">Restauro</SelectItem>
                      <SelectItem value="Risanamento conservativo">
                        Risanamento conservativo
                      </SelectItem>
                      <SelectItem value="Ristrutturazione edilizia">
                        Ristrutturazione edilizia
                      </SelectItem>
                      <SelectItem value="Nuova costruzione">
                        Nuova costruzione
                      </SelectItem>
                      <SelectItem value="Ristrutturazione urbanistica">
                        Ristrutturazione urbanistica
                      </SelectItem>
                      <SelectItem value="Lavori stradali">
                        Lavori stradali
                      </SelectItem>
                      <SelectItem value="Impianti fognari">
                        Impianti fognari
                      </SelectItem>
                      <SelectItem value="Movimento terra">
                        Movimento terra
                      </SelectItem>
                      <SelectItem value="Impiantistica">
                        Impiantistica
                      </SelectItem>
                      <SelectItem value="Bonifica">Bonifica</SelectItem>
                      <SelectItem value="Demolizione">Demolizione</SelectItem>
                      <SelectItem value="Edilizia industrializzata">
                        Edilizia industrializzata
                      </SelectItem>
                      <SelectItem value="Opere idrauliche">
                        Opere idrauliche
                      </SelectItem>
                      <SelectItem value="Impianti idroelettrici">
                        Impianti idroelettrici
                      </SelectItem>
                      <SelectItem value="Opere marittime">
                        Opere marittime
                      </SelectItem>
                      <SelectItem value="Lavori ferroviari">
                        Lavori ferroviari
                      </SelectItem>
                      <SelectItem value="Lavori di linee e condotte">
                        Lavori di linee e condotte
                      </SelectItem>
                      <SelectItem value="Lavori al sottosuolo">
                        Lavori al sottosuolo
                      </SelectItem>
                      <SelectItem value="Lavori speciali">
                        Lavori speciali
                      </SelectItem>
                      <SelectItem value="Altro">Altro</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                {/* Apertura cantiere */}
                <div className="space-y-2">
                  <Label htmlFor="openingDate">Apertura cantiere</Label>
                  <Input
                    type="date"
                    id="openingDate"
                    name="openingDate"
                    value={
                      site.openingDate
                        ? new Date(site.openingDate).toISOString().split("T")[0]
                        : ""
                    }
                    onChange={handleInputChange}
                    required
                    disabled={isArchived}
                    className={
                      isArchived ? "bg-gray-100 cursor-not-allowed" : ""
                    }
                  />
                </div>

                {/* Chiusura cantiere */}
                <div className="space-y-2">
                  <Label htmlFor="closingDate">Chiusura cantiere</Label>
                  <Input
                    type="date"
                    id="closingDate"
                    name="closingDate"
                    min={
                      site.openingDate
                        ? new Date(site.openingDate).toISOString().split("T")[0]
                        : ""
                    }
                    value={
                      site.closingDate
                        ? new Date(site.closingDate).toISOString().split("T")[0]
                        : ""
                    }
                    onChange={handleInputChange}
                    disabled={isArchived}
                    className={
                      isArchived ? "bg-gray-100 cursor-not-allowed" : ""
                    }
                  />
                </div>

                {/* Stato del cantiere */}
                <div className="space-y-2">
                  <Label htmlFor="status">Stato del cantiere</Label>
                  <Select
                    name="status"
                    value={site.status}
                    onValueChange={(value) =>
                      handleSelectChange("status", value)
                    }
                    disabled={isArchived || isCheckingStatus}
                  >
                    <SelectTrigger
                      className={`${
                        isArchived ? "bg-gray-100 cursor-not-allowed" : ""
                      } ${isCheckingStatus ? "opacity-50" : ""}`}
                    >
                      <SelectValue placeholder="Seleziona stato" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="In pianificazione">
                        In pianificazione
                      </SelectItem>
                      <SelectItem value="In corso">In corso</SelectItem>
                      <SelectItem value="In revisione">In revisione</SelectItem>
                      <SelectItem value="Sospeso">Sospeso</SelectItem>
                      <SelectItem value="In collaudo">In collaudo</SelectItem>
                      <SelectItem value="Terminato">Terminato</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div className="space-y-2">
                <Label htmlFor="description">Descrizione dei lavori</Label>
                <Textarea
                  id="description"
                  name="description"
                  value={site.description}
                  onChange={handleInputChange}
                  disabled={isArchived}
                  className={isArchived ? "bg-gray-100 cursor-not-allowed" : ""}
                  rows={3}
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="country">Nazione</Label>
                  <Input
                    id="country"
                    name="country"
                    value={site.country}
                    onChange={handleInputChange}
                    required
                    disabled={isArchived}
                    className={
                      isArchived ? "bg-gray-100 cursor-not-allowed" : ""
                    }
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="city">Città</Label>
                  <Input
                    id="city"
                    name="city"
                    value={site.city}
                    onChange={handleInputChange}
                    required
                    disabled={isArchived}
                    className={
                      isArchived ? "bg-gray-100 cursor-not-allowed" : ""
                    }
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="province">Provincia</Label>
                  <Input
                    id="province"
                    name="province"
                    value={site.province}
                    onChange={handleInputChange}
                    required
                    disabled={isArchived}
                    className={
                      isArchived ? "bg-gray-100 cursor-not-allowed" : ""
                    }
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="postalCode">CAP</Label>
                  <Input
                    id="postalCode"
                    name="postalCode"
                    value={site.postalCode}
                    onChange={handleInputChange}
                    required
                    disabled={isArchived}
                    className={
                      isArchived ? "bg-gray-100 cursor-not-allowed" : ""
                    }
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="address">Indirizzo</Label>
                  <Input
                    id="address"
                    name="address"
                    value={site.address}
                    onChange={handleInputChange}
                    required
                    disabled={isArchived}
                    className={
                      isArchived ? "bg-gray-100 cursor-not-allowed" : ""
                    }
                  />
                </div>
              </div>

              {!isArchived && (
                <div className="flex justify-end space-x-2 mt-4">
                  <Button
                    type="button"
                    onClick={() => navigate("/cantieri")}
                    variant="outline"
                  >
                    Annulla
                  </Button>
                  <Button type="submit" disabled={isUpdating}>
                    {isUpdating ? "Aggiornamento..." : "Salva"}
                  </Button>
                </div>
              )}
              {isArchived && (
                <div className="flex justify-end mt-4">
                  <Button
                    type="button"
                    onClick={() => navigate("/cantieri")}
                    variant="outline"
                  >
                    Torna indietro
                  </Button>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </form>
    </div>
  );
}

export default Informazioni;
