import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Edit, Plus, FileDown, Trash2 } from "lucide-react";
import {
  AddCompanyShares,
  deleteMemberShares,
  updateMemberShare
} from "../../../../apis/companyShares";
import { toast } from "react-toastify";
import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import { Card, CardHeader, CardTitle, CardContent } from "../../../ui/card";
import { exportToPDF } from "../../../../config/helper";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "../../../ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from "../../../ui/alert-dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "../../../ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../../ui/select";

const ROLES = [
  "Amministratore",
  "Socio",
  "Responsabile finanze",
  "Responsabile tecnologie",
  "Marketing manager",
  "HR Manager",
  "Direttore operativo",
  "Direttore commerciale",
  "Responsabile clienti e fornitori",
  "Project Manager"
];

const Ownership = ({ SharesData, dataUpdated, setDataUpdated, printRef }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      role: "Socio",
      name: "",
      email: "",
      shares: "",
      labelColor: "#FFFFFF"
    }
  });

  const totalExistingShares = SharesData.reduce(
    (sum, member) => sum + (parseFloat(member.shares) || 0),
    0
  );

  useEffect(() => {
    if (!isDialogOpen) {
      reset();
      setSelectedMember(null);
    }
  }, [isDialogOpen]);

  useEffect(() => {
    if (selectedMember) {
      setValue("role", selectedMember.role);
      setValue("name", selectedMember.name);
      setValue("email", selectedMember.email);
      setValue("shares", selectedMember.shares);
      setValue("labelColor", selectedMember.labelColor);
    }
  }, [selectedMember, setValue]);

  const handleAddOrUpdateMember = async (data) => {
    const newShare = parseFloat(data.shares);
    const currentTotal = selectedMember
      ? totalExistingShares - parseFloat(selectedMember.shares)
      : totalExistingShares;

    if (currentTotal + newShare > 100) {
      toast.error("Il totale delle quote non può superare il 100%");
      return;
    }

    try {
      if (selectedMember) {
        const updatePayload = {
          companyId,
          memberId: selectedMember._id,
          updates: {
            name: data.name,
            email: data.email,
            shares: parseFloat(data.shares),
            role: data.role,
            labelColor: data.labelColor
          }
        };

        //console.log("Update payload:", updatePayload); // Debug log

        const response = await updateMemberShare(updatePayload);

        if (response.status === 200) {
          toast.success("Membro aggiornato con successo");
          setDataUpdated(!dataUpdated);
        }
      } else {
        const response = await AddCompanyShares({
          companyId,
          members: [data]
        });
        if (response.status === 201) {
          toast.success("Membro aggiunto con successo");
          setDataUpdated(!dataUpdated);
        }
      }
      setIsDialogOpen(false);
      reset();
    } catch (error) {
      console.error("Error in member operation:", error);
      const errorMessage = error.error?.[0] || "Operazione non riuscita";
      toast.error(errorMessage);
    }
  };

  const handleDelete = async () => {
    if (!deleteId) return;

    try {
      const response = await deleteMemberShares({
        companyId,
        memberId: deleteId
      });

      if (response.status === 200) {
        // Update local state immediately
        const updatedShares = SharesData.filter(
          (member) => member._id !== deleteId
        );
        // Update parent component state
        setDataUpdated(!dataUpdated);
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting member shares:", error);
      const errorMessage = error.message || "Impossibile eliminare il membro";
      toast.error(errorMessage);
    } finally {
      setIsDeleteDialogOpen(false);
      setDeleteId(null);
    }
  };

  const handleEdit = (member) => {
    setSelectedMember(member);
    setIsDialogOpen(true);
  };

  const openDeleteDialog = (id) => {
    setDeleteId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleExportPDF = async () => {
    try {
      const data = SharesData.map((member) => ({
        ruolo: member.role,
        nome: member.name || "-",
        email: member.email || "-",
        quote: `${member.shares}%`
      }));

      const columns = [
        { header: "Ruolo", key: "ruolo", width: 1 },
        { header: "Nome", key: "nome", width: 1.5 },
        { header: "Email", key: "email", width: 1.5 },
        { header: "Quote", key: "quote", width: 1 }
      ];

      const totalShares = SharesData.reduce(
        (sum, member) => sum + (parseFloat(member.shares) || 0),
        0
      );

      const stats = [
        { label: "Numero di soci", value: data.length },
        { label: "Quote totali", value: `${totalShares.toFixed(2)}%` },
        { label: "Data", value: new Date().toLocaleDateString("it-IT") }
      ];

      await exportToPDF(data, columns, "Composizione Societaria", stats);
    } catch (error) {
      console.error("Error exporting to PDF:", error);
      toast.error("Errore durante l'esportazione del PDF");
    }
  };

  return (
    <Card className="w-full border-0 p-0 sm:w-2/3">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 mb-2 p-0">
        <CardTitle className="text-xl font-bold">
          Proprietà dell'azienda
        </CardTitle>
        <div className="flex gap-2">
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
              <Button size="sm" className="flex items-center gap-1">
                Aggiungi persone
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>
                  {selectedMember ? "Modifica Membro" : "Aggiungi Nuovo Membro"}
                </DialogTitle>
              </DialogHeader>
              <form
                onSubmit={handleSubmit(handleAddOrUpdateMember)}
                className="space-y-4"
              >
                <div className="space-y-2">
                  <Label htmlFor="role">Ruolo</Label>
                  <Select defaultValue="Socio" {...register("role")}>
                    <SelectTrigger>
                      <SelectValue placeholder="Seleziona ruolo" />
                    </SelectTrigger>
                    <SelectContent>
                      {ROLES.map((role) => (
                        <SelectItem key={role} value={role}>
                          {role}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="name">Nome</Label>
                  <Input
                    {...register("name", {
                      required: "Il nome è obbligatorio"
                    })}
                    placeholder="Nome completo"
                  />
                  {errors.name && (
                    <span className="text-red-500 text-sm">
                      {errors.name.message}
                    </span>
                  )}
                </div>

                <div className="space-y-2">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    type="email"
                    {...register("email", {
                      required: "L'email è obbligatoria",
                      pattern: {
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Inserisci un indirizzo email valido"
                      }
                    })}
                    placeholder="email@example.com"
                  />
                  {errors.email && (
                    <span className="text-red-500 text-sm">
                      {errors.email.message}
                    </span>
                  )}
                </div>

                <div className="space-y-2">
                  <Label htmlFor="shares">Quote (%)</Label>
                  <Input
                    type="number"
                    {...register("shares", {
                      required: "La quota è obbligatoria",
                      min: {
                        value: 0,
                        message: "La quota deve essere maggiore di 0"
                      },
                      max: {
                        value: 100,
                        message: "La quota non può superare 100%"
                      }
                    })}
                    placeholder="Percentuale quote"
                  />
                  {errors.shares && (
                    <span className="text-red-500 text-sm">
                      {errors.shares.message}
                    </span>
                  )}
                  <span className="text-sm text-gray-500">
                    Quote totali attuali:{" "}
                    {selectedMember
                      ? totalExistingShares - parseFloat(selectedMember.shares)
                      : totalExistingShares}
                    %
                  </span>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="labelColor">Colore</Label>
                  <Input
                    type="color"
                    {...register("labelColor")}
                    className="h-10 w-full"
                  />
                </div>

                <Button type="submit" className="w-full">
                  {selectedMember ? "Aggiorna" : "Aggiungi"}
                </Button>
              </form>
            </DialogContent>
          </Dialog>

          {/* <Button onClick={handleExportPDF} variant="outline" size="sm">
            <FileDown className="h-4 w-4 mr-1" />
            Esporta
          </Button> */}
        </div>
      </CardHeader>

      <CardContent className="p-0">
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="font-semibold">Nome</TableHead>
                <TableHead className="font-semibold">Email</TableHead>
                <TableHead className="font-semibold">Ruolo</TableHead>
                <TableHead className="font-semibold text-right">
                  Quote (%)
                </TableHead>
                <TableHead className="text-right"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {SharesData.map((member) => (
                <TableRow
                  key={member._id}
                  className="hover:bg-gray-50 text-start px-2"
                >
                  <TableCell className="font-medium">{member.name}</TableCell>
                  <TableCell>{member.email}</TableCell>
                  <TableCell>{member.role}</TableCell>
                  <TableCell className="text-right tabular-nums">
                    {member.shares}%
                  </TableCell>
                  <TableCell className="text-right pr-4">
                    <div className="flex justify-end gap-2">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleEdit(member)}
                        className="h-8 w-8 p-0 hover:bg-blue-50"
                      >
                        <Edit className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => openDeleteDialog(member._id)}
                        className="h-8 w-8 p-0 hover:bg-red-50"
                      >
                        <Trash2 className="h-4 w-4 text-red-500" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>

      <AlertDialog
        open={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Conferma eliminazione</AlertDialogTitle>
            <AlertDialogDescription>
              Sei sicuro di voler eliminare questo membro? Questa azione non può
              essere annullata.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setIsDeleteDialogOpen(false)}>
              Annulla
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-red-500 hover:bg-red-600"
            >
              Elimina
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Card>
  );
};

export default Ownership;
