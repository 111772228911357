import React, { useState, useEffect, useMemo } from "react";
import { useFormulario } from "./context/FormularioContext";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import { Switch } from "../../../ui/switch";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import { Textarea } from "../../../ui/textarea";

const Produttore = () => {
  const { updateProduttore, formularioData } = useFormulario();
  const produttoreData = useMemo(
    () => formularioData.produttore || {},
    [formularioData.produttore]
  );

  const [useCurrentUnit, setUseCurrentUnit] = useState(
    produttoreData.useCurrentUnit || false
  );
  const [trasbordoParziale, setTrasbordoParziale] = useState(
    produttoreData.trasbordoParziale?.isTrasbordoParziale || false
  );
  const [isDetentore, setIsDetentore] = useState(
    produttoreData.isDetentore || false
  );
  const [showLuogoProduzioneExtraFields, setShowLuogoProduzioneExtraFields] =
    useState(produttoreData.luogoProduzione?.diversoDaUnitaLocale || false);

  const [
    showTrasbordoParzialeExtraFields,
    setShowTrasbordoParzialeExtraFields,
  ] = useState(produttoreData.trasbordoParziale?.isTrasbordoParziale || false);

  useEffect(() => {
    setUseCurrentUnit(produttoreData.useCurrentUnit || false);
    setIsDetentore(produttoreData.isDetentore || false);
    setTrasbordoParziale(
      produttoreData.trasbordoParziale?.isTrasbordoParziale || false
    );
    setShowLuogoProduzioneExtraFields(
      produttoreData.luogoProduzione?.diversoDaUnitaLocale || false
    );
  }, [produttoreData]);

  const handleProduttoreChange = (field, value) => {
    updateProduttore({
      ...produttoreData,
      datiProduttore: {
        ...produttoreData.datiProduttore,
        [field]: value,
      },
    });
  };

  const handleLuogoProduzioneChange = (field, value) => {
    updateProduttore({
      ...produttoreData,
      luogoProduzione: {
        ...produttoreData.luogoProduzione,
        [field]: value,
      },
    });
  };

  const handleAutorizzazioniChange = (field, value) => {
    updateProduttore({
      ...produttoreData,
      autorizzazioni: {
        ...produttoreData.autorizzazioni,
        [field]: value,
      },
    });
  };

  const handleTrasbordoParzialeChange = (field, value) => {
    updateProduttore({
      ...produttoreData,
      trasbordoParziale: {
        ...produttoreData.trasbordoParziale,
        [field]: value,
      },
    });
  };

  const handleUseCurrentUnitChange = (checked) => {
    setUseCurrentUnit(checked);
    updateProduttore({
      ...produttoreData,
      useCurrentUnit: checked,
    });
  };

  const handleIsDetentoreChange = (checked) => {
    setIsDetentore(checked);
    updateProduttore({
      ...produttoreData,
      isDetentore: checked,
    });
  };

  const handleLuogoDiversoChange = (checked) => {
    setShowLuogoProduzioneExtraFields(checked);
    updateProduttore({
      ...produttoreData,
      luogoProduzione: {
        ...produttoreData.luogoProduzione,
        diversoDaUnitaLocale: checked,
      },
    });
  };

  const handleTrasbordoParziale = (checked) => {
    setShowTrasbordoParzialeExtraFields(checked);
    updateProduttore({
      ...produttoreData,
      trasbordoParziale: {
        ...produttoreData.trasbordoParziale,
        isTrasbordoParziale: checked,
      },
    });
  };

  return (
    <div className="space-y-6">
      {/* Trasbordo parziale section */}
      <Card>
        <CardHeader>
          <CardTitle>Trasbordo parziale</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex gap-8">
              <div className="space-y-2 w-full">
                <div className="flex items-center justify-between w-fit gap-4">
                  <Label htmlFor="trasbordoParziale" className="font-medium">
                    FIR da trasbordo parziale
                  </Label>
                  <Switch
                    id="trasbordoParziale"
                    checked={trasbordoParziale}
                    onCheckedChange={handleTrasbordoParziale}
                  />
                </div>
                <div
                  className={`
                    overflow-y-clip overflow-x-visible transition-all duration-300 ease-in-out pb-1
                    ${
                      showTrasbordoParzialeExtraFields
                        ? "max-h-[281px] opacity-100"
                        : "max-h-0 opacity-0"
                    }
                  `}
                >
                  <div className="space-y-4">
                    {/* First row */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor="numeroFIR">Numero FIR</Label>
                        <Input
                          value={
                            produttoreData.trasbordoParziale.numeroFir || ""
                          }
                          onChange={(e) =>
                            handleTrasbordoParzialeChange(
                              "numeroFir",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>

                    {/* Second row */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor="nazionalita">Nazionalità</Label>
                        <Select
                          value={
                            produttoreData.trasbordoParziale.nazionalita || ""
                          }
                          onValueChange={(value) =>
                            handleTrasbordoParzialeChange("nazionalita", value)
                          }
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Seleziona nazione" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="IT">Italia</SelectItem>
                            <SelectItem value="DE">Germania</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="codiceFiscale">Codice Fiscale</Label>
                        <Input
                          id="codiceFiscale"
                          value={
                            produttoreData.trasbordoParziale?.codiceFiscale ||
                            ""
                          }
                          onChange={(e) =>
                            handleTrasbordoParzialeChange(
                              "codiceFiscale",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="denominazione">Denominazione</Label>
                        <Input
                          id="denominazione"
                          value={
                            produttoreData.trasbordoParziale?.denominazione ||
                            ""
                          }
                          onChange={(e) =>
                            handleTrasbordoParzialeChange(
                              "denominazione",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>

                    {/* New row for Causale */}
                    <div className="grid grid-cols-1 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor="causale">Causale</Label>
                        <Textarea
                          id="causale"
                          className="resize-none"
                          value={
                            produttoreData.trasbordoParziale?.causale || ""
                          }
                          onChange={(e) =>
                            handleTrasbordoParzialeChange(
                              "causale",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Only show these cards if trasbordoParziale is false */}
      {!trasbordoParziale && (
        <>
          {/* Manufacturer Section */}
          <Card>
            <CardHeader>
              <CardTitle>Produttore</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {/* First row */}
                <div className="flex gap-8">
                  <div className="space-y-2">
                    <div className="flex items-center justify-between w-fit gap-4">
                      <Label htmlFor="useCurrentUnit" className="font-medium">
                        Ricopia anagrafica dell'unità corrente
                      </Label>
                      <Switch
                        id="useCurrentUnit"
                        checked={useCurrentUnit}
                        onCheckedChange={handleUseCurrentUnitChange}
                      />
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="flex items-center justify-between w-fit gap-4">
                      <Label htmlFor="isDetentore" className="font-medium">
                        I dati si riferiscono al detentore
                      </Label>
                      <Switch
                        id="isDetentore"
                        checked={isDetentore}
                        onCheckedChange={handleIsDetentoreChange}
                      />
                    </div>
                  </div>
                </div>

                {/* Second row */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="nazionalita">Nazionalità</Label>
                    <Select
                      value={produttoreData.datiProduttore?.nazionalita || ""}
                      onValueChange={(value) =>
                        handleProduttoreChange("nazionalita", value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Seleziona nazionalità" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="IT">Italia</SelectItem>
                        {/* Add more countries as needed */}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="codiceFiscale">Codice Fiscale</Label>
                    <Input
                      id="codiceFiscale"
                      value={produttoreData.datiProduttore?.codiceFiscale || ""}
                      onChange={(e) =>
                        handleProduttoreChange("codiceFiscale", e.target.value)
                      }
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="denominazione">Denominazione</Label>
                    <Input
                      id="denominazione"
                      value={produttoreData.datiProduttore?.denominazione || ""}
                      onChange={(e) =>
                        handleProduttoreChange("denominazione", e.target.value)
                      }
                    />
                  </div>
                </div>

                {/* Third row */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="nazione">Nazione</Label>
                    <Select
                      value={produttoreData.datiProduttore?.nazione || ""}
                      onValueChange={(value) =>
                        handleProduttoreChange("nazione", value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Seleziona nazione" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="IT">Italia</SelectItem>
                        {/* Add more countries as needed */}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="provincia">Provincia</Label>
                    <Select
                      value={produttoreData.datiProduttore?.provincia || ""}
                      onValueChange={(value) =>
                        handleProduttoreChange("provincia", value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Seleziona provincia" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="RM">Roma</SelectItem>
                        {/* Add more provinces as needed */}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="comune">Comune</Label>
                    <Select
                      value={produttoreData.datiProduttore?.comune || ""}
                      onValueChange={(value) =>
                        handleProduttoreChange("comune", value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Seleziona comune" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="ROMA">Roma</SelectItem>
                        {/* Add more cities as needed */}
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                {/* Fourth row */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="indirizzo">Indirizzo</Label>
                    <Input
                      id="indirizzo"
                      value={produttoreData.datiProduttore?.indirizzo || ""}
                      onChange={(e) =>
                        handleProduttoreChange("indirizzo", e.target.value)
                      }
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="civico">Civico</Label>
                    <Input
                      id="civico"
                      type="number"
                      value={produttoreData.datiProduttore?.civico || ""}
                      onChange={(e) =>
                        handleProduttoreChange("civico", e.target.value)
                      }
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="cap">CAP</Label>
                    <Input
                      id="cap"
                      type="number"
                      value={produttoreData.datiProduttore?.cap || ""}
                      onChange={(e) =>
                        handleProduttoreChange("cap", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Place of production section */}
          <Card>
            <CardHeader>
              <CardTitle>Luogo di produzione</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex items-center justify-between w-fit gap-4">
                  <Label htmlFor="luogoDiverso" className="font-medium">
                    Luogo di produzione del rifiuto diverso dall'unità locale
                  </Label>
                  <Switch
                    id="luogoDiverso"
                    checked={showLuogoProduzioneExtraFields}
                    onCheckedChange={handleLuogoDiversoChange}
                  />
                </div>

                <div
                  className={`
                    overflow-y-clip transition-all duration-300 ease-in-out pb-1
                    ${
                      showLuogoProduzioneExtraFields
                        ? "max-h-[188px] opacity-100"
                        : "max-h-0 opacity-0"
                    }
                  `}
                >
                  <div className="space-y-4">
                    {/* First row */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor="luogoNazione">Nazione</Label>
                        <Select
                          value={produttoreData.luogoProduzione?.nazione || ""}
                          onValueChange={(value) =>
                            handleLuogoProduzioneChange("nazione", value)
                          }
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Seleziona nazione" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="IT">Italia</SelectItem>
                            {/* Add more countries as needed */}
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="luogoProvincia">Provincia</Label>
                        <Select
                          value={
                            produttoreData.luogoProduzione?.provincia || ""
                          }
                          onValueChange={(value) =>
                            handleLuogoProduzioneChange("provincia", value)
                          }
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Seleziona provincia" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="RM">Roma</SelectItem>
                            {/* Add more provinces as needed */}
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="luogoComune">Comune</Label>
                        <Select
                          value={produttoreData.luogoProduzione?.comune || ""}
                          onValueChange={(value) =>
                            handleLuogoProduzioneChange("comune", value)
                          }
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Seleziona comune" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="ROMA">Roma</SelectItem>
                            {/* Add more cities as needed */}
                          </SelectContent>
                        </Select>
                      </div>
                    </div>

                    {/* Second row */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor="luogoIndirizzo">Indirizzo</Label>
                        <Input
                          id="luogoIndirizzo"
                          value={
                            produttoreData.luogoProduzione?.indirizzo || ""
                          }
                          onChange={(e) =>
                            handleLuogoProduzioneChange(
                              "indirizzo",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="luogoCivico">Civico</Label>
                        <Input
                          id="luogoCivico"
                          type="number"
                          value={produttoreData.luogoProduzione?.civico || ""}
                          onChange={(e) =>
                            handleLuogoProduzioneChange(
                              "civico",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="luogoCAP">CAP</Label>
                        <Input
                          id="luogoCAP"
                          type="number"
                          value={produttoreData.luogoProduzione?.cap || ""}
                          onChange={(e) =>
                            handleLuogoProduzioneChange("cap", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Authorizations section */}
          <Card>
            <CardHeader>
              <CardTitle>Autorizzazioni</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="numeroAlbo">Numero descrizione albo</Label>
                  <Input
                    id="numeroAlbo"
                    value={produttoreData.autorizzazioni?.numeroAlbo || ""}
                    onChange={(e) =>
                      handleAutorizzazioniChange("numeroAlbo", e.target.value)
                    }
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="tipoAutorizzazione">
                    Tipo autorizzazione
                  </Label>
                  <Select
                    value={produttoreData.autorizzazioni?.tipo || ""}
                    onValueChange={(value) =>
                      handleAutorizzazioniChange("tipo", value)
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Seleziona tipo" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="tipo1">Tipo 1</SelectItem>
                      <SelectItem value="tipo2">Tipo 2</SelectItem>
                      {/* Add more types as needed */}
                    </SelectContent>
                  </Select>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="numero">Numero</Label>
                  <Input
                    id="numero"
                    type="number"
                    value={produttoreData.autorizzazioni?.numero || ""}
                    onChange={(e) =>
                      handleAutorizzazioniChange("numero", e.target.value)
                    }
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
};

export default Produttore;
