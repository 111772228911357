import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Edit, Upload, Calendar, Key } from "lucide-react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription
} from "../../ui/card";
import { Button } from "../../ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent
} from "../../ui/tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "../../ui/dropdown-menu";
import EditUsernameModal from "./modals/EditUsernameModal";
import EditDobModal from "./modals/EditDobModal";
import EditPasswordModal from "./modals/EditPasswordModal";
import DeleteAccountModal from "./modals/DeleteAccountModal";

function ProfilePage() {
  const [showEditUsername, setShowEditUsername] = useState(false);
  const [showEditDob, setShowEditDob] = useState(false);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const navigate = useNavigate();

  const [userData, setUserData] = useState(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsed = JSON.parse(storedUser);
      return parsed;
    }
    return null;
  });

  // Effect to update userData if localStorage changes
  useEffect(() => {
    const handleStorageChange = () => {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        const parsed = JSON.parse(storedUser);
        setUserData(parsed);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  // Handler functions
  const handleShowEditUsername = () => setShowEditUsername(true);
  const handleCloseEditUsername = () => setShowEditUsername(false);

  const handleShowEditDob = () => setShowEditDob(true);
  const handleCloseEditDob = () => setShowEditDob(false);

  const handleShowEditPassword = () => setShowEditPassword(true);
  const handleCloseEditPassword = () => setShowEditPassword(false);

  const handleShowDeleteAccount = () => setShowDeleteAccount(true);
  const handleCloseDeleteAccount = () => setShowDeleteAccount(false);

  // Description to avoid warnings
  const dialogDescription = "Modifica le tue informazioni personali";

  return (
    <div className="mx-auto p-6 space-y-6">
      <Card className="border-0">
        <CardHeader>
          <CardTitle className="text-2xl font-bold">Profilo Utente</CardTitle>
          <CardDescription>
            Gestisci le tue informazioni personali e le preferenze del tuo
            account.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="flex flex-col items-center space-y-4">
              <Avatar className="w-32 h-32">
                <AvatarImage
                  src="https://cdn.dribbble.com/users/3305260/screenshots/6628643/order-_fo59bc14fe01.jpg"
                  alt={`${userData?.username} avatar`}
                />
                <AvatarFallback>{userData?.username?.charAt(0)}</AvatarFallback>
              </Avatar>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" disabled>
                    <Upload className="mr-2 h-4 w-4" /> Gestisci Foto
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem>Carica nuova foto</DropdownMenuItem>
                  <DropdownMenuItem>Rimuovi foto</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <div className="md:col-span-2 space-y-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-4">
                <div>
                  <Label>Nome Completo</Label>
                  <div className="flex items-center space-x-2">
                    <Input value={userData?.username || ""} readOnly />
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={handleShowEditUsername}
                            disabled={!userData?.id}
                          >
                            <Edit className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Modifica nome</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                </div>

                <div>
                  <Label>Email</Label>
                  <div className="flex items-center space-x-2">
                    <Input value={userData?.email || ""} readOnly />
                  </div>
                </div>

                <div>
                  <Label>Nome Azienda</Label>
                  <div className="flex items-center space-x-2">
                    <Input value={userData?.companyName || ""} readOnly />
                  </div>
                </div>

                <div>
                  <Label>Data di nascita</Label>
                  <div className="flex items-center space-x-2">
                    <Input value={userData?.dob || ""} readOnly />
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={handleShowEditDob}
                      disabled={!userData?.id}
                    >
                      <Calendar className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card className="border-0">
        <CardHeader>
          <CardTitle>Sicurezza dell'Account</CardTitle>
          <CardDescription>
            Gestisci le impostazioni di sicurezza e l'accesso al tuo account.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="flex justify-between items-center">
            <div>
              <h4 className="text-lg font-semibold">Password</h4>
              <p className="text-sm text-gray-500">
                Ultima modifica: 3 mesi fa
              </p>
            </div>
            <Button variant="outline" onClick={handleShowEditPassword}>
              <Key className="mr-2 h-4 w-4" /> Modifica Password
            </Button>
          </div>
          <div className="flex justify-between items-center">
            <div>
              <h4 className="text-lg font-semibold">
                Autenticazione a Due Fattori
              </h4>
              <p className="text-sm text-gray-500">
                Aumenta la sicurezza del tuo account
              </p>
            </div>
            <Button disabled variant="outline">
              Funzionalità in arrivo
            </Button>
          </div>
          <div className="flex justify-between items-center">
            <div>
              <h4 className="text-lg font-semibold">Sessioni Attive</h4>
              <p className="text-sm text-gray-500">
                Gestisci i tuoi accessi attivi
              </p>
            </div>
            <Button disabled variant="outline">
              Funzionalità in arrivo
            </Button>
          </div>
        </CardContent>
      </Card>

      <EditUsernameModal
        isOpen={showEditUsername}
        onClose={handleCloseEditUsername}
        id={userData?.id}
        currentUsername={userData?.username}
        description={dialogDescription}
        onSuccess={(newUsername) => {
          const updatedUser = { ...userData, username: newUsername };
          localStorage.setItem("user", JSON.stringify(updatedUser));
          setUserData(updatedUser);
        }}
      />

      <EditDobModal
        isOpen={showEditDob}
        onClose={handleCloseEditDob}
        id={userData?.id}
        currentDob={userData?.dob}
        description={dialogDescription}
        onSuccess={(newDob) => {
          const updatedUser = { ...userData, dob: newDob };
          localStorage.setItem("user", JSON.stringify(updatedUser));
          setUserData(updatedUser);
        }}
      />
      <EditPasswordModal
        isOpen={showEditPassword}
        onClose={handleCloseEditPassword}
      />
      <DeleteAccountModal
        isOpen={showDeleteAccount}
        onClose={handleCloseDeleteAccount}
      />
    </div>
  );
}

export default ProfilePage;
