import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import {
  MdDashboard,
  MdConstruction,
  MdChat,
  MdChecklist,
} from "react-icons/md";
import {
  FaMagento,
  FaCalendarAlt,
  FaCalculator,
  FaTruck,
  FaHome,
  FaCar,
  FaBox,
  FaUsers,
  FaHandshake,
  FaIndustry,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import { PiUsersThreeFill } from "react-icons/pi";
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetFooter,
  SheetClose,
} from "../../ui/sheet";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from "../../ui/dropdown-menu";
import {
  BellDot,
  Search,
  Calendar,
  Building2,
  Users,
  Truck,
  Warehouse,
  CreditCard,
  FileText,
  Clipboard,
  HardHat,
  MessageSquare,
  UserCircle,
  HelpCircle,
  CalendarDays,
  User,
  Settings,
  Menu,
  Archive,
  FileSignature,
  Landmark,
} from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from "../../ui/command";
import { Input } from "../../ui/input";
import { Badge } from "../../ui/badge";
import { performSearch } from "../../../apis/SearchApi";
import { getEvents } from "../../../apis/Event";
import { fetchDocuments } from "../../../apis/Document";
import UserDropdown from "./UserDropdown";
import logo from "../../../assest/logo1.png";
import NotificationDropdown from "./NotificationDropdown";

const searchItems = [
  {
    icon: <Calendar className="mr-2 h-4 w-4" />,
    label: "Dashboard",
    path: "/dashboard",
  },
  {
    icon: <Calendar className="mr-2 h-4 w-4" />,
    label: "Calendario",
    path: "/calendar",
  },
  {
    icon: <Building2 className="mr-2 h-4 w-4" />,
    label: "Azienda",
    path: "/agency/information",
  },
  {
    icon: <Users className="mr-2 h-4 w-4" />,
    label: "Dipendenti",
    path: "/employees/all employees",
  },
  {
    icon: <User className="mr-2 h-4 w-4" />,
    label: "Clienti",
    path: "/clients",
  },
  {
    icon: <User className="mr-2 h-4 w-4" />,
    label: "Fornitori",
    path: "/suppliers",
  },
  {
    icon: <Truck className="mr-2 h-4 w-4" />,
    label: "Flotta",
    path: "/flotta",
  },
  {
    icon: <Warehouse className="mr-2 h-4 w-4" />,
    label: "Magazzino",
    path: "/magazzino",
  },
  {
    icon: <CreditCard className="mr-2 h-4 w-4" />,
    label: "Movimenti",
    path: "/movimenti",
  },
  {
    icon: <Landmark className="mr-2 h-4 w-4" />,
    label: "Conti bancari",
    path: "/payment-methods",
  },
  { icon: <FileText className="mr-2 h-4 w-4" />, label: "DDT", path: "/ddt" },
  {
    icon: <Clipboard className="mr-2 h-4 w-4" />,
    label: "Computi Metrici",
    path: "/computi-metrici",
  },
  {
    icon: <FileText className="mr-2 h-4 w-4" />,
    label: "Preventivi",
    path: "/preventivi",
  },
  {
    icon: <HardHat className="mr-2 h-4 w-4" />,
    label: "Cantieri",
    path: "/cantieri",
  },
  {
    icon: <MessageSquare className="mr-2 h-4 w-4" />,
    label: "Chat",
    path: "/chat",
  },
  {
    icon: <Settings className="mr-2 h-4 w-4" />,
    label: "Impostazioni",
    path: "/impostazioni",
  },
  {
    icon: <UserCircle className="mr-2 h-4 w-4" />,
    label: "Il mio profilo",
    path: "/profilio",
  },
  {
    icon: <Users className="mr-2 h-4 w-4" />,
    label: "Utenti",
    path: "/utenti",
  },
  {
    icon: <HelpCircle className="mr-2 h-4 w-4" />,
    label: "Assistenza",
    path: "/assistenza",
  },
  {
    icon: <CreditCard className="mr-2 h-4 w-4" />,
    label: "Abbonamento",
    path: "/subscription",
  },
  {
    icon: <CalendarDays className="mr-2 h-4 w-4" />,
    label: "Agenda",
    path: "/calendar",
  },
  {
    icon: <Archive className="mr-2 h-4 w-4" />,
    label: "Archivio",
    path: "/archive",
  },
  {
    icon: <FileSignature className="mr-2 h-4 w-4" />,
    label: "Documenti",
    path: "/documenti",
  },
  {
    icon: <FileText className="mr-2 h-4 w-4" />,
    label: "Prima Nota",
    path: "/prima-nota",
  },
];

const routeTitles = {
  "/": "Dashboard",
  "/dashboard": "Dashboard",
  "/calendar": "Calendario",
  "/employees": "Dipendenti",
  "/clients": "Clienti",
  "/suppliers": "Fornitori",
  "/computi-metrici": "Computi Metrici",
  "/flotta": "Flotta",
  "/profilio": "Profilo",
  "/utenti": "Utenti",
  "/magazzino": "Magazzino",
  "/movimenti": "Movimenti",
  "/payment-methods": "Conti bancari",
  "/chat": "Chat",
  "/assistenza": "Assistenza",
  "/impostazioni": "Impostazioni",
  "/ddt": "DDT",
  "/preventivi": "Preventivi",
  "/cantieri": "Cantieri",
  "/prezzari": "Prezzari",
  "/capitoli": "Capitoli",
  "/categoria": "Categoria",
  "/famiglia": "Famiglia",
  "/regioni": "Regioni",
  "/subscription": "Abbonamento",
  "/status_preventivi": "Pianificazione",
  "/settings": "Impostazioni",
  "/preventivi_add": "Crea preventivo",
  "/preventivi_edit": "Modifica preventivo",
  "/notifications": "Notifiche",
  "/ddt_add": "DDT",
  "/archive": "Archivio",
  "/installment": "Rateizzi",
  "/documenti": "Documenti",
  "/prima-nota": "Prima Nota",
  "/tax": "Tasse",
  "/budget": "Budget",
  "/tasse-ricorrenti": "Tasse Ricorrenti",
};

const styles = {
  text: {
    color: "rgb(201,201,201)",
  },
};

const SidebarContent = ({
  isActive,
  linkClass,
  dropdownStates,
  toggleDropdown,
  updateBreadcrumbs,
  isMobile,
}) => (
  <>
    <div className={`${isMobile ? "mb-6" : ""}`}>
      <Link to="/">
        <img src={logo} alt="logo" className="w-full" />
      </Link>
    </div>
    <ul className="space-y-2 font-medium mt-3">
      <li>
        <Link
          to="/dashboard"
          className={linkClass("/dashboard")}
          onClick={() =>
            updateBreadcrumbs({ path: "/dashboard", label: "Dashboard" })
          }
        >
          <MdDashboard size={18} />
          <span className="ms-3">Dashboard</span>
        </Link>
      </li>
      <li>
        <Link
          to="/calendar"
          className={linkClass("/calendar")}
          onClick={() =>
            updateBreadcrumbs({ path: "/calendar", label: "Calendario" })
          }
        >
          <FaCalendarAlt size={18} />
          <span className="ms-3 whitespace-nowrap">Calendario</span>
        </Link>
      </li>
      <h4 className="text-white pt-4" style={styles.text}>
        Organizzazione
      </h4>
      <li>
        <Link
          to="/agency/information"
          className={linkClass("/agency/information")}
          onClick={() =>
            updateBreadcrumbs({ path: "/agency/information", label: "Azienda" })
          }
        >
          <FaMagento size={18} />
          <span className="ms-3 whitespace-nowrap">Azienda</span>
        </Link>
      </li>
      <li>
        <button
          type="button"
          className={`flex items-center w-full p-2 text-base ${
            isActive("/employees") ||
            isActive("/clients") ||
            isActive("/suppliers")
              ? "bg-gray-100 text-blue-950"
              : "text-white hover:bg-gray-100 hover:text-blue-950"
          } transition duration-75 rounded-lg group`}
          onClick={() => toggleDropdown("personalData", "Persone")}
        >
          <PiUsersThreeFill size={18} />
          <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
            Persone
          </span>
          <IoIosArrowDown />
        </button>
        {dropdownStates.personalData && (
          <ul className="py-2 space-y-2">
            <li>
              <Link
                to="/employees/all employees"
                className={`flex items-center w-full p-2 gap-3 transition duration-75 rounded-lg pl-6 group ${
                  isActive("/employees/all employees")
                    ? "bg-gray-100 text-blue-950"
                    : "text-white hover:bg-gray-100 hover:text-blue-950"
                }`}
                onClick={() =>
                  updateBreadcrumbs({
                    path: "/employees/all employees",
                    label: "Dipendenti",
                  })
                }
              >
                <FaUsers />
                <span>Dipendenti</span>
              </Link>
            </li>
            <li>
              <Link
                to="/clients"
                className={`flex items-center w-full p-2 gap-3 transition duration-75 rounded-lg pl-6 group ${
                  isActive("/clients")
                    ? "bg-gray-100 text-blue-950"
                    : "text-white hover:bg-gray-100 hover:text-blue-950"
                }`}
                onClick={() =>
                  updateBreadcrumbs({ path: "/clients", label: "Cliente" })
                }
              >
                <FaHandshake />
                <span>Clienti</span>
              </Link>
            </li>
            <li>
              <Link
                to="/suppliers"
                className={`flex items-center w-full p-2 gap-3 transition duration-75 rounded-lg pl-6 group ${
                  isActive("/suppliers")
                    ? "bg-gray-100 text-blue-950"
                    : "text-white hover:bg-gray-100 hover:text-blue-950"
                }`}
                onClick={() =>
                  updateBreadcrumbs({ path: "/suppliers", label: "Fornitori" })
                }
              >
                <FaIndustry />
                <span>Fornitori</span>
              </Link>
            </li>
          </ul>
        )}
      </li>
      <li>
        <Link
          to="/flotta"
          className={linkClass("/flotta")}
          onClick={() =>
            updateBreadcrumbs({ path: "/flotta", label: "Flotta" })
          }
        >
          <FaTruck size={18} />
          <span className="ms-3 whitespace-nowrap">Flotta</span>
        </Link>
      </li>
      <li>
        <Link
          to="/magazzino"
          className={linkClass("/magazzino")}
          onClick={() =>
            updateBreadcrumbs({ path: "/magazzino", label: "Magazzino" })
          }
        >
          <FaHome size={18} />
          <span className="ms-3 whitespace-nowrap">Magazzino</span>
        </Link>
      </li>
      <h4 className="text-white pt-4" style={styles.text}>
        Contabilità
      </h4>
      <li>
        <Link
          to="/prima-nota"
          className={linkClass("/prima-nota")}
          onClick={() =>
            updateBreadcrumbs({ path: "/prima-nota", label: "Prima Nota" })
          }
        >
          <FileText size={18} />
          <span className="ms-3 whitespace-nowrap">Prima Nota</span>
        </Link>
      </li>

      <li>
        <Link
          to="/movimenti"
          className={linkClass("/movimenti")}
          onClick={() =>
            updateBreadcrumbs({ path: "/movimenti", label: "Movimenti" })
          }
        >
          <FaCar size={18} />
          <span className="ms-3 whitespace-nowrap">Movimenti</span>
        </Link>
      </li>
      <li>
        <Link
          to="/payment-methods"
          className={linkClass("/payment-methods")}
          onClick={() =>
            updateBreadcrumbs({
              path: "/payment-methods",
              label: "Conti bancari",
            })
          }
        >
          <Landmark size={16} />
          <span className="ms-3 whitespace-nowrap">Conti bancari</span>
        </Link>
      </li>
      <li>
        <Link
          to="/tax"
          className={linkClass("/tax")}
          onClick={() => updateBreadcrumbs({ path: "/tax", label: "Tasse" })}
        >
          <FaCar size={18} />
          <span className="ms-3 whitespace-nowrap">Tasse</span>
        </Link>
      </li>
      <li>
        <Link
          to="/installment"
          className={linkClass("/installment")}
          onClick={() =>
            updateBreadcrumbs({ path: "/installment", label: "Rateizzi" })
          }
        >
          <FaFileInvoiceDollar size={18} />
          <span className="ms-3 whitespace-nowrap">Rateizzi</span>
        </Link>
      </li>
      <li>
        <Link
          to="/ddt"
          className={linkClass("/ddt")}
          onClick={() => updateBreadcrumbs({ path: "/ddt", label: "DDT" })}
        >
          <FaBox size={18} />
          <span className="ms-3 whitespace-nowrap">DDT</span>
        </Link>
      </li>
      <h4 className="text-white pt-4" style={styles.text}>
        Produzione
      </h4>
      <li>
        <Link
          to="/computi-metrici"
          className={linkClass("/computi-metrici")}
          onClick={() =>
            updateBreadcrumbs({
              path: "/computi-metrici",
              label: "Computi metrici",
            })
          }
        >
          <FaCalculator size={18} />
          <span className="ms-3 whitespace-nowrap">Computi Metrici</span>
        </Link>
      </li>
      <li>
        <Link
          to="/preventivi"
          className={linkClass("/preventivi")}
          onClick={() =>
            updateBreadcrumbs({ path: "/preventivi", label: "Preventivi" })
          }
        >
          <FaCalculator size={18} />
          <span className="ms-3 whitespace-nowrap">Preventivi</span>
        </Link>
      </li>
      <li>
        <Link
          to="/cantieri"
          className={linkClass("/cantieri")}
          onClick={() =>
            updateBreadcrumbs({ path: "/cantieri", label: "Cantieri" })
          }
        >
          <MdConstruction size={18} />
          <span className="ms-3 whitespace-nowrap">Cantieri</span>
        </Link>
      </li>
      <li>
        <Link
          to="/documenti"
          className={linkClass("/documenti")}
          onClick={() =>
            updateBreadcrumbs({ path: "/documenti", label: "Documenti" })
          }
        >
          <FileSignature size={18} />
          <span className="ms-3 whitespace-nowrap">Documenti</span>
        </Link>
      </li>
      <li>
        <Link
          to="/archive"
          className={linkClass("/archive")}
          onClick={() =>
            updateBreadcrumbs({ path: "/archive", label: "Archivio" })
          }
        >
          <Archive size={18} />
          <span className="ms-3 whitespace-nowrap">Archivio</span>
        </Link>
      </li>
      <h4 className="text-white pt-4" style={styles.text}>
        Comunicazione
      </h4>
      <li>
        <Link
          to="/chat"
          className={linkClass("/chat")}
          onClick={() => updateBreadcrumbs({ path: "/chat", label: "Chat" })}
        >
          <MdChat size={18} />
          <span className="ms-3 whitespace-nowrap">Chat</span>
        </Link>
      </li>
    </ul>
  </>
);

const NavBar = ({ updateBreadcrumbs }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [eventResults, setEventResults] = useState([]);
  const [documentResults, setDocumentResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));

  const isActive = (path) => location.pathname === path;

  const toggleDropdown = (menu, label) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
    if (!dropdownStates[menu] && updateBreadcrumbs) {
      updateBreadcrumbs({ path: "", label });
    }
  };

  const linkClass = (path) =>
    `flex items-center p-2 rounded-lg ${
      isActive(path)
        ? "bg-gray-100 text-blue-950"
        : "text-white hover:bg-gray-100 hover:text-blue-950"
    } transition-colors duration-200`;

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "k" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        setIsSearchOpen((prev) => !prev);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  const logoutHandle = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("breadcrumb");
    window.location.href = "/";
  };

  const getCurrentTitle = () => {
    if (location.pathname.startsWith("/agency")) {
      return "Azienda";
    }
    if (location.pathname.startsWith("/employees")) {
      return "Dipendenti";
    }
    if (location.pathname.startsWith("/formulari")) {
      return "Formulari";
    }
    if (location.pathname.startsWith("/registri")) {
      return "Registri";
    }
    if (location.pathname.startsWith("/cantieri")) {
      return "Cantieri";
    }
    if (location.pathname.startsWith("/computimetriciaddrec")) {
      return "Computi Metrici";
    }
    if (location.pathname.startsWith("/preventivi_edit")) {
      return "Modifica preventivo";
    }
    if (location.pathname.startsWith("/flotta_details")) {
      return "Flotta";
    }
    if (location.pathname.startsWith("/installment")) {
      return "Rateizzi";
    }
    if (location.pathname.startsWith("/suppliers")) {
      return "Fornitori";
    }
    if (location.pathname.startsWith("/ddt_edit")) {
      return "DDT";
    }
    if (location.pathname.startsWith("/documenti")) {
      return "Documenti";
    }
    if (location.pathname.startsWith("/clients")) {
      return "Clienti";
    }
    if (location.pathname.startsWith("/invitation-code")) {
      return "Codice Invito";
    }
    return routeTitles[location.pathname] || "";
  };

  const searchDocuments = async (query) => {
    try {
      const response = await fetchDocuments(user.companyId, 1, 10, query);
      if (response && response.documents) {
        return response.documents.map((doc) => ({
          ...doc,
          type: "document",
          label: doc.title,
          path: `/documents?id=${doc._id}`,
          category: "Documenti",
          isValid: new Date(doc.expireDate) > new Date(),
        }));
      }
      return [];
    } catch (error) {
      console.error("Error searching documents:", error);
      return [];
    }
  };

  const searchEvents = async (query) => {
    try {
      const response = await getEvents();
      if (response.status === 200) {
        const events = response.data.events || [];
        return events
          .filter((event) => {
            const searchTerm = query.toLowerCase();
            return (
              event.title?.toLowerCase().includes(searchTerm) ||
              event.description?.toLowerCase().includes(searchTerm)
            );
          })
          .map((event) => ({
            ...event,
            type: "event",
            label: event.title,
            path: `/calendar?event=${event._id}`,
            category: "Eventi",
            start: new Date(event.start),
            end: new Date(event.end),
          }));
      }
      return [];
    } catch (error) {
      console.error("Error searching events:", error);
      return [];
    }
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (query.length < 2) {
      setSearchResults([]);
      setEventResults([]);
      setDocumentResults([]);
      return;
    }

    setIsLoading(true);
    try {
      const [searchData, eventData, documentData] = await Promise.all([
        performSearch(query),
        searchEvents(query),
        searchDocuments(query),
      ]);

      setSearchResults(searchData?.results || []);
      setEventResults(eventData);
      setDocumentResults(documentData);
    } catch (error) {
      console.error("Search error:", error);
      setSearchResults([]);
      setEventResults([]);
      setDocumentResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = (item) => {
    setIsSearchOpen(false);
    navigate(item.path);
  };

  const formatEventDate = (date) => {
    return new Date(date).toLocaleDateString("it-IT", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const getIconForType = (type) => {
    switch (type) {
      case "calendar":
        return <Calendar className="mr-2 h-4 w-4" />;
      case "user":
        return <User className="mr-2 h-4 w-4" />;
      case "client":
        return <Users className="mr-2 h-4 w-4" />;
      case "supplier":
        return <Truck className="mr-2 h-4 w-4" />;
      case "construction":
        return <HardHat className="mr-2 h-4 w-4" />;
      case "document":
        return <FileText className="mr-2 h-4 w-4" />;
      case "warehouse":
        return <Warehouse className="mr-2 h-4 w-4" />;
      case "payment":
        return <CreditCard className="mr-2 h-4 w-4" />;
      case "message":
        return <MessageSquare className="mr-2 h-4 w-4" />;
      case "settings":
        return <Settings className="mr-2 h-4 w-4" />;
      default:
        return <FileText className="mr-2 h-4 w-4" />;
    }
  };

  return (
    <>
      <nav className="border-gray-100 lg:ml-[14rem] relative z-40 bg-white border-b">
        <div className="mx-auto max-sm:px-2">
          <div className="flex items-center justify-between h-12">
            <div className="flex items-center px-2">
              {/* Mobile Menu Sheet */}
              <Sheet>
                <SheetTrigger asChild>
                  <Menu className="h-6 w-6 lg:hidden cursor-pointer" />
                </SheetTrigger>
                <SheetContent side="left" className="w-64 p-0 bg-[#06052C]">
                  <div className="h-full px-3 py-4 overflow-y-auto">
                    <SidebarContent
                      isActive={isActive}
                      linkClass={linkClass}
                      dropdownStates={dropdownStates}
                      toggleDropdown={toggleDropdown}
                      updateBreadcrumbs={updateBreadcrumbs}
                      isMobile={true}
                    />
                  </div>
                </SheetContent>
              </Sheet>
              <h1 className="text-xl max-sm:ml-8 px-2 font-bold text-gray-700 dark:text-gray-300">
                {getCurrentTitle()}
              </h1>
            </div>

            <div className="flex-grow flex justify-center max-sm:hidden">
              <div className="w-full max-w-xl relative">
                <Input
                  type="text"
                  className="w-full pl-10 pr-16 py-2 bg-white hover:bg-gray-50 transition-colors cursor-pointer focus:ring-0 focus:ring-offset-0 max-sm:hidden"
                  placeholder="Cerca persone, eventi, lavori, documenti e tanto altro..."
                  onClick={() => setIsSearchOpen(true)}
                  readOnly
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 h-5 w-5 max-sm:hidden" />
              </div>
            </div>

            <div className="ml-4 flex items-center gap-x-4 md:ml-6">
              <button
                onClick={() => setIsSearchOpen(true)}
                className="hidden max-sm:flex items-center justify-center p-2 hover:bg-gray-100 rounded-full"
              >
                <Search className="cursor-pointer h-6 w-6 text-gray-600 hover:text-gray-800" />
              </button>
              <NotificationDropdown />
              <UserDropdown user={user} logoutHandle={logoutHandle} />
            </div>
          </div>
        </div>
      </nav>

      {isSearchOpen && (
        <div
          className="fixed inset-0 z-50 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4"
          onClick={() => setIsSearchOpen(false)}
        >
          <div
            className="bg-white dark:bg-gray-800 rounded-xl shadow-2xl w-full max-w-2xl overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <Command className="rounded-lg border shadow-md">
              <CommandInput
                placeholder="Cerca nell'applicazione..."
                value={searchQuery}
                onValueChange={handleSearch}
              />
              <CommandList>
                <CommandEmpty>Nessun risultato trovato.</CommandEmpty>

                <CommandGroup heading="Collegamenti rapidi">
                  {searchItems.map((item, index) => (
                    <CommandItem
                      key={index}
                      onSelect={() => handleSelect(item)}
                    >
                      {item.icon}
                      <span>{item.label}</span>
                      <CommandShortcut>{`⌘${index + 1}`}</CommandShortcut>
                    </CommandItem>
                  ))}
                </CommandGroup>

                {documentResults.length > 0 && (
                  <>
                    <CommandSeparator />
                    <CommandGroup heading="Documenti">
                      {documentResults.map((doc, index) => (
                        <CommandItem
                          key={`doc-${index}`}
                          onSelect={() => handleSelect(doc)}
                        >
                          <FileText className="mr-2 h-4 w-4" />
                          <div className="flex flex-col">
                            <span>{doc.title}</span>
                            <span className="text-sm text-gray-500">
                              Scadenza:{" "}
                              {new Date(doc.expireDate).toLocaleDateString()}{" "}
                              <Badge
                                variant={
                                  doc.isValid ? "success" : "destructive"
                                }
                              >
                                {doc.isValid ? "Valido" : "Scaduto"}
                              </Badge>
                            </span>
                          </div>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </>
                )}

                {eventResults.length > 0 && (
                  <>
                    <CommandSeparator />
                    <CommandGroup heading="Eventi">
                      {eventResults.map((event, index) => (
                        <CommandItem
                          key={`event-${index}`}
                          onSelect={() => handleSelect(event)}
                        >
                          <Calendar className="mr-2 h-4 w-4" />
                          <div className="flex flex-col">
                            <span>{event.title}</span>
                            <span className="text-sm text-gray-500">
                              {formatEventDate(event.start)}
                            </span>
                          </div>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </>
                )}

                <CommandSeparator />
                <CommandGroup heading="Risultati della ricerca">
                  {isLoading && <CommandItem>Caricamento...</CommandItem>}
                  {!isLoading &&
                    searchResults.map((item, index) => (
                      <CommandItem
                        key={index}
                        onSelect={() => handleSelect(item)}
                      >
                        {getIconForType(item.type)}
                        <span className="ml-2">{item.title || item.label}</span>
                        <span className="ml-auto text-sm text-gray-500">
                          {item.category || item.type}
                        </span>
                      </CommandItem>
                    ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </div>
        </div>
      )}
    </>
  );
};

export default NavBar;
