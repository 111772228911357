import React, { useState } from "react";
import { Plus, Filter, Eye } from "lucide-react";
import { Button } from "../../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "../../ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription
} from "../../ui/dialog";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Textarea } from "../../ui/textarea";
import SearchBar from "../sharedComponent/SearchBar";

function Registri() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("all");
  const [unitFilter, setUnitFilter] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [registers, setRegisters] = useState([
    {
      id: "REG001",
      description: "Registro Operazioni",
      activity: "Manutenzione",
      openingDate: "2024-01-15",
      status: "Attivo"
    }
  ]);

  // Mock data for units
  const units = [
    { id: "1", name: "Unità Locale 1" },
    { id: "2", name: "Unità Locale 2" },
    { id: "3", name: "Unità Locale 3" }
  ];

  const handleSearch = (term) => {
    setSearchTerm(term);
    // search logic here
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // form submission logic here
    setIsModalOpen(false);
  };

  return (
    <div className="p-6 space-y-6">
      <div className="flex flex-col gap-2 space-y-4 lg:space-y-0 lg:flex-row lg:justify-between lg:items-center">
        {/* Left side buttons */}
        <div className="flex items-center space-x-2">
          <Button
            onClick={() => setIsModalOpen(true)}
            className="flex items-center"
          >
            <Plus className="mr-2 h-4 w-4" />
            Nuovo Registro
          </Button>
        </div>

        {/* Right side filters and search */}
        <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2 sm:items-center">
          <Select value={statusFilter} onValueChange={setStatusFilter}>
            <SelectTrigger className="w-[140px]">
              <SelectValue placeholder="Stato" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Tutti</SelectItem>
              <SelectItem value="active">Attivi</SelectItem>
              <SelectItem value="inactive">Inattivi</SelectItem>
            </SelectContent>
          </Select>

          <Select value={unitFilter} onValueChange={setUnitFilter}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Unità locale" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Tutte le unità</SelectItem>
              {units.map((unit) => (
                <SelectItem key={unit.id} value={unit.id}>
                  {unit.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <div className="w-full sm:w-[300px]">
            <SearchBar onSearch={handleSearch} />
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Identificativo</TableHead>
              <TableHead>Descrizione</TableHead>
              <TableHead>Attività</TableHead>
              <TableHead>Data apertura</TableHead>
              <TableHead>Stato</TableHead>
              <TableHead className="text-center">Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {registers.map((register) => (
              <TableRow key={register.id}>
                <TableCell>{register.id}</TableCell>
                <TableCell>{register.description}</TableCell>
                <TableCell>{register.activity}</TableCell>
                <TableCell>
                  {new Date(register.openingDate).toLocaleDateString("it-IT")}
                </TableCell>
                <TableCell>
                  <span
                    className={`px-2 py-1 rounded-full text-xs ${
                      register.status === "Attivo"
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {register.status}
                  </span>
                </TableCell>
                <TableCell className="text-center">
                  <Button variant="ghost" size="sm">
                    <Eye className="h-4 w-4" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {/* New Register Modal */}
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Nuovo Registro</DialogTitle>
            <DialogDescription>
              Inserisci i dettagli del nuovo registro
            </DialogDescription>
          </DialogHeader>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="unit">Unità Locale</Label>
              <Select required>
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona unità locale" />
                </SelectTrigger>
                <SelectContent>
                  {units.map((unit) => (
                    <SelectItem key={unit.id} value={unit.id}>
                      {unit.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="description">Descrizione</Label>
              <Input id="description" required />
            </div>

            <div className="space-y-2">
              <Label htmlFor="activity">Attività</Label>
              <Textarea id="activity" required />
            </div>

            <div className="space-y-2">
              <Label htmlFor="openingDate">Data Apertura</Label>
              <Input type="date" id="openingDate" required />
            </div>

            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => setIsModalOpen(false)}
              >
                Annulla
              </Button>
              <Button type="submit">Salva</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Registri;
