import React, { createContext, useContext, useReducer } from "react";

const FormularioContext = createContext();

const initialState = {
  produttore: {
    trasbordoParziale: {
      isTrasbordoParziale: false,
      numeroFir: "",
      nazionalita: "",
      codiceFiscale: "",
      denominazione: "",
      causale: "",
    },
    anagraficaProduttore: {
      useCurrentUnit: false,
      isDetentore: false,
      nazionalita: "",
      codiceFiscale: "",
      denominazione: "",
      nazione: "",
      provincia: "",
      comune: "",
      indirizzo: "",
      civico: "",
      cap: "",
    },
    luogoProduzione: {
      isDiverso: false,
      nazione: "",
      provincia: "",
      comune: "",
      indirizzo: "",
      civico: "",
      cap: "",
    },
    autorizzazioni: {
      numeroAlbo: "",
      tipoAutorizzazione: "",
      numero: "",
    },
  },
  destinatario: {
    anagrafica: {
      useCurrentUnit: false,
      nazionalita: "",
      codiceFiscale: "",
      denominazione: "",
      nazione: "",
      provincia: "",
      comune: "",
      indirizzo: "",
      civico: "",
      cap: "",
    },
    destinazione: {
      tipo: "recupero",
      attivita: "",
    },
    autorizzazioni: {
      numeroAlbo: "",
      tipoAutorizzazione: "",
      numero: "",
    },
  },
  trasportatori: [
    {
      datiTrasportatore: {
        nazionalita: "",
        codiceFiscale: "",
        denominazione: "",
        tipoTrasporto: "",
        numeroIscrizione: "",
      },
    },
  ],
  intermediari: [
    {
      datiIntermediario: {
        nazionalita: "",
        codiceFiscale: "",
        denominazione: "",
        numeroIscrizione: "",
      },
    },
  ],
  rifiuto: {
    caratteristiche: {
      provenienza: "",
      codiceEer: "",
      destinazioneRifiuto: "",
      descrizione: "",
      statoFisico: "",
      caratteristichePericolo: [],
    },
    quantita: {
      verificatoPartenza: false,
      rinfusa: false,
      quantita: "",
      unitaMisura: "",
      numColli: "",
    },
    analisi: {
      tipo: "analisi", // 'analisi' o 'classificazione'
      numero: "",
      validaAl: "",
    },
    adr: {
      soggetto: false,
      numeroONU: "",
      classe: "",
      note: "",
    },
  },
  trasportoPartenza: {
    informazioniPartenza: {
      trasportatore: "",
      targaAutomezzo: "",
      targaRimorchio: "",
      percorso: "",
      cognomeConducente: "",
      nomeConducente: "",
      dataInizio: "",
      oraInizio: "",
    },
  },
  annotazioni: {
    note: "",
  },
};

function formularioReducer(state, action) {
  switch (action.type) {
    case "UPDATE_PRODUTTORE":
      return {
        ...state,
        produttore: { ...state.produttore, ...action.payload },
      };
    case "UPDATE_DESTINATARIO":
      return {
        ...state,
        destinatario: { ...state.destinatario, ...action.payload },
      };
    case "UPDATE_TRASPORTATORI":
      return { ...state, trasportatori: action.payload };
    case "UPDATE_INTERMEDIARI":
      return { ...state, intermediari: action.payload };
    case "UPDATE_RIFIUTO":
      return { ...state, rifiuto: { ...state.rifiuto, ...action.payload } };
    case "UPDATE_TRASPORTO_PARTENZA":
      return {
        ...state,
        trasportoPartenza: { ...state.trasportoPartenza, ...action.payload },
      };
    case "UPDATE_ANNOTAZIONI":
      return { ...state, annotazioni: action.payload };
    case "RESET_FORM":
      return initialState;
    default:
      return state;
  }
}

export function FormularioProvider({ children }) {
  const [state, dispatch] = useReducer(formularioReducer, initialState);

  const updateProduttore = (data) => {
    dispatch({ type: "UPDATE_PRODUTTORE", payload: data });
  };

  const updateDestinatario = (data) => {
    dispatch({ type: "UPDATE_DESTINATARIO", payload: data });
  };

  const updateTrasportatori = (data) => {
    dispatch({ type: "UPDATE_TRASPORTATORI", payload: data });
  };

  const updateIntermediari = (data) => {
    dispatch({ type: "UPDATE_INTERMEDIARI", payload: data });
  };

  const updateRifiuto = (data) => {
    dispatch({ type: "UPDATE_RIFIUTO", payload: data });
  };

  const updateTrasportoPartenza = (data) => {
    dispatch({ type: "UPDATE_TRASPORTO_PARTENZA", payload: data });
  };

  const updateAnnotazioni = (data) => {
    dispatch({ type: "UPDATE_ANNOTAZIONI", payload: data });
  };

  const resetForm = () => {
    dispatch({ type: "RESET_FORM" });
  };

  const value = {
    formularioData: state,
    updateProduttore,
    updateDestinatario,
    updateTrasportatori,
    updateIntermediari,
    updateRifiuto,
    updateTrasportoPartenza,
    updateAnnotazioni,
    resetForm,
  };

  return (
    <FormularioContext.Provider value={value}>
      {children}
    </FormularioContext.Provider>
  );
}

export const useFormulario = () => {
  const context = useContext(FormularioContext);
  if (!context) {
    throw new Error("useFormulario must be used within a FormularioProvider");
  }
  return context;
};
