import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Eye,
  Edit,
  Trash,
  Plus,
  FileDown,
  ChevronUp,
  ChevronDown,
  SortAsc,
  SortDesc,
  MoreHorizontal
} from "lucide-react";
import {
  fetchComputimetric,
  updateComputimetric,
  addComputimetric,
  deleteComputimetric,
  fetchClients,
  downloadComputimetricPDF
} from "../../../../apis/Computimetric";
import { fetchComputimetriciaddrec } from "../../../../apis/ComputimetricAddrecSumm";
import Pagination from "../../sharedComponent/Pgination";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../../../ui/tooltip";

import { Button } from "../../../ui/button";
import { Checkbox } from "../../../ui/checkbox";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "../../../ui/table";
import { Alert, AlertDescription } from "../../../ui/alert";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "../../../ui/dropdown-menu";

import EditModal from "./EditModal";
import AddModal from "./AddModal";
import SearchBar from "../../sharedComponent/SearchBar";
import HelpSheet from "../../sharedComponent/HelpSheet";
import { exportToPDF } from "../../../../config/helper";

// Create a React context for the enhanced table features
const EnhancedTableContext = React.createContext({
  sortConfig: { key: null, direction: null },
  requestSort: () => {},
  selectedItems: {},
  selectItem: () => {},
  selectAll: false,
  handleSelectAll: () => {},
  items: []
});

// Wrapper component for enhanced table functionality
const EnhancedTable = ({
  children,
  items = [],
  sortConfig = { key: null, direction: null },
  onSortChange,
  selectedItems = {},
  onSelectChange,
  selectAll = false,
  onSelectAllChange,
  ...props
}) => {
  const [internalSortConfig, setInternalSortConfig] = useState({
    key: null,
    direction: null
  });
  const [internalSelectedItems, setInternalSelectedItems] = useState({});
  const [internalSelectAll, setInternalSelectAll] = useState(false);

  const actualSortConfig = sortConfig || internalSortConfig;
  const actualSelectedItems = selectedItems || internalSelectedItems;
  const actualSelectAll = selectAll || internalSelectAll;

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      actualSortConfig.key === key &&
      actualSortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }

    const newSortConfig = { key, direction };

    if (onSortChange) {
      onSortChange(newSortConfig);
    } else {
      setInternalSortConfig(newSortConfig);
    }
  };

  const selectItem = (id, checked) => {
    const newSelectedItems = { ...actualSelectedItems, [id]: checked };

    if (onSelectChange) {
      onSelectChange(newSelectedItems);
    } else {
      setInternalSelectedItems(newSelectedItems);
    }

    const allSelected =
      items.length > 0 &&
      items.every((item) => newSelectedItems[item.id || item._id]);

    if (onSelectAllChange) {
      onSelectAllChange(allSelected);
    } else {
      setInternalSelectAll(allSelected);
    }
  };

  const handleSelectAll = (checked) => {
    const newSelectedItems = {};

    if (checked) {
      items.forEach((item) => {
        const itemId = item.id || item._id;
        if (itemId) newSelectedItems[itemId] = true;
      });
    }

    if (onSelectChange) {
      onSelectChange(newSelectedItems);
    } else {
      setInternalSelectedItems(newSelectedItems);
    }

    if (onSelectAllChange) {
      onSelectAllChange(checked);
    } else {
      setInternalSelectAll(checked);
    }
  };

  return (
    <EnhancedTableContext.Provider
      value={{
        sortConfig: actualSortConfig,
        requestSort,
        selectedItems: actualSelectedItems,
        selectItem,
        selectAll: actualSelectAll,
        handleSelectAll,
        items
      }}
    >
      <Table {...props}>{children}</Table>
    </EnhancedTableContext.Provider>
  );
};

// Hook to use the enhanced table context
const useEnhancedTable = () => React.useContext(EnhancedTableContext);

// Enhanced TableHead component with sorting capability
const SortableTableHead = ({ children, sortKey, ...props }) => {
  const { sortConfig, requestSort } = useEnhancedTable();

  const getSortIcon = () => {
    if (!sortKey) return null;

    if (sortConfig.key !== sortKey) {
      return (
        <div className="ml-2 inline-block">
          <ChevronUp className="h-3 w-3 opacity-50" />
          <ChevronDown className="h-3 w-3 -mt-1 opacity-50" />
        </div>
      );
    }
    return sortConfig.direction === "ascending" ? (
      <SortAsc className="ml-2 h-4 w-4 inline-block" />
    ) : (
      <SortDesc className="ml-2 h-4 w-4 inline-block" />
    );
  };

  return (
    <TableHead
      className={sortKey ? "cursor-pointer" : ""}
      onClick={() => sortKey && requestSort(sortKey)}
      {...props}
    >
      {children}
      {getSortIcon()}
    </TableHead>
  );
};

// Select All checkbox for table header
const SelectAllTableHead = (props) => {
  const { selectAll, handleSelectAll, items } = useEnhancedTable();

  return (
    <TableHead className="w-12" {...props}>
      {items.length > 0 && (
        <Checkbox
          checked={selectAll}
          onCheckedChange={handleSelectAll}
          aria-label="Select all"
        />
      )}
    </TableHead>
  );
};

// Checkbox cell for row selection
const SelectionTableCell = ({ item, ...props }) => {
  const { selectedItems, selectItem } = useEnhancedTable();
  const itemId = item?.id || item?._id;

  return (
    <TableCell {...props}>
      {itemId && (
        <Checkbox
          checked={!!selectedItems[itemId]}
          onCheckedChange={(checked) => selectItem(itemId, checked)}
          aria-label={`Select row ${itemId}`}
        />
      )}
    </TableCell>
  );
};

// Dropdown menu for actions
const ActionsTableCell = ({ children, ...props }) => {
  return (
    <TableCell {...props}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm">
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">{children}</DropdownMenuContent>
      </DropdownMenu>
    </TableCell>
  );
};

const TableComponent = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [computiTotals, setComputiTotals] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getItems = async () => {
    try {
      const response = await fetchComputimetric(companyId, currentPage, limit);
      const limitedItems = response.data.computimetrics.slice(0, limit);

      // Convert array to object with ids as keys
      const totalsObject = limitedItems.reduce((obj, item) => {
        obj[item._id] = item.importo_totale ?? 0;
        return obj;
      }, {});

      //console.log("totalsObject", totalsObject);

      setComputiTotals(totalsObject);
      setData(limitedItems);
      setFilteredData(limitedItems);
      setDataIsLoaded(true);
      setTotalPage(Math.ceil(response.data.totalItems / limit));
      setCurrentPage(response.data?.currentPage);
    } catch (error) {
      console.error("Errore nel recupero dei dati!", error);
      setAlertMessage("Errore nel recupero dei dati. Riprova più tardi.");
    }
  };

  useEffect(() => {
    getItems();
  }, [currentPage, limit]);

  // Apply sorting when sortConfig changes
  useEffect(() => {
    if (sortConfig.key && filteredData.length > 0) {
      const sortedData = [...filteredData].sort((a, b) => {
        let aValue, bValue;

        // Handle nested properties and special cases
        if (sortConfig.key === "importo") {
          aValue = computiTotals[a._id] || 0;
          bValue = computiTotals[b._id] || 0;
        } else if (sortConfig.key === "data") {
          aValue = new Date(a.data_computi).getTime();
          bValue = new Date(b.data_computi).getTime();
        } else if (sortConfig.key === "cliente") {
          aValue = a.clientName || "";
          bValue = b.clientName || "";
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }

        // Handle string comparison
        if (typeof aValue === "string") {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });

      setFilteredData(sortedData);
    }
  }, [sortConfig, computiTotals]);

  const handleSearch = (searchTerm) => {
    if (searchTerm.length >= 3) {
      const filtered = data.filter(
        (item) =>
          item.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.clientName?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };

  const handleAddClick = () => {
    setIsAddModalOpen(true);
  };

  const handleAdd = async (newData) => {
    try {
      await addComputimetric(newData);
      setIsAddModalOpen(false);
      getItems();
      setAlertMessage("Elemento aggiunto con successo.");
    } catch (error) {
      console.error("Errore nell'aggiunta dell'elemento:", error);
      setAlertMessage("Errore nell'aggiunta dell'elemento. Riprova.");
    }
  };

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const handleUpdate = async (updatedData) => {
    try {
      if (!updatedData._id) {
        console.error("ID mancante nei dati aggiornati");
        setAlertMessage("Errore: ID mancante. Impossibile aggiornare.");
        return;
      }

      await updateComputimetric(updatedData._id, updatedData);
      setIsEditModalOpen(false);
      getItems();
      setAlertMessage("Elemento aggiornato con successo.");
    } catch (error) {
      console.error("Errore nell'aggiornamento dell'elemento:", error);
      setAlertMessage("Errore nell'aggiornamento dell'elemento. Riprova.");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Sei sicuro di voler eliminare questo elemento?")) {
      try {
        await deleteComputimetric(id);
        getItems();
        setAlertMessage("Elemento eliminato con successo.");
      } catch (error) {
        console.error("Errore nella cancellazione dell'elemento!", error);
        setAlertMessage("Errore nella cancellazione dell'elemento. Riprova.");
      }
    }
  };

  const handleExport = async (id) => {
    try {
      const formattedData = filteredData.map((item, index) => ({
        nu: (index + 1).toString(),
        numero: item.numero || "-",
        data: new Date(item.data_computi).toLocaleDateString(),
        cliente: item.clientName || "-",
        descrizione: item.descrizione_lavori || "-",
        importo: `€${item.importo_totale || "0.00"}`
      }));

      const columns = [
        { header: "N°", key: "nu" },
        { header: "Numero", key: "numero" },
        { header: "Data", key: "data" },
        { header: "Cliente", key: "cliente" },
        { header: "Descrizione", key: "descrizione" },
        { header: "Importo", key: "importo" }
      ];

      const stats = [
        { label: "Totale Computi", value: filteredData.length },
        { label: "Data", value: new Date().toLocaleDateString() }
      ];

      exportToPDF(formattedData, columns, "Lista Computi Metrici", stats);
      setAlertMessage("PDF esportato con successo.");
    } catch (error) {
      console.error("Errore nel download del PDF:", error);
      setAlertMessage("Errore nel download del PDF. Riprova.");
    }
  };

  return (
    <div className="p-6 space-y-6">
      {alertMessage && (
        <Alert variant="info" className="mb-4">
          <AlertDescription>{alertMessage}</AlertDescription>
        </Alert>
      )}
      <div className="flex justify-between items-center">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button onClick={handleAddClick}>Nuovo</Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Apri</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <div className="flex items-center space-x-2">
          <SearchBar
            onSearch={handleSearch}
            className="md:w-1/6 flex justify-center items-center"
          />
          <HelpSheet route="/computi-metrici" />
        </div>
      </div>

      <div className="rounded-lg border">
        <EnhancedTable
          items={filteredData}
          sortConfig={sortConfig}
          onSortChange={setSortConfig}
          selectedItems={selectedItems}
          onSelectChange={setSelectedItems}
          selectAll={selectAll}
          onSelectAllChange={setSelectAll}
        >
          <TableHeader>
            <TableRow>
              <SelectAllTableHead />
              <SortableTableHead>Numero</SortableTableHead>
              <SortableTableHead sortKey="data">Data</SortableTableHead>
              <SortableTableHead sortKey="cliente">Cliente</SortableTableHead>
              <SortableTableHead sortKey="descrizione_lavori">
                Descrizione
              </SortableTableHead>
              <SortableTableHead sortKey="importo">Importo</SortableTableHead>
              <TableHead>Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <TableRow key={item._id}>
                  <SelectionTableCell item={item} />
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {new Date(item.data_computi)
                      .toLocaleDateString("it-IT", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric"
                      })
                      .replace(/\//g, "-")}
                  </TableCell>
                  <TableCell>{item.clientName || ""}</TableCell>
                  <TableCell>{item.descrizione_lavori}</TableCell>
                  <TableCell>
                    {computiTotals[item._id]
                      ? `€ ${parseFloat(computiTotals[item._id]).toFixed(2)}`
                      : "€ 0.00"}
                  </TableCell>
                  <ActionsTableCell>
                    <DropdownMenuItem asChild>
                      <Link
                        to={`/computimetriciaddrec/${item._id}`}
                        className="flex items-center"
                      >
                        <Eye className="h-4 w-4 mr-2" /> Apri
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => handleEditClick(item)}
                      className="flex items-center"
                    >
                      <Edit className="h-4 w-4 mr-2" /> Modifica
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => handleDelete(item._id)}
                      className="flex items-center"
                    >
                      <Trash className="h-4 w-4 text-red-500 mr-2" /> Elimina
                    </DropdownMenuItem>
                    {/* <DropdownMenuItem onClick={() => handleExport(item._id)} className="flex items-center">
                      <FileDown className="h-4 w-4 mr-2" /> Scarica
                    </DropdownMenuItem> */}
                  </ActionsTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} className="text-center py-4">
                  Nessun dato
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </EnhancedTable>
      </div>

      {filteredData.length > 0 && (
        <Pagination
          totalPages={totalPages}
          totalItems={filteredData.length}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      <AddModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAdd={handleAdd}
        fetchClients={fetchClients}
        companyId={companyId}
      />
      <EditModal
        open={isEditModalOpen}
        item={selectedItem}
        onClose={() => setIsEditModalOpen(false)}
        onUpdate={handleUpdate}
        fetchClients={fetchClients}
        companyId={companyId}
      />
    </div>
  );
};

export default TableComponent;
