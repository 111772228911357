// src/contexts/PermissionsContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const PermissionsContext = createContext();

// Define available routes and their display names
export const AVAILABLE_ROUTES = {
  dashboard: {
    name: 'Dashboard',
    path: '/dashboard'
  },
  calendar: {
    name: 'Calendar',
    path: '/calendar'
  },
  agency: {
    name: 'Agency',
    path: '/agency'
  },
  employees: {
    name: 'Employees',
    path: '/employees'
  },
  clients: {
    name: 'Clients',
    path: '/clients'
  },
  suppliers: {
    name: 'Suppliers',
    path: '/suppliers'
  },
  archive: {
    name: 'Archive',
    path: '/archive'
  },
  'computi-metrici': {
    name: 'Computi Metrici',
    path: '/computi-metrici'
  },
  flotta: {
    name: 'Flotta',
    path: '/flotta'
  },
  profilio: {
    name: 'Profile',
    path: '/profilio'
  },
  magazzino: {
    name: 'Magazzino',
    path: '/magazzino'
  },
  movimenti: {
    name: 'Movimenti',
    path: '/movimenti'
  },
  chat: {
    name: 'Chat',
    path: '/chat'
  },
  assistenza: {
    name: 'Assistenza',
    path: '/assistenza'
  },
  impostazioni: {
    name: 'Settings',
    path: '/impostazioni'
  },
  ddt: {
    name: 'DDT',
    path: '/ddt'
  },
  preventivi: {
    name: 'Preventivi',
    path: '/preventivi'
  },
  cantieri: {
    name: 'Cantieri',
    path: '/cantieri'
  },
  prezzari: {
    name: 'Prezzari',
    path: '/prezzari'
  }
};

// Group routes by category
export const ROUTE_CATEGORIES = {
  'Core Features': ['dashboard', 'calendar', 'chat'],
  'Management': ['agency', 'employees', 'clients', 'suppliers'],
  'Operations': ['computi-metrici', 'flotta', 'magazzino', 'movimenti'],
  'Documents': ['ddt', 'preventivi', 'archive'],
  'Projects': ['cantieri', 'prezzari'],
  'System': ['profilio', 'assistenza', 'impostazioni']
};

export function PermissionsProvider({ children }) {
  const [permissions, setPermissions] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    setIsAdmin(user.role === 'admin');
    setPermissions(user.permissions || {});
  }, []);

  const checkPermission = (route) => {
    if (isAdmin) return true;
    return permissions[route] || false;
  };

  const handleRouteAccess = (route) => {
    if (!checkPermission(route)) {
      // toast.error(`You don't have permission to access ${AVAILABLE_ROUTES[route]?.name || route}`);
      navigate('/dashboard');
      return false;
    }
    return true;
  };

  return (
    <PermissionsContext.Provider value={{ permissions, checkPermission, handleRouteAccess, isAdmin }}>
      {children}
    </PermissionsContext.Provider>
  );
}

export function usePermissions() {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }
  return context;
}