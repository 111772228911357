import { createJsonTypeInstance } from "./index";
const axios = createJsonTypeInstance();

export const fetchBankAccounts = async (userId) => {
  try {
    const response = await axios.get(
      `/account/gocardless/accounts?userId=${userId}`
    );
    return response;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: "Network error occurred" };
  }
};

export const createAggreement = async (bankId, sessionToken) => {
  try {
    const response = await axios.post(`/account/gocardless/agreements`, {
      // institutionId: "SANDBOXFINANCE_SFIN0000",
      institutionId: bankId,
      sessionToken,
    });
    return response;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: "Network error occurred" };
  }
};

export const createRequistition = async (
  bankId,
  sessionToken,
  agreementId,
  userId,
  bankName
) => {
  try {
    const response = await axios.post(`/account/gocardless/bank-connection`, {
      // institutionId: "SANDBOXFINANCE_SFIN0000",
      agreementId: agreementId,
      userId,
      institutionName: bankName,
      institutionId: bankId,
      sessionToken: sessionToken,
      redirectUrl: `${window.location.origin}/bank-callBack`,
    });
    return response;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: "Network error occurred" };
  }
};

export const fetchCountryBanks = async (country) => {
  try {
    const response = await axios.get(`/account/gocardless/banks`, {
      params: { country },
    });
    return response;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: "Network error occurred" };
  }
};

export const disconnectBankAccount = async (requisitionId, userId) => {
  try {
    const response = await axios.post(`/account/gocardless/disconnect`, {
      userId: userId,
      requisitionId: requisitionId,
    });
    return response;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: "Network error occurred" };
  }
};

export const checkRequisitionStatus = async (requisitionId) => {
  try {
    const response = await axios.get(
      `/account/gocardless/requisition-status?requisitionId=${requisitionId}`
    );
    return response;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: "Network error occurred" };
  }
};

// export const fetchMandateId = async (customerAccountId, requisitionId) => {
//   try {
//     const response = await axios.get(
//       `/account/gocardless/mandate?customerAccountId=${customerAccountId}&requisitionId=${requisitionId}`
//     );
//     return response;
//   } catch (error) {
//     if (error.response?.data) {
//       throw error.response.data;
//     }
//     throw { message: "Network error occurred" };
//   }
// };

// export const createPayment = async (data) => {
//   try {
//     const response = await axios.post(`/account/gocardless/payments`, {
//       data,
//     });
//     return response;
//   } catch (error) {
//     if (error.response?.data) {
//       throw error.response.data;
//     }
//     throw { message: "Network error occurred" };
//   }
// };

export const getAllBalances = async (accountIds) => {
  try {
    const response = await axios.post(`/account/gocardless/balances`, {
      accountIds,
    });
    return response;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: "Network error occurred" };
  }
};
