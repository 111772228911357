import { createJsonTypeInstance } from "./index";

// Types for better type checking
const SubscriptionStatus = {
  ACTIVE: "active",
  TRIALING: "trialing",
  CANCELED: "canceled",
  CANCELED_ACTIVE: "canceled_active",
  PAST_DUE: "past_due",
  INCOMPLETE: "incomplete",
  INCOMPLETE_EXPIRED: "incomplete_expired",
  UNPAID: "unpaid"
};

// Error handling utility
class SubscriptionError extends Error {
  constructor(message, code, originalError = null) {
    super(message);
    this.name = "SubscriptionError";
    this.code = code;
    this.originalError = originalError;
  }
}

// API response validator
const validateSubscriptionResponse = (data) => {
  if (!data)
    throw new SubscriptionError("Invalid response data", "INVALID_RESPONSE");

  if (data.subscription) {
    const { subscription } = data;
    if (!subscription.planType)
      throw new SubscriptionError("Invalid plan type", "INVALID_PLAN");
    if (!subscription.status)
      throw new SubscriptionError("Invalid status", "INVALID_STATUS");
    if (!subscription.currentPeriodEnd)
      throw new SubscriptionError("Invalid period end", "INVALID_PERIOD");
  }

  return data;
};

const handleApiError = (error, customMessage) => {
  console.error(customMessage, error);

  if (error instanceof SubscriptionError) throw error;

  if (error.response) {
    throw new SubscriptionError(
      error.response.data?.message || customMessage,
      error.response.status,
      error
    );
  }

  throw new SubscriptionError(customMessage, "UNKNOWN_ERROR", error);
};

// Retry mechanism
const retryOperation = async (operation, maxRetries = 3, delay = 1000) => {
  let lastError;

  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      return await operation();
    } catch (error) {
      lastError = error;
      if (attempt === maxRetries) break;

      // Don't retry on certain errors
      if (error.response?.status === 404 || error.response?.status === 401) {
        throw error;
      }

      await new Promise((resolve) => setTimeout(resolve, delay * attempt));
    }
  }

  throw lastError;
};

// Create axios instance with proper interceptors
const createSubscriptionAxios = () => {
  const axiosInstance = createJsonTypeInstance();

  axiosInstance.interceptors.request.use(
    (config) => {
      // Ensure we have required headers
      if (!config.headers.Authorization) {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => validateSubscriptionResponse(response.data),
    (error) => Promise.reject(error)
  );

  return axiosInstance;
};

// Enhanced API functions
export const fetchCurrentSubscription = async () => {
  return retryOperation(async () => {
    try {
      const axiosInstance = createSubscriptionAxios();
      const response = await axiosInstance.get("/subscription/current");
      //console.log('API Response:', response);
      return response.subscription;
    } catch (error) {
      handleApiError(error, "Error fetching current subscription");
    }
  });
};

export const cancelSubscription = async () => {
  try {
    const axiosInstance = createSubscriptionAxios();
    const response = await axiosInstance.post("/subscription/cancel");

    // Validate cancellation response
    if (!response.subscription || !response.message) {
      throw new SubscriptionError(
        "Invalid cancellation response",
        "INVALID_RESPONSE"
      );
    }

    // Ensure the status is properly updated
    if (
      response.subscription.status !== SubscriptionStatus.CANCELED &&
      response.subscription.status !== SubscriptionStatus.CANCELED_ACTIVE
    ) {
      throw new SubscriptionError(
        "Subscription status not properly updated",
        "INVALID_STATUS_UPDATE"
      );
    }

    return response;
  } catch (error) {
    handleApiError(error, "Error cancelling subscription");
  }
};

export const initiateStripeCheckout = async (priceId, options = {}) => {
  if (!priceId)
    throw new SubscriptionError("Price ID is required", "MISSING_PRICE_ID");

  try {
    const axiosInstance = createSubscriptionAxios();
    const user = localStorage.getItem("user");
    const username = user ? JSON.parse(user).username : null;

    if (!username) {
      throw new SubscriptionError(
        "User information not found",
        "USER_NOT_FOUND"
      );
    }

    const response = await axiosInstance.post("/subscription/checkout", {
      priceId,
      username,
      reactivate: options.reactivate || false,
      source: window.location.pathname
    });

    if (!response.checkoutUrl) {
      throw new SubscriptionError(
        "Checkout URL not received",
        "INVALID_CHECKOUT_URL"
      );
    }

    return response;
  } catch (error) {
    handleApiError(error, "Error initiating Stripe checkout");
  }
};

export const changePlan = async (newPriceId) => {
  if (!newPriceId)
    throw new SubscriptionError("New price ID is required", "MISSING_PRICE_ID");

  return retryOperation(async () => {
    try {
      const axiosInstance = createSubscriptionAxios();
      const response = await axiosInstance.post("/subscription/change-plan", {
        newPriceId
      });

      // Validate plan change response
      if (!response.subscription || !response.message) {
        throw new SubscriptionError(
          "Invalid plan change response",
          "INVALID_RESPONSE"
        );
      }

      // Ensure the subscription was properly updated
      if (!response.subscription.planType || !response.subscription.status) {
        throw new SubscriptionError(
          "Subscription not properly updated",
          "INVALID_UPDATE"
        );
      }

      return response;
    } catch (error) {
      handleApiError(error, "Error changing subscription plan");
    }
  });
};

export const fetchPlans = async () => {
  return retryOperation(async () => {
    try {
      const axiosInstance = createSubscriptionAxios();
      const response = await axiosInstance.get("/subscription/plans");

      if (!Array.isArray(response)) {
        throw new SubscriptionError(
          "Invalid plans data received",
          "INVALID_PLANS_DATA"
        );
      }

      return response;
    } catch (error) {
      handleApiError(error, "Error fetching plans");
    }
  });
};

export const initiateFreeTrial = async (planId, username) => {
  if (!planId || !username) {
    throw new SubscriptionError(
      "Plan ID and username are required",
      "MISSING_REQUIRED_FIELDS"
    );
  }

  try {
    const axiosInstance = createSubscriptionAxios();
    const response = await axiosInstance.post("/subscription/free-trial", {
      planId,
      username
    });

    // Validate trial activation response
    if (!response.success || !response.user || !response.user.subscription) {
      throw new SubscriptionError(
        "Invalid trial activation response",
        "INVALID_TRIAL_ACTIVATION"
      );
    }

    return response;
  } catch (error) {
    handleApiError(error, "Error initiating free trial");
  }
};

export const resetSubscription = async () => {
  try {
    const axiosInstance = createSubscriptionAxios();
    const response = await axiosInstance.post("/subscription/reset");

    if (!response.message) {
      throw new SubscriptionError(
        "Invalid reset response",
        "INVALID_RESET_RESPONSE"
      );
    }

    return response;
  } catch (error) {
    handleApiError(error, "Error resetting subscription");
  }
};

// Utility function to check subscription status
export const isSubscriptionActive = (subscription) => {
  if (!subscription) return false;

  const now = new Date();
  const endDate = new Date(subscription.currentPeriodEnd);

  return (
    (subscription.status === SubscriptionStatus.ACTIVE ||
      subscription.status === SubscriptionStatus.TRIALING ||
      subscription.status === SubscriptionStatus.CANCELED_ACTIVE) &&
    endDate > now
  );
};
