import { createJsonTypeInstance } from "./index";
const axios = createJsonTypeInstance();

export const addSupplier = async (data) => {
  try {
    const response = await axios.post("/Supplier", data);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const fetchSuppliers = async (companyId) => {
  try {
    const response = await axios.get(`/Supplier/all-Suppliers/${companyId}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const importSupplierList = async (data) => {
  try {
    const response = await axios.post("/Supplier/import-supplier", data);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const getSingleSupplier = async (id) => {
  try {
    const response = await axios.get(`/Supplier/${id}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const updateSupplier = async (id, updateData) => {
  try {
    const response = await axios.put(`/Supplier/${id}`, updateData);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const deleteSupplier = async (id) => {
  try {
    const response = await axios.delete(`/Supplier/${id}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const addAccountingMovement = async (supplierId, movementData) => {
  try {
    const response = await axios.post(
      `/Supplier/${supplierId}/accounting-movements`,
      movementData
    );
    return response;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};
