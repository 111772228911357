import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs, TabsList, TabsTrigger } from "../../../ui/tabs";
import { SETTINGS_TABS } from './settingsConstants';

const SettingsTabs = ({ defaultTab, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(defaultTab || SETTINGS_TABS[0].route);

  useEffect(() => {
    const currentTab = SETTINGS_TABS.find(tab => location.pathname.startsWith(tab.route));
    if (currentTab) {
      setActiveTab(currentTab.route);
    }
  }, [location]);

  const handleTabChange = (value) => {
    navigate(value);
  };

  return (
    <div className="mx-auto space-y-4 p-6">
      <Tabs
        value={activeTab}
        onValueChange={handleTabChange}
        className="w-full"
      >
        <div className="border-b border-gray-200">
          <TabsList className="flex justify-start h-auto p-0 bg-transparent space-x-8">
            {SETTINGS_TABS.map((tab) => (
              <TabsTrigger
                key={tab.name}
                value={tab.route}
                className="py-4 px-1 text-sm font-medium text-gray-500 hover:text-gray-700 relative data-[state=active]:text-slate-600 data-[state=active]:font-medium bg-transparent before:absolute before:h-0.5 before:w-full before:bg-slate-700 before:bottom-0 before:left-0 before:scale-x-0 data-[state=active]:before:scale-x-100 before:transition-transform"
              >
                {tab.name}
              </TabsTrigger>
            ))}
          </TabsList>
        </div>
      </Tabs>
      
      {children}
    </div>
  );
};

export default SettingsTabs;