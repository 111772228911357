import React, { useState, useEffect } from "react";
import { useFormulario } from "./context/FormularioContext";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import { Button } from "../../../ui/button";
import { Truck, Plus, Trash2 } from "lucide-react";

const TrasportatoreSection = ({
  number,
  onDelete,
  showDelete,
  isRemoving,
  isOnlyOne,
  data,
  onChange,
}) => {
  const handleChange = (field, value) => {
    onChange({
      ...data,
      datiTrasportatore: {
        ...data.datiTrasportatore,
        [field]: value,
      },
    });
  };

  return (
    <Card
      className={`mb-4 relative transition-all duration-200 ${
        isRemoving ? "animate-fade-out" : "animate-fade-in"
      }`}
    >
      {showDelete && (
        <button
          onClick={onDelete}
          className="absolute top-4 right-4 p-2 text-red-500 hover:text-red-700 transition-colors"
          aria-label="Elimina trasportatore"
        >
          <Trash2 className="h-5 w-5" />
        </button>
      )}
      <CardHeader>
        <CardTitle>Trasportatore{!isOnlyOne && ` ${number}`}</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {/* First row - Transport Type */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="space-y-2">
            <Label htmlFor={`tipoTrasporto-${number}`}>Tipo trasporto</Label>
            <Select
              value={data.datiTrasportatore.tipoTrasporto}
              onValueChange={(value) => handleChange("tipoTrasporto", value)}
            >
              <SelectTrigger className="w-full">
                <Truck className="mr-2 h-4 w-4" />
                <SelectValue placeholder="Seleziona tipo trasporto" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="stradale">Stradale</SelectItem>
                <SelectItem value="ferroviario">Ferroviario</SelectItem>
                <SelectItem value="marittimo">Marittimo</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        {/* Second row - Company Details */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="space-y-2">
            <Label htmlFor={`nazionalita-${number}`}>Nazionalità</Label>
            <Select
              value={data.datiTrasportatore.nazionalita}
              onValueChange={(value) => handleChange("nazionalita", value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Seleziona nazionalità" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="IT">Italia</SelectItem>
                <SelectItem value="FR">Francia</SelectItem>
                <SelectItem value="DE">Germania</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <Label htmlFor={`codiceFiscale-${number}`}>Codice Fiscale</Label>
            <Input
              id={`codiceFiscale-${number}`}
              value={data.datiTrasportatore.codiceFiscale || ""}
              onChange={(e) => handleChange("codiceFiscale", e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor={`denominazione-${number}`}>Denominazione</Label>
            <Input
              id={`denominazione-${number}`}
              value={data.datiTrasportatore.denominazione || ""}
              onChange={(e) => handleChange("denominazione", e.target.value)}
            />
          </div>
        </div>

        {/* Third row - Registration Number */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="space-y-2">
            <Label htmlFor={`numeroIscrizione-${number}`}>
              Numero iscrizione all'albo
            </Label>
            <Input
              id={`numeroIscrizione-${number}`}
              value={data.datiTrasportatore.numeroIscrizione || ""}
              onChange={(e) => handleChange("numeroIscrizione", e.target.value)}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const Trasportatori = () => {
  const { updateTrasportatori, formularioData } = useFormulario();
  const [trasportatori, setTrasportatori] = useState(
    formularioData.trasportatori
  );
  const [removingItems, setRemovingItems] = useState(new Set());

  useEffect(() => {
    updateTrasportatori(trasportatori);
  }, [trasportatori, updateTrasportatori]);

  const handleAddTrasportatore = () => {
    const newId = Math.max(...trasportatori.map((t) => t.id || 0)) + 1;
    setTrasportatori([
      ...trasportatori,
      {
        id: newId,
        datiTrasportatore: {
          nazionalita: "",
          codiceFiscale: "",
          denominazione: "",
          tipoTrasporto: "",
          numeroIscrizione: "",
        },
      },
    ]);
  };

  const handleDeleteTrasportatore = (idToDelete) => {
    setRemovingItems((prev) => new Set([...prev, idToDelete]));

    setTimeout(() => {
      setTrasportatori((prev) => prev.filter((t) => t.id !== idToDelete));
      setRemovingItems((prev) => {
        const newSet = new Set(prev);
        newSet.delete(idToDelete);
        return newSet;
      });
    }, 200); // Animation duration
  };

  const handleTrasportatoreChange = (index, newData) => {
    const newTrasportatori = [...trasportatori];
    newTrasportatori[index] = { ...newTrasportatori[index], ...newData };
    setTrasportatori(newTrasportatori);
  };

  return (
    <div className="space-y-4">
      {trasportatori.map((trasportatore, index) => (
        <div key={trasportatore.id || index}>
          <TrasportatoreSection
            number={index + 1}
            data={trasportatore}
            onChange={(newData) => handleTrasportatoreChange(index, newData)}
            showDelete={trasportatori.length > 1}
            onDelete={() => handleDeleteTrasportatore(trasportatore.id)}
            isRemoving={removingItems.has(trasportatore.id)}
            isOnlyOne={trasportatori.length === 1}
          />
        </div>
      ))}

      <Button
        onClick={handleAddTrasportatore}
        variant="outline"
        className="w-fit"
      >
        <Plus className="mr-2 h-4 w-4" />
        Aggiungi trasportatore
      </Button>
    </div>
  );
};

export default Trasportatori;
