import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Edit,
  Trash2,
  Eye,
  UsersRound,
  UserPlus,
  Check,
  MoreHorizontal
} from "lucide-react";
import {
  deleteEmployee,
  importEmployeesList
} from "../../../../../apis/Employees";
import { exportToExcel, exportToPDF } from "../../../../../config/helper";
import { QUALIFICATION_LEVELS } from "../../../../../constants/formFields"; // Add this import
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../../../../ui/tooltip";
import { Button } from "../../../../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "../../../../ui/dropdown-menu";
import {
  EnhancedTable,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell,
  useTableContext
} from "../../../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "../../../../ui/dialog";
import { ScrollArea } from "../../../../ui/scroll-area";
import SearchBar from "../../../sharedComponent/SearchBar";
import Confirmation from "../../../sharedComponent/Confirmation";
import Pagination from "../../../sharedComponent/Pgination";
import ImportFile from "../../../sharedComponent/ImportFile";
import HelpSheet from "../../../sharedComponent/HelpSheet";
import EmployeeGroupDialog from "../components/EmployeeGroupDialog";
import {
  getEmployeeGroups,
  updateEmployeeGroup
} from "../../../../../apis/EmployeeGroups";
import EmployeeLimitModal from "../components/EmployeeLimitModal";
import { useSubscription } from "../../../../../screens/auth/SubscriptionContext";

const deleteContent = {
  title: "Elimina Dipendente",
  des: "Sei sicuro di voler eliminare questo dipendente? Questa azione è irreversibile."
};

const AllEmployees = ({
  employeeData,
  setIsDelete,
  isDelete,
  setSearchQuery,
  currentPage,
  setCurrentPage,
  setLimit,
  totalPages,
  handlePageChange,
  limit,
  dataUpdate,
  setDataUpdate
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const [importModal, setImportModal] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;
  const [isGroupDialogOpen, setIsGroupDialogOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupAssignmentModal, setGroupAssignmentModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const { subscription } = useSubscription();
  const [employeeStats, setEmployeeStats] = useState(null);

  // Add new state for enhanced table
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  // Helper function to get qualification description
  const getQualificationText = (qualificationKey) => {
    const qualification = QUALIFICATION_LEVELS.find(q => q.key === qualificationKey);
    return qualification ? qualification.value : qualificationKey || "-";
  };

  // Helper function to truncate text with tooltip for full text
  const truncateText = (text, maxLength = 20) => {
    if (!text) return "-";
    
    if (text.length <= maxLength) {
      return text;
    }
    
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="cursor-help">{text.substring(0, maxLength)}...</span>
          </TooltipTrigger>
          <TooltipContent>
            <p>{text}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  // Sort data based on sortConfig
  const sortedData = useMemo(() => {
    if (!employeeData || !sortConfig.key) return employeeData;
    
    return [...employeeData].sort((a, b) => {
      // Handle possible null or undefined values
      const aValue = a[sortConfig.key] || '';
      const bValue = b[sortConfig.key] || '';
      
      // Special case for qualification - sort by the readable text, not the key
      if (sortConfig.key === 'qualification') {
        const aText = getQualificationText(a.qualification);
        const bText = getQualificationText(b.qualification);
        
        return sortConfig.direction === 'ascending'
          ? aText.localeCompare(bText)
          : bText.localeCompare(aText);
      }
      
      // For strings (default case)
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortConfig.direction === 'ascending'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      
      // For numbers
      return sortConfig.direction === 'ascending'
        ? aValue - bValue
        : bValue - aValue;
    });
  }, [employeeData, sortConfig]);

  const handleGroupAssignment = (employee) => {
    setSelectedEmployee(employee);
    setGroupAssignmentModal(true);
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const handleDeleteEmployee = async () => {
    setIsModalOpen(false);
    const response = await deleteEmployee(deletedId);
    if (response.status === 200) {
      toast.success(
        `${response.data?.employee.firstName} dipendente eliminato con successo`
      );
      setIsDelete(!isDelete);
    }
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
  };

  const handleExport = (value) => {
    const columns = [
      { header: "Nome", key: "firstName" },
      { header: "Cognome", key: "sureName" },
      { header: "Email", key: "email" },
      { header: "Posizione", key: "Cognometion" },
      { header: "Livello", key: "level" },
      { header: "Cellulare", key: "telephone" }
    ];

    if (value === "excel") {
      exportToExcel(employeeData, "Dipendenti");
    } else if (value === "pdf") {
      exportToPDF(employeeData, columns, "Lista Dipendenti");
    }
  };

  const handleAddEmployee = async (value) => {
    if (value === "single") {
      // Get current employee count from employeeData length
      const currentCount = employeeData?.length || 0;

      // Get limit based on subscription plan
      let limit;
      const plan = subscription?.planType?.toLowerCase() || "";

      if (plan.includes("basic")) {
        limit = 5;
      } else if (plan.includes("standard")) {
        limit = 20;
      } else if (plan.includes("professional")) {
        limit = 50;
      } else if (plan.includes("enterprise")) {
        limit = Infinity;
      } else {
        limit = 0;
      }

      if (currentCount >= limit && limit !== Infinity) {
        setEmployeeStats({
          currentCount,
          limit
        });
        setShowLimitModal(true);
      } else {
        navigate("/employees/add");
      }
    } else if (value === "multiple") {
      setImportModal(true);
    }
  };

  const importEmployees = async (fileData) => {
    let payload = {
      companyId: companyId,
      employees: fileData
    };
    const response = await importEmployeesList(payload);
    if (response.status === 201) {
      toast.success("Dipendenti importati con successo!");
      setDataUpdate(!dataUpdate);
      setImportModal(false);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await getEmployeeGroups(companyId);
      setGroups(response.data.groups);
    } catch (error) {
      toast.error("Errore nel recupero dei gruppi");
    }
  };

  const handleToggleEmployeeGroup = async (groupId, employeeId) => {
    try {
      const group = groups.find((g) => g._id === groupId);
      if (!group) return;

      const isInGroup = group.employees.some((emp) => emp._id === employeeId);
      const updatedEmployees = isInGroup
        ? group.employees.filter((emp) => emp._id !== employeeId)
        : [...group.employees, employeeId];

      await updateEmployeeGroup(groupId, {
        ...group,
        employees: updatedEmployees
      });

      await fetchGroups();
      toast.success(
        isInGroup
          ? "Dipendente rimosso dal gruppo"
          : "Dipendente aggiunto al gruppo"
      );
    } catch (error) {
      toast.error("Errore durante l'aggiornamento del gruppo");
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:justify-between sm:items-center">
        <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button className="bg-[#06052C] text-primary-foreground hover:bg-[#06052C]/90">
                Aggiungi dipendente
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onClick={() => handleAddEmployee("single")}>
                Crea nuovo dipendente
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => handleAddEmployee("multiple")}>
                Importa dipendenti
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">Esporta</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onClick={() => handleExport("excel")}>
                Esporta in Excel
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => handleExport("pdf")}>
                Esporta in PDF
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="md:w-1/6 flex justify-center items-center gap-x-2">
          <Button
            variant="outline"
            onClick={() => setIsGroupDialogOpen(true)}
            className="flex items-center gap-2"
          >
            <UsersRound className="h-4 w-4" />
            Gruppi
          </Button>
          <SearchBar onSearch={handleSearch} />
          <HelpSheet route="/employees" />
        </div>
      </div>

      <div className="rounded-md border">
        <EnhancedTable
          items={sortedData || []}
          sortConfig={sortConfig}
          onSortChange={setSortConfig}
          selectedItems={selectedItems}
          onSelectChange={setSelectedItems}
          selectAll={selectAll}
          onSelectAllChange={setSelectAll}
        >
          <TableHeader>
            <TableRow>
              <SelectAllTableHead />
              <SortableTableHead sortKey="freshman">
                Matricola
              </SortableTableHead>
              <SortableTableHead sortKey="firstName">Nome</SortableTableHead>
              <SortableTableHead sortKey="sureName">Cognome</SortableTableHead>
              <SortableTableHead>Gruppo</SortableTableHead>
              <SortableTableHead sortKey="email">Email</SortableTableHead>
              <SortableTableHead sortKey="task">Mansione</SortableTableHead>
              <SortableTableHead sortKey="qualification">
                Qualifica
              </SortableTableHead>
              <SortableTableHead sortKey="telephone">
                Cellulare
              </SortableTableHead>
              <SortableTableHead></SortableTableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedData?.length > 0 ? (
              sortedData?.map((data, index) => (
                <SelectableTableRow key={data._id || index} item={data}>
                  <SelectionTableCell item={data} />
                  <TableCell>{data.freshman || "-"}</TableCell>
                  <TableCell>{data.firstName}</TableCell>
                  <TableCell>{data.sureName}</TableCell>
                  <TableCell>
                    {groups
                      .filter((group) =>
                        group.employees.some((emp) => emp._id === data._id)
                      )
                      .map((group) => group.name)
                      .join(", ") || "-"}
                  </TableCell>
                  <TableCell>{data.email}</TableCell>
                  <TableCell>{data.task}</TableCell>
                  <TableCell>
                    {truncateText(getQualificationText(data.qualification), 15)}
                  </TableCell>
                  <TableCell>{data.telephone}</TableCell>
                  <ActionsTableCell>
                    <DropdownMenuItem
                      onClick={() => handleGroupAssignment(data)}
                    >
                      <UserPlus className="h-4 w-4 mr-2" />
                      Assegna a gruppo
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => navigate(`/employees/edit/${data._id}`)}
                    >
                      <Eye className="h-4 w-4 mr-2" />
                      Visualizza dettagli
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => {
                        setIsModalOpen(true);
                        setDeletedId(data._id);
                      }}
                    >
                      <Trash2 className="h-4 w-4 mr-2 text-red-600" />
                      Elimina dipendente
                    </DropdownMenuItem>
                  </ActionsTableCell>
                </SelectableTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} className="text-center py-8">
                  Nessun dato disponibile
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </EnhancedTable>
      </div>
      <Dialog
        open={groupAssignmentModal}
        onOpenChange={() => setGroupAssignmentModal(false)}
      >
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Assegna a Gruppo</DialogTitle>
            <DialogDescription>
              Seleziona i gruppi per {selectedEmployee?.firstName}{" "}
              {selectedEmployee?.sureName}
            </DialogDescription>
          </DialogHeader>
          <div className="py-4">
            <ScrollArea className="h-[300px] rounded-md border p-2">
              <div className="space-y-2">
                {groups.map((group) => {
                  const isInGroup = group.employees.some(
                    (emp) => emp._id === selectedEmployee?._id
                  );
                  return (
                    <div
                      key={group._id}
                      className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-100 rounded"
                      onClick={() =>
                        handleToggleEmployeeGroup(
                          group._id,
                          selectedEmployee?._id
                        )
                      }
                    >
                      <div
                        className={`w-4 h-4 border rounded flex items-center justify-center
                  ${
                    isInGroup
                      ? "bg-blue-500 border-blue-500"
                      : "border-gray-300"
                  }`}
                      >
                        {isInGroup && <Check className="h-3 w-3 text-white" />}
                      </div>
                      <span>{group.name}</span>
                    </div>
                  );
                })}
              </div>
            </ScrollArea>
          </div>
          <DialogFooter>
            <Button onClick={() => setGroupAssignmentModal(false)}>
              Chiudi
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {sortedData?.length > 0 && (
        <div className="flex justify-end">
          <Pagination
            totalPages={totalPages}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
          />
        </div>
      )}

      <Confirmation
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        setIsModalOpen={setIsModalOpen}
        deleteFunction={handleDeleteEmployee}
        deleteContent={deleteContent}
      />

      <ImportFile
        isOpen={importModal}
        onClose={() => setImportModal(false)}
        setIsModalOpen={setIsModalOpen}
        setDataUpdate={setDataUpdate}
        dataUpdate={dataUpdate}
        importFunction={importEmployees}
        vendorType="Dipendenti"
      />
      <EmployeeGroupDialog
        isOpen={isGroupDialogOpen}
        onClose={() => setIsGroupDialogOpen(false)}
        employees={employeeData}
        onGroupCreated={fetchGroups}
      />
      <EmployeeLimitModal
        isOpen={showLimitModal}
        onClose={() => setShowLimitModal(false)}
        currentCount={employeeStats?.currentCount}
        limit={employeeStats?.limit}
        planType={subscription?.planType}
        isAdmin={user.role === "admin"}
      />
    </div>
  );
};

export default AllEmployees;