import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getSingleClient } from "../../../../apis/client";
import { Card, CardContent } from "../../../ui/card";
import { Label } from "../../../ui/label";
import { Button } from "../../../ui/button";
import { ArrowLeft, Eye, Menu, Edit, MoreHorizontal } from "lucide-react";
import { toast } from "react-toastify";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";
import { Sheet, SheetContent, SheetTrigger } from "../../../ui/sheet";
import { fetchPreventivi } from "../../../../apis/Preventivi";
import { getConstructionSites } from "../../../../apis/ConstructionSite";
import { cn } from "../../../../lib/utils";
import Spinner from "../../../Spinner";
import {
  EnhancedTable,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../../ui/table";
import { DropdownMenuItem } from "../../../ui/dropdown-menu";

const ClientDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [activeTab, setActiveTab] = useState("personal");
  const [quotes, setQuotes] = useState([]);
  const [constructionSites, setConstructionSites] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoadingQuotes, setIsLoadingQuotes] = useState(false);
  const [isLoadingSites, setIsLoadingSites] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  // Add enhanced table state
  const [quoteSortConfig, setQuoteSortConfig] = useState({
    key: "dataa",
    direction: "descending"
  });
  const [quoteSelectedItems, setQuoteSelectedItems] = useState({});
  const [quoteSelectAll, setQuoteSelectAll] = useState(false);

  const [siteSortConfig, setSiteSortConfig] = useState({
    key: "name",
    direction: "ascending"
  });
  const [siteSelectedItems, setSiteSelectedItems] = useState({});
  const [siteSelectAll, setSiteSelectAll] = useState(false);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await getSingleClient(id);
        if (response.status === 200) {
          setClient(response.data.client);
        }
      } catch (error) {
        toast.error("Errore nel caricamento dei dati del cliente");
      }
    };

    if (id) {
      fetchClientData();
    }
  }, [id]);

  useEffect(() => {
    const fetchQuotes = async () => {
      if (activeTab === "quotes" && id) {
        setIsLoadingQuotes(true);
        try {
          const response = await fetchPreventivi(user.companyId);
          const clientQuotes = response.data.preventivis
            .filter((quote) => quote.clienti?._id === id)
            .map((quote) => ({
              ...quote,
              id: quote._id // Add id property for selection
            }));
          setQuotes(clientQuotes);
        } catch (error) {
          console.error("Error fetching quotes:", error);
          toast.error("Errore nel caricamento dei preventivi");
        } finally {
          setIsLoadingQuotes(false);
        }
      }
    };

    fetchQuotes();
  }, [activeTab, id]);

  useEffect(() => {
    const fetchSites = async () => {
      if (activeTab === "sites" && id) {
        setIsLoadingSites(true);
        try {
          const response = await getConstructionSites(user.companyId);
          const clientSites = response.data.sites
            .filter((site) => site.client?._id === id)
            .map((site) => ({
              ...site,
              id: site._id // Add id property for selection
            }));
          setConstructionSites(clientSites);
        } catch (error) {
          console.error("Error fetching construction sites:", error);
          toast.error("Errore nel caricamento dei cantieri");
        } finally {
          setIsLoadingSites(false);
        }
      }
    };

    fetchSites();
  }, [activeTab, id]);

  if (!client) return null;

  const DetailsSection = ({ label, value }) => (
    <div className="flex flex-col">
      <Label className="text-xs text-muted-foreground">{label}</Label>
      <span className="text-sm font-medium">{value || "N/A"}</span>
    </div>
  );

  const renderPersonalDataTab = () => (
    <div className="space-y-6 py-4">
      <Card className="shadow-none border-0 px-0">
        <CardContent>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Left Column */}
            <div className="grid grid-rows-2 gap-6">
              {/* Personal Details */}
              <div className="bg-card rounded-lg relative">
                <div className="absolute -top-3 left-4 px-2 bg-background border rounded-xl">
                  <h3 className="text-lg font-semibold">Dati Personali</h3>
                </div>
                <div className="border rounded-lg p-6 h-full">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    <DetailsSection
                      label="Nome/Ragione Sociale"
                      value={client.fullName}
                    />
                    <DetailsSection
                      label="Partita IVA"
                      value={client.vatNumber}
                    />
                    <DetailsSection
                      label="Codice Fiscale"
                      value={client.taxId}
                    />
                    <DetailsSection
                      label="Codice Univoco"
                      value={client.billingCode}
                    />
                  </div>
                </div>
              </div>

              {/* Contact Details */}
              <div className="bg-card rounded-lg relative">
                <div className="absolute -top-3 left-4 px-2 bg-background border rounded-xl">
                  <h3 className="text-lg font-semibold">Contatti</h3>
                </div>
                <div className="border rounded-lg p-6 h-full">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    <DetailsSection label="Email" value={client.email} />
                    <DetailsSection label="PEC" value={client.pec} />
                    <DetailsSection label="Telefono" value={client.telephone} />
                    <DetailsSection
                      label="Cellulare"
                      value={client.mobilePhone}
                    />
                    <DetailsSection label="Sito Web" value={client.website} />
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="grid grid-rows-2 gap-6">
              {/* Address Details */}
              <div className="bg-card rounded-lg relative">
                <div className="absolute -top-3 left-4 px-2 bg-background border rounded-xl">
                  <h3 className="text-lg font-semibold">Luogo</h3>
                </div>
                <div className="border rounded-lg p-6 h-full">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    <DetailsSection label="Indirizzo" value={client.address} />
                    <DetailsSection label="Città" value={client.city} />
                    <DetailsSection label="CAP" value={client.postalCode} />
                    <DetailsSection label="Provincia" value={client.province} />
                    <DetailsSection label="Nazione" value={client.nation} />
                  </div>
                </div>
              </div>

              {/* Notes */}
              <div className="bg-card rounded-lg relative">
                <div className="absolute -top-3 left-4 px-2 bg-background border rounded-xl">
                  <h3 className="text-lg font-semibold">Note</h3>
                </div>
                <div className="border rounded-lg p-6 h-full">
                  <p className="text-sm">
                    {client.note || "Nessuna nota disponibile"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderQuotesTab = () => (
    <Card className="px-4 border-0">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-semibold">Preventivi</h2>
        <Button
          onClick={() => navigate("/preventivi_add")}
          className="w-full sm:w-auto"
        >
          Nuovo Preventivo
        </Button>
      </div>

      {isLoadingQuotes ? (
        <div className="flex justify-center py-8">
          <Spinner />
        </div>
      ) : (
        <div className="rounded-md border">
          <EnhancedTable
            items={quotes}
            sortConfig={quoteSortConfig}
            onSortChange={setQuoteSortConfig}
            selectedItems={quoteSelectedItems}
            onSelectChange={setQuoteSelectedItems}
            selectAll={quoteSelectAll}
            onSelectAllChange={setQuoteSelectAll}
          >
            <TableHeader>
              <TableRow>
                <SelectAllTableHead />
                <SortableTableHead sortKey="numero">Numero</SortableTableHead>
                <SortableTableHead sortKey="dataa">Data</SortableTableHead>
                <SortableTableHead sortKey="ogguto">Oggetto</SortableTableHead>
                <SortableTableHead sortKey="totaleval">
                  Totale
                </SortableTableHead>
                <SortableTableHead sortKey="stato">Stato</SortableTableHead>
                <SortableTableHead></SortableTableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {quotes.length > 0 ? (
                quotes.map((quote) => (
                  <SelectableTableRow key={quote._id} item={quote}>
                    <SelectionTableCell item={quote} />
                    <TableCell>{quote.numero}</TableCell>
                    <TableCell>
                      {new Date(quote.dataa).toLocaleDateString("it-IT")}
                    </TableCell>
                    <TableCell>{quote.ogguto}</TableCell>
                    <TableCell>
                      €{" "}
                      {parseFloat(quote.totaleval).toLocaleString("it-IT", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </TableCell>
                    <TableCell>
                      <span
                        className={cn(
                          "inline-block px-2 py-1 text-xs rounded-full",
                          {
                            "bg-yellow-100 text-yellow-800":
                              quote.stato === "In corso",
                            "bg-green-100 text-green-800":
                              quote.stato === "Accettato",
                            "bg-red-100 text-red-800":
                              quote.stato === "Rifiutato",
                            "bg-blue-100 text-blue-800":
                              quote.stato === "Inviato"
                          }
                        )}
                      >
                        {quote.stato}
                      </span>
                    </TableCell>
                    <ActionsTableCell>
                      <DropdownMenuItem
                        onClick={() =>
                          navigate(`/preventivi_edit/${quote._id}`)
                        }
                      >
                        <Eye className="h-4 w-4 mr-2" /> Visualizza dettagli
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() =>
                          navigate(`/preventivi_edit/${quote._id}`)
                        }
                      >
                        <Edit className="h-4 w-4 mr-2" /> Modifica
                      </DropdownMenuItem>
                    </ActionsTableCell>
                  </SelectableTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} className="text-center py-4">
                    Nessun preventivo trovato per questo cliente
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </EnhancedTable>
        </div>
      )}
    </Card>
  );

  const renderConstructionSitesTab = () => (
    <Card className="px-4 border-0">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-semibold">Cantieri</h2>
        <Button
          onClick={() => navigate("/cantieri/nuovo")}
          className="w-full sm:w-auto"
        >
          Nuovo Cantiere
        </Button>
      </div>

      {isLoadingSites ? (
        <div className="flex justify-center py-8">
          <Spinner />
        </div>
      ) : (
        <div className="rounded-md border">
          <EnhancedTable
            items={constructionSites}
            sortConfig={siteSortConfig}
            onSortChange={setSiteSortConfig}
            selectedItems={siteSelectedItems}
            onSelectChange={setSiteSelectedItems}
            selectAll={siteSelectAll}
            onSelectAllChange={setSiteSelectAll}
          >
            <TableHeader>
              <TableRow>
                <SelectAllTableHead />
                <SortableTableHead sortKey="name">Nome</SortableTableHead>
                <SortableTableHead sortKey="address">
                  Indirizzo
                </SortableTableHead>
                <SortableTableHead sortKey="amount">Importo</SortableTableHead>
                <SortableTableHead sortKey="status">Stato</SortableTableHead>
                <SortableTableHead></SortableTableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {constructionSites.length > 0 ? (
                constructionSites.map((site) => (
                  <SelectableTableRow key={site._id} item={site}>
                    <SelectionTableCell item={site} />
                    <TableCell>{site.name}</TableCell>
                    <TableCell>{site.address}</TableCell>
                    <TableCell>
                      €{" "}
                      {parseFloat(site.amount).toLocaleString("it-IT", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </TableCell>
                    <TableCell>
                      <span
                        className={cn(
                          "inline-block px-2 py-1 text-xs rounded-full",
                          {
                            "bg-yellow-100 text-yellow-800":
                              site.status === "In corso",
                            "bg-green-100 text-green-800":
                              site.status === "Terminato",
                            "bg-red-100 text-red-800":
                              site.status === "Sospeso",
                            "bg-blue-100 text-blue-800":
                              site.status === "In pianificazione",
                            "bg-purple-100 text-purple-800":
                              site.status === "In collaudo",
                            "bg-orange-100 text-orange-800":
                              site.status === "In revisione",
                            "bg-gray-100 text-gray-800":
                              site.status === "Archiviato"
                          }
                        )}
                      >
                        {site.status}
                      </span>
                    </TableCell>
                    <ActionsTableCell>
                      <DropdownMenuItem
                        onClick={() =>
                          navigate(`/cantieri/${site._id}/informazioni`)
                        }
                      >
                        <Eye className="h-4 w-4 mr-2" /> Visualizza dettagli
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() =>
                          navigate(`/cantieri/${site._id}/modifica`)
                        }
                      >
                        <Edit className="h-4 w-4 mr-2" /> Modifica
                      </DropdownMenuItem>
                    </ActionsTableCell>
                  </SelectableTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} className="text-center py-4">
                    Nessun cantiere trovato per questo cliente
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </EnhancedTable>
        </div>
      )}
    </Card>
  );

  const tabs = [
    { id: "personal", label: "Dati Personali" },
    { id: "quotes", label: "Preventivi" },
    { id: "sites", label: "Cantieri" }
  ];

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-50">
      <Tabs
        orientation="vertical"
        value={activeTab}
        onValueChange={setActiveTab}
        className="flex flex-col lg:flex-row w-full"
      >
        {/* Mobile menu button */}
        <div className="lg:hidden p-4 bg-white border-b">
          <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen}>
            <SheetTrigger asChild>
              <Button
                variant="outline"
                className="w-full flex justify-between items-center"
              >
                <span>{tabs.find((tab) => tab.id === activeTab)?.label}</span>
                <Menu className="h-5 w-5" />
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="w-[240px] sm:w-[280px]">
              <nav className="flex flex-col space-y-2">
                {tabs.map((tab) => (
                  <Button
                    key={tab.id}
                    variant={activeTab === tab.id ? "default" : "ghost"}
                    className="justify-start"
                    onClick={() => {
                      setActiveTab(tab.id);
                      setIsMobileMenuOpen(false);
                    }}
                  >
                    {tab.label}
                  </Button>
                ))}
              </nav>
            </SheetContent>
          </Sheet>
        </div>

        {/* Left sidebar with tab triggers (hidden on mobile) */}
        <div className="hidden lg:block w-48 lg:h-screen border-r">
          <div className="p-4 sticky top-0 z-10">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => navigate("/clients")}
              className="flex items-center gap-2 mb-4 p-0"
            >
              <ArrowLeft className="h-4 w-4" />
              <h3 className="text-sm font-bold">
                {client.fullName || "Dettagli Cliente"}
              </h3>
            </Button>

            <TabsList className="flex flex-col justify-start bg-transparent space-y-2 text-start">
              {tabs.map((tab) => (
                <TabsTrigger
                  key={tab.id}
                  value={tab.id}
                  className="w-full text-left py-1 px-0 border-r-2 border-transparent data-[state=active]:border-primary rounded-none"
                >
                  {tab.label}
                </TabsTrigger>
              ))}
            </TabsList>
          </div>
        </div>

        {/* Main content area */}
        <div className="flex-1 px-4 overflow-y-auto">
          <TabsContent value="personal">{renderPersonalDataTab()}</TabsContent>
          <TabsContent value="quotes">{renderQuotesTab()}</TabsContent>
          <TabsContent value="sites">
            {renderConstructionSitesTab()}
          </TabsContent>
        </div>
      </Tabs>
    </div>
  );
};

export default ClientDetailsPage;
