import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { MdDashboard, MdConstruction, MdChat } from "react-icons/md";
import {
  FaMagento,
  FaCalendarAlt,
  FaCalculator,
  FaTruck,
  FaHome,
  FaBox,
  FaUsers,
  FaHandshake,
  FaIndustry,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import { PiUsersThreeFill } from "react-icons/pi";
import {
  Archive,
  HandCoins,
  FileText,
  TrendingUpDown,
  Construction,
  DollarSign,
  SquareActivity,
  ClipboardList,
  Landmark,
} from "lucide-react";
import logo from "../../../assest/logo2.png";
import comingSoonIcon from "../../../assest/comingsoon2.png";

const styles = {
  categoryHeading: {
    color: "#666666",
    fontSize: "12px",
    fontWeight: "500",
    marginTop: "16px",
    marginBottom: "8px",
    paddingLeft: "8px",
  },
};

const SidebarContent = ({
  isActive,
  linkClass,
  dropdownStates,
  toggleDropdown,
  updateBreadcrumbs,
  isMobile,
}) => (
  <>
    <div className={`${isMobile ? "mb-2" : ""}`}>
      <Link to="/">
        <img src={logo} alt="logo" className="w-auto h-8" />
      </Link>
    </div>
    <ul className="space-y-1 font-medium mt-3">
      <li>
        <Link
          to="/dashboard"
          className={linkClass("/dashboard")}
          onClick={() =>
            updateBreadcrumbs({ path: "/dashboard", label: "Dashboard" })
          }
        >
          <MdDashboard size={14} />
          <span className="ms-2 text-sm">Dashboard</span>
        </Link>
      </li>
      <li>
        <Link
          to="/calendar"
          className={linkClass("/calendar")}
          onClick={() =>
            updateBreadcrumbs({ path: "/calendar", label: "Calendario" })
          }
        >
          <FaCalendarAlt size={14} />
          <span className="ms-2 text-sm whitespace-nowrap">Calendario</span>
        </Link>
      </li>
      <h4 className="text-xs font-medium" style={styles.categoryHeading}>
        Organizzazione
      </h4>
      <li>
        <Link
          to="/agency/information"
          className={linkClass("/agency")}
          onClick={() =>
            updateBreadcrumbs({ path: "/agency/information", label: "Azienda" })
          }
        >
          <FaMagento size={14} />
          <span className="ms-2 text-sm whitespace-nowrap">Azienda</span>
        </Link>
      </li>
      <li>
        <button
          type="button"
          className={`flex items-center w-full py-1 px-2 text-sm ${
            isActive("/employees") ||
            isActive("/clients") ||
            isActive("/suppliers")
              ? "bg-slate-300 text-gray-950"
              : "text-gray-700 hover:bg-gray-200 hover:text-gray-900"
          } transition duration-75 rounded-lg group`}
          onClick={() => toggleDropdown("personalData", "Persone")}
        >
          <PiUsersThreeFill size={14} />
          <span className="flex-1 ms-2 text-left rtl:text-right whitespace-nowrap">
            Persone
          </span>
          <IoIosArrowDown size={14} />
        </button>
        {dropdownStates.personalData && (
          <ul className="py-1 space-y-1">
            <li>
              <Link
                to="/employees/all employees"
                className={`flex items-center w-full py-1 px-2 gap-2 transition duration-75 rounded-lg pl-6 group text-sm ${
                  isActive("/employees")
                    ? "bg-gray-200 text-gray-900"
                    : "text-gray-700 hover:bg-gray-200 hover:text-gray-900"
                }`}
                onClick={() =>
                  updateBreadcrumbs({
                    path: "/employees/all employees",
                    label: "Dipendenti",
                  })
                }
              >
                <FaUsers size={12} />
                <span>Dipendenti</span>
              </Link>
            </li>
            <li>
              <Link
                to="/clients"
                className={`flex items-center w-full py-1 px-2 gap-2 transition duration-75 rounded-lg pl-6 group text-sm ${
                  isActive("/clients")
                    ? "bg-gray-200 text-gray-900"
                    : "text-gray-700 hover:bg-gray-200 hover:text-gray-900"
                }`}
                onClick={() =>
                  updateBreadcrumbs({ path: "/clients", label: "Cliente" })
                }
              >
                <FaHandshake size={12} />
                <span>Clienti</span>
              </Link>
            </li>
            <li>
              <Link
                to="/suppliers"
                className={`flex items-center w-full py-1 px-2 gap-2 transition duration-75 rounded-lg pl-6 group text-sm ${
                  isActive("/suppliers")
                    ? "bg-gray-200 text-gray-900"
                    : "text-gray-700 hover:bg-gray-200 hover:text-gray-900"
                }`}
                onClick={() =>
                  updateBreadcrumbs({ path: "/suppliers", label: "Fornitori" })
                }
              >
                <FaIndustry size={12} />
                <span>Fornitori</span>
              </Link>
            </li>
          </ul>
        )}
      </li>
      <li>
        <Link
          to="/flotta"
          className={linkClass("/flotta")}
          onClick={() =>
            updateBreadcrumbs({ path: "/flotta", label: "Flotta" })
          }
        >
          <FaTruck size={14} />
          <span className="ms-2 text-sm whitespace-nowrap">Flotta</span>
        </Link>
      </li>
      <li>
        <Link
          to="/magazzino"
          className={linkClass("/magazzino")}
          onClick={() =>
            updateBreadcrumbs({ path: "/magazzino", label: "Magazzino" })
          }
        >
          <FaHome size={14} />
          <span className="ms-2 text-sm whitespace-nowrap">Magazzino</span>
        </Link>
      </li>
      <h4 className="text-xs font-medium" style={styles.categoryHeading}>
        Contabilità
      </h4>
      <li>
        <Link
          to="/prima-nota"
          className={linkClass("/prima-nota")}
          onClick={() =>
            updateBreadcrumbs({ path: "/prima-nota", label: "Prima Nota" })
          }
        >
          <FileText size={14} />
          <span className="ms-2 text-sm whitespace-nowrap">Prima Nota</span>
        </Link>
      </li>
      <li>
        <Link
          to="/movimenti"
          className={linkClass("/movimenti")}
          onClick={() =>
            updateBreadcrumbs({ path: "/movimenti", label: "Movimenti" })
          }
        >
          <SquareActivity size={14} />
          <span className="ms-2 text-sm whitespace-nowrap">Movimenti</span>
        </Link>
      </li>
      <li>
        <Link
          to="/payment-methods"
          className={linkClass("/payment-methods")}
          onClick={() =>
            updateBreadcrumbs({
              path: "/payment-methods",
              label: "Conti bancari",
            })
          }
        >
          <Landmark size={14} />
          <span className="ms-2 text-sm whitespace-nowrap">Conti bancari</span>
        </Link>
      </li>
      <li>
        <Link
          to="/tax"
          className={linkClass("/tax")}
          onClick={() => updateBreadcrumbs({ path: "/tax", label: "Tasse" })}
        >
          <HandCoins size={14} />
          <span className="ms-2 text-sm whitespace-nowrap">Tasse</span>
        </Link>
      </li>
      <li>
        <Link
          to="/installment"
          className={linkClass("/installment")}
          onClick={() =>
            updateBreadcrumbs({ path: "/installment", label: "Rateizzi" })
          }
        >
          <FaFileInvoiceDollar size={14} />
          <span className="ms-2 text-sm whitespace-nowrap">Rateizzi</span>
        </Link>
      </li>
      <li>
        <Link
          to="/ddt"
          className={linkClass("/ddt")}
          onClick={() => updateBreadcrumbs({ path: "/ddt", label: "DDT" })}
        >
          <FaBox size={14} />
          <span className="ms-2 text-sm whitespace-nowrap">DDT</span>
        </Link>
      </li>
      <h4 className="text-xs font-medium" style={styles.categoryHeading}>
        Produzione
      </h4>
      <li>
        <Link
          to="/computi-metrici"
          className={linkClass("/computi-metrici")}
          onClick={() =>
            updateBreadcrumbs({
              path: "/computi-metrici",
              label: "Computi metrici",
            })
          }
        >
          <FaCalculator size={14} />
          <span className="ms-2 text-sm whitespace-nowrap">Computi Metrici</span>
        </Link>
      </li>
      <li>
        <Link
          to="/preventivi"
          className={linkClass("/preventivi")}
          onClick={() =>
            updateBreadcrumbs({ path: "/preventivi", label: "Preventivi" })
          }
        >
          <TrendingUpDown size={14} />
          <span className="ms-2 text-sm whitespace-nowrap">Preventivi</span>
        </Link>
      </li>
      <li>
        <button
          type="button"
          className={`flex items-center w-full py-1 px-2 text-sm ${
            isActive("/cantieri") || isActive("/budget")
              ? "bg-slate-300 text-slate-900"
              : "text-slate-700 hover:bg-slate-200 hover:text-slate-900"
          } transition duration-75 rounded-lg group`}
          onClick={() => toggleDropdown("constructionSites", "Cantieri")}
        >
          <MdConstruction size={14} />
          <span className="flex-1 ms-2 text-left whitespace-nowrap">
            Cantieri
          </span>
          <IoIosArrowDown size={14} />
        </button>
        {dropdownStates.constructionSites && (
          <ul className="py-1 space-y-1">
            <li>
              <Link
                to="/cantieri"
                className={`flex items-center w-full py-1 px-2 gap-2 transition duration-75 rounded-lg pl-6 group text-sm ${
                  isActive("/cantieri")
                    ? "bg-gray-200 text-gray-900"
                    : "text-gray-700 hover:bg-gray-200 hover:text-gray-900"
                }`}
                onClick={() =>
                  updateBreadcrumbs({
                    path: "/cantieri",
                    label: "Tutti i Cantieri",
                  })
                }
              >
                <Construction size={12} />
                <span>Tutti i cantieri</span>
              </Link>
            </li>
            <li>
              <Link
                to="/budget"
                className={`flex items-center w-full py-1 px-2 gap-2 transition duration-75 rounded-lg pl-6 group text-sm ${
                  isActive("/budget")
                    ? "bg-gray-200 text-gray-900"
                    : "text-gray-700 hover:bg-gray-200 hover:text-gray-900"
                }`}
                onClick={() =>
                  updateBreadcrumbs({ path: "/budget", label: "Budget" })
                }
              >
                <DollarSign size={12} />
                <span>Budget</span>
              </Link>
            </li>
          </ul>
        )}
      </li>

      <li>
        <Link
          to="/documenti"
          className={linkClass("/documenti")}
          onClick={() =>
            updateBreadcrumbs({ path: "/documenti", label: "Documenti" })
          }
        >
          <FileText size={14} />
          <span className="ms-2 text-sm whitespace-nowrap">Documenti</span>
        </Link>
      </li>

      <li>
        <Link
          to="/archive"
          className={linkClass("/archive")}
          onClick={() =>
            updateBreadcrumbs({ path: "/archive", label: "Archivio" })
          }
        >
          <Archive size={14} />
          <span className="ms-2 text-sm whitespace-nowrap">Archivio</span>
        </Link>
      </li>
      <h4 className="text-xs font-medium" style={styles.categoryHeading}>
        RENTRI
      </h4>

      <li>
        <Link
          className={`${linkClass(
            "/"
          )} cursor-pointer justify-between pointer-events-none`}
        >
          <div className="flex items-center">
            <ClipboardList size={14} />
            <span className="ms-2 text-sm whitespace-nowrap text-slate-900">Formulari</span>
          </div>

          <div className="flex items-center overflow-hidden max-h-[28px] max-w-[36px]">
            <img
              className="w-[50px] h-[50px] object-cover max-w-full max-h-full"
              src={comingSoonIcon}
              alt="Coming soon"
            />
          </div>
        </Link>
      </li>

      <li>
        <Link
          className={`${linkClass(
            "/"
          )} cursor-pointer justify-between pointer-events-none`}
        >
          <div className="flex items-center">
            <ClipboardList size={14} />
            <span className="ms-2 text-sm whitespace-nowrap text-slate-900">Registri</span>
          </div>

          <div className="flex items-center overflow-hidden max-h-[28px] max-w-[36px]">
            <img
              className="w-[50px] h-[50px] object-cover max-w-full max-h-full"
              src={comingSoonIcon}
              alt="Coming soon"
            />
          </div>
        </Link>
      </li>

      <h4 className="text-xs font-medium" style={styles.categoryHeading}>
        Comunicazione
      </h4>
      <li>
        <Link
          to="/chat"
          className={linkClass("/chat")}
          onClick={() => updateBreadcrumbs({ path: "/chat", label: "Chat" })}
        >
          <MdChat size={14} />
          <span className="ms-2 text-sm whitespace-nowrap">Chat</span>
        </Link>
      </li>
    </ul>
  </>
);

const ResponsiveSidebar = ({ updateBreadcrumbs }) => {
  const location = useLocation();
  const [dropdownStates, setDropdownStates] = useState({});

  // Updated isActive function to check for preventivi_add and preventivi_edit routes
  const isActive = (path) => {
    if (path === "/") return location.pathname === "/";
    
    if (path === "/preventivi") {
      return (
        location.pathname.startsWith("/preventivi") ||
        location.pathname.startsWith("/preventivi_add") ||
        location.pathname.startsWith("/preventivi_edit")
      );
    }
    
    return location.pathname === path || location.pathname.startsWith(`${path}/`);
  };

  const toggleDropdown = (menu, label) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
    if (!dropdownStates[menu]) {
      updateBreadcrumbs({ path: "", label });
    }
  };

  const linkClass = (path) =>
    `flex items-center py-1 px-2 rounded-lg ${
      isActive(path)
        ? "bg-slate-300 text-slate-950 font-medium"
        : "text-slate-900 hover:bg-slate-200 hover:text-slate-900"
    } transition-colors duration-200`;

  return (
    <div className="hidden lg:block">
      <aside
        className="fixed top-0 left-0 z-40 w-56 h-screen"
        aria-label="Sidebar"
      >
        <div className="h-full p-2 overflow-y-auto bg-gray-100 sidebar-scrollbar">
          <SidebarContent
            isActive={isActive}
            linkClass={linkClass}
            dropdownStates={dropdownStates}
            toggleDropdown={toggleDropdown}
            updateBreadcrumbs={updateBreadcrumbs}
            isMobile={false}
          />
        </div>
      </aside>
    </div>
  );
};

export default ResponsiveSidebar;