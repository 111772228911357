import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { fetchCompany } from "../apis/CompanyDetail";

export const DateFormat = (date) => {
  const parts = date?.split("-");
  const formattedExpireDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return formattedExpireDate;
};

// Helper function to format date in dd-mm-yyyy
const formatDateToDDMMYYYY = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

export const changeDateFormat = (dateString) => {
  let parts = dateString.split("-");
  let formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return formattedDate;
};

export const exportToExcel = (data, fileName) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fileName);
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export const exportComputiMetriciToPDF = async (
  formattedData,
  columns,
  stats,
  summaries,
  generalData = {}
) => {
  const doc = new jsPDF();

  // Add custom landing page with general data
  await createComputiMetriciLandingPage(doc, generalData);

  // Add data pages - remove the total row from formatted data
  const dataWithoutTotal = formattedData.slice(0, -1); // Remove last row (total)
  doc.addPage();
  await addComputiMetriciDataPages(
    doc,
    dataWithoutTotal,
    columns,
    stats,
    summaries
  );

  return doc;
};

const createComputiMetriciLandingPage = async (doc, generalData = {}) => {
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const margin = 16;

  // Set navy blue color
  const navyBlue = [0, 0, 128];
  doc.setDrawColor(...navyBlue);
  doc.setFillColor(...navyBlue);
  doc.setTextColor(...navyBlue);

  // Header Section
  doc.setFontSize(32);
  doc.setFont("Montserrat", "bold");
  const headerTitle = `Comune di ${generalData.comune || ""}`;
  const headerTitleWidth = doc.getTextWidth(headerTitle);
  doc.text(headerTitle, (pageWidth - headerTitleWidth) / 2, 40);

  doc.setFontSize(20);
  const subHeader = `Provincia di ${generalData.provincia || ""}`;
  const subHeaderWidth = doc.getTextWidth(subHeader);
  doc.text(subHeader, (pageWidth - subHeaderWidth) / 2, 55);

  // Divider Line
  doc.setLineWidth(0.5);
  doc.line(margin, 65, pageWidth - margin, 65);

  // Main Title
  doc.setFontSize(32);
  const mainTitle = "COMPUTO METRICO";
  const mainTitleWidth = doc.getTextWidth(mainTitle);
  doc.text(mainTitle, (pageWidth - mainTitleWidth) / 2, 90);

  // OGGETTO Section
  const labelY = 120;
  doc.setFillColor(...navyBlue);
  doc.setTextColor(255, 255, 255);

  doc.rect(margin, labelY, 50, 12, "F");
  doc.setFontSize(14);
  doc.setFont("Montserrat", "bold");
  doc.text("OGGETTO :", margin + 5, labelY + 8);

  doc.setTextColor(0, 0, 0);
  doc.setFont("Montserrat", "normal");

  // Split oggetto text into multiple lines if needed
  const maxWidth = pageWidth - (margin + 60 + margin);
  const oggetto = generalData.oggetto || "";
  const splitOggetto = doc.splitTextToSize(oggetto, maxWidth);
  splitOggetto.forEach((line, index) => {
    doc.text(line, margin + 60, labelY + 8 + index * 8);
  });

  // COMMITTENTE Section
  const committenteLabelY = labelY + 40;
  doc.setTextColor(255, 255, 255);
  doc.setFillColor(...navyBlue);
  doc.rect(margin, committenteLabelY, 50, 12, "F");
  doc.setFont("Montserrat", "bold");
  doc.text("COMMITTENTE :", margin + 5, committenteLabelY + 8);

  doc.setTextColor(0, 0, 0);
  doc.setFont("Montserrat", "normal");
  const clientName =
    generalData.committente?.typology === "Private"
      ? generalData.committente?.fullName
      : generalData.committente?.companyName;
  doc.text(clientName || "", margin + 60, committenteLabelY + 8);

  // Signature Section
  const signatureY = pageHeight - 80;
  doc.line(margin, signatureY, margin + 48, signatureY);
  doc.line(pageWidth - margin - 48, signatureY, pageWidth - margin, signatureY);

  doc.setFontSize(12);
  doc.text("Data", margin + 20, signatureY + 10);
  doc.text("IL tecnico", pageWidth - margin - 40, signatureY + 10);

  // Custom Footer Section
  const footerHeight = 24;
  const footerY = pageHeight - footerHeight;

  // Get company info
  const user = JSON.parse(localStorage.getItem("user"));
  let companyInfo = null;

  if (user?.companyId) {
    try {
      const response = await fetchCompany(user.companyId);
      companyInfo = response.data.company;
    } catch (error) {
      console.error("Error fetching company info:", error);
    }
  }

  // Navy blue background
  doc.setFillColor(...navyBlue);
  doc.rect(0, footerY, pageWidth, footerHeight, "F");

  // Set text color to white for footer
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(10);

  // Left side footer text with dynamic company info
  const leftFooterLine1 = companyInfo
    ? [
        companyInfo.companyName,
        companyInfo.address,
        companyInfo.postalCode,
        companyInfo.city,
        companyInfo.province
      ]
        .filter(Boolean)
        .join(", ")
    : "";

  const leftFooterLine2 = companyInfo
    ? [
        companyInfo.telephoneNumber && `Tel: ${companyInfo.telephoneNumber}`,
        companyInfo.businessEmail
      ]
        .filter(Boolean)
        .join(" - ")
    : "";

  // Add left footer text
  doc.text(leftFooterLine1, margin, footerY + 12);
  doc.text(leftFooterLine2, margin, footerY + 20);

  // Right side footer text
  doc.text(
    "Powered by Restruct © Galdiero Systems Srl",
    pageWidth - margin - 60,
    footerY + 20
  );
};

const addComputiMetriciDataPages = async (
  doc,
  formattedData,
  columns,
  stats,
  summaries
) => {
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const margin = 8;
  const navyBlue = [0, 0, 128];

  // Add the table starting from top of page
  await doc.autoTable({
    startY: margin,
    // Define two level headers
    head: [
      // First level - Group headers
      [
        { content: "", colSpan: 3 }, // Empty cells for Voce, Codice, Descrizione
        { content: "Misure", colSpan: 5, styles: { halign: "center" } }, // Group header for Misure
        { content: "Prezzi", colSpan: 2, styles: { halign: "center" } } // Group header for Prezzi
      ],
      // Second level - Column headers
      columns.map((col) => col.header)
    ],
    body: formattedData.map((row) => columns.map((col) => row[col.key] ?? "")),
    styles: {
      font: "Montserrat",
      lineWidth: 0.1,
      lineColor: [80, 80, 80],
      textColor: [0, 0, 0],
      fillColor: false
    },
    headStyles: {
      fontStyle: "bold",
      halign: "left",
      fillColor: false,
      textColor: [0, 0, 0]
    },
    bodyStyles: {
      fillColor: false
    },
    columnStyles: columns.reduce((styles, col, index) => {
      styles[index] = {
        cellWidth:
          ((col.width || 1) * (pageWidth - 2 * margin)) /
          columns.reduce((sum, c) => sum + (c.width || 1), 0),
        cellPadding: 2,
        fontSize: 9,
        overflow: "linebreak"
      };
      return styles;
    }, {}),
    theme: "plain",
    margin: { left: margin, right: margin },
    didDrawPage: (data) => {
      // Add footer with navy blue background
      const footerHeight = 24;
      const footerY = pageHeight - footerHeight;

      // Draw navy blue background
      doc.setFillColor(...navyBlue);
      doc.rect(0, footerY, pageWidth, footerHeight, "F");

      // Set text color to white for footer
      doc.setTextColor(255, 255, 255);
      doc.setFontSize(10);

      // Get company info synchronously from localStorage
      const user = JSON.parse(localStorage.getItem("user"));
      const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

      if (companyInfo) {
        // Left side footer text
        const leftFooterText = [
          companyInfo.companyName,
          companyInfo.address,
          companyInfo.postalCode,
          companyInfo.city,
          companyInfo.province
        ]
          .filter(Boolean)
          .join(", ");

        const contactInfo = [
          companyInfo.telephoneNumber && `Tel: ${companyInfo.telephoneNumber}`,
          companyInfo.businessEmail
        ]
          .filter(Boolean)
          .join(" - ");

        doc.text(leftFooterText, margin, footerY + 12);
        doc.text(contactInfo, margin, footerY + 20);
      }

      // Right side footer text
      doc.text(
        "Powered by Restruct © Galdiero Systems Srl",
        pageWidth - margin - 60,
        footerY + 16
      );
    }
  });

  // Add total after the table
  const finalY = doc.previousAutoTable.finalY + 10;
  doc.setFontSize(10);
  doc.setFont("Montserrat", "bold");
  doc.setTextColor(0, 0, 0);

  const totalText = "Totale Complessivo:";
  const totalValue = `€ ${summaries[0]?.value.split(" ")[1] || "0.00"}`;

  const totalWidth = doc.getTextWidth(totalText);
  const valueWidth = doc.getTextWidth(totalValue);

  doc.text(totalText, pageWidth - margin - valueWidth - totalWidth - 5, finalY);
  doc.text(totalValue, pageWidth - margin - valueWidth, finalY);
};

export const addCompanyHeader = async (doc) => {
  const user = JSON.parse(localStorage.getItem("user"));
  let companyInfo = null;

  if (user?.companyId) {
    try {
      const response = await fetchCompany(user.companyId);
      companyInfo = response.data.company;
    } catch (error) {
      //console.log('Error fetching company info:', error);
      return 15;
    }
  }

  const leftMargin = 14;
  const pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  let yPos = 15;
  let contentStartY = yPos;

  // Logo section - on the left
  if (companyInfo?.logo1) {
    try {
      const logoUrl = `${process.env.REACT_APP_BACKEND_URL}/${companyInfo.logo1}`;
      const img = new Image();
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = logoUrl;
      });

      const squareSize = 30;
      const aspectRatio = img.width / img.height;
      let logoWidth, logoHeight;

      if (aspectRatio > 1) {
        logoWidth = squareSize;
        logoHeight = squareSize / aspectRatio;
      } else {
        logoHeight = squareSize;
        logoWidth = squareSize * aspectRatio;
      }

      doc.addImage(logoUrl, "JPEG", leftMargin, yPos, logoWidth, logoHeight);
      contentStartY = Math.max(contentStartY, yPos + logoHeight + 5);
    } catch (error) {
      //console.log('Error adding logo:', error);
    }
  }

  // Company info section - starts to the right of logo
  const infoStartX = leftMargin + 40;
  yPos = 15;

  if (companyInfo?.companyName) {
    doc.setFontSize(12);
    doc.setFont("Montserrat", "bold");
    doc.text(companyInfo.companyName, infoStartX, yPos);
    yPos += 6;
  }

  doc.setFontSize(10);
  doc.setFont("Montserrat", "normal");

  let addressLine = "";
  if (companyInfo?.address) addressLine += companyInfo.address;
  if (companyInfo?.postalCode)
    addressLine += addressLine
      ? `, ${companyInfo.postalCode}`
      : companyInfo.postalCode;
  if (companyInfo?.province)
    addressLine += addressLine
      ? `, ${companyInfo.province}`
      : companyInfo.province;

  if (addressLine) {
    doc.text(addressLine, infoStartX, yPos);
    yPos += 5;
  }

  let contactLine = "";
  if (companyInfo?.taxIdCode) contactLine += `P.IVA: ${companyInfo.taxIdCode}`;
  if (companyInfo?.telephoneNumber)
    contactLine += contactLine
      ? `, Tel. ${companyInfo.telephoneNumber}`
      : `Tel. ${companyInfo.telephoneNumber}`;

  if (contactLine) {
    doc.text(contactLine, infoStartX, yPos);
    yPos += 5;
  }

  if (companyInfo?.businessEmail) {
    doc.text(companyInfo.businessEmail, infoStartX, yPos);
    yPos += 8;
  }

  const finalYPos = Math.max(contentStartY, yPos);

  if (companyInfo && Object.keys(companyInfo).some((key) => companyInfo[key])) {
    doc.setDrawColor(200, 200, 200);
    doc.line(leftMargin, finalYPos, pageWidth - leftMargin, finalYPos);
    return finalYPos + 10;
  }

  return finalYPos;
};

export const exportToPDF = async (
  data,
  columns,
  fileName,
  stats,
  companyInfo = null,
  summaries = null,
  oggettoSection = null,
  existingDoc = null,
  startY = null
) => {
  const doc = existingDoc || new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  doc.setFont("Montserrat");

  let yPos = startY || (await addCompanyHeader(doc));

  if (!existingDoc) {
    // Add title with date
    doc.setFontSize(16);
    doc.setFont("Montserrat", "bold");
    const currentDate = formatDateToDDMMYYYY(new Date());
    const titleText = `${fileName} del ${currentDate}`;
    doc.text(titleText, 14, yPos);
    yPos += 10;
  }

  if (stats?.left && stats?.right) {
    doc.setFontSize(10);

    let leftYPos = yPos;
    stats.left.forEach((stat) => {
      if (stat.isTitle) {
        doc.setFont("Montserrat", "bold");
        doc.text(stat.label, 14, leftYPos);
        leftYPos += 8;
      } else if (stat.label) {
        doc.setFont("Montserrat", "normal");
        // Format date if the label is "Data"
        const value =
          stat.label.toLowerCase() === "data"
            ? formatDateToDDMMYYYY(stat.value)
            : stat.value;
        doc.text(`${stat.label}: ${value}`, 14, leftYPos);
        leftYPos += 5;
      }
    });

    let rightYPos = yPos;
    stats.right.forEach((stat) => {
      if (stat.isTitle) {
        doc.setFont("Montserrat", "bold");
        const textWidth = doc.getTextWidth(stat.label);
        doc.text(stat.label, pageWidth - 14 - textWidth, rightYPos);
        rightYPos += 8;
      } else if (stat.value) {
        doc.setFont("Montserrat", "normal");
        const text = stat.value;
        const textWidth = doc.getTextWidth(text);
        doc.text(text, pageWidth - 14 - textWidth, rightYPos);
        rightYPos += 5;
      }
    });

    yPos = Math.max(leftYPos, rightYPos);
  }

  if (oggettoSection) {
    yPos += 4;
    doc.setFontSize(10);
    doc.setFont("Montserrat", "bold");
    doc.text(`${oggettoSection.label}:`, 14, yPos);
    yPos += 5;
    doc.setFont("Montserrat", "normal");
    const maxWidth = pageWidth - 28;
    const lines = doc.splitTextToSize(oggettoSection.value, maxWidth);
    lines.forEach((line) => {
      doc.text(line, 14, yPos);
      yPos += 5;
    });
    yPos += 4;
  }

  const tableResult = doc.autoTable({
    startY: yPos,
    head: [columns.map((col) => col.header)],
    body: data.map((row) => columns.map((col) => row[col.key] ?? "")),
    styles: {
      font: "Montserrat",
      lineWidth: 0.1,
      lineColor: [80, 80, 80],
      textColor: [0, 0, 0],
      fillColor: false
    },
    headStyles: {
      fontStyle: "bold",
      halign: "left",
      fillColor: false,
      textColor: [0, 0, 0]
    },
    bodyStyles: {
      fillColor: false
    },
    columnStyles: columns.reduce((styles, col, index) => {
      styles[index] = {
        cellWidth:
          ((col.width || 1) * (pageWidth - 28)) /
          columns.reduce((sum, c) => sum + (c.width || 1), 0),
        cellPadding: 2,
        fontSize: 9,
        overflow: "linebreak"
      };
      return styles;
    }, {}),
    theme: "plain",
    margin: { left: 14, right: 14 }
  });

  if (summaries?.length) {
    let summaryY = tableResult.lastAutoTable.finalY + 10;
    doc.setFontSize(10);

    summaries.forEach((summary, index) => {
      const isLastItem = index === summaries.length - 1;

      // Set bold font for the last item (Totale Complessivo)
      doc.setFont("Montserrat", isLastItem ? "bold" : "normal");

      const text = `${summary.label}: ${summary.value}`;
      const textWidth = doc.getTextWidth(text);
      doc.text(text, pageWidth - 14 - textWidth, summaryY);
      summaryY += 6;
    });
  }

  const pageHeight = doc.internal.pageSize.height;
  const footerText = "Powered by Restruct © Galdiero Systems Srl";
  doc.setFontSize(8);
  doc.setFont("Montserrat", "normal");
  const footerWidth = doc.getTextWidth(footerText);
  doc.text(footerText, pageWidth - footerWidth - 14, pageHeight - 10);

  doc.save(`${fileName}.pdf`);
};

const flattenTreeStructure = (
  node,
  result = [],
  level = 0,
  parentName = null
) => {
  if (!node) return result;

  result.push({
    level: level,
    name: node.name || node.designation,
    designation: node.designation,
    parent: parentName
  });

  if (node.children && node.children.length > 0) {
    node.children.forEach((child) => {
      flattenTreeStructure(
        child,
        result,
        level + 1,
        node.name || node.designation
      );
    });
  }

  return result;
};

export const exportOrgChartToPDF = async (tree) => {
  // Flatten the tree structure
  const flattenedData = flattenTreeStructure(tree);

  // Define columns for the PDF table
  const columns = [
    { header: "Livello", key: "level", width: 0.5 },
    { header: "Nome", key: "name", width: 1.5 },
    { header: "Ruolo", key: "designation", width: 1.5 },
    { header: "Riporta a", key: "parent", width: 1.5 }
  ];

  // Generate statistics
  const stats = {
    left: [
      { label: "Totale dipendenti", value: flattenedData.length },
      {
        label: "Livelli organizzativi",
        value: Math.max(...flattenedData.map((item) => item.level)) + 1
      }
    ],
    right: [{ label: "Data", value: new Date().toLocaleDateString("it-IT") }]
  };

  // Export to PDF
  await exportToPDF(flattenedData, columns, "Organigramma Aziendale", stats);
};

export const exportDocumentToPDF = async (document, formData) => {
  try {
    // Create the jsPDF instance
    const doc = new jsPDF({
      unit: "pt",
      format: "a4"
    });

    // Define separate margins
    const TOP_MARGIN = 20;
    const BOTTOM_MARGIN = 40;
    // Calculate the usable width and page height
    const pageWidth = doc.internal.pageSize.width - 2 * TOP_MARGIN;
    const pageHeight = doc.internal.pageSize.height;

    // Start at the top margin
    let yPos = TOP_MARGIN;
    let currentPage = doc.internal.getNumberOfPages();

    if (!document || !document.content) {
      console.error("❌ Document content is missing!");
      return;
    }

    // Parse the Quill-generated HTML
    const parser = new DOMParser();
    const parsedDoc = parser.parseFromString(document.content, "text/html");
    const body = parsedDoc.body;

    // Collect the content blocks
    let contentArray = [];

    const processElement = (el, prefix = "") => {
      // Process lists (UL/OL)
      if (el.tagName === "UL" || el.tagName === "OL") {
        const isOrdered = el.tagName === "OL";
        Array.from(el.children).forEach((child, index) => {
          const bullet = isOrdered ? `${index + 1}. ` : "• ";
          processElement(child, bullet);
        });
        return;
      }

      // Process child nodes if not a simple inline element
      if (
        el.children.length > 0 &&
        !["B", "STRONG", "I", "EM", "U"].includes(el.tagName)
      ) {
        Array.from(el.childNodes).forEach((child) => {
          if (child.nodeType === Node.TEXT_NODE) {
            const rawText = child.textContent;
            contentArray.push({
              text: rawText.trim() === "" ? " " : prefix + rawText.trim(),
              fontSize: 12,
              fontVariant: "normal",
              textDecoration: false,
              textAlign: "left"
            });
          } else if (child.nodeType === Node.ELEMENT_NODE) {
            processElement(child, prefix);
          }
        });
        return;
      }

      // Process block element
      const rawText = el.textContent;
      if (rawText.trim() === "") {
        contentArray.push({
          text: " ",
          fontSize: 12,
          fontVariant: "normal",
          textDecoration: false,
          textAlign: "left"
        });
        return;
      }

      let text = rawText.trim();
      let fontSize = 12;
      let fontWeight = false;
      let fontStyle = false;
      let textDecoration = false;
      let textAlign = "left";

      // Adjust for headings
      if (el.tagName === "H1") {
        fontSize = 24;
        fontWeight = "bold";
      } else if (el.tagName === "H2") {
        fontSize = 18;
        fontWeight = "bold";
      } else if (el.tagName === "H3") {
        fontSize = 16;
        fontWeight = "bold";
      }

      // Check for inline styles
      if (
        el.tagName === "STRONG" ||
        el.tagName === "B" ||
        el.classList.contains("ql-bold")
      ) {
        fontWeight = "bold";
      } else if (
        el.tagName === "EM" ||
        el.tagName === "I" ||
        el.classList.contains("ql-italic")
      ) {
        fontStyle = "italic";
      } else if (el.tagName === "U" || el.classList.contains("ql-underline")) {
        textDecoration = "underline";
      }

      // Check for alignment classes from Quill
      if (el.classList.contains("ql-align-center")) textAlign = "center";
      else if (el.classList.contains("ql-align-right")) textAlign = "right";
      else if (el.classList.contains("ql-align-justify")) textAlign = "justify";

      // Determine font variant
      let fontVariant = "normal";
      if (fontWeight === "bold" && fontStyle === "italic") {
        fontVariant = "bolditalic";
      } else if (fontWeight === "bold") {
        fontVariant = "bold";
      } else if (fontStyle === "italic") {
        fontVariant = "italic";
      }

      contentArray.push({
        text: prefix + text,
        fontSize,
        fontVariant,
        textDecoration,
        textAlign
      });
    };

    // Process all top-level elements
    Array.from(body.children).forEach((el) => {
      processElement(el);
    });

    // Render each block, checking for page breaks using the bottom margin
    contentArray.forEach(
      ({ text, fontSize, fontVariant, textDecoration, textAlign }) => {
        // Reset yPos if a new page is detected
        if (doc.internal.getNumberOfPages() > currentPage) {
          currentPage = doc.internal.getNumberOfPages();
          yPos = TOP_MARGIN;
        }

        doc.setFontSize(fontSize);
        doc.setFont("Montserrat", fontVariant);

        // Wrap text for available width
        const textLines = doc.splitTextToSize(text, pageWidth);
        const textHeight = textLines.length * (fontSize + 2);

        // Check if adding this block exceeds the bottom margin
        if (yPos + textHeight > pageHeight - BOTTOM_MARGIN) {
          doc.addPage();
          currentPage = doc.internal.getNumberOfPages();
          yPos = TOP_MARGIN;
        }

        // Render underline if needed
        if (textDecoration === "underline") {
          const textWidth = doc.getTextWidth(text);
          const underlineY = yPos + 2;
          doc.line(TOP_MARGIN, underlineY, TOP_MARGIN + textWidth, underlineY);
        }

        // Render text with proper alignment
        if (textAlign === "center") {
          doc.text(textLines, doc.internal.pageSize.width / 2, yPos, {
            align: "center"
          });
        } else if (textAlign === "right") {
          doc.text(textLines, doc.internal.pageSize.width - TOP_MARGIN, yPos, {
            align: "right"
          });
        } else {
          doc.text(textLines, TOP_MARGIN, yPos);
        }

        // Update yPos for next block
        yPos += textHeight + 5;
      }
    );

    // Save the PDF
    doc.save(`${document.name}.pdf`);
  } catch (error) {
    console.error("❌ Export Error:", error);
  }
};

// Utility functions for time formatting
export const formatTimeStringToAMPM = (timeString) => {
  if (!timeString) return "";
  let [hours, minutes] = timeString.trim().split(":").map(Number);
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return `${hours}:${minutes} ${ampm}`;
};

export const formatWorkingHours = (workingHours) => {
  if (!workingHours) return "";
  const [hours, minutes] = workingHours.toString().split(".").map(Number);
  const formattedMinutes = minutes || 0;
  return `${hours}h ${formattedMinutes.toString().padStart(2, "0")}m`;
};

export const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
