import React, { useEffect, useState, useMemo } from "react";
import { Edit, Trash2, Eye } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { it } from "date-fns/locale";

import {
  fetchTrainings,
  deleteTrainingCertificate,
  fetchSingleTraining
} from "../../../../../apis/training";

import { Button } from "../../../../ui/button";
import {
  EnhancedTable,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "../../../../ui/dialog";
import { DropdownMenuItem } from "../../../../ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../../../../ui/tooltip";
import AddCertificate from "./AddCertificate";
import SearchBar from "../../../sharedComponent/SearchBar";
import HelpSheet from "../../../sharedComponent/HelpSheet";
import Pagination from "../../../sharedComponent/Pgination";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Training = ({ employeeData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [trainingData, setTrainingData] = useState([]);
  const [singleTraining, setSingleTraining] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const companyId = user.companyId;

  // Add enhanced table state
  const [sortConfig, setSortConfig] = useState({
    key: "expiration",
    direction: "ascending"
  });
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const getExpiryStatus = (expiryDate) => {
    const today = new Date();
    const expiry = new Date(expiryDate);
    const diffTime = expiry - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 0) {
      return { status: "Scaduta", color: "text-red-500" };
    } else if (diffDays <= 30) {
      return { status: "In Scadenza", color: "text-orange-500" };
    } else {
      return { status: "Valida", color: "text-green-500" };
    }
  };

  // Create sortedData memoized function
  const sortedData = useMemo(() => {
    if (!trainingData || !sortConfig.key) return trainingData;
    
    return [...trainingData].sort((a, b) => {
      // Handle nested properties like employee.firstName
      if (sortConfig.key.includes('.')) {
        const keys = sortConfig.key.split('.');
        let aValue = a;
        let bValue = b;
        
        // Navigate through nested objects
        for (const key of keys) {
          aValue = aValue?.[key] || '';
          bValue = bValue?.[key] || '';
        }
        
        // Compare the values
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortConfig.direction === 'ascending' 
            ? aValue.localeCompare(bValue) 
            : bValue.localeCompare(aValue);
        }
        
        return sortConfig.direction === 'ascending' 
          ? (aValue > bValue ? 1 : -1) 
          : (bValue > aValue ? 1 : -1);
      }
      
      // Special case for date sorting
      if (sortConfig.key === 'expiration') {
        const dateA = new Date(a[sortConfig.key] || '');
        const dateB = new Date(b[sortConfig.key] || '');
        return sortConfig.direction === 'ascending' ? dateA - dateB : dateB - dateA;
      }
      
      // Default string comparison
      const aValue = a[sortConfig.key] || '';
      const bValue = b[sortConfig.key] || '';
      
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortConfig.direction === 'ascending' 
          ? aValue.localeCompare(bValue) 
          : bValue.localeCompare(aValue);
      }
      
      // Numeric comparison
      return sortConfig.direction === 'ascending' 
        ? (aValue > bValue ? 1 : -1) 
        : (bValue > aValue ? 1 : -1);
    });
  }, [trainingData, sortConfig]);

  const getTrainings = async () => {
    try {
      const response = await fetchTrainings(
        companyId,
        currentPage,
        limit,
        searchQuery
      );
      if (response.status === 200) {
        // Add an id property for selection tracking
        const trainingsWithIds = response.data.trainingCertificates.map(
          (cert) => ({
            ...cert,
            id: cert._id
          })
        );
        setTrainingData(trainingsWithIds);
        setTotalPage(response.data?.totalPages);
        setCurrentPage(response.data?.currentPage);
      }
    } catch (error) {
      console.error("Errore nel recupero dei dati di formazione", error);
      toast.error("Impossibile recuperare i dati di formazione");
    }
  };

  useEffect(() => {
    getTrainings();
  }, [isUpdated, currentPage, limit, searchQuery]);

  const editDocument = async (id) => {
    try {
      setSelectedId(id);
      const response = await fetchSingleTraining(id);
      setSingleTraining(response.data?.trainingCertificate);
      if (response.status === 200) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Errore nel recupero dei dati del certificato", error);
      toast.error("Impossibile recuperare i dati del certificato");
    }
  };

  const handleDeleteDocument = async () => {
    try {
      const response = await deleteTrainingCertificate(selectedId);
      if (response.status === 200) {
        toast.success("Certificato di formazione eliminato con successo!");
        setIsUpdated(!isUpdated);
        setIsDeleteModalOpen(false);
      } else {
        toast.error("Impossibile eliminare il certificato di formazione.");
      }
    } catch (error) {
      console.error("Errore durante l'eliminazione del certificato", error);
      toast.error(
        "Si è verificato un errore durante l'eliminazione del certificato di formazione."
      );
    }
  };

  const getDocumentUrl = (documentUrl) => {
    if (!documentUrl) return "";
    if (documentUrl.startsWith("http")) return documentUrl;

    // Remove any leading slash from documentUrl to avoid double slashes
    const cleanDocumentUrl = documentUrl.startsWith("/")
      ? documentUrl.slice(1)
      : documentUrl;

    // Ensure backendUrl ends with a slash
    const cleanBackendUrl = backendUrl.endsWith("/")
      ? backendUrl
      : `${backendUrl}/`;

    return `${cleanBackendUrl}${cleanDocumentUrl}`;
  };

  const handleViewDocument = (imagePath) => {
    if (!imagePath) {
      toast.error("Nessun documento disponibile per la visualizzazione");
      return;
    }

    const fullUrl = getDocumentUrl(imagePath);
    window.open(fullUrl, "_blank");
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  // Function to truncate text and add tooltip for full text
  const truncateText = (text, maxLength = 20) => {
    if (!text) return "-";
    
    if (text.length <= maxLength) {
      return text;
    }
    
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="cursor-help">{text.substring(0, maxLength)}...</span>
          </TooltipTrigger>
          <TooltipContent>
            <p>{text}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  return (
    <div className="mx-auto space-y-4">
      <div className="flex flex-col md:flex-row justify-between items-center my-2 space-y-4 md:space-y-0">
        <Button onClick={() => setIsModalOpen(true)}>
          Aggiungi Certificato
        </Button>
        <div className="md:w-1/6 flex justify-center items-center gap-x-2">
          <SearchBar onSearch={handleSearch} />
          <HelpSheet route="/employees" />
        </div>
      </div>

      <div className="rounded-md border">
        <EnhancedTable
          items={sortedData}
          sortConfig={sortConfig}
          onSortChange={setSortConfig}
          selectedItems={selectedItems}
          onSelectChange={setSelectedItems}
          selectAll={selectAll}
          onSelectAllChange={setSelectAll}
        >
          <TableHeader>
            <TableRow>
              <SelectAllTableHead />
              <SortableTableHead sortKey="employee.freshman">
                Matricola
              </SortableTableHead>
              <SortableTableHead sortKey="employee.firstName">
                Nome
              </SortableTableHead>
              <SortableTableHead sortKey="employee.sureName">
                Cognome
              </SortableTableHead>
              <SortableTableHead sortKey="employee.Cognometion">
                Qualifica
              </SortableTableHead>
              <SortableTableHead sortKey="attestedName">
                Attestato
              </SortableTableHead>
              <SortableTableHead sortKey="expiration">
                Scadenza
              </SortableTableHead>
              <SortableTableHead></SortableTableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedData?.length > 0 ? (
              sortedData?.map((data, index) => {
                const expiryStatus = getExpiryStatus(data.expiration);
                return (
                  <SelectableTableRow key={data._id} item={data}>
                    <SelectionTableCell item={data} />
                    <TableCell>{data?.employee?.freshman || "-"}</TableCell>
                    <TableCell>{data.employee?.firstName}</TableCell>
                    <TableCell>{data.employee?.sureName || "-"}</TableCell>
                    <TableCell>{truncateText(data.employee?.Cognometion, 15)}</TableCell>
                    <TableCell>{truncateText(data.attestedName, 20)}</TableCell>
                    <TableCell>
                      {format(new Date(data.expiration), "dd/MM/yyyy", {
                        locale: it
                      })}
                      <span className={`ml-2 ${expiryStatus.color}`}>
                        ({expiryStatus.status})
                      </span>
                    </TableCell>
                    <ActionsTableCell>
                      <DropdownMenuItem
                        onClick={() => handleViewDocument(data.image)}
                      >
                        <Eye className="h-4 w-4 mr-2" /> Visualizza
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => editDocument(data._id)}>
                        <Edit className="h-4 w-4 mr-2" /> Modifica
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          setSelectedId(data._id);
                          setIsDeleteModalOpen(true);
                        }}
                        className="text-red-600"
                      >
                        <Trash2 className="h-4 w-4 mr-2" /> Elimina
                      </DropdownMenuItem>
                    </ActionsTableCell>
                  </SelectableTableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={8} className="text-center py-8">
                  Nessun dato disponibile
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </EnhancedTable>
      </div>

      {sortedData?.length > 0 && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onPageChange={(page) => setCurrentPage(page)}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      <AddCertificate
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        setIsModalOpen={setIsModalOpen}
        employeeData={employeeData}
        selectedId={selectedId}
        setIsUpdated={setIsUpdated}
        isUpdated={isUpdated}
        companyId={companyId}
        singleTraining={singleTraining}
      />

      <Dialog open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Elimina Certificato</DialogTitle>
            <DialogDescription>
              Sei sicuro di voler eliminare questo certificato? Questa azione è
              irreversibile.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Annulla
            </Button>
            <Button variant="destructive" onClick={handleDeleteDocument}>
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Training;