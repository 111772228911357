import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Eye, Trash, Filter, Upload, Archive, AlertTriangle, ArchiveRestore } from "lucide-react";
import {
  fetchPreventivi,
  updatePreventivi,
  deletePreventivo,
  archivePreventivo,
  unarchivePreventivo
} from "../../../apis/Preventivi";
import { toast } from "react-toastify";

import {
  EnhancedTable,
  TableHeader,
  TableBody,
  TableCell,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../ui/table";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription
} from "../../ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../../ui/tooltip";
import { DropdownMenuItem } from "../../ui/dropdown-menu";
import { Textarea } from "../../ui/textarea";

import SearchBar from "../sharedComponent/SearchBar";
import Pagination from "../../admin/sharedComponent/Pgination";
import HelpSheet from "../sharedComponent/HelpSheet";
import Spinner from "../../Spinner";
import { fetchCompany } from "../../../apis/PreventiviEdit";
import { exportToPDF } from "../../../config/helper";
import ImportDialog from "./ImportDialog";

const FilterDialog = ({
  filters,
  onFilterChange,
  onFilterApply,
  open,
  onOpenChange
}) => (
  <Dialog open={open} onOpenChange={onOpenChange}>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Filtri</DialogTitle>
      </DialogHeader>
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="startDate">Data di inizio</Label>
            <Input
              id="startDate"
              type="date"
              value={filters.startDate}
              onChange={(e) => onFilterChange("startDate", e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="endDate">Data di fine</Label>
            <Input
              id="endDate"
              type="date"
              value={filters.endDate}
              onChange={(e) => onFilterChange("endDate", e.target.value)}
            />
          </div>
        </div>
        <div className="space-y-2">
          <Label htmlFor="cliente">Cliente</Label>
          <Input
            id="cliente"
            placeholder="Nome del cliente"
            value={filters.cliente}
            onChange={(e) => onFilterChange("cliente", e.target.value)}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="numeroMin">Numero Minimo</Label>
            <Input
              id="numeroMin"
              type="number"
              placeholder="Numero min"
              value={filters.numeroMin}
              onChange={(e) => onFilterChange("numeroMin", e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="numeroMax">Numero Massimo</Label>
            <Input
              id="numeroMax"
              type="number"
              placeholder="Numero max"
              value={filters.numeroMax}
              onChange={(e) => onFilterChange("numeroMax", e.target.value)}
            />
          </div>
        </div>
        <div className="space-y-2">
          <Label htmlFor="showArchived">Mostra preventivi archiviati</Label>
          <div className="flex items-center">
            <input
              id="showArchived"
              type="checkbox"
              className="mr-2"
              checked={filters.showArchived}
              onChange={(e) => onFilterChange("showArchived", e.target.checked)}
            />
            <span>Includi preventivi archiviati</span>
          </div>
        </div>
      </div>
      <DialogFooter>
        <Button variant="outline" onClick={() => onOpenChange(false)}>
          Annulla
        </Button>
        <Button
          onClick={() => {
            onFilterApply();
            onOpenChange(false);
          }}
        >
          Applica Filtri
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
);

const AllPreventivi = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    cliente: "",
    numeroMin: "",
    numeroMax: "",
    showArchived: false
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [company, setCompany] = useState(null);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [preventivoToArchive, setPreventivoToArchive] = useState(null);
  const [archiveReason, setArchiveReason] = useState("");
  const [isArchiving, setIsArchiving] = useState(false);

  // Combine filtering and sorting logic in a single memoized function
  const filteredAndSortedData = useMemo(() => {
    // First apply search filter
    let filteredData = [...data];
    
    // Apply search term if it exists and is long enough
    if (searchTerm && searchTerm.length >= 3) {
      filteredData = filteredData.filter(
        (item) =>
          item.ogguto?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.clienti?.fullName
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.numero?.toString().includes(searchTerm) ||
          item.dataa?.includes(searchTerm)
      );
    }
    
    // Apply additional filters
    filteredData = filteredData.filter((item) => {
      // Skip filtering if no filters are set
      if (!filters.startDate && !filters.endDate && !filters.cliente && 
          !filters.numeroMin && !filters.numeroMax && !filters.showArchived) {
        return true;
      }
      
      const itemDate = item.dataa ? new Date(item.dataa) : null;
      
      return (
        // Date filters
        (!filters.startDate || !itemDate || itemDate >= new Date(filters.startDate)) &&
        (!filters.endDate || !itemDate || itemDate <= new Date(filters.endDate)) &&
        
        // Client filter
        (!filters.cliente ||
          (item.clienti?.fullName || "")
            .toLowerCase()
            .includes(filters.cliente.toLowerCase())) &&
        
        // Number range filters
        (!filters.numeroMin || !item.numero || 
          parseInt(item.numero) >= parseInt(filters.numeroMin)) &&
        (!filters.numeroMax || !item.numero || 
          parseInt(item.numero) <= parseInt(filters.numeroMax)) &&
        
        // Archive filter
        (filters.showArchived || !item.isArchived)
      );
    });

    // Apply sorting if a sort key is set
    if (sortConfig.key) {
      filteredData.sort((a, b) => {
        let aValue, bValue;

        // Handle nested properties and special date fields
        if (sortConfig.key === "cliente") {
          aValue = a.clienti?.fullName || "";
          bValue = b.clienti?.fullName || "";
        } else if (sortConfig.key === "data") {
          aValue = a.dataa ? new Date(a.dataa).getTime() : 0;
          bValue = b.dataa ? new Date(b.dataa).getTime() : 0;
        } else if (sortConfig.key === "validita") {
          aValue = a.validita ? new Date(a.validita).getTime() : 0;
          bValue = b.validita ? new Date(b.validita).getTime() : 0;
        } else if (sortConfig.key === "totaleval") {
          aValue = parseFloat(a.totaleval || 0);
          bValue = parseFloat(b.totaleval || 0);
        } else if (sortConfig.key === "numero") {
          aValue = parseInt(a.numero || 0);
          bValue = parseInt(b.numero || 0);
        } else {
          aValue = a[sortConfig.key] || "";
          bValue = b[sortConfig.key] || "";
        }

        // Handle string comparison
        if (typeof aValue === "string") {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredData;
  }, [data, searchTerm, filters, sortConfig]);

  useEffect(() => {
    getItems();
    fetchCompany(companyId).then(setCompany);
  }, [currentPage, limit]);

  const handleExport = async () => {
    try {
      setExporting(true);

      const formattedData = filteredAndSortedData.map((item, index) => ({
        nu: (index + 1).toString(),
        numero: item.numero || "",
        data: item.dataa
          ? new Date(item.dataa)
              .toLocaleDateString("it-IT", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
              })
              .replace(/\//g, "-")
          : "",
        cliente: item.clienti?.fullName || item.clienti?.companyName || "",
        oggetto: item.ogguto || "",
        validita: item.validita
          ? new Date(item.validita)
              .toLocaleDateString("it-IT", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
              })
              .replace(/\//g, "-")
          : "",
        stato: item.isArchived ? "Archiviato" : (item.stato || ""),
        totale: item.totaleval
          ? `€ ${parseFloat(item.totaleval).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`
          : "€ 0,00"
      }));

      const columns = [
        { header: "N°", key: "nu", width: 0.5 },
        { header: "Numero", key: "numero", width: 1 },
        { header: "Data", key: "data", width: 1.2 },
        { header: "Cliente", key: "cliente", width: 2 },
        { header: "Oggetto", key: "oggetto", width: 2 },
        { header: "Validità", key: "validita", width: 1.2 },
        { header: "Stato", key: "stato", width: 1 },
        { header: "Totale", key: "totale", width: 1.2 }
      ];

      const currentDate = new Date()
        .toLocaleDateString("it-IT", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric"
        })
        .replace(/\//g, "-");

      let dateRange = currentDate;
      if (filters.startDate && filters.endDate) {
        dateRange = `Dal ${new Date(filters.startDate)
          .toLocaleDateString("it-IT")
          .replace(/\//g, "-")} al ${new Date(filters.endDate)
          .toLocaleDateString("it-IT")
          .replace(/\//g, "-")}`;
      }

      const stats = [
        { label: "Totale Preventivi", value: filteredAndSortedData.length },
        { label: "Periodo", value: dateRange }
      ];

      // Calculate totals
      const totals = filteredAndSortedData.reduce(
        (acc, item) => {
          const imponibile = parseFloat(item.totaleval) || 0;
          return {
            imponibile: acc.imponibile + imponibile
          };
        },
        { imponibile: 0 }
      );

      const summaries = [
        {
          label: "Totale Complessivo",
          value: `€ ${totals.imponibile.toLocaleString("it-IT", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
        }
      ];

      await exportToPDF(
        formattedData,
        columns,
        "Lista Preventivi",
        stats,
        undefined,
        summaries
      );

      toast.success("Esportazione PDF completata con successo");
    } catch (error) {
      console.error("Errore durante l'esportazione PDF:", error);
      toast.error("Errore durante l'esportazione PDF");
    } finally {
      setExporting(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getItems = async () => {
    try {
      setLoading(true);
      const response = await fetchPreventivi(companyId, currentPage, limit);
      setData(response.data.preventivis);
      setTotalPage(response.data?.totalPages);
      setCurrentPage(response.data?.currentPage);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
      toast.error("Errore nel recupero dei dati. Riprova più tardi.");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleFilterChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleFilterApply = () => {
    // We don't need to do anything here as the filters are applied automatically
    // in the memoized filteredAndSortedData
  };

  const handleDelete = async (id) => {
    if (window.confirm("Sei sicuro di voler eliminare questo elemento?")) {
      try {
        await deletePreventivo(id);
        getItems();
        toast.success("Preventivo eliminato con successo");
      } catch (error) {
        console.error("Errore nella cancellazione del preventivo:", error);
        toast.error("Errore nella cancellazione del preventivo. Riprova.");
      }
    }
  };

  const handleArchiveClick = (preventivo) => {
    setPreventivoToArchive(preventivo);
    setArchiveModalOpen(true);
  };

  const handleArchiveConfirm = async () => {
    setIsArchiving(true);
    try {
      await archivePreventivo(preventivoToArchive._id, archiveReason);
      toast.success("Preventivo archiviato con successo");
      getItems();
      setArchiveModalOpen(false);
      setArchiveReason("");
      setPreventivoToArchive(null);
    } catch (error) {
      toast.error(
        error.message || "Errore durante l'archiviazione del preventivo"
      );
    } finally {
      setIsArchiving(false);
    }
  };

  const handleUnarchive = async (preventivoId) => {
    try {
      await unarchivePreventivo(preventivoId);
      toast.success("Preventivo ripristinato con successo");
      getItems();
    } catch (error) {
      toast.error(error.message || "Errore durante il ripristino del preventivo");
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return new Date(dateString)
      .toLocaleDateString("it-IT", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      })
      .replace(/\//g, "-");
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-row sm:items-center">
          <Link to="/preventivi_add" className="w-full sm:w-auto">
            <Button className="w-full sm:w-auto">Aggiungi</Button>
          </Link>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  onClick={handleExport}
                  disabled={exporting}
                  variant="outline"
                  className="w-full sm:w-auto"
                >
                  {exporting ? "Esportazione..." : "Esporta PDF"}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Scarica</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <Button
            onClick={() => setIsImportDialogOpen(true)}
            variant="outline"
            className="w-full sm:w-auto"
          >
            Importa
          </Button>
        </div>
        <div className="flex sm:flex-row gap-2 w-full sm:w-auto">
          <div className="w-full sm:w-auto">
            <SearchBar onSearch={handleSearch} />
          </div>
          <Button
            variant="outline"
            onClick={() => setIsFilterDialogOpen(true)}
            className="w-full sm:w-auto"
          >
            <Filter className="mr-2 h-4 w-4" /> Filtri
          </Button>
          <HelpSheet route="/preventivi" />
        </div>
      </div>

      <FilterDialog
        filters={filters}
        onFilterChange={handleFilterChange}
        onFilterApply={handleFilterApply}
        open={isFilterDialogOpen}
        onOpenChange={setIsFilterDialogOpen}
      />

      <div className="rounded-md border">
        {loading ? (
          <div className="text-center py-4 border-0">
            <Spinner loading={loading} />
          </div>
        ) : (
          <EnhancedTable
            items={filteredAndSortedData}
            sortConfig={sortConfig}
            onSortChange={setSortConfig}
            selectedItems={selectedItems}
            onSelectChange={setSelectedItems}
            selectAll={selectAll}
            onSelectAllChange={setSelectAll}
          >
            <TableHeader>
              <SelectAllTableHead />
              <SortableTableHead sortKey="numero">Numero</SortableTableHead>
              <SortableTableHead sortKey="data">Data</SortableTableHead>
              <SortableTableHead sortKey="cliente">Cliente</SortableTableHead>
              <SortableTableHead sortKey="stato">Stato</SortableTableHead>
              <SortableTableHead sortKey="ogguto" className="w-40">
                Descrizione
              </SortableTableHead>
              <SortableTableHead sortKey="validita">
                Validità
              </SortableTableHead>
              <SortableTableHead></SortableTableHead>
            </TableHeader>
            <TableBody>
              {filteredAndSortedData.length > 0 ? (
                filteredAndSortedData.map((item) => (
                  <SelectableTableRow key={item._id} item={item}>
                    <SelectionTableCell item={item} />
                    <TableCell>
                      <Link
                        to={`/preventivi_edit/${item._id}`}
                        className="hover:underline"
                      >
                        {item.numero}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {formatDate(item.dataa)}
                    </TableCell>
                    <TableCell>{item.clienti?.fullName}</TableCell>
                    <TableCell>
                      {item.isArchived ? "Archiviato" : item?.stato}
                    </TableCell>
                    <TableCell className="max-w-xs truncate">
                      {item.ogguto?.length > 30
                        ? `${item.ogguto.slice(0, 50)}...`
                        : item.ogguto}
                    </TableCell>
                    <TableCell>
                      {formatDate(item.validita)}
                    </TableCell>
                    <ActionsTableCell>
                      <DropdownMenuItem asChild>
                        <Link
                          to={`/preventivi_edit/${item._id}`}
                          className="flex items-center"
                        >
                          <Eye className="h-4 w-4 mr-2" /> Apri
                        </Link>
                      </DropdownMenuItem>
                      {item.isArchived ? (
                        <DropdownMenuItem
                          onClick={() => handleUnarchive(item._id)}
                          className="flex items-center"
                        >
                          <ArchiveRestore className="h-4 w-4 mr-2 text-green-500" /> Ripristina
                        </DropdownMenuItem>
                      ) : (
                        <DropdownMenuItem
                          onClick={() => handleArchiveClick(item)}
                          className="flex items-center"
                        >
                          <Archive className="h-4 w-4 mr-2" /> Archivia
                        </DropdownMenuItem>
                      )}
                      <DropdownMenuItem
                        onClick={() => handleDelete(item._id)}
                        className="flex items-center"
                      >
                        <Trash className="h-4 w-4 text-red-500 mr-2" /> Elimina
                      </DropdownMenuItem>
                    </ActionsTableCell>
                  </SelectableTableRow>
                ))
              ) : (
                <SelectableTableRow>
                  <TableCell colSpan={8} className="text-center py-4">
                    Nessun dato
                  </TableCell>
                </SelectableTableRow>
              )}
            </TableBody>
          </EnhancedTable>
        )}
      </div>

      {filteredAndSortedData.length > 0 && (
        <Pagination
          totalItems={filteredAndSortedData.length}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      <ImportDialog
        open={isImportDialogOpen}
        onOpenChange={setIsImportDialogOpen}
        onImportComplete={getItems}
      />

      {/* Archive Confirmation Dialog */}
      <Dialog open={archiveModalOpen} onOpenChange={setArchiveModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              <AlertTriangle className="text-yellow-500" />
              Conferma archiviazione
            </DialogTitle>
          </DialogHeader>
          <DialogDescription className="space-y-4">
            <p>
              Sei sicuro di voler archiviare il preventivo "{preventivoToArchive?.numero}"?
            </p>
            <div className="space-y-2">
              <Label>Motivo dell'archiviazione (opzionale)</Label>
              <Textarea
                placeholder="Inserisci il motivo dell'archiviazione..."
                value={archiveReason}
                onChange={(e) => setArchiveReason(e.target.value)}
              />
            </div>
          </DialogDescription>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setArchiveModalOpen(false);
                setArchiveReason("");
                setPreventivoToArchive(null);
              }}
            >
              Annulla
            </Button>
            <Button
              variant="destructive"
              onClick={handleArchiveConfirm}
              disabled={isArchiving}
            >
              {isArchiving ? "Archiviazione in corso..." : "Archivia"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AllPreventivi;