import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate, useLocation } from "react-router-dom";
import { resetPasswordSchema } from "../../config/validations";
import { useForm } from "react-hook-form";
import Content from "./Content";
import { resetPassword } from "../../apis/Auth";
import { toast } from "react-toastify";
import loginImage from "../../assest/singup.jpg";
import logo from "../../assest/logo1.png";

const CreateNewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [passwordShown, setPasswordShown] = useState(false);
  const contentData = {
    title: "Costruire con passione, creare con precisione",
    description:
      "Realizza spazi ispiratori con precisione, passione e un impegno per l'eccellenza nella costruzione.",
    image: loginImage
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
    mode: "onChange"
  });

  const onSubmit = async (data) => {
    try {
      const response = await resetPassword(token, data);
      if (response.status === 200) {
        toast.success(response.data.message);
        navigate("/login");
      } else {
        toast.error("Impossibile reimpostare la password. Riprova.");
      }
    } catch (error) {
      //console.log("error", error);
      toast.error("Si è verificato un errore. Riprova.");
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      <div className="md:hidden w-full bg-gradient-to-r from-blue-950 to-blue-800 p-4 flex justify-center">
        <img src={logo} className="w-[180px]" alt="Logo" />
      </div>
      <div className="hidden md:block md:w-[45%]">
        <Content contentData={contentData} />
      </div>
      <div className="flex-1 flex flex-col justify-center items-center p-4 md:p-8 bg-gradient-to-r from-blue-950 to-blue-800">
        <h1 className="mb-6 md:mb-10 text-white font-nunito text-2xl md:text-3xl font-bold text-center">
          Inserisci la nuova <span className="text-lime-400">Password</span>
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-[350px] md:max-w-[400px] space-y-4"
        >
          <div className="relative w-full">
            <input
              {...register("password")}
              type={passwordShown ? "text" : "password"}
              className={`h-12 p-4 pl-4 pr-10 rounded-full text-normal text-black w-full ring-2 ${
                errors.password ? "ring-red-500" : "ring-blue-700"
              }`}
              autoComplete="off"
              placeholder="Password"
            />
            <div
              onClick={togglePasswordVisibility}
              className="absolute top-0 bottom-0 right-4 flex items-center my-2 text-lg cursor-pointer"
            >
              {passwordShown ? <FiEyeOff /> : <FiEye />}
            </div>
          </div>
          <p className="text-red-500 text-sm">{errors.password?.message}</p>

          <div className="relative w-full">
            <input
              {...register("confirm_password")}
              type={passwordShown ? "text" : "password"}
              className={`h-12 p-4 pl-4 pr-10 rounded-full text-normal text-black w-full ring-2 ${
                errors.confirm_password ? "ring-red-500" : "ring-blue-700"
              }`}
              autoComplete="off"
              placeholder="Conferma Password"
            />
            <div
              onClick={togglePasswordVisibility}
              className="absolute top-0 bottom-0 right-4 flex items-center my-2 text-lg cursor-pointer"
            >
              {passwordShown ? <FiEyeOff /> : <FiEye />}
            </div>
          </div>
          <p className="text-red-500 text-sm">
            {errors.confirm_password?.message}
          </p>

          <button
            type="submit"
            className="h-12 p-2 w-full rounded-full text-normal bg-green-500 hover:opacity-70 text-white"
          >
            Reimposta password
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateNewPassword;
