import React from "react";
import Layout from "../../../components/admin/sharedComponent/Layout";
import VisualizzaVoceComponent from "../../../components/admin/dashboard/computi-metrici/VisualizzaVoce";

const VisualizzaVoce = () => {
  return (
    <Layout>
      <VisualizzaVoceComponent />
    </Layout>
  );
};

export default VisualizzaVoce;
