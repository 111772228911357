import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import RecurringTax from '../../../components/admin/impostazioni/TasseRicorrenti'

const TasseRicorrenti = () => {
  return (
    <Layout>
      <RecurringTax />
    </Layout>
  )
}

export default TasseRicorrenti