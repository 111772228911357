import React, { useState, useEffect } from "react";
import {
  FaDesktop,
  FaWallet,
  FaGlobe,
  FaFile,
  FaShoppingCart,
  FaCircle,
  FaUser,
  FaAngleLeft,
  FaAngleRight,
  FaChartBar,
  FaMoneyBillWave,
  FaHardHat,
  FaList,
  FaComments,
  FaChevronRight
} from "react-icons/fa";
import { fetchDashboard } from "../../../apis/Dashboard";
import { getConstructionSites } from "../../../apis/ConstructionSite";
import { ScrollArea } from "../../ui/scroll-area";

import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { Button } from "../../ui/button";
import { Progress } from "../../ui/progress";
import {
  Area,
  AreaChart,
  Pie,
  PieChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  LineChart,
  Line,
  CartesianGrid
} from "recharts";
import { Separator } from "../../ui/separator";
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar";
import Spinner from "../../Spinner";
import { Link, useNavigate } from "react-router-dom";

// Safe number formatting function that handles undefined/null values
const formatNumber = (num) => {
  if (num === undefined || num === null) return "0,00";
  return typeof num === "number"
    ? new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(num)
    : "0,00";
};

// Safe array function to prevent mapping errors
const safeArray = (arr) => (Array.isArray(arr) ? arr : []);

const DashBoard = () => {
  const navigate = useNavigate();
  const initialData = {
    cashFlow: 0,
    totalClients: 0,
    totalSuppliers: 0,
    totalConstructionSites: 0,
    sentQuotes: 0,
    stats: {
      week: { quotes: 0, inProgress: 0, sent: 0 },
      month: { quotes: 0, inProgress: 0, sent: 0 },
      day: { quotes: 0, inProgress: 0, sent: 0 }
    },
    siteTypes: [],
    projectStatus: [],
    movimentiData: [],
    preventiviData: [],
    recentChats: [],
    ongoingQuotes: []
  };

  const [data, setData] = useState(initialData);
  const [suppliers, setSuppliers] = useState([]);
  const [constructionSites, setConstructionSites] = useState([]);
  const [totalCreditFromCustomers, setTotalCreditFromCustomers] = useState(0);
  const [rateizziStats, setRateizziStats] = useState({
    inCorso: 0,
    totalAmount: 0,
    upcomingPayments: 0,
    totalInstallments: 0,
    unpaidInstallments: 0,
    paidInstallments: 0
  });
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Get user from localStorage with proper error handling
        let userData = null;
        try {
          const userString = localStorage.getItem("user");
          if (userString) {
            userData = JSON.parse(userString);
            setUser(userData);
          }
        } catch (userError) {
          console.warn("Error parsing user data:", userError);
        }

        // Fetch all dashboard data
        const response = await fetchDashboard();
        const dashboardData = response.data || {};

        // Fetch construction sites data
        try {
          const sitesResponse = await getConstructionSites();
          //console.log("sitesResponse", sitesResponse);
          const sites = sitesResponse.data?.sites;
          if (sitesResponse.data && Array.isArray(sites)) {
            setConstructionSites(sites);

            // Calculate total credit from customers (remaining payments)
            let totalCredit = 0;

            // For each construction site, calculate remaining amount to be paid
            sites.forEach((site) => {
              // Get the total contract amount
              const contractAmount = parseFloat(site.amount) || 0;

              // Sum up all payments made
              const totalPaid = Array.isArray(site.progressStatuses)
                ? site.progressStatuses.reduce((sum, payment) => {
                    return sum + (parseFloat(payment.amount) || 0);
                  }, 0)
                : 0;

              // Calculate remaining amount for this site
              const remainingAmount = contractAmount - totalPaid;

              // Add to total if it's a positive value
              if (remainingAmount > 0) {
                totalCredit += remainingAmount;
              }
            });

            setTotalCreditFromCustomers(totalCredit);
          }
        } catch (sitesError) {
          console.error("Error fetching construction sites:", sitesError);
        }

        // Set suppliers with safe default and make sure we have proper accounting data
        const suppliersWithBalances = Array.isArray(dashboardData.suppliers)
          ? dashboardData.suppliers.map((supplier) => ({
              ...supplier,
              // Make sure accountingMovements is an array
              accountingMovements: Array.isArray(supplier.accountingMovements)
                ? supplier.accountingMovements
                : []
            }))
          : [];

        setSuppliers(suppliersWithBalances);

        // Set rateizzi stats with safe access
        setRateizziStats({
          inCorso: dashboardData.rateizziStats?.inCorso || 0,
          totalAmount: dashboardData.rateizziStats?.totalAmount || 0,
          upcomingPayments: dashboardData.rateizziStats?.upcomingPayments || 0,
          totalInstallments:
            dashboardData.rateizziStats?.totalInstallments || 0,
          unpaidInstallments:
            dashboardData.rateizziStats?.unpaidInstallments || 0,
          paidInstallments: dashboardData.rateizziStats?.paidInstallments || 0
        });

        // Set main dashboard data with defensive coding
        setData({
          cashFlow: dashboardData.cashFlow || 0,
          totalClients: dashboardData.totalClients || 0,
          totalSuppliers: dashboardData.totalSuppliers || 0,
          totalConstructionSites: dashboardData.totalConstructionSites || 0,
          sentQuotes: dashboardData.sentQuotes || 0,
          stats: dashboardData.stats || {
            week: { quotes: 0, inProgress: 0, sent: 0 },
            month: { quotes: 0, inProgress: 0, sent: 0 },
            day: { quotes: 0, inProgress: 0, sent: 0 }
          },
          siteTypes: Array.isArray(dashboardData.siteTypes)
            ? dashboardData.siteTypes
            : [],
          projectStatus: Array.isArray(dashboardData.projectStatus)
            ? dashboardData.projectStatus
            : [],
          movimentiData: Array.isArray(dashboardData.movimentiData)
            ? dashboardData.movimentiData
            : [],
          preventiviData: Array.isArray(dashboardData.preventiviData)
            ? dashboardData.preventiviData
            : [],
          recentChats: Array.isArray(dashboardData.recentChats)
            ? dashboardData.recentChats
            : [],
          ongoingQuotes: Array.isArray(dashboardData.ongoingQuotes)
            ? dashboardData.ongoingQuotes
            : []
        });

        setIsDataReady(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(
          `An error occurred while loading the dashboard: ${
            error.message || "Unknown error"
          }`
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Helper function to calculate supplier debt (moved outside of component rendering)
  const calculateSupplierDebt = (accountingMovements) => {
    if (
      !Array.isArray(accountingMovements) ||
      accountingMovements.length === 0
    ) {
      return 0;
    }

    return accountingMovements.reduce((total, movement) => {
      const amount = Number.parseFloat(movement.amount) || 0;
      return movement.type === "debito" ? total + amount : total - amount;
    }, 0);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload || !payload.length) return null;

    // For bank movements, show the €1.468,97 style value
    if (payload[0]?.name === "Entra" || payload[0]?.name === "Uscita") {
      const total = payload.reduce((sum, entry) => sum + (entry.value || 0), 0);
      return (
        <div className="bg-[#292F36] p-2 rounded shadow text-white">
          <p className="font-semibold text-center">€{formatNumber(total)}</p>
        </div>
      );
    }

    // For preventivi, show the simple number style (like "5")
    if (payload[0]?.name === "count") {
      return (
        <div className="bg-[#292F36] p-2 rounded shadow text-white">
          <p className="font-semibold text-center">{payload[0].value}</p>
        </div>
      );
    }

    // Default tooltip for other chart types
    return (
      <div className="bg-white p-2 border border-gray-300 rounded shadow">
        <p className="font-semibold">{`${label || ""}`}</p>
        {payload.map((entry, index) => (
          <p key={index} style={{ color: entry.color }}>
            {`${entry.name || ""}: ${entry.value} ${
              entry.name === "Da pagare" || entry.name === "Pagate"
                ? "rate"
                : ""
            }`}
          </p>
        ))}
      </div>
    );
  };

  const EmptyState = ({ message }) => (
    <div className="flex flex-col items-start justify-start h-full ">
      <FaChartBar className="text-gray-200 mb-2" size={32} />
      <p className="text-gray-400 text-sm">{message}</p>
    </div>
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <div className="p-6 text-red-500">{error}</div>;
  }

  // Chart colors to match the design
  const COLORS = [
    "#4682B4",
    "#87CEEB",
    "#B0E0E6",
    "#292F36",
    "#E9D8FD",
    "#ECC94B"
  ];

  return (
    <div className="mx-auto p-4 bg-white pb-20">
      {/* Header Section */}
      <div className="mb-8">
        <h1 className="text-xl font-bold mb-1">
          Ciao {user?.username || "EclarKhalid"}, felice di rivederti!
        </h1>
        <p className="text-gray-500 text-sm">
          Rivedi le tue attività, aggiorna le informazioni e mantieni tutto
          organizzato al meglio.
        </p>
      </div>

      {/* Key metrics - 2 columns on small screens, 5 on large */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 mb-8">
        <KeyMetric
          value={`€${formatNumber(data.cashFlow)}`}
          label="Flusso di cassa"
        />
        <KeyMetric
          value={data.totalConstructionSites}
          label="Totale progetti in corso"
        />
        <KeyMetric
          value={`€${formatNumber(
            suppliers.reduce(
              (sum, supplier) =>
                sum + calculateSupplierDebt(supplier.accountingMovements),
              0
            )
          )}`}
          label="Debito con i fornitori"
        />
        <KeyMetric
          value={`€${formatNumber(totalCreditFromCustomers)}`} // Updated to use totalCreditFromCustomers
          label="Credito con i clienti"
        />
        <KeyMetric
          value={`€${formatNumber(rateizziStats.totalAmount)}`}
          label="Totale rateizzi da pagare"
        />
      </div>

      {/* Main Dashboard Grid - Adjust for mobile but keep structure */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-12 mb-20">
        {/* Bank movements chart */}
        <div className="md:col-span-1 lg:col-span-6">
          <h2 className="mb-3 font-semibold text-lg">Movimenti bancari</h2>
          <div className="flex items-center space-x-6 mb-3">
            <LegendItem color="#4682B4" label="Entrate" />
            <LegendItem color="#292F36" label="Uscite" />
          </div>
          <div className="bg-white rounded-lg">
            <div className="relative h-[300px]">
              {safeArray(data.movimentiData).length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={data.movimentiData}
                    margin={{ top: 5, right: 5, left: 0, bottom: 5 }}
                  >
                    <XAxis
                      dataKey="date"
                      axisLine={false}
                      tickLine={false}
                      tick={{ fontSize: 10 }}
                    />
                    <YAxis
                      axisLine={false}
                      tickLine={false}
                      tick={{ fontSize: 10 }}
                      width={40}
                      tickFormatter={(value) => (value === 0 ? "0" : value)}
                    />
                    <Tooltip
                      content={<CustomTooltip />}
                      cursor={false}
                      wrapperStyle={{ zIndex: 100 }}
                    />
                    <CartesianGrid
                      horizontal={true}
                      vertical={false}
                      stroke="#EEEEEE"
                      strokeDasharray="5 5"
                    />
                    <Line
                      type="monotone"
                      dataKey="Entra.total"
                      name="Entra"
                      stroke="#4682B4"
                      strokeWidth={3}
                      dot={false}
                      activeDot={{ r: 6 }}
                    />
                    <Line
                      type="monotone"
                      dataKey="Uscita.total"
                      name="Uscita"
                      stroke="#292F36"
                      strokeWidth={3}
                      dot={false}
                      activeDot={{ r: 6 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <EmptyState message="Nessun dato disponibile per i movimenti" />
              )}
            </div>
          </div>
        </div>

        {/* Debts with suppliers */}
        <div className="md:col-span-1 lg:col-span-2">
          <h2 className="mb-3 font-semibold text-lg">Debiti con i fornitori</h2>
          <div className="bg-white rounded-lg md:h-[300px] overflow-hidden">
            {safeArray(suppliers).length > 0 ? (
              safeArray(suppliers)
                .slice(0, 4)
                .map((supplier, index) => {
                  const debtAmount = calculateSupplierDebt(
                    supplier.accountingMovements
                  );
                  return (
                    <SupplierDebtItem
                      key={supplier._id || index}
                      amount={`€${formatNumber(debtAmount)}`}
                      supplier={
                        supplier.businessName ||
                        supplier.name ||
                        `Fornitore ${index + 1}`
                      }
                      isLast={
                        index === safeArray(suppliers).slice(0, 4).length - 1
                      }
                      onClick={() =>
                        navigate(`/suppliers/details/${supplier._id}`)
                      }
                    />
                  );
                })
            ) : (
              <EmptyState message="Nessun fornitore disponibile" />
            )}
          </div>
        </div>

        {/* Installments situation */}
        <div className="md:col-span-1 lg:col-span-2">
          <h2 className="mb-3 font-semibold text-lg">Situazione rateizzi</h2>
          <div className="bg-white rounded-lg md:h-[300px]">
            <div className="text-start mb-1 pt-3 px-3">
              <div className="text-sm">
                {rateizziStats.upcomingPayments} rate da pagare
              </div>
              <div className="text-xs text-gray-500">nei prossimi 7 giorni</div>
            </div>
            <div className="relative h-[220px] flex items-center justify-center">
              <div className="absolute inset-0">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={[
                        {
                          name: "Da pagare",
                          value: rateizziStats.unpaidInstallments || 1
                        },
                        {
                          name: "Pagate",
                          value: rateizziStats.paidInstallments || 1
                        }
                      ]}
                      cx="50%"
                      cy="45%" // Moved up from 50% to 45%
                      innerRadius={50}
                      outerRadius={70}
                      fill="#E9D8FD"
                      paddingAngle={0}
                      dataKey="value"
                      animationDuration={750}
                    >
                      <Cell key="cell-0" fill="#4682B4" />
                      <Cell key="cell-1" fill="#292F36" />
                    </Pie>
                    <Tooltip
                      content={<CustomTooltip />}
                      cursor={false}
                      wrapperStyle={{
                        zIndex: 1000,
                        position: "absolute",
                        top: "0px"
                      }}
                      offset={-10} // Moves tooltip upward
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className="z-10 text-center ">
                {" "}
                {/* Moved text slightly down */}
                <div className="text-xs font-medium">Divisione</div>
                <div className="text-xs font-medium">rate</div>
              </div>
            </div>
            <div className="flex justify-center mt-1 space-x-4">
              <LegendItem color="#4682B4" label="Da pagare" />
              <LegendItem color="#292F36" label="Pagate" />
            </div>
          </div>
        </div>
        {/* Chat recenti */}
        <div className="md:col-span-1 lg:col-span-2">
          <h2 className="mb-3 font-semibold text-lg">Chat recenti</h2>
          <div className="bg-white rounded-lg md:h-[200px] p-3">
            <div className="space-y-3">
              {safeArray(data.recentChats).length > 0 ? (
                // Improved chat rendering with defensive coding
                safeArray(data.recentChats).map((chat) => {
                  // Safe access to participants
                  const participants = Array.isArray(chat.participants)
                    ? chat.participants
                    : [];

                  // Find the other participant (not the current user)
                  const otherParticipant =
                    participants.find((p) => p?.user?._id !== user?._id)
                      ?.user || {};

                  // Get the latest message
                  const messages = Array.isArray(chat.messages)
                    ? chat.messages
                    : [];
                  const latestMessage =
                    messages.length > 0 ? messages[0] : null;

                  return (
                    <div
                      key={chat._id || Math.random()}
                      className="flex items-center space-x-3 cursor-pointer hover:bg-gray-50 p-1 rounded"
                      onClick={() => navigate(`/chat`)}
                    >
                      <Avatar className="h-8 w-8">
                        <AvatarImage src={otherParticipant.avatar || ""} />
                        <AvatarFallback>
                          {otherParticipant.name
                            ? otherParticipant.name
                                .substring(0, 2)
                                .toUpperCase()
                            : otherParticipant.username
                            ? otherParticipant.username
                                .substring(0, 2)
                                .toUpperCase()
                            : "UN"}
                        </AvatarFallback>
                      </Avatar>
                      <div className="overflow-hidden">
                        <div className="text-sm font-medium truncate">
                          {otherParticipant.name ||
                            otherParticipant.username ||
                            "Utente"}
                        </div>
                        <div className="text-xs text-gray-500 truncate">
                          {latestMessage?.content || "Nessun messaggio"}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <EmptyState message="Nessuna chat recente" />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Bottom section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 gap-6">
        {/* Quotes issued */}
        <div className="sm:col-span-1 lg:col-span-3">
          <h2 className="mb-3 font-semibold text-lg">Preventivi emessi</h2>
          <div className="bg-white rounded-lg">
            <div className="relative h-[240px]">
              {safeArray(data.preventiviData).length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={data.preventiviData}
                    margin={{ top: 5, right: 5, left: 0, bottom: 5 }}
                  >
                    <XAxis
                      dataKey="month"
                      axisLine={false}
                      tickLine={false}
                      tick={{ fontSize: 10 }}
                    />
                    <YAxis
                      axisLine={false}
                      tickLine={false}
                      tick={{ fontSize: 10 }}
                      width={30}
                      tickFormatter={(value) => (value === 0 ? "0" : value)}
                    />
                    <CartesianGrid
                      horizontal={true}
                      vertical={false}
                      stroke="#EEEEEE"
                      strokeDasharray="5 5"
                    />
                    <Tooltip
                      content={<CustomTooltip />}
                      cursor={false}
                      wrapperStyle={{ zIndex: 100 }}
                    />
                    <Line
                      type="monotone"
                      dataKey="count"
                      stroke="#292F36"
                      strokeWidth={3}
                      dot={false}
                      activeDot={{ r: 6 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <EmptyState message="Nessun dato disponibile per i preventivi" />
              )}
            </div>
          </div>
        </div>

        {/* Ongoing quotes */}
        <div className="sm:col-span-1 lg:col-span-3">
          <h2 className="mb-3 font-semibold text-lg">Preventivi in corso</h2>
          <div className="bg-white rounded-lg">
            {safeArray(data.ongoingQuotes).length > 0 ? (
              safeArray(data.ongoingQuotes).map((quote, index) => {
                // First try to find client in populated form
                let clientName = "Cliente";

                // Check if data.clients exists and try to find the client by ID
                if (typeof quote.clienti === "string") {
                  // Try to find client from clients list if available
                  const foundClient = data.clients?.find(
                    (c) => c._id === quote.clienti
                  );
                  if (foundClient) {
                    clientName =
                      foundClient.fullName ||
                      foundClient.companyName ||
                      `Cliente ${index + 1}`;
                  } else {
                    clientName = `Cliente ${index + 1}`;
                  }
                } else if (
                  quote.clienti &&
                  (quote.clienti.fullName || quote.clienti.companyName)
                ) {
                  // If clienti is already populated as an object
                  clientName =
                    quote.clienti.fullName || quote.clienti.companyName;
                } else {
                  clientName = `Cliente ${index + 1}`;
                }

                return (
                  <OngoingQuoteItem
                    key={quote._id || index}
                    id={quote._id}
                    title={quote.ogguto || `Preventivo ${index + 1}`}
                    client={clientName}
                    isLast={index === safeArray(data.ongoingQuotes).length - 1}
                  />
                );
              })
            ) : (
              <EmptyState message="Nessun preventivo in corso" />
            )}
          </div>
        </div>

        {/* Construction types */}
        <div className="sm:col-span-1 lg:col-span-3">
          <h2 className="mb-3 font-semibold text-lg">Tipologia cantieri</h2>
          <div className="bg-white rounded-lg md:h-[300px]">
            <div className="flex flex-row h-[220px]">
              {/* Pie chart on the left */}
              <div className="relative flex-1 flex items-center justify-center">
                <div className="absolute inset-0">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={
                          safeArray(data.siteTypes).length > 0
                            ? data.siteTypes
                            : [{ name: "Altri tipi", value: 100 }]
                        }
                        cx="50%"
                        cy="50%"
                        innerRadius={50}
                        outerRadius={70}
                        paddingAngle={5}
                        dataKey="value"
                      >
                        {(safeArray(data.siteTypes).length > 0
                          ? data.siteTypes
                          : [{ name: "Altri tipi", value: 100 }]
                        ).map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className="z-10 text-center">
                  <div className="text-xl font-medium">
                    {data.totalConstructionSites || 0}
                  </div>
                  <div className="text-xs text-gray-500">cantieri</div>
                </div>
              </div>

              {/* Type list on the right */}
              <div className="flex-1 flex flex-col justify-center px-3">
                <div className="space-y-3">
                  {safeArray(data.siteTypes).length > 0 ? (
                    safeArray(data.siteTypes)
                      .slice(0, 6)
                      .map((type, index) => (
                        <div
                          key={type.name || index}
                          className="flex items-center"
                        >
                          <div
                            className="w-3 h-3 rounded-full mr-2"
                            style={{
                              backgroundColor: COLORS[index % COLORS.length]
                            }}
                          ></div>
                          <span className="text-xs truncate">
                            {type.name || `Tipo ${index + 1}`}
                          </span>
                        </div>
                      ))
                  ) : (
                    <div className="flex items-center">
                      <div
                        className="w-3 h-3 rounded-full mr-2"
                        style={{ backgroundColor: COLORS[0] }}
                      ></div>
                      <span className="text-xs text-gray-500">
                        Nessun dato disponibile
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Construction sites situation */}
        <div className="sm:col-span-1 lg:col-span-3">
          <h2 className="mb-3 font-semibold text-lg">Situazione cantieri</h2>
          <div className="bg-white rounded-lg">
            <div className="space-y-4">
              {safeArray(data.projectStatus).length > 0 ? (
                safeArray(data.projectStatus)
                  .slice(0, 3)
                  .map((project, index) => (
                    <ConstructionSiteItem
                      key={index}
                      title={project.name || `Progetto ${index + 1}`}
                      progress={project.progress || 0}
                      description={
                        project.description || "Nessuna descrizione disponibile"
                      }
                    />
                  ))
              ) : (
                <EmptyState message="Nessun cantiere disponibile" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const KeyMetric = ({ value, label }) => (
  <div className="space-y-1">
    <div className="text-xl font-bold truncate">{value}</div>
    <div className="text-sm text-gray-500">{label}</div>
  </div>
);

const LegendItem = ({ color, label }) => (
  <div className="flex items-center">
    <div
      className="w-2 h-2 rounded-full mr-2"
      style={{ backgroundColor: color }}
    ></div>
    <span className="text-xs text-gray-500 truncate">{label}</span>
  </div>
);

const SupplierDebtItem = ({ amount, supplier, isLast, onClick }) => (
  <div
    className={`flex items-center justify-between p-3 hover:bg-gray-50 cursor-pointer ${
      isLast ? "rounded-b-lg" : "border-b border-gray-100"
    }`}
    onClick={onClick}
  >
    <div className="flex items-center overflow-hidden">
      <div className="w-1 h-10 bg-[#4682B4] rounded-full mr-3 flex-shrink-0"></div>
      <div className="min-w-0">
        <div className="font-medium truncate">{amount}</div>
        <div className="text-xs text-gray-500 truncate">{supplier}</div>
      </div>
    </div>
    <div className="flex items-center flex-shrink-0">
      <FaChevronRight className="h-4 w-4 text-gray-400" />
    </div>
  </div>
);

const OngoingQuoteItem = ({ id, title, client, isLast }) => (
  <div
    className={`flex items-center justify-between p-2 hover:bg-gray-50 cursor-pointer ${
      isLast ? "rounded-b-lg" : "border-b border-gray-100"
    }`}
    onClick={() => {
      if (id) window.location.href = `/preventivi_edit/${id}`;
    }}
  >
    <div className="flex items-center overflow-hidden">
      <div className="w-1 h-10 bg-[#4682B4] rounded-full mr-3 flex-shrink-0"></div>
      <div className="min-w-0">
        <div className="text-sm font-medium truncate">{title}</div>
        <div className="text-xs text-gray-500 truncate">{client}</div>
      </div>
    </div>
    <div className="flex items-center flex-shrink-0">
      <FaChevronRight className="h-4 w-4 text-gray-400" />
    </div>
  </div>
);

const ConstructionSiteItem = ({ title, progress, description }) => (
  <div className="p-1">
    <div className="flex justify-between mb-1">
      <div className="text-sm font-medium truncate" style={{ maxWidth: "70%" }}>
        {title}
      </div>
      <div className="text-sm font-medium">{progress}%</div>
    </div>
    <div className="text-xs text-gray-500 mb-1 truncate">{description}</div>
    <Progress value={progress} className="h-1.5 bg-gray-100" />
  </div>
);

export default DashBoard;
