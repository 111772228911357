import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../../ui/tabs";
import { toast } from "react-toastify";
import { ChevronLeft, ChevronRight } from "lucide-react";
import AllEmployees from "./allEmployess/AllEmployees";
import Training from "./training";
import Absence from "./absence";
import Salary from "./salary";
import Planning from "./planning";
import AttendanceSheet from "./attendanceSheet/index.jsx";
import { Employees } from "../../../../apis/Employees";
import MedicalVisits from "./medicalVisists";
import Spinner from "../../../Spinner";
import { Button } from "../../../ui/button";

const PERSONAL_TAB = [
  { name: "Tutti i dipendenti", value: "tutti-i-dipendenti" },
  { name: "Foglio presenze", value: "foglio-presenze" },
  { name: "Assenze", value: "assenze" },
  { name: "Visite mediche", value: "visite-mediche" },
  { name: "Formazione", value: "formazione" },
  { name: "Retribuzione", value: "retribuzione" },
  { name: "Pianificazione", value: "pianificazione" }
];

const ScrollableTabsList = ({ children }) => {
  const scrollRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
        setShowLeftArrow(scrollLeft > 0);
        setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1);
      }
    };

    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
      handleScroll();

      const resizeObserver = new ResizeObserver(handleScroll);
      resizeObserver.observe(scrollElement);

      return () => {
        scrollElement.removeEventListener("scroll", handleScroll);
        resizeObserver.disconnect();
      };
    }
  }, []);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = direction === "left" ? -100 : 100;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <div className="relative border-b border-gray-200">
      {showLeftArrow && (
        <button
          onClick={() => scroll("left")}
          className="absolute left-0 top-1/2 -translate-y-1/2 bg-white shadow-md rounded-md p-1 z-10"
        >
          <ChevronLeft size={20} className="text-gray-600" />
        </button>
      )}
      <div ref={scrollRef} className="overflow-x-auto scrollbar-hide">
        {children}
      </div>
      {showRightArrow && (
        <button
          onClick={() => scroll("right")}
          className="absolute right-0 top-1/2 -translate-y-1/2 bg-white shadow-md rounded-md p-1 z-10"
        >
          <ChevronRight size={20} className="text-gray-600" />
        </button>
      )}
    </div>
  );
};

const EmployeeComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("tutti-i-dipendenti");
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeTransformData, setEmployeeTransformData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isDelete, setIsDelete] = useState(false);
  const [dataUpdate, setDataUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;

  const getCompanyId = () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user?.companyId) {
        throw new Error("Company ID not found");
      }
      return user.companyId;
    } catch (error) {
      console.error("Error getting company ID:", error);
      toast.error("Session error. Please log in again.");
      navigate("/login");
      return null;
    }
  };

  const fetchEmployees = async (retry = 0) => {
    const companyId = getCompanyId();
    if (!companyId) return;

    setIsLoading(true);
    setError(null);

    try {
      const response = await Employees(
        companyId,
        currentPage,
        limit,
        searchQuery
      );

      if (response?.status === 200 && response.data) {
        const employees = response.data.employees || [];
        setEmployeeData(employees);
        setTotalPage(response.data.totalPages || 0);
        setCurrentPage(response.data.currentPage || 1);

        const dataTransform = employees.map((employee) => ({
          employeeId: employee._id,
          name: employee.firstName,
          sureName: employee.sureName,
          freshman: employee.freshman
        }));
        setEmployeeTransformData(dataTransform);
        setRetryCount(0);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
      
      if (error?.status === 401 || error?.status === 403) {
        toast.error("Session expired. Please log in again.");
        navigate("/login");
        return;
      }

      if (retry < maxRetries) {
        setRetryCount(retry + 1);
        const delay = Math.min(1000 * Math.pow(2, retry), 8000);
        setTimeout(() => fetchEmployees(retry + 1), delay);
        return;
      }

      setError(error?.data?.message || "Failed to fetch employees");
      toast.error("Failed to fetch employees. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    const tab = PERSONAL_TAB.find((t) => t.value === path);
    if (tab) {
      setActiveTab(tab.value);
    } else {
      navigate("/employees/tutti-i-dipendenti", { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (getCompanyId()) {
      fetchEmployees();
    }
  }, [searchQuery, isDelete, dataUpdate, currentPage, limit]);

  const handleTabChange = (value) => {
    navigate(`/employees/${value}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRetry = () => {
    setRetryCount(0);
    fetchEmployees();
  };

  if (!getCompanyId()) {
    return null;
  }

  if (isLoading && !employeeData.length) {
    return (
      <Spinner />
    );
  }

  if (error && !employeeData.length) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-600 mb-4">{error}</p>
          <Button
            onClick={handleRetry}
          >
            Riprova
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full md:p-6 p-2 pt-1 pb-7">
      <Tabs
        value={activeTab}
        onValueChange={handleTabChange}
        className="w-full"
      >
        <ScrollableTabsList>
          <TabsList className="flex justify-start h-auto p-0 bg-transparent space-x-8">
            {PERSONAL_TAB.map((tab) => (
              <TabsTrigger
                key={tab.value}
                value={tab.value}
                className="py-4 px-1 text-sm font-medium text-gray-500 hover:text-gray-700 relative data-[state=active]:text-slate-600 data-[state=active]:font-medium bg-transparent before:absolute before:h-0.5 before:w-full before:bg-slate-700 before:bottom-0 before:left-0 before:scale-x-0 data-[state=active]:before:scale-x-100 before:transition-transform whitespace-nowrap"
              >
                {tab.name}
              </TabsTrigger>
            ))}
          </TabsList>
        </ScrollableTabsList>
        <div className="py-4">
          <TabsContent value="tutti-i-dipendenti">
            <AllEmployees
              setSearchQuery={setSearchQuery}
              employeeData={employeeData}
              setIsDelete={setIsDelete}
              isDelete={isDelete}
              currentPage={currentPage}
              setLimit={setLimit}
              totalPages={totalPages}
              limit={limit}
              handlePageChange={handlePageChange}
              setCurrentPage={setCurrentPage}
              setDataUpdate={setDataUpdate}
              dataUpdate={dataUpdate}
              isLoading={isLoading}
              error={error}
              onRetry={handleRetry}
            />
          </TabsContent>
          <TabsContent value="foglio-presenze">
            <AttendanceSheet employeeData={employeeData} />
          </TabsContent>
          <TabsContent value="assenze">
            <Absence employeeData={employeeTransformData} />
          </TabsContent>
          <TabsContent value="formazione">
            <Training employeeData={employeeTransformData} />
          </TabsContent>
          <TabsContent value="visite-mediche">
            <MedicalVisits employeeData={employeeTransformData} />
          </TabsContent>
          <TabsContent value="retribuzione">
            <Salary companyId={getCompanyId()} employeeData={employeeData} />
          </TabsContent>
          <TabsContent value="pianificazione">
            <Planning employeeData={employeeTransformData} />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  );
};

export default EmployeeComponent;