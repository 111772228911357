import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getContabilita,
  getAccountingItems,
  addAccountingItem,
  updateAccountingItem,
  deleteAccountingItem,
  getConstructionSite
} from "../../../apis/ConstructionSite";
import { fetchUms } from "../../../apis/ComputimetricAddrecSumm";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import {
  EnhancedTable,
  TableHeader,
  TableBody,
  TableCell,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import { Loader2, Plus, Trash2, Edit, Filter, Archive, AlertCircle } from "lucide-react";
import ConstructionSiteNavbar from "./ConstructionSiteNavbar";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../ui/tooltip";
import { DropdownMenuItem } from "../../ui/dropdown-menu";

const categories = [
  { id: "materiali", name: "Materiali" },
  { id: "manodopera", name: "Manodopera" },
  { id: "attrezzatura", name: "Attrezzatura" }
];

const quantityRanges = [
  { label: "0-50", min: 0, max: 50 },
  { label: "51-100", min: 51, max: 100 },
  { label: "101-500", min: 101, max: 500 },
  { label: "500+", min: 501, max: Infinity }
];

const priceRanges = [
  { label: "€0-€100", min: 0, max: 100 },
  { label: "€101-€500", min: 101, max: 500 },
  { label: "€501-€1000", min: 501, max: 1000 },
  { label: "€1000+", min: 1001, max: Infinity }
];

const Accounting = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [totalWorkCost, setTotalWorkCost] = useState(0);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [ums, setUms] = useState([]);
  const [filters, setFilters] = useState({
    dateFrom: "",
    dateTo: "",
    category: "all",
    quantity: "all",
    price: "all"
  });
  const [newItem, setNewItem] = useState({
    date: new Date().toISOString().split("T")[0],
    category: "",
    name: "",
    description: "",
    unit: "",
    quantity: 0,
    price: 0,
    total: 0
  });
  const [isArchived, setIsArchived] = useState(false);
  const [budget, setBudget] = useState(0);
  
  // Added states for EnhancedTable
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    applyFiltersAndSearch();
  }, [items, searchTerm, filters]);

  // Apply sorting when sortConfig changes
  useEffect(() => {
    if (sortConfig.key && filteredItems.length > 0) {
      const sortedData = [...filteredItems].sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Handle undefined or null values (place them at the end)
        if (aValue === undefined || aValue === null) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        if (bValue === undefined || bValue === null) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }

        // Handle string comparison
        if (typeof aValue === "string") {
          aValue = aValue.toLowerCase();
          bValue = typeof bValue === "string" ? bValue.toLowerCase() : String(bValue).toLowerCase();
        } else if (typeof bValue === "string") {
          bValue = bValue.toLowerCase();
          aValue = String(aValue).toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });

      setFilteredItems(sortedData);
    }
  }, [sortConfig]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const umData = await fetchUms();
        setUms(umData?.um || []);
        fetchData();
      } catch (error) {
        console.error("Error fetching UMs:", error);
      }
    };

    initialize();
  }, [id]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const [contabilitaData, accountingData, siteData] = await Promise.all([
        getContabilita(id),
        getAccountingItems(id),
        getConstructionSite(id)
      ]);

      setRemainingAmount(contabilitaData.amountPaid || 0);
      setItems(accountingData.items || []);
      setTotalWorkCost(accountingData.workCost || 0);
      const siteBudget = siteData.data?.budget || 0;
      setBudget(siteBudget);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to check if an item is imported from attendance
  const isAttendanceImported = (item) => {
    // Based on backend logic, items created from attendance have these characteristics:
    // - Category is always 'manodopera' (labor)
    // - Unit is typically 'Ora' (hour)
    // - The name should match an employee name format (contains a space for first and last name)
    // - They don't have specific sources or flags (yet) in the backend
    
    // More precise detection based on how attendance creates accounting items
    return item.category === "manodopera" && 
           item.unit === "Ora" && 
           /\s/.test(item.name); // Name contains a space (indicating first and last name)
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR"
    }).format(amount);
  };

  const applyFiltersAndSearch = () => {
    let result = [...items];

    if (filters.dateFrom || filters.dateTo) {
      result = result.filter((item) => {
        const itemDate = new Date(item.date);
        itemDate.setHours(0, 0, 0, 0);

        if (filters.dateFrom && filters.dateTo) {
          const startDate = new Date(filters.dateFrom);
          const endDate = new Date(filters.dateTo);
          endDate.setHours(23, 59, 59, 999);
          return itemDate >= startDate && itemDate <= endDate;
        } else if (filters.dateFrom) {
          const startDate = new Date(filters.dateFrom);
          return itemDate >= startDate;
        } else if (filters.dateTo) {
          const endDate = new Date(filters.dateTo);
          endDate.setHours(23, 59, 59, 999);
          return itemDate <= endDate;
        }
        return true;
      });
    }

    // Apply search
    if (searchTerm) {
      result = result.filter(
        (item) =>
          item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.description?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply category filter
    if (filters.category !== "all") {
      result = result.filter((item) => item.category === filters.category);
    }

    // Apply quantity filter
    if (filters.quantity !== "all") {
      const [min, max] = filters.quantity.split("-").map(Number);
      result = result.filter((item) => {
        const quantity = Number(item.quantity);
        return quantity >= min && (max === Infinity ? true : quantity <= max);
      });
    }

    // Apply price filter
    if (filters.price !== "all") {
      const [min, max] = filters.price.split("-").map(Number);
      result = result.filter((item) => {
        const price = Number(item.price);
        return price >= min && (max === Infinity ? true : price <= max);
      });
    }

    setFilteredItems(result);
  };

  const handleFilterChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const resetFilters = () => {
    setFilters({
      category: "all",
      quantity: "all",
      price: "all"
    });
    setSearchTerm("");
    setIsFilterDialogOpen(false);
  };

  const areFiltersActive = () => {
    return (
      filters.category !== "all" ||
      filters.quantity !== "all" ||
      filters.price !== "all" ||
      searchTerm !== ""
    );
  };

  useEffect(() => {
    fetchSiteStatus();
    fetchData();
  }, [id]);

  const fetchSiteStatus = async () => {
    setIsLoading(true);
    try {
      const response = await getConstructionSite(id);
      setIsArchived(response.data.status === "Archiviato");
    } catch (error) {
      console.error("Error fetching site status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddItem = async () => {
    if (isArchived) return;
    try {
      await addAccountingItem(id, newItem);
      await fetchData();
      setNewItem({
        date: new Date().toISOString().split("T")[0],
        category: "",
        name: "",
        description: "",
        unit: "",
        quantity: 0,
        price: 0,
        total: 0
      });
      setIsAddModalOpen(false);
    } catch (error) {
      console.error("Error adding item:", error);
    }
  };

  const handleEditClick = (item) => {
    if (isArchived || isAttendanceImported(item)) return;
    
    const editItem = {
      _id: item._id,
      date: item.date ? new Date(item.date).toISOString().split("T")[0] : "",
      category: item.category || "",
      name: item.name || "",
      description: item.description || "",
      unit: item.unit || "",
      quantity: Number(item.quantity) || 0,
      price: Number(item.price) || 0,
      total: Number(item.total) || 0
    };
    setEditingItem(editItem);
    setIsEditModalOpen(true);
  };

  const handleEditSave = async () => {
    if (isArchived) return;
    try {
      if (!editingItem || !editingItem._id) {
        console.error("No item ID found");
        return;
      }

      // Format the data properly before sending
      const itemToUpdate = {
        date: new Date(editingItem.date).toISOString(),
        category: editingItem.category,
        name: editingItem.name,
        description: editingItem.description || "",
        unit: editingItem.unit,
        quantity: Number(editingItem.quantity),
        price: Number(editingItem.price)
      };

      await updateAccountingItem(id, editingItem._id, itemToUpdate);
      await fetchData();
      setEditingItem(null);
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };
  
  const handleDeleteItem = async (itemId) => {
    if (isArchived) return;
    try {
      await deleteAccountingItem(id, itemId);
      await fetchData();
      setIsDeleteModalOpen(false);
      setItemToDelete(null);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleInputChange = (e) => {
    let name, value;

    // Handle both event objects and direct value changes
    if (e.target) {
      // For regular input events
      ({ name, value } = e.target);
    } else if (e.name) {
      // For direct object passing (used in Select)
      ({ name, value } = e);
    } else {
      // If neither format is present, something is wrong
      console.error("Invalid input format:", e);
      return;
    }

    const updatedItem = editingItem || newItem;
    const newValues = { ...updatedItem };

    // Handle numeric values
    if (name === "quantity" || name === "price") {
      newValues[name] = Number(value);
      newValues.total = newValues.quantity * newValues.price;
    } else {
      newValues[name] = value;
    }

    if (editingItem) {
      setEditingItem(newValues);
    } else {
      setNewItem(newValues);
    }
  };

  return (
    <>
      <ConstructionSiteNavbar />
      {isArchived && (
        <div className="mx-6 mt-6">
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex items-center">
              <Archive className="w-5 h-5 text-yellow-400 mr-2" />
              <p className="text-yellow-700">
                Questo cantiere è archiviato. È possibile solo visualizzare la
                contabilità.
              </p>
            </div>
          </div>
        </div>
      )}
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="px-2 py-4 space-y-6">
          {/* Summary Cards with updated styling (no borders) */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-4">
            <Card className="border-0 shadow-none">
              <CardContent className="p-2">
                <p className="text-2xl font-bold mb-1">
                  {formatCurrency(remainingAmount)}
                </p>
                <p className="text-gray-500 text-sm font-normal">Importo Pagato</p>
              </CardContent>
            </Card>

            <Card className="border-0 shadow-none">
              <CardContent className="p-2">
                <p className="text-2xl font-bold mb-1">
                  {formatCurrency(budget || 0)}
                </p>
                <p className="text-gray-500 text-sm font-normal">Budget</p>
              </CardContent>
            </Card>

            <Card className="border-0 shadow-none">
              <CardContent className="p-2">
                <p className="text-2xl font-bold mb-1">
                  {formatCurrency(totalWorkCost)}
                </p>
                <p className="text-gray-500 text-sm font-normal">Costo dei Lavori</p>
              </CardContent>
            </Card>

            <Card className="border-0 shadow-none">
              <CardContent className="p-2">
                <p className="text-2xl font-bold mb-1">
                  {formatCurrency(
                    filteredItems
                      .filter((item) => item.category === "manodopera")
                      .reduce((sum, item) => sum + (item.total || 0), 0)
                  )}
                </p>
                <p className="text-gray-500 text-sm font-normal">Costo Manodopera</p>
              </CardContent>
            </Card>

            <Card className="border-0 shadow-none">
              <CardContent className="p-2">
                <p className="text-2xl font-bold mb-1">
                  {formatCurrency(
                    filteredItems
                      .filter((item) => item.category === "materiali")
                      .reduce((sum, item) => sum + (item.total || 0), 0)
                  )}
                </p>
                <p className="text-gray-500 text-sm font-normal">Costo Materiali</p>
              </CardContent>
            </Card>

            <Card className="border-0 shadow-none">
              <CardContent className="p-2">
                <p className="text-2xl font-bold mb-1">
                  {formatCurrency(
                    filteredItems
                      .filter((item) => item.category === "attrezzatura")
                      .reduce((sum, item) => sum + (item.total || 0), 0)
                  )}
                </p>
                <p className="text-gray-500 text-sm font-normal">Costo Attrezzatura</p>
              </CardContent>
            </Card>
          </div>

          {/* Items Table */}
          <div>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Dettaglio Costi</h2>
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <Input
                    type="text"
                    placeholder="Cerca per nome o descrizione..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-64"
                  />
                  <Button
                    variant="outline"
                    onClick={() => setIsFilterDialogOpen(true)}
                    className="flex items-center gap-2"
                  >
                    <Filter className="h-4 w-4" />
                    Filtri
                    {areFiltersActive() && (
                      <span className="flex h-2 w-2 rounded-full bg-red-600"></span>
                    )}
                  </Button>
                </div>
                {!isArchived && (
                  <Button onClick={() => setIsAddModalOpen(true)}>
                    Aggiungi
                  </Button>
                )}
              </div>
            </div>

            <div className="rounded-md border">
              <EnhancedTable
                items={filteredItems}
                sortConfig={sortConfig}
                onSortChange={setSortConfig}
                selectedItems={selectedItems}
                onSelectChange={setSelectedItems}
                selectAll={selectAll}
                onSelectAllChange={setSelectAll}
              >
                <TableHeader>
                  <SelectAllTableHead />
                  <SortableTableHead sortKey="date">Data</SortableTableHead>
                  <SortableTableHead sortKey="category">Categoria</SortableTableHead>
                  <SortableTableHead sortKey="name">Nome</SortableTableHead>
                  <SortableTableHead sortKey="description">Descrizione</SortableTableHead>
                  <SortableTableHead sortKey="unit">U.M.</SortableTableHead>
                  <SortableTableHead sortKey="quantity" >Q.tà</SortableTableHead>
                  <SortableTableHead sortKey="price" >Prezzo</SortableTableHead>
                  <SortableTableHead sortKey="total" >Totale</SortableTableHead>
                  {!isArchived && <SortableTableHead ></SortableTableHead>}
                </TableHeader>
                <TableBody>
                  {filteredItems.length > 0 ? (
                    filteredItems.map((item) => (
                      <SelectableTableRow key={item._id} item={item}>
                        <SelectionTableCell item={item} />
                        <TableCell>
                          {item.date
                            ? new Date(item.date).toLocaleDateString("it-IT")
                            : ""}
                        </TableCell>
                        <TableCell>
                          {categories.find((c) => c.id === item.category)?.name}
                        </TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>
                          {item.description}
                          {isAttendanceImported(item) && (
                            <div className="mt-1">
                              <span className="text-xs text-gray-500 font-medium bg-gray-100 px-2 py-1 rounded">
                                Importato da Foglio Presenze
                              </span>
                            </div>
                          )}
                        </TableCell>
                        <TableCell>{item.unit}</TableCell>
                        <TableCell >
                          {item.quantity}
                        </TableCell>
                        <TableCell >
                          {formatCurrency(item.price)}
                        </TableCell>
                        <TableCell >
                          {formatCurrency(item.total)}
                        </TableCell>
                        {!isArchived && (
                          <ActionsTableCell>
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <DropdownMenuItem
                                    onClick={() => handleEditClick(item)}
                                    disabled={isAttendanceImported(item)}
                                    className={isAttendanceImported(item) ? "opacity-50 cursor-not-allowed" : ""}
                                  >
                                    <Edit className={`h-4 w-4 mr-2 ${isAttendanceImported(item) ? "text-gray-400" : ""}`} />
                                    Modifica
                                  </DropdownMenuItem>
                                </TooltipTrigger>
                                {isAttendanceImported(item) && (
                                  <TooltipContent side="top">
                                    <p className="font-medium">Non puoi modificare una presenza importata</p>
                                  </TooltipContent>
                                )}
                              </Tooltip>
                            </TooltipProvider>

                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <DropdownMenuItem
                                    onClick={() => {
                                      if (!isAttendanceImported(item)) {
                                        setItemToDelete(item);
                                        setIsDeleteModalOpen(true);
                                      }
                                    }}
                                    disabled={isAttendanceImported(item)}
                                    className={isAttendanceImported(item) ? "opacity-50 cursor-not-allowed" : ""}
                                  >
                                    <Trash2 className={`h-4 w-4 mr-2 ${isAttendanceImported(item) ? "text-gray-400" : "text-red-500"}`} />
                                    Elimina
                                  </DropdownMenuItem>
                                </TooltipTrigger>
                                {isAttendanceImported(item) && (
                                  <TooltipContent side="top">
                                    <p className="font-medium">Non puoi eliminare una presenza importata</p>
                                  </TooltipContent>
                                )}
                              </Tooltip>
                            </TooltipProvider>
                          </ActionsTableCell>
                        )}
                      </SelectableTableRow>
                    ))
                  ) : (
                    <SelectableTableRow>
                      <TableCell colSpan={isArchived ? 9 : 10} className="text-center py-4">
                        Nessun elemento trovato
                      </TableCell>
                    </SelectableTableRow>
                  )}
                </TableBody>
              </EnhancedTable>
            </div>
          </div>

          {!isArchived && (
            <>
              {/* Add Item Modal */}
              <Dialog open={isAddModalOpen} onOpenChange={setIsAddModalOpen}>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Aggiungi Elemento</DialogTitle>
                    <DialogDescription>
                      Inserisci i dettagli del nuovo elemento.
                    </DialogDescription>
                  </DialogHeader>
                  <div className="grid gap-4 py-4">
                    <div className="grid gap-2">
                      <Label>Data</Label>
                      <Input
                        type="date"
                        name="date"
                        value={newItem.date}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid gap-2">
                      <Label>Categoria</Label>
                      <Select
                        value={newItem.category}
                        onValueChange={(value) =>
                          handleInputChange({
                            target: { name: "category", value }
                          })
                        }
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Seleziona categoria" />
                        </SelectTrigger>
                        <SelectContent>
                          {categories.map((category) => (
                            <SelectItem key={category.id} value={category.id}>
                              {category.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="grid gap-2">
                      <Label>Nome</Label>
                      <Input
                        name="name"
                        value={newItem.name}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid gap-2">
                      <Label>Descrizione</Label>
                      <Input
                        name="description"
                        value={newItem.description}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid gap-2">
                      <Label>Unità di Misura</Label>
                      <Select
                        name="unit"
                        value={newItem.unit}
                        onValueChange={(value) =>
                          handleInputChange({ target: { name: "unit", value } })
                        }
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Seleziona unità di misura" />
                        </SelectTrigger>
                        <SelectContent>
                          {ums.map((um) => (
                            <SelectItem key={um.unit} value={um.unit}>
                              {um.unit}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div className="grid gap-2">
                        <Label>Quantità</Label>
                        <Input
                          type="number"
                          name="quantity"
                          value={newItem.quantity}
                          onChange={handleInputChange}
                          min="0"
                          step="0.01"
                        />
                      </div>

                      <div className="grid gap-2">
                        <Label>Prezzo Unitario</Label>
                        <Input
                          type="number"
                          name="price"
                          value={newItem.price}
                          onChange={handleInputChange}
                          min="0"
                          step="0.01"
                        />
                      </div>
                    </div>

                    <div className="grid gap-2">
                      <Label>Totale</Label>
                      <Input
                        type="number"
                        value={newItem.quantity * newItem.price}
                        readOnly
                        disabled
                      />
                    </div>
                  </div>

                  <DialogFooter>
                    <Button
                      variant="outline"
                      onClick={() => setIsAddModalOpen(false)}
                    >
                      Annulla
                    </Button>
                    <Button onClick={handleAddItem}>Aggiungi</Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>

              {/* Edit Item Modal */}
              <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Modifica Elemento</DialogTitle>
                    <DialogDescription>
                      Modifica i dettagli dell'elemento.
                    </DialogDescription>
                  </DialogHeader>

                  <div className="grid gap-4 py-4">
                    <div className="grid gap-2">
                      <Label>Data</Label>
                      <Input
                        type="date"
                        name="date"
                        value={
                          editingItem?.date
                            ? new Date(editingItem.date)
                                .toISOString()
                                .split("T")[0]
                            : ""
                        }
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid gap-2">
                      <Label>Categoria</Label>
                      <Select
                        value={editingItem?.category || ""}
                        onValueChange={(value) =>
                          handleInputChange({ name: "category", value })
                        }
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Seleziona categoria" />
                        </SelectTrigger>
                        <SelectContent>
                          {categories.map((category) => (
                            <SelectItem key={category.id} value={category.id}>
                              {category.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="grid gap-2">
                      <Label>Nome</Label>
                      <Input
                        name="name"
                        value={editingItem?.name || ""}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid gap-2">
                      <Label>Descrizione</Label>
                      <Input
                        name="description"
                        value={editingItem?.description || ""}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid gap-2">
                      <Label>Unità di Misura</Label>
                      <Select
                        value={editingItem?.unit || ""}
                        onValueChange={(value) =>
                          handleInputChange({ name: "unit", value })
                        }
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Seleziona unità di misura" />
                        </SelectTrigger>
                        <SelectContent>
                          {ums.map((um) => (
                            <SelectItem key={um.unit} value={um.unit}>
                              {um.unit}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div className="grid gap-2">
                        <Label>Quantità</Label>
                        <Input
                          type="number"
                          name="quantity"
                          value={editingItem?.quantity || 0}
                          onChange={handleInputChange}
                          min="0"
                          step="0.01"
                        />
                      </div>

                      <div className="grid gap-2">
                        <Label>Prezzo Unitario</Label>
                        <Input
                          type="number"
                          name="price"
                          value={editingItem?.price || 0}
                          onChange={handleInputChange}
                          min="0"
                          step="0.01"
                        />
                      </div>
                    </div>

                    <div className="grid gap-2">
                      <Label>Totale</Label>
                      <Input
                        type="number"
                        value={
                          (editingItem?.quantity || 0) *
                          (editingItem?.price || 0)
                        }
                        readOnly
                        disabled
                      />
                    </div>
                  </div>

                  <DialogFooter>
                    <Button
                      variant="outline"
                      onClick={() => {
                        setIsEditModalOpen(false);
                        setEditingItem(null);
                      }}
                    >
                      Annulla
                    </Button>
                    <Button onClick={handleEditSave}>Salva Modifiche</Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>

              {/* Delete Confirmation Modal */}
              <Dialog
                open={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
              >
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Conferma Eliminazione</DialogTitle>
                    <DialogDescription>
                      Sei sicuro di voler eliminare questo elemento? Questa
                      azione non può essere annullata.
                    </DialogDescription>
                  </DialogHeader>

                  {itemToDelete && (
                    <div className="py-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <Label className="font-semibold">Categoria</Label>
                          <p>
                            {
                              categories.find(
                                (c) => c.id === itemToDelete.category
                              )?.name
                            }
                          </p>
                        </div>
                        <div>
                          <Label className="font-semibold">Nome</Label>
                          <p>{itemToDelete.name}</p>
                        </div>
                        <div>
                          <Label className="font-semibold">Prezzo</Label>
                          <p>{formatCurrency(itemToDelete.price)}</p>
                        </div>
                        <div>
                          <Label className="font-semibold">Totale</Label>
                          <p>{formatCurrency(itemToDelete.total)}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <DialogFooter>
                    <Button
                      variant="outline"
                      onClick={() => {
                        setIsDeleteModalOpen(false);
                        setItemToDelete(null);
                      }}
                    >
                      Annulla
                    </Button>
                    <Button
                      variant="destructive"
                      onClick={() => handleDeleteItem(itemToDelete._id)}
                    >
                      Elimina
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>

              {/* Filter Dialog */}
              <Dialog
                open={isFilterDialogOpen}
                onOpenChange={setIsFilterDialogOpen}
              >
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle>Filtri</DialogTitle>
                    <DialogDescription>
                      Applica i filtri per raffinare i risultati
                    </DialogDescription>
                  </DialogHeader>

                  <div className="grid gap-4 py-4">
                    <div className="grid gap-2">
                      <Label>Periodo</Label>
                      <div className="grid grid-cols-2 gap-4">
                        <div className="grid gap-2">
                          <Label className="text-xs text-muted-foreground">
                            Da
                          </Label>
                          <Input
                            type="date"
                            value={filters.dateFrom}
                            onChange={(e) =>
                              handleFilterChange("dateFrom", e.target.value)
                            }
                          />
                        </div>
                        <div className="grid gap-2">
                          <Label className="text-xs text-muted-foreground">
                            A
                          </Label>
                          <Input
                            type="date"
                            value={filters.dateTo}
                            onChange={(e) =>
                              handleFilterChange("dateTo", e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid gap-2">
                      <Label>Categoria</Label>
                      <Select
                        value={filters.category}
                        onValueChange={(value) =>
                          handleFilterChange("category", value)
                        }
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Tutte le categorie" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="all">
                            Tutte le categorie
                          </SelectItem>
                          {categories.map((category) => (
                            <SelectItem key={category.id} value={category.id}>
                              {category.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="grid gap-2">
                      <Label>Quantità</Label>
                      <Select
                        value={filters.quantity}
                        onValueChange={(value) =>
                          handleFilterChange("quantity", value)
                        }
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Tutte le quantità" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="all">Tutte le quantità</SelectItem>
                          {quantityRanges.map((range, index) => (
                            <SelectItem
                              key={index}
                              value={`${range.min}-${range.max}`}
                            >
                              {range.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="grid gap-2">
                      <Label>Prezzo</Label>
                      <Select
                        value={filters.price}
                        onValueChange={(value) =>
                          handleFilterChange("price", value)
                        }
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Tutti i prezzi" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="all">Tutti i prezzi</SelectItem>
                          {priceRanges.map((range, index) => (
                            <SelectItem
                              key={index}
                              value={`${range.min}-${range.max}`}
                            >
                              {range.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>

                  <DialogFooter>
                    <Button variant="outline" onClick={resetFilters}>
                      Reset
                    </Button>
                    <Button onClick={() => setIsFilterDialogOpen(false)}>
                      Applica
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Accounting;