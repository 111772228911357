import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { ChevronLeft, ChevronRight, MoreHorizontal } from "lucide-react";

import {
  GetMonthlySalary,
  CalculateSalary,
  UpdateSalaryStatus
} from "../../../../../apis/Salary";
import { getCompnayEmployeeAttendence } from "../../../../../apis/Attendence";
import { GetCompanyLeaves } from "../../../../../apis/leave";
import { Employees } from "../../../../../apis/Employees";

import { Button } from "../../../../ui/button";
import { Input } from "../../../../ui/input";
import {
  EnhancedTable,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "../../../../ui/dialog";
import { DropdownMenuItem } from "../../../../ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../../../ui/select";

import SearchBar from "../../../sharedComponent/SearchBar";
import HelpSheet from "../../../sharedComponent/HelpSheet";

const Salary = ({ companyId }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [employeeData, setEmployeeData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDataUpdate, setIsDataUpdate] = useState(false);
  const [calculatedData, setCalculatedData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [payload, setPayload] = useState({
    companyId,
    month: selectedMonth.getMonth() + 1,
    year: selectedMonth.getFullYear()
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  // Add enhanced table state
  const [sortConfig, setSortConfig] = useState({
    key: "employee.firstName",
    direction: "ascending"
  });
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  // Create sortedData memoized function
  const sortedData = useMemo(() => {
    if (!calculatedData || !sortConfig.key) return calculatedData;
    
    return [...calculatedData].sort((a, b) => {
      // Handle nested properties like employee.firstName
      if (sortConfig.key.includes('.')) {
        const keys = sortConfig.key.split('.');
        let aValue = a;
        let bValue = b;
        
        // Navigate through nested objects
        for (const key of keys) {
          aValue = aValue?.[key] || '';
          bValue = bValue?.[key] || '';
        }
        
        // Compare the values
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortConfig.direction === 'ascending' 
            ? aValue.localeCompare(bValue) 
            : bValue.localeCompare(aValue);
        }
        
        return sortConfig.direction === 'ascending' 
          ? (aValue > bValue ? 1 : -1) 
          : (bValue > aValue ? 1 : -1);
      }
      
      // Handle numeric fields
      if (['totalWorkHours', 'absenceHours', 'monthlySalary', 'workingDays'].includes(sortConfig.key)) {
        const aValue = parseFloat(a[sortConfig.key] || 0);
        const bValue = parseFloat(b[sortConfig.key] || 0);
        return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
      }
      
      // Handle string comparison for state
      if (sortConfig.key === 'state') {
        return sortConfig.direction === 'ascending'
          ? a.state.localeCompare(b.state)
          : b.state.localeCompare(a.state);
      }
      
      // Default string comparison
      const aValue = a[sortConfig.key] || '';
      const bValue = b[sortConfig.key] || '';
      
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortConfig.direction === 'ascending' 
          ? aValue.localeCompare(bValue) 
          : bValue.localeCompare(aValue);
      }
      
      // Default comparison
      return sortConfig.direction === 'ascending' 
        ? (aValue > bValue ? 1 : -1) 
        : (bValue > aValue ? 1 : -1);
    });
  }, [calculatedData, sortConfig]);

  const calculateHours = (startTime, exitTime) => {
    if (!startTime || !exitTime) return 0;
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const [exitHours, exitMinutes] = exitTime.split(":").map(Number);
    const totalMinutes =
      exitHours * 60 + exitMinutes - (startHours * 60 + startMinutes);
    return totalMinutes / 60;
  };

  const calculateSalary = (workingHours, grossHourlyPay) => {
    if (!workingHours || !grossHourlyPay || grossHourlyPay === "") return 0;
    const hourlyPay = parseFloat(grossHourlyPay);
    if (isNaN(hourlyPay)) return 0;
    return workingHours * hourlyPay;
  };

  const fetchSalaryData = async () => {
    try {
      const response = await GetMonthlySalary(
        companyId,
        payload.month,
        payload.year,
        searchQuery
      );

      if (response.status === 200) {
        // Create a map of employee IDs to their salary states
        const salaryStates = new Map(
          response.data.salaries.map((salary) => [
            salary.employee._id,
            salary.state || "unpaid"
          ])
        );

        // Update calculatedData with correct states
        setCalculatedData((prevData) =>
          prevData.map((data) => ({
            ...data,
            state: salaryStates.get(data.employee._id) || "unpaid",
            id: data.employee._id // Add id for selection tracking
          }))
        );
      }
    } catch (error) {
      toast.error("Errore nel recupero dei dati dello stipendio");
    }
  };

  const handleStatusUpdate = async (employee, newStatus) => {
    try {
      const response = await UpdateSalaryStatus({
        employeeId: employee._id,
        companyId,
        month: payload.month,
        year: payload.year,
        state: newStatus
      });

      if (response.status === 200) {
        setCalculatedData((prevData) =>
          prevData.map((data) =>
            data.employee._id === employee._id
              ? { ...data, state: newStatus }
              : data
          )
        );
        toast.success("Stato aggiornato con successo");
      }
    } catch (error) {
      toast.error("Errore nell'aggiornamento dello stato");
    } finally {
      setIsStatusDialogOpen(false);
      setSelectedStatus("");
    }
  };

  const toggleStatus = (employee) => {
    setSelectedEmployee(employee);
    const currentStatus =
      calculatedData.find((data) => data.employee._id === employee._id)
        ?.state || "unpaid";
    setSelectedStatus(currentStatus);
    setIsStatusDialogOpen(true);
  };

  const fetchEmployees = async () => {
    try {
      const response = await Employees(
        companyId,
        currentPage,
        limit,
        searchQuery
      );
      if (response.status === 200) {
        const employees = response.data?.employees || [];
        setEmployeeData(employees);
      }
    } catch (error) {
      toast.error("Failed to fetch employees");
    }
  };

  const saveSalaryCalculations = async (processedData) => {
    try {
      const response = await CalculateSalary({
        companyId: payload.companyId,
        month: payload.month,
        year: payload.year,
        salaryData: processedData
      });

      if (response.status === 200) {
        toast.success("Stipendi calcolati con successo");
        // Update the states from the response
        const salaryStates = new Map(
          response.data.salaries.map((salary) => [
            salary.employee,
            salary.state || "unpaid"
          ])
        );

        setCalculatedData((prevData) =>
          prevData.map((data) => ({
            ...data,
            state: salaryStates.get(data.employee._id) || "unpaid"
          }))
        );
      }
    } catch (error) {
      toast.error("Errore nel salvataggio dei calcoli degli stipendi");
    }
  };

  // Update the fetchAttendanceAndLeaves function
  const fetchAttendanceAndLeaves = async () => {
    try {
      setIsDataUpdate(true);
      // Only get attendance data since we don't want to make it dependent on leaves
      const attendanceResponse = await getCompnayEmployeeAttendence(companyId);

      if (attendanceResponse.status === 200) {
        const attendanceData = attendanceResponse.data.employees;

        const processedData = attendanceData
          .map((employee) => {
            const fullEmployeeData = employeeData.find(
              (emp) => emp._id === employee._id
            );

            if (!fullEmployeeData) return null;

            const monthAttendance = employee.attendance.filter((att) => {
              if (!att || !att.date) return false;
              const [day, month, year] = att.date.split("-").map(Number);
              return month === payload.month && year === payload.year;
            });

            const presentDays = monthAttendance.filter(
              (att) =>
                att.attendanceState === "present" ||
                att.attendanceState === "late"
            ).length;

            const totalWorkHours = monthAttendance.reduce((total, att) => {
              if (
                att.attendanceState === "present" ||
                att.attendanceState === "late"
              ) {
                return total + calculateHours(att.startTime, att.exitTime);
              }
              return total;
            }, 0);

            const absenceHours = monthAttendance.reduce((total, att) => {
              if (
                att.attendanceState === "absent" ||
                att.attendanceState === "leave"
              ) {
                return total + 8;
              }
              return total;
            }, 0);

            const grossHourlyWage = fullEmployeeData?.grosshourlywage || "0";
            const monthlySalary = calculateSalary(
              totalWorkHours,
              grossHourlyWage
            );

            const employeeInfo = {
              _id: employee._id,
              firstName: employee.firstName || "",
              sureName: employee.sureName || "",
              freshman: employee.freshman || ""
            };

            return {
              employee: employeeInfo,
              workingDays: presentDays,
              totalWorkHours,
              absenceHours,
              grossHourlyWage,
              monthlySalary,
              state: "unpaid", // Default state
              id: employee._id // Add id for selection tracking
            };
          })
          .filter(Boolean);

        setCalculatedData(processedData);
        // Save the calculations to the backend
        await saveSalaryCalculations(processedData);
        // Fetch salary data to get updated states
        await fetchSalaryData();
      }
    } catch (error) {
      toast.error("Errore nel recupero dei dati");
    } finally {
      setIsDataUpdate(false);
    }
  };

  useEffect(() => {
    if (employeeData.length > 0) {
      fetchAttendanceAndLeaves();
    }
  }, [payload, searchQuery, employeeData]);

  useEffect(() => {
    fetchEmployees();
  }, [searchQuery, currentPage, limit]);

  const handleMonthChange = (e) => {
    const newDate = new Date(e.target.value);
    setSelectedMonth(newDate);
    setPayload({
      ...payload,
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear()
    });
  };

  const incrementMonth = () => {
    setSelectedMonth((prev) => {
      const newDate = new Date(prev.setMonth(prev.getMonth() + 1));
      setPayload({
        ...payload,
        month: newDate.getMonth() + 1,
        year: newDate.getFullYear()
      });
      return newDate;
    });
  };

  const decrementMonth = () => {
    setSelectedMonth((prev) => {
      const newDate = new Date(prev.setMonth(prev.getMonth() - 1));
      setPayload({
        ...payload,
        month: newDate.getMonth() + 1,
        year: newDate.getFullYear()
      });
      return newDate;
    });
  };

  const formatEmployeeName = (employee) => {
    const firstName = employee.firstName || "";
    const sureName = employee.sureName || "";
    const freshman = employee.freshman || "";
    if (!firstName && !sureName && !freshman) return "N/A";
    return `${firstName} ${sureName}${freshman ? ` (${freshman})` : ""}`.trim();
  };

  return (
    <div className="mx-auto space-y-4">
      <div className="flex flex-col md:flex-row justify-between items-center my-2 space-y-4 md:space-y-0">
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2">
            <Button variant="outline" size="icon" onClick={decrementMonth}>
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <Input
              type="month"
              value={format(selectedMonth, "yyyy-MM")}
              onChange={handleMonthChange}
              className="w-full"
            />
            <Button variant="outline" size="icon" onClick={incrementMonth}>
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
        <div className="md:w-1/6 flex justify-center items-center gap-x-2">
          <SearchBar onSearch={setSearchQuery} />
          <HelpSheet route="/employees" />
        </div>
      </div>

      <div className="rounded-md border">
        <EnhancedTable
          items={sortedData}
          sortConfig={sortConfig}
          onSortChange={setSortConfig}
          selectedItems={selectedItems}
          onSelectChange={setSelectedItems}
          selectAll={selectAll}
          onSelectAllChange={setSelectAll}
        >
          <TableHeader>
            <TableRow>
              <SelectAllTableHead />
              <SortableTableHead>ID</SortableTableHead>
              <SortableTableHead sortKey="employee.firstName">
                Dipendente
              </SortableTableHead>
              <SortableTableHead sortKey="workingDays">
                Giorni Lavorativi Totali
              </SortableTableHead>
              <SortableTableHead sortKey="totalWorkHours">
                Ore di Lavoro Totali
              </SortableTableHead>
              <SortableTableHead sortKey="absenceHours">
                Ore di Assenza
              </SortableTableHead>
              <SortableTableHead sortKey="grossHourlyWage">
                Retribuzione oraria lorda
              </SortableTableHead>
              <SortableTableHead sortKey="monthlySalary">
                Stipendio
              </SortableTableHead>
              <SortableTableHead sortKey="state">Stato</SortableTableHead>
              <SortableTableHead></SortableTableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedData.length > 0 ? (
              sortedData.map((data, index) => (
                <SelectableTableRow
                  key={data.employee._id || index}
                  item={data}
                >
                  <SelectionTableCell item={data} />
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{formatEmployeeName(data.employee)}</TableCell>
                  <TableCell>{data.workingDays}</TableCell>
                  <TableCell>{data.totalWorkHours.toFixed(2)}H</TableCell>
                  <TableCell>{data.absenceHours.toFixed(2)}H</TableCell>
                  <TableCell>
                    €{parseFloat(data.grossHourlyWage || "0").toFixed(2)}/h
                  </TableCell>
                  <TableCell>€{data.monthlySalary.toFixed(2)}</TableCell>
                  <TableCell
                    className={`${
                      data.state === "unpaid"
                        ? "text-red-600"
                        : "text-green-600"
                    }`}
                  >
                    {data.state === "unpaid" ? "Non Pagato" : "Pagato"}
                  </TableCell>
                  <ActionsTableCell>
                    <DropdownMenuItem
                      onClick={() => toggleStatus(data.employee)}
                    >
                      Cambia Stato
                    </DropdownMenuItem>
                  </ActionsTableCell>
                </SelectableTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} className="text-center py-8">
                  Nessun record trovato per questo mese
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </EnhancedTable>
      </div>

      <Dialog open={isStatusDialogOpen} onOpenChange={setIsStatusDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Cambia Stato Pagamento</DialogTitle>
            <DialogDescription>
              {selectedEmployee &&
                `Dipendente: ${formatEmployeeName({ ...selectedEmployee })}`}
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <label className="text-sm font-medium">Seleziona Stato</label>
              <Select value={selectedStatus} onValueChange={setSelectedStatus}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Seleziona uno stato" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="paid">Pagato</SelectItem>
                  <SelectItem value="unpaid">Non Pagato</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setIsStatusDialogOpen(false);
                setSelectedStatus("");
              }}
            >
              Annulla
            </Button>
            <Button
              onClick={() => {
                if (selectedStatus) {
                  handleStatusUpdate(selectedEmployee, selectedStatus);
                } else {
                  toast.error("Seleziona uno stato prima di confermare");
                }
              }}
              disabled={!selectedStatus}
            >
              Conferma
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Salary;