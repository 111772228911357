import { createJsonTypeInstance } from "./index";
import { format } from "date-fns";
const axios = createJsonTypeInstance();

export const getCompnayEmployeeAttendence = async (companyId) => {
  try {
    const response = await axios.get(
      `/attendance/AllEmployee-Attendance/${companyId}`
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const markAttendance = async (data) => {
  //console.log("attendance data:", data);
  try {
    const response = await axios.post("/attendance/mark", data);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateAttendance = async (data) => {
  try {
    const response = await axios.put("/attendance/update", data);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteAttendance = async (employeeId, date, companyId) => {
  try {
    const response = await axios.delete(
      `/attendance/delete/${employeeId}/${date}/${companyId}`
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const getAttendanceBySiteAndDate = async (siteId, date) => {
  try {
    // Format date as dd-MM-yyyy for the API
    const formattedDate = format(date, "dd-MM-yyyy");
    const response = await axios.get(
      `/attendance/site/${siteId}/date/${formattedDate}`
    );
    return response.data.attendanceRecords || [];
  } catch (error) {
    console.error("Error fetching attendance by site and date:", error);
    return [];
  }
};
