import { Button } from "../ui/button";
import Modal from "react-modal";
import { Input } from "../../components/ui/input";
Modal.setAppElement("#root");

const BankSelectionModal = ({
  isOpen,
  onClose,
  searchQuery,
  handleSearch,
  banks,
  connectBankAccount,
  loading,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div className="bg-white p-6 pt-0 rounded-lg shadow-lg max-w-[50%] w-full max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center w-full p-3 sticky top-0 bg-white z-10">
          <h2 className="text-xl font-semibold">Seleziona la tua banca</h2>
          <Button className="hover:underline" onClick={onClose}>
            Chiudi
          </Button>
        </div>
        <div className="my-3">
          <Input
            type="text"
            placeholder="Search for a bank..."
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          {banks?.length === 0 && (
            <div className="text-gray-500 text-center">
              <h4 className="text-center"> Nessun bank collegato.</h4>
            </div>
          )}
          {banks?.length > 0 &&
            banks.map((bank) => (
              <div
                key={bank.id}
                className={`p-4 border rounded cursor-pointer hover:bg-gray-100 transition ${
                  loading ? "pointer-events-none opacity-50" : ""
                }`}
                onClick={() =>
                  !loading && connectBankAccount(bank.id, bank.name)
                }
              >
                <img
                  src={bank.logo}
                  alt={bank.name}
                  className="w-16 h-16 mx-auto"
                />
                <p className="text-center">{bank.name}</p>
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default BankSelectionModal;
