import React, { useState, useEffect, useCallback } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import { Button } from "../../../ui/button";
import {
  EnhancedTable,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "../../../ui/dialog";
import { Checkbox } from "../../../ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../../ui/select";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import { ScrollArea } from "../../../ui/scroll-area";
import { Edit, Trash2, Plus, Minus } from "lucide-react";
import { DropdownMenuItem } from "../../../ui/dropdown-menu";
import {
  addPoints,
  createViolation,
  getViolations,
  deleteViolation,
  updateViolation,
  resetPoints,
  updatePoints,
  deletePoints
} from "../../../../apis/Violation";
import { getConstructionSites } from "../../../../apis/ConstructionSite";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateCompany, fetchCompany } from "../../../../apis/CompanyDetail";
import Spinner from "../../../Spinner";

const violations = [
  {
    id: 1,
    description: "Omessa elaborazione del documento di valutazione dei rischi",
    points: -5
  },
  {
    id: 2,
    description: "Omessa elaborazione del Piano di emergenza ed evacuazione",
    points: -3
  },
  { id: 3, description: "Omessi formazione ed addestramento", points: -2 },
  {
    id: 4,
    description:
      "Omessa costituzione del servizio di prevenzione o nomina del relativo responsabile",
    points: -3
  },
  {
    id: 5,
    description: "Omessa elaborazione del piano operativo di sicurezza",
    points: -3
  },
  {
    id: 6,
    description:
      "Omessa fornitura del dispositivo di protezione individuale contro le cadute dall'alto",
    points: -2
  },
  { id: 7, description: "Mancanza di protezioni verso il vuoto", points: -3 },
  {
    id: 8,
    description:
      "Mancata installazione delle armature di sostegno, fatte salve le prescrizioni desumibili dalla relazione tecnica sulla consistenza del terreno",
    points: -2
  },
  {
    id: 9,
    description:
      "Lavori in prossimità di linee elettriche in assenza di disposizioni organizzative e procedurali idonee a proteggere i lavoratori dai conseguenti rischi",
    points: -2
  },
  {
    id: 10,
    description:
      "Presenza di conduttori nudi in tensione in assenza di disposizioni organizzative e procedurali idonee a proteggere i lavoratori dai conseguenti rischi",
    points: -2
  },
  {
    id: 11,
    description:
      "Mancanza di protezione contro i contatti diretti e indiretti (impianto di terra, interruttore magnetotermico, interruttore differenziale)",
    points: -2
  },
  {
    id: 12,
    description:
      "Omessa vigilanza in ordine alla rimozione o modifica dei dispositivi di sicurezza o di segnalazione o di controllo",
    points: -2
  },
  {
    id: 13,
    description:
      "Omessa notifica all'organo di vigilanza prima dell'inizio di lavori che possono comportare il rischio di esposizione all'amianto",
    points: -1
  },
  {
    id: 14,
    description:
      "Omessa valutazione dei rischi derivanti dal possibile rinvenimento di ordigni bellici inesplosi ai sensi dell'articolo 28",
    points: -3
  },
  {
    id: 15,
    description:
      "Omessa valutazione del rischio biologico e da sostanze chimiche",
    points: -3
  },
  {
    id: 16,
    description:
      "Omessa individuazione delle zone controllate o sorvegliate ai sensi del decreto legislativo 31 luglio 2020, n. 101",
    points: -3
  },
  {
    id: 17,
    description: "Omessa valutazione del rischio di annegamento",
    points: -2
  },
  {
    id: 18,
    description:
      "Omessa valutazione dei rischi collegati a lavori in pozzi, sterri sotterranei e gallerie",
    points: -2
  },
  {
    id: 19,
    description:
      "Omessa valutazione dei rischi collegati all'impiego di esplosivi",
    points: -3
  },
  {
    id: 20,
    description:
      "Omessa formazione dei lavoratori che operano in ambienti confinati o sospetti di inquinamento ai sensi del regolamento di cui al decreto del Presidente della Repubblica 14 settembre 2011, n. 177",
    points: -1
  },
  {
    id: 21,
    description:
      "Condotta sanzionata ai sensi dell'articolo 3, comma 3, lettera a), del decreto- legge 22 febbraio 2002, n. 12, convertito, con modificazioni, dalla legge 23 aprile 2002, n. 73",
    points: -1
  },
  {
    id: 22,
    description:
      "Condotta sanzionata ai sensi dell'articolo 3, comma 3, lettera b), del decreto- legge 22 febbraio 2002, n. 12, convertito, con modificazioni, dalla legge 23 aprile 2002, n. 73",
    points: -2
  },
  {
    id: 23,
    description:
      "Condotta sanzionata ai sensi dell'articolo 3, comma 3, lettera c), del decreto- legge 22 febbraio 2002, n. 12, convertito, con modificazioni, dalla legge 23 aprile 2002, n. 73",
    points: -3
  },
  {
    id: 24,
    description:
      "Condotta sanzionata ai sensi dell'articolo 3, comma 3-quater, del decreto-legge 22 febbraio 2002, n. 12, convertito, con modificazioni, dalla legge 23 aprile 2002, n. 73, in aggiunta alle condotte di cui ai numeri 21, 22 e 23",
    points: -1
  },
  {
    id: 25,
    description:
      "Infortunio di lavoratore dipendente dell'impresa, occorso a seguito di violazione delle norme sulla prevenzione degli infortuni sul lavoro di cui al presente decreto, dal quale derivi un'inabilità temporanea assoluta che importi l'astensione dal lavoro per più di 60 giorni",
    points: -5
  },
  {
    id: 26,
    description:
      "Infortunio di lavoratore dipendente dell'impresa, occorso a seguito di violazione delle norme sulla prevenzione degli infortuni sul lavoro di cui al presente decreto, che comporti una parziale inabilità permanente al lavoro",
    points: -8
  },
  {
    id: 27,
    description:
      "Infortunio di lavoratore dipendente dell'impresa, occorso a seguito di violazione delle norme sulla prevenzione degli infortuni sul lavoro di cui al presente decreto, che comporti un'assoluta inabilità permanente al lavoro",
    points: -15
  },
  {
    id: 28,
    description:
      "Infortunio mortale di lavoratore dipendente dell'impresa, occorso a seguito di violazione delle norme sulla prevenzione degli infortuni sul lavoro di cui al presente decreto",
    points: -20
  },
  {
    id: 29,
    description:
      "Malattia professionale di lavoratore dipendente dell'impresa, derivante dalla violazione delle norme sulla prevenzione degli infortuni sul lavoro di cui al presente decreto",
    points: -10
  }
];

export default function License() {
  const [totalPoints, setTotalPoints] = useState(0);
  const [licenses, setLicenses] = useState([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isPointsDialogOpen, setIsPointsDialogOpen] = useState(false);
  const [constructionSites, setConstructionSites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editingLicense, setEditingLicense] = useState(null);
  const [pointsToAdd, setPointsToAdd] = useState("");
  const [companyInfo, setCompanyInfo] = useState({});
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [newLicense, setNewLicense] = useState({
    controlNumber: "",
    date: "",
    constructionSiteId: "",
    violations: [],
    pointsVariation: 0,
    note: ""
  });
  const companyId = user.companyId;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deletingLicenseId, setDeletingLicenseId] = useState(null);
  const [isEditPointsDialogOpen, setIsEditPointsDialogOpen] = useState(false);
  const [editPointsValue, setEditPointsValue] = useState(totalPoints);

  // Add enhanced table state
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "descending"
  });
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    fetchViolations();
    fetchConstructionSites();
    setEditPointsValue(totalPoints);
  }, [totalPoints]);

  const getCompanyData = async () => {
    try {
      const response = await fetchCompany(companyId);
      setCompanyInfo(response.data.company);
      setDataIsLoaded(true);
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchViolations = async () => {
    try {
      setIsLoading(true);
      const response = await getViolations();
      if (response?.data) {
        const violationsWithIds = response.data.violations.map((violation) => ({
          ...violation,
          id: violation._id
        }));
        setLicenses(violationsWithIds || []);
        setTotalPoints(response.data.totalPoints ?? 0);
      }
    } catch (error) {
      console.error("Error fetching violations:", error);
      toast.error(error?.message || "Errore nel recupero delle violazioni");
      setLicenses([]);
      setTotalPoints(0);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenEdit = (license) => {
    setEditingLicense({
      ...license,
      violations: (license.violations || []).map((v) => v.violationId || v.id)
    });
    setIsAddDialogOpen(true);
  };

  const fetchConstructionSites = async () => {
    try {
      const response = await getConstructionSites();
      setConstructionSites(response.data.sites);
    } catch (error) {
      toast.error("Errore nel recupero dei cantieri");
    }
  };

  const calculateMaxReduction = (selectedViolations) => {
    if (!selectedViolations || selectedViolations.length === 0) return 0;
    const maxViolation = Math.max(
      ...selectedViolations.map((id) =>
        Math.abs(violations.find((v) => v.id === id)?.points || 0)
      )
    );
    return maxViolation * 2;
  };

  const handleViolationChange = (violationId) => {
    const currentLicense = editingLicense || newLicense;
    const currentViolations = currentLicense.violations || [];

    const updatedViolations = currentViolations.includes(violationId)
      ? currentViolations.filter((id) => id !== violationId)
      : [...currentViolations, violationId];

    let pointsVariation = updatedViolations.reduce((total, id) => {
      const violation = violations.find((v) => v.id === id);
      return total + (violation ? violation.points : 0);
    }, 0);

    const maxReduction = calculateMaxReduction(updatedViolations);
    pointsVariation = Math.max(pointsVariation, -maxReduction);

    if (editingLicense) {
      setEditingLicense({
        ...editingLicense,
        violations: updatedViolations,
        pointsVariation
      });
    } else {
      setNewLicense({
        ...newLicense,
        violations: updatedViolations,
        pointsVariation
      });
    }
  };

  const handleAddLicense = async () => {
    try {
      if (
        !newLicense.date ||
        !newLicense.constructionSiteId ||
        !newLicense.controlNumber
      ) {
        toast.error("Compilare tutti i campi obbligatori");
        return;
      }

      setIsLoading(true);
      const violationData = {
        ...newLicense,
        maxReduction: calculateMaxReduction(newLicense.violations),
        violations: newLicense.violations.map((id) => {
          const violation = violations.find((v) => v.id === id);
          return {
            violationId: violation.id,
            description: violation.description,
            points: violation.points
          };
        })
      };

      await createViolation(violationData);
      toast.success("Violazione registrata con successo");
      setIsAddDialogOpen(false);
      resetForm();
      fetchViolations();
    } catch (error) {
      toast.error(
        error.message || "Errore nella registrazione della violazione"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditLicense = async () => {
    try {
      setIsLoading(true);
      const selectedSite = constructionSites.find(
        (site) => site._id === editingLicense.constructionSiteId
      );

      const selectedViolations = editingLicense.violations || [];
      const maxReduction = calculateMaxReduction(selectedViolations);

      const rawPointsVariation = selectedViolations.reduce((total, id) => {
        const violation = violations.find((v) => v.id === id);
        return total + (violation ? violation.points : 0);
      }, 0);

      const limitedPointsVariation = Math.max(
        rawPointsVariation,
        -maxReduction
      );

      const violationData = {
        ...editingLicense,
        constructionSite: selectedSite,
        maxReduction: maxReduction,
        pointsVariation: limitedPointsVariation || 0,
        violations: selectedViolations.map((id) => {
          const violation = violations.find((v) => v.id === id);
          return {
            violationId: violation.id,
            description: violation.description,
            points: violation.points
          };
        })
      };

      await updateViolation(editingLicense._id, violationData);
      toast.success("Violazione aggiornata con successo");
      setIsAddDialogOpen(false);
      setEditingLicense(null);
      fetchViolations();
    } catch (error) {
      console.error("Error updating violation:", error);
      toast.error("Errore nell'aggiornamento della violazione");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteConfirmation = (id) => {
    setDeletingLicenseId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteLicense = async () => {
    try {
      setIsLoading(true);
      await deleteViolation(deletingLicenseId);
      toast.success("Violazione eliminata con successo");
      fetchViolations();
    } catch (error) {
      toast.error("Errore nell'eliminazione della violazione");
    } finally {
      setIsLoading(false);
      setIsDeleteDialogOpen(false);
    }
  };

  const handleAddPoints = async () => {
    try {
      const points = parseInt(pointsToAdd);
      if (isNaN(points) || points <= 0) {
        toast.error("Inserire un numero valido maggiore di zero");
        return;
      }

      await addPoints({
        points,
        date: new Date().toISOString()
      });

      setPointsToAdd("");
      setIsPointsDialogOpen(false);
      toast.success("Punti aggiunti con successo");
      fetchViolations();
    } catch (error) {
      console.error("Error adding points:", error);
      toast.error(error.message || "Errore nell'aggiunta dei punti");
    }
  };

  const handleDeletePoints = async (id) => {
    try {
      setIsLoading(true);
      await deletePoints(id);
      await fetchViolations();
      toast.success("Punti eliminati con successo");
    } catch (error) {
      toast.error(error.message || "Errore nell'eliminazione dei punti");
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setNewLicense({
      controlNumber: "",
      date: "",
      constructionSiteId: "",
      violations: [],
      pointsVariation: 0
    });
    setEditingLicense(null);
  };

  const handleModalClose = () => {
    setIsAddDialogOpen(false);
    resetForm();
  };

  return (
    <div className="mx-auto">
      <div className="md:flex justify-between items-start mb-6">
        <Card className="md:w-[300px] max-sm:mb-6">
          <CardHeader>
            <div className="flex justify-between items-center">
              <CardTitle>Punti</CardTitle>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setIsEditPointsDialogOpen(true)}
              >
                <Edit className="h-4 w-4" />
              </Button>
            </div>
          </CardHeader>
          <CardContent>
            <p className="text-4xl font-bold">{totalPoints}</p>
          </CardContent>
        </Card>
        <div className="space-x-2">
          <Button onClick={() => setIsPointsDialogOpen(true)}>
            Aggiunti punti
          </Button>
          <Button onClick={() => setIsAddDialogOpen(true)}>
            Aggiunti Violazione
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              toast.info("Funzionalità di esportazione in sviluppo");
            }}
          >
            Esporta
          </Button>
        </div>
      </div>

      {/* Points Dialog */}
      <Dialog open={isPointsDialogOpen} onOpenChange={setIsPointsDialogOpen}>
        <DialogContent className="sm:max-w-[400px]">
          <DialogHeader>
            <DialogTitle>Aggiungi Punti</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="points">Punti da aggiungere</Label>
              <Input
                id="points"
                type="number"
                min="1"
                value={pointsToAdd}
                onChange={(e) => setPointsToAdd(e.target.value)}
              />
            </div>
            <div className="flex justify-end space-x-2">
              <Button
                variant="outline"
                onClick={() => setIsPointsDialogOpen(false)}
              >
                Annulla
              </Button>
              <Button onClick={handleAddPoints}>Aggiungi</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isEditPointsDialogOpen}
        onOpenChange={setIsEditPointsDialogOpen}
      >
        <DialogContent className="sm:max-w-[400px]">
          <DialogHeader>
            <DialogTitle>Modifica Punti</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="editPoints">Punti</Label>
              <Input
                id="editPoints"
                type="number"
                min="0"
                value={editPointsValue}
                onChange={(e) => setEditPointsValue(e.target.value)}
              />
            </div>
            <div className="flex justify-between">
              <Button
                variant="outline"
                onClick={async () => {
                  try {
                    await resetPoints();
                    fetchViolations();
                    setIsEditPointsDialogOpen(false);
                    toast.success("Punti azzerati con successo");
                  } catch (error) {
                    toast.error(error.message);
                  }
                }}
              >
                Azzera
              </Button>
              <div className="space-x-2">
                <Button
                  variant="outline"
                  onClick={() => setIsEditPointsDialogOpen(false)}
                >
                  Annulla
                </Button>
                <Button
                  onClick={async () => {
                    try {
                      await updatePoints(parseInt(editPointsValue));
                      fetchViolations();
                      setIsEditPointsDialogOpen(false);
                      toast.success("Punti aggiornati con successo");
                    } catch (error) {
                      toast.error(error.message);
                    }
                  }}
                >
                  Salva
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Violations Dialog */}
      <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
        <DialogContent className="sm:max-w-[600px] w-full max-h-[90vh] overflow-auto">
          <DialogHeader>
            <DialogTitle>
              {editingLicense ? "Modifica Violazione" : "Aggiungi Violazione"}
            </DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="controlNumber">Controllo n.</Label>
              <Input
                id="controlNumber"
                value={
                  editingLicense?.controlNumber || newLicense.controlNumber
                }
                onChange={(e) => {
                  if (editingLicense) {
                    setEditingLicense({
                      ...editingLicense,
                      controlNumber: e.target.value
                    });
                  } else {
                    setNewLicense({
                      ...newLicense,
                      controlNumber: e.target.value
                    });
                  }
                }}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="date">Data</Label>
              <Input
                id="date"
                type="date"
                value={editingLicense?.date?.split("T")[0] || newLicense.date}
                onChange={(e) => {
                  if (editingLicense) {
                    setEditingLicense({
                      ...editingLicense,
                      date: e.target.value
                    });
                  } else {
                    setNewLicense({ ...newLicense, date: e.target.value });
                  }
                }}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="constructionSite">Cantiere</Label>
              <Select
                value={
                  editingLicense?.constructionSiteId ||
                  newLicense.constructionSiteId
                }
                onValueChange={(value) => {
                  if (editingLicense) {
                    setEditingLicense({
                      ...editingLicense,
                      constructionSiteId: value
                    });
                  } else {
                    setNewLicense({ ...newLicense, constructionSiteId: value });
                  }
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona un cantiere" />
                </SelectTrigger>
                <SelectContent>
                  {constructionSites.map((site) => (
                    <SelectItem key={site._id} value={site._id}>
                      {site.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label>Violazioni</Label>
              <ScrollArea className="h-[200px] w-full rounded-md border p-4">
                {violations.map((violation) => (
                  <div
                    key={violation.id}
                    className="flex items-center space-x-2 mb-2"
                  >
                    <Checkbox
                      id={`violation-${violation.id}`}
                      checked={
                        (editingLicense?.violations || []).includes(
                          violation.id
                        ) ||
                        (newLicense.violations || []).includes(violation.id)
                      }
                      onCheckedChange={() =>
                        handleViolationChange(violation.id)
                      }
                    />
                    <label
                      htmlFor={`violation-${violation.id}`}
                      className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {violation.description} ({violation.points} punti)
                    </label>
                  </div>
                ))}
              </ScrollArea>
            </div>
            <div className="space-y-2">
              <Label>Variazione punti</Label>
              <div className="font-bold">
                {editingLicense?.pointsVariation || newLicense.pointsVariation}
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="note">Note</Label>
              <Input
                id="note"
                type="text"
                placeholder="Aggiungi una nota"
                value={editingLicense?.note || newLicense.note || ""}
                onChange={(e) => {
                  if (editingLicense) {
                    setEditingLicense({
                      ...editingLicense,
                      note: e.target.value
                    });
                  } else {
                    setNewLicense({ ...newLicense, note: e.target.value });
                  }
                }}
              />
            </div>
            <div className="pt-4 flex justify-end space-x-2">
              <Button
                variant="outline"
                onClick={handleModalClose}
                disabled={isLoading}
              >
                Annulla
              </Button>
              <Button
                onClick={editingLicense ? handleEditLicense : handleAddLicense}
                disabled={isLoading}
              >
                {isLoading
                  ? "Caricamento..."
                  : editingLicense
                  ? "Salva Modifiche"
                  : "Aggiungi Violazione"}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="sm:max-w-[400px]">
          <DialogHeader>
            <DialogTitle>Conferma Eliminazione</DialogTitle>
          </DialogHeader>
          <p>
            Sei sicuro di voler eliminare questa violazione? Questa azione non
            può essere annullata.
          </p>
          <div className="flex justify-end space-x-2 pt-4">
            <Button
              variant="outline"
              onClick={() => setIsDeleteDialogOpen(false)}
            >
              Annulla
            </Button>
            <Button
              variant="destructive"
              onClick={handleDeleteLicense}
              disabled={isLoading}
            >
              {isLoading ? "Eliminazione..." : "Elimina"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {isLoading && !licenses.length ? (
        <Spinner />
      ) : (
        <div className="rounded border">
          <EnhancedTable
            items={licenses}
            sortConfig={sortConfig}
            onSortChange={setSortConfig}
            selectedItems={selectedItems}
            onSelectChange={setSelectedItems}
            selectAll={selectAll}
            onSelectAllChange={setSelectAll}
          >
            <TableHeader>
              <TableRow>
                <SelectAllTableHead />
                <SortableTableHead>#</SortableTableHead>
                <SortableTableHead sortKey="controlNumber">
                  Controllo n.
                </SortableTableHead>
                <SortableTableHead sortKey="date">Data</SortableTableHead>
                <SortableTableHead sortKey="constructionSiteId">
                  Cantiere
                </SortableTableHead>
                <SortableTableHead>Violazioni</SortableTableHead>
                <SortableTableHead sortKey="pointsVariation">
                  Variazione punti
                </SortableTableHead>
                <SortableTableHead>Note</SortableTableHead>
                <SortableTableHead></SortableTableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {licenses.map((license, index) => (
                <SelectableTableRow key={license._id} item={license}>
                  <SelectionTableCell item={license} />
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {!license.isPointAddition ? license.controlNumber : "-"}
                  </TableCell>
                  <TableCell>
                    {new Date(license.date).toLocaleDateString("it-IT")}
                  </TableCell>
                  <TableCell>
                    {!license.isPointAddition
                      ? license.constructionSiteId?.name ||
                        constructionSites.find(
                          (site) => site._id === license.constructionSiteId
                        )?.name ||
                        "N/A"
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {!license.isPointAddition
                      ? license.violations?.length || 0
                      : "-"}
                  </TableCell>
                  <TableCell
                    className={
                      (license.pointsVariation || 0) < 0
                        ? "text-red-500"
                        : "text-green-500"
                    }
                  >
                    {license.pointsVariation > 0
                      ? `+${license.pointsVariation}`
                      : license.pointsVariation}
                  </TableCell>
                  <TableCell>{license.note || "-"}</TableCell>
                  <ActionsTableCell>
                    {!license.isPointAddition ? (
                      <>
                        <DropdownMenuItem
                          onClick={() => handleOpenEdit(license)}
                        >
                          <Edit className="h-4 w-4 mr-2" />
                          Modifica
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => handleDeleteConfirmation(license._id)}
                        >
                          <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                          Elimina
                        </DropdownMenuItem>
                      </>
                    ) : (
                      <DropdownMenuItem
                        onClick={() => handleDeletePoints(license._id)}
                      >
                        <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                        Elimina punti
                      </DropdownMenuItem>
                    )}
                  </ActionsTableCell>
                </SelectableTableRow>
              ))}
              {!licenses.length && !isLoading && (
                <TableRow>
                  <TableCell colSpan={9} className="text-center py-4">
                    Nessuna violazione registrata
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </EnhancedTable>
        </div>
      )}
    </div>
  );
}
