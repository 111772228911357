import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../ui/tabs";
import Information from "./information/Information";
import Document from "./document";
import Property from "./property";
import Structure from "./structure";
import Licence from "./licence";

const AGENCY_TAB = ["Dati Azienda", "Documenti", "Proprietà", "Patente a punti", "Organigramma"];
const DEFAULT_TAB = "dati azienda";

const AgencyComponent = () => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB);

  useEffect(() => {
    if (tab) {
      const lowercaseTab = tab.toLowerCase();
      if (AGENCY_TAB.map(t => t.toLowerCase()).includes(lowercaseTab)) {
        setActiveTab(lowercaseTab);
      } else {
        navigate(`/agency/${DEFAULT_TAB}`, { replace: true });
      }
    } else {
      navigate(`/agency/${DEFAULT_TAB}`, { replace: true });
    }
  }, [tab, navigate]);

  const handleTabChange = (value) => {
    navigate(`/agency/${value.toLowerCase()}`);
  };

  return (
    <div className="h-full md:p-6 p-2 pt-1 pb-7">
      <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
        <div className="border-b border-gray-200">
          <TabsList className="flex justify-start h-auto p-0 bg-transparent space-x-8">
            {AGENCY_TAB.map((data) => (
              <TabsTrigger
                key={data}
                value={data.toLowerCase()}
                className="py-4 px-1 text-sm font-medium text-gray-500 hover:text-gray-700 relative data-[state=active]:text-slate-600 data-[state=active]:font-medium bg-transparent before:absolute before:h-0.5 before:w-full before:bg-slate-700 before:bottom-0 before:left-0 before:scale-x-0 data-[state=active]:before:scale-x-100 before:transition-transform"
              >
                {data}
              </TabsTrigger>
            ))}
          </TabsList>
        </div>
        <div className="py-4">
          <TabsContent value="dati azienda">
            <Information />
          </TabsContent>
          <TabsContent value="documenti">
            <Document />
          </TabsContent>
          <TabsContent value="proprietà">
            <Property />
          </TabsContent>
          <TabsContent value="patente a punti">
            <Licence />
          </TabsContent>
          <TabsContent value="organigramma">
            <Structure />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  );
};

export default AgencyComponent;