import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchCurrentSubscription } from "../../apis/SubscriptionApi";
import { createJsonTypeInstance } from "../../apis/index";
import { Alert, AlertTitle, AlertDescription } from "../../components/ui/alert";
import { useSubscription } from "./SubscriptionContext";

const SubscriptionSuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isNewRegistration, setIsNewRegistration] = useState(false);
  const { setSubscription } = useSubscription();

  useEffect(() => {
    const handleSubscriptionSuccess = async () => {
      const axiosInstance = createJsonTypeInstance();
      try {
        const searchParams = new URLSearchParams(location.search);
        const sessionId = searchParams.get("session_id");
        const source = searchParams.get("source");

        setIsNewRegistration(source === "registration");

        if (sessionId) {
          //console.log(`Handling subscription success for session: ${sessionId}`);

          // Call the backend to handle subscription success
          const response = await axiosInstance.get(
            `/subscription/success?session_id=${sessionId}`
          );
          //console.log('Subscription success response:', response.data);

          if (response.data.success) {
            // Fetch the updated subscription details
            const updatedSubscription = await fetchCurrentSubscription();
            //console.log('Fetched updated subscription:', updatedSubscription);
            setSubscription(updatedSubscription);

            // Perform redirection after a short delay
            setTimeout(() => {
              if (isNewRegistration) {
                navigate("/login");
              } else {
                navigate("/dashboard");
              }
            }, 3000); // 3 seconds delay
          } else {
            throw new Error(
              response.data.message || "Failed to activate subscription"
            );
          }
        } else {
          console.error("No session_id found in URL");
          setError("Invalid session. Please try subscribing again.");
        }
      } catch (err) {
        console.error("Error handling subscription success:", err);
        setError(
          "Si è verificato un errore nell'attivazione dell'abbonamento. Riprova più tardi."
        );
      } finally {
        setLoading(false);
      }
    };

    handleSubscriptionSuccess();
  }, [location, navigate, setSubscription, isNewRegistration]);

  if (loading) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-6 text-center">
          Aggiornamento Abbonamento in Corso...
        </h1>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto p-4">
        <Alert variant="destructive">
          <AlertTitle>Errore</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <Alert variant="success" className="mb-6">
        <AlertTitle>Abbonamento Attivato con Successo!</AlertTitle>
        <AlertDescription>
          {isNewRegistration
            ? "Grazie per esserti registrato e aver attivato il tuo abbonamento. Sarai reindirizzato alla pagina di login tra pochi secondi."
            : "Grazie per aver aggiornato il tuo abbonamento. Sarai reindirizzato alla dashboard tra pochi secondi."}
        </AlertDescription>
      </Alert>
    </div>
  );
};

export default SubscriptionSuccessPage;
