import React, { useEffect, useState } from "react";
import {
  Edit,
  Trash2,
  Filter,
  ArrowUpCircle,
  ArrowDownCircle
} from "lucide-react";
import {
  fetchMovimenti,
  addMovimenti,
  updateMovimenti,
  sumEntra,
  sumEntraPrevious,
  sumUscita,
  sumUscitaPrevious,
  sumAll,
  sumAllPrevious,
  importMovimenti,
  deleteMovimenti
} from "../../../apis/Movimenti";
import { toast } from "react-toastify";
import { exportToExcel, exportToPDF } from "../../../config/helper";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../../ui/tooltip";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import {
  EnhancedTable,
  TableHeader,
  TableBody,
  TableCell,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import { Card, CardContent } from "../../ui/card";
import { DropdownMenuItem } from "../../ui/dropdown-menu";

import SearchBar from "../sharedComponent/SearchBar";
import Pagination from "../../admin/sharedComponent/Pgination";
import HelpSheet from "../sharedComponent/HelpSheet";
import ImportDialog from "./ImportModal";

function TableComponent() {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalEntraImporto, setTotalEntraImporto] = useState(0);
  const [totalEntraPreviousImporto, setTotalEntraPreviousImporto] = useState(0);
  const [totalUscitaImporto, setTotalUscitaImporto] = useState(0);
  const [totalUscitaPreviousImporto, setTotalUscitaPreviousImporto] =
    useState(0);
  const [totalAllImporto, setTotalAllImporto] = useState(0);
  const [totalAllPreviousImporto, setTotalAllPreviousImporto] = useState(0);
  const [formData, setFormData] = useState({
    entraUscita: "",
    movdata: "",
    nome: "",
    importo: "",
    riferimento: "",
    companyId: companyId,
    idAzienda: "2424"
  });
  const [formDataEdit, setFormDataEdit] = useState({
    _id: "",
    entraUscita: "",
    movdata: "",
    nome: "",
    importo: "",
    riferimento: "",
    idAzienda: "2424"
  });
  const [showFiltersDialog, setShowFiltersDialog] = useState(false);
  const [filters, setFilters] = useState({
    dateFrom: "",
    dateTo: "",
    status: ""
  });

  const [importFile, setImportFile] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);

  // Enhanced Table state
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const handleFileChange = (e) => {
    setImportFile(e.target.files[0]);
  };

  // Apply sorting when sort configuration changes
  useEffect(() => {
    if (sortConfig.key && filteredData.length > 0) {
      const sortedData = [...filteredData].sort((a, b) => {
        let aValue, bValue;

        // Handle special cases for different columns
        if (
          sortConfig.key === "valueDate" ||
          sortConfig.key === "bookingDate"
        ) {
          aValue = a[sortConfig.key]
            ? new Date(a[sortConfig.key]).getTime()
            : 0;
          bValue = b[sortConfig.key]
            ? new Date(b[sortConfig.key]).getTime()
            : 0;
        } else if (sortConfig.key === "amount") {
          aValue = parseFloat(a[sortConfig.key]) || 0;
          bValue = parseFloat(b[sortConfig.key]) || 0;
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }

        // Handle string comparison
        if (typeof aValue === "string") {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });

      setFilteredData(sortedData);
    }
  }, [sortConfig]);

  const handleImport = async () => {
    if (!importFile) {
      toast.error("Please select a file to import");
      return;
    }

    const formData = new FormData();
    formData.append("file", importFile);
    formData.append("companyId", companyId);

    try {
      const response = await importMovimenti(formData);
      toast.success(response.data.message);
      fetchData(); // Refresh the data after import
    } catch (error) {
      console.error("Error importing data:", error);
      toast.error("Error importing data");
    }
  };

  const handleExport = async (value) => {
    try {
      const formattedData = filteredData.map((item, index) => ({
        nr: (index + 1).toString(),
        data: item.valueDate
          ? new Date(item.valueDate)
              .toLocaleDateString("it-IT", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
              })
              .replace(/\//g, "-")
          : "",
        nome: item.name || "",
        importo: item.amount
          ? `€ ${parseFloat(item.amount).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`
          : "€ 0,00",
        riferimento: item.description || "",
        stato: parseFloat(item.amount) > 0 ? "Entrata" : "Uscita"
      }));

      const columns = [
        // { header: "Nr", key: "nr", width: 0.5 },
        { header: "Data contabile", key: "dataContabile", width: 1 },
        { header: "Data valuta", key: "dataValuta", width: 1 },
        { header: "Nome", key: "nome", width: 2 },
        { header: "Importo", key: "importo", width: 1 },
        { header: "Valuta", key: "valuta", width: 1 },
        { header: "Stato", key: "stato", width: 1 },
        { header: "ID transazione", key: "transazione", width: 1 }
      ];

      // Calculate totals
      const totals = filteredData.reduce(
        (acc, item) => {
          const amount = parseFloat(item.amount) || 0;
          if (amount > 0) {
            acc.entrate += amount;
          } else if (amount < 0) {
            acc.uscite += Math.abs(amount);
          }
          return acc;
        },
        { entrate: 0, uscite: 0 }
      );

      const stats = [
        { label: "Totale Movimenti", value: filteredData.length },
        {
          label: "Periodo",
          value:
            filters.dateFrom && filters.dateTo
              ? `Dal ${new Date(filters.dateFrom)
                  .toLocaleDateString("it-IT")
                  .replace(/\//g, "-")} al ${new Date(filters.dateTo)
                  .toLocaleDateString("it-IT")
                  .replace(/\//g, "-")}`
              : new Date().toLocaleDateString("it-IT").replace(/\//g, "-")
        }
      ];

      const summaries = [
        {
          label: "Totale Entrate",
          value: `€ ${totals.entrate.toLocaleString("it-IT", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
        },
        {
          label: "Totale Uscite",
          value: `€ ${totals.uscite.toLocaleString("it-IT", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
        },
        {
          label: "Saldo",
          value: `€ ${(totals.entrate - totals.uscite).toLocaleString("it-IT", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
        }
      ];

      if (value === "excel") {
        exportToExcel(formattedData, "Movimenti");
      } else if (value === "pdf") {
        await exportToPDF(
          formattedData,
          columns,
          "Lista Movimenti",
          stats,
          undefined,
          summaries
        );
      }

      toast.success(
        `Esportazione ${value.toUpperCase()} completata con successo`
      );
    } catch (error) {
      console.error("Errore durante l'esportazione:", error);
      toast.error(`Errore durante l'esportazione ${value.toUpperCase()}`);
    }
  };
  function handleAddClick() {
    setShowModal(true);
  }

  const handleEditClick = (item) => {
    setShowModalEdit(true);
    setFormDataEdit({
      _id: item._id || "",
      entraUscita: item.entraUscita || "",
      movdata: item.movdata || "",
      nome: item.nome || "",
      importo: item.importo || "",
      riferimento: item.riferimento || "",
      idAzienda: item.idAzienda || "2424"
    });
  };

  function handleCloseModal() {
    setShowModal(false);
    setShowModalEdit(false);
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleChangeEdit = (e) => {
    setFormDataEdit({
      ...formDataEdit,
      [e.target.name]: e.target.value
    });
  };

  const fetchData = async () => {
    try {
      const response = await fetchMovimenti(companyId, currentPage, limit);
      if (response.data) {
        setData(response.data.movimentis);
        setFilteredData(response.data.movimentis);
        setDataIsLoaded(true);
        setTotalPage(response.data.totalPages);

        // If current page is greater than total pages, reset to page 1
        if (currentPage > response.data.totalPages) {
          setCurrentPage(1);
        } else {
          setCurrentPage(response.data.currentPage);
        }
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
      toast.error("Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, limit]);

  const sumEntradata = async () => {
    try {
      const response = await sumEntra(companyId);
      setTotalEntraImporto(response.data.totalImporto);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const sumEntraPreviousdata = async () => {
    try {
      const response = await sumEntraPrevious(companyId);
      setTotalEntraPreviousImporto(response.data.totalImportoPrevious);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const sumUscitadata = async () => {
    try {
      const response = await sumUscita(companyId);
      setTotalUscitaImporto(response.data.totalImporto);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const sumUscitaPreviousdata = async () => {
    try {
      const response = await sumUscitaPrevious(companyId);
      setTotalUscitaPreviousImporto(response.data.totalImportoPrevious);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const sumAlldata = async () => {
    try {
      const response = await sumAll(companyId);
      setTotalAllImporto(response.data.totalImporto);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const sumAllPreviousdata = async () => {
    try {
      const response = await sumAllPrevious(companyId);
      setTotalAllPreviousImporto(response.data.totalImportoPrevious);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  useEffect(() => {
    fetchData();
    sumEntradata();
    sumEntraPreviousdata();
    sumUscitadata();
    sumUscitaPreviousdata();
    sumAlldata();
    sumAllPreviousdata();
  }, []);

  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) return "0.00%";
    const change = ((current - previous) / previous) * 100;
    return `${change.toFixed(2)}%`;
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm.length >= 3) {
      const filtered = data.filter(
        (item) =>
          item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.description?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
      setCurrentPage(1); // Reset to first page when searching
    } else {
      setFilteredData(data);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addMovimenti(formData);
      if (response) {
        toast.success(response.data.message);
        fetchData();
        setShowModal(false);
      } else {
        toast.error("Failed to add movimenti!");
      }
    } catch (error) {
      console.error("Error adding movimenti:", error);
      toast.error("Error creating movimenti!");
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateMovimenti(formDataEdit._id, formDataEdit);
      setShowModalEdit(false);
      fetchData();
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error editing movimenti:", error);
      toast.error("Error editing movimenti!");
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const parseItalianDate = (dateString) => {
    if (!dateString) return null;
    const [year, month, day] = dateString.split("-");
    return new Date(year, month - 1, day);
  };

  const applyFilters = () => {
    let filtered = data;
    if (filters.dateFrom) {
      filtered = filtered.filter((item) => {
        const itemDate = parseItalianDate(item.movdata);
        const filterDate = parseItalianDate(filters.dateFrom);
        return itemDate >= filterDate;
      });
    }
    if (filters.dateTo) {
      filtered = filtered.filter((item) => {
        const itemDate = parseItalianDate(item.movdata);
        const filterDate = parseItalianDate(filters.dateTo);
        return itemDate <= filterDate;
      });
    }
    if (filters.status && filters.status !== "all") {
      filtered = filtered.filter((item) => item.entraUscita === filters.status);
    }
    setFilteredData(filtered);
    setShowFiltersDialog(false);
    // Reset to first page when applying filters
    setCurrentPage(1);
  };
  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      await deleteMovimenti(itemToDelete._id);
      toast.success("Movimenti deleted successfully");
      fetchData();
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting movimenti:", error);
      toast.error("Error deleting movimenti");
    }
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    try {
      const response = await fetchMovimenti(companyId, page, limit);
      if (response.data) {
        setData(response.data.movimentis);
        setFilteredData(response.data.movimentis);
        setTotalPage(response.data.totalPages);
      }
    } catch (error) {
      console.error("Error changing page:", error);
      toast.error("Error loading page data");
    }
  };

  const formatDateToDDMMYYYY = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div className="p-6 space-y-6">
      <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:justify-between lg:items-center w-full">
        {/* Left side buttons group */}
        <div className="grid grid-cols-2 md:grid-cols-4 lg:flex lg:flex-row gap-2 lg:items-center">
          {/* <Button onClick={handleAddClick} className="w-full lg:w-auto">
            Aggiungi
          </Button> */}

          {/* <Button
            variant="outline"
            onClick={() => setIsImportDialogOpen(true)}
            className="w-full lg:w-auto"
          >
            Importa
          </Button> */}

          <div className="w-full lg:w-auto">
            <Select onValueChange={handleExport}>
              <SelectTrigger className="w-full lg:w-[180px]">
                <SelectValue placeholder="Esporta" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="excel">Esporta in Excel</SelectItem>
                <SelectItem value="pdf">Esporta in PDF</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {/* Mobile Filter Button */}
          <Button
            variant="outline"
            onClick={() => setShowFiltersDialog(true)}
            className="w-full lg:hidden"
          >
            <Filter className="mr-2 h-4 w-4" /> Filtri
          </Button>
        </div>

        {/* Right side search and filters group */}
        <div className="flex flex-col md:flex-row gap-2 w-full lg:w-auto">
          <div className="flex-grow lg:flex-grow-0">
            <SearchBar onSearch={handleSearch} className="w-full" />
          </div>
          <div className="flex gap-2">
            {/* Desktop Filter Button */}
            <Button
              variant="outline"
              onClick={() => setShowFiltersDialog(true)}
              className="hidden lg:flex items-center"
            >
              <Filter className="mr-2 h-4 w-4" /> Filtri
            </Button>
            <div className="flex-shrink-0">
              <HelpSheet route="/movimenti" />
            </div>
          </div>
        </div>
      </div>

      <div className="rounded-md border">
        <EnhancedTable
          items={filteredData}
          sortConfig={sortConfig}
          onSortChange={setSortConfig}
          selectedItems={selectedItems}
          onSelectChange={setSelectedItems}
          selectAll={selectAll}
          onSelectAllChange={setSelectAll}
        >
          <TableHeader>
            <SelectAllTableHead />
            <SortableTableHead sortKey="valueDate">
              Data valuta
            </SortableTableHead>
            <SortableTableHead sortKey="bookingDate">
              Data contabile
            </SortableTableHead>
            <SortableTableHead sortKey="name">Nome</SortableTableHead>
            <SortableTableHead sortKey="amount" className="text-right">
              Importo
            </SortableTableHead>
            <SortableTableHead sortKey="currency">Valuta</SortableTableHead>
            <SortableTableHead sortKey="description">
              Descrizione
            </SortableTableHead>
            <SortableTableHead></SortableTableHead>
          </TableHeader>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <SelectableTableRow key={index} item={item}>
                  <SelectionTableCell item={item} />
                  <TableCell>{formatDateToDDMMYYYY(item.valueDate)}</TableCell>
                  <TableCell>
                    {item.bookingDate
                      ? formatDateToDDMMYYYY(item.bookingDate)
                      : "Waiting"}
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell
                    className={
                      parseFloat(item.amount) > 0
                        ? "text-green-500 text-right"
                        : "text-red-500 text-right"
                    }
                  >
                    {parseFloat(item.amount)}
                  </TableCell>
                  <TableCell>{item.currency}</TableCell>
                  <TableCell>
                    {item.description ? item?.description : "No description"}
                  </TableCell>
                  <ActionsTableCell>
                    {/* <DropdownMenuItem onClick={() => handleEditClick(item)} className="flex items-center">
                      <Edit className="h-4 w-4 mr-2" /> Modifica
                    </DropdownMenuItem> */}
                    <DropdownMenuItem
                      onClick={() => handleDeleteClick(item)}
                      className="flex items-center"
                    >
                      <Trash2 className="h-4 w-4 text-red-500 mr-2" /> Elimina
                    </DropdownMenuItem>
                  </ActionsTableCell>
                </SelectableTableRow>
              ))
            ) : (
              <SelectableTableRow>
                <TableCell colSpan={8} className="text-center py-4">
                  No data
                </TableCell>
              </SelectableTableRow>
            )}
          </TableBody>
        </EnhancedTable>
      </div>

      {filteredData?.length > 0 && (
        <div className="flex justify-end mt-4">
          <Pagination
            totalPages={totalPages} // Use the totalPages from API
            totalItems={data.length} // Use total items count from API if available
            onPageChange={handlePageChange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={(newLimit) => {
              setLimit(newLimit);
              setCurrentPage(1); // Reset to first page when limit changes
            }}
          />
        </div>
      )}

      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Aggiungi nuova voce</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="entraUscita">Entrata Uscita</Label>
                <Select
                  name="entraUscita"
                  value={formData.entraUscita}
                  onValueChange={(value) =>
                    handleChange({ target: { name: "entraUscita", value } })
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Entrata">Entrata</SelectItem>
                    <SelectItem value="Uscita">Uscita</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="movdata">Data</Label>
                <Input type="date" name="movdata" onChange={handleChange} />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="nome">Nome</Label>
                <Input
                  type="text"
                  name="nome"
                  id="nome"
                  onChange={handleChange}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="importo">Importo</Label>
                <Input
                  type="number"
                  step="0.01"
                  name="importo"
                  id="importo"
                  value={formData.importo}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="riferimento">Riferimento</Label>
              <Input
                type="text"
                name="riferimento"
                id="riferimento"
                onChange={handleChange}
              />
            </div>
            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button type="submit">Add Movimenti</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={showDeleteModal} onOpenChange={setShowDeleteModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conferma eliminazione</DialogTitle>
          </DialogHeader>
          <p>Sei sicuro di voler eliminare questo movimento?</p>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowDeleteModal(false)}>
              Annulla
            </Button>
            <Button variant="destructive" onClick={handleDelete}>
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={showModalEdit} onOpenChange={setShowModalEdit}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Modifica voce</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmitEdit} className="space-y-4">
            <input type="hidden" value={formDataEdit._id} />
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="entraUscita">Entra Uscita</Label>
                <Select
                  name="entraUscita"
                  value={formDataEdit.entraUscita}
                  onValueChange={(value) =>
                    handleChangeEdit({ target: { name: "entraUscita", value } })
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Entrata">Entrata</SelectItem>
                    <SelectItem value="Uscita">Uscita</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="movdata">Data</Label>
                <Input
                  type="date"
                  name="movdata"
                  value={formDataEdit.movdata}
                  onChange={handleChangeEdit}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="nome">Nome</Label>
                <Input
                  type="text"
                  name="nome"
                  id="nome"
                  value={formDataEdit.nome}
                  onChange={handleChangeEdit}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="importo">Importo</Label>
                <Input
                  type="text"
                  name="importo"
                  id="importo"
                  value={formDataEdit.importo}
                  onChange={handleChangeEdit}
                />
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="riferimento">Riferimento</Label>
              <Input
                type="text"
                name="riferimento"
                id="riferimento"
                value={formDataEdit.riferimento}
                onChange={handleChangeEdit}
              />
            </div>
            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button type="submit">Edit</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={showFiltersDialog} onOpenChange={setShowFiltersDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Filtri</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="dateFrom">Da Data</Label>
                <Input
                  type="date"
                  id="dateFrom"
                  name="dateFrom"
                  value={filters.dateFrom}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="dateTo">A Data</Label>
                <Input
                  type="date"
                  id="dateTo"
                  name="dateTo"
                  value={filters.dateTo}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="status">Stato</Label>
              <Select
                name="status"
                value={filters.status}
                onValueChange={(value) =>
                  handleFilterChange({ target: { name: "status", value } })
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona stato" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">Tutti</SelectItem>
                  <SelectItem value="credit">Entra</SelectItem>
                  <SelectItem value="debit">Uscita</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setShowFiltersDialog(false)}
            >
              Annulla
            </Button>
            <Button onClick={applyFilters}>Applica Filtri</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <ImportDialog
        open={isImportDialogOpen}
        onOpenChange={setIsImportDialogOpen}
        onImportComplete={fetchData}
      />
    </div>
  );
}

export default TableComponent;
