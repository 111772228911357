import React, { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis
} from "recharts";
import { getConstructionSites } from "../../../apis/ConstructionSite";
import Spinner from "../../Spinner";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";

const CATEGORY_COLORS = {
  manodopera: "#35b3f0",
  materiali: "#097bb2",
  attrezzatura: "#09384e"
};

const MONTHS = [
  "Gen", "Feb", "Mar", "Apr", "Mag", "Giu",
  "Lug", "Ago", "Set", "Ott", "Nov", "Dic"
];

const Budget = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [budgetData, setBudgetData] = useState({
    totalBudget: 0,
    totalExpenses: 0,
    activeProjects: 0,
    laborCost: 0,
    materialsCost: 0,
    equipmentCost: 0,
    monthlyExpenses: [],
    nearestDeadline: null,
    farthestDeadline: null
  });
  const [rawSitesData, setRawSitesData] = useState([]);
  // Filter state: selected month (number, 0-indexed) and selected construction site code.
  const [filterMonth, setFilterMonth] = useState(new Date().getMonth());
  const [selectedSiteCode, setSelectedSiteCode] = useState("all");

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (rawSitesData.length === 0) return;
    const currentYear = new Date().getFullYear();
    const startDate = new Date(currentYear - 1, filterMonth, 1);
    const endDate = new Date(currentYear, filterMonth + 1, 0);

    // Generate timeline array covering each month from startDate to endDate.
    const timeline = [];
    let temp = new Date(startDate);
    while (temp <= endDate) {
      const label = `${MONTHS[temp.getMonth()]} ${temp.getFullYear()}`;
      timeline.push({
        key: `${temp.getFullYear()}-${temp.getMonth()}`,
        month: label,
        manodopera: 0,
        materiali: 0,
        attrezzatura: 0
      });
      temp = new Date(temp.getFullYear(), temp.getMonth() + 1, 1);
    }

    // Filter sites by selected site code if needed.
    const sitesToAggregate =
      selectedSiteCode === "all"
        ? rawSitesData
        : rawSitesData.filter(site => site.code === selectedSiteCode);

    sitesToAggregate.forEach(site => {
      site.accountingItems?.forEach(item => {
        if (!item.date) return;
        const itemDate = new Date(item.date);
        if (itemDate >= startDate && itemDate <= endDate) {
          const key = `${itemDate.getFullYear()}-${itemDate.getMonth()}`;
          const index = timeline.findIndex(t => t.key === key);
          if (index >= 0) {
            const total = parseFloat(item.total) || 0;
            if (item.category === "manodopera") {
              timeline[index].manodopera += total;
            } else if (item.category === "materiali") {
              timeline[index].materiali += total;
            } else if (item.category === "attrezzatura") {
              timeline[index].attrezzatura += total;
            }
          }
        }
      });
    });
    setBudgetData(prev => ({ ...prev, monthlyExpenses: timeline }));
  }, [rawSitesData, filterMonth, selectedSiteCode]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const { data } = await getConstructionSites(user.companyId);

      if (!data?.sites?.length) {
        setIsLoading(false);
        return;
      }

      const activeSites = data.sites.filter(
        site => site.status !== "Terminato"
      );

      const totals = activeSites.reduce(
        (acc, site) => {
          acc.totalBudget += parseFloat(site.budget) || 0;
          site.accountingItems?.forEach(item => {
            if (!item.date) return;
            const total = parseFloat(item.total) || 0;
            acc.totalExpenses += total;
            if (item.category === "manodopera") {
              acc.laborCost += total;
            } else if (item.category === "materiali") {
              acc.materialsCost += total;
            } else if (item.category === "attrezzatura") {
              acc.equipmentCost += total;
            }
          });
          return acc;
        },
        {
          totalBudget: 0,
          totalExpenses: 0,
          laborCost: 0,
          materialsCost: 0,
          equipmentCost: 0
        }
      );

      const deadlines = activeSites
        .map(site => (site.closingDate ? new Date(site.closingDate) : null))
        .filter(date => date && !isNaN(date.getTime()))
        .sort((a, b) => a - b);

      setBudgetData({
        ...totals,
        activeProjects: activeSites.length,
        monthlyExpenses: [],
        nearestDeadline: deadlines[0],
        farthestDeadline: deadlines[deadlines.length - 1]
      });
      setRawSitesData(activeSites);
    } catch (error) {
      console.error("Error fetching budget data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  const formatDate = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleDateString("it-IT", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });
  };

  const pieData = [
    { name: "Manodopera", value: budgetData.laborCost },
    { name: "Materiali", value: budgetData.materialsCost },
    { name: "Attrezzatura", value: budgetData.equipmentCost }
  ];

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="p-2 md:p-4">
      {/* Main Chart + Stats Section */}
      <div className="flex flex-col md:flex-row">
        {/* Pie Chart Section */}
        <div className="w-full md:w-1/3 mb-3 md:mb-0">
          <div className="relative">
            <ResponsiveContainer width="100%" height={220}>
              <PieChart>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  outerRadius={90}
                  dataKey="value"
                  labelLine={false}
                  label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
                >
                  <Cell fill={CATEGORY_COLORS.manodopera} />
                  <Cell fill={CATEGORY_COLORS.materiali} />
                  <Cell fill={CATEGORY_COLORS.attrezzatura} />
                </Pie>
                <Tooltip
                  formatter={(value) => formatCurrency(value)}
                  contentStyle={{
                    backgroundColor: "#fff",
                    border: "none",
                    borderRadius: "4px"
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
            <div className="flex flex-wrap justify-center items-center gap-2 mt-1">
              <div className="flex items-center gap-1">
                <div className="w-3 h-3" style={{ backgroundColor: CATEGORY_COLORS.manodopera }} />
                <span className="text-xs">Manodopera</span>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-3 h-3" style={{ backgroundColor: CATEGORY_COLORS.materiali }} />
                <span className="text-xs">Materiali</span>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-3 h-3" style={{ backgroundColor: CATEGORY_COLORS.attrezzatura }} />
                <span className="text-xs">Attrezzatura</span>
              </div>
            </div>
          </div>
        </div>
        {/* Stats Grid */}
        <div className="w-full md:w-2/3 flex justify-center items-center">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-y-3 gap-x-2 w-full">
            {[
              ["Budget totale cantieri", budgetData.totalBudget],
              ["Spesa totale nei cantieri", budgetData.totalExpenses],
              ["Cantieri in corso", budgetData.activeProjects],
              ["Spesa totale manodopera", budgetData.laborCost],
              ["Spesa totale materiali", budgetData.materialsCost],
              ["Spesa totale attrezzatura", budgetData.equipmentCost]
            ].map(([label, value], index) => (
              <div key={index} className="px-1">
                <div className="flex items-center gap-1">
                  <div className="w-2 h-2 rounded-full bg-black" />
                  <span className="text-sm font-medium text-gray-600">{label}</span>
                </div>
                <p className="text-base font-semibold mt-0.5">
                  {typeof value === "number" && !label.includes("Cantieri")
                    ? formatCurrency(value)
                    : value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Additional Data Section */}
      <div className="my-3 md:my-6">
        <h2 className="text-base font-semibold mb-2">Altri dati</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 rounded-full bg-black" />
              <span className="text-sm font-medium text-gray-600">
                Scadenza cantiere più recente
              </span>
            </div>
            <p className="text-base font-semibold mt-0.5">
              {formatDate(budgetData.nearestDeadline)}
            </p>
          </div>
          <div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 rounded-full bg-black" />
              <span className="text-sm font-medium text-gray-600">
                Scadenza cantiere più lontana
              </span>
            </div>
            <p className="text-base font-semibold mt-0.5">
              {formatDate(budgetData.farthestDeadline)}
            </p>
          </div>
        </div>
      </div>

      {/* Header and responsive filters using shadcn UI Selects */}
      <div className="flex flex-wrap items-center justify-between mb-2 gap-2">
        <h2 className="text-base font-semibold">Andamento delle spese nel tempo</h2>
        <div className="flex gap-2">
          <Select
            value={filterMonth.toString()}
            onValueChange={(value) => setFilterMonth(Number(value))}
          >
            <SelectTrigger className="h-8 text-sm w-24 sm:w-28">
              <SelectValue placeholder="Mese" />
            </SelectTrigger>
            <SelectContent>
              {MONTHS.map((m, index) => (
                <SelectItem key={index} value={index.toString()}>
                  {m}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select
            value={selectedSiteCode}
            onValueChange={setSelectedSiteCode}
          >
            <SelectTrigger className="h-8 text-sm w-24 sm:w-28">
              <SelectValue placeholder="Cantiere" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Tutti</SelectItem>
              {Array.from(
                new Set(rawSitesData.map(site => site.code).filter(code => code))
              ).map((code, index) => (
                <SelectItem key={index} value={code}>
                  {code}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      {/* Area Chart Section */}
      <div className="mt-3">
        <div className="w-full overflow-x-auto">
          <div className="h-[300px] w-full">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={budgetData.monthlyExpenses}
                margin={{ top: 5, right: 5, left: 0, bottom: 15 }}
              >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis 
                  dataKey="month" 
                  tick={{ fontSize: 9, fontWeight: 500 }}
                  interval="preserveStartEnd"
                />
                <YAxis
                  tickFormatter={(value) => formatCurrency(value)}
                  tick={{ fontSize: 9, fontWeight: 500 }}
                  width={70}
                />
                <Tooltip
                  formatter={(value, name) => [
                    formatCurrency(value),
                    name.charAt(0).toUpperCase() + name.slice(1)
                  ]}
                  contentStyle={{
                    backgroundColor: "#fff",
                    border: "none",
                    borderRadius: "4px"
                  }}
                />
                <Area
                  type="monotone"
                  dataKey="manodopera"
                  stackId="1"
                  stroke={CATEGORY_COLORS.manodopera}
                  fill={CATEGORY_COLORS.manodopera}
                  fillOpacity={0.1}
                />
                <Area
                  type="monotone"
                  dataKey="materiali"
                  stackId="1"
                  stroke={CATEGORY_COLORS.materiali}
                  fill={CATEGORY_COLORS.materiali}
                  fillOpacity={0.1}
                />
                <Area
                  type="monotone"
                  dataKey="attrezzatura"
                  stackId="1"
                  stroke={CATEGORY_COLORS.attrezzatura}
                  fill={CATEGORY_COLORS.attrezzatura}
                  fillOpacity={0.1}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Budget;