import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  getConstructionSites,
  createConstructionSite,
  deleteConstructionSite,
  archiveConstructionSite,
  unarchiveConstructionSite
} from "../../../apis/ConstructionSite";
import {
  Trash,
  Eye,
  ArchiveRestore,
  Archive,
  AlertTriangle
} from "lucide-react";
import { toast } from "react-toastify";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../../ui/tooltip";
import { ScrollArea } from "../../ui/scroll-area";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { DropdownMenuItem } from "../../ui/dropdown-menu";
import {
  EnhancedTable,
  TableHeader,
  TableBody,
  TableCell,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem, 
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import { Textarea } from "../../ui/textarea";

import SearchBar from "../sharedComponent/SearchBar";
import Pagination from "../sharedComponent/Pgination";
import HelpSheet from "../sharedComponent/HelpSheet";
import { exportToExcel, exportToPDF } from "../../../config/helper";

const getUserFromLocalStorage = () => {
  const userString = localStorage.getItem("user");
  if (!userString) return null;

  const user = JSON.parse(userString);
  if (!user.companyId) {
    console.warn("User does not have a companyId");
    user.companyId = "default";
  }
  return user;
};

const RequiredLabel = ({ htmlFor, children }) => (
  <Label htmlFor={htmlFor} className="flex items-center gap-1 mb-2">
    {children}
    <span className="text-red-500">*</span>
  </Label>
);

function Cantieri() {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [siteToDelete, setSiteToDelete] = useState(null);
  const [sites, setSites] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [clients, setClients] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [filteredSites, setFilteredSites] = useState([]);
  const [password, setPassword] = useState("");
  const [deleteError, setDeleteError] = useState("");
  const [selectedSites, setSelectedSites] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [newSite, setNewSite] = useState({
    name: "",
    code: "",
    client: "",
    address: "",
    amount: "",
    budget: "",
    workType: "",
    description: "",
    city: "",
    postalCode: "",
    province: "",
    country: "",
    featuredImage: null,
    openingDate: "",
    closingDate: "",
    status: "In pianificazione"
  });

  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [siteToArchive, setSiteToArchive] = useState(null);
  const [archiveReason, setArchiveReason] = useState("");
  const [isArchiving, setIsArchiving] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    fetchSites();
  }, [currentPage, limit]);

  const formattedAddress = (site) => {
    return `${site.address}, ${site.city}, ${site.postalCode}, ${site.country}`;
  };

  useEffect(() => {
    if (searchTerm.length >= 1) {
      const filtered = sites.filter(
        (site) =>
          (site.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            site.client?.fullName
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            site.address?.toLowerCase().includes(searchTerm.toLowerCase())) &&
          site.status !== "Archiviato"
      );
      setFilteredSites(filtered);
    } else {
      const nonArchivedSites = sites.filter(
        (site) => site.status !== "Archiviato"
      );
      setFilteredSites(
        nonArchivedSites.slice((currentPage - 1) * limit, currentPage * limit)
      );
    }
  }, [sites, searchTerm, currentPage, limit]);

  // Apply sorting when sortConfig changes
  useEffect(() => {
    if (sortConfig.key && filteredSites.length > 0) {
      const sortedData = [...filteredSites].sort((a, b) => {
        let aValue, bValue;

        // Handle nested properties
        if (sortConfig.key === "client") {
          aValue = a.client?.fullName || "";
          bValue = b.client?.fullName || "";
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }

        // Handle string comparison
        if (typeof aValue === "string") {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });

      setFilteredSites(sortedData);
    }
  }, [sortConfig]);

  const fetchSites = async () => {
    try {
      const user = getUserFromLocalStorage();
      if (!user) {
        console.error("User information not found");
        return;
      }
      const skip = (currentPage - 1) * limit;
      const response = await getConstructionSites(
        user.companyId,
        currentPage,
        limit
      );

      const activeSites = response.data.sites.filter(
        (site) => site.status !== "Archiviato"
      );

      setSites(activeSites);
      setClients(response.data.clients);
      setTotalPages(Math.ceil(activeSites.length / limit));
      setTotalItems(activeSites.length);
    } catch (error) {
      console.error("Error fetching construction sites:", error);
      toast.error("Error loading construction sites");
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsAdding(true);

    try {
      const user = getUserFromLocalStorage();
      if (!user || !user.companyId) {
        throw new Error("User or company information not found");
      }

      // Validate required fields
      const requiredFields = ["name", "client", "openingDate"];

      const missingFields = requiredFields.filter((field) => !newSite[field]);

      if (missingFields.length > 0) {
        throw new Error(
          `Please fill in all required fields: ${missingFields.join(", ")}`
        );
      }

      // Create FormData object
      const formData = new FormData();

      // Append all fields to FormData
      Object.keys(newSite).forEach((key) => {
        if (key === "featuredImage" && newSite[key] instanceof File) {
          formData.append(key, newSite[key]);
        } else if (newSite[key] !== null && newSite[key] !== undefined) {
          formData.append(key, newSite[key].toString());
        }
      });

      // Add required fields that might be missing
      formData.append("companyId", user.companyId);
      formData.append("totalAmount", newSite.amount);

      // Make API call
      await createConstructionSite(formData);

      // Handle success
      toast.success("Construction site created successfully!");
      setIsModalOpen(false);

      // Reset form
      setNewSite({
        name: "",
        client: "",
        address: "",
        amount: "",
        workType: "",
        description: "",
        city: "",
        postalCode: "",
        province: "",
        country: "",
        featuredImage: null,
        openingDate: "",
        closingDate: "",
        status: "In pianificazione"
      });

      // Refresh the list
      fetchSites();
    } catch (error) {
      console.error("Error creating construction site:", error);
      toast.error(error.message || "Error creating construction site");
    } finally {
      setIsAdding(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setNewSite((prev) => ({
        ...prev,
        [name]: files[0]
      }));
    } else {
      setNewSite((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSelectChange = (name, value) => {
    setNewSite((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDeleteClick = (site) => {
    setSiteToDelete(site);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setIsDeleting(true);
    setDeleteError("");
    try {
      await deleteConstructionSite(siteToDelete._id, password);
      toast.success("Construction site deleted successfully");
      fetchSites();
      setDeleteModalOpen(false);
      setPassword("");
      setSiteToDelete(null);
    } catch (error) {
      setDeleteError(error.message || "Password non valida");
      toast.error(error.message || "Error deleting construction site");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleExport = (value) => {
    const columns = [
      { header: "Numero", key: "id" },
      { header: "Nome", key: "name" },
      { header: "Indirizzo", key: "address" }
    ];

    if (value === "excel") {
      exportToExcel(filteredSites, "cantieri");
    } else if (value === "pdf") {
      exportToPDF(filteredSites, columns, "Elenco Cantieri");
    }
  };

  const handleArchiveClick = (site) => {
    setSiteToArchive(site);
    setArchiveModalOpen(true);
  };

  const handleArchiveConfirm = async () => {
    setIsArchiving(true);
    try {
      await archiveConstructionSite(siteToArchive._id, archiveReason);
      toast.success("Cantiere archiviato con successo");
      fetchSites();
      setArchiveModalOpen(false);
      setArchiveReason("");
      setSiteToArchive(null);
    } catch (error) {
      toast.error(
        error.message || "Errore durante l'archiviazione del cantiere"
      );
    } finally {
      setIsArchiving(false);
    }
  };

  const handleUnarchive = async (siteId) => {
    try {
      await unarchiveConstructionSite(siteId);
      toast.success("Cantiere ripristinato con successo");
      fetchSites();
    } catch (error) {
      toast.error(error.message || "Errore durante il ripristino del cantiere");
    }
  };

  return (
    <div className="md:p-6 p-2 space-y-6">
      <div className="flex justify-between items-center">
        <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-row sm:items-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  onClick={() => setIsModalOpen(true)}
                  disabled={isAdding}
                >
                  {isAdding ? "Aggiungendo..." : "Aggiungi"}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Aggiungi nuovo cantiere</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Select onValueChange={handleExport}>
                  <SelectTrigger className="w-full sm:w-[180px]">
                    <SelectValue placeholder="Esporta" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="excel">Esporta in Excel</SelectItem>
                    <SelectItem value="pdf">Esporta in PDF</SelectItem>
                  </SelectContent>
                </Select>
              </TooltipTrigger>
              <TooltipContent>
                <p>Esporta dati</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="md:w-1/6 flex justify-center items-center gap-x-2">
          <SearchBar onSearch={handleSearch} />
          <HelpSheet route="/cantieri" />
        </div>
      </div>

      <div className="rounded-md border">
        <EnhancedTable
          items={filteredSites}
          sortConfig={sortConfig}
          onSortChange={setSortConfig}
          selectedItems={selectedSites}
          onSelectChange={setSelectedSites}
          selectAll={selectAll}
          onSelectAllChange={setSelectAll}
        >
          <TableHeader>
            <SelectAllTableHead />
            <SortableTableHead>Numero</SortableTableHead>
            <SortableTableHead sortKey="name">Nome</SortableTableHead>
            <SortableTableHead sortKey="code">Codice</SortableTableHead>
            <SortableTableHead sortKey="client">Committente</SortableTableHead>
            <SortableTableHead>Luogo</SortableTableHead>
            <SortableTableHead sortKey="workType">Tipologia</SortableTableHead>
            <SortableTableHead sortKey="status">Stato</SortableTableHead>
            <SortableTableHead></SortableTableHead>
          </TableHeader>
          <TableBody>
            {filteredSites.length > 0 ? (
              filteredSites.map((site, index) => (
                <SelectableTableRow key={site._id} item={site}>
                  <SelectionTableCell item={site} />
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Link
                      to={`/cantieri/${site._id}/informazioni`}
                      className="hover:underline"
                    >
                      {site.name}
                    </Link>
                  </TableCell>
                  <TableCell>{site.code}</TableCell>
                  <TableCell>{site.client?.fullName}</TableCell>
                  <TableCell>{formattedAddress(site)}</TableCell>
                  <TableCell>{site.workType}</TableCell>
                  <TableCell>{site.status || "In pianificazione"}</TableCell>
                  <ActionsTableCell>
                    <DropdownMenuItem asChild>
                      <Link
                        to={`/cantieri/${site._id}/informazioni`}
                        className="flex items-center"
                      >
                        <Eye className="h-4 w-4 mr-2" /> Visualizza dettagli
                      </Link>
                    </DropdownMenuItem>
                    {site.status === "Archiviato" ? (
                      <DropdownMenuItem
                        onClick={() => handleUnarchive(site._id)}
                        className="flex items-center"
                      >
                        <ArchiveRestore className="h-4 w-4 mr-2 text-green-500" />{" "}
                        Ripristina cantiere
                      </DropdownMenuItem>
                    ) : (
                      <DropdownMenuItem
                        onClick={() => handleArchiveClick(site)}
                        className="flex items-center"
                      >
                        <Archive className="h-4 w-4 mr-2" /> Archivia cantiere
                      </DropdownMenuItem>
                    )}
                    <DropdownMenuItem
                      onClick={() => handleDeleteClick(site)}
                      className="flex items-center"
                    >
                      <Trash className="h-4 w-4 mr-2 text-red-500" /> Elimina
                      cantiere
                    </DropdownMenuItem>
                  </ActionsTableCell>
                </SelectableTableRow>
              ))
            ) : (
              <SelectableTableRow>
                <TableCell colSpan={9} className="text-center">
                  Nessun cantiere trovato.
                </TableCell>
              </SelectableTableRow>
            )}
          </TableBody>
        </EnhancedTable>
      </div>

      {filteredSites?.length > 0 && (
        <Pagination
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      {/* Add site modal */}
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="sm:max-w-[700px] max-h-[90vh]">
          <DialogHeader>
            <DialogTitle>Aggiungi Nuovo Cantiere</DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[calc(85vh-180px)] pr-4">
            <form onSubmit={handleSubmit} className="space-y-6 p-2">
              <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                <div className="sm:grid sm:grid-cols-2 sm:gap-y-4 sm:gap-x-8">
                  <div>
                    <RequiredLabel htmlFor="name">Nome Cantiere</RequiredLabel>
                    <Input
                      id="name"
                      name="name"
                      value={newSite.name}
                      onChange={handleInputChange}
                      placeholder="Inserisci il nome del cantiere"
                      className="placeholder:text-muted-foreground"
                      required
                    />
                  </div>
                  <div>
                    <RequiredLabel htmlFor="code">Codice</RequiredLabel>
                    <Input
                      id="code"
                      name="code"
                      value={newSite.code}
                      onChange={handleInputChange}
                      placeholder="Inserisci il codice cantiere"
                      className="placeholder:text-muted-foreground"
                      required
                    />
                  </div>
                  <div>
                    <RequiredLabel htmlFor="budget">
                      Budget lavori €
                    </RequiredLabel>
                    <Input
                      type="number"
                      id="budget"
                      name="budget"
                      value={newSite.budget}
                      onChange={handleInputChange}
                      placeholder="0.00"
                      className="placeholder:text-muted-foreground"
                      required
                    />
                  </div>
                  <div>
                    <RequiredLabel htmlFor="client">Committente</RequiredLabel>
                    <Select
                      name="client"
                      value={newSite.client}
                      onValueChange={(value) =>
                        handleSelectChange("client", value)
                      }
                      required
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Seleziona committente" />
                      </SelectTrigger>
                      <SelectContent>
                        {clients.map((client) => (
                          <SelectItem key={client._id} value={client._id}>
                            {client.typology === "Private"
                              ? client.fullName
                              : client.companyName}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-2 sm:gap-4">
                  <div>
                    <RequiredLabel htmlFor="openingDate">
                      Data Apertura
                    </RequiredLabel>
                    <Input
                      type="date"
                      id="openingDate"
                      name="openingDate"
                      value={newSite.openingDate}
                      onChange={handleInputChange}
                      className="placeholder:text-muted-foreground"
                      required
                    />
                  </div>
                  <div>
                    <Label htmlFor="closingDate">Data Chiusura</Label>
                    <Input
                      type="date"
                      id="closingDate"
                      name="closingDate"
                      min={newSite.openingDate}
                      value={newSite.closingDate}
                      onChange={handleInputChange}
                      className="placeholder:text-muted-foreground"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-2 sm:gap-4">
                  <div>
                    <RequiredLabel htmlFor="amount">Importo €</RequiredLabel>
                    <Input
                      type="number"
                      id="amount"
                      name="amount"
                      value={newSite.amount}
                      onChange={handleInputChange}
                      placeholder="0.00"
                      className="placeholder:text-muted-foreground"
                      required
                    />
                  </div>
                  <div>
                    <RequiredLabel htmlFor="workType">
                      Tipologia di lavoro
                    </RequiredLabel>
                    <Select
                      name="workType"
                      value={newSite.workType}
                      onValueChange={(value) =>
                        handleSelectChange("workType", value)
                      }
                      required
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Seleziona tipologia" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Manutenzione ordinaria">
                          Manutenzione ordinaria
                        </SelectItem>
                        <SelectItem value="Manutenzione straordinaria">
                          Manutenzione straordinaria
                        </SelectItem>
                        <SelectItem value="Restauro">Restauro</SelectItem>
                        <SelectItem value="Risanamento conservativo">
                          Risanamento conservativo
                        </SelectItem>
                        <SelectItem value="Ristrutturazione edilizia">
                          Ristrutturazione edilizia
                        </SelectItem>
                        <SelectItem value="Nuova costruzione">
                          Nuova costruzione
                        </SelectItem>
                        <SelectItem value="Ristrutturazione urbanistica">
                          Ristrutturazione urbanistica
                        </SelectItem>
                        <SelectItem value="Lavori stradali">
                          Lavori stradali
                        </SelectItem>
                        <SelectItem value="Impianti fognari">
                          Impianti fognari
                        </SelectItem>
                        <SelectItem value="Movimento terra">
                          Movimento terra
                        </SelectItem>
                        <SelectItem value="Impiantistica">
                          Impiantistica
                        </SelectItem>
                        <SelectItem value="Bonifica">Bonifica</SelectItem>
                        <SelectItem value="Demolizione">Demolizione</SelectItem>
                        <SelectItem value="Edilizia industrializzata">
                          Edilizia industrializzata
                        </SelectItem>
                        <SelectItem value="Opere idrauliche">
                          Opere idrauliche
                        </SelectItem>
                        <SelectItem value="Impianti idroelettrici">
                          Impianti idroelettrici
                        </SelectItem>
                        <SelectItem value="Opere marittime">
                          Opere marittime
                        </SelectItem>
                        <SelectItem value="Lavori ferroviari">
                          Lavori ferroviari
                        </SelectItem>
                        <SelectItem value="Lavori di linee e condotte">
                          Lavori di linee e condotte
                        </SelectItem>
                        <SelectItem value="Lavori al sottosuolo">
                          Lavori al sottosuolo
                        </SelectItem>
                        <SelectItem value="Lavori speciali">
                          Lavori speciali
                        </SelectItem>
                        <SelectItem value="Altro">Altro</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                <div>
                  <Label htmlFor="featuredImage">Immagine in evidenza</Label>
                  <Input
                    id="featuredImage"
                    type="file"
                    name="featuredImage"
                    onChange={handleInputChange}
                    accept="image/*"
                    className="mt-1"
                  />
                </div>

                <div>
                  <RequiredLabel htmlFor="address">Indirizzo</RequiredLabel>
                  <Input
                    id="address"
                    name="address"
                    value={newSite.address}
                    onChange={handleInputChange}
                    placeholder="Inserisci l'indirizzo completo"
                    className="placeholder:text-muted-foreground"
                    required
                  />
                </div>

                <div className="sm:grid sm:grid-cols-2 sm:gap-4">
                  <div>
                    <RequiredLabel htmlFor="city">Città</RequiredLabel>
                    <Input
                      id="city"
                      name="city"
                      value={newSite.city}
                      onChange={handleInputChange}
                      placeholder="Inserisci la città"
                      className="placeholder:text-muted-foreground"
                      required
                    />
                  </div>
                  <div>
                    <RequiredLabel htmlFor="postalCode">CAP</RequiredLabel>
                    <Input
                      id="postalCode"
                      name="postalCode"
                      value={newSite.postalCode}
                      onChange={handleInputChange}
                      placeholder="Inserisci il CAP"
                      className="placeholder:text-muted-foreground"
                      required
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-2 sm:gap-4">
                  <div>
                    <RequiredLabel htmlFor="province">Provincia</RequiredLabel>
                    <Input
                      id="province"
                      name="province"
                      value={newSite.province}
                      onChange={handleInputChange}
                      placeholder="Inserisci la provincia"
                      className="placeholder:text-muted-foreground"
                      required
                    />
                  </div>
                  <div>
                    <RequiredLabel htmlFor="country">Nazione</RequiredLabel>
                    <Input
                      id="country"
                      name="country"
                      value={newSite.country}
                      onChange={handleInputChange}
                      placeholder="Inserisci la nazione"
                      className="placeholder:text-muted-foreground"
                      required
                    />
                  </div>
                </div>

                <div>
                  <Label htmlFor="description">Descrizione dei lavori</Label>
                  <Textarea
                    id="description"
                    name="description"
                    value={newSite.description}
                    onChange={handleInputChange}
                    placeholder="Inserisci una descrizione dettagliata dei lavori..."
                    className="h-32 placeholder:text-muted-foreground"
                  />
                </div>

                <div>
                  <Label htmlFor="status">Stato del cantiere</Label>
                  <Select
                    name="status"
                    value={newSite.status}
                    onValueChange={(value) =>
                      handleSelectChange("status", value)
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Seleziona stato" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="In pianificazione">
                        In pianificazione
                      </SelectItem>
                      <SelectItem value="In corso">In corso</SelectItem>
                      <SelectItem value="In revisione">In revisione</SelectItem>
                      <SelectItem value="Sospeso">Sospeso</SelectItem>
                      <SelectItem value="In collaudo">In collaudo</SelectItem>
                      <SelectItem value="Terminato">Terminato</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <DialogFooter>
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setIsModalOpen(false)}
                >
                  Annulla
                </Button>
                <Button type="submit" disabled={isAdding}>
                  {isAdding ? "Aggiungendo..." : "Salva"}
                </Button>
              </DialogFooter>
            </form>
          </ScrollArea>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteModalOpen} onOpenChange={setDeleteModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conferma eliminazione</DialogTitle>
          </DialogHeader>
          <DialogDescription className="space-y-4">
            <p>
              Sei sicuro di voler eliminare il cantiere "{siteToDelete?.name}"?
            </p>
            <div className="space-y-2">
              <Label htmlFor="password">
                Inserisci la tua password per confermare
              </Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setDeleteError("");
                }}
                placeholder="Inserisci la password"
              />
              {deleteError && (
                <p className="text-sm text-red-500">{deleteError}</p>
              )}
            </div>
          </DialogDescription>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setDeleteModalOpen(false);
                setPassword("");
                setDeleteError("");
                setSiteToDelete(null);
              }}
            >
              Annulla
            </Button>
            <Button
              variant="destructive"
              onClick={handleDeleteConfirm}
              disabled={isDeleting || !password}
            >
              {isDeleting ? "Eliminando..." : "Elimina"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={archiveModalOpen} onOpenChange={setArchiveModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              <AlertTriangle className="text-yellow-500" />
              Conferma archiviazione
            </DialogTitle>
          </DialogHeader>
          <DialogDescription className="space-y-4">
            <p>
              Sei sicuro di voler archiviare il cantiere "{siteToArchive?.name}
              "?
            </p>
            <div className="space-y-2">
              <Label>Motivo dell'archiviazione (opzionale)</Label>
              <Textarea
                placeholder="Inserisci il motivo dell'archiviazione..."
                value={archiveReason}
                onChange={(e) => setArchiveReason(e.target.value)}
              />
            </div>
          </DialogDescription>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setArchiveModalOpen(false);
                setArchiveReason("");
                setSiteToArchive(null);
              }}
            >
              Annulla
            </Button>
            <Button
              variant="destructive"
              onClick={handleArchiveConfirm}
              disabled={isArchiving}
            >
              {isArchiving ? "Archiviazione in corso..." : "Archivia"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Cantieri;
