import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "../../ui/button";
import { ScrollArea } from "../../ui/scroll-area";
import { Card } from "../../ui/card";
import { Loader2, Save, ArrowLeft, FileDown } from "lucide-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { getDocument, updateDocument } from "../../../apis/Documents";
import { fetchCompany } from "../../../apis/CompanyDetail";
import { getSingleClient } from "../../../apis/client";
import { getConstructionSites } from "../../../apis/ConstructionSite";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "../../ui/alert-dialog";
import { exportDocumentToPDF } from "../../../config/helper";
const Quill = ReactQuill.Quill;
const Delta = Quill.import("delta");
const Embed = Quill.import("blots/embed");

// Define a custom Blot for template fields
class TemplateFieldBlot extends Embed {
  static create(value) {
    const node = super.create();
    node.setAttribute("class", "template-field");
    node.setAttribute("contenteditable", "false");
    node.innerHTML = value;
    return node;
  }

  static value(node) {
    return node.innerHTML;
  }
}
TemplateFieldBlot.blotName = "templateField";
TemplateFieldBlot.tagName = "span";
Quill.register(TemplateFieldBlot);

// Custom module to handle template fields
const templateModule = {
  clipboard: {
    matchers: [
      [
        "span.template-field",
        (node, delta) => {
          const value = node.innerHTML;
          return delta.compose(new Delta().insert({ templateField: value }));
        }
      ]
    ]
  }
};

// Add some CSS to your styles
const templateFieldStyles = `
  .template-field {
    background-color: #e9ecef;
    padding: 2px 4px;
    border-radius: 4px;
    margin: 0 2px;
    color: #495057;
    cursor: not-allowed;
    user-select: none;
  }
`;

const templateFieldMappings = {
  Company: {
    companyName: "companyName", // direct mapping
    address: "address",
    city: "city",
    postalCode: "postalCode",
    province: "province",
    taxIdCode: "taxIdCode",
    pec: "pec",
    telephoneNumber: "telephoneNumber"
  },
  Client: {
    fullName: "fullName",
    taxId: "taxId"
  },
  ConstructionSite: {
    address: "address",
    city: "city",
    postalCode: "postalCode",
    province: "province",
    workType: "workType"
  }
};

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    ["clean"]
  ]
};

const StyleTag = () => (
  <style>{`
    .template-field {
      background-color: #e9ecef;
      padding: 2px 4px;
      border-radius: 4px;
      margin: 0 2px;
      color: #495057;
      cursor: not-allowed;
      user-select: none;
    }
  `}</style>
);

const customModules = {
  ...modules,
  clipboard: {
    matchVisual: false
  },
  keyboard: {
    bindings: {
      // Prevent backspace from deleting template fields
      backspace: {
        key: 8,
        handler(range, context) {
          const [leaf] = this.quill.getLeaf(range.index);
          if (leaf.domNode.classList?.contains("template-field")) {
            return false;
          }
          return true;
        }
      },
      // Prevent delete from deleting template fields
      delete: {
        key: 46,
        handler(range, context) {
          const [leaf] = this.quill.getLeaf(range.index);
          if (leaf.domNode.classList?.contains("template-field")) {
            return false;
          }
          return true;
        }
      }
    }
  }
};

export default function DocumentEditor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [document, setDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(true);
  const [editedContent, setEditedContent] = useState("");
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [error, setError] = useState(null);
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] =
    useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isEditingMode, setIsEditingMode] = useState(false);

  const replaceTemplateFields = (content) => {
    if (!content) {
      //console.log("No content provided");
      return "";
    }
    if (!formData || Object.keys(formData).length === 0) {
      //console.log("No formData available", formData);
      return content;
    }

    //console.log("Current formData:", formData);

    return content.replace(/\{\{([^}]+)\}\}/g, (match, p1) => {
      //console.log("Trying to replace:", p1);
      const createDashPlaceholder = (length = 5) =>
        "—".repeat(Math.max(5, length));

      // First try direct field access as it's simpler
      const [model, field] = p1.split(".");
      //console.log("Looking for:", model, field);
      const directValue = formData[model]?.[field];
      if (directValue) {
        //console.log("Found direct value:", directValue);
        return directValue;
      }

      // Then try the mappings
      for (const [mapModel, mappings] of Object.entries(
        templateFieldMappings
      )) {
        if (mapModel === model) {
          for (const [templateField, dataField] of Object.entries(mappings)) {
            if (p1.toLowerCase().includes(templateField.toLowerCase())) {
              const value = formData[model]?.[dataField];
              //console.log(`Found mapped value for ${templateField}:`, value);
              return value || createDashPlaceholder(templateField.length);
            }
          }
        }
      }

      //console.log("No value found, using placeholder");
      return createDashPlaceholder(field.length);
    });
  };

  const verifyFieldMappings = (formData) => {
    // Log the exact fields we need
    const fieldsToCheck = {
      Company: [
        "companyName",
        "address",
        "city",
        "postalCode",
        "province",
        "taxIdCode",
        "pec",
        "telephoneNumber"
      ],
      Client: ["fullName", "taxId"],
      ConstructionSite: [
        "address",
        "city",
        "postalCode",
        "province",
        "workType"
      ]
    };

    //console.log("=== Field Verification ===");
    for (const [model, fields] of Object.entries(fieldsToCheck)) {
      //console.log(`\n${model} fields:`);
      fields.forEach((field) => {
        //console.log(`${field}: ${formData[model]?.[field]}`);
      });
    }
    //console.log("========================");
  };

  // Call this in useEffect after setting formData
  useEffect(() => {
    if (formData && Object.keys(formData).length > 0) {
      verifyFieldMappings(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (id) {
      fetchDocumentAndData();
    }
  }, [id]);

  useEffect(() => {
    if (document && formData && Object.keys(formData).length > 0) {
      // If we're editing, show the template fields
      if (isEditing) {
        setEditedContent(document.content);
      } else {
        // If we're viewing, show the replaced content
        setEditedContent(replaceTemplateFields(document.content));
      }
      setIsEditing(document.status !== "final");
    }
  }, [document, formData]);

  const fetchDocumentAndData = async () => {
    try {
      setIsLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      //console.log("User data:", user);

      if (!user?.companyId) throw new Error("User information not found");

      const documentData = await getDocument(id);
      //console.log("Document data:", documentData);

      //console.log("Initial document content:", documentData.content); // Debug log

      // Set initial content immediately with template fields for editing
      setEditedContent(documentData.content);

      const [companyResponse, sitesResponse] = await Promise.all([
        fetchCompany(user.companyId),
        getConstructionSites(user.companyId)
      ]);

      const site = sitesResponse.data?.sites?.find(
        (s) => s._id === documentData.constructionSiteId
      );
      //console.log("Found site:", site);

      if (!site) throw new Error("Construction site not found");

      // Client data is now inside the site object
      const clientData = site.client || {};

      // Create properly structured formData
      const newFormData = {
        Company: companyResponse.data?.company || {},
        // Use the client data from the site object
        Client: {
          fullName: clientData.fullName || "",
          companyName: clientData.companyName || "",
          vatNumber: clientData.vatNumber || "",
          taxId: clientData.taxId || "",
          address: clientData.address || "",
          city: clientData.city || "",
          postalCode: clientData.postalCode || "",
          province: clientData.province || "",
          nation: clientData.nation || "",
          email: clientData.email || "",
          mobilePhone: clientData.mobilePhone || "",
          telephone: clientData.telephone || "",
          pec: clientData.pec || "",
          website: clientData.website || "",
          note: clientData.note || "",
          billingCode: clientData.billingCode || "",
          typology: clientData.typology || ""
        },
        // Map construction site data
        ConstructionSite: {
          name: site.name || "",
          workType: site.workType || "",
          address: site.address || "",
          amount: site.amount?.toString() || "",
          description: site.description || "",
          city: site.city || "",
          postalCode: site.postalCode || "",
          province: site.province || "",
          country: site.country || "",
          openingDate: site.openingDate
            ? new Date(site.openingDate).toISOString().split("T")[0]
            : "",
          closingDate: site.closingDate
            ? new Date(site.closingDate).toISOString().split("T")[0]
            : "",
          status: site.status || "",
          workCost: site.workCost?.toString() || ""
        }
      };

      //console.log("Setting formData:", newFormData);

      setFormData(newFormData);
      setDocument(documentData);
      setIsLoading(false);
      //console.log("Data fetching complete");
    } catch (error) {
      console.error("Error loading document:", error);
      setError(error.message || "Error loading document");
      toast.error(error.message || "Errore nel caricamento del documento");
      setIsLoading(false);
    }
  };

  const handleExportPDF = async () => {
    if (!document) return;
    try {
      setIsExporting(true);
      const documentWithReplacedContent = {
        ...document,
        content: replaceTemplateFields(document.content)
      };
      await exportDocumentToPDF(documentWithReplacedContent, formData);
      toast.success("Documento esportato con successo");
    } catch (error) {
      console.error("Export error:", error);
      toast.error("Errore nell'esportazione del documento");
    } finally {
      setIsExporting(false);
    }
  };

  const handleSave = async (asDraft = true) => {
    if (!document) return;
    setIsSaving(true);

    try {
      // Always save the template version (with {{field}} notation)
      const payload = {
        content: editedContent,
        status: asDraft ? "draft" : "final",
        formData
      };

      const updatedDoc = await updateDocument(id, payload);

      setDocument((prevDoc) => ({
        ...prevDoc,
        content: updatedDoc.content,
        status: updatedDoc.status
      }));

      // Keep the template version in editedContent
      setEditedContent(updatedDoc.content);

      toast.success(asDraft ? "Bozza salvata" : "Documento salvato");
      setHasUnsavedChanges(false);

      if (!asDraft) {
        setIsEditing(false);
        // When making final, show the replaced version
        setEditedContent(replaceTemplateFields(updatedDoc.content));
        navigate("/documenti");
      }
    } catch (error) {
      console.error("Save error:", error);
      toast.error("Errore nel salvataggio del documento");
    } finally {
      setIsSaving(false);
    }
  };

  const handleBack = () => {
    if (hasUnsavedChanges) {
      setUnsavedChangesDialogOpen(true);
      return;
    }
    navigate("/documenti");
  };

  // Function to prepare content for editor
  const prepareEditorContent = (content) => {
    if (!content) return "";
    return content.replace(/\{\{([^}]+)\}\}/g, (match) => {
      return `<span class="template-field">${match}</span>`;
    });
  };

  // Function to clean content before saving
  const cleanContent = (content) => {
    return content.replace(
      /<span class="template-field"[^>]*>(.*?)<\/span>/g,
      "$1"
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (error || !document) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="text-red-500 mb-4">{error || "Document not found"}</p>
        <Button onClick={() => navigate("/documenti")}>
          Return to Documents
        </Button>
      </div>
    );
  }

  return (
    <>
      <StyleTag />
      <div className="mx-auto p-2 space-y-6">
        <div className="flex flex-wrap justify-between items-center gap-4 md:gap-0">
          {/* Left Section */}
          <div className="flex items-center gap-2 md:gap-4">
            <Button variant="ghost" size="sm" onClick={handleBack}>
              <ArrowLeft className="h-4 w-4" />
            </Button>
            <h1 className="text-xl md:text-2xl font-bold">{document.name}</h1>
          </div>

          {/* Right Section */}
          <div className="flex flex-wrap gap-2 md:gap-4">
            {isEditing && (
              <>
                {/* Toggle Button */}
                <Button
                  variant="outline"
                  onClick={() => setIsEditingMode(!isEditingMode)}
                  className="w-full md:w-auto"
                >
                  {isEditingMode ? "Anteprima" : "Modifica"}
                </Button>

                {/* Save Draft Button */}
                {isEditingMode && (
                  <Button
                    variant="outline"
                    onClick={() => handleSave(true)}
                    disabled={isSaving}
                    className="w-full md:w-auto"
                  >
                    {isSaving ? (
                      <Loader2 className="h-4 w-4 animate-spin mr-2" />
                    ) : (
                      <Save className="h-4 w-4 mr-2" />
                    )}
                    Salva bozza
                  </Button>
                )}

                {/* Save Final Document Alert Dialog */}
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button disabled={isSaving} className="w-full md:w-auto">
                      Salva documento
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>
                        Salva documento finale
                      </AlertDialogTitle>
                      <AlertDialogDescription>
                        Sei sicuro di voler salvare il documento come versione
                        finale? Questa azione non può essere annullata e il
                        documento non sarà più modificabile.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Annulla</AlertDialogCancel>
                      <AlertDialogAction onClick={() => handleSave(false)}>
                        Conferma
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </>
            )}

            {/* Export PDF Button */}
            <Button
              variant="outline"
              onClick={handleExportPDF}
              disabled={isExporting}
              className="w-full md:w-auto"
            >
              {isExporting ? (
                <Loader2 className="h-4 w-4 mr-2 animate-spin" />
              ) : (
                <FileDown className="h-4 w-4 mr-2" />
              )}
              Esporta PDF
            </Button>
          </div>
        </div>

        <Card className="p-2">
          <ScrollArea className="h-auto">
            {isEditing ? (
              isEditingMode ? (
                <ReactQuill
                  value={prepareEditorContent(editedContent)}
                  onChange={(content, delta, source) => {
                    if (source === "user") {
                      const cleaned = cleanContent(content);
                      setEditedContent(cleaned);
                      setHasUnsavedChanges(true);
                    }
                  }}
                  modules={customModules}
                  className="h-auto"
                />
              ) : (
                <div
                  className="prose max-w-none ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: replaceTemplateFields(editedContent)
                  }}
                />
              )
            ) : (
              <div
                className="prose max-w-none ql-editor"
                dangerouslySetInnerHTML={{
                  __html: replaceTemplateFields(editedContent)
                }}
              />
            )}
          </ScrollArea>
        </Card>

        <AlertDialog
          open={unsavedChangesDialogOpen}
          onOpenChange={setUnsavedChangesDialogOpen}
        >
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Modifiche non salvate</AlertDialogTitle>
              <AlertDialogDescription>
                Hai delle modifiche non salvate. Vuoi salvare prima di uscire?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Annulla</AlertDialogCancel>
              <Button
                variant="outline"
                onClick={() => {
                  setHasUnsavedChanges(false);
                  setUnsavedChangesDialogOpen(false);
                  navigate("/documenti");
                }}
              >
                Esci senza salvare
              </Button>
              <AlertDialogAction
                onClick={async () => {
                  await handleSave(true);
                  setUnsavedChangesDialogOpen(false);
                  navigate("/documenti");
                }}
              >
                Salva ed esci
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </>
  );
}
