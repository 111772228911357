import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  fetchPreventivi,
  fetchCurrencies,
  fetchPreventiviInvoiceById,
  updatePreventivi,
  updatePreventiviInvoice,
  fetchClients,
  fetchUm,
  fetchCompany
} from "../../../apis/PreventiviEdit";
import { toast } from "react-toastify";

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Textarea } from "../../ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle
} from "../../ui/card";
import {
  Alert,
  AlertDescription,
  AlertTitle
} from "../../ui/alert";
import { Archive } from "lucide-react";
import { pdf } from "@react-pdf/renderer";
import Spinner from "../../Spinner";
import { exportToPDF } from "../../../config/helper";
import { ChevronDown, ChevronUp } from "lucide-react";

const formatDateToDDMMYYYY = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};


function DetailComponent() {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;
  const navigate = useNavigate();
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [itemDetails, setItemDetails] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [um, setUm] = useState([]);
  const [clients, setClients] = useState([]);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [openSection, setOpenSection] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const [itemData, currencyData, umData, clientsData, companyData] =
          await Promise.all([
            fetchPreventivi(id),
            fetchCurrencies(),
            fetchUm(),
            fetchClients(companyId),
            fetchCompany(companyId)
          ]);

        setItem(itemData.data.preventivi);
        setCurrencies(currencyData.data.valuta || []);
        setUm(umData?.um || []);
        setClients(clientsData?.data.clients || []);
        setCompany(companyData?.data.company || null);

        if (itemData.data.preventivi?.token) {
          const itemDetailData = await fetchPreventiviInvoiceById(
            itemData.data.preventivi.token
          );
          setItemDetails(itemDetailData.data.preventiviInvoice || []);
        }
      } catch (error) {
        console.error("There was an error fetching the data!", error);
        toast.error("Error fetching data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [id, companyId]);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Prevent editing if preventivo is archived
    if (item.isArchived) {
      toast.error("Non è possibile modificare un preventivo archiviato");
      return;
    }
    
    try {
      const updatedData = {
        ...item,
        preventiviInvoice: itemDetails
      };

      const response = await updatePreventivi(id, updatedData);
      if (response.status === 200) {
        toast.success("Data saved successfully");
        navigate(-1);
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      toast.error("Error saving data");
    }
  };

  const handleDuplicate = (index) => {
    // Prevent editing if preventivo is archived
    if (item.isArchived) {
      toast.error("Non è possibile modificare un preventivo archiviato");
      return;
    }
    
    setItemDetails((prev) => {
      const newDetails = [...prev];
      // Create a new object without _id field when duplicating
      const { _id, ...duplicatedItemWithoutId } = newDetails[index];

      // Insert the duplicated item after the current item
      newDetails.splice(index + 1, 0, duplicatedItemWithoutId);

      // Recalculate totals
      const totals = newDetails.reduce(
        (acc, curr) => {
          const baseAmount =
            parseFloat(curr.qita || 0) * parseFloat(curr.importonetto || 0);
          const discount = (baseAmount * parseFloat(curr.sconto || 0)) / 100;
          const amountAfterDiscount = baseAmount - discount;
          const ivaAmount =
            (amountAfterDiscount * parseFloat(curr.iva || 0)) / 100;

          return {
            prodottival: acc.prodottival + amountAfterDiscount,
            ivaval: acc.ivaval + ivaAmount
          };
        },
        { prodottival: 0, ivaval: 0 }
      );

      // Update the parent item with new totals
      setItem((prevItem) => ({
        ...prevItem,
        prodottival: totals.prodottival.toFixed(2),
        ivaval: totals.ivaval.toFixed(2),
        totaleval: (totals.prodottival + totals.ivaval).toFixed(2)
      }));

      // Set the openSection to the new item's index
      setOpenSection(index + 1);

      return newDetails;
    });
  };

  const handleInputChange = (e) => {
    // Prevent editing if preventivo is archived
    if (item.isArchived) {
      toast.error("Non è possibile modificare un preventivo archiviato");
      return;
    }
    
    const { name, value } = e.target;
    setItem((prev) => ({ ...prev, [name]: value }));
  };

  const handleDetailChange = (index, field, value) => {
    // Prevent editing if preventivo is archived
    if (item.isArchived) {
      toast.error("Non è possibile modificare un preventivo archiviato");
      return;
    }
    
    setItemDetails((prev) => {
      const newDetails = [...prev];
      const detail = { ...newDetails[index], [field]: value };

      // Base calculations
      const quantity = parseFloat(detail.qita) || 0;
      const unitPrice = parseFloat(detail.importonetto) || 0;
      const discount = parseFloat(detail.sconto) || 0;
      const iva = parseFloat(detail.iva) || 0;

      // Base amount
      const baseAmount = quantity * unitPrice;
      const discountAmount = (baseAmount * discount) / 100;
      const amountAfterDiscount = baseAmount - discountAmount;

      // Calculate VAT amount directly
      const ivaAmount = (amountAfterDiscount * iva) / 100;
      const totalAmount = amountAfterDiscount + ivaAmount;

      detail.importo_totale = totalAmount.toFixed(2);
      newDetails[index] = detail;

      // Calculate totals
      const totals = newDetails.reduce(
        (acc, curr) => {
          const baseAmount =
            parseFloat(curr.qita || 0) * parseFloat(curr.importonetto || 0);
          const discount = (baseAmount * parseFloat(curr.sconto || 0)) / 100;
          const amountAfterDiscount = baseAmount - discount;
          const ivaAmount =
            (amountAfterDiscount * parseFloat(curr.iva || 0)) / 100;

          return {
            prodottival: acc.prodottival + amountAfterDiscount,
            ivaval: acc.ivaval + ivaAmount
          };
        },
        { prodottival: 0, ivaval: 0 }
      );

      setItem((prevItem) => ({
        ...prevItem,
        prodottival: totals.prodottival.toFixed(2),
        ivaval: totals.ivaval.toFixed(2),
        totaleval: (totals.prodottival + totals.ivaval).toFixed(2)
      }));

      return newDetails;
    });
  };

  const handleExport = async () => {
    try {
      setExporting(true);

      const formattedData = itemDetails.map((detail) => ({
        codice: detail.codice || "",
        descrizione: detail.descrizione || "",
        qita: detail.qita || "0",
        um: detail.um || "",
        importo: detail.importonetto
          ? `€ ${parseFloat(detail.importonetto).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`
          : "€ 0,00",
        totale: detail.importo_totale
          ? `€ ${parseFloat(detail.importo_totale).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`
          : "€ 0,00",
        iva: detail.iva ? `${detail.iva}%` : "0%"
      }));

      const columns = [
        { header: "Cod.", key: "codice", width: 0.8 },
        { header: "Descrizione", key: "descrizione", width: 2 },
        { header: "Q.tà", key: "qita", width: 0.5 },
        { header: "U.M.", key: "um", width: 0.5 },
        { header: "Importo", key: "importo", width: 1 },
        { header: "Totale", key: "totale", width: 1 },
        { header: "IVA", key: "iva", width: 0.5 }
      ];

      const currentClient = clients.find((c) => c._id === item.clienti);
      const formattedDate = item.dataa
        ? new Date(item.dataa)
            .toLocaleDateString("it-IT", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric"
            })
            .replace(/\//g, "-")
        : "";

      // Get the first item's codice to display in the client section
      const firstItemCodice = itemDetails[0]?.codice || "";

      const stats = {
        left: [
          { label: "DATI GENERALI", value: "", isTitle: true },
          { label: "Numero", value: item.numero || "" },
          { label: "Data", value: formattedDate },
          {
            label: "Validità",
            value: formatDateToDDMMYYYY(item.validita) || ""
          },
          {
            label: "Stato",
            value: item.isArchived ? "Archiviato" : (item.stato || "")
          }
        ],

        right: [
          { label: "CLIENTE", value: "", isTitle: true },
          {
            value: currentClient?.companyName || currentClient?.fullName || ""
          },
          {
            value: `${currentClient?.address || ""} ${
              currentClient?.city || ""
            } ${currentClient?.postalCode || ""} ${
              currentClient?.province || ""
            }`
          },
          { value: currentClient?.taxId }
        ]
      };

      const oggettoSection = {
        label: "Oggetto",
        value: item.ogguto || "",
        fullWidth: true,
        marginTop: 20,
        marginBottom: 20
      };

      const summaries = [
        {
          label: "Imponibile",
          value: `€ ${parseFloat(item.prodottival || 0).toLocaleString(
            "it-IT",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          )}`
        },
        {
          label: "IVA",
          value: `€ ${parseFloat(item.ivaval || 0).toLocaleString("it-IT", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
        },
        {
          label: "Totale Complessivo",
          value: `€ ${parseFloat(item.totaleval || 0).toLocaleString("it-IT", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
        }
      ];

      await exportToPDF(
        formattedData,
        columns,
        `Preventivo ${item.numero}`,
        stats,
        company,
        summaries,
        oggettoSection
      );

      toast.success("Esportazione PDF completata con successo");
    } catch (error) {
      console.error("Errore durante l'esportazione PDF:", error);
      toast.error("Errore durante l'esportazione PDF");
    } finally {
      setExporting(false);
    }
  };

  const handleDelete = (indexToDelete) => {
    // Prevent editing if preventivo is archived
    if (item.isArchived) {
      toast.error("Non è possibile modificare un preventivo archiviato");
      return;
    }
    
    if (itemDetails.length <= 1) return; // Prevent deletion if only one item remains
    setItemDetails((prev) =>
      prev.filter((_, index) => index !== indexToDelete)
    );
  };

  if (!item) {
    return <Spinner />;
  }

  return (
    <form className="p-6 space-y-2" onSubmit={handleSubmit}>
      {item.isArchived && (
        <Alert variant="warning" className="mb-4 bg-yellow-50 border-yellow-200">
          <Archive className="h-4 w-4" />
          <AlertTitle>Preventivo archiviato</AlertTitle>
          <AlertDescription>
            Questo preventivo è stato archiviato{item.archiveReason ? ` con la seguente motivazione: "${item.archiveReason}"` : ''}.
            Non è possibile apportare modifiche.
          </AlertDescription>
        </Alert>
      )}
      
      <Card className="bg-white">
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>Dati del documento</CardTitle>
            <Button
              type="button"
              onClick={handleExport}
              disabled={exporting}
              variant="outline"
            >
              {exporting ? "Esportazione..." : "Esporta PDF"}
            </Button>
          </div>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="grid grid-cols-3 gap-4">
            <div className="space-y-2">
              <Label htmlFor="data">Data</Label>
              <Input
                type="date"
                id="data"
                name="dataa"
                value={formatDate(item.dataa)}
                onChange={handleInputChange}
                required
                disabled={item.isArchived}
                className={item.isArchived ? "bg-gray-100" : ""}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="numero">Numero</Label>
              <Input
                id="numero"
                name="numero"
                value={item.numero || ""}
                readOnly
                disabled
                className="bg-gray-100"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="stato">Stato</Label>
              <Select
                name="stato"
                value={item.isArchived ? "Archiviato" : (item.stato || "")}
                onValueChange={(value) =>
                  handleInputChange({ target: { name: "stato", value } })
                }
                disabled={item.isArchived}
              >
                <SelectTrigger className={item.isArchived ? "bg-gray-100" : ""}>
                  <SelectValue placeholder="Seleziona" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Inviato">Inviato</SelectItem>
                  <SelectItem value="In corso">In corso</SelectItem>
                  <SelectItem value="Accettato">Accettato</SelectItem>
                  <SelectItem value="Rifiutato">Rifiutato</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div className="space-y-2">
              <Label htmlFor="clienti">Cliente</Label>
              <Select
                name="clienti"
                value={item.clienti || ""}
                onValueChange={(value) =>
                  handleInputChange({ target: { name: "clienti", value } })
                }
                required
                disabled={item.isArchived}
              >
                <SelectTrigger className={item.isArchived ? "bg-gray-100" : ""}>
                  <SelectValue placeholder="Scegli Cliente" />
                </SelectTrigger>
                <SelectContent>
                  {Array.isArray(clients) &&
                    clients.map((client) => (
                      <SelectItem key={client._id} value={client._id}>
                        {client.typology === "Private"
                          ? client.fullName
                          : client.companyName}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="valuta">Valuta</Label>
              <Select
                name="valuta"
                value={item.valuta || ""}
                onValueChange={(value) =>
                  handleInputChange({ target: { name: "valuta", value } })
                }
                required
                disabled={item.isArchived}
              >
                <SelectTrigger className={item.isArchived ? "bg-gray-100" : ""}>
                  <SelectValue placeholder="Seleziona Valuta" />
                </SelectTrigger>
                <SelectContent>
                  {currencies.map((currency) => (
                    <SelectItem key={currency.valuta} value={currency.valuta}>
                      {currency.valuta}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="validita">Validità</Label>
              <Input
                type="date"
                id="validita"
                name="validita"
                value={formatDate(item.validita)}
                onChange={handleInputChange}
                required
                disabled={item.isArchived}
                className={item.isArchived ? "bg-gray-100" : ""}
              />
            </div>
          </div>
          <div className="space-y-2">
            <Label htmlFor="ogguto">Oggetto</Label>
            <Textarea
              id="ogguto"
              name="ogguto"
              value={item.ogguto || ""}
              onChange={handleInputChange}
              required
              disabled={item.isArchived}
              className={item.isArchived ? "bg-gray-100" : ""}
            />
          </div>
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 items-start">
        <Card className="bg-white md:col-span-2">
          <CardHeader>
            <CardTitle>Lista degli articoli</CardTitle>
          </CardHeader>
          <CardContent>
            {itemDetails.map((detail, index) => (
              <div key={index} className="mb-6 pb-6 border-b">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() =>
                    setOpenSection(openSection === index ? null : index)
                  }
                >
                  <div className="flex items-center space-x-4">
                    <span className="font-medium">
                      {detail.nome || detail.codice || `Articolo ${index + 1}`}
                    </span>
                    <span className="text-sm text-gray-500">
                      {detail.um ? `${detail.qita} ${detail.um}` : ""}
                    </span>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className="font-medium">
                      € {detail.importo_totale || "0.00"}
                    </span>
                    {openSection === index ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </div>
                </div>

                {openSection === index && (
                  <div className="mt-4">
                    <div className="grid grid-cols-2 gap-4 mb-4">
                      <div className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <Label htmlFor={`codice-${index}`}>Codice</Label>
                            <Input
                              id={`codice-${index}`}
                              value={detail.codice || ""}
                              onChange={(e) =>
                                handleDetailChange(
                                  index,
                                  "codice",
                                  e.target.value
                                )
                              }
                              disabled={item.isArchived}
                              className={item.isArchived ? "bg-gray-100" : ""}
                            />
                          </div>
                          <div className="space-y-2">
                            <Label htmlFor={`nome-${index}`}>Nome</Label>
                            <Input
                              id={`nome-${index}`}
                              value={detail.nome || ""}
                              onChange={(e) =>
                                handleDetailChange(
                                  index,
                                  "nome",
                                  e.target.value
                                )
                              }
                              disabled={item.isArchived}
                              className={item.isArchived ? "bg-gray-100" : ""}
                            />
                          </div>
                        </div>
                        <div className="space-y-2">
                          <Label htmlFor={`descrizione-${index}`}>
                            Descrizione
                          </Label>
                          <Textarea
                            id={`descrizione-${index}`}
                            value={detail.descrizione || ""}
                            onChange={(e) =>
                              handleDetailChange(
                                index,
                                "descrizione",
                                e.target.value
                              )
                            }
                            disabled={item.isArchived}
                            className={item.isArchived ? "bg-gray-100" : ""}
                          />
                        </div>
                      </div>

                      <div className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <Label htmlFor={`um-${index}`}>U.M.</Label>
                            <Select
                              value={detail.um || ""}
                              onValueChange={(value) =>
                                handleDetailChange(index, "um", value)
                              }
                              disabled={item.isArchived}
                            >
                              <SelectTrigger className={item.isArchived ? "bg-gray-100" : ""}>
                                <SelectValue placeholder="Seleziona U.M." />
                              </SelectTrigger>
                              <SelectContent>
                                {um.map((unit) => (
                                  <SelectItem key={unit.unit} value={unit.unit}>
                                    {unit.unit}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </div>
                          <div className="space-y-2">
                            <Label htmlFor={`qita-${index}`}>Q.ità</Label>
                            <Input
                              id={`qita-${index}`}
                              value={detail.qita || ""}
                              onChange={(e) =>
                                handleDetailChange(
                                  index,
                                  "qita",
                                  e.target.value
                                )
                              }
                              disabled={item.isArchived}
                              className={item.isArchived ? "bg-gray-100" : ""}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <Label htmlFor={`iva-${index}`}>IVA</Label>
                            <Select
                              value={detail.iva || ""}
                              onValueChange={(value) =>
                                handleDetailChange(index, "iva", value)
                              }
                              required
                              disabled={item.isArchived}
                            >
                              <SelectTrigger className={item.isArchived ? "bg-gray-100" : ""}>
                                <SelectValue placeholder="Seleziona IVA" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="22">22%</SelectItem>
                                <SelectItem value="21">21%</SelectItem>
                                <SelectItem value="20">20%</SelectItem>
                                <SelectItem value="10">10%</SelectItem>
                                <SelectItem value="4">4%</SelectItem>
                                <SelectItem value="0">0%</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                          <div className="space-y-2">
                            <Label htmlFor={`sconto-${index}`}>Sconto</Label>
                            <Input
                              id={`sconto-${index}`}
                              value={detail.sconto || ""}
                              onChange={(e) =>
                                handleDetailChange(
                                  index,
                                  "sconto",
                                  e.target.value
                                )
                              }
                              disabled={item.isArchived}
                              className={item.isArchived ? "bg-gray-100" : ""}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <Label htmlFor={`importonetto-${index}`}>
                              Importo netto
                            </Label>
                            <Input
                              id={`importonetto-${index}`}
                              value={detail.importonetto || ""}
                              onChange={(e) =>
                                handleDetailChange(
                                  index,
                                  "importonetto",
                                  e.target.value
                                )
                              }
                              disabled={item.isArchived}
                              className={item.isArchived ? "bg-gray-100" : ""}
                            />
                          </div>
                          <div className="space-y-2">
                            <Label htmlFor={`importo_totale-${index}`}>
                              Importo
                            </Label>
                            <Input
                              id={`importo_totale-${index}`}
                              value={detail.importo_totale || ""}
                              onChange={(e) =>
                                handleDetailChange(
                                  index,
                                  "importo_totale",
                                  e.target.value
                                )
                              }
                              readOnly
                              disabled
                              className="bg-gray-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {!item.isArchived && itemDetails.length > 1 && (
                      <div className="flex justify-end space-x-2">
                        <Button
                          type="button"
                          onClick={() => handleDelete(index)}
                          variant="destructive"
                          size="sm"
                        >
                          Elimina
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </CardContent>
          {!item.isArchived && (
            <CardFooter className="flex justify-end">
              <Button
                type="button"
                onClick={() => handleDuplicate(itemDetails.length - 1)}
                size="sm"
              >
                Aggiungi
              </Button>
            </CardFooter>
          )}
        </Card>

        <div className="flex flex-col space-y-2">
          <Card className="bg-white">
            <CardHeader>
              <CardTitle>Riepilogo</CardTitle>
            </CardHeader>
            <CardContent className="space-y-2">
              <div className="flex justify-start gap-x-2">
                <span>Prodotti e/o Servizi</span>
                <span className="text-xl">€ {item.prodottival || ""}</span>
              </div>
              <div className="flex justify-start gap-x-2">
                <span>IVA</span>
                <span className="text-xl">€ {item.ivaval || ""}</span>
              </div>
              <div className="flex justify-start gap-x-2">
                <span className="font-bold">Totale</span>
                <span className="text-xl font-bold">
                  € {item.totaleval || ""}
                </span>
              </div>
            </CardContent>
          </Card>
          <div className="flex justify-end w-full mt-4">
            {!item.isArchived && (
              <Button type="submit">Salva</Button>
            )}
            {item.isArchived && (
              <Button type="button" onClick={() => navigate(-1)}>
                Torna indietro
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        <input
          type="hidden"
          name="prodottival"
          value={item.prodottival || ""}
        />
        <input type="hidden" name="ivaval" value={item.ivaval || ""} />
        <input type="hidden" name="totaleval" value={item.totaleval || ""} />
      </div>
    </form>
  );
}

export default DetailComponent;