import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format, parseISO } from "date-fns";
import { it } from "date-fns/locale";
import { Button } from "../../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "../../ui/dialog";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Checkbox } from "../../ui/checkbox";
import {
  EnhancedTable,
  TableBody,
  TableCell,
  TableHeader,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import { DropdownMenuItem } from "../../ui/dropdown-menu";
import SearchBar from "../sharedComponent/SearchBar";
import SettingsTabs from "./components/SettingsTabs";
import {
  getTaxes,
  createTax,
  updateTax,
  deleteTax,
  recordTaxPayment
} from "../../../apis/RecurringTaxes";

const TasseRicorrenti = () => {
  const [taxes, setTaxes] = useState([]);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedTax, setSelectedTax] = useState(null);
  const [taxToDelete, setTaxToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTaxes, setSelectedTaxes] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  useEffect(() => {
    fetchTaxes();
  }, [searchQuery]);

  const fetchTaxes = async () => {
    try {
      const response = await getTaxes(searchQuery);
      if (response.status === 200) {
        setTaxes(response.data.taxes || []);
      }
    } catch (error) {
      console.error("Unable to fetch taxes", error);
      toast.error("Impossibile recuperare le tasse ricorrenti");
    }
  };

  const handleCreateTax = async (taxData) => {
    try {
      const response = await createTax(taxData);
      if (response.status === 201) {
        toast.success("Tassa ricorrente creata con successo");
        fetchTaxes();
        setCreateModalOpen(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Impossibile creare la tassa ricorrente");
    }
  };

  const handleUpdateTax = async (taxData) => {
    try {
      const response = await updateTax(selectedTax._id, taxData);
      if (response.status === 200) {
        toast.success("Tassa ricorrente aggiornata con successo");
        fetchTaxes();
        setEditModalOpen(false);
      }
    } catch (error) {
      console.error(error);
      toast.error(
        error.message || "Impossibile aggiornare la tassa ricorrente"
      );
    }
  };

  const handleDeleteTax = async () => {
    if (!taxToDelete) return;
    try {
      const response = await deleteTax(taxToDelete._id);
      if (response.status === 200) {
        toast.success("Tassa ricorrente eliminata con successo");
        fetchTaxes();
        setDeleteConfirmOpen(false);
        setTaxToDelete(null);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Impossibile eliminare la tassa ricorrente");
    }
  };

  const handleEditTax = (tax) => {
    setSelectedTax(tax);
    setEditModalOpen(true);
  };

  const handlePaymentClick = (tax) => {
    setSelectedTax(tax);
    setPaymentModalOpen(true);
  };

  const handleDeleteClick = (tax) => {
    setTaxToDelete(tax);
    setDeleteConfirmOpen(true);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  // Format currency values
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR"
    }).format(amount);
  };

  // Format dates
  const formatDate = (dateString) => {
    try {
      if (!dateString) return "N/A";
      const date = parseISO(dateString);
      return format(date, "dd/MM/yyyy", { locale: it });
    } catch (error) {
      return dateString || "N/A";
    }
  };

  return (
    <SettingsTabs defaultTab="/tasse-ricorrenti">
      <ToastContainer />
      <div className="flex flex-col md:flex-row justify-between my-2">
        <div className="flex items-center gap-2 mb-4 md:mb-0">
          <Button onClick={() => setCreateModalOpen(true)}>
            Crea tassa ricorrente
          </Button>
        </div>
        <div className="md:w-1/3 flex justify-end">
          <SearchBar onSearch={handleSearch} placeholder="Cerca tasse..." />
        </div>
      </div>

      <div className="rounded-md border">
        <EnhancedTable
          items={taxes}
          sortConfig={sortConfig}
          onSortChange={setSortConfig}
          selectedItems={selectedTaxes}
          onSelectChange={setSelectedTaxes}
          selectAll={selectAll}
          onSelectAllChange={setSelectAll}
        >
          <TableHeader>
            <SelectAllTableHead />
            <SortableTableHead sortKey="name">Nome tassa</SortableTableHead>
            <SortableTableHead sortKey="frequency">Frequenza</SortableTableHead>
            <SortableTableHead sortKey="nextDueDate">
              Prossima scadenza
            </SortableTableHead>
            <SortableTableHead sortKey="lastPaymentDate">
              Ultimo pagamento
            </SortableTableHead>
            <SortableTableHead sortKey="importToF24">
              Importa in F24
            </SortableTableHead>
            <SortableTableHead sortKey="estimatedAmount">
              Importo stimato
            </SortableTableHead>
            <SortableTableHead sortKey="paymentMethod">
              Metodo di pagamento
            </SortableTableHead>
            <SortableTableHead></SortableTableHead>
          </TableHeader>
          <TableBody>
            {taxes.length > 0 ? (
              taxes.map((tax) => (
                <SelectableTableRow key={tax._id} item={tax}>
                  <SelectionTableCell item={tax} />
                  <TableCell>{tax.name}</TableCell>
                  <TableCell>{tax.frequency}</TableCell>
                  <TableCell>{formatDate(tax.nextDueDate)}</TableCell>
                  <TableCell>{formatDate(tax.lastPaymentDate)}</TableCell>
                  <TableCell>{tax.importToF24 ? "Sì" : "No"}</TableCell>
                  <TableCell>{formatCurrency(tax.estimatedAmount)}</TableCell>
                  <TableCell>{tax.paymentMethod}</TableCell>
                  <ActionsTableCell>
                    <DropdownMenuItem
                      onClick={() => handlePaymentClick(tax)}
                      className="flex items-center"
                    >
                      Registra pagamento
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => handleEditTax(tax)}
                      className="flex items-center"
                    >
                      Modifica
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => handleDeleteClick(tax)}
                      className="flex items-center text-red-600"
                    >
                      Elimina
                    </DropdownMenuItem>
                  </ActionsTableCell>
                </SelectableTableRow>
              ))
            ) : (
              <SelectableTableRow>
                <TableCell colSpan={9} className="text-center">
                  Nessuna tassa trovata.
                </TableCell>
              </SelectableTableRow>
            )}
          </TableBody>
        </EnhancedTable>
      </div>

      <Dialog open={isCreateModalOpen} onOpenChange={setCreateModalOpen}>
        <DialogContent className="sm:max-w-[625px]">
          <DialogHeader>
            <DialogTitle>Creazione nuova tassa ricorrente</DialogTitle>
          </DialogHeader>
          <CreateTaxModalContent onSubmit={handleCreateTax} />
        </DialogContent>
      </Dialog>

      <Dialog open={isEditModalOpen} onOpenChange={setEditModalOpen}>
        <DialogContent className="sm:max-w-[625px]">
          <DialogHeader>
            <DialogTitle>Modifica tassa ricorrente</DialogTitle>
          </DialogHeader>
          <CreateTaxModalContent
            onSubmit={handleUpdateTax}
            initialData={selectedTax}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isPaymentModalOpen} onOpenChange={setPaymentModalOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Registra pagamento</DialogTitle>
          </DialogHeader>
          <PaymentModalContent
            tax={selectedTax}
            formatDate={formatDate}
            onSubmit={() => {
              fetchTaxes();
              setPaymentModalOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteConfirmOpen} onOpenChange={setDeleteConfirmOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Conferma Eliminazione</DialogTitle>
          </DialogHeader>
          <p>
            Sei sicuro di voler eliminare la tassa ricorrente "
            {taxToDelete?.name}"?
          </p>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setDeleteConfirmOpen(false)}
            >
              Annulla
            </Button>
            <Button variant="destructive" onClick={handleDeleteTax}>
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </SettingsTabs>
  );
};

const CreateTaxModalContent = ({ onSubmit, initialData = null }) => {
  const [taxData, setTaxData] = useState(
    initialData || {
      name: "",
      startDate: format(new Date(), "yyyy-MM-dd"),
      frequency: "Mensile",
      paymentMethod: "Bonifico bancario",
      estimatedAmount: "",
      importToF24: false
    }
  );

  useEffect(() => {
    if (initialData) {
      setTaxData({
        ...initialData,
        startDate: initialData.startDate
          ? format(new Date(initialData.startDate), "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd")
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTaxData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value
    }));
  };

  const handleSelectChange = (name, value) => {
    setTaxData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    // Validate required fields
    if (!taxData.name || !taxData.estimatedAmount) {
      toast.error("Compila tutti i campi obbligatori");
      return;
    }

    // Convert amount to number
    const dataToSubmit = {
      ...taxData,
      estimatedAmount: Number(taxData.estimatedAmount)
    };

    if (onSubmit) onSubmit(dataToSubmit);
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="name">Nome tassa</Label>
          <Input
            id="name"
            name="name"
            value={taxData.name}
            onChange={handleChange}
            placeholder="Es. INPS"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="startDate">Data di inizio</Label>
          <Input
            id="startDate"
            name="startDate"
            type="date"
            value={taxData.startDate}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="frequency">Frequenza</Label>
          <Select
            value={taxData.frequency}
            onValueChange={(value) => handleSelectChange("frequency", value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Seleziona frequenza" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Mensile">Mensile</SelectItem>
              <SelectItem value="Trimestrale">Trimestrale</SelectItem>
              <SelectItem value="Semestrale">Semestrale</SelectItem>
              <SelectItem value="Annuale">Annuale</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <Label htmlFor="paymentMethod">Metodo di pagamento</Label>
          <Select
            value={taxData.paymentMethod}
            onValueChange={(value) =>
              handleSelectChange("paymentMethod", value)
            }
          >
            <SelectTrigger>
              <SelectValue placeholder="Seleziona metodo di pagamento" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Bonifico bancario">
                Bonifico bancario
              </SelectItem>
              <SelectItem value="F24 telematico">F24 telematico</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="space-y-2">
        <Label htmlFor="estimatedAmount">Importo stimato</Label>
        <Input
          id="estimatedAmount"
          name="estimatedAmount"
          type="number"
          step="0.01"
          value={taxData.estimatedAmount}
          onChange={handleChange}
          placeholder="0.00"
        />
      </div>

      {/* <div className="flex items-center space-x-2">
        <Checkbox
          id="importToF24"
          name="importToF24"
          checked={taxData.importToF24}
          onCheckedChange={(checked) =>
            setTaxData((prev) => ({ ...prev, importToF24: checked }))
          }
        />
        <Label htmlFor="importToF24">Importa in F24</Label>
      </div> */}

      <DialogFooter className="pt-4">
        <Button variant="outline" onClick={() => onSubmit(null)}>
          Annulla
        </Button>
        <Button onClick={handleSubmit}>
          {initialData ? "Aggiorna" : "Aggiungi"}
        </Button>
      </DialogFooter>
    </div>
  );
};

const PaymentModalContent = ({ tax, formatDate, onSubmit }) => {
  const [paymentData, setPaymentData] = useState({
    paymentDate: format(new Date(), "yyyy-MM-dd"),
    actualAmount: tax?.estimatedAmount || ""
  });

  useEffect(() => {
    if (tax) {
      setPaymentData({
        ...paymentData,
        actualAmount: tax.estimatedAmount
      });
    }
  }, [tax]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    if (!tax) return;

    try {
      const dataToSubmit = {
        ...paymentData,
        actualAmount: Number(paymentData.actualAmount)
      };

      const response = await recordTaxPayment(tax._id, dataToSubmit);
      if (response.status === 200) {
        toast.success("Pagamento registrato con successo");
        if (onSubmit) onSubmit();
      }
    } catch (error) {
      console.error(error);
      toast.error("Errore durante la registrazione del pagamento");
    }
  };

  if (!tax) return null;

  return (
    <div className="space-y-6">
      <div className="mb-4">
        <p>
          <strong>Tassa:</strong> {tax.name}
        </p>
        <p>
          <strong>Frequenza:</strong> {tax.frequency}
        </p>
        <p>
          <strong>Prossima scadenza:</strong> {formatDate(tax.nextDueDate)}
        </p>
      </div>

      <div className="space-y-2">
        <Label htmlFor="paymentDate">Data pagamento</Label>
        <Input
          id="paymentDate"
          name="paymentDate"
          type="date"
          value={paymentData.paymentDate}
          onChange={handleChange}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="actualAmount">Importo effettivo</Label>
        <Input
          id="actualAmount"
          name="actualAmount"
          type="number"
          step="0.01"
          value={paymentData.actualAmount}
          onChange={handleChange}
          placeholder="0.00"
        />
      </div>

      <DialogFooter>
        <Button variant="outline" onClick={onSubmit}>
          Annulla
        </Button>
        <Button onClick={handleSubmit}>Registra pagamento</Button>
      </DialogFooter>
    </div>
  );
};

export default TasseRicorrenti;
