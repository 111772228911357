import React, { useEffect, useState } from "react";
import { Edit, Building } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { fetchCompany } from "../../../../apis/CompanyDetail";
import { Button } from "../../../ui/button";
import { Skeleton } from "../../../ui/skeleton";
import HelpSheet from "../../sharedComponent/HelpSheet";

const Information = () => {
  const navigate = useNavigate();
  const [companyInfo, setCompanyInfo] = useState({});
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;

  const getCompanyData = async () => {
    try {
      const response = await fetchCompany(companyId);
      setCompanyInfo(response.data.company);
      setDataIsLoaded(true);
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  const renderLogo = (logoKey, label) => {
    return (
      <div className="flex flex-col items-center gap-2 w-full sm:w-auto">
        <div className="w-16 h-16 sm:w-24 sm:h-24 rounded-full overflow-hidden bg-gray-100 flex items-center justify-center">
          {!companyInfo[logoKey] ? (
            <Building className="h-8 w-8 sm:h-12 sm:w-12 text-gray-400" />
          ) : (
            <img
              src={`${process.env.REACT_APP_BACKEND_URL}/${companyInfo[logoKey]}`}
              alt={`Logo ${logoKey}`}
              className="w-full h-full object-cover"
            />
          )}
        </div>
        <span className="text-sm text-gray-600 text-center">{label}</span>
      </div>
    );
  };

  const InfoSection = ({ title, children }) => (
    <div className="bg-card rounded-lg relative">
      <div className="absolute -top-3 left-2 sm:left-4 px-2 bg-background border rounded-xl">
        <h3 className="text-base sm:text-lg font-semibold">{title}</h3>
      </div>
      <div className="border rounded-lg p-4 sm:p-6 h-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {children}
        </div>
      </div>
    </div>
  );

  const InfoField = ({ label, value }) => (
    <div className="flex flex-col gap-1">
      <span className="text-xs sm:text-sm text-gray-500">{label}</span>
      <span className="text-sm font-medium text-gray-900 break-words">
        {value || "-"}
      </span>
    </div>
  );

  if (!dataIsLoaded) {
    return <Skeleton className="h-[800px] w-full" />;
  }

  return (
    <div className="p-4">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
        <div className="w-full sm:w-auto">
          <Button
            onClick={() =>
              navigate(`/agency/edit-information/${companyInfo?._id}`)
            }
            variant="outline"
            size="sm"
            className="w-full sm:w-auto flex items-center gap-2"
          >
            <Edit className="h-4 w-4" />
            <span className="hidden sm:inline">Modifica</span>
          </Button>
        </div>
        <HelpSheet route="/agency" />
      </div>

      <div className="flex flex-row sm:flex-row gap-4 mb-8">
        {renderLogo("logo1", "Logo 1")}
        {renderLogo("logo2", "Logo 2")}
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InfoSection title="Dati Principali">
            <InfoField
              label="Ragione Sociale"
              value={companyInfo.companyName}
            />
            <InfoField label="Soprannome" value={companyInfo.nickName} />
            <InfoField
              label="Tipologia azienda"
              value={companyInfo.typeOfBusiness}
            />
            <InfoField label="Partita IVA" value={companyInfo.taxIdCode} />
            <InfoField label="Codice Fiscale" value={companyInfo.taxIdCode} />
          </InfoSection>

          <InfoSection title="Sede Legale">
            <InfoField label="Indirizzo" value={companyInfo.address} />
            <InfoField label="Città" value={companyInfo.city} />
            <InfoField label="Provincia" value={companyInfo.province} />
            <InfoField label="CAP" value={companyInfo.postalCode} />
            <InfoField label="Nazione" value={companyInfo.country} />
            <InfoField label="Note indirizzo" value={companyInfo.addressNote} />
          </InfoSection>
        </div>

        <InfoSection title="Contatti">
          <InfoField label="Telefono" value={companyInfo.telephoneNumber} />
          <InfoField label="Cellulare" value={companyInfo.mobileNumber} />
          <InfoField label="Fax" value={companyInfo.fax} />
          <InfoField
            label="Email principale"
            value={companyInfo.businessEmail}
          />
          <InfoField
            label="Email secondaria"
            value={companyInfo.secondaryEmail}
          />
        </InfoSection>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InfoSection title="Dati bancari">
            <InfoField label="IBAN" value={companyInfo.iban} />
            <InfoField label="Banca" value={companyInfo.bank} />
          </InfoSection>

          <InfoSection title="Posizioni azienda">
            <InfoField
              label="Pos. Cassa Edile/Edilcassa"
              value={companyInfo.edilcassaPosition}
            />
            <InfoField
              label="Posizione INPS"
              value={companyInfo.inpsPosition}
            />
            <InfoField
              label="Posizione Inail"
              value={companyInfo.inailPosition}
            />
          </InfoSection>
        </div>
      </div>
    </div>
  );
};

export default Information;
