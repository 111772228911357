import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Edit, Trash2, Eye, Plus, Trash } from "lucide-react";
import { vehicleService } from "../../../services/vehicleService";
import debounce from "lodash/debounce";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../../ui/tooltip";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import {
  EnhancedTable,
  TableHeader,
  TableBody,
  TableCell,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "../../ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "../../ui/alert-dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import { Label } from "../../ui/label";
import { toast } from "react-toastify";
import { DropdownMenuItem } from "../../ui/dropdown-menu";

import SearchBar from "../sharedComponent/SearchBar";
import Pagination from "../sharedComponent/Pgination";
import HelpSheet from "../sharedComponent/HelpSheet";
import { exportToExcel, exportToPDF } from "../../../config/helper";

const statusOptions = [
  { value: "attivo", label: "Attivo" },
  { value: "inattivo", label: "Inattivo" },
  { value: "in_manutenzione", label: "In manutenzione" },
  { value: "in_riparazione", label: "In riparazione" },
  { value: "in_attesa", label: "In attesa" },
  { value: "fuori_servizio", label: "Fuori servizio" },
  { value: "noleggiato", label: "Noleggiato" },
  { value: "disponibile_per_noleggio", label: "Disponibile per noleggio" }
];

const TableComponent = () => {
  const navigate = useNavigate();
  const [allVehicles, setAllVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingVehicle, setEditingVehicle] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);
  const [insuranceStatus, setInsuranceStatus] = useState(
    editingVehicle?.insurance || "valid"
  );
  const [deleteVehicleId, setDeleteVehicleId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // Enhanced Table state
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.companyId) {
      setCompanyId(user.companyId);
      fetchVehicles(user.companyId, currentPage, limit);
    } else {
      console.error("Nessun ID azienda trovato nel local storage");
      setError("Nessun ID azienda trovato. Effettua nuovamente l'accesso.");
    }
  }, [currentPage, limit]);

  // Apply sorting when sort configuration changes
  useEffect(() => {
    if (sortConfig.key && filteredVehicles.length > 0) {
      const sortedData = [...filteredVehicles].sort((a, b) => {
        let aValue, bValue;

        // Handle special cases for different columns
        if (sortConfig.key === "insuranceExpiryDate") {
          aValue = new Date(a.insuranceExpiryDate).getTime();
          bValue = new Date(b.insuranceExpiryDate).getTime();
        } else if (sortConfig.key === "nextInspectionDate") {
          aValue = new Date(a.nextInspectionDate).getTime();
          bValue = new Date(b.nextInspectionDate).getTime();
        } else if (sortConfig.key === "status") {
          aValue = getStatusLabel(a.status);
          bValue = getStatusLabel(b.status);
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }

        // Handle string comparison
        if (typeof aValue === "string") {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });

      setFilteredVehicles(sortedData);
    }
  }, [sortConfig]);

  const fetchVehicles = async (companyId, page, pageLimit) => {
    try {
      const response = await vehicleService.getAllVehicles(
        companyId,
        page,
        pageLimit
      );
      if (Array.isArray(response)) {
        setAllVehicles(response);
        setFilteredVehicles(response);
        setTotalPages(Math.ceil(response.length / pageLimit));
        setError(null);
      } else if (response && Array.isArray(response.data)) {
        setAllVehicles(response.data);
        setFilteredVehicles(response.data);
        setTotalPages(Math.ceil(response.data.length / pageLimit));
        setError(null);
      } else {
        throw new Error("Formato di risposta inaspettato dal server");
      }
    } catch (error) {
      console.error("Errore in fetchVehicles:", error);
      setError(
        `Impossibile recuperare i veicoli: ${error.message}. Riprova più tardi.`
      );
      setFilteredVehicles([]);
      setAllVehicles([]);
      setTotalPages(1);
    }
  };

  const searchVehicles = useCallback(
    debounce((term) => {
      if (!term) {
        setFilteredVehicles(allVehicles);
        return;
      }

      const lowercasedTerm = term.toLowerCase();
      const filtered = allVehicles.filter(
        (vehicle) =>
          vehicle.make.toLowerCase().includes(lowercasedTerm) ||
          vehicle.model.toLowerCase().includes(lowercasedTerm) ||
          vehicle.licensePlate.toLowerCase().includes(lowercasedTerm)
      );
      setFilteredVehicles(filtered);
    }, 300),
    [allVehicles]
  );

  const handleDelete = async (id) => {
    if (window.confirm("Sei sicuro di voler eliminare questo veicolo?")) {
      try {
        await vehicleService.deleteVehicle(id, companyId);
        fetchVehicles(companyId, currentPage, limit);
        toast.success("Veicolo eliminato con successo");
      } catch (error) {
        console.error("Errore nell'eliminazione del veicolo:", error);
        toast.error("Impossibile eliminare il veicolo");
      }
    }
  };

  const handleExport = (value) => {
    try {
      const formattedData = filteredVehicles.map((vehicle, index) => {
        const insuranceStatus = getInsuranceStatus(vehicle.insuranceExpiryDate);

        return {
          id: (currentPage - 1) * limit + index + 1,
          marca: vehicle.make || "",
          modello: vehicle.model || "",
          tipo: vehicle.type || "",
          targa: vehicle.licensePlate || "",
          carburante: vehicle.fuelType || "",
          stato: getStatusLabel(vehicle.status) || "",
          assicurazione: `${new Date(
            vehicle.insuranceExpiryDate
          ).toLocaleDateString("it-IT")} (${insuranceStatus.status})`,
          revisione:
            vehicle.inspection === "present" ? "Presente" : "Non Presente",
          prossima_revisione: vehicle.nextInspectionDate
            ? new Date(vehicle.nextInspectionDate).toLocaleDateString("it-IT")
            : "",
          data_acquisto: vehicle.purchaseDate
            ? new Date(vehicle.purchaseDate).toLocaleDateString("it-IT")
            : "",
          prezzo: vehicle.purchasePrice
            ? `€ ${parseFloat(vehicle.purchasePrice).toLocaleString("it-IT", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}`
            : "",
          telaio: vehicle.vinNumber || ""
        };
      });

      const columns = [
        { header: "ID", key: "id", width: 0.5 },
        { header: "Marca", key: "marca", width: 1 },
        { header: "Modello", key: "modello", width: 1 },
        { header: "Tipo", key: "tipo", width: 1 },
        { header: "Targa", key: "targa", width: 1 },
        { header: "Carburante", key: "carburante", width: 1 },
        { header: "Stato", key: "stato", width: 1.2 },
        { header: "Assicurazione", key: "assicurazione", width: 1.5 },
        { header: "Revisione", key: "revisione", width: 1 },
        { header: "Prossima Revisione", key: "prossima_revisione", width: 1.2 },
        { header: "Data Acquisto", key: "data_acquisto", width: 1 },
        { header: "Prezzo", key: "prezzo", width: 1 },
        { header: "N° Telaio", key: "telaio", width: 1.2 }
      ];

      const currentDate = new Date()
        .toLocaleDateString("it-IT", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric"
        })
        .replace(/\//g, "-");

      const stats = {
        left: [
          {
            label: "Totale Veicoli",
            value: filteredVehicles.length.toString()
          },
          { label: "Data", value: currentDate }
        ],
        right: [
          { label: "Cliente", value: "" } // You can add client info here if needed
        ]
      };

      // Calculate fleet statistics
      const fleetStats = {
        active: filteredVehicles.filter((v) => v.status === "attivo").length,
        maintenance: filteredVehicles.filter(
          (v) => v.status === "in_manutenzione"
        ).length,
        expired_insurance: filteredVehicles.filter(
          (v) => new Date(v.insuranceExpiryDate) < new Date()
        ).length
      };

      const summaries = [
        { label: "Veicoli Attivi", value: `${fleetStats.active}` },
        { label: "In Manutenzione", value: `${fleetStats.maintenance}` },
        {
          label: "Assicurazioni Scadute",
          value: `${fleetStats.expired_insurance}`
        }
      ];

      if (value === "excel") {
        exportToExcel(formattedData, "Flotta Veicoli");
      } else if (value === "pdf") {
        exportToPDF(
          formattedData,
          columns,
          "Lista Flotta Veicoli",
          stats,
          undefined,
          summaries
        );
      }

      toast.success(
        `Esportazione ${value.toUpperCase()} completata con successo`
      );
    } catch (error) {
      console.error("Errore durante l'esportazione:", error);
      toast.error(`Errore durante l'esportazione ${value.toUpperCase()}`);
    }
  };

  const handleAddOrUpdateVehicle = async (vehicle) => {
    try {
      const vehicleWithCompany = { ...vehicle, companyId };
      //console.log("Saving vehicle with data:", vehicleWithCompany); // Add this line to debug
      if (editingVehicle) {
        await vehicleService.updateVehicle(
          editingVehicle._id,
          vehicleWithCompany
        );
        toast.success("Veicolo aggiornato con successo");
      } else {
        await vehicleService.addVehicle(vehicleWithCompany);
        toast.success("Veicolo aggiunto con successo");
      }
      fetchVehicles(companyId, currentPage, limit);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Errore nell'aggiunta/aggiornamento del veicolo:", error);
      toast.error("Impossibile salvare il veicolo");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteClick = (id) => {
    setDeleteVehicleId(id);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (deleteVehicleId) {
      try {
        await vehicleService.deleteVehicle(deleteVehicleId, companyId);
        fetchVehicles(companyId, currentPage, limit);
        toast.success("Veicolo eliminato con successo");
      } catch (error) {
        console.error("Errore nell'eliminazione del veicolo:", error);
        toast.error("Impossibile eliminare il veicolo");
      }
    }
    setIsDeleteModalOpen(false);
    setDeleteVehicleId(null);
  };

  const getStatusLabel = (value) => {
    const status = statusOptions.find((option) => option.value === value);
    return status ? status.label : "Sconosciuto";
  };

  const getInsuranceStatus = (expiryDate) => {
    const today = new Date();
    const expiry = new Date(expiryDate);
    const diffTime = expiry - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 0) {
      return { status: "Scaduta", color: "text-red-500" };
    } else if (diffDays <= 30) {
      return { status: "In Scadenza", color: "text-orange-500" };
    } else {
      return { status: "Valida", color: "text-green-500" };
    }
  };

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex flex-wrap items-center gap-2">
          <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
            <DialogTrigger asChild>
              <Button onClick={() => setEditingVehicle(null)}>Aggiungi</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[800px] max-h-[90vh] overflow-y-auto">
              <DialogHeader>
                <DialogTitle>
                  {editingVehicle ? "Modifica" : "Aggiungi Nuovo"} Veicolo
                </DialogTitle>
              </DialogHeader>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const formData = new FormData(e.target);
                  const vehicleData = Object.fromEntries(formData.entries());
                  handleAddOrUpdateVehicle(vehicleData);
                }}
              >
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-4">
                  <div className="space-y-2">
                    <Label htmlFor="make">Marca</Label>
                    <Input
                      id="make"
                      name="make"
                      defaultValue={editingVehicle?.make}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="model">Modello</Label>
                    <Input
                      id="model"
                      name="model"
                      defaultValue={editingVehicle?.model}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="type">Tipo</Label>
                    <Input
                      id="type"
                      name="type"
                      defaultValue={editingVehicle?.type}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="licensePlate">Targa</Label>
                    <Input
                      id="licensePlate"
                      name="licensePlate"
                      defaultValue={editingVehicle?.licensePlate}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="fuelType">Tipo di Carburante</Label>
                    <Input
                      id="fuelType"
                      name="fuelType"
                      defaultValue={editingVehicle?.fuelType}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="purchaseDate">Data di Acquisto</Label>
                    <Input
                      type="date"
                      id="purchaseDate"
                      name="purchaseDate"
                      defaultValue={editingVehicle?.purchaseDate?.split("T")[0]}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="purchasePrice">Prezzo di Acquisto</Label>
                    <Input
                      type="number"
                      id="purchasePrice"
                      name="purchasePrice"
                      defaultValue={editingVehicle?.purchasePrice}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="registrationDate">
                      Data di Immatricolazione
                    </Label>
                    <Input
                      type="date"
                      id="registrationDate"
                      name="registrationDate"
                      defaultValue={
                        editingVehicle?.registrationDate?.split("T")[0]
                      }
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="vinNumber">Numero di Telaio</Label>
                    <Input
                      id="vinNumber"
                      name="vinNumber"
                      defaultValue={editingVehicle?.vinNumber}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="engineCapacity">Cilindrata</Label>
                    <Input
                      type="number"
                      id="engineCapacity"
                      name="engineCapacity"
                      defaultValue={editingVehicle?.engineCapacity}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="horsepower">Cavalli</Label>
                    <Input
                      type="number"
                      id="horsepower"
                      name="horsepower"
                      defaultValue={editingVehicle?.horsepower}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="color">Colore</Label>
                    <Input
                      id="color"
                      name="color"
                      defaultValue={editingVehicle?.color}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="seatingCapacity">Posti a Sedere</Label>
                    <Input
                      type="number"
                      id="seatingCapacity"
                      name="seatingCapacity"
                      defaultValue={editingVehicle?.seatingCapacity}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="weight">Peso</Label>
                    <Input
                      type="number"
                      id="weight"
                      name="weight"
                      defaultValue={editingVehicle?.weight}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="tires">Pneumatici</Label>
                    <Input
                      id="tires"
                      name="tires"
                      defaultValue={editingVehicle?.tires}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="axleCount">Numero di Assi</Label>
                    <Input
                      type="number"
                      id="axleCount"
                      name="axleCount"
                      defaultValue={editingVehicle?.axleCount}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="insurance">Assicurazione</Label>
                    <Select
                      name="insurance"
                      defaultValue={editingVehicle?.insurance || "valid"}
                      onValueChange={(value) => setInsuranceStatus(value)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Seleziona stato assicurazione" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="valid">Si</SelectItem>
                        <SelectItem value="expired">No</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="insuranceExpiryDate">
                      Scadenza Assicurazione
                    </Label>
                    <Input
                      type="date"
                      id="insuranceExpiryDate"
                      name="insuranceExpiryDate"
                      defaultValue={
                        editingVehicle?.insuranceExpiryDate?.split("T")[0]
                      }
                      disabled={insuranceStatus === "expired"}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="inspection">Revisione</Label>
                    <Select
                      name="inspection"
                      defaultValue={editingVehicle?.inspection || "present"}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Seleziona stato revisione" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="present">Presente</SelectItem>
                        <SelectItem value="not_present">
                          Non Presente
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="nextInspectionDate">
                      Scadenza Revisione
                    </Label>
                    <Input
                      type="date"
                      id="nextInspectionDate"
                      name="nextInspectionDate"
                      defaultValue={
                        editingVehicle?.nextInspectionDate?.split("T")[0]
                      }
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="status">Stato</Label>
                    <Select
                      name="status"
                      defaultValue={editingVehicle?.status || "attivo"}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Seleziona stato veicolo" />
                      </SelectTrigger>
                      <SelectContent>
                        {statusOptions.map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <DialogFooter className="mt-6">
                  <Button type="submit">
                    {editingVehicle ? "Aggiorna" : "Aggiungi"} Veicolo
                  </Button>
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>
          <Select onValueChange={handleExport}>
            <SelectTrigger className="w-full sm:w-[180px]">
              <SelectValue placeholder="Esporta" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="excel">Esporta in Excel</SelectItem>
              <SelectItem value="pdf">Esporta in PDF</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="md:w-1/6 flex justify-center items-center gap-x-2">
          <SearchBar onSearch={searchVehicles} />
          <HelpSheet route="/flotta" />
        </div>
      </div>

      {error && <div className="text-red-500 text-center">{error}</div>}
      <AlertDialog open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Sei sicuro di voler eliminare questo veicolo?
            </AlertDialogTitle>
            <AlertDialogDescription>
              Questa azione non può essere annullata. Il veicolo sarà
              permanentemente rimosso dal database.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setIsDeleteModalOpen(false)}>
              Annulla
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeleteConfirm}
              className="bg-[#8c060c]"
            >
              Elimina
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <div className="rounded-md border">
        <EnhancedTable
          items={filteredVehicles}
          sortConfig={sortConfig}
          onSortChange={setSortConfig}
          selectedItems={selectedItems}
          onSelectChange={setSelectedItems}
          selectAll={selectAll}
          onSelectAllChange={setSelectAll}
        >
          <TableHeader>
            <SelectAllTableHead />
            <SortableTableHead className="w-[100px]">Id</SortableTableHead>
            <SortableTableHead sortKey="make" className="text-left px-1.5">
              Marca
            </SortableTableHead>
            <SortableTableHead sortKey="model" className="text-left px-1.5">
              Modello
            </SortableTableHead>
            <SortableTableHead
              sortKey="licensePlate"
              className="text-left px-1.5"
            >
              Targa
            </SortableTableHead>
            <SortableTableHead
              sortKey="insuranceExpiryDate"
              className="text-left px-1.5"
            >
              Scadenza Assicurazione
            </SortableTableHead>
            <SortableTableHead
              sortKey="nextInspectionDate"
              className="text-left px-1.5"
            >
              Scadenza revisione
            </SortableTableHead>
            <SortableTableHead sortKey="status" className="text-left px-1.5">
              Stato
            </SortableTableHead>
            <SortableTableHead></SortableTableHead>
          </TableHeader>
          <TableBody>
            {filteredVehicles.length > 0 ? (
              filteredVehicles.map((vehicle, index) => {
                const insuranceStatus = getInsuranceStatus(
                  vehicle.insuranceExpiryDate
                );
                return (
                  <SelectableTableRow key={vehicle._id} item={vehicle}>
                    <SelectionTableCell item={vehicle} />
                    <TableCell className="font-medium">
                      {(currentPage - 1) * limit + index + 1}
                    </TableCell>
                    <TableCell>{vehicle.make}</TableCell>
                    <TableCell>{vehicle.model}</TableCell>
                    <TableCell>{vehicle.licensePlate}</TableCell>
                    <TableCell>
                      {new Date(
                        vehicle.insuranceExpiryDate
                      ).toLocaleDateString()}
                      <span className={`ml-2 ${insuranceStatus.color}`}>
                        ({insuranceStatus.status})
                      </span>
                    </TableCell>
                    <TableCell>
                      {new Date(
                        vehicle.nextInspectionDate
                      ).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{getStatusLabel(vehicle.status)}</TableCell>
                    <ActionsTableCell>
                      <DropdownMenuItem asChild>
                        <Link
                          to={`/flotta_details/${vehicle._id}`}
                          className="flex items-center"
                        >
                          <Eye className="h-4 w-4 mr-2" /> Visualizza
                        </Link>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          setEditingVehicle(vehicle);
                          setIsModalOpen(true);
                        }}
                        className="flex items-center"
                      >
                        <Edit className="h-4 w-4 mr-2" /> Modifica
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => handleDeleteClick(vehicle._id)}
                        className="flex items-center"
                      >
                        <Trash className="h-4 w-4 text-red-500 mr-2" /> Elimina
                      </DropdownMenuItem>
                    </ActionsTableCell>
                  </SelectableTableRow>
                );
              })
            ) : (
              <SelectableTableRow>
                <TableCell colSpan={9} className="text-center py-4">
                  Nessun veicolo trovato.
                </TableCell>
              </SelectableTableRow>
            )}
          </TableBody>
        </EnhancedTable>
      </div>

      {filteredVehicles.length > 0 && (
        <div className="flex justify-end mt-4">
          <Pagination
            totalPages={totalPages}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
          />
        </div>
      )}
    </div>
  );
};

export default TableComponent;
