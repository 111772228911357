import PaymentMethodsList from "../components/paymentMethods/index";
import Layout from "../components/admin/sharedComponent/Layout";

const PaymentMethods = () => {
  return (
    <Layout>
      <PaymentMethodsList />;
    </Layout>
  );
};

export default PaymentMethods;
