import React from "react";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import { useFormulario } from "./context/FormularioContext";

const TrasportoPartenza = () => {
  const { updateTrasportoPartenza, formularioData } = useFormulario();
  const trasportoData = formularioData.trasportoPartenza;

  const handleTrasportoChange = (field, value) => {
    updateTrasportoPartenza({
      ...trasportoData,
      informazioniPartenza: {
        ...trasportoData.informazioniPartenza,
        [field]: value,
      },
    });
  };

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Informazioni note alla partenza</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          {/* First row - Trasportatore */}
          <div className="space-y-2">
            <Label htmlFor="trasportatore">Trasportatore</Label>
            <Input
              id="trasportatore"
              className="w-full"
              value={trasportoData.informazioniPartenza.trasportatore || ""}
              onChange={(e) =>
                handleTrasportoChange("trasportatore", e.target.value)
              }
            />
          </div>

          {/* Second row - Targa and Percorso */}
          <div className="grid grid-cols-4 gap-4">
            <div className="space-y-2">
              <Label htmlFor="targaAutomezzo">Targa automezzo</Label>
              <Input
                id="targaAutomezzo"
                value={trasportoData.informazioniPartenza.targaAutomezzo || ""}
                onChange={(e) =>
                  handleTrasportoChange("targaAutomezzo", e.target.value)
                }
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="targaRimorchio">Targa rimorchio</Label>
              <Input
                id="targaRimorchio"
                value={trasportoData.informazioniPartenza.targaRimorchio || ""}
                onChange={(e) =>
                  handleTrasportoChange("targaRimorchio", e.target.value)
                }
              />
            </div>
            <div className="col-span-2 space-y-2">
              <Label htmlFor="percorso">Percorso</Label>
              <Input
                id="percorso"
                value={trasportoData.informazioniPartenza.percorso || ""}
                onChange={(e) =>
                  handleTrasportoChange("percorso", e.target.value)
                }
              />
            </div>
          </div>

          {/* Third row - Conducente */}
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="cognomeConducente">Cognome conducente</Label>
              <Input
                id="cognomeConducente"
                value={
                  trasportoData.informazioniPartenza.cognomeConducente || ""
                }
                onChange={(e) =>
                  handleTrasportoChange("cognomeConducente", e.target.value)
                }
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="nomeConducente">Nome conducente</Label>
              <Input
                id="nomeConducente"
                value={trasportoData.informazioniPartenza.nomeConducente || ""}
                onChange={(e) =>
                  handleTrasportoChange("nomeConducente", e.target.value)
                }
              />
            </div>
          </div>

          {/* Fourth row - Data e Ora */}
          <div className="flex gap-4">
            <div className="space-y-2">
              <Label htmlFor="dataInizio">Data inizio trasporto</Label>
              <Input
                id="dataInizio"
                type="date"
                className="w-fit"
                value={trasportoData.informazioniPartenza.dataInizio || ""}
                onChange={(e) =>
                  handleTrasportoChange("dataInizio", e.target.value)
                }
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="oraInizio">Ora inizio trasporto</Label>
              <Input
                id="oraInizio"
                type="time"
                className="w-fit"
                value={trasportoData.informazioniPartenza.oraInizio || ""}
                onChange={(e) =>
                  handleTrasportoChange("oraInizio", e.target.value)
                }
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default TrasportoPartenza;
