import BankCallBack from "../components/paymentMethods/BankCallBack";
import Layout from "../components/admin/sharedComponent/Layout";

const BankCallBackPage = () => {
  return (
    <Layout>
      <BankCallBack />;
    </Layout>
  );
};

export default BankCallBackPage;
