import React, { useState, useEffect } from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../../ui/tabs";
import AllPreventivi from '../index';
import SituazionePreventivi from '../SituazionePreventivi.jsx';

const PreventiviTabs = () => {
  // Get the saved tab from localStorage, default to "tutti" if nothing saved
  const getSavedTab = () => {
    const savedTab = localStorage.getItem('preventiviActiveTab');
    return savedTab || "tutti";
  };

  const [activeTab, setActiveTab] = useState(getSavedTab);

  // Handle tab change and save to localStorage
  const handleTabChange = (value) => {
    setActiveTab(value);
    localStorage.setItem('preventiviActiveTab', value);
  };

  // Ensure we load the saved tab on initial render
  useEffect(() => {
    setActiveTab(getSavedTab());
  }, []);

  return (
    <div className="space-y-6 p-6">
      <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
        <div className="border-b border-gray-200">
          <TabsList className="flex justify-start h-auto p-0 bg-transparent space-x-8">
            <TabsTrigger
              value="situazione"
              className="py-4 px-1 text-sm font-medium text-gray-500 hover:text-gray-700 relative data-[state=active]:text-slate-600 data-[state=active]:font-medium bg-transparent before:absolute before:h-0.5 before:w-full before:bg-slate-700 before:bottom-0 before:left-0 before:scale-x-0 data-[state=active]:before:scale-x-100 before:transition-transform"
            >
              Situazione
            </TabsTrigger>
            <TabsTrigger
              value="tutti"
              className="py-4 px-1 text-sm font-medium text-gray-500 hover:text-gray-700 relative data-[state=active]:text-slate-600 data-[state=active]:font-medium bg-transparent before:absolute before:h-0.5 before:w-full before:bg-slate-700 before:bottom-0 before:left-0 before:scale-x-0 data-[state=active]:before:scale-x-100 before:transition-transform"
            >
              Tutti i preventivi
            </TabsTrigger>
          </TabsList>
        </div>
        
        <TabsContent value="situazione" className="py-4">
          <SituazionePreventivi />
        </TabsContent>
        
        <TabsContent value="tutti" className="py-4">
          <AllPreventivi />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default PreventiviTabs;