import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import PreventiviTabs from '../../../components/admin/preventivi/components/PreventiviTabs'

const PreventiviDetails = () => {
  return (
    <Layout>
      <PreventiviTabs />
    </Layout>
  )
}

export default PreventiviDetails