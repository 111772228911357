import { createJsonTypeInstance } from "./index";
const axios = createJsonTypeInstance();

export const getChats = async () => {
  try {
    const response = await axios.get("/chats");
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const getMessages = async (chatId) => {
  try {
    const response = await axios.get(`/chats/messages/${chatId}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const getCompanyUsers = async (companyId) => {
  try {
    const response = await axios.get(`/chats/company`, {
      params: { companyId }
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const createChat = async (participantId) => {
  try {
    // Ensure we're sending an object with the participantId property
    const response = await axios.post("/chats", {
      participantId: participantId
    });
    return response.data;
  } catch (error) {
    console.error("Create chat error:", error.response?.data || error);
    throw error.response?.data || error;
  }
};

export const sendMessage = async (chatId, content) => {
  try {
    //console.log('Sending message to chat:', chatId, 'Content:', content);
    const response = await axios.post(`/chats/${chatId}/messages`, { content });
    //console.log('Message sent successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending message:", error.response?.data || error);
    throw error.response?.data || error;
  }
};

export const deleteMessage = async (chatId, messageId) => {
  try {
    const response = await axios.delete(
      `/chats/${chatId}/messages/${messageId}`
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const deleteChat = async (chatId) => {
  try {
    const response = await axios.delete(`/chats/${chatId}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};
