import React, { useState } from "react";
import { useFormulario } from "./context/FormularioContext";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import { Button } from "../../../ui/button";
import { Plus, Trash2 } from "lucide-react";

const IntermediarioSection = ({
  index,
  data,
  onChange,
  onDelete,
  showDelete,
  isRemoving,
  isOnlyOne,
}) => {
  const handleChange = (field, value) => {
    onChange(index, {
      ...data,
      datiIntermediario: {
        ...data.datiIntermediario,
        [field]: value,
      },
    });
  };

  return (
    <Card
      className={`mb-4 relative transition-all duration-200 ${
        isRemoving ? "animate-fade-out" : "animate-fade-in"
      }`}
    >
      {showDelete && (
        <button
          onClick={onDelete}
          className="absolute top-4 right-4 p-2 text-red-500 hover:text-red-700 transition-colors"
          aria-label="Elimina intermediario"
        >
          <Trash2 className="h-5 w-5" />
        </button>
      )}
      <CardHeader>
        <CardTitle>Intermediario{!isOnlyOne && ` ${index + 1}`}</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {/* First row - Company Details */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="space-y-2">
            <Label htmlFor="nazionalita">Nazionalità</Label>
            <Select
              value={data.datiIntermediario.nazionalita}
              onValueChange={(value) => handleChange("nazionalita", value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Seleziona nazionalità" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="IT">Italia</SelectItem>
                <SelectItem value="FR">Francia</SelectItem>
                <SelectItem value="DE">Germania</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <Label htmlFor="codiceFiscale">Codice Fiscale</Label>
            <Input
              id="codiceFiscale"
              value={data.datiIntermediario.codiceFiscale}
              onChange={(e) => handleChange("codiceFiscale", e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="denominazione">Denominazione</Label>
            <Input
              id="denominazione"
              value={data.datiIntermediario.denominazione || ""}
              onChange={(e) => handleChange("denominazione", e.target.value)}
            />
          </div>
        </div>

        {/* Second row - Registration Number */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="space-y-2">
            <Label htmlFor="numeroIscrizione">Numero iscrizione all'albo</Label>
            <Input
              id="numeroIscrizione"
              value={data.datiIntermediario.numeroIscrizione || ""}
              onChange={(e) => handleChange("numeroIscrizione", e.target.value)}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const Intermediari = () => {
  const { updateIntermediari, formularioData } = useFormulario();
  const [intermediari, setIntermediari] = useState(() => {
    // Initialize with proper id structure
    return formularioData.intermediari.map((int, index) => ({
      ...int,
      id: int.id || index,
    }));
  });
  const [removingItems, setRemovingItems] = useState(new Set());

  const handleAddIntermediario = () => {
    const newId =
      intermediari.length === 0
        ? 0
        : Math.max(...intermediari.map((t) => t.id || 0)) + 1;

    setIntermediari([
      ...intermediari,
      {
        id: newId,
        datiIntermediario: {
          nazionalita: "",
          codiceFiscale: "",
          denominazione: "",
          numeroIscrizione: "",
        },
      },
    ]);
  };

  const handleDeleteIntermediario = (idToDelete) => {
    setRemovingItems((prev) => new Set([...prev, idToDelete]));

    setTimeout(() => {
      setIntermediari((prev) => prev.filter((t) => t.id !== idToDelete));
      setRemovingItems((prev) => {
        const newSet = new Set(prev);
        newSet.delete(idToDelete);
        return newSet;
      });
    }, 200);
  };

  const handleIntermediarioChange = (index, newData) => {
    const newIntermediari = [...intermediari];
    newIntermediari[index] = newData;
    setIntermediari(newIntermediari);
    updateIntermediari(newIntermediari);
  };

  return (
    <div className="space-y-4">
      {intermediari.map((intermediario, index) => (
        <div key={intermediario.id || `temp-${index}`}>
          <IntermediarioSection
            index={index}
            data={intermediario}
            onChange={handleIntermediarioChange}
            showDelete={intermediari.length > 1}
            onDelete={() => handleDeleteIntermediario(intermediario.id)}
            isRemoving={removingItems.has(intermediario.id)}
            isOnlyOne={intermediari.length === 1}
          />
        </div>
      ))}

      <Button
        onClick={handleAddIntermediario}
        variant="outline"
        className="w-fit"
      >
        <Plus className="mr-2 h-4 w-4" />
        Aggiungi intermediario
      </Button>
    </div>
  );
};

export default Intermediari;
