import React, { useRef } from "react";
import {
  fetchComputimetriciaddrec,
  fetchComputimetricsumm,
  updateComputimetricsumm,
  deleteComputimetricsumm,
  addComputimetricsumm,
  updateComputimetricaddrec,
  fetchUms
} from "../../../../apis/ComputimetricAddrecSumm";
import {
  updateComputimetric,
  fetchComputimetricById
} from "../../../../apis/Computimetric";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ChevronLeft, Trash, Plus, PenLine } from "lucide-react";
import { Button } from "../../../ui/button";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "../../../ui/dialog";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import { Textarea } from "../../../ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../../ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableHeader
} from "../../../ui/table";

function VisualizzaVoce() {
  const { id, voce_id } = useParams();
  const navigate = useNavigate();
  const [currentVoce, setCurrentVoce] = useState({});
  const [currentMeasures, setCurrentMeasures] = useState([]);
  const [currentCell, setCurrentCell] = useState({});
  const isHandleCellOnBlurRunning = useRef(false);
  const [voci, setVoci] = useState([]);

  //console.log("currentVoce", currentVoce);
  // Add new state variables
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [ums, setUms] = useState([]);
  const [formDataEdit, setFormDataEdit] = useState({
    _id: "",
    codice: "",
    descrizione_lavori: "",
    um: "",
    prezzo_unitario: ""
  });

  // Array of column types in order of appearance in the table
  const columnOrder = [
    "description",
    "numero",
    "lunghezza",
    "larghezza",
    "hpeso"
  ];

  useEffect(() => {
    try {
      const fetchData = async () => {
        // Fetch of "voci"
        const vociResponse = await fetchComputimetriciaddrec(id);
        const _voci = vociResponse.data.computimetriciaddrec;
        const voce_corrente = _voci.find((voce) => voce._id === voce_id);

        setVoci(_voci || []);
        setCurrentVoce(voce_corrente || {});

        // Fetch of measurements
        const misureResponse = await fetchComputimetricsumm(id);
        const misure = misureResponse.data.computimetricsumm;

        const currentMeasures = misure.filter(
          (measure) => measure.token === voce_corrente?._id
        );

        setCurrentMeasures(currentMeasures);

        setFormDataEdit({
          _id: voce_corrente?._id || "",
          codice: voce_corrente?.codice || "",
          descrizione_lavori: voce_corrente?.descrizione_lavori || "",
          um: voce_corrente?.um || "",
          prezzo_unitario: voce_corrente?.prezzo_unitario || ""
        });
      };

      fetchData();
    } catch (error) {
      //console.log(error);
    }
  }, [voce_id, id]);

  // Add UMs fetch effect
  useEffect(() => {
    const fetchUMData = async () => {
      try {
        const umData = await fetchUms();
        setUms(umData?.um || []);
      } catch (error) {
        console.error("Error fetching UMs:", error);
      }
    };
    fetchUMData();
  }, []);

  const handleCellClick = ({ measure, tipo }) => {
    setCurrentCell({ measure, tipo });
  };

  const handleCellOnBlur = async ({ measure, tipo }) => {
    if (
      !currentCell.measure ||
      currentCell.tipo !== tipo ||
      measure._id !== currentCell.measure._id
    )
      return;

    if (isHandleCellOnBlurRunning.current) return;
    isHandleCellOnBlurRunning.current = true;

    // Update measurements
    try {
      const response = await updateComputimetricsumm(measure._id, {
        ...measure,
        [tipo]:
          tipo === "description"
            ? currentCell.measure[tipo]
            : formatNumber(currentCell.measure[tipo])
      });
      // Do update of the currentMeasures state
      const updatedMeasures = currentMeasures.map((m) => {
        if (m._id === measure._id) {
          return response.data.computimetricsumm;
        }
        return m;
      });
      setCurrentMeasures(updatedMeasures);
    } catch (error) {
      //console.log("Error while updating cell value", error);
    }

    // Update computo metrico "prezzo_totale" field
    try {
      const computiMetriciResponse = await fetchComputimetricById(
        currentVoce.token
      );

      const computoMetrico = computiMetriciResponse.data.computimetric;

      const sommaPrezzoVoci = voci.reduce(
        (acc, voce) => acc + Number(voce.prezzo_totale),
        0
      );

      if (computoMetrico._id && sommaPrezzoVoci > 0) {
        const computoUpdated = {
          ...computoMetrico,
          importo_totale: sommaPrezzoVoci
        };

        await updateComputimetric(currentVoce.token, computoUpdated);
      }
    } catch (error) {
      console.error("Error updating total price:", error);
    }

    isHandleCellOnBlurRunning.current = false;
  };

  const handleCellChange = ({ measure, tipo, e }) => {
    if (tipo === "description") {
      setCurrentCell({
        ...currentCell,
        measure: {
          ...currentCell.measure,
          [tipo]: e.target.value
        }
      });
      return;
    }
    setCurrentCell({
      ...currentCell,
      measure: {
        ...currentCell.measure,
        [tipo]: formatNumber(e.target.value)
      }
    });
  };

  // Handle keyboard navigation between cells
  const handleKeyDown = (e, measure, tipo, index) => {
    if (
      ![
        "ArrowRight",
        "ArrowLeft",
        "ArrowUp",
        "ArrowDown",
        "Tab",
        "Enter"
      ].includes(e.key)
    )
      return;

    e.preventDefault();

    const currentColIndex = columnOrder.indexOf(tipo);
    let nextColIndex = currentColIndex; // Initial state
    let nextRowIndex = index;

    // Handle horizontal navigation, Tab and Enter keys
    if (e.key === "ArrowRight" || e.key === "Tab" || e.key === "Enter") {
      nextColIndex = (currentColIndex + 1) % columnOrder.length;
      if (nextColIndex === 0)
        nextRowIndex = (index + 1) % currentMeasures.length;
    } else if (e.key === "ArrowLeft") {
      nextColIndex =
        (currentColIndex - 1 + columnOrder.length) % columnOrder.length;
      if (nextColIndex === columnOrder.length - 1)
        nextRowIndex =
          (index - 1 + currentMeasures.length) % currentMeasures.length;
    }
    // Handle vertical navigation
    else if (e.key === "ArrowDown") {
      nextRowIndex = (index + 1) % currentMeasures.length;
    } else if (e.key === "ArrowUp") {
      nextRowIndex =
        (index - 1 + currentMeasures.length) % currentMeasures.length;
    }

    // Save current cell value before moving
    handleCellOnBlur({ measure, tipo });

    // Move to next cell
    const nextMeasure = currentMeasures[nextRowIndex];
    const nextTipo = columnOrder[nextColIndex];

    if (nextMeasure) {
      handleCellClick({ measure: nextMeasure, tipo: nextTipo });
    }
  };

  const handleDeleteMeasurement = async (measureId) => {
    try {
      await deleteComputimetricsumm(measureId);

      // Update local state by removing the deleted measurement
      setCurrentMeasures(
        currentMeasures.filter((measure) => measure._id !== measureId)
      );

      toast.success("Misurazione eliminata con successo");
    } catch (error) {
      console.error("Errore durante l'eliminazione della misurazione:", error);
      toast.error("Errore durante l'eliminazione della misurazione");
    }
  };

  const handleAddMeasurement = async () => {
    try {
      const newMeasurement = {
        token: voce_id,
        numero: "0",
        description: "",
        lunghezza: "0",
        larghezza: "0",
        hpeso: "0",
        quantita_riga: "0"
      };

      const response = await addComputimetricsumm(newMeasurement);
      if (response?.data) {
        setCurrentMeasures([...currentMeasures, response.data]);
        toast.success("Nuova misurazione aggiunta");
      }
    } catch (error) {
      console.error("Errore durante l'aggiunta della misurazione:", error);
      toast.error("Errore durante l'aggiunta della misurazione");
    }
  };

  const calcolaTotaleRiga = (measure) => {
    if (
      measure.numero === 0 &&
      measure.lunghezza === 0 &&
      measure.larghezza === 0 &&
      measure.hpeso === 0
    ) {
      return 0;
    }
    return (
      (measure.numero || 1) *
      (measure.lunghezza || 1) *
      (measure.larghezza || 1) *
      (measure.hpeso || 1)
    );
  };

  const calcolaTotale = () => {
    return currentMeasures.reduce((acc, measure) => {
      const quantita = calcolaTotaleRiga(measure);
      return acc + quantita;
    }, 0);
  };

  const totalQuantity = calcolaTotale();
  const totalAmount = totalQuantity * (currentVoce?.prezzo_unitario || 0);

  // Add useEffect to update prezzo_totale when totalAmount changes
  useEffect(() => {
    const updateTotalPrice = async () => {
      if (currentVoce._id && totalAmount > 0) {
        try {
          await updateComputimetricaddrec(currentVoce._id, {
            ...currentVoce,
            prezzo_totale: totalAmount
          });
          //console.log("Total price updated successfully");
        } catch (error) {
          console.error("Error updating total price:", error);
        }
      }
    };

    updateTotalPrice();
  }, [totalAmount, currentVoce]);

  const formatNumber = (num) => {
    return num && !isNaN(parseFloat(num)) ? parseFloat(num).toFixed(2) : "0.00";
  };

  function renderCellContent(measure, tipo, currentCell, rowIndex) {
    const _value =
      tipo === "description" ? measure[tipo] : formatNumber(measure[tipo]);
    let valueToShow = _value;
    if (_value === "0.00") {
      valueToShow = "";
    }

    if (
      currentCell.measure &&
      currentCell.measure._id === measure._id &&
      currentCell.tipo === tipo
    ) {
      return (
        <input
          autoFocus
          type="text"
          defaultValue={valueToShow}
          className="w-full text-center box-border max-w-full h-7 p-0 m-0"
          onKeyDown={(e) => handleKeyDown(e, measure, tipo, rowIndex)}
        />
      );
    }

    return valueToShow;
  }

  // Add edit handler
  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateComputimetricaddrec(
        formDataEdit._id,
        formDataEdit
      );
      if (response.status === 200) {
        setIsEditModalOpen(false);
        // Refresh current voce data
        const vociResponse = await fetchComputimetriciaddrec(id);
        const _voci = vociResponse.data.computimetriciaddrec;
        const voce_corrente = _voci.find((voce) => voce._id === voce_id);
        setCurrentVoce(voce_corrente || {});
        toast.success("Voce aggiornata con successo");
      }
    } catch (error) {
      console.error("Error editing item:", error);
      toast.error("Error editing item!");
    }
  };

  return (
    <div className="p-6 flex flex-col gap-8">
      <div className="flex justify-between items-center">
        <div
          variant="ghost"
          onClick={() => navigate(`/computimetriciaddrec/${id}`)}
          className="flex items-center text-blue-600 cursor-pointer font-medium"
        >
          <ChevronLeft className="mr-2 h-5 w-5 mt-[1px]" />
          Indietro
        </div>
        <Button
          variant="outline"
          onClick={() => setIsEditModalOpen(true)}
          className="flex items-center gap-2"
        >
          <PenLine className="h-4 w-4" />
          Modifica Voce
        </Button>
      </div>

      {/* Item details */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-0">
        {/* Left side: Code and Description */}
        <div>
          <div>
            <div>
              <p className="text-xl font-bold">
                {currentVoce?.codice || "N/A"}
              </p>
            </div>
            <div>
              <p className="whitespace-pre-wrap">
                {currentVoce?.descrizione_lavori || "N/A"}
              </p>
            </div>
          </div>
        </div>

        {/* Right side: Information table */}
        <div className="rounded-md border h-full flex min-h-28">
          <Table className="h-full">
            <TableBody>
              <TableRow>
                <TableCell>Unità di misura:</TableCell>
                <TableCell className="text-right font-bold">
                  {currentVoce?.um || "N/A"}
                </TableCell>
                <TableCell className="font-medium">Quantità:</TableCell>
                <TableCell className="text-right font-bold">
                  {formatNumber(totalQuantity)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Importo unitario:</TableCell>
                <TableCell colSpan={3} className="text-right font-bold">
                  € {formatNumber(currentVoce?.prezzo_unitario || 0)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Importo totale:</TableCell>
                <TableCell colSpan={3} className="text-right font-bold">
                  € {formatNumber(totalAmount)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>

      {/* Measurements table */}
      <div>
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead
                  rowSpan={2}
                  className="text-center align-middle w-1/3"
                >
                  Descrizione misurazione
                </TableHead>
                <TableHead colSpan={4} className="text-center">
                  Misure
                </TableHead>
                <TableHead
                  rowSpan={2}
                  className="text-center align-middle w-24"
                >
                  Quantità
                </TableHead>
                <TableHead
                  rowSpan={2}
                  className="text-center align-middle w-16"
                >
                  Azioni
                </TableHead>
              </TableRow>
              <TableRow>
                <TableHead className="text-center w-16">Numero</TableHead>
                <TableHead className="text-center w-16">Lung.</TableHead>
                <TableHead className="text-center w-16">Largh.</TableHead>
                <TableHead className="text-center w-16">H/Peso</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {
                <>
                  {currentMeasures.map((measure, index) => (
                    <TableRow key={measure._id || index}>
                      <TableCell
                        onClick={() => {
                          handleCellClick({ measure, tipo: "description" });
                        }}
                        onBlur={() => {
                          handleCellOnBlur({ measure, tipo: "description" });
                        }}
                        onChange={(e) => {
                          handleCellChange({ measure, tipo: "description", e });
                        }}
                      >
                        {renderCellContent(
                          measure,
                          "description",
                          currentCell,
                          index
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          handleCellClick({ measure, tipo: "numero" });
                        }}
                        onBlur={() => {
                          handleCellOnBlur({ measure, tipo: "numero" });
                        }}
                        onChange={(e) => {
                          handleCellChange({ measure, tipo: "numero", e });
                        }}
                        className="text-center"
                      >
                        {renderCellContent(
                          measure,
                          "numero",
                          currentCell,
                          index
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          handleCellClick({ measure, tipo: "lunghezza" });
                        }}
                        onBlur={() => {
                          handleCellOnBlur({ measure, tipo: "lunghezza" });
                        }}
                        onChange={(e) => {
                          handleCellChange({ measure, tipo: "lunghezza", e });
                        }}
                        className="text-center"
                      >
                        {renderCellContent(
                          measure,
                          "lunghezza",
                          currentCell,
                          index
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          handleCellClick({ measure, tipo: "larghezza" });
                        }}
                        onBlur={() => {
                          handleCellOnBlur({ measure, tipo: "larghezza" });
                        }}
                        onChange={(e) => {
                          handleCellChange({ measure, tipo: "larghezza", e });
                        }}
                        className="text-center"
                      >
                        {renderCellContent(
                          measure,
                          "larghezza",
                          currentCell,
                          index
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          handleCellClick({ measure, tipo: "hpeso" });
                        }}
                        onBlur={() => {
                          handleCellOnBlur({ measure, tipo: "hpeso" });
                        }}
                        onChange={(e) => {
                          handleCellChange({ measure, tipo: "hpeso", e });
                        }}
                        className="text-center"
                      >
                        {renderCellContent(
                          measure,
                          "hpeso",
                          currentCell,
                          index
                        )}
                      </TableCell>
                      <TableCell className="text-center">
                        {formatNumber(calcolaTotaleRiga(measure))}
                      </TableCell>
                      <TableCell className="text-center">
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleDeleteMeasurement(measure._id)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <Trash className="h-4 w-4" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              }
              <TableRow>
                <TableCell className="p-0" colSpan={7}>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={handleAddMeasurement}
                    className="w-full flex items-center justify-start text-blue-600 hover:text-blue-800 p-2"
                  >
                    <Plus className="h-5 w-5 mr-2" />
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>

      {/* Add Edit Modal */}
      <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Modifica voce</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmitEdit} className="space-y-4">
            <input type="hidden" name="_id" value={formDataEdit._id} />
            <div className="grid grid-cols-1 gap-4">
              <div className="space-y-2">
                <Label htmlFor="codice">Codice</Label>
                <Input
                  id="codice"
                  name="codice"
                  value={formDataEdit.codice}
                  onChange={(e) =>
                    setFormDataEdit({ ...formDataEdit, codice: e.target.value })
                  }
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="descrizione_lavori">
                  Descrizione dei lavori
                </Label>
                <Textarea
                  id="descrizione_lavori"
                  name="descrizione_lavori"
                  value={formDataEdit.descrizione_lavori}
                  onChange={(e) =>
                    setFormDataEdit({
                      ...formDataEdit,
                      descrizione_lavori: e.target.value
                    })
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="um">U.M</Label>
                <Select
                  name="um"
                  value={formDataEdit.um}
                  onValueChange={(value) =>
                    setFormDataEdit({ ...formDataEdit, um: value })
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona U.M." />
                  </SelectTrigger>
                  <SelectContent>
                    {ums.map((um) => (
                      <SelectItem key={um.unit} value={um.unit}>
                        {um.unit}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="prezzo_unitario">Prezzo unitario</Label>
                <Input
                  id="prezzo_unitario"
                  name="prezzo_unitario"
                  type="number"
                  step="0.01"
                  value={formDataEdit.prezzo_unitario}
                  onChange={(e) =>
                    setFormDataEdit({
                      ...formDataEdit,
                      prezzo_unitario: e.target.value
                    })
                  }
                />
              </div>
            </div>
            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => setIsEditModalOpen(false)}
              >
                Annulla
              </Button>
              <Button type="submit">Salva modifiche</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default VisualizzaVoce;
