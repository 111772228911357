import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../../ui/card";
import { Switch } from "../../ui/switch";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";
import SettingsTabs from "./components/SettingsTabs";

function PreferencesTab() {
    const [notificationsEnabled, setNotificationsEnabled] = useState(true);
    const [language, setLanguage] = useState('it');
    const [theme, setTheme] = useState('light');
    const [timezone, setTimezone] = useState('GMT+1');

    return (
        <SettingsTabs defaultTab="/impostazioni">
            <Card className="mt-6">
                <CardHeader>
                    <CardTitle>Preferenze Utente</CardTitle>
                    <CardDescription>Personalizza la tua esperienza sull'applicazione.</CardDescription>
                </CardHeader>
                <CardContent className="space-y-6">
                    <div className="flex justify-between items-center">
                        <div>
                            <h4 className="text-lg font-semibold">Lingua</h4>
                            <p className="text-sm text-gray-500">Seleziona la lingua dell'interfaccia</p>
                        </div>
                        <Select value={language} onValueChange={setLanguage}>
                            <SelectTrigger className="w-40">
                                <SelectValue placeholder="Seleziona lingua" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="it">Italiano</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="flex justify-between items-center">
                        <div>
                            <h4 className="text-lg font-semibold">Tema</h4>
                            <p className="text-sm text-gray-500">Scegli tra tema chiaro e scuro</p>
                        </div>
                        <Select value={theme} onValueChange={setTheme}>
                            <SelectTrigger className="w-40">
                                <SelectValue placeholder="Seleziona tema" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="light">Chiaro</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="flex justify-between items-center">
                        <div>
                            <h4 className="text-lg font-semibold">Timezone</h4>
                            <p className="text-sm text-gray-500">Imposta il tuo fuso orario</p>
                        </div>
                        <Select value={timezone} onValueChange={setTimezone}>
                            <SelectTrigger className="w-40">
                                <SelectValue placeholder="Seleziona timezone" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="GMT+1">GMT+1</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </CardContent>
            </Card>
        </SettingsTabs>
    );
}

export default PreferencesTab;