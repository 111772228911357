import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Tabs, TabsList, TabsTrigger } from "../../ui/tabs";
import { ChevronLeft, ChevronRight } from "lucide-react";

function ConstructionSiteNavbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('informazioni');
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const scrollRef = useRef(null);

  const navItems = [
    { value: 'informazioni', label: 'Informazioni' },
    { value: 'documenti', label: 'Documenti' },
    { value: 'contabilita', label: 'SAL' },
    { value: 'accounting', label: 'Contabilità' },
    { value: 'cronoprogramma', label: 'Cronoprogramma' },
    { value: 'produzione', label: 'Giornale dei lavori' },
    { value: 'rapportini', label: 'Rapportini' },
    { value: 'foto', label: 'Media' },
  ];

  useEffect(() => {
    const path = location.pathname.split('/').pop();
    setActiveTab(path);

    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
        setShowLeftArrow(scrollLeft > 0);
        setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1);
      }
    };

    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check

      const resizeObserver = new ResizeObserver(handleScroll);
      resizeObserver.observe(scrollElement);

      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
        resizeObserver.disconnect();
      };
    }
  }, [location]);

  const handleTabChange = (value) => {
    navigate(`/cantieri/${id}/${value}`);
  };

  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = direction === 'left' ? -100 : 100;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className="relative border-b border-gray-200 mt-2 px-4">
      {showLeftArrow && (
        <button 
          onClick={() => scroll('left')} 
          className="absolute left-0 top-1/2 -translate-y-1/2 bg-white shadow-md rounded-md p-1 z-10"
        >
          <ChevronLeft size={20} className="text-gray-600" />
        </button>
      )}
      <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
        <div ref={scrollRef} className="overflow-x-auto scrollbar-hide">
          <TabsList className="bg-transparent h-auto p-0 flex justify-start space-x-6">
            {navItems.map((item) => (
              <TabsTrigger
                key={item.value}
                value={item.value}
                className="py-4 px-1 text-sm font-medium text-gray-500 hover:text-gray-700 relative data-[state=active]:text-slate-600 data-[state=active]:font-medium bg-transparent before:absolute before:h-0.5 before:w-full before:bg-slate-700 before:bottom-0 before:left-0 before:scale-x-0 data-[state=active]:before:scale-x-100 before:transition-transform whitespace-nowrap"
              >
                {item.label}
              </TabsTrigger>
            ))}
          </TabsList>
        </div>
      </Tabs>
      {showRightArrow && (
        <button 
          onClick={() => scroll('right')} 
          className="absolute right-0 top-1/2 -translate-y-1/2 bg-white shadow-md rounded-md p-1 z-10"
        >
          <ChevronRight size={20} className="text-gray-600" />
        </button>
      )}
    </div>
  );
}

export default ConstructionSiteNavbar;