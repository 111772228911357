import React, { useState, useEffect, useRef, useMemo } from "react";
import { IoCaretBack, IoCaretForward } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameMonth,
  startOfWeek,
  endOfWeek,
  addMonths,
  subMonths
} from "date-fns";
import { PDFDownloadLink } from "@react-pdf/renderer";
import AttendanceDocument from "./AttendanceDocumentPdf";
import { formatWorkingHours } from "../../../../../config/helper";
import {
  getCompnayEmployeeAttendence,
  markAttendance,
  updateAttendance,
  deleteAttendance
} from "../../../../../apis/Attendence";
import { singleEmployee } from "../../../../../apis/Employees";
import { getCompanyPlans, getCompanyPlan } from "../../../../../apis/Planning";
import { Button } from "../../../../ui/button";
import { getConstructionSites } from "../../../../../apis/ConstructionSite";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "../../../../ui/dialog";
import { Label } from "../../../../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../../../ui/select";
import { Card, CardContent, CardHeader } from "../../../../ui/card";
import EmployeeSelectionDialog from "./SelectEmployeeModal";
import { getEmployeeGroups } from "../../../../../apis/EmployeeGroups";
import { Link } from "react-router-dom";
import { AddLeave } from "../../../../../apis/leave";

const getAbsenceCode = (absenceReason) => {
  switch (absenceReason) {
    case "Ingiustificata":
      return "A";
    case "Malattia":
      return "M";
    case "Permesso":
      return "P";
    case "Ferie":
      return "F";
    default:
      return "";
  }
};

// Required field indicator component
const RequiredIndicator = () => <span className="text-red-500 ml-1">*</span>;

const AttendanceSheet = ({ employeeData }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredAttendanceData, setFilteredAttendanceData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSelectEmployeeDialogOpen, setSelectEmployeeDialogOpen] =
    useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [companyPlans, setCompanyPlans] = useState([]);
  const [plansMap, setPlansMap] = useState({}); // Plan map for efficient lookups
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const hasInitialized = useRef(false);
  const [constructionSites, setConstructionSites] = useState([]);

  // Create memoized plan map for lookups
  useEffect(() => {
    const planMap = {};
    companyPlans.forEach((plan) => {
      planMap[plan._id] = plan;
    });
    setPlansMap(planMap);
  }, [companyPlans]);

  // Listen for plan updates from other components
  useEffect(() => {
    const handlePlanUpdate = () => {
      fetchCompanyPlans();
      fetchAttendanceData();
    };

    window.addEventListener("planDataChanged", handlePlanUpdate);
    return () => {
      window.removeEventListener("planDataChanged", handlePlanUpdate);
    };
  }, []);

  useEffect(() => {
    if (employeeData && employeeData.length > 0) {
      fetchAttendanceData();
    }
    fetchCompanyPlans();
  }, [selectedMonth, employeeData]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await getEmployeeGroups(user.companyId);
        setGroups(response.data.groups);
      } catch (error) {
        console.error("Error fetching groups:", error);
        toast.error("Unable to fetch employee groups");
      }
    };

    fetchGroups();
  }, []);

  useEffect(() => {
    if (attendanceData.length > 0 && selectedEmployees.length === 0) {
      if (!hasInitialized.current) {
        setSelectedEmployees(attendanceData.map((emp) => emp._id));
        hasInitialized.current = true;
      }
    }
    filterAttendanceData();
  }, [attendanceData, selectedEmployees]);

  useEffect(() => {
    const fetchConstructionSites = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await getConstructionSites(user.companyId);

        if (!response || !response.sites) {
          throw new Error("No sites data found");
        }

        const activeSites = Array.isArray(response.sites)
          ? response.sites.filter((site) => site.status !== "Terminato")
          : [];

        setConstructionSites(activeSites);
      } catch (error) {
        console.error("Error fetching construction sites:", error);
        setConstructionSites([]);
      }
    };

    fetchConstructionSites();
  }, []);

  const fetchAttendanceData = async () => {
    try {
      setIsLoading(true);
      const response = await getCompnayEmployeeAttendence(user.companyId);
      if (response.data?.employees) {
        const attendanceMap = response.data.employees.reduce((map, emp) => {
          map[emp._id] = emp.attendance || [];
          return map;
        }, {});

        const mergedEmployees = employeeData.map((emp) => ({
          ...emp,
          attendance: attendanceMap[emp._id] || []
        }));

        setAttendanceData(mergedEmployees);
      }
    } catch (error) {
      console.error("Failed to fetch attendance data", error);
      toast.error("Unable to fetch attendance data");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCompanyPlans = async () => {
    try {
      const response = await getCompanyPlans();
      setCompanyPlans(response.data.plans);
    } catch (error) {
      console.error("Unable to fetch company plans", error);
      toast.error("Unable to fetch company plans");
    }
  };

  const filterAttendanceData = () => {
    let filtered = [];
    if (selectedEmployees && selectedEmployees.length > 0) {
      filtered = attendanceData.filter((emp) =>
        selectedEmployees.includes(emp._id)
      );
    }
    setFilteredAttendanceData(filtered);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(new Date(e.target.value));
  };

  const incrementMonth = () => {
    setSelectedMonth(addMonths(selectedMonth, 1));
  };

  const decrementMonth = () => {
    setSelectedMonth(subMonths(selectedMonth, 1));
  };

  const getCalendarDays = () => {
    const start = startOfWeek(startOfMonth(selectedMonth), { weekStartsOn: 1 });
    const end = endOfWeek(endOfMonth(selectedMonth), { weekStartsOn: 1 });
    return eachDayOfInterval({ start, end });
  };

  const openModal = (employee, date) => {
    setSelectedEmployee(employee);
    setSelectedDate(date);
    setModalOpen(true);
  };

  const handleModalSubmit = async (data) => {
    try {
      const response = data._id
        ? await updateAttendance(data)
        : await markAttendance(data);
      if (response.status === 200 || response.status === 201) {
        toast.success("Attendance updated successfully");
        fetchAttendanceData();
      }
    } catch (error) {
      console.error("Failed to update attendance", error);
      toast.error("Unable to update attendance");
    }
    setModalOpen(false);
  };

  const handleEmployeeSelect = (newSelectedEmployees) => {
    setSelectedEmployees(newSelectedEmployees || []);
  };

  const handleDeleteAttendance = async (employeeId, date, companyId) => {
    try {
      const response = await deleteAttendance(employeeId, date, companyId);
      if (response.status === 200) {
        toast.success("Registrazione eliminata con successo");
        setModalOpen(false);
        fetchAttendanceData();
      }
    } catch (error) {
      console.error("Failed to delete attendance", error);
      toast.error("Impossibile eliminare la registrazione");
    }
  };

  const days = getCalendarDays();
  const weeks = [];
  let currentWeek = [];

  days.forEach((day) => {
    if (currentWeek.length === 7) {
      weeks.push(currentWeek);
      currentWeek = [];
    }
    currentWeek.push(day);
  });
  if (currentWeek.length > 0) {
    weeks.push(currentWeek);
  }

  // Get current plan color and acronym, prioritizing up-to-date values
  const getPlanDisplay = (attendanceRecord) => {
    if (!attendanceRecord || attendanceRecord.attendanceState !== "present") {
      return { color: "#ffffff", acronym: "P" };
    }

    // If we have the plan ID and it exists in our plans map, use the current values
    if (attendanceRecord.planId && plansMap[attendanceRecord.planId]) {
      const currentPlan = plansMap[attendanceRecord.planId];
      return {
        color: currentPlan.color,
        acronym: currentPlan.acronym
      };
    }

    // Otherwise fallback to the stored values
    return {
      color: attendanceRecord.planColor || "#ffffff",
      acronym: attendanceRecord.planAcronym || "P"
    };
  };

  return (
    <Card className="mx-auto p-0 border-0">
      <CardHeader className="py-2 px-0">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center gap-2">
            <Button onClick={decrementMonth} variant="outline" className="p-1">
              <IoCaretBack className="w-4 h-4 text-gray-600" />
            </Button>
            <span className="text-gray-900 text-sm">
              {format(selectedMonth, "d MMM yyyy")} {">"}{" "}
              {format(endOfMonth(selectedMonth), "d MMM yyyy")}
            </span>
            <Button onClick={incrementMonth} variant="outline" className="p-1">
              <IoCaretForward className="w-4 h-4 text-gray-600" />
            </Button>
          </div>
          <div className="flex items-center gap-2 md:mt-0">
            <Button
              variant="outline"
              onClick={() => setSelectEmployeeDialogOpen(true)}
            >
              Seleziona Dipendenti
            </Button>
            <PDFDownloadLink
              document={
                <AttendanceDocument
                  attendanceData={filteredAttendanceData}
                  selectedMonth={selectedMonth}
                />
              }
              fileName={`Foglio_Presenze_${format(
                selectedMonth,
                "yyyy-MM"
              )}.pdf`}
            >
              <Button>Esporta</Button>
            </PDFDownloadLink>
          </div>
        </div>
      </CardHeader>

      <CardContent className="p-0 mt-3">
        {isLoading ? (
          <div className="text-center py-8">Caricamento dati...</div>
        ) : (
          <div className="overflow-x-auto">
            <div className="min-w-max">
              <div className="ml-10 flex gap-4">
                {weeks.map((week, weekIndex) => (
                  <div key={weekIndex} className="flex">
                    <div className="flex">
                      {week.map((day, dayIndex) => (
                        <div key={dayIndex} className="w-8">
                          <div className="text-xs text-gray-500 text-center">
                            {format(day, "EEE").toUpperCase()}
                          </div>
                          <div className="text-xs text-blue-500 text-center">
                            {format(day, "d")}
                          </div>
                        </div>
                      ))}
                    </div>
                    {weekIndex < weeks.length - 1 && (
                      <div className="w-4 flex items-center justify-center">
                        <div className="h-full w-px bg-gray-200"></div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="mt-4 space-y-6">
                {filteredAttendanceData.length > 0 ? (
                  filteredAttendanceData.map((employee) => (
                    <div key={employee._id} className="flex flex-col">
                      <div className="flex items-center gap-2 mb-1">
                        <div className="w-9 h-9 bg-gray-100 rounded-full flex items-center justify-center">
                          <span className="text-md text-gray-600 font-medium">
                            {`${employee.firstName[0]}${
                              employee.sureName?.[0] || ""
                            }`}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-md text-gray-900">
                            {employee.firstName}{" "}
                            <span className="font-medium">
                              {employee.sureName}
                            </span>
                          </span>
                          <span className="text-xs text-gray-500">
                            Matricola: {employee.freshman}
                          </span>
                        </div>
                      </div>

                      <div className="ml-10 gap-4 flex">
                        {weeks.map((week, weekIndex) => (
                          <div key={weekIndex} className="flex">
                            <div className="flex">
                              {week.map((day) => {
                                const dateString = format(day, "dd-MM-yyyy");
                                const attendanceForDate =
                                  employee.attendance.find(
                                    (a) => a.date === dateString
                                  );

                                // Get current plan color and acronym
                                const {
                                  color: planColor,
                                  acronym: planAcronym
                                } = getPlanDisplay(attendanceForDate);

                                const absenceCode = getAbsenceCode(
                                  attendanceForDate?.absenceReason
                                );

                                return (
                                  <div
                                    key={`${employee._id}-${day.getTime()}`}
                                    className="w-8"
                                  >
                                    <div
                                      onClick={() => openModal(employee, day)}
                                      className={`w-8 h-8 border rounded hover:bg-gray-50 cursor-pointer mx-auto flex items-center justify-center 
                                        ${
                                          !isSameMonth(day, selectedMonth)
                                            ? "bg-gray-100"
                                            : ""
                                        } 
                                        ${
                                          attendanceForDate?.attendanceState ===
                                          "present"
                                            ? "border-green-500 border"
                                            : ""
                                        }
                                        ${
                                          attendanceForDate?.attendanceState ===
                                          "absent"
                                            ? "border-red-500 border bg-red-100"
                                            : ""
                                        }
                                      `}
                                      style={{
                                        backgroundColor: isSameMonth(
                                          day,
                                          selectedMonth
                                        )
                                          ? attendanceForDate?.attendanceState ===
                                            "present"
                                            ? planColor
                                            : attendanceForDate?.attendanceState ===
                                              "absent"
                                            ? "white"
                                            : "white"
                                          : "white"
                                      }}
                                    >
                                      {attendanceForDate &&
                                        isSameMonth(day, selectedMonth) && (
                                          <div className="flex flex-col items-center text-[10px]">
                                            <span
                                              className={`font-semibold ${
                                                attendanceForDate.attendanceState ===
                                                "present"
                                                  ? "text-white font-bold"
                                                  : "text-red-600 font-bold"
                                              }`}
                                            >
                                              {attendanceForDate.attendanceState ===
                                              "present"
                                                ? planAcronym
                                                : absenceCode}
                                            </span>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            {weekIndex < weeks.length - 1 && (
                              <div className="w-4 flex items-center justify-center">
                                <div className="h-full w-px bg-gray-200"></div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center py-8 text-gray-500">
                    Nessun dato disponibile per il periodo selezionato o nessun
                    dipendente selezionato.
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </CardContent>

      <Dialog open={isModalOpen} onOpenChange={setModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Utente selezionato: {selectedEmployee?.firstName}{" "}
              {selectedEmployee?.sureName}
              <span className="text-sm font-normal text-gray-500 ml-2">
                (Matricola: {selectedEmployee?.freshman})
              </span>
              {selectedDate && ` - ${format(selectedDate, "dd/MM/yyyy")}`}
            </DialogTitle>
          </DialogHeader>
          {selectedEmployee && selectedDate && (
            <ModalContent
              employee={selectedEmployee}
              date={selectedDate}
              onSubmit={handleModalSubmit}
              onDelete={handleDeleteAttendance}
              companyPlans={companyPlans}
              plansMap={plansMap}
              initialAttendanceData={selectedEmployee?.attendance.find(
                (a) => a.date === format(selectedDate, "dd-MM-yyyy")
              )}
              onAttendanceUpdated={fetchAttendanceData}
            />
          )}
        </DialogContent>
      </Dialog>

      <EmployeeSelectionDialog
        isOpen={isSelectEmployeeDialogOpen}
        onClose={() => setSelectEmployeeDialogOpen(false)}
        employees={attendanceData}
        groups={groups}
        selectedEmployees={selectedEmployees}
        onSelectionChange={handleEmployeeSelect}
        onConfirm={() => {
          setSelectEmployeeDialogOpen(false);
          filterAttendanceData();
        }}
      />

      <ToastContainer />
    </Card>
  );
};

const ModalContent = ({
  employee,
  date,
  onSubmit,
  onDelete,
  companyPlans,
  plansMap,
  initialAttendanceData,
  onAttendanceUpdated
}) => {
  const [attendanceState, setAttendanceState] = useState(
    initialAttendanceData?.attendanceState || "present"
  );
  const [selectedPlan, setSelectedPlan] = useState(
    initialAttendanceData?.planId || ""
  );
  const [startTime, setStartTime] = useState(
    initialAttendanceData?.startTime || ""
  );
  const [exitTime, setExitTime] = useState(
    initialAttendanceData?.exitTime || ""
  );
  const [absenceReason, setAbsenceReason] = useState(
    initialAttendanceData?.absenceReason || ""
  );
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [constructionSites, setConstructionSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState(
    initialAttendanceData?.constructionSiteId || "none"
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Reset form when initialAttendanceData changes
  useEffect(() => {
    setAttendanceState(initialAttendanceData?.attendanceState || "present");
    setSelectedPlan(initialAttendanceData?.planId || "");
    setStartTime(initialAttendanceData?.startTime || "");
    setExitTime(initialAttendanceData?.exitTime || "");
    setAbsenceReason(initialAttendanceData?.absenceReason || "");
    setSelectedSite(initialAttendanceData?.constructionSiteId || "none");
  }, [initialAttendanceData]);

  useEffect(() => {
    const fetchConstructionSites = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await getConstructionSites(user.companyId);
        if (response?.data?.sites) {
          const activeSites = response.data.sites.filter(
            (site) => site.status !== "Terminato"
          );
          setConstructionSites(activeSites);
        } else {
          setConstructionSites([]);
        }
      } catch (error) {
        console.error("Error fetching construction sites:", error);
        toast.error("Impossibile recuperare i cantieri");
        setConstructionSites([]);
      }
    };

    fetchConstructionSites();

    // If we have a plan ID and it's in our attendance record but might be outdated
    if (
      initialAttendanceData?.planId &&
      !plansMap[initialAttendanceData.planId]
    ) {
      // Fetch the latest plan details directly
      const fetchLatestPlan = async () => {
        try {
          const response = await getCompanyPlan(initialAttendanceData.planId);
          if (response.status === 200 && response.data.plan) {
            // Just log the data - the parent component will handle refreshing
            //console.log("Fetched latest plan data:", response.data.plan);
            onAttendanceUpdated(); // Signal parent to refresh attendance data
          }
        } catch (error) {
          console.error("Failed to fetch latest plan details:", error);
        }
      };

      fetchLatestPlan();
    }
  }, [initialAttendanceData, plansMap, onAttendanceUpdated]);

  const handleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const selectedPlanDetails = companyPlans.find(
        (p) => p._id === selectedPlan
      );

      let workingHours = 0;
      if (attendanceState === "present" && selectedPlanDetails) {
        const startHour = selectedPlanDetails.startTime.split(":").map(Number);
        const endHour = selectedPlanDetails.endTime.split(":").map(Number);

        workingHours = endHour[0] - startHour[0];
        const minutes = endHour[1] - startHour[1];
        workingHours += minutes / 60;

        if (!selectedPlanDetails.isContinuous) {
          const breakStart = selectedPlanDetails.breakStartTime
            .split(":")
            .map(Number);
          const breakEnd = selectedPlanDetails.breakEndTime
            .split(":")
            .map(Number);
          const breakHours = breakEnd[0] - breakStart[0];
          const breakMinutes = breakEnd[1] - breakStart[1];
          workingHours -= breakHours + breakMinutes / 60;
        }
      }

      // Handle construction site field - only include if it's a valid selection (not "none")
      const constructionSiteId =
        attendanceState === "present" && selectedSite && selectedSite !== "none"
          ? selectedSite
          : null;

      const attendancePayload = {
        _id: initialAttendanceData?._id,
        employeeId: employee._id,
        date: format(date, "dd-MM-yyyy"),
        attendanceState,
        startTime:
          attendanceState === "present"
            ? startTime || selectedPlanDetails?.startTime
            : "",
        exitTime:
          attendanceState === "present"
            ? exitTime || selectedPlanDetails?.endTime
            : "",
        planId: attendanceState === "present" ? selectedPlan : "",
        planAcronym: selectedPlanDetails?.acronym || "",
        planColor: selectedPlanDetails?.color || "",
        absenceReason: attendanceState === "absent" ? absenceReason : "",
        constructionSiteId,
        companyId: user.companyId
      };

      // Mark/update attendance
      await onSubmit(attendancePayload);

      if (attendanceState === "absent") {
        const leavePayload = {
          employeeId: employee._id,
          companyId: user.companyId,
          absenceType: absenceReason,
          startDate: format(date, "yyyy-MM-dd"),
          endDate: format(date, "yyyy-MM-dd"),
          absenceHours: 8
        };

        await AddLeave(leavePayload);
      }

      // Notify plan data change to refresh colors
      const event = new CustomEvent("planDataChanged");
      window.dispatchEvent(event);
    } catch (error) {
      console.error("Failed to update records:", error);
      toast.error("Failed to update records");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    await onDelete(employee._id, format(date, "dd-MM-yyyy"), user.companyId);
    setShowDeleteConfirm(false);
  };

  const selectedPlanDetails = companyPlans.find((p) => p._id === selectedPlan);

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="attendanceState">
          Stato Presenza
          <RequiredIndicator />
        </Label>
        <Select value={attendanceState} onValueChange={setAttendanceState}>
          <SelectTrigger id="attendanceState">
            <SelectValue placeholder="Seleziona lo stato" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="present">Presente</SelectItem>
            <SelectItem value="absent">Assente</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {attendanceState === "present" ? (
        <div className="space-y-2">
          <Label htmlFor="planSelect">
            Seleziona turno
            <RequiredIndicator />
          </Label>
          <Select value={selectedPlan} onValueChange={setSelectedPlan}>
            <SelectTrigger id="planSelect">
              <SelectValue placeholder="Seleziona un turno" />
            </SelectTrigger>
            <SelectContent>
              {companyPlans.map((plan) => (
                <SelectItem key={plan._id} value={plan._id}>
                  {`${plan.name} (${plan.acronym}) - ${plan.workSite}`}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          {selectedPlanDetails && (
            <div className="space-y-2">
              <h3 className="font-bold">Dettagli Turno:</h3>
              <div className="grid gap-2 p-4 border rounded-lg">
                <p>Nome: {selectedPlanDetails.name}</p>
                <p>Acronimo: {selectedPlanDetails.acronym}</p>
                <div className="flex items-center gap-2">
                  <span>Colore:</span>
                  <div
                    className="w-5 h-5 rounded-full border"
                    style={{ backgroundColor: selectedPlanDetails.color }}
                  ></div>
                  <span>{selectedPlanDetails.color}</span>
                </div>
                <p>Sede: {selectedPlanDetails.workSite}</p>
                <p>
                  Orario: {selectedPlanDetails.startTime} -{" "}
                  {selectedPlanDetails.endTime}
                </p>
                {selectedPlanDetails.isContinuous ? (
                  <p>Turno Continuato</p>
                ) : (
                  <p>
                    Pausa: {selectedPlanDetails.breakStartTime} -{" "}
                    {selectedPlanDetails.breakEndTime}
                  </p>
                )}
              </div>
            </div>
          )}

          {attendanceState === "present" && (
            <div className="space-y-2">
              <Label htmlFor="constructionSite">Cantiere</Label>
              <Select
                value={selectedSite}
                onValueChange={setSelectedSite}
                disabled={attendanceState !== "present"}
              >
                <SelectTrigger id="constructionSite">
                  <SelectValue placeholder="Seleziona cantiere (opzionale)" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="none">Nessun cantiere</SelectItem>
                  {constructionSites?.map((site) => (
                    <SelectItem key={site._id} value={site._id}>
                      {site.name} {site.city ? `- ${site.city}` : ""}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
        </div>
      ) : (
        <div className="space-y-2">
          <Label htmlFor="absenceReason">
            Motivo Assenza
            <RequiredIndicator />
          </Label>
          <Select value={absenceReason} onValueChange={setAbsenceReason}>
            <SelectTrigger id="absenceReason">
              <SelectValue placeholder="Seleziona il motivo" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Ingiustificata">Ingiustificata</SelectItem>
              <SelectItem value="Malattia">Malattia</SelectItem>
              <SelectItem value="Ferie">Ferie</SelectItem>
              <SelectItem value="Permesso">Permesso</SelectItem>
            </SelectContent>
          </Select>
        </div>
      )}

      <DialogFooter>
        <div className="flex justify-between w-full">
          {initialAttendanceData && (
            <Button
              variant="destructive"
              onClick={() => setShowDeleteConfirm(true)}
              disabled={isSubmitting}
            >
              Elimina
            </Button>
          )}
          <div className="flex gap-2 flex-grow justify-end">
            {attendanceState === "present" && (
              <Link to="/settings">
                <Button variant="link">Aggiungi nuovo turno</Button>
              </Link>
            )}
            <Button
              onClick={handleSubmit}
              disabled={
                isSubmitting ||
                (attendanceState === "present" && !selectedPlan) ||
                (attendanceState === "absent" && !absenceReason)
              }
            >
              {isSubmitting
                ? "Salvataggio..."
                : initialAttendanceData
                ? "Aggiornamento"
                : "Salva"}
            </Button>
          </div>
        </div>
      </DialogFooter>

      <Dialog open={showDeleteConfirm} onOpenChange={setShowDeleteConfirm}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conferma eliminazione</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p>Sei sicuro di voler eliminare questa registrazione?</p>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setShowDeleteConfirm(false)}
            >
              Annulla
            </Button>
            <Button variant="destructive" onClick={handleDelete}>
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AttendanceSheet;
