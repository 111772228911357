import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createWorkJournalEntry,
  getWorkJournalEntries,
  exportWorkJournalToPDF,
  getWorkJournalEntry,
  updateWorkJournalEntry,
  clearWorkJournalEntry
} from "../../../apis/ConstructionWorkJournal";
import { getConstructionSite } from "../../../apis/ConstructionSite";
import { fetchCompany } from "../../../apis/CompanyDetail";
import { getAttendanceBySiteAndDate } from "../../../apis/Attendence";
import ConstructionSiteNavbar from "./ConstructionSiteNavbar";
import { Archive, Plus, Copy, Trash2, UserCheck } from "lucide-react";

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Textarea } from "../../ui/textarea";
import { Label } from "../../ui/label";
import {
  EnhancedTable,
  TableBody,
  TableCell,
  TableHeader,
  SelectableTableRow,
  SortableTableHead,
  SelectAllTableHead,
  SelectionTableCell,
  ActionsTableCell
} from "../../ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../../ui/tooltip";
import {
  exportWorkJournalToExcel,
  exportWorkJournalToPDFFile
} from "./components/exportProduzione";
import { FileText, Download } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "../../ui/dropdown-menu";
import WorkJournalCalendar from "./components/workJournalCalendar";
import { downloadGiornaleLavoriPDF } from "../../../config/giornaleLavoriExport";
import { getSingleClient } from "../../../apis/client";

function Produzione() {
  const { id } = useParams();
  const [entries, setEntries] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [annotations, setAnnotations] = useState("");
  const [temperature, setTemperature] = useState("");
  const [weather, setWeather] = useState("");
  const [tableData, setTableData] = useState([]);
  const [currentEntryId, setCurrentEntryId] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [isArchived, setIsArchived] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(true);
  const [siteInfo, setSiteInfo] = useState(null);
  const [siteName, setSiteName] = useState("");
  const [selectedTableRows, setSelectedTableRows] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [attendanceData, setAttendanceData] = useState([]);

  useEffect(() => {
    fetchSiteStatus();
    fetchEntries();
    fetchCompanyDetails();
  }, [id]);

  const fetchSiteStatus = async () => {
    setIsLoading(true);
    try {
      //console.log("Fetching site status...");
      const response = await getConstructionSite(id);
      const siteData = response.data;

      // Populate client information if needed
      if (siteData.client && typeof siteData.client === "string") {
        //console.log("Fetching client details for ID:", siteData.client);
        try {
          const clientResponse = await getSingleClient(siteData.client);
          // Get the actual client object from the response
          siteData.client = clientResponse.data.client;
          //console.log("Updated client data:", siteData.client);
        } catch (clientError) {
          console.error("Error fetching client details:", clientError);
        }
      }

      //console.log("Final site data with client:", siteData);
      setIsArchived(siteData.status === "Archiviato");
      setSiteInfo(siteData);
      setSiteName(siteData.name || "Cantiere");
    } catch (error) {
      console.error("Error fetching site status:", error);
      toast.error("Error fetching site information");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCompanyDetails = async () => {
    try {
      if (user?.companyId) {
        const response = await fetchCompany(user.companyId);
        if (response.data?.company?.companyName) {
          setCompanyName(response.data.company.companyName);
        }
      }
    } catch (error) {
      console.error("Errore nel recupero dei dettagli azienda:", error);
      toast.error("Errore nel recupero dei dettagli azienda");
    }
  };

  useEffect(() => {
    fetchEntryForDate(selectedDate);
  }, [selectedDate]);

  const fetchEntries = async () => {
    try {
      const data = await getWorkJournalEntries(id);
      //console.log("Fetched Entries:", data);
      setEntries(data);
    } catch (error) {
      console.error("Errore nel recupero delle voci:", error);
      toast.error("Errore nel recupero delle voci");
    }
  };

  // New function to fetch attendance data by site and date
  const fetchAttendanceData = async (date) => {
    try {
      const attendanceRecords = await getAttendanceBySiteAndDate(id, date);
      //console.log("Fetched attendance records:", attendanceRecords);
      setAttendanceData(attendanceRecords);

      // Convert attendance records to table entries
      const attendanceEntries = attendanceRecords.map((record) => {
        const employee = record.employeeId;
        const jobTitle = employee.jobTitle || "Operaio";
        const fullName = `${employee.firstName} ${employee.sureName}`;

        return {
          name: `${jobTitle} (${fullName})`,
          company: companyName || "Sagia Costruzioni",
          quantity: 1,
          isAttendanceBased: true, // Flag to identify attendance-based entries
          employeeId: employee._id,
          attendanceId: record._id
        };
      });

      // Merge with existing manual entries (preserving entries that aren't attendance-based)
      if (tableData.length > 0) {
        const manualEntries = tableData.filter(
          (entry) => !entry.isAttendanceBased
        );
        setTableData([...manualEntries, ...attendanceEntries]);
      } else {
        setTableData(attendanceEntries);
      }
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  const fetchEntryForDate = async (date) => {
    try {
      const entry = await getWorkJournalEntry(id, date);

      if (entry) {
        setAnnotations(entry.annotations || "");
        setTemperature(entry.temperature || "");
        setWeather(entry.weatherConditions || "");

        // Get manual entries from the journal entry
        const manualEntries = entry.entries || [];

        // Fetch attendance data for this date
        await fetchAttendanceData(date);

        // Set the current entry ID
        setCurrentEntryId(entry._id);
      } else {
        resetForm();
        // Still fetch attendance data even if no journal entry exists
        await fetchAttendanceData(date);
      }
    } catch (error) {
      console.error(
        "Errore nel recupero della voce per la data selezionata:",
        error
      );
      toast.error("Errore nel recupero della voce per la data selezionata");
      resetForm();
      // Try to fetch attendance data anyway
      await fetchAttendanceData(date);
    }
  };

  const handleClearEntry = async () => {
    if (isArchived) return;
    try {
      if (!selectedDate) {
        toast.error("Nessuna data selezionata");
        return;
      }

      await clearWorkJournalEntry(id, selectedDate);
      toast.success("Voce eliminata con successo");
      resetForm();
      await fetchEntries();
      // Fetch attendance data after clearing the journal entry
      await fetchAttendanceData(selectedDate);
    } catch (error) {
      console.error("Errore nella cancellazione:", error);
      toast.error("Errore nella cancellazione della voce");
    }
  };

  const resetForm = () => {
    setAnnotations("");
    setTemperature("");
    setWeather("");
    // Don't clear attendance-based entries
    setTableData(tableData.filter((entry) => entry.isAttendanceBased) || []);
    setCurrentEntryId(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isArchived) return;
    try {
      // Filter out attendance-based entries - they're managed separately
      const manualEntries = tableData.filter(
        (entry) => !entry.isAttendanceBased
      );

      const entryData = {
        date: selectedDate,
        annotations,
        temperature,
        weatherConditions: weather,
        entries: manualEntries // Save only manual entries to the journal
      };

      if (currentEntryId) {
        await updateWorkJournalEntry(id, currentEntryId, entryData);
        toast.success("Voce aggiornata con successo");
      } else {
        await createWorkJournalEntry(id, entryData);
        toast.success("Nuova voce creata con successo");
      }
      await fetchEntries();
    } catch (error) {
      console.error("Errore nel salvataggio della voce:", error);
      toast.error("Errore nel salvataggio della voce");
    }
  };

  const handlePrint = async () => {
    try {
      if (currentEntryId) {
        const pdfBlob = await exportWorkJournalToPDF(id, currentEntryId);
        const url = window.URL.createObjectURL(new Blob([pdfBlob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `work_journal_${format(selectedDate, "yyyy-MM-dd")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        toast.success("PDF generato con successo");
      } else {
        toast.error("Nessuna voce trovata per la data selezionata");
      }
    } catch (error) {
      console.error("Errore nella stampa:", error);
      toast.error(
        `Errore nella generazione del PDF: ${
          error.message || "Errore sconosciuto"
        }`
      );
    }
  };

  const addTableRow = () => {
    if (isArchived) return;
    setTableData([
      ...tableData,
      {
        name: "",
        company: companyName || "Sagia Costruzioni",
        quantity: 1,
        isAttendanceBased: false
      }
    ]);
  };

  const duplicateTableRow = (index) => {
    if (isArchived) return;
    const newRow = { ...tableData[index], isAttendanceBased: false }; // When duplicating, make it a manual entry
    setTableData([
      ...tableData.slice(0, index + 1),
      newRow,
      ...tableData.slice(index + 1)
    ]);
  };

  const deleteTableRow = (index) => {
    if (isArchived) return;
    setTableData(tableData.filter((_, i) => i !== index));
  };

  const handleExportToExcel = async () => {
    try {
      await exportWorkJournalToExcel(entries, siteName);
      toast.success("Export Excel completato con successo");
    } catch (error) {
      console.error("Errore durante l'export in Excel:", error);
      toast.error("Errore durante l'export in Excel");
    }
  };

  const handleExportToPDF = async () => {
    try {
      if (!siteInfo || !entries.length) {
        toast.error("Nessun dato disponibile per l'esportazione");
        return;
      }

      //console.log("Site info before PDF generation:", siteInfo);
      //console.log("Client data in site info:", siteInfo.client);
      //console.log("Entries data:", entries);

      await downloadGiornaleLavoriPDF(siteInfo, entries);
      toast.success("Export PDF completato con successo");
    } catch (error) {
      console.error("Errore durante l'export in PDF:", error);
      console.error("Error stack:", error.stack);
      toast.error("Errore durante l'export in PDF");
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ConstructionSiteNavbar />

      {isArchived && (
        <div className="mx-6 mt-6">
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex items-center">
              <Archive className="w-5 h-5 text-yellow-400 mr-2" />
              <p className="text-yellow-700">
                Questo cantiere è archiviato. È possibile solo visualizzare il
                giornale dei lavori.
              </p>
            </div>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="p-3">
          <div className="flex gap-3 mb-4">
            <Button variant="outline" onClick={handleExportToPDF}>
              <Download className="mr-2 h-4 w-4" /> Esporta
            </Button>

            {!isArchived && currentEntryId && (
              <Button
                type="button"
                variant="destructive"
                onClick={handleClearEntry}
                size="sm"
              >
                Cancella giornata
              </Button>
            )}
          </div>

          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              {/* Left column - Calendar */}
              <div className="lg:col-span-1">
                <div className=" rounded-lg ">
                  <WorkJournalCalendar
                    selectedDate={selectedDate}
                    onDateSelect={setSelectedDate}
                    entries={entries}
                  />
                </div>
              </div>

              {/* Right column - Annotations and Weather */}
              <div className="lg:col-span-2 space-y-6">
                <div>
                  <h2 className="text-lg font-semibold mb-2">
                    Annotazioni ed osservazioni sull'andamento e l'esecuzione
                    dei lavori
                  </h2>
                  <div className=" rounded-lg  p-4">
                    <Textarea
                      value={annotations}
                      onChange={(e) =>
                        !isArchived && setAnnotations(e.target.value)
                      }
                      className="w-full min-h-[100px]"
                      disabled={isArchived}
                    />
                  </div>
                </div>

                <div>
                  <h2 className="text-lg font-semibold mb-2">
                    Condizioni meteorologiche
                  </h2>
                  <div className=" rounded-lg  p-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <Label htmlFor="temperature">Temperatura</Label>
                        <Select
                          id="temperature"
                          value={temperature}
                          onValueChange={(value) =>
                            !isArchived && setTemperature(value)
                          }
                          disabled={isArchived}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Seleziona temperatura" />
                          </SelectTrigger>
                          <SelectContent className="max-h-[200px]">
                            {[...Array(91)].map((_, index) => {
                              const value = (index / 2).toFixed(1);
                              return (
                                <SelectItem key={value} value={value}>
                                  {value.replace(".", ",")}°C
                                </SelectItem>
                              );
                            })}
                          </SelectContent>
                        </Select>
                      </div>
                      <div>
                        <Label htmlFor="weather">Meteo</Label>
                        <Select
                          id="weather"
                          value={weather}
                          onValueChange={(value) =>
                            !isArchived && setWeather(value)
                          }
                          disabled={isArchived}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Seleziona condizioni meteo" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="Caldo intenso">
                              Caldo intenso
                            </SelectItem>
                            <SelectItem value="Gelo">Gelo</SelectItem>
                            <SelectItem value="Meteo estremo">
                              Meteo estremo
                            </SelectItem>
                            <SelectItem value="Nebbia">Nebbia</SelectItem>
                            <SelectItem value="Neve">Neve</SelectItem>
                            <SelectItem value="Nuvoloso">Nuvoloso</SelectItem>
                            <SelectItem value="Parzialmente nuvoloso">
                              Parzialmente nuvoloso
                            </SelectItem>
                            <SelectItem value="Pioggia">Pioggia</SelectItem>
                            <SelectItem value="Sereno">Sereno</SelectItem>
                            <SelectItem value="Siccità">Siccità</SelectItem>
                            <SelectItem value="Temperature variabili">
                              Temperature variabili
                            </SelectItem>
                            <SelectItem value="Umidità alta">
                              Umidità alta
                            </SelectItem>
                            <SelectItem value="Vento forte">
                              Vento forte
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Table section */}
            <div className="mt-8">
              <div className="rounded-lg">
                <div className="flex items-center justify-between p-4 border-b">
                  <h2 className="text-lg font-semibold">
                    Operai, Mezzi ed Attrezzatura utilizzati
                  </h2>
                  {!isArchived && (
                    <div className="flex items-center space-x-2">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              onClick={addTableRow}
                              variant="outline"
                              size="icon"
                            >
                              <Plus className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Aggiungi</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  )}
                </div>

                {/* Information badge about attendance data */}
                {attendanceData.length > 0 && (
                  <div className="flex items-center gap-2 p-2 bg-blue-50 text-blue-700 rounded-md m-2">
                    <UserCheck className="h-4 w-4" />
                    <span className="text-sm">
                      {attendanceData.length} dipendenti dal sistema presenze
                      sono stati inclusi automaticamente.
                    </span>
                  </div>
                )}

                <div className="p-2">
                  <div className="rounded-md border">
                    <EnhancedTable
                      items={tableData}
                      sortConfig={sortConfig}
                      onSortChange={setSortConfig}
                      selectedItems={selectedTableRows}
                      onSelectChange={setSelectedTableRows}
                      selectAll={selectAll}
                      onSelectAllChange={setSelectAll}
                    >
                      <TableHeader>
                        <SelectAllTableHead />
                        <SortableTableHead sortKey="name" className="w-[40%]">
                          Nome
                        </SortableTableHead>
                        <SortableTableHead
                          sortKey="company"
                          className="w-[40%]"
                        >
                          Azienda
                        </SortableTableHead>
                        <SortableTableHead
                          sortKey="quantity"
                          className="w-[10%]"
                        >
                          Q.tà
                        </SortableTableHead>
                        {!isArchived && (
                          <SortableTableHead className="w-[10%]"></SortableTableHead>
                        )}
                      </TableHeader>
                      <TableBody>
                        {tableData.length > 0 ? (
                          tableData.map((row, index) => (
                            <SelectableTableRow
                              key={
                                row.isAttendanceBased
                                  ? `attendance-${row.attendanceId}`
                                  : `manual-${index}`
                              }
                              item={{ ...row, _id: index }}
                            >
                              <SelectionTableCell
                                item={{ ...row, _id: index }}
                              />
                              <TableCell>
                                <div className="relative">
                                  <Input
                                    value={row.name}
                                    onChange={(e) => {
                                      if (isArchived || row.isAttendanceBased)
                                        return;
                                      const newData = [...tableData];
                                      newData[index].name = e.target.value;
                                      setTableData(newData);
                                    }}
                                    disabled={
                                      isArchived || row.isAttendanceBased
                                    }
                                    className={
                                      row.isAttendanceBased
                                        ? "bg-blue-50 border-blue-200"
                                        : ""
                                    }
                                  />
                                  {row.isAttendanceBased && (
                                    <div className="absolute -top-2 -right-2">
                                      <TooltipProvider>
                                        <Tooltip>
                                          <TooltipTrigger asChild>
                                            <div className="bg-blue-500 text-white rounded-full p-1">
                                              <UserCheck className="h-3 w-3" />
                                            </div>
                                          </TooltipTrigger>
                                          <TooltipContent>
                                            <p>
                                              Dato importato dal sistema
                                              presenze
                                            </p>
                                          </TooltipContent>
                                        </Tooltip>
                                      </TooltipProvider>
                                    </div>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell>
                                <Select
                                  value={row.company}
                                  onValueChange={(value) => {
                                    if (isArchived || row.isAttendanceBased)
                                      return;
                                    const newData = [...tableData];
                                    newData[index].company = value;
                                    setTableData(newData);
                                  }}
                                  disabled={isArchived || row.isAttendanceBased}
                                >
                                  <SelectTrigger
                                    className={
                                      row.isAttendanceBased
                                        ? "bg-blue-50 border-blue-200"
                                        : ""
                                    }
                                  >
                                    <SelectValue placeholder="Seleziona azienda" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem
                                      value={companyName || "Sagia Costruzioni"}
                                    >
                                      {companyName || "Sagia Costruzioni"}
                                    </SelectItem>
                                  </SelectContent>
                                </Select>
                              </TableCell>
                              <TableCell>
                                <Input
                                  type="number"
                                  value={row.quantity}
                                  onChange={(e) => {
                                    if (isArchived || row.isAttendanceBased)
                                      return;
                                    const newData = [...tableData];
                                    newData[index].quantity = parseInt(
                                      e.target.value
                                    );
                                    setTableData(newData);
                                  }}
                                  disabled={isArchived || row.isAttendanceBased}
                                  className={
                                    row.isAttendanceBased
                                      ? "bg-blue-50 border-blue-200"
                                      : ""
                                  }
                                />
                              </TableCell>
                              {!isArchived && (
                                <ActionsTableCell>
                                  {row.isAttendanceBased ? (
                                    <DropdownMenuItem
                                      className="text-gray-400 cursor-not-allowed"
                                      onSelect={(e) => e.preventDefault()}
                                    >
                                      <span className="text-xs italic">
                                        Dal sistema presenze
                                      </span>
                                    </DropdownMenuItem>
                                  ) : (
                                    <>
                                      <DropdownMenuItem
                                        onClick={() => duplicateTableRow(index)}
                                        className="flex items-center"
                                      >
                                        <Copy className="h-4 w-4 mr-2" />{" "}
                                        Duplica
                                      </DropdownMenuItem>
                                      <DropdownMenuItem
                                        onClick={() => deleteTableRow(index)}
                                        className="flex items-center text-red-600"
                                      >
                                        <Trash2 className="h-4 w-4 mr-2" />{" "}
                                        Elimina
                                      </DropdownMenuItem>
                                    </>
                                  )}
                                </ActionsTableCell>
                              )}
                            </SelectableTableRow>
                          ))
                        ) : (
                          <SelectableTableRow>
                            <TableCell
                              colSpan={isArchived ? 4 : 5}
                              className="text-center"
                            >
                              Nessun elemento trovato.{" "}
                              {!isArchived &&
                                "Clicca su 'Aggiungi' per inserire un nuovo elemento."}
                            </TableCell>
                          </SelectableTableRow>
                        )}
                      </TableBody>
                    </EnhancedTable>
                  </div>
                </div>
              </div>
            </div>

            {!isArchived && (
              <div className="flex justify-end mt-6 space-x-2">
                <Button type="submit" size="sm">
                  Salva
                </Button>
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  );
}

export default Produzione;
