import React, { useState, useEffect, useRef } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Input } from "./input";
import { Label } from "./label";

const ColorPicker = ({ value = "#000000", onChange }) => {
  const [color, setColor] = useState(value);
  const [rgb, setRgb] = useState({ r: 0, g: 0, b: 0 });
  const [hsv, setHsv] = useState({ h: 0, s: 0, v: 0 });
  
  const saturationRef = useRef(null);
  const hueRef = useRef(null);
  const saturationKnobRef = useRef(null);
  const hueKnobRef = useRef(null);
  
  // Initialize colors when component loads
  useEffect(() => {
    if (value) {
      updateColorFromHex(value);
    }
  }, []);

  // Update RGB and HSV values when color changes
  const updateColorFromHex = (hex) => {
    if (!hex.startsWith('#')) hex = '#' + hex;
    if (hex.length === 4) {
      // Convert shorthand hex (#RGB) to full form (#RRGGBB)
      hex = '#' + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
    }
    
    // Validate hex format
    if (!/^#[0-9A-Fa-f]{6}$/.test(hex)) {
      hex = "#000000";
    }
    
    setColor(hex);
    
    // Convert hex to RGB
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    setRgb({ r, g, b });
    
    // Convert RGB to HSV
    const hsv = rgbToHsv(r, g, b);
    setHsv(hsv);
    
    // Update knob positions
    updateKnobPositions(hsv);
  };
  
  // Convert RGB to HSV
  const rgbToHsv = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;
    
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const delta = max - min;
    
    let h = 0;
    const s = max === 0 ? 0 : delta / max;
    const v = max;
    
    if (delta === 0) {
      h = 0;
    } else if (max === r) {
      h = ((g - b) / delta) % 6;
    } else if (max === g) {
      h = (b - r) / delta + 2;
    } else {
      h = (r - g) / delta + 4;
    }
    
    h = Math.round(h * 60);
    if (h < 0) h += 360;
    
    return { h, s, v };
  };
  
  // Convert HSV to RGB
  const hsvToRgb = (h, s, v) => {
    h = h / 360;
    
    let r, g, b;
    const i = Math.floor(h * 6);
    const f = h * 6 - i;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);
    
    switch (i % 6) {
      case 0: r = v; g = t; b = p; break;
      case 1: r = q; g = v; b = p; break;
      case 2: r = p; g = v; b = t; break;
      case 3: r = p; g = q; b = v; break;
      case 4: r = t; g = p; b = v; break;
      case 5: r = v; g = p; b = q; break;
      default: r = 0; g = 0; b = 0;
    }
    
    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255)
    };
  };
  
  // Convert RGB to Hex
  const rgbToHex = (r, g, b) => {
    return '#' + [r, g, b].map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    }).join('').toUpperCase();
  };
  
  // Update the knob positions based on HSV values
  const updateKnobPositions = ({ h, s, v }) => {
    if (saturationKnobRef.current && saturationRef.current) {
      const saturationRect = saturationRef.current.getBoundingClientRect();
      saturationKnobRef.current.style.left = `${s * 100}%`;
      saturationKnobRef.current.style.top = `${100 - v * 100}%`;
    }
    
    if (hueKnobRef.current) {
      hueKnobRef.current.style.left = `${(h / 360) * 100}%`;
    }
  };
  
  // Handle click on the saturation/value area
  const handleSaturationMouseDown = (e) => {
    const handleSaturationMove = (moveEvent) => {
      if (saturationRef.current) {
        const rect = saturationRef.current.getBoundingClientRect();
        const x = Math.min(Math.max(0, moveEvent.clientX - rect.left), rect.width);
        const y = Math.min(Math.max(0, moveEvent.clientY - rect.top), rect.height);
        
        const s = x / rect.width;
        const v = 1 - y / rect.height;
        
        const newHsv = { ...hsv, s, v };
        setHsv(newHsv);
        
        updateKnobPositions(newHsv);
        
        // Update RGB & Hex
        const rgb = hsvToRgb(newHsv.h, newHsv.s, newHsv.v);
        setRgb(rgb);
        const hex = rgbToHex(rgb.r, rgb.g, rgb.b);
        setColor(hex);
        onChange(hex);
      }
    };
    
    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleSaturationMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
    
    document.addEventListener('mousemove', handleSaturationMove);
    document.addEventListener('mouseup', handleMouseUp);
    
    // Initial position update
    handleSaturationMove(e);
  };
  
  // Handle click on the hue slider
  const handleHueMouseDown = (e) => {
    const handleHueMove = (moveEvent) => {
      if (hueRef.current) {
        const rect = hueRef.current.getBoundingClientRect();
        const x = Math.min(Math.max(0, moveEvent.clientX - rect.left), rect.width);
        
        const h = Math.round((x / rect.width) * 360);
        
        const newHsv = { ...hsv, h };
        setHsv(newHsv);
        
        updateKnobPositions(newHsv);
        
        // Update RGB & Hex
        const rgb = hsvToRgb(newHsv.h, newHsv.s, newHsv.v);
        setRgb(rgb);
        const hex = rgbToHex(rgb.r, rgb.g, rgb.b);
        setColor(hex);
        onChange(hex);
      }
    };
    
    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleHueMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
    
    document.addEventListener('mousemove', handleHueMove);
    document.addEventListener('mouseup', handleMouseUp);
    
    // Initial position update
    handleHueMove(e);
  };
  
  // Handle change in RGB inputs
  const handleRgbChange = (e) => {
    const { name, value } = e.target;
    let numValue = parseInt(value);
    
    // Validate input
    if (isNaN(numValue)) numValue = 0;
    if (numValue > 255) numValue = 255;
    if (numValue < 0) numValue = 0;
    
    const newRgb = { ...rgb, [name]: numValue };
    setRgb(newRgb);
    
    const hex = rgbToHex(newRgb.r, newRgb.g, newRgb.b);
    setColor(hex);
    onChange(hex);
    
    const newHsv = rgbToHsv(newRgb.r, newRgb.g, newRgb.b);
    setHsv(newHsv);
    updateKnobPositions(newHsv);
  };
  
  // Handle change in Hex input
  const handleHexChange = (e) => {
    let newHex = e.target.value;
    
    // Add # if missing
    if (newHex && !newHex.startsWith('#')) {
      newHex = '#' + newHex;
    }
    
    setColor(newHex);
    
    // Only update everything else if it's a valid hex code
    if (/^#[0-9A-Fa-f]{6}$/.test(newHex)) {
      updateColorFromHex(newHex);
      onChange(newHex);
    }
  };
  
  // Calculate the hue color
  const getHueColor = (h) => {
    return `hsl(${h}, 100%, 50%)`;
  };
  
  return (
    <Popover>
      <PopoverTrigger asChild>
        <div
          className="w-10 h-10 rounded-md border border-gray-300 cursor-pointer"
          style={{ backgroundColor: color }}
        />
      </PopoverTrigger>
      <PopoverContent className="w-72">
        <div className="space-y-4">
          {/* Saturation/Value selector */}
          <div
            ref={saturationRef}
            className="relative w-full h-40 rounded-md cursor-pointer"
            onMouseDown={handleSaturationMouseDown}
            style={{
              background: `linear-gradient(to right, #fff, ${getHueColor(hsv.h)}), 
                           linear-gradient(to top, #000, transparent)`,
              backgroundBlendMode: 'multiply'
            }}
          >
            <div
              ref={saturationKnobRef}
              className="absolute w-4 h-4 border-2 border-white rounded-full -translate-x-1/2 -translate-y-1/2 pointer-events-none"
              style={{ 
                boxShadow: "0 0 0 1px rgba(0,0,0,0.3)",
                left: `${hsv.s * 100}%`,
                top: `${(1 - hsv.v) * 100}%`
              }}
            />
          </div>
          
          {/* Hue selector */}
          <div
            ref={hueRef}
            className="relative w-full h-6 rounded-md cursor-pointer"
            onMouseDown={handleHueMouseDown}
            style={{
              background: `linear-gradient(to right, 
                #FF0000, #FFFF00, #00FF00, #00FFFF, #0000FF, #FF00FF, #FF0000)`
            }}
          >
            <div
              ref={hueKnobRef}
              className="absolute top-0 w-4 h-6 border-2 border-white rounded-sm -translate-x-1/2 pointer-events-none"
              style={{ 
                boxShadow: "0 0 0 1px rgba(0,0,0,0.3)",
                left: `${(hsv.h / 360) * 100}%`
              }}
            />
          </div>
          
          {/* Color inputs */}
          <div className="grid grid-cols-2 gap-2">
            <div className="col-span-1">
              <Label htmlFor="hex" className="text-xs">Hex</Label>
              <Input
                id="hex"
                value={color}
                onChange={handleHexChange}
                className="h-8 text-xs"
              />
            </div>
            <div className="col-span-1">
              <Label htmlFor="r" className="text-xs">R</Label>
              <Input
                id="r"
                name="r"
                type="number"
                min="0"
                max="255"
                value={rgb.r}
                onChange={handleRgbChange}
                className="h-8 text-xs"
              />
            </div>
            <div className="col-span-1">
              <Label htmlFor="g" className="text-xs">G</Label>
              <Input
                id="g"
                name="g"
                type="number"
                min="0"
                max="255"
                value={rgb.g}
                onChange={handleRgbChange}
                className="h-8 text-xs"
              />
            </div>
            <div className="col-span-1">
              <Label htmlFor="b" className="text-xs">B</Label>
              <Input
                id="b"
                name="b"
                type="number"
                min="0"
                max="255"
                value={rgb.b}
                onChange={handleRgbChange}
                className="h-8 text-xs"
              />
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ColorPicker;