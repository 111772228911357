import { createJsonTypeInstance } from "./index";
const axios = createJsonTypeInstance();

export const GetMonthlySalary = async (companyId, month, year, searchQuery) => {
  try {
    const response = await axios.get(
      `/salary/get-salaries?companyId=${companyId}&month=${month}&year=${year}&search=${searchQuery}`
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const CalculateSalary = async (data) => {
  try {
    const response = await axios.post("/salary/calculate-salary", data);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};


export const UpdateSalaryStatus = async (data) => {
  try {
    const response = await axios.put('/salary/update-status', data);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};