import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Eye, Trash2, Plus, Search } from "lucide-react";
import { fetchDdt, deleteDdt } from "../../../apis/Ddt";
import { fetchCantiere } from "../../../apis/DdtEdit";
import { toast } from "react-toastify";
import Pagination from "../sharedComponent/Pgination";

import { Button } from "../../ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../../ui/tooltip";
import {
  EnhancedTable,
  TableHeader,
  TableBody,
  TableCell,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell
} from "../../ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "../../ui/dropdown-menu";
import SearchBar from "../sharedComponent/SearchBar";
import HelpSheet from "../sharedComponent/HelpSheet";
import { exportToExcel, exportToPDF } from "../../../config/helper";

const TableComponent = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [cantiereMap, setCantiereMap] = useState({});

  // Enhanced Table state
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const getCantiereData = async () => {
    try {
      const response = await fetchCantiere(companyId);
      const sites = response?.data?.sites || [];
      const siteMap = {};
      sites.forEach((site) => {
        siteMap[site._id] = site.name;
      });
      setCantiereMap(siteMap);
    } catch (error) {
      console.error("Error fetching cantiere data:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getItems = async () => {
    try {
      const response = await fetchDdt(companyId, currentPage, limit);
      if (response.data.success) {
        const limitedItems = response.data.ddts.slice(0, limit);
        setData(limitedItems);
        setFilteredData(limitedItems);
        setDataIsLoaded(true);
        setTotalPage(Math.ceil(response.data.totalDdt / limit));
        setCurrentPage(response.data.currentPage);
        setTotalRecords(response.data.totalDdt);
      }
    } catch (error) {
      console.error("Error fetching DDTs:", error);
      toast.error("Errore nel caricamento dei DDT");
    }
  };

  useEffect(() => {
    getItems();
    getCantiereData();
  }, [currentPage, limit]);

  // Apply sorting when sort configuration changes
  useEffect(() => {
    if (sortConfig.key && filteredData.length > 0) {
      const sortedData = [...filteredData].sort((a, b) => {
        let aValue, bValue;

        // Handle special cases for different columns
        if (sortConfig.key === "cantiere") {
          aValue = cantiereMap[a.cantiere] || "";
          bValue = cantiereMap[b.cantiere] || "";
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }

        // Handle string comparison
        if (typeof aValue === "string") {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });

      setFilteredData(sortedData);
    }
  }, [sortConfig, cantiereMap]);

  const handleSearch = (value) => {
    const searchTerm = value.toLowerCase();
    if (searchTerm.length >= 3) {
      const filtered = data.filter(
        (item) =>
          item.numeroddt?.toLowerCase().includes(searchTerm) ||
          item.data?.toLowerCase().includes(searchTerm) ||
          item.valuta?.toLowerCase().includes(searchTerm) ||
          cantiereMap[item.cantiere]?.toLowerCase().includes(searchTerm) ||
          item.tipologia?.toLowerCase().includes(searchTerm)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Sei sicuro di voler eliminare questo DDT?")) {
      try {
        const response = await deleteDdt(id);
        if (response.data.success) {
          toast.success("DDT eliminato con successo");
          getItems();
        }
      } catch (error) {
        console.error("Error deleting DDT:", error);
        toast.error("Errore nell'eliminazione del DDT");
      }
    }
  };

  const handleExport = (value) => {
    const formattedData = filteredData.map((item, index) => ({
      nu: (index + 1).toString(),
      numero: item.numeroddt || "",
      data: item.data || "",
      valuta: item.valuta || "",
      cantiere: cantiereMap[item.cantiere] || "-",
      tipologia: item.tipologia || "-",
      prodotti: item.prodottival ? `€${item.prodottival}` : "€0",
      iva: item.ivaval ? `€${item.ivaval}` : "€0",
      totale: item.totaleval ? `€${item.totaleval}` : "€0"
    }));

    const columns = [
      { header: "N°", key: "nu" },
      { header: "Numero DDT", key: "numero" },
      { header: "Data", key: "data" },
      { header: "Valuta", key: "valuta" },
      { header: "Cantiere", key: "cantiere" },
      { header: "Tipologia", key: "tipologia" },
      { header: "Prodotti", key: "prodotti" },
      { header: "IVA", key: "iva" },
      { header: "Totale", key: "totale" }
    ];

    const stats = [
      { label: "Totale DDT", value: totalRecords },
      { label: "Data Export", value: new Date().toLocaleDateString() }
    ];

    if (value === "excel") {
      exportToExcel(formattedData, "DDT_Export");
    } else if (value === "pdf") {
      exportToPDF(formattedData, columns, "Elenco DDT", stats);
    }
  };

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-row sm:items-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button>Nuovo DDT</Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-[170px]">
                    <DropdownMenuItem asChild>
                      <Link
                        to="/ddt_add?type=Emessi"
                        className="cursor-pointer"
                      >
                        Nuovo DDT emesso
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link
                        to="/ddt_add?type=Ricevuti"
                        className="cursor-pointer"
                      >
                        Nuovo DDT ricevuto
                      </Link>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TooltipTrigger>
              <TooltipContent>
                <p>Crea nuovo DDT</p>
              </TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <Select onValueChange={handleExport}>
                  <SelectTrigger className="w-full sm:w-[180px]">
                    <SelectValue placeholder="Esporta" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="excel">Esporta in Excel</SelectItem>
                    <SelectItem value="pdf">Esporta in PDF</SelectItem>
                  </SelectContent>
                </Select>
              </TooltipTrigger>
              <TooltipContent>
                <p>Esporta dati</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="flex items-center space-x-2">
          <SearchBar onSearch={handleSearch} />
          <HelpSheet route="/ddt" />
        </div>
      </div>

      <div className="rounded-md border">
        <EnhancedTable
          items={filteredData}
          sortConfig={sortConfig}
          onSortChange={setSortConfig}
          selectedItems={selectedItems}
          onSelectChange={setSelectedItems}
          selectAll={selectAll}
          onSelectAllChange={setSelectAll}
        >
          <TableHeader>
            <SelectAllTableHead />
            <SortableTableHead sortKey="numeroddt">
              Numero DDT
            </SortableTableHead>
            <SortableTableHead sortKey="tipologia">Tipologia</SortableTableHead>
            <SortableTableHead sortKey="data">Data</SortableTableHead>
            <SortableTableHead sortKey="cantiere">Cantiere</SortableTableHead>
            <SortableTableHead></SortableTableHead>
          </TableHeader>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map((item) => (
                <SelectableTableRow key={item._id} item={item}>
                  <SelectionTableCell item={item} />
                  <TableCell className="font-medium">
                    {item.numeroddt || "-"}
                  </TableCell>
                  <TableCell>{item.tipologia || "-"}</TableCell>
                  <TableCell>{item.data || "-"}</TableCell>
                  <TableCell>{cantiereMap[item.cantiere] || "-"}</TableCell>
                  <ActionsTableCell>
                    <DropdownMenuItem asChild>
                      <Link
                        to={`/ddt_edit/${item._id}`}
                        className="flex items-center"
                      >
                        <Eye className="h-4 w-4 mr-2" /> Visualizza/Modifica
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => handleDelete(item._id)}
                      className="flex items-center"
                    >
                      <Trash2 className="h-4 w-4 text-red-500 mr-2" /> Elimina
                    </DropdownMenuItem>
                  </ActionsTableCell>
                </SelectableTableRow>
              ))
            ) : (
              <SelectableTableRow>
                <TableCell colSpan={6} className="text-center py-4">
                  {dataIsLoaded ? "Nessun DDT trovato" : "Caricamento..."}
                </TableCell>
              </SelectableTableRow>
            )}
          </TableBody>
        </EnhancedTable>
      </div>

      {filteredData.length > 0 && (
        <Pagination
          totalPages={totalPages}
          totalItems={totalRecords}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
    </div>
  );
};

export default TableComponent;
