import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { X } from "lucide-react";
import { format } from "date-fns";

import { Button } from "../../../../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../ui/select";
import { Input } from "../../../../ui/input";
import { Label } from "../../../../ui/label";
import { AddLeave, UpdateLeave } from "../../../../../apis/leave";
import { markAttendance } from "../../../../../apis/Attendence";

const AddAbsence = ({ 
  isOpen, 
  onClose, 
  setIsModalOpen, 
  employeeData, 
  selectedId, 
  SingleLeaveData, 
  dataUpdate, 
  setDataUpdate 
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: SingleLeaveData,
  });

  // ✅ Async function to process attendance records
  const createAttendanceRecords = async (data, employeeId) => {
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);
    const attendanceRecords = [];
  
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      attendanceRecords.push({
        employeeId: employeeId,
        date: format(currentDate, "dd-MM-yyyy"),
        attendanceState: "absent",
        absenceReason: data.absenceType,
        companyId: companyId,
      });
  
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    try {
      // ✅ Send a single API call with all records
      await markAttendance(attendanceRecords);
    } catch (error) {
      console.error("Error creating attendance records:", error);
    }
  };
  

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setIsLoading(true); // ✅ Disable button while processing

    try {
      // Find selected employee
      const selectedEmployee = employeeData.find(emp => emp.employeeId === data.employeeId);
      const payload = {
        ...data,
        companyId: companyId,
        matricola: selectedEmployee?.freshman || '',
      };
      
      if (selectedId) {
        const res = await UpdateLeave(selectedId, payload);
        if (res.status === 200) {
          toast.success("Assenza aggiornata con successo");
          await createAttendanceRecords(data, data.employeeId);
        } else {
          toast.error("Errore nell'aggiornamento dell'assenza");
        }
      } else {
        const res = await AddLeave(payload);
        if (res.status === 201) {
          toast.success("Assenza aggiunta con successo");
          await createAttendanceRecords(data, data.employeeId);
        } else {
          toast.error("Errore durante l'aggiunta dell'assenza");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Si è verificato un errore");
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
      setDataUpdate(!dataUpdate);
    }
  };

  useEffect(() => {
    reset(SingleLeaveData);
  }, [SingleLeaveData, reset]);

  // ✅ Automatically calculate hours
  useEffect(() => {
    const startDate = new Date(watch("startDate"));
    const endDate = new Date(watch("endDate"));

    if (startDate && endDate && startDate <= endDate) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
      setValue("absenceHours", diffDays * 8);
    } else {
      setValue("absenceHours", 0);
    }
  }, [watch("startDate"), watch("endDate")]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-xl">
        <DialogHeader>
          <div className="flex items-center justify-between">
            <DialogTitle>{selectedId ? "Aggiorna assenza" : "Aggiungi assenza"}</DialogTitle>
          </div>
        </DialogHeader>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="employeeId">Dipendente</Label>
            <Select onValueChange={(value) => setValue("employeeId", value)}>
              <SelectTrigger>
                <SelectValue placeholder="Seleziona dipendente" />
              </SelectTrigger>
              <SelectContent>
                {employeeData?.map((employee) => (
                  <SelectItem key={employee.employeeId} value={employee.employeeId}>
                    {`${employee.name} (${employee.freshman || 'No matricola'})`}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <Label htmlFor="absenceType">Tipologia assenza</Label>
            <Select onValueChange={(value) => setValue("absenceType", value)}>
              <SelectTrigger>
                <SelectValue placeholder="Seleziona tipologia" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Ferie">Ferie</SelectItem>
                <SelectItem value="Malattia">Malattia</SelectItem>
                <SelectItem value="Permesso">Permesso</SelectItem>
                <SelectItem value="Ingiustificata">Ingiustificata</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <Label htmlFor="startDate">Data inizio assenza</Label>
            <Input id="startDate" type="date" {...register("startDate")} />
          </div>

          <div className="space-y-2">
            <Label htmlFor="endDate">Data fine assenza</Label>
            <Input id="endDate" type="date" {...register("endDate")} />
          </div>

          <div className="space-y-2">
            <Label htmlFor="absenceHours">Ore di assenza</Label>
            <Input id="absenceHours" type="number" min={0} {...register("absenceHours")} readOnly />
          </div>

          <div className="flex justify-end space-x-2 pt-4">
            <Button type="button" variant="destructive" onClick={onClose} disabled={isLoading}>
              Annulla
            </Button>
            <Button type="submit" className="bg-[#06052C] hover:opacity-90" disabled={isLoading}>
              {isLoading ? "Salvataggio..." : selectedId ? "Aggiornamento" : "Aggiungi"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddAbsence;
