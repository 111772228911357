import { createJsonTypeInstance } from "./index";
const axios = createJsonTypeInstance();

export const fetchComputimetric = async (companyId, currentPage, limit) => {
  try {
    const response = await axios.get(
      `/computimetric/${companyId}?page=${currentPage}&limit=${limit}`
    );
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const addComputimetric = async (newData) => {
  try {
    const response = await axios.post(`/computimetric`, newData);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const fetchComputimetricById = async (id) => {
  try {
    const response = await axios.get(`/computimetric/find_one/${id}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const updateComputimetric = async (id, updatedData) => {
  try {
    if (!id) {
      throw new Error("ID non fornito per l'aggiornamento");
    }
    const response = await axios.put(`/computimetric/${id}`, updatedData);
    //console.log("Update Computimetric response:", response);
    return response.data;
  } catch (error) {
    console.error("Errore nell'aggiornamento del computimetric:", error);
    throw error;
  }
};

export const deleteComputimetric = async (id) => {
  try {
    const response = await axios.delete(`computimetric/${id}`);
    //console.log("Deleted Preventivi frontend:", response);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const fetchClients = async (companyId) => {
  try {
    const response = await axios.get(
      `/client/all-Clients_computimetric/${companyId}`
    );
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const downloadComputimetricPDF = async (id) => {
  try {
    const response = await axios.get(`/computimetric/${id}/pdf`, {
      responseType: "blob"
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `computimetric-${id}.pdf`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error("Error downloading PDF:", error);
    throw error;
  }
};
