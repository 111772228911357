import { Button } from "../ui/button";
import Modal from "react-modal";
import { Input } from "../../components/ui/input";
Modal.setAppElement("#root");

const PaymentModal = ({
  isOpen,
  onClose,
  paymentAmount,
  setPaymentAmount,
  paymentDescription,
  setPaymentDescription,
  handlePaymentSubmit,
  loading,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-semibold">Create Payment</h2>
        <Input
          type="number"
          placeholder="Enter amount"
          value={paymentAmount}
          onChange={(e) => setPaymentAmount(e.target.value)}
          className="w-full p-2 border rounded-md mt-3"
        />
        <Input
          type="text"
          placeholder="Enter description"
          value={paymentDescription}
          onChange={(e) => setPaymentDescription(e.target.value)}
          className="w-full p-2 border rounded-md mt-3"
        />
        <div className="flex justify-end mt-4 gap-1">
          <Button onClick={onClose} disabled={loading}>
            Close
          </Button>
          <Button onClick={handlePaymentSubmit} disabled={loading}>
            Submit Payment
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentModal;
