import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "../../../../ui/button";
import AccordionItem from "./AccordionItem";
import MainData from "./MainData";
import Position from "../../client/addClient/Position";
import Contacts from "../../client/addClient/Contacts";
import Extra from "../../client/addClient/Extra";
import {
  addSupplier,
  getSingleSupplier,
  updateSupplier
} from "../../../../../apis/supplier";

const AddSupplier = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;
  const [singleSupplierData, setSingleSupplierData] = useState({});
  const { register, handleSubmit, reset } = useForm({
    defaultValues: singleSupplierData
  });

  const items = [
    {
      id: "11",
      label: "Dati Principali",
      content: <MainData register={register} />
    },
    { id: "22", label: "Posizione", content: <Position register={register} /> },
    { id: "33", label: "Contatti", content: <Contacts register={register} /> },
    { id: "34", label: "Extra", content: <Extra register={register} /> }
  ];

  const handleClick = (nextIndex) => {
    setExpandedIndex(expandedIndex === nextIndex ? -1 : nextIndex);
  };

  const fetchSingleSupplier = async () => {
    try {
      const response = await getSingleSupplier(id);
      //console.log(response.data.supplier);
      setSingleSupplierData(response?.data?.supplier);
    } catch (error) {
      console.error(error);
      toast.error("Errore nel recupero dei dati del fornitore");
    }
  };

  useEffect(() => {
    if (id) {
      fetchSingleSupplier();
    }
  }, [id]);

  useEffect(() => {
    reset(singleSupplierData);
  }, [singleSupplierData, reset]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      const payload = {
        ...data,
        companyId: companyId
      };

      if (id) {
        const response = await updateSupplier(id, payload);
        if (response.status === 200) {
          toast.success(response.data.message);
          navigate("/suppliers");
        }
      } else {
        const response = await addSupplier(payload);
        if (response.status === 201) {
          toast.success(response.data.message);
          navigate("/suppliers");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Errore nel salvataggio dei dati");
    }
  };

  return (
    <div className="md:w-4/5 mx-auto rounded-lg mt-20 bg-white p-1 md:p-5">
      <h1 className="text-2xl font-bold mb-5 text-center">
        {id ? "Modifica Fornitore" : "Aggiungi Nuovo Fornitore"}
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          {items?.map((item, index) => (
            <AccordionItem
              key={item.id}
              id={item.id}
              label={item.label}
              isExpanded={index === expandedIndex}
              onClick={() => handleClick(index)}
            >
              {item.content}
            </AccordionItem>
          ))}
        </div>
        <div className="flex justify-end mt-5 gap-3">
          <Button variant="destructive" onClick={() => navigate("/suppliers")}>
            Annulla
          </Button>
          <Button type="submit">{id ? "Aggiorna" : "Salva"}</Button>
        </div>
      </form>
    </div>
  );
};

export default AddSupplier;
