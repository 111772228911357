import React, { useState, useEffect } from "react";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import { Switch } from "../../../ui/switch";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import { RadioGroup, RadioGroupItem } from "../../../ui/radio-group";
import { useFormulario } from "./context/FormularioContext";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../ui/tooltip";
import { HelpCircle } from "lucide-react";

const Rifiuto = () => {
  const { updateRifiuto, formularioData } = useFormulario();
  const rifiutoData = formularioData.rifiuto;

  const [isADR, setIsADR] = useState(rifiutoData.adr?.soggetto || false);
  const [isVerificatoPartenza, setIsVerificatoPartenza] = useState(
    rifiutoData.quantita?.verificatoPartenza || false
  );
  const [isRinfusa, setIsRinfusa] = useState(
    rifiutoData.quantita?.rinfusa || false
  );

  useEffect(() => {
    setIsADR(rifiutoData.adr?.soggetto || false);
    setIsVerificatoPartenza(rifiutoData.quantita?.verificatoPartenza || false);
    setIsRinfusa(rifiutoData.quantita?.rinfusa || false);
  }, [rifiutoData]);

  const handleRifiutoChange = (section, field, value) => {
    updateRifiuto({
      ...rifiutoData,
      [section]: {
        ...rifiutoData[section],
        [field]: value,
      },
    });
  };

  const handleADRChange = (checked) => {
    setIsADR(checked);
    handleRifiutoChange("adr", "soggetto", checked);
  };

  return (
    <div className="space-y-6">
      {/* Waste Characteristics Section */}
      <Card>
        <CardHeader>
          <CardTitle>Caratteristiche rifiuto</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          {/* First row */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="space-y-2">
              <Label htmlFor="provenienza">Provenienza</Label>
              <Select
                value={rifiutoData.caratteristiche?.provenienza}
                onValueChange={(value) =>
                  handleRifiutoChange("caratteristiche", "provenienza", value)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona provenienza" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="tipo1">Tipo 1</SelectItem>
                  <SelectItem value="tipo2">Tipo 2</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="codiceEER">Codice EER</Label>
              <Select
                value={rifiutoData.caratteristiche?.codiceEER}
                onValueChange={(value) =>
                  handleRifiutoChange("caratteristiche", "codiceEER", value)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona codice" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="codice1">Codice 1</SelectItem>
                  <SelectItem value="codice2">Codice 2</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="destinazioneRifiuto">Destinazione rifiuto</Label>
              <Input
                id="destinazioneRifiuto"
                value={rifiutoData.caratteristiche?.destinazioneRifiuto || ""}
                onChange={(e) =>
                  handleRifiutoChange(
                    "caratteristiche",
                    "destinazioneRifiuto",
                    e.target.value
                  )
                }
              />
            </div>
          </div>

          {/* Second row */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="statoFisico">Stato fisico</Label>
              <Select
                value={rifiutoData.caratteristiche?.statoFisico}
                onValueChange={(value) =>
                  handleRifiutoChange("caratteristiche", "statoFisico", value)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona stato" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="solido">Solido</SelectItem>
                  <SelectItem value="liquido">Liquido</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="caratteristichePericolo">
                Caratteristiche di pericolo
              </Label>
              <Select
                value={rifiutoData.caratteristiche?.caratteristichePericolo}
                onValueChange={(value) =>
                  handleRifiutoChange(
                    "caratteristiche",
                    "caratteristichePericolo",
                    value
                  )
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona caratteristiche" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="hp1">HP1</SelectItem>
                  <SelectItem value="hp2">HP2</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Quantity Section */}
      <Card>
        <CardHeader>
          <CardTitle>Quantità</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          {/* First row - Switches */}
          <div className="flex flex-wrap gap-8">
            <div className="flex items-center justify-between gap-4">
              <Label htmlFor="verificatoPartenza" className="font-medium">
                Verificato in partenza
              </Label>
              <Switch
                id="verificatoPartenza"
                checked={isVerificatoPartenza}
                onCheckedChange={(checked) => {
                  setIsVerificatoPartenza(checked);
                  handleRifiutoChange(
                    "quantita",
                    "verificatoPartenza",
                    checked
                  );
                }}
              />
            </div>
            <div className="flex items-center justify-between gap-4">
              <Label htmlFor="rinfusa" className="font-medium">
                Rinfusa
              </Label>
              <Switch
                id="rinfusa"
                checked={isRinfusa}
                onCheckedChange={(checked) => {
                  setIsRinfusa(checked);
                  handleRifiutoChange("quantita", "rinfusa", checked);
                }}
              />
            </div>
          </div>

          {/* Second row */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="space-y-2">
              <Label htmlFor="quantita">Quantità</Label>
              <Input
                id="quantita"
                type="number"
                value={rifiutoData.quantita?.quantita || ""}
                onChange={(e) =>
                  handleRifiutoChange("quantita", "quantita", e.target.value)
                }
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="unitaMisura">Unità di misura</Label>
              <Select
                value={rifiutoData.quantita?.unitaMisura}
                onValueChange={(value) =>
                  handleRifiutoChange("quantita", "unitaMisura", value)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona unità" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="kg">Kg</SelectItem>
                  <SelectItem value="t">T</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="numeroColli">Numero Colli</Label>
              <Input
                id="numeroColli"
                type="number"
                value={rifiutoData.quantita?.numeroColli || ""}
                onChange={(e) =>
                  handleRifiutoChange("quantita", "numeroColli", e.target.value)
                }
              />
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Analysis Section */}
      <Card>
        <CardHeader>
          <CardTitle>Analisi</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-wrap items-center gap-8">
            <RadioGroup
              value={rifiutoData.analisi?.tipo}
              defaultValue="analisi"
              className="flex items-center gap-4"
              onValueChange={(value) =>
                handleRifiutoChange("analisi", "tipo", value)
              }
            >
              <div className="flex items-center gap-2">
                <RadioGroupItem value="analisi" id="analisi" />
                <Label htmlFor="analisi">Analisi/rapporto di prova</Label>
              </div>
              <div className="flex items-center gap-2">
                <RadioGroupItem value="classificazione" id="classificazione" />
                <Label htmlFor="classificazione">Classificazione</Label>
              </div>
            </RadioGroup>
            <div className="flex items-center gap-4 flex-1">
              <div className="flex-1">
                <Label htmlFor="numero">Numero</Label>
                <Input
                  id="numero"
                  value={rifiutoData.analisi?.numero || ""}
                  onChange={(e) =>
                    handleRifiutoChange("analisi", "numero", e.target.value)
                  }
                />
              </div>
              <div className="flex-1">
                <Label htmlFor="validaAl">Valida al</Label>
                <div className="relative">
                  <Input
                    id="validaAl"
                    type="date"
                    value={rifiutoData.analisi?.validaAl || ""}
                    onChange={(e) =>
                      handleRifiutoChange("analisi", "validaAl", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* ADR Regulation Section */}
      <Card>
        <CardHeader>
          <div className="flex items-center gap-2">
            <CardTitle>Normativa ADR</CardTitle>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <HelpCircle className="h-4 w-4 text-gray-500" />
                </TooltipTrigger>
                <TooltipContent className="max-w-xs">
                  <p>
                    Nel caso in cui il trasporto avvenga esclusivamente tramite
                    un vettore marittimo o ferroviario le informazioni relative
                    al trasporto ADR si intendono riferite al RID (Regolamento
                    sul trasporto ferroviario di merci pericolose) o al IMDG
                    (Regolamento sul trasporto via mare di merci pericolose).
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </CardHeader>
        <CardContent className="space-y-4">
          {/* First row */}
          <div className="flex items-center justify-between w-fit gap-4">
            <Label htmlFor="isADR" className="font-medium">
              Trasporto soggetto a normativa ADR
            </Label>
            <Switch
              id="isADR"
              checked={isADR}
              onCheckedChange={handleADRChange}
            />
          </div>

          {/* ADR Fields with animation */}
          <div
            className={`
              overflow-y-clip transition-all duration-300 ease-in-out pb-1
              ${isADR ? "max-h-[188px] opacity-100" : "max-h-0 opacity-0"}
            `}
          >
            <div className="space-y-4">
              {/* Second row */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="numeroONU">Numero ONU</Label>
                  <Input
                    id="numeroONU"
                    value={rifiutoData.adr?.numeroONU || ""}
                    onChange={(e) =>
                      handleRifiutoChange("adr", "numeroONU", e.target.value)
                    }
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="classe">Classe</Label>
                  <Input
                    id="classe"
                    value={rifiutoData.adr?.classe || ""}
                    onChange={(e) =>
                      handleRifiutoChange("adr", "classe", e.target.value)
                    }
                  />
                </div>
              </div>

              {/* Third row */}
              <div className="space-y-2">
                <Label htmlFor="note">Note</Label>
                <Input
                  id="note"
                  className="w-full"
                  value={rifiutoData.adr?.note || ""}
                  onChange={(e) =>
                    handleRifiutoChange("adr", "note", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Rifiuto;
