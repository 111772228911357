// services/referral.service.js

import { createJsonTypeInstance } from "./index";
const axios = createJsonTypeInstance();

// Get user's referral code
export const getReferralCode = async () => {
  try {
    const response = await axios.get("/referral/code");
    if (response.data.success) {
      return response.data;
    }
    throw new Error(
      response.data.message || "Errore nel recuperare il codice di invito"
    );
  } catch (error) {
    throw error.response?.data || { message: "Errore di rete" };
  }
};

// Get list of referrals
export const getReferrals = async () => {
  try {
    const response = await axios.get("/referral/list");
    if (response.data.success) {
      return response.data;
    }
    throw new Error(
      response.data.message || "Errore nel recuperare gli inviti"
    );
  } catch (error) {
    throw (
      error.response?.data || { message: "Errore nel recupero degli inviti" }
    );
  }
};

// Validate a referral code
export const validateReferralCode = async (code) => {
  try {
    //console.log('Attempting to validate code:', code); // Debug log

    if (!code) {
      throw new Error("Codice di invito non fornito");
    }

    // Log the request being made
    //console.log('Making validation request with:', { referralCode: code });

    const response = await axios.post("/referral/validate", {
      referralCode: code
    });

    // Log the response
    //console.log('Server response:', response.data);

    if (response.data.success) {
      return response.data;
    }

    throw new Error(response.data.message || "Codice di invito non valido");
  } catch (error) {
    console.error("Validation error:", error.response || error);
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: error.message || "Codice di invito non valido" };
  }
};

// Register with referral code
export const registerWithReferral = async (userData, referralCode) => {
  try {
    let registerData = { ...userData };

    if (referralCode) {
      // First validate the referral code
      const validationResponse = await validateReferralCode(referralCode);

      if (validationResponse.success) {
        registerData.referredBy = validationResponse.referrerId;
        registerData.referralCode = referralCode;
      }
    }

    // Register the user
    const response = await axios.post("/auth/register", registerData);
    return response.data;
  } catch (error) {
    throw (
      error.response?.data || { message: "Errore durante la registrazione" }
    );
  }
};
