import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const fetchDashboard = async (currentPage, limit) => {
  try {
    const response = await axios.get(`/api/dashboard`);
    return response;
  } catch (error) {
    throw error.response;
  }
};