import * as React from "react";
import { ChevronUp, ChevronDown, SortAsc, SortDesc, MoreHorizontal } from "lucide-react";
import { Checkbox } from "../ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import { cn } from "../../lib/utils";

// Original Table Components - unchanged from shadcn
const Table = React.forwardRef(({ className, ...props }, ref) => (
  <div className="relative w-full overflow-auto">
    <table
      ref={ref}
      className={cn("w-full caption-bottom text-sm", className)}
      {...props} />
  </div>
))
Table.displayName = "Table"

const TableHeader = React.forwardRef(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn("[&_tr]:border-b border-b border-slate-200", className)} {...props} />
))
TableHeader.displayName = "TableHeader"

const TableBody = React.forwardRef(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn("[&_tr:last-child]:border-0", className)}
    {...props} />
))
TableBody.displayName = "TableBody"

const TableFooter = React.forwardRef(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn("border-t bg-muted/50 font-medium [&>tr]:last:border-b-0", className)}
    {...props} />
))
TableFooter.displayName = "TableFooter"

const TableRow = React.forwardRef(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      "border-b border-slate-200 transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted",
      className
    )}
    {...props} />
))
TableRow.displayName = "TableRow"

const TableHead = React.forwardRef(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "h-8 px-4 text-left align-middle font-bold text-slate-900 [&:has([role=checkbox])]:pr-0",
      className
    )}
    {...props} />
))
TableHead.displayName = "TableHead"

const TableCell = React.forwardRef(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn("px-4 align-middle [&:has([role=checkbox])]:pr-0", className)}
    {...props} />
))
TableCell.displayName = "TableCell"

const TableCaption = React.forwardRef(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-muted-foreground", className)}
    {...props} />
))
TableCaption.displayName = "TableCaption"

// Context for table sorting and selection
const TableContext = React.createContext({
  sortConfig: { key: null, direction: null },
  requestSort: () => {},
  selectedItems: {},
  selectItem: () => {},
  selectAll: false,
  handleSelectAll: () => {},
  items: []
});

// Enhanced Table with selection and sorting functionality
const EnhancedTable = React.forwardRef(({ 
  children, 
  className, 
  sortConfig, 
  onSortChange, 
  selectedItems = {}, 
  onSelectChange,
  selectAll = false,
  onSelectAllChange,
  items = [],
  ...props 
}, ref) => {
  // If sort props aren't provided, use local state
  const [internalSortConfig, setInternalSortConfig] = React.useState({ key: null, direction: null });
  const [internalSelectedItems, setInternalSelectedItems] = React.useState({});
  const [internalSelectAll, setInternalSelectAll] = React.useState(false);

  const actualSortConfig = sortConfig || internalSortConfig;
  const actualSelectedItems = selectedItems || internalSelectedItems;
  const actualSelectAll = selectAll || internalSelectAll;

  const requestSort = (key) => {
    let direction = 'ascending';
    if (actualSortConfig.key === key && actualSortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    
    const newSortConfig = { key, direction };
    
    if (onSortChange) {
      onSortChange(newSortConfig);
    } else {
      setInternalSortConfig(newSortConfig);
    }
  };

  const selectItem = (id, checked) => {
    const newSelectedItems = { ...actualSelectedItems, [id]: checked };
    
    if (onSelectChange) {
      onSelectChange(newSelectedItems);
    } else {
      setInternalSelectedItems(newSelectedItems);
    }

    // Check if all items are selected
    const allSelected = items.length > 0 && 
      items.every(item => newSelectedItems[item.id || item._id]);
    
    if (onSelectAllChange) {
      onSelectAllChange(allSelected);
    } else {
      setInternalSelectAll(allSelected);
    }
  };

  const handleSelectAll = (checked) => {
    const newSelectedItems = {};
    
    if (checked) {
      items.forEach(item => {
        const itemId = item.id || item._id;
        if (itemId) newSelectedItems[itemId] = true;
      });
    }
    
    if (onSelectChange) {
      onSelectChange(newSelectedItems);
    } else {
      setInternalSelectedItems(newSelectedItems);
    }
    
    if (onSelectAllChange) {
      onSelectAllChange(checked);
    } else {
      setInternalSelectAll(checked);
    }
  };

  return (
    <TableContext.Provider 
      value={{ 
        sortConfig: actualSortConfig, 
        requestSort, 
        selectedItems: actualSelectedItems, 
        selectItem,
        selectAll: actualSelectAll,
        handleSelectAll,
        items
      }}
    >
      <Table ref={ref} className={className} {...props}>
        {children}
      </Table>
    </TableContext.Provider>
  );
});
EnhancedTable.displayName = "EnhancedTable";

// Use the table context
const useTableContext = () => React.useContext(TableContext);

// Enhanced Table Header Cell with sorting capability
const SortableTableHead = React.forwardRef(({ 
  children, 
  className, 
  sortKey,
  ...props 
}, ref) => {
  const { sortConfig, requestSort } = useTableContext();
  
  const getSortIcon = () => {
    if (!sortKey) return null;
    
    if (sortConfig.key !== sortKey) {
      return (
        <div className="ml-2 inline-flex items-center">
          <div className="flex flex-col">
            <ChevronUp className="h-3 w-3 opacity-50" />
            <ChevronDown className="h-3 w-3 opacity-50 -mt-1" />
          </div>
        </div>
      );
    }
    return sortConfig.direction === 'ascending' ? (
      <SortAsc className="ml-2 h-4 w-4" />
    ) : (
      <SortDesc className="ml-2 h-4 w-4" />
    );
  };

  return (
    <TableHead 
      ref={ref} 
      className={cn(sortKey && "cursor-pointer", className)} 
      onClick={() => sortKey && requestSort(sortKey)}
      {...props}
    >
      <div className="flex items-center">
        <span>{children}</span>
        {getSortIcon()}
      </div>
    </TableHead>
  );
});
SortableTableHead.displayName = "SortableTableHead";

// Select All Checkbox for table header
const SelectAllTableHead = React.forwardRef(({ className, ...props }, ref) => {
  const { selectAll, handleSelectAll, items } = useTableContext();
  
  return (
    <TableHead ref={ref} className={cn("w-12", className)} {...props}>
      {items.length > 0 && (
        <Checkbox 
          checked={selectAll}
          onCheckedChange={handleSelectAll}
          aria-label="Select all"
        />
      )}
    </TableHead>
  );
});
SelectAllTableHead.displayName = "SelectAllTableHead";

// Selectable Table Row
const SelectableTableRow = React.forwardRef(({ 
  children, 
  className, 
  item,
  ...props 
}, ref) => {
  const { selectedItems, selectItem } = useTableContext();
  const itemId = item?.id || item?._id;
  
  return (
    <TableRow
      ref={ref}
      className={cn(
        selectedItems[itemId] && "bg-muted/50",
        className
      )}
      {...props}
    >
      {children}
    </TableRow>
  );
});
SelectableTableRow.displayName = "SelectableTableRow";

// Checkbox Cell for the selectable row
const SelectionTableCell = React.forwardRef(({ 
  className, 
  item,
  ...props 
}, ref) => {
  const { selectedItems, selectItem } = useTableContext();
  const itemId = item?.id || item?._id;
  
  return (
    <TableCell ref={ref} className={className} {...props}>
      {itemId && (
        <Checkbox 
          checked={!!selectedItems[itemId]}
          onCheckedChange={(checked) => selectItem(itemId, checked)}
          aria-label={`Select row ${itemId}`}
        />
      )}
    </TableCell>
  );
});
SelectionTableCell.displayName = "SelectionTableCell";

// Reusable Actions Cell with dropdown menu
const ActionsTableCell = React.forwardRef(({ 
  children, 
  className,
  ...props 
}, ref) => {
  return (
    <TableCell ref={ref} className={className} {...props}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm">
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {children}
        </DropdownMenuContent>
      </DropdownMenu>
    </TableCell>
  );
});
ActionsTableCell.displayName = "ActionsTableCell";

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
  EnhancedTable,
  SortableTableHead,
  SelectAllTableHead,
  SelectableTableRow,
  SelectionTableCell,
  ActionsTableCell,
  useTableContext
};