"use client";

import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion } from "framer-motion";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../../../ui/tooltip";
import {
  Calendar,
  ChevronLeft,
  ChevronRight,
  ArrowRight,
  Maximize2,
  GripHorizontal,
  Check,
  ChevronDown
} from "lucide-react";
import { Badge } from "../../../ui/badge";
import { Button } from "../../../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../../ui/select";
import { Slider } from "../../../ui/slider";
import { Popover, PopoverContent, PopoverTrigger } from "../../../ui/popover";

// Category colors for different categories
const CATEGORY_COLORS = {
  default: {
    bg: "bg-violet-500",
    hover: "hover:bg-violet-600",
    light: "bg-violet-100",
    circle: "bg-violet-500"
  },
  home: {
    bg: "bg-blue-500",
    hover: "hover:bg-blue-600",
    light: "bg-blue-100",
    circle: "bg-blue-500"
  },
  dew: {
    bg: "bg-emerald-500",
    hover: "hover:bg-emerald-600",
    light: "bg-emerald-100",
    circle: "bg-emerald-500"
  },
  planning: {
    bg: "bg-amber-500",
    hover: "hover:bg-amber-600",
    light: "bg-amber-100",
    circle: "bg-amber-500"
  },
  design: {
    bg: "bg-rose-500",
    hover: "hover:bg-rose-600",
    light: "bg-rose-100",
    circle: "bg-rose-500"
  },
  development: {
    bg: "bg-indigo-500",
    hover: "hover:bg-indigo-600",
    light: "bg-indigo-100",
    circle: "bg-indigo-500"
  },
  testing: {
    bg: "bg-orange-500",
    hover: "hover:bg-orange-600",
    light: "bg-orange-100",
    circle: "bg-orange-500"
  },
  deployment: {
    bg: "bg-cyan-500",
    hover: "hover:bg-cyan-600",
    light: "bg-cyan-100",
    circle: "bg-cyan-500"
  },
  maintenance: {
    bg: "bg-purple-500",
    hover: "hover:bg-purple-600",
    light: "bg-purple-100",
    circle: "bg-purple-500"
  }
};

const CustomGanttChart = ({
  tasks,
  onTaskClick,
  onTaskUpdate,
  siteOpeningDate, // New prop for site opening date
  siteClosingDate // New prop for site closing date (optional)
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [daysInRange, setDaysInRange] = useState([]);
  const [zoom, setZoom] = useState("month"); // Changed from "day" to "month"
  const [isToday, setIsToday] = useState(false);
  const [draggedTask, setDraggedTask] = useState(null);
  const [dragType, setDragType] = useState(null); // 'move', 'resize-start', 'resize-end'
  const [dragStartPos, setDragStartPos] = useState(null);
  const [dragTaskOriginalDates, setDragTaskOriginalDates] = useState(null);
  const [hoveredTask, setHoveredTask] = useState(null);
  const [categoryColors, setCategoryColors] = useState({});
  const scrollRef = useRef(null);
  const ganttContainerRef = useRef(null);

  // Calculate date range from tasks
  useEffect(() => {
    if (!tasks || tasks.length === 0) return;

    // Find earliest start date and latest end date
    let earliest = new Date(tasks[0].startDate);
    let latest = new Date(tasks[0].endDate);

    tasks.forEach((task) => {
      const taskStart = new Date(task.startDate);
      const taskEnd = new Date(task.endDate);

      if (taskStart < earliest) earliest = taskStart;
      if (taskEnd > latest) latest = taskEnd;
    });

    // Add padding to the range (14 days before and after)
    earliest.setDate(earliest.getDate() - 7);
    latest.setDate(latest.getDate() + 14);

    // If we have site constraints, respect them for the chart boundaries
    if (siteOpeningDate) {
      const openingDate = new Date(siteOpeningDate);
      if (openingDate < earliest) {
        earliest = new Date(openingDate);
      }
    }

    if (siteClosingDate) {
      const closingDate = new Date(siteClosingDate);
      if (closingDate > latest) {
        latest = new Date(closingDate);
      }
    }

    setStartDate(earliest);
    setEndDate(latest);

    // Generate array of days in the range
    const days = [];
    const currentDay = new Date(earliest);

    while (currentDay <= latest) {
      days.push(new Date(currentDay));
      currentDay.setDate(currentDay.getDate() + 1);
    }

    setDaysInRange(days);

    // Check if today is in the range
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const isInRange = days.some(
      (day) =>
        day.getFullYear() === today.getFullYear() &&
        day.getMonth() === today.getMonth() &&
        day.getDate() === today.getDate()
    );
    setIsToday(isInRange);

    // Generate category colors
    const colors = {};
    const uniqueCategories = new Set();

    tasks.forEach((task) => {
      if (task.categoryId && !uniqueCategories.has(task.categoryId)) {
        uniqueCategories.add(task.categoryId);
      }
    });

    // Assign colors to categories
    const colorKeys = Object.keys(CATEGORY_COLORS);
    let colorIndex = 0;

    uniqueCategories.forEach((categoryId) => {
      // Find category name from first task with this categoryId
      const categoryTask = tasks.find((t) => t.categoryId === categoryId);
      const categoryName = categoryTask?.categoryName?.toLowerCase() || "";

      // Check if the category name matches any of our predefined color keys
      const matchedColor = colorKeys.find((key) => categoryName.includes(key));

      if (matchedColor) {
        colors[categoryId] = CATEGORY_COLORS[matchedColor];
      } else {
        // Assign the next color from our list
        colors[categoryId] =
          CATEGORY_COLORS[colorKeys[colorIndex % colorKeys.length]];
        colorIndex++;
      }
    });

    setCategoryColors(colors);
  }, [tasks, siteOpeningDate, siteClosingDate]);

  // Scroll to today if in range
  useEffect(() => {
    if (isToday && scrollRef.current) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // Find index of today in daysInRange
      const todayIndex = daysInRange.findIndex(
        (day) =>
          day.getFullYear() === today.getFullYear() &&
          day.getMonth() === today.getMonth() &&
          day.getDate() === today.getDate()
      );

      if (todayIndex !== -1) {
        const scrollPosition = todayIndex * getDayWidth();
        scrollRef.current.scrollLeft =
          scrollPosition - scrollRef.current.clientWidth / 3;
      }
    }
  }, [isToday, daysInRange]);

  // Helper function to get day width based on zoom level
  const getDayWidth = () => {
    switch (zoom) {
      case "day":
        return 80;
      case "week":
        return 45;
      case "month":
        return 25;
      default:
        return 80;
    }
  };

  // Helper to format date as needed
  const formatDate = (date, format = "short") => {
    if (!date) return "";

    const options = {
      weekday: format === "full" ? "long" : "short",
      day: "numeric",
      month: format === "full" ? "long" : "short"
    };

    return date.toLocaleDateString("it-IT", options);
  };

  // Helper to determine the position and width of a task bar
  const calculateTaskBar = (task) => {
    if (!startDate || !endDate || daysInRange.length === 0)
      return { left: 0, width: 0 };

    const taskStart = new Date(task.startDate);
    const taskEnd = new Date(task.endDate);

    // Calculate start position
    let startDiff = (taskStart - startDate) / (24 * 60 * 60 * 1000); // Difference in days

    // Calculate end position
    let endDiff = (taskEnd - startDate) / (24 * 60 * 60 * 1000); // Difference in days

    // Ensure at least 1 day width
    if (endDiff - startDiff < 1) endDiff = startDiff + 1;

    // Calculate position and width
    const dayWidth = getDayWidth();
    const left = startDiff * dayWidth;
    const width = (endDiff - startDiff) * dayWidth;

    return { left, width };
  };

  // Helper to get a task color based on its category
  const getTaskColor = (task) => {
    if (task.categoryId && categoryColors[task.categoryId]) {
      return categoryColors[task.categoryId];
    }
    return CATEGORY_COLORS.default;
  };

  // Handle zoom change
  const handleZoomChange = (value) => {
    // Store current scroll position relative to total width
    const scrollContainer = scrollRef.current;
    const currentPosition = scrollContainer
      ? scrollContainer.scrollLeft / scrollContainer.scrollWidth
      : 0;

    setZoom(value);

    // After rerender, restore relative scroll position
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollLeft =
          currentPosition * scrollRef.current.scrollWidth;
      }
    }, 50);
  };

  // Scroll to today
  const scrollToToday = () => {
    if (!isToday || !scrollRef.current) return;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const todayIndex = daysInRange.findIndex(
      (day) =>
        day.getFullYear() === today.getFullYear() &&
        day.getMonth() === today.getMonth() &&
        day.getDate() === today.getDate()
    );

    if (todayIndex !== -1) {
      const scrollPosition = todayIndex * getDayWidth();
      scrollRef.current.scrollTo({
        left: scrollPosition - scrollRef.current.clientWidth / 3,
        behavior: "smooth"
      });
    }
  };

  // Handle scroll event to dynamically load more dates
  const handleScroll = useCallback(() => {
    if (!scrollRef.current) return;

    const scrollElement = scrollRef.current;
    const { scrollLeft, scrollWidth, clientWidth } = scrollElement;

    // Check if we're near the left edge (within 200px)
    if (scrollLeft < 200 && startDate) {
      // Add more days to the left (30 days)
      const newStartDate = new Date(startDate);
      newStartDate.setDate(newStartDate.getDate() - 30);

      // Respect site opening date if provided
      if (siteOpeningDate) {
        const openingDate = new Date(siteOpeningDate);
        if (newStartDate < openingDate) {
          newStartDate.setTime(openingDate.getTime());
        }
      }

      // Only update if the new start date is actually earlier
      if (newStartDate < startDate) {
        // Update the start date
        setStartDate(newStartDate);

        // Regenerate days range
        const days = [];
        const currentDay = new Date(newStartDate);

        while (currentDay <= endDate) {
          days.push(new Date(currentDay));
          currentDay.setDate(currentDay.getDate() + 1);
        }

        setDaysInRange(days);

        // Maintain scroll position after adding days
        const currentPosition = scrollLeft;
        const offsetAdded =
          getDayWidth() *
          Math.round((startDate - newStartDate) / (24 * 60 * 60 * 1000));

        requestAnimationFrame(() => {
          if (scrollRef.current) {
            scrollRef.current.scrollLeft = currentPosition + offsetAdded;
          }
        });
      }
    }

    // Check if we're near the right edge (within 200px)
    if (scrollWidth - (scrollLeft + clientWidth) < 200 && endDate) {
      // Add more days to the right (30 days)
      const newEndDate = new Date(endDate);
      newEndDate.setDate(newEndDate.getDate() + 30);

      // Respect site closing date if provided
      if (siteClosingDate) {
        const closingDate = new Date(siteClosingDate);
        if (newEndDate > closingDate) {
          newEndDate.setTime(closingDate.getTime());
        }
      }

      // Only update if the new end date is actually later
      if (newEndDate > endDate) {
        // Update the end date
        setEndDate(newEndDate);

        // Add new days to the range
        const newDays = [...daysInRange];
        const currentDay = new Date(newDays[newDays.length - 1]);
        currentDay.setDate(currentDay.getDate() + 1);

        while (currentDay <= newEndDate) {
          newDays.push(new Date(currentDay));
          currentDay.setDate(currentDay.getDate() + 1);
        }

        setDaysInRange(newDays);
      }
    }
  }, [
    startDate,
    endDate,
    daysInRange,
    getDayWidth,
    siteOpeningDate,
    siteClosingDate
  ]);

  // Add scroll event listener
  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  // Render dependency arrow between tasks
  const renderDependencyArrow = (task) => {
    if (!task.dependencies) return null;

    // Find the dependency task
    const dependencyTask = tasks.find((t) => t._id === task.dependencies);
    if (!dependencyTask) return null;

    const sourcePos = calculateTaskBar(dependencyTask);
    const targetPos = calculateTaskBar(task);

    // Calculate arrow points
    const sourceX = sourcePos.left + sourcePos.width;
    const sourceY = tasks.indexOf(dependencyTask) * 40 + 20; // Center of the task bar
    const targetX = targetPos.left;
    const targetY = tasks.indexOf(task) * 40 + 20; // Center of the task bar

    // Construct SVG path for curved arrow
    const controlX = (sourceX + targetX) / 2;
    const path = `M${sourceX},${sourceY} C${controlX},${sourceY} ${controlX},${targetY} ${targetX},${targetY}`;

    return (
      <svg
        className="absolute top-0 left-0 w-full h-full pointer-events-none"
        style={{ zIndex: 1 }}
      >
        <path
          d={path}
          stroke="#9333ea"
          strokeWidth="1.5"
          fill="none"
          strokeDasharray="4"
          markerEnd="url(#arrowhead)"
        />
        <defs>
          <marker
            id="arrowhead"
            markerWidth="8"
            markerHeight="6"
            refX="8"
            refY="3"
            orient="auto"
          >
            <polygon points="0 0, 8 3, 0 6" fill="#9333ea" />
          </marker>
        </defs>
      </svg>
    );
  };

  // Validate date constraints during drag operations
  const validateDateConstraints = (startDate, endDate) => {
    if (!startDate || !endDate) return false;

    // Ensure we're working with Date objects
    const start = startDate instanceof Date ? startDate : new Date(startDate);
    const end = endDate instanceof Date ? endDate : new Date(endDate);

    // If we have site opening date
    if (siteOpeningDate) {
      const openingDate = new Date(siteOpeningDate);
      openingDate.setHours(0, 0, 0, 0);

      if (start < openingDate) {
        return false;
      }
    }

    // If we have site closing date
    if (siteClosingDate) {
      const closingDate = new Date(siteClosingDate);
      closingDate.setHours(23, 59, 59, 999);

      if (end > closingDate) {
        return false;
      }
    }

    return true;
  };

  // Handle mouse down to start drag
  const handleMouseDown = (e, task, type) => {
    e.stopPropagation();
    e.preventDefault();

    try {
      // Ensure we have valid dates before initiating drag
      const startDate = new Date(task.startDate);
      const endDate = new Date(task.endDate);

      // Save original task state
      setDraggedTask({ ...task });
      setDragType(type);
      setDragStartPos({ x: e.clientX, y: e.clientY });
      setDragTaskOriginalDates({
        startDate: startDate,
        endDate: endDate
      });
    } catch (error) {
      console.error("Error processing dates during drag start:", error);
      // Don't start drag if dates are invalid
      return;
    }

    // Change cursor
    if (ganttContainerRef.current) {
      ganttContainerRef.current.style.cursor =
        type === "move" ? "grabbing" : "ew-resize";
    }
  };

  // Handle mouse move for drag
  const handleMouseMove = useCallback(
    (e) => {
      if (!draggedTask || !dragStartPos || !dragTaskOriginalDates) return;

      const dx = e.clientX - dragStartPos.x;
      const daysShifted = Math.round(dx / getDayWidth());

      if (daysShifted === 0) return;

      const newDates = { ...dragTaskOriginalDates };

      // Update dates based on drag type
      if (dragType === "move") {
        // Make sure we're working with Date objects
        newDates.startDate =
          dragTaskOriginalDates.startDate instanceof Date
            ? new Date(dragTaskOriginalDates.startDate)
            : new Date(dragTaskOriginalDates.startDate);

        newDates.endDate =
          dragTaskOriginalDates.endDate instanceof Date
            ? new Date(dragTaskOriginalDates.endDate)
            : new Date(dragTaskOriginalDates.endDate);

        newDates.startDate.setDate(newDates.startDate.getDate() + daysShifted);
        newDates.endDate.setDate(newDates.endDate.getDate() + daysShifted);

        // Validate against site constraints
        if (!validateDateConstraints(newDates.startDate, newDates.endDate)) {
          return; // Don't update if dates are invalid
        }
      } else if (dragType === "resize-start") {
        // Make sure we're working with Date objects
        newDates.startDate =
          dragTaskOriginalDates.startDate instanceof Date
            ? new Date(dragTaskOriginalDates.startDate)
            : new Date(dragTaskOriginalDates.startDate);

        newDates.startDate.setDate(newDates.startDate.getDate() + daysShifted);

        // Ensure start date is not after end date
        if (newDates.startDate >= dragTaskOriginalDates.endDate) {
          newDates.startDate = new Date(dragTaskOriginalDates.endDate);
          newDates.startDate.setDate(newDates.startDate.getDate() - 1);
        }

        // Validate against site opening date
        if (siteOpeningDate) {
          const openingDate = new Date(siteOpeningDate);
          openingDate.setHours(0, 0, 0, 0);

          if (newDates.startDate < openingDate) {
            newDates.startDate = new Date(openingDate);
          }
        }
      } else if (dragType === "resize-end") {
        // Make sure we're working with Date objects
        newDates.endDate =
          dragTaskOriginalDates.endDate instanceof Date
            ? new Date(dragTaskOriginalDates.endDate)
            : new Date(dragTaskOriginalDates.endDate);

        newDates.endDate.setDate(newDates.endDate.getDate() + daysShifted);

        // Ensure end date is not before start date
        if (newDates.endDate <= dragTaskOriginalDates.startDate) {
          newDates.endDate = new Date(dragTaskOriginalDates.startDate);
          newDates.endDate.setDate(newDates.endDate.getDate() + 1);
        }

        // Validate against site closing date
        if (siteClosingDate) {
          const closingDate = new Date(siteClosingDate);
          closingDate.setHours(23, 59, 59, 999);

          if (newDates.endDate > closingDate) {
            newDates.endDate = new Date(closingDate);
          }
        }
      }

      // Update task preview
      setDraggedTask({
        ...draggedTask,
        startDate: newDates.startDate,
        endDate: newDates.endDate
      });
    },
    [
      draggedTask,
      dragStartPos,
      dragTaskOriginalDates,
      dragType,
      getDayWidth,
      siteOpeningDate,
      siteClosingDate
    ]
  );

  // Handle mouse up for drag end
  const handleMouseUp = useCallback(() => {
    // Update task if it was actually moved
    if (draggedTask && dragTaskOriginalDates && onTaskUpdate) {
      // Ensure we're working with Date objects
      const draggedStartDate = new Date(draggedTask.startDate);
      const draggedEndDate = new Date(draggedTask.endDate);
      const originalStartDate = new Date(dragTaskOriginalDates.startDate);
      const originalEndDate = new Date(dragTaskOriginalDates.endDate);

      // Check if dates actually changed
      const startChanged =
        draggedStartDate.getTime() !== originalStartDate.getTime();
      const endChanged = draggedEndDate.getTime() !== originalEndDate.getTime();

      if (startChanged || endChanged) {
        // Format dates for API
        const formattedTask = {
          ...draggedTask,
          startDate: draggedStartDate.toISOString().split("T")[0],
          endDate: draggedEndDate.toISOString().split("T")[0]
        };

        // Call the update function
        onTaskUpdate(formattedTask);
      }
    }

    // Reset cursor
    if (ganttContainerRef.current) {
      ganttContainerRef.current.style.cursor = "default";
    }

    // Clear drag state
    setDraggedTask(null);
    setDragType(null);
    setDragStartPos(null);
    setDragTaskOriginalDates(null);
  }, [draggedTask, dragTaskOriginalDates, onTaskUpdate]);

  // Set up and clean up event listeners
  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  // Function to update task progress
  const handleProgressUpdate = (task, newProgress) => {
    if (onTaskUpdate) {
      onTaskUpdate({
        ...task,
        progress: newProgress
      });
    }
  };

  // No tasks to display
  if (!tasks || tasks.length === 0 || !startDate || !endDate) {
    return (
      <div className="flex justify-center items-center h-full bg-white rounded-md p-10">
        <div className="text-center text-gray-500">
          <Calendar className="h-16 w-16 mx-auto mb-4 opacity-20" />
          <h3 className="text-lg font-medium mb-2">Nessuna attività</h3>
          <p>Aggiungi attività per visualizzare il diagramma di Gantt.</p>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={ganttContainerRef}
      className="flex flex-col h-full bg-white rounded-md"
    >
      {/* Gantt Controls */}
      <div className="flex justify-between items-center p-2 border-b bg-gray-50">
        <div className="flex space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => scrollToToday()}
            disabled={!isToday}
            className="text-xs h-8"
          >
            <Calendar className="h-3.5 w-3.5 mr-1" />
            Oggi
          </Button>
          <div className="flex items-center space-x-1">
            <Button
              variant="ghost"
              size="sm"
              className="h-8 w-8 p-0"
              onClick={() => {
                if (scrollRef.current) {
                  // Scroll by 5 days worth of pixels
                  const scrollAmount = getDayWidth() * 5;

                  // Check if we need to add more days to the left
                  if (scrollRef.current.scrollLeft < scrollAmount * 2) {
                    // Add more days to the left (15 days)
                    const newStartDate = new Date(startDate);
                    newStartDate.setDate(newStartDate.getDate() - 15);

                    // Respect site opening date
                    if (siteOpeningDate) {
                      const openingDate = new Date(siteOpeningDate);
                      if (newStartDate < openingDate) {
                        newStartDate.setTime(openingDate.getTime());
                      }
                    }

                    // Only update if the new start date is actually earlier
                    if (newStartDate < startDate) {
                      setStartDate(newStartDate);

                      // Regenerate days range
                      const days = [];
                      const currentDay = new Date(newStartDate);

                      while (currentDay <= endDate) {
                        days.push(new Date(currentDay));
                        currentDay.setDate(currentDay.getDate() + 1);
                      }

                      setDaysInRange(days);

                      // Schedule scroll after state update and re-render
                      requestAnimationFrame(() => {
                        if (scrollRef.current) {
                          scrollRef.current.scrollLeft =
                            scrollRef.current.scrollLeft +
                            getDayWidth() * 15 -
                            scrollAmount;
                        }
                      });
                    } else {
                      // Normal scroll if we're at the site boundary
                      scrollRef.current.scrollBy({
                        left: -scrollAmount,
                        behavior: "smooth"
                      });
                    }
                  } else {
                    // Normal scroll if we have enough days
                    scrollRef.current.scrollBy({
                      left: -scrollAmount,
                      behavior: "smooth"
                    });
                  }
                }
              }}
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              className="h-8 w-8 p-0"
              onClick={() => {
                if (scrollRef.current) {
                  // Scroll by 5 days worth of pixels
                  const scrollAmount = getDayWidth() * 5;

                  // Calculate how much space is left to scroll
                  const { scrollLeft, scrollWidth, clientWidth } =
                    scrollRef.current;
                  const scrollRemaining =
                    scrollWidth - (scrollLeft + clientWidth);

                  // Check if we need to add more days to the right
                  if (scrollRemaining < scrollAmount * 2) {
                    // Add more days to the right (15 days)
                    const newEndDate = new Date(endDate);
                    newEndDate.setDate(newEndDate.getDate() + 15);

                    // Respect site closing date
                    if (siteClosingDate) {
                      const closingDate = new Date(siteClosingDate);
                      if (newEndDate > closingDate) {
                        newEndDate.setTime(closingDate.getTime());
                      }
                    }

                    // Only update if the new end date is actually later
                    if (newEndDate > endDate) {
                      setEndDate(newEndDate);

                      // Add new days to the range
                      const newDays = [...daysInRange];
                      const currentDay = new Date(newDays[newDays.length - 1]);
                      currentDay.setDate(currentDay.getDate() + 1);

                      while (currentDay <= newEndDate) {
                        newDays.push(new Date(currentDay));
                        currentDay.setDate(currentDay.getDate() + 1);
                      }

                      setDaysInRange(newDays);
                    }
                  }

                  // Scroll normally
                  scrollRef.current.scrollBy({
                    left: scrollAmount,
                    behavior: "smooth"
                  });
                }
              }}
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <Badge variant="outline" className="bg-gray-50 text-xs">
            {formatDate(startDate, "short")} - {formatDate(endDate, "short")}
          </Badge>
          <Select value={zoom} onValueChange={handleZoomChange}>
            <SelectTrigger className="w-24 h-8 text-xs">
              <SelectValue placeholder="Zoom" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="day">Giorno</SelectItem>
              <SelectItem value="week">Settimana</SelectItem>
              <SelectItem value="month">Mese</SelectItem>
            </SelectContent>
          </Select>
          <Button
            variant="ghost"
            size="sm"
            className="h-8 w-8 p-0"
            onClick={() => {
              // Toggle fullscreen logic
              const elem = document.documentElement;
              if (!document.fullscreenElement) {
                elem.requestFullscreen().catch((err) => {
                  //console.log(`Error attempting to enable fullscreen: ${err.message}`);
                });
              } else {
                document.exitFullscreen();
              }
            }}
          >
            <Maximize2 className="h-4 w-4" />
          </Button>
        </div>
      </div>

      {/* Gantt Chart */}
      <div className="flex flex-1 overflow-hidden">
        {/* Timeline and Tasks */}
        <div className="flex-1 overflow-hidden">
          {/* Timeline Header */}
          <div className="h-8 border-b sticky top-0 bg-white z-10 flex-shrink-0">
            <div
              className="flex"
              style={{ width: daysInRange.length * getDayWidth() }}
            >
              {daysInRange.map((day, index) => {
                const isWeekend = day.getDay() === 0 || day.getDay() === 6;
                const isCurrentDay =
                  day.getDate() === new Date().getDate() &&
                  day.getMonth() === new Date().getMonth() &&
                  day.getFullYear() === new Date().getFullYear();

                // Check if this date is the construction site opening date
                const isSiteOpeningDate =
                  siteOpeningDate &&
                  day.toDateString() ===
                    new Date(siteOpeningDate).toDateString();

                // Check if this date is the construction site closing date
                const isSiteClosingDate =
                  siteClosingDate &&
                  day.toDateString() ===
                    new Date(siteClosingDate).toDateString();

                return (
                  <div
                    key={index}
                    className={`flex-shrink-0 border-r text-xs flex flex-col justify-center items-center
                      ${isWeekend ? "bg-gray-50" : ""}
                      ${isCurrentDay ? "bg-blue-50 border border-blue-200" : ""}
                      ${
                        isSiteOpeningDate
                          ? "bg-green-50 border-l-2 border-green-500"
                          : ""
                      }
                      ${
                        isSiteClosingDate
                          ? "bg-rose-50 border-r-2 border-rose-500"
                          : ""
                      }
                    `}
                    style={{ width: getDayWidth() }}
                  >
                    <div className="font-medium text-gray-700">
                      {day.getDate()}
                    </div>
                    <div className="text-gray-500 text-[10px]">
                      {day.toLocaleDateString("it-IT", { weekday: "short" })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Task Bars */}
          <div
            ref={scrollRef}
            className="overflow-auto h-[calc(100%-2rem)] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
          >
            <div
              className="relative"
              style={{
                width: daysInRange.length * getDayWidth(),
                height: tasks.length * 40 // 40px height per task
              }}
            >
              {/* Grid Lines */}
              {daysInRange.map((day, index) => {
                const isWeekend = day.getDay() === 0 || day.getDay() === 6;
                const isCurrentDay =
                  day.getDate() === new Date().getDate() &&
                  day.getMonth() === new Date().getMonth() &&
                  day.getFullYear() === new Date().getFullYear();

                // Check if this date is the construction site opening date
                const isSiteOpeningDate =
                  siteOpeningDate &&
                  day.toDateString() ===
                    new Date(siteOpeningDate).toDateString();

                // Check if this date is the construction site closing date
                const isSiteClosingDate =
                  siteClosingDate &&
                  day.toDateString() ===
                    new Date(siteClosingDate).toDateString();

                return (
                  <div
                    key={index}
                    className={`absolute border-r h-full
                      ${isWeekend ? "bg-gray-50" : ""}
                      ${isCurrentDay ? "bg-blue-50" : ""}
                      ${
                        isSiteOpeningDate
                          ? "bg-green-50 border-l-2 border-green-500"
                          : ""
                      }
                      ${
                        isSiteClosingDate
                          ? "bg-rose-50 border-r-2 border-rose-500"
                          : ""
                      }
                    `}
                    style={{
                      left: index * getDayWidth(),
                      width: getDayWidth()
                    }}
                  />
                );
              })}

              {/* Horizontal Grid Lines */}
              {tasks.map((_, index) => (
                <div
                  key={index}
                  className="absolute border-b w-full"
                  style={{ top: (index + 1) * 40 - 1 }}
                />
              ))}

              {/* Dependency Arrows */}
              {tasks.map((task) => renderDependencyArrow(task))}

              {/* Task Bars */}
              {tasks.map((task, index) => {
                // Check if this is the currently dragged task
                const isDragging = draggedTask && draggedTask._id === task._id;

                // Use either the dragged task state or original task
                const currentTask = isDragging ? draggedTask : task;

                const { left, width } = calculateTaskBar(currentTask);
                const taskColor = getTaskColor(currentTask);

                return (
                  <TooltipProvider key={task._id}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{
                            duration: 0.2,
                            delay: index * 0.03,
                            ease: "easeOut"
                          }}
                          className={`absolute rounded-sm h-7 ${
                            taskColor.bg
                          } cursor-move
                            ${
                              isDragging
                                ? "ring-2 ring-offset-1 ring-indigo-300 z-20"
                                : "hover:ring-2 hover:ring-indigo-300 hover:shadow-md transition-shadow"
                            }
                          `}
                          style={{
                            top: index * 40 + 7,
                            left: left,
                            width: width,
                            zIndex: isDragging ? 20 : 10
                          }}
                          onMouseDown={(e) => handleMouseDown(e, task, "move")}
                          onMouseEnter={() => setHoveredTask(task)}
                          onMouseLeave={() => setHoveredTask(null)}
                        >
                          {/* Left resize handle */}
                          <div
                            className="absolute -left-1 top-0 w-2 h-full cursor-w-resize z-30"
                            onMouseDown={(e) =>
                              handleMouseDown(e, task, "resize-start")
                            }
                          />

                          {/* Right resize handle */}
                          <div
                            className="absolute -right-1 top-0 w-2 h-full cursor-e-resize z-30"
                            onMouseDown={(e) =>
                              handleMouseDown(e, task, "resize-end")
                            }
                          />

                          <div className="w-full h-full px-2 flex items-center justify-between text-white relative overflow-hidden">
                            <div className="flex items-center space-x-1 truncate">
                              <GripHorizontal className="h-3 w-3 text-white opacity-70" />
                              <span className="truncate font-medium text-xs">
                                {task.name}
                              </span>
                            </div>

                            {width > 60 && (
                              <span className="text-[10px] whitespace-nowrap ml-1">
                                {task.progress}%
                              </span>
                            )}

                            {/* Progress bar */}
                            <div
                              className="absolute bottom-0 left-0 h-1 bg-white bg-opacity-30"
                              style={{ width: `${task.progress}%` }}
                            />
                          </div>
                        </motion.div>
                      </TooltipTrigger>
                      <TooltipContent side="top" className="p-0">
                        <div className="bg-white p-3 rounded-lg shadow-lg border min-w-[250px]">
                          <div className="font-bold text-gray-800 flex items-center">
                            <span
                              className={`w-2 h-2 rounded-full mr-2 ${taskColor.bg}`}
                            ></span>
                            {task.name}
                          </div>
                          <div className="text-sm text-gray-600 mt-1">
                            <div className="flex items-center">
                              <Calendar className="h-3 w-3 mr-1" />
                              {new Date(task.startDate).toLocaleDateString(
                                "it-IT",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric"
                                }
                              )}{" "}
                              -{" "}
                              {new Date(task.endDate).toLocaleDateString(
                                "it-IT",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric"
                                }
                              )}
                            </div>

                            {/* Update progress option */}
                            <Popover>
                              <PopoverTrigger asChild>
                                <div className="mt-2 cursor-pointer">
                                  <div className="flex justify-between items-center text-xs text-gray-700">
                                    <span>Progresso:</span>
                                    <span className="font-medium">
                                      {task.progress}%
                                    </span>
                                  </div>
                                  <div className="w-full bg-gray-200 rounded-full h-1.5 mt-1">
                                    <div
                                      className={`${taskColor.bg} h-1.5 rounded-full`}
                                      style={{ width: `${task.progress}%` }}
                                    />
                                  </div>
                                </div>
                              </PopoverTrigger>
                              <PopoverContent className="w-64 p-2">
                                <div className="space-y-2">
                                  <h4 className="text-sm font-medium">
                                    Aggiorna progresso
                                  </h4>
                                  <Slider
                                    defaultValue={[task.progress]}
                                    max={100}
                                    step={5}
                                    onValueChange={(value) =>
                                      handleProgressUpdate(task, value[0])
                                    }
                                  />
                                  <div className="flex justify-between text-xs text-gray-500">
                                    <span>0%</span>
                                    <span>50%</span>
                                    <span>100%</span>
                                  </div>
                                </div>
                              </PopoverContent>
                            </Popover>

                            {task.dependencies && (
                              <div className="mt-2 flex items-center text-xs text-gray-700">
                                <ArrowRight className="h-3 w-3 mr-1" />
                                <span>Dipende da: </span>
                                <span className="font-medium ml-1">
                                  {tasks.find(
                                    (t) => t._id === task.dependencies
                                  )?.name || "Attività sconosciuta"}
                                </span>
                              </div>
                            )}

                            <div className="mt-2 border-t pt-2 text-xs text-gray-500">
                              <div>• Trascina per spostare</div>
                              <div>• Usa i bordi per ridimensionare</div>
                              <div>• Clicca per dettagli</div>
                            </div>
                          </div>
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Task modification mini-tip */}
      <div className="bg-gray-50 border-t p-1.5 text-xs text-gray-500 flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <div className="flex items-center">
            <GripHorizontal className="h-3 w-3 mr-1 text-gray-400" />
            <span>Trascina per spostare</span>
          </div>
          <div className="flex items-center">
            <ChevronDown className="h-3 w-3 mr-1 text-gray-400" />
            <span>Clicca per più opzioni</span>
          </div>
        </div>
        <div>
          {daysInRange.length} giorni • {tasks.length} attività
        </div>
      </div>
    </div>
  );
};

export default CustomGanttChart;
